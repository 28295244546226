import { doRequest } from "./helpers";

const PermissionService = {
  getPermissions: async () => {
    const response = await doRequest("GET", "/swagger.json");
    return response ? response.paths : null;
  },
  getPermissionCategories: async () => {
    return await doRequest("GET", "/admin/role/permission/categories");
  },

  createPermission: async (roleId, permission) => {
    return doRequest("POST", `/admin/role/${roleId}/permission`, {
      data: { ...permission },
    });
  },
  updatePermission: async (roleId, permissionId, permission) => {
    return doRequest(
      "PUT",
      `/admin/role/${roleId}/permission/${permissionId}`,
      {
        data: {
          ...permission,
        },
      }
    );
  },
};

export default PermissionService;

import React from "react";
import { Dropdown } from "semantic-ui-react";
import "./UserStatus.scoped.scss";
import { capitalize } from "lodash";
import { USER_STATUSES } from "constants/Constants";
import { useUserStatus } from "./UserStatusContext";

const statusesOptions = [
  {
    key: USER_STATUSES.ONLINE.key,
    text: capitalize(USER_STATUSES.ONLINE.label),
    value: USER_STATUSES.ONLINE.key,
    label: { color: "green", empty: true, circular: true },
  },
  {
    key: USER_STATUSES.OFFLINE.key,
    text: capitalize(USER_STATUSES.OFFLINE.label),
    value: USER_STATUSES.OFFLINE.key,
    label: { color: "red", empty: true, circular: true },
  },
  {
    key: USER_STATUSES.DISCONNECTED.key,
    text: capitalize(USER_STATUSES.DISCONNECTED.label),
    value: USER_STATUSES.DISCONNECTED.key,
    label: { color: "grey", empty: true, circular: true },
    className: "hidden-option",
  },
  {
    key: USER_STATUSES.DISABLED.key,
    text: capitalize(USER_STATUSES.DISABLED.label),
    value: USER_STATUSES.DISABLED.key,
    label: { color: "grey", empty: true, circular: true },
    className: "hidden-option",
  },
  {
    key: USER_STATUSES.ON_CALL.key,
    text: capitalize(USER_STATUSES.ON_CALL.label),
    value: USER_STATUSES.ON_CALL.key,
    label: { color: "orange", empty: true, circular: true },
    className: "hidden-option",
  },
];

function UserStatus() {
  const { status, isDisabled, updateStatus } = useUserStatus();

  const getCircleColor = () => {
    if (!status) return;

    return statusesOptions.find(option => option.key === status).label.color;
  };

  return (
    <div className="user-status">
      <div className={`ui circular empty label  ${getCircleColor()}`}></div>
      <Dropdown
        disabled={isDisabled}
        placeholder="Status"
        fluid
        selection
        value={status}
        onChange={(e, { _, value }) => {
          updateStatus(value);
        }}
        options={statusesOptions}
      />
    </div>
  );
}

export default UserStatus;

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Dropdown, Grid, List, Tab } from "semantic-ui-react";
import "./../../styles/campaign.scss";
import Sidebar from "../../components/Sidebar";
import CampaignService from "./../../services/Campaign";
import EntityService from "./../../services/Entity";
import AuthService from "./../../services/Auth";
import TagService from "../../services/Tag";
import RevenueOpportunityService from "../../services/RevenueOpportunities";
import { EditCampaignTargetModal as _EditCampaignTargetModal } from "./components/Modals";
import CustomItems from "./../../components/CustomItems";
import CallsTable from "../../components/CallsTable";
import { formatDate } from "./../../components/helpers";
import CONFIG from "../../Config";
import NoticesTable from "./NoticesTable";
import { NewNoteModal } from "./../../components/modals/NoteModals";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import { checkIsAuthorized } from "../../components/helpers";
import RouteParamsManager from "../../hooks/params/RouteParamsManager";
import moment from "moment";
import setPageTitle from "../../helpers/title";
import ContactsTable from "./components/ContactsTable";
import RevenueOpportunityModal from "../../components/modals/RevenueOpportunityModal";
import NotesView from "../../components/views/NotesView";
import isNil from "lodash/isNil";
import CasesTable from "../case/CasesTable";
import _RevenueOpportunitiesTable from "../../components/RevenueOpportunityTable";

const EditCampaignTargetModal = withRoleCheck(_EditCampaignTargetModal, [
  ACL_RELATIONSHIPS.campaignTarget.edit,
]);

const RevenueOpportunitiesTable = withRoleCheck(
  _RevenueOpportunitiesTable,
  [ACL_RELATIONSHIPS.revenueOpportunity.readTable],
  { showMsg: true }
);

const CampaignTarget = () => {
  const [campaignName, setCampaignName] = useState("no name");
  const [campaignTarget, setCampaignTarget] = useState({
    id: null,
    entity_name: "",
    campaign_id: null,
    entity_id: null,
    creator_user_id: null,
    assigned_user_id: null,
    info: { custom: {} },
    campaign_status: {},
    metrics: {},
    tags: [],
    custom_fields: {},
  });
  const [entity, setEntity] = useState({
    id: null,
    name: null,
    url: null,
    addresses: [],
    contacts: [],
  });
  const [routeParams, setRouteParams] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [tags, setTags] = useState([]);
  const [revenueOpportunity, setRevenueOpportunity] = useState({});
  const [revenueStatusOptions, setRevenueStatusOptions] = useState([]);
  const newNoteModal = useRef();

  const params = useParams();
  const navigate = useNavigate();

  const getTabs = () => {
    let tabs = [
      { name: "contacts" },
      { name: "notices" },
      { name: "calls", visible: CONFIG.IS_PHONE_ENABLED },
      { name: "notes" },
      {
        name: "cases",
        visible: CONFIG.ENABLE_PIRACY,
      },
      {
        name: "revenueOpportunities",
      },
    ];
    tabs = tabs
      .filter(({ visible }) => visible === undefined || visible === true)
      .map(({ name }) => name);
    setTabs(tabs);
  };

  const deleteCampaignTarget = async (campaignId, entityId) => {
    await CampaignService.deleteCampaignTarget(campaignId, entityId);
    navigate("/campaigns/" + campaignId);
  };

  const fetchCampaignTarget = async () => {
    const target = await CampaignService.getCampaignTarget(
      params.id,
      params.entity_id
    );
    const ct = target || campaignTarget;
    setPageTitle(ct.entity_name);
    setCampaignTarget(ct);
  };

  const fetchTags = async () => {
    const tags = await TagService.getTags({ model: "entity" });
    setTags(tags);
  };

  const fetchCampaign = async () => {
    const campaign = await CampaignService.getCampaign(params.id);
    setCampaignName(campaign.name);
  };

  const fetchEntity = async () => {
    const entity = await EntityService.getEntity(params.entity_id);
    setEntity(entity);
  };

  const fetchRevenueStatusOptions = async () => {
    let options =
      await RevenueOpportunityService.getRevenueOpportunityStatusesForFilters();
    let optionsArr = [];
    if (options) {
      options.map(option => {
        let formOption = {
          id: option.name,
          text: option.name,
          value: option.id,
        };
        optionsArr.push(formOption);
      });
      setRevenueStatusOptions(optionsArr);
    }
  };

  const fetchRevenueOpportunity = async () => {
    const entityId = params.entity_id;
    const campaignId = params.id;
    const filters = { entity_id: entityId };
    const revenueOpportunity =
      await RevenueOpportunityService.getRevenueOpportunity(
        campaignId,
        filters
      );
    setRevenueOpportunity(revenueOpportunity);
  };

  const handleRevenueStatusChange = async (revenueOpportunityId, data) => {
    if (data) {
      const formData = {
        status_id: data.value,
      };
      await RevenueOpportunityService.setRevenueOpportunityStatus(
        revenueOpportunityId,
        formData
      );
    }
    fetchRevenueOpportunity();
  };
  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      fetchCampaignTarget(); // don't await
      fetchCampaign(); // don't await
      fetchEntity(); // don't await
      fetchTags(); // don't await
      fetchRevenueStatusOptions();
      fetchRevenueOpportunity();
      getTabs();
    }
  }, []);

  const handleTabChange = (_, { activeIndex }) => {
    const tab = tabs[activeIndex];
    setRouteParams({ ...routeParams, tab });
  };

  const updateRouteParams = routeParams => {
    const activeIndex = tabs.findIndex(tab => tab === routeParams.tab);
    setRouteParams(routeParams);
    setActiveIndex(activeIndex);
  };

  const getPanes = () => {
    let panes = [
      {
        key: "contacts",
        menuItem: "Contacts",
        render: () => (
          <Tab.Pane>
            <ContactsTable
              tableName="campaign_target_contacts"
              entityId={parseInt(params.entity_id)}
              campaignId={parseInt(params.id)}
              entityName={entity.name}
            />
          </Tab.Pane>
        ),
      },
      {
        key: "notices",
        menuItem: "Notices",
        render: () => (
          <Tab.Pane>
            <NoticesTable campaignId={params.id} entityId={params.entity_id} />
          </Tab.Pane>
        ),
      },
      {
        key: "calls",
        menuItem: "Calls",
        render: () => (
          <Tab.Pane>
            <CallsTable campaignId={params.id} entityId={params.entity_id} />
          </Tab.Pane>
        ),
      },
      {
        key: "notes",
        menuItem: "Notes",
        render: () => (
          <Tab.Pane>
            <NotesView
              modelType="CampaignTarget"
              modelId={campaignTarget.id}
              fluid
            />
          </Tab.Pane>
        ),
      },
      {
        key: "cases",
        menuItem: "Cases",
        render: () => (
          <Tab.Pane>
            <CasesTable campaignId={params.id} entityId={params.entity_id} />
          </Tab.Pane>
        ),
      },
      {
        key: "revenueOpportunities",
        menuItem: "Revenue Opportunities",
        render: () => (
          <Tab.Pane>
            <RevenueOpportunitiesTable
              nested
              campaignId={params.id}
              entityId={params.entity_id}
            />
          </Tab.Pane>
        ),
      },
    ];
    panes = panes.filter(({ key }) => tabs.includes(key));
    return panes;
  };

  const renderSidebar = () => (
    <Sidebar
      modelName="campaignTargets"
      modelId={parseInt(campaignTarget.id)}
      campaignId={campaignTarget.campaign_id}
      campaignName={campaignName}
      name={campaignTarget.entity_name}
      fetchModel={fetchEntity}
      editButton={
        <EditCampaignTargetModal
          fetchCampaignTargets={fetchCampaignTarget}
          campaignId={campaignTarget.campaign_id}
          entityId={campaignTarget.entity_id}
          iconTrigger="pencil"
        />
      }
      onCreateNote={() => {
        newNoteModal.current.open();
      }}
      info={[
        {
          title: "Entity Name",
          value: (
            <Link to={`/entities/${campaignTarget.entity_id}`}>
              {campaignTarget.entity_name}
            </Link>
          ),
        },
        {
          title: "Creator",
          value: campaignTarget.creator_user_full_name,
        },
        {
          title: "Assignee",
          value: campaignTarget.assigned_full_name,
        },
        {
          title: "Created at",
          value: formatDate(campaignTarget.created_at),
        },
        {
          title: "# Contacts",
          value: campaignTarget.metrics.contact_count,
        },
        {
          title: "# Contacts in Campaign",
          value: campaignTarget.metrics.campaign_contact_count,
        },
        {
          title: "# Emailable Contacts",
          value: campaignTarget.metrics.emailable_contact_count,
        },
        {
          title: "# Callable Contacts",
          value: campaignTarget.metrics.callable_contact_count,
        },
        {
          title: "Campaign Status",
          value: campaignTarget.campaign_status.name,
        },
        {
          title: "Campaign Status Last Updated At",
          value: campaignTarget.status_last_updated_at
            ? moment(campaignTarget.status_last_updated_at).format("YYYY-MM-DD")
            : "N/A",
        },
        {
          value: entity.tags,
          type: "tags",
          model: entity,
          modelType: "Entity",
          tags,
        },
        {
          title: "Campaign Target Custom Fields",
          value: <CustomItems items={campaignTarget.custom_fields} />,
        },
        {
          title: "Entity Custom Fields",
          value: <CustomItems items={entity.custom_fields} />,
        },
      ]}
    />
  );

  if (tabs.length === 0) {
    return null;
  }

  const panes = getPanes();
  return (
    <Container fluid className="route divided">
      <RouteParamsManager
        defaultParams={{ tab: tabs[0] }}
        routeParams={routeParams}
        setRouteParams={updateRouteParams}
      />
      {renderSidebar()}
      <div className="main">
        <NewNoteModal
          ref={newNoteModal}
          modelType="CampaignTarget"
          modelId={parseInt(campaignTarget.id)}
          noTrigger
        />
        <Grid className="bg" divided style={{ height: "100%" }}>
          <Grid.Row>
            <Grid.Column>
              <div
                className="campaign-segment"
                style={{ padding: "0 0 2em 0" }}
              >
                <Tab
                  panes={panes}
                  activeIndex={activeIndex}
                  onTabChange={handleTabChange}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Container>
  );
};

export default CampaignTarget;

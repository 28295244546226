import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import countryOptions from "../../../components/Countries";
import RegionService from "../../../services/Regions";

export default class EditRegionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previousName: "",
      previousCountries: [],
      name: "",
      countries: [],
    };
  }

  async componentDidMount() {
    const region = await RegionService.getRegion(this.props.id);
    this.setState({
      name: region.name,
      countries: region.countries,
      previousName: region.name,
      previousCountries: region.countries,
    });
  }

  cancel = (e, d) => {
    this.setState({
      name: this.state.previousName,
      countries: this.state.previousCountries,
    });
    this.props.onClose();
  };

  handleSubmit = async () => {
    const name = this.state.name;
    const countries = this.state.countries.join(",");

    await RegionService.editRegion(this.props.id, name, countries);
  };

  handleChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  render() {
    const { name, countries } = this.state;
    return (
      <RuvixxForm
        ready={!!name && countries.length > 0}
        onCancel={this.cancel}
        onSubmit={this.handleSubmit}
        onSuccess={this.props.onSuccess}
      >
        <Form.Input
          inline
          name="name"
          label="Name"
          required
          onChange={this.handleChange}
          value={this.state.name}
        />
        <Form.Select
          inline
          search
          multiple
          clearable
          name="countries"
          label="Countries"
          placeholder="Select Country"
          onChange={this.handleChange}
          options={countryOptions}
          value={this.state.countries}
        />
      </RuvixxForm>
    );
  }
}

import { applyFilters, doRequest } from "./helpers";

export default class CaseEventService {
  static async getTable(filters = null) {
    let path = "/case_event/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getNearbyEvents(filters) {
    let path = "/case_event/nearby_events";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }
}

import React from "react";
import PropTypes from "prop-types";
import { Container, Dropdown, Segment } from "semantic-ui-react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import moment from "moment";

import "../../../styles/table.scss";
import "./../../../styles/tab_view.scss";

import BaseTable from "../../../components/BaseTable";
import AuthService from "../../../services/Auth";
import ContractService from "../../../services/Contract";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import CustomDropdown from "../../../components/CustomDropdown";
import setPageTitle from "../../../helpers/title";
import _DeleteContractModal from "./DeleteContractModal";
import _ContractModal from "./ContractModal";

const NewContractModal = withRoleCheck(_ContractModal, [
  ACL_RELATIONSHIPS.contract.create,
  ACL_RELATIONSHIPS.contractForFilters.read,
]);

const EditContractModal = withRoleCheck(_ContractModal, [
  ACL_RELATIONSHIPS.contract.edit,
  ACL_RELATIONSHIPS.contractForFilters.read,
]);

const DeleteContractModal = withRoleCheck(_DeleteContractModal, [
  ACL_RELATIONSHIPS.contract.delete,
]);

class ContractTable extends BaseTable {
  static propTypes = {
    entityId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.queryMethod = ContractService.getContracts;

    this.state = {
      ...this.state,
      defaultFilterParams: { entity_id: this.props.entityId },
      header: "Contracts",
      headerIcon: "building",
      className: "Contract",
      tableName: "contracts",
      noDataText: "No Contracts found. Try adjusting your filters.",
      contractOptions: [],
      createButton: (
        <NewContractModal
          onSuccess={this.fetchData}
          entityId={this.props.entityId}
        />
      ),
      enableCustomFieldsSettings: true,
    };
  }

  async componentDidMount() {
    setPageTitle("Contracts");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchCustomFields();
      this.fetchContracts();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "Contract ID",
        accessor: "id",
      },
      {
        Header: "External Contract #",
        accessor: "external_contract_num",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
        sortable: false,
        headerClassName: "non-sortable",
      },
      {
        Header: "Department",
        accessor: "department_name",
        className: "centered",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return null;
        },
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return null;
        },
      },
      {
        Header: "Number of Licenses",
        accessor: "num_licenses",
      },
      {
        Header: "Parent Contract Name",
        accessor: "parent_contract_name",
      },
      {
        Header: "Actions",
        id: "actions",
        width: 140,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditContractModal
                contract={props.original}
                entityId={this.props.entityId}
                onSuccess={this.fetchData}
              />
              <DeleteContractModal
                onConfirmDelete={() => this.deleteContract(props.original.id)}
                menuTrigger
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  deleteContract = async id => {
    await ContractService.deleteContract(id, this.props.entityId);
    this.fetchData();
  };

  fetchCustomFields = async () => {
    const customFields = await ContractService.getCustomFields({
      entity_id: this.props.entityId,
    });
    this.setState(
      {
        customFields: [
          {
            accessorPrefix: "custom_fields",
            fields: customFields,
            model: "Contract",
          },
        ],
      },
      this.updateFilterOptions
    );
  };

  fetchContracts = async () => {
    let filters = { entity_id: this.props.entityId };
    let contracts = await ContractService.getContractForFilters(filters);
    this.setState({ contractOptions: contracts }, this.updateFilterOptions);
  };

  updateFilterOptions = async () => {
    const { users, tags, customFields, contractOptions } = this.state;

    let filters = [
      {
        key: "parent_contract_id",
        title: "Parent Contract",
        type: "select",
        data: contractOptions,
      },
      {
        key: "start_date",
        title: "Start Date",
        type: "dateRange",
      },
      {
        key: "end_date",
        title: "End Date",
        type: "dateRange",
      },
    ];
    customFields.forEach(cf =>
      cf.fields.forEach(field => {
        filters.push({
          key: `search:custom:${cf.model}:${field}`,
          title: field,
          type: "input",
          model: cf.model,
        });
      })
    );
    this.setState({ filters });
  };

  render() {
    return (
      <Container fluid className="route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default ContractTable;

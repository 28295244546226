import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Header, Icon, Modal } from "semantic-ui-react";
import CONSTANTS from "../../constants/Constants";

import "../../routes/data_tab/components/forms/ImportForm.scoped.scss";
import UploadProgressBar from "../../routes/data_tab/components/UploadProgressBar";
import { handleDownload } from "../../routes/data_tab/helpers";
import DataJobService from "../../services/DataJob";
import TagService from "../../services/Tag";
import FilePicker from "../FilePicker";
import RuvixxForm from "../RuvixxForm";
import UploadManager from "../UploadManager";

const DownloadImportTemplate = () => {
  const templateName = "Case Data";

  const getImportTemplate = async () => {
    let guidelines = [];
    let fields = [];
    const rawTemplate = await DataJobService.getCaseDataImportTemplate();

    for (const row in rawTemplate) {
      fields.push(row);
      guidelines.push([row, rawTemplate[row]]);
    }

    fields.shift();

    const templateConfig = [
      {
        data: [fields],
        sheetName: `${templateName} Import Template`,
      },
      {
        data: guidelines,
        sheetName: `${templateName} Import Guidelines`,
      },
    ];

    await handleDownload(
      templateConfig,
      `${templateName} Import Template.xlsx`
    );
  };

  return (
    <div className="validation-template">
      <Button
        className="template-download"
        onClick={getImportTemplate}
        size="small"
      >
        <Icon name="download" />
        Download Template
      </Button>
    </div>
  );
};

const TIMEOUT = 2000;

function mapTagsForSelect(item) {
  return {
    key: item.id,
    text: item.name,
    value: item.id,
    ...(item.color && {
      className: "hideText",
      label: {
        content: item.name,
        className: "ctag",
        style: { backgroundColor: item.color },
      },
    }),
  };
}

const CaseDataImportForm = props => {
  const [cancelRequest, setCancelRequest] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [stage, setStage] = useState(props.dataJobId ? "importing" : "init");
  const [files, setFiles] = useState([]);
  const [isFileValid, setIsFileValid] = useState(false);
  const [dataJobId, setDataJobId] = useState(props.dataJobId);
  const [dataJobStatus, setDataJobStatus] = useState();
  const [progressListenerId, setProgressListenerId] = useState();
  const [successListenerId, setSuccessListenerId] = useState();
  const [numberOfFiles, setNumberOfFiles] = useState(0);
  const [processedFiles, setProcessedFiles] = useState(0);
  const [tagOptions, setTagOptions] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [description, setDescription] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const uploadManager = useMemo(() => new UploadManager(), []);

  useEffect(() => {
    setProgressListenerId(uploadManager.addProgressListener(handleProgress));
    setSuccessListenerId(uploadManager.addSuccessListener(handleSuccess));
    fetchTags();

    return function cleanup() {
      uploadManager.removeProgressListener(progressListenerId || 0);
      uploadManager.removeSuccessListener(successListenerId || 0);
    };
  }, [uploadManager]);

  useEffect(() => {
    let timeout, started;

    async function monitor(jobId) {
      try {
        const dataJob = await DataJobService.getDataJob(jobId);
        const { info, status } = dataJob;
        if (dataJob.description) {
          setDescription(dataJob.description);
        }

        setDataJobStatus(status);
        if (status === CONSTANTS.DATA_JOB_STATUSES.ABORTED) {
          setStage("aborted");
          return;
        }
        setNumberOfFiles(info.number_of_files || 0);
        setProcessedFiles(info.processed_files || 0);
        let doTimeout = false;
        switch (status) {
          case CONSTANTS.DATA_JOB_STATUSES.FAILED:
            setStage("failed");
            setError(true);
            setErrorMessage(info.error?.message);
            setSubmitted(true);
            break;
          case CONSTANTS.DATA_JOB_STATUSES.COMPLETE:
            setStage("imported");
            break;
          default:
            // still importing
            doTimeout = true;
        }

        if (doTimeout) {
          timeout = setTimeout(() => {
            monitor(jobId);
          }, TIMEOUT);
        }
      } catch (e) {
        // uncaught errors
        if (timeout) {
          clearTimeout(timeout);
        }
        setStage("errored");
        setSuccess(false);
        setError(true);
        setErrorMessage("message" in error ? error.message : error.toString());
      }
    }

    if (dataJobId && !started) {
      started = true;
      monitor(dataJobId);
    }

    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [dataJobId]);

  async function startImport() {
    setStage("importing");
    const dataJobId = (
      await DataJobService.createCaseDataImportJob(
        files.map(file => file.attachment),
        { tag_ids: tagIds },
        description
      )
    ).id;
    setDataJobId(dataJobId);
    setSubmitted(true);
  }

  const fetchTags = async () => {
    let tags = await TagService.getTags({ model: "case" });
    setTagOptions(tags.map(mapTagsForSelect));
  };

  function handleCancelRequest() {
    if (["uploading", "validating", "validated", "importing"].includes(stage)) {
      setCancelRequest(true);
    } else {
      props.refreshTable();
      props.onClose();
    }
  }

  function handleContinue() {
    setCancelRequest(false);
  }

  function handleAbort() {
    if (dataJobId) {
      DataJobService.abortImport(dataJobId); //dont await
    }

    props.refreshTable();
    props.onClose();
  }

  function handleHide() {
    props.refreshTable();
    props.onClose();
  }

  function handleDescriptionChange(_, { value: description }) {
    if (description.length <= 255) {
      setDescription(description);
    }
  }

  async function startUpload(files, event, dropped) {
    if (!files) {
      setSuccess(false);
      setError(false);
      setErrorMessage(null);
      return;
    }
    if (files.length === 0) {
      setSuccess(false);
      setError(true);
      setErrorMessage("No file was selected.");
      return;
    }
    let errorMessage = null;
    let isFileValid = true;
    if (dropped) {
      isFileValid = files.every(file => [".zip", ".csv"].includes(file.type));
      errorMessage = isFileValid ? null : `Upload must be a .zip or .csv file.`;
    }

    setIsFileValid(isFileValid);
    setSuccess(false);
    setError(!isFileValid);
    setErrorMessage(errorMessage);

    if (isFileValid) {
      setStage("uploading");
      try {
        await uploadManager.uploadFiles(files);
      } catch (error) {
        setStage("failed");
        setError(true);
        setErrorMessage("message" in error ? error.message : error.toString());
      }
    }
  }

  function handleProgress(files) {
    setFiles([...files]); // careful, not enough to be considered immutable...
  }

  const handleSuccess = useCallback(async () => {
    if (files.every(file => file.status === "finished")) {
      setStage("uploaded");
    }
  }, [files]);

  const statusMessage = Object.keys(CONSTANTS.DATA_JOB_STATUSES).map(key => {
    const status = key.toLowerCase();
    return status.replaceAll("_", " ");
  });
  return (
    <RuvixxForm
      ready
      error={error}
      errorMessage={errorMessage}
      success={success}
      extraButtons={[
        [
          CONSTANTS.DATA_JOB_STATUSES.IN_PROGRESS,
          CONSTANTS.DATA_JOB_STATUSES.SCHEDULED,
          CONSTANTS.DATA_JOB_STATUSES.READY,
        ].includes(dataJobStatus) && (
          <Button
            secondary
            basic
            onClick={handleCancelRequest}
            compact
            size="tiny"
          >
            Cancel
          </Button>
        ),

        <Button
          secondary
          onClick={handleHide}
          compact
          size="tiny"
          content="Close"
        />,
      ]}
      submitted={submitted}
    >
      <div className="validation-grid">
        {stage === "uploading" ? <p>Uploading file...</p> : null}
        {stage === "uploaded" ? (
          <div className="validation-grid">
            <Icon name="check circle" color="green" />
            <p className="successful">Upload successful!</p>
          </div>
        ) : null}
        {/*<UploadProgressBar uploads={files} validation />*/}
        {!dataJobStatus && files.length > 0 && (
          <UploadProgressBar
            uploads={files}
            onDelete={uploadManager.removeUpload}
            xOnComplete={true}
          />
        )}
        {(stage === "init" || stage === "uploaded") && (
          <>
            <FilePicker
              multiple
              onSelect={startUpload}
              accept={".zip, .csv"}
              primary
            />
            <Form.Select
              style={{ minWidth: "250px" }}
              key="tags"
              multiple
              label="Tag"
              inline
              search
              options={tagOptions}
              onChange={(e, { value }) => setTagIds(value)}
              placeholder="Choose a Tag"
              renderLabel={item => item.label || item.text}
            />
            <div className="description-container">
              <Form.TextArea
                required
                inline
                label="Description"
                style={{
                  maxWidth: "calc(100% - 135px)",
                  minWidth: "300px",
                  height: "43px",
                }}
                onChange={handleDescriptionChange}
                value={description}
                placeholder="Description..."
              />
              <span className="description-counter">
                {description.length}/255
              </span>
            </div>
            {["init", "uploaded"].includes(stage) && (
              <p className="validation-msg" style={{ marginBottom: "0.5em" }}>
                Before you upload your file, make sure it's in Pleteo format.
                File should be a zip file containing one or more .csv files.
              </p>
            )}
            <DownloadImportTemplate />
          </>
        )}
        {stage === "uploaded" && (
          <Button
            style={{ marginTop: "1em" }}
            disabled={!files.length || !isFileValid || !description}
            className="item-adder"
            content="Import"
            onClick={startImport}
          />
        )}
      </div>
      {stage === "importing" ||
      dataJobStatus === CONSTANTS.DATA_JOB_STATUSES.IN_PROGRESS ? (
        <div>
          {numberOfFiles
            ? `Processing Files... (${
                processedFiles || 0
              } of ${numberOfFiles} complete)`
            : "Processing Files..."}
          <Icon name="circle notched" loading />
        </div>
      ) : null}

      {dataJobStatus >= CONSTANTS.DATA_JOB_STATUSES.IN_PROGRESS && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span>Import {statusMessage[dataJobStatus - 1]}</span>
          <span>{description}</span>
        </div>
      )}

      {cancelRequest ? (
        <Modal open={true} onClose={handleContinue} size="small">
          <Header icon="warning sign" content="Abort Process?" />
          <Modal.Content>
            This operation will abort the process and you will have to start
            again.
          </Modal.Content>
          <Modal.Actions>
            <Button className="warning cancel" onClick={handleContinue}>
              <Icon name="remove" /> No
            </Button>
            <Button className="warning confirm" inverted onClick={handleAbort}>
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      ) : null}
    </RuvixxForm>
  );
};
export default CaseDataImportForm;

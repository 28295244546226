import React, { useState, useEffect } from "react";
import DownloadAttachments from "./DownloadAttachments";
import PleteoUpload from "./PleteoUpload";
import { formatDate } from "../helpers";
import {
  Button,
  Card,
  Header,
  Icon,
  Image,
  Label,
  List,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import { generatePdfDocument } from "./v1FormResponsePDF";

function DownloadResponsePDF({ formResponse, language, contact }) {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      await generatePdfDocument(formResponse, language, contact);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };
  return (
    <Button
      primary
      loading={loading}
      size="tiny"
      style={{ marginLeft: "auto" }}
      onClick={handleDownload}
    >
      Download PDF
    </Button>
  );
}

const answerByType = (answer, question, log) => {
  switch (answer.type) {
    case "text":
    case "TextInput":
    case "TextBox":
      return answer.text;
    case "choice":
    case "RadioButtonGroup":
      return answer.choice.label;
    case "choices":
      return answer.choices.labels;
    case "email":
      return answer.email;
    case "url":
      return (
        <a href={answer.url} style={{ wordBreak: "break-word" }}>
          {answer.url}
        </a>
      );
    case "file_url":
      return (
        <a href={answer.file_url} style={{ wordBreak: "break-word" }}>
          {answer.file_url}
        </a>
      );
    case "Upload":
      return (
        <Card>
          {answer.uploadType === "Image" ? (
            <Image src={answer.url} wrapped ui={false} />
          ) : null}
          <Card.Content>
            <a href={answer.url} style={{ wordBreak: "break-word" }}>
              {answer.uploadFileName}
            </a>
          </Card.Content>
        </Card>
      );
    case "boolean":
      return answer.boolean ? "True" : "False";
    case "number":
      return answer.number;
    case "date":
      return answer.date;
    case "DatePicker":
      return answer.choice.label;
    case "payment":
      return answer.payment;
    case "Table": {
      let headers = question.table_headers.map(header => header.label);
      let colors = [
        "orange",
        "yellow",
        "olive",
        "green",
        "teal",
        "blue",
        "violet",
        "purple",
        "pink",
        "brown",
      ];
      return (
        <Table color={colors[Math.floor(Math.random() * 10)]}>
          <Table.Header>
            <Table.Row>
              {headers.map((h, i) => (
                <Table.HeaderCell key={i} textAlign="center">
                  {h}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {answer.table_rows.map((row, index) => (
              <Table.Row key={index}>
                {question.table_headers.map((h, i) => {
                  return (
                    <Table.Cell key={i} style={{ padding: "5px" }}>
                      {row[h.colID] || ""}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    }
    case "TableV1": {
      let headers = answer.data.columns.filter(
        data => data.dataPath !== "delete"
      );
      let rows = answer.data.rows;
      let colors = [
        "orange",
        "yellow",
        "olive",
        "green",
        "teal",
        "blue",
        "violet",
        "purple",
        "pink",
        "brown",
      ];
      return (
        <Table
          color={colors[Math.floor(Math.random() * 10)]}
          size="small"
          stackable
        >
          <Table.Header>
            <Table.Row>
              {headers.map(h => (
                <Table.HeaderCell key={h.dataPath} textAlign="center">
                  {h.label}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map((row, index) => (
              <Table.Row key={index}>
                {headers.map(h => {
                  return (
                    <Table.Cell key={h.dataPath} style={{ padding: "5px" }}>
                      {row[h.dataPath] || ""}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    }
    case "PleteoUpload":
      return <PleteoUpload activityLogId={answer.activityLogId} />;
    case "CheckboxButtonGroup":
      return (
        <List>
          {answer.checked !== null
            ? answer.checked.map(x => <List.Item key={x}>{x}</List.Item>)
            : "---"}
        </List>
      );
    case "MultiRadioButtonGroup":
      return (
        <List>
          {answer.choices.map(radio => (
            <List.Item
              key={radio.id}
            >{`${radio.title}: ${radio.value}`}</List.Item>
          ))}
        </List>
      );
    case "CheckboxMultiInput":
      return (
        <List>
          {answer.multiChecked.checkedValues.map(checkedValue => (
            <List.Item key={checkedValue.id}>{`${checkedValue.title} ${
              checkedValue.value ? " : " : " "
            } ${checkedValue.value || " "}`}</List.Item>
          ))}
        </List>
      );
    case "Dropdown":
      return answer.value;
    case "RemediationRepeater":
      return (
        <Card.Group fluid>
          {answer.data.map(item => (
            <Card key={item._id} fluid>
              <Card.Content>
                <Card.Header>{item.deviceName}</Card.Header>
                <Card.Meta>Error: {item.errorMessage}</Card.Meta>
                <Card.Description>
                  Action Required: {item.actionRequired}
                </Card.Description>
              </Card.Content>
              <Card.Content>
                <Segment>
                  <strong>{item.responseChoice}</strong>
                </Segment>
                <Segment>
                  <Label color="blue" ribbon>
                    User Commented:
                  </Label>
                  {item.customResponse || "nothing"}
                </Segment>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      );
    /*"_id":invoiceNumbers[i],
						"invoiceNumber":invoiceNumbers[i],
						"invoiceDate":invoicedates[i],
						"dealNumber":casdealnumbers[i],
						"shippingDocument": {},
						"salesInvoice": {},
						"customerPO": {}*/
    case "DocumentationRepeater":
      return (
        <Card.Group fluid>
          {answer.data.map(item => (
            <Card key={item._id} fluid>
              <Card.Content>
                <Card.Header>Invoice Number: {item.invoiceNumber}</Card.Header>
                <Card.Meta>{item.invoiceDate}</Card.Meta>
                <Card.Description>
                  Deal Number: {item.dealNumber}
                </Card.Description>
              </Card.Content>
              <Card.Content>
                <Segment color="blue">
                  <strong>Shipping Document: </strong>
                  {!!item.shippingDocument.url ? (
                    <a
                      href={item.shippingDocument.url}
                      style={{ wordBreak: "break-word" }}
                    >
                      {item.shippingDocument.uploadFileName}
                    </a>
                  ) : (
                    "no file"
                  )}
                </Segment>
              </Card.Content>
              <Card.Content>
                <Segment color="violet">
                  <strong>Sales Invoice: </strong>
                  {!!item.salesInvoice.url ? (
                    <a
                      href={item.salesInvoice.url}
                      style={{ wordBreak: "break-word" }}
                    >
                      {item.salesInvoice.uploadFileName}
                    </a>
                  ) : (
                    "no file"
                  )}
                </Segment>
              </Card.Content>
              <Card.Content>
                <Segment color="purple">
                  <strong>Customer PO: </strong>
                  {!!item.customerPO.url ? (
                    <a
                      href={item.customerPO.url}
                      style={{ wordBreak: "break-word" }}
                    >
                      {item.customerPO.uploadFileName}
                    </a>
                  ) : (
                    "no file"
                  )}
                </Segment>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      );
    default:
      return "Unknown Answer Type";
  }
};

const WixFormResponseV1 = (form_response, language, contact, ids) => {
  const answers = form_response.answers;
  const qAndA = form_response.definition.fields
    .filter(q => {
      const answer = answers.filter(a => {
        return a.field.id === q.id;
      });
      return answer.length > 0;
    })
    .map(q => {
      const answer = answers.filter(a => {
        return a.field.id === q.id;
      });
      return {
        type: answer[0].type,
        question: q.title,
        answer: answerByType(answer[0], q),
        id: q.id,
      };
    });
  const agentName = form_response.hidden.agent_name;
  return (
    <React.Fragment>
      <Card fluid>
        <Card.Content header={form_response.definition.title} />
        <Card.Content>
          <Table basic="very" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Questions</Table.HeaderCell>
                <Table.HeaderCell>Answers</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {qAndA.map((e, index) =>
                e.type === "Table" ? (
                  <React.Fragment key={e.id}>
                    {/* HEADER */}
                    <Table.Row key={e.id + "title"}>
                      <Table.Cell colSpan={2}>
                        <Header as="h4">
                          <Header.Content>
                            {e.question || "Table"}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    {/* BODY */}
                    <Table.Row key={e.id + "body"}>
                      <Table.Cell colSpan={2}>{e.answer}</Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                ) : (
                  <Table.Row key={index}>
                    <Table.Cell width={6}>
                      <Header as="h4">
                        <Header.Content>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: e.question,
                            }}
                          />
                          <Header.Subheader>{e.type}</Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell width={6}>{e.answer}</Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </Card.Content>
        <Card.Content extra>
          <p>Submitted at: {formatDate(form_response.submitted_at)}</p>
          {agentName && <p>Submitted by: {agentName}</p>}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <DownloadResponsePDF
              formResponse={form_response}
              language={language}
              contact={contact}
            />
          </div>
        </Card.Content>
      </Card>
      {answers.some(
        event => event && ["Upload", "PleteoUpload"].includes(event.type)
      ) && <DownloadAttachments ids={ids} />}
    </React.Fragment>
  );
};

export default WixFormResponseV1;

import { applyFilters, doRequest } from "./helpers";

export default class VoicemailService {
  static async getVoicemails(filters) {
    let path = `/voicemail/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getVoicemailsForFilter() {
    let path = `/voicemail/for_filters`;
    return doRequest("GET", path);
  }

  static async getVoicemail(id) {
    let path = `/voicemail/${id}`;
    return doRequest("GET", path);
  }

  static async createVoicemail(data) {
    return doRequest("POST", "/voicemail/", { data });
  }

  static editVoicemail(id, data) {
    return doRequest("PUT", `/voicemail/${id}`, { data });
  }

  static deleteVoicemail(id) {
    return doRequest("DELETE", `/voicemail/${id}`);
  }
}

import { applyFilters, doRequest } from "./helpers";

export default class TagCategoryService {
  static async getTagCategoriesTable(tableName = null) {
    let path = `/tag_category/table`;
    path = applyFilters(tableName, path);
    return doRequest("GET", path);
  }

  static async createTagCategory(data) {
    let path = `/tag_category/`;
    return doRequest("POST", path, { data });
  }

  static async getTagCategoryForFilters(filters = null) {
    let path = "/tag_category/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async editTagCategory(id, data) {
    let path = `/tag_category/${id}`;
    return doRequest("PUT", path, { data });
  }

  static async deleteTagCategory(id) {
    let path = `/tag_category/${id}`;
    return doRequest("DELETE", path);
  }
}

import React, { Component } from "react";
import { Button, Header, Modal, Dimmer, Loader } from "semantic-ui-react";
import { parseWixForm } from "../../../components/wixForms/helpers";
import PropTypes from "prop-types";
import FormResponseService from "../../../services/FormResponse";

export default class FormResponsesListModal extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      responses: null,
    };
  }

  handleOpen = async () => {
    this.setState({
      open: true,
      loading: true,
    });
    const resp = await FormResponseService.getFormResponses({
      campaign_id: this.props.campaignId,
      entity_id: this.props.entityId,
      sort_dir: "desc",
      sort_by: "id",
      per_page: 100,
    });
    this.setState({
      responses: resp.data,
      loading: false,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      responses: null,
    });
  };

  render() {
    return (
      <Modal
        size="fullscreen"
        trigger={
          <Button size="tiny" onClick={this.handleOpen}>
            Responses
          </Button>
        }
        open={this.state.open}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>Responses</Modal.Header>
        <Modal.Content>
          <Dimmer inverted active={this.state.loading}>
            <Loader content="Loading" />
          </Dimmer>
          {!!this.state.responses
            ? this.state.responses.map((formResponse, index) => {
                let responses = [];
                if (
                  formResponse.info.typeform_events &&
                  formResponse.info.typeform_events.length
                ) {
                  formResponse.info.typeform_events.forEach(event =>
                    responses.push(event)
                  );
                }
                if (
                  formResponse.info.custom_form_events &&
                  formResponse.info.custom_form_events.length
                ) {
                  formResponse.info.custom_form_events.forEach(event =>
                    responses.push(event)
                  );
                }
                return (
                  <div key={index}>
                    <Header
                      as="h4"
                      icon="user"
                      content={formResponse.contact.email}
                      style={{ marginTop: "10px" }}
                    />
                    {parseWixForm(
                      responses[0],
                      formResponse.id,
                      formResponse.email_url.language,
                      formResponse.contact
                    )}
                  </div>
                );
              })
            : null}
        </Modal.Content>
      </Modal>
    );
  }
}

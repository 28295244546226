import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "semantic-ui-react";
import PermissionForm from "./PermissionForm";

const PermissionModal = forwardRef(({ onFilter }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useState({});

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = filters => {
    setFilters(filters);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      size="small"
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      className="permission-modal"
    >
      <Modal.Header content="Filter Permissions" />
      <Modal.Content>
        <PermissionForm
          filters={filters}
          onFilter={onFilter}
          onSuccess={handleClose}
        />
      </Modal.Content>
    </Modal>
  );
});

export default PermissionModal;

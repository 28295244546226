const CONFIG = {
  IS_PHONE_ENABLED: process.env.REACT_APP_IS_PHONE_ENABLED === "True",
  LOGO_SRC: process.env.REACT_APP_LOGO_SRC,
  HEADER_LOGO_SRC: process.env.REACT_APP_HEADER_LOGO_SRC,
  HEADER_LOGO_W: process.env.REACT_APP_HEADER_LOGO_W,
  HEADER_LOGO_H: process.env.REACT_APP_HEADER_LOGO_H,
  CC_EMAIL: process.env.REACT_APP_CC_EMAIL,
  CONSTANT_CONTACT_ENABLED:
    process.env.REACT_APP_CONSTANT_CONTACT_ENABLED === "True",
  UNLAYER_PROJECT_ID: process.env.REACT_APP_UNLAYER_PROJECT_ID,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  ENABLE_PIRACY: process.env.REACT_APP_ENABLE_PIRACY === "True",
  MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE === "true",
};

export const MAINTENANCE_MODE = CONFIG.MAINTENANCE_MODE;
export default CONFIG;

import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Header, Segment, Button, Divider } from "semantic-ui-react";

import PriorityLabel from "./PriorityLabel";

import "./../../../styles/sidebar.scss";
import "./Sidebar.scoped.scss";
import taskConstants from "../../../constants/Task";
import UserService from "../../../services/User";
import CampaignService from "../../../services/Campaign";
import { checkIsAuthorized, capitalize } from "../../../components/helpers";
import ACL_RELATIONSHIPS from "../../../acl-relationships";

import withRoleCheck from "../../../components/hocs/withRoleCheck";

const Sidebar = ({
  filterParams,
  setFilterParams,
  assignees,
  campaigns,
  setAssignees,
  setCampaigns,
}) => {
  const navigate = useNavigate();
  const fetchAssignees = useCallback(async () => {
    if (!checkIsAuthorized([ACL_RELATIONSHIPS.user.read])) {
      return;
    }
    let assignees = await UserService.getUsers();
    assignees = assignees.map(({ id, full_name }) => ({
      id,
      name: full_name,
    }));
    setAssignees(assignees);
  }, []);

  const fetchCampaigns = useCallback(async () => {
    if (!checkIsAuthorized([ACL_RELATIONSHIPS.campaign.read])) {
      return;
    }
    let campaigns = await CampaignService.getCampaignsForFilters();
    campaigns = campaigns.map(({ id, name }) => ({
      id,
      name,
    }));
    setCampaigns(campaigns);
  }, []);

  useEffect(() => {
    fetchAssignees();
    fetchCampaigns();
  }, [fetchAssignees, fetchCampaigns]);

  const isSelected = (type, id) => {
    const title = capitalize(type);
    const filterParam = filterParams[title];
    return filterParam && filterParam.some(({ value }) => value === id);
  };

  const toggleSelected = (type, id, name) => {
    const title = capitalize(type);
    let params = filterParams[title] || [];
    const index = params.findIndex(({ value }) => value === id);
    if (index === -1) {
      params = [...params, { key: id, text: name, value: id }];
    } else {
      params = [...params.slice(0, index), ...params.slice(index + 1)];
    }
    const newFilterParams = { ...filterParams, [title]: params };
    if (params.length === 0) {
      delete newFilterParams[title];
    }
    setFilterParams(newFilterParams);
  };

  const FilterButton = ({ type, id, name, priority }) => {
    const selected = isSelected(type, id);
    const handleClick = () => {
      toggleSelected(type, id, name);
    };
    if (priority) {
      return (
        <PriorityLabel
          priority={priority}
          onClick={handleClick}
          selected={selected}
        />
      );
    }
    return (
      <Button
        className={`textLink ${selected ? "selected" : ""}`}
        onClick={handleClick}
        content={name}
      />
    );
  };

  const SequencesButton = withRoleCheck(
    () => (
      <Button
        compact
        size="tiny"
        color="orange"
        content="Sequences"
        onClick={() => navigate("/sequences")}
      />
    ),
    [ACL_RELATIONSHIPS.sequence.read]
  );

  return (
    <div className="tasks sidebar">
      <aside>
        <Segment basic className="top">
          <SequencesButton />
        </Segment>
        <Segment basic className="vertical">
          <Header size="small">Priority</Header>
          {taskConstants.priorities.map((priority, index) => (
            <FilterButton
              key={index}
              type="priorities"
              id={priority.id}
              name={priority.name}
              priority={priority}
            />
          ))}
        </Segment>
        {assignees.length > 0 && (
          <Segment basic className="vertical">
            <Header size="small">Assignee</Header>
            {assignees.map(({ id, name }, index) => (
              <FilterButton key={index} type="assignees" id={id} name={name} />
            ))}
          </Segment>
        )}
        {campaigns.length > 0 && (
          <Segment basic className="vertical">
            <Header size="small">Campaigns</Header>
            {campaigns.map(({ id, name }, index) => (
              <FilterButton key={index} type="campaigns" id={id} name={name} />
            ))}
          </Segment>
        )}
        <Divider hidden />
      </aside>
    </div>
  );
};

export default Sidebar;

import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "semantic-ui-react";
import BulkUpdateCategoryForm from "./BulkUpdateCategoryForm";

const BulkUpdateCategoryModal = forwardRef(({ refreshTable }, ref) => {
  const [open, setOpen] = useState(false);
  const [tagIds, setTagIds] = useState([]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = tagIds => {
    setTagIds(tagIds);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    handleClose();
    refreshTable();
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header content="Bulk Update Category" />
      <Modal.Content>
        <BulkUpdateCategoryForm tagIds={tagIds} onSuccess={handleSuccess} />
      </Modal.Content>
    </Modal>
  );
});

export default BulkUpdateCategoryModal;

import React, { Component } from "react";
import Datetime from "react-datetime";
import { Form, Input } from "semantic-ui-react";
import store from "store";
import CustomFieldForm from "../../../components/forms/CustomFieldForm";
import {
  checkIsCustomFieldsReady,
  compareCreatedAt,
  getCustomFieldsForSubmit,
  keyIsUnique,
} from "../../../components/helpers";
import RuvixxForm from "../../../components/RuvixxForm";
import CONSTANTS from "../../../constants/Constants";
import { startCase } from "../../../helpers/string";

import CampaignService from "../../../services/Campaign";
import CampaignStatusPresetService from "../../../services/CampaignStatusPreset";
import CustomFieldService from "../../../services/CustomField";
import EmailUrlService from "../../../services/EmailUrls";
import RegionService from "../../../services/Regions";
import UserService from "../../../services/User";
import EmailTemplateService from "../../../services/EmailTemplate";
import "../../../styles/datetime.scss";
import EmailProviderService from "../../../services/EmailProviders";
import campaignConstants from "constants/Campaign";
import RevenueOpportunityStatusPresetService from "../../../services/RevenueOpportunityStatusPreset";

class CampaignForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      status: "",
      assigned: "",
      start: "",
      end: "",
      description: "",
      emailService: null, // TODO: Update to Sendgrid in future. Default to mailgun for now.
      emailType: null,
      emailServices: [],
      emailTypes: [],
      emailTemplates: [],
      selectedTemplates: [],
      form: "",
      forms: [],
      region: "",
      regions: [],
      searchQuery: null,
      custom: [],
      customFieldConfigs: [],
      users: [],
      campaignStatusPresets: [],
      campaignStatusPreset: null,
      revenueStatusPresets: [],
      revenueStatusPreset: null,
      limitEmailTemplateToggle: false,
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSelect = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSearchChange = async (e, { searchQuery }) => {
    this.setState({ searchQuery });
    await this.fetchUsers(searchQuery);
  };

  handleSubmit = async e => {
    const {
      name,
      status,
      assigned,
      start,
      end,
      description,
      region,
      form,
      emailService,
      emailType,
      custom,
      campaignStatusPreset,
      caseCampaignStatusPreset,
      revenueStatusPreset,
      customFieldConfigs,
      selectedTemplates,
    } = this.state;
    const cust = getCustomFieldsForSubmit(customFieldConfigs, custom);
    try {
      await CampaignService.createCampaign({
        name: name,
        status: status,
        assigned_user_id: assigned,
        start_time: start,
        end_time: end || null,
        description: description,
        region: region,
        form: form,
        emailService: emailService,
        emailType: emailType,
        custom: cust,
        campaignStatusPreset: campaignStatusPreset || null,
        caseCampaignStatusPreset: caseCampaignStatusPreset || null,
        emailTemplates: selectedTemplates,
        revenueStatusPreset,
      });
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  isUnique = key => {
    return keyIsUnique({
      key: key,
      array: this.state.custom,
    });
  };

  handleStart = moment => {
    this.setState({ start: moment });
  };

  handleEnd = moment => {
    this.setState({ end: moment });
  };

  fetchUsers = async query => {
    let users;
    if (query) {
      users = await UserService.search(query);
    } else {
      users = await UserService.getUsers();
    }
    this.setState({ users });
  };

  fetchRegions = async () => {
    const rawRegions = await RegionService.getRegions();
    const regions = rawRegions.map(region => ({
      text: region.name,
      key: region.id,
      value: region.id,
    }));
    this.setState({ regions });
  };

  fetchForms = async () => {
    let forms = await EmailUrlService.getForms();
    forms.sort(compareCreatedAt);
    forms = forms.map(forms => ({
      text: forms.name,
      key: forms.id,
      value: forms.id,
    }));
    this.setState({ forms });
  };

  fetchEmailServices = async () => {
    const emailServices = await EmailProviderService.getEmailProviders(
      true,
      true
    );
    this.setState({ emailServices });
  };

  fetchEmailTypes = async () => {
    const emailTypes = Object.keys(CONSTANTS.EMAIL_TYPES).map(key => ({
      key: CONSTANTS.EMAIL_TYPES[key],
      value: CONSTANTS.EMAIL_TYPES[key],
      text: startCase(key.toLowerCase()),
    }));
    this.setState({ emailTypes });
  };

  fetchEmailTemplates = async () => {
    const emailTemplates =
      await EmailTemplateService.getEmailTemplatesForFilters();
    const templateOptions = emailTemplates.map(template => ({
      text: template.name,
      key: template.id,
      value: template.id,
    }));
    this.setState({ emailTemplates: templateOptions });
  };

  fetchCampaignStatusPresets = async () => {
    const rawPresets =
      await CampaignStatusPresetService.getCampaignStatusPresets();
    const campaignStatusPresets = rawPresets.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
    this.setState({ campaignStatusPresets });
  };

  fetchCustomFieldConfigs = async () => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel("Campaign");
    this.setState({ customFieldConfigs });
  };

  handleUpdateCustomFieldConfigs = customFieldConfigs => {
    this.setState({ customFieldConfigs });
    this.handleUpdateCustomFields(
      customFieldConfigs
        .filter(cfc => cfc.required || cfc.selected)
        .map(cfc => ({ key: cfc.label, value: cfc.value }))
    );
  };

  handleUpdateCustomFields = customFields => {
    this.setState({ custom: customFields });
  };

  handleTemplatesSelect = (e, { value }) => {
    this.setState({ selectedTemplates: value });
  };

  fetchRevenueStatusPresets = async () => {
    const response =
      await RevenueOpportunityStatusPresetService.getCampaignStatusPresets();
    const revenueStatusPresets = response.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
    this.setState({ revenueStatusPresets });
  };

  componentDidMount() {
    this.fetchEmailServices(); // don't await
    this.fetchEmailTypes(); // don't await
    this.fetchEmailTemplates(); // don't await
    this.fetchForms(); // don't await
    this.fetchRegions(); // don't await
    this.fetchUsers(); // don't await
    this.fetchCampaignStatusPresets(); // don't await
    this.fetchCustomFieldConfigs();
    this.fetchRevenueStatusPresets();
  }

  render() {
    const {
      name,
      assigned,
      start,
      custom,
      customFieldConfigs,
      users,
      regions,
      forms,
      emailService,
      emailServices,
      emailType,
      emailTypes,
      campaignStatusPresets,
      revenueStatusPresets,
      revenueStatusPreset,
      emailTemplates,
      selectedTemplates,
      limitEmailTemplateToggle,
    } = this.state;
    const userOptions = users.map(user => {
      return { key: user.id, text: user.full_name, value: user.id };
    });

    const statusOptions = campaignConstants.status.map(status => {
      return { key: status.id, text: status.name, value: status.id };
    });
    const { timeZone } = store.get("userAuth");

    return (
      <RuvixxForm
        ready={
          !!name &&
          !!assigned &&
          !!start &&
          !!emailService &&
          !!emailType &&
          checkIsCustomFieldsReady(customFieldConfigs)
        }
        onSubmit={this.handleSubmit}
        onSuccess={this.props.onSuccess}
      >
        <Form.Input
          inline
          name="name"
          label="Name"
          required
          onChange={this.handleChange}
        />
        <Form.Select
          inline
          required
          label="Status"
          name="status"
          onChange={this.handleSelect}
          search
          options={statusOptions}
        />
        <Form.Select
          inline
          required
          label="Assignee"
          name="assigned"
          onChange={this.handleSelect}
          onSearchChange={this.handleSearchChange}
          search
          options={userOptions}
        />
        <Form.Group>
          <Form.Field required>
            <label>Start Time</label>
            <Datetime
              closeOnSelect={true}
              onChange={this.handleStart}
              displayTimeZone={timeZone}
              renderInput={props => (
                <Input icon="large calendar outline" {...props} />
              )}
              dateFormat={"YYYY-MM-DD"}
            />
          </Form.Field>
          <Form.Field>
            <label>End Time</label>
            <Datetime
              closeOnSelect={true}
              onChange={this.handleEnd}
              displayTimeZone={timeZone}
              renderInput={props => (
                <Input icon="large calendar outline" {...props} />
              )}
              dateFormat={"YYYY-MM-DD"}
            />
          </Form.Field>
        </Form.Group>
        <Form.Select
          inline
          label="Form"
          clearable
          name="form"
          search
          options={forms}
          onChange={this.handleSelect}
        />
        <Form.Checkbox
          className="limit-email-templates-toggle"
          toggle
          checked={limitEmailTemplateToggle}
          label="Limit Email Templates"
          onChange={(_, { checked }) =>
            this.setState({
              limitEmailTemplateToggle: checked,
              selectedTemplates: checked ? this.state.selectedTemplates : [],
            })
          }
          required
        />
        {limitEmailTemplateToggle && (
          <Form.Dropdown
            fluid
            multiple
            search
            selection
            label="Templates"
            name="selectedTemplates"
            options={emailTemplates}
            onChange={this.handleTemplatesSelect}
            value={selectedTemplates}
          />
        )}
        <Form.Select
          inline
          required
          label="Email Service"
          name="emailService"
          options={emailServices}
          onChange={this.handleSelect}
        />
        <Form.Select
          inline
          required
          label="Email Type"
          name="emailType"
          options={emailTypes}
          onChange={this.handleSelect}
        />
        <Form.Select
          inline
          label="Region"
          clearable
          name="region"
          search
          options={regions}
          onChange={this.handleSelect}
        />
        <Form.Select
          inline
          label="Campaign Status Preset"
          clearable
          name="campaignStatusPreset"
          search
          options={campaignStatusPresets}
          onChange={this.handleSelect}
        />
        <Form.Select
          inline
          label="Case Campaign Status Preset"
          clearable
          name="caseCampaignStatusPreset"
          search
          options={campaignStatusPresets}
          onChange={this.handleSelect}
        />
        <Form.Select
          clearable
          inline
          search
          name="revenueStatusPreset"
          label="Revenue Opportunity Status Preset"
          options={revenueStatusPresets}
          value={revenueStatusPreset}
          onChange={this.handleSelect}
        />
        <Form.Input
          fluid
          name="description"
          label="Description"
          onChange={this.handleChange}
        />
        {customFieldConfigs && (
          <CustomFieldForm
            modelType="Campaign"
            customFields={custom}
            customFieldConfigs={customFieldConfigs}
            onUpdateCustomFieldConfigs={this.handleUpdateCustomFieldConfigs}
          />
        )}
      </RuvixxForm>
    );
  }
}

export default CampaignForm;

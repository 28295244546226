import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { Modal, Header } from "semantic-ui-react";
import OptionsForm from "./OptionsForm";

const OptionsModal = forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [question, setQuestion] = useState();
  const [index, setIndex] = useState();
  const onUpdateRef = useRef();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = (question, index, onUpdate) => {
    setQuestion(question);
    setIndex(index);
    setModalOpen(true);
    onUpdateRef.current = onUpdate;
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = () => {
    handleClose();
  };

  const updateQuestion = question => {
    if (props.questions?.length > 0) {
      props.updateQuestionAtIndex(index, question);
    } else if (question) {
      onUpdateRef.current(question);
    }
  };

  return (
    <Modal open={modalOpen} onClose={handleClose} size="tiny" closeIcon>
      <Header content="Edit Options" />
      <Modal.Content>
        <OptionsForm
          question={question}
          questionIndex={index}
          {...props}
          onSuccess={handleSuccess}
          updateQuestion={updateQuestion}
        />
      </Modal.Content>
    </Modal>
  );
});

export default OptionsModal;

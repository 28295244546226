import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "semantic-ui-react";
import CategoricalTypeOptionsForm from "./CategoricalTypeOptionsForm";

const CategoricalTypeOptionsModal = forwardRef(({ doChange }, ref) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState();
  const [fieldOptions, setFieldOptions] = useState({});

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = (index, fieldOptions) => {
    setIndex(index);
    setFieldOptions({ type: "single", ...fieldOptions });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = data => {
    handleClose();
    doChange(index, data);
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header content="Categorical Type Options" />
      <Modal.Content>
        <CategoricalTypeOptionsForm
          fieldOptions={fieldOptions}
          setFieldOptions={setFieldOptions}
          onSuccess={handleSuccess}
        />
      </Modal.Content>
    </Modal>
  );
});

export default CategoricalTypeOptionsModal;

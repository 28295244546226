import { InfoWindow, Marker } from "@react-google-maps/api";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { List } from "semantic-ui-react";
import { CASE_TIERS } from "../constants/Case";

// faLocationPin
// https://github.com/FortAwesome/Font-Awesome/blob/65f4bdb5025759ba25cd3432568de288b6a67527/js-packages/%40fortawesome/free-solid-svg-icons/faLocationPin.js
const height = 512;
const width = 384;
const svgPathData =
  "M384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384z";

const MapMarker = props => {
  const [infoOpen, setInfoOpen] = useState(false);

  const handleInfoOpen = () => setInfoOpen(true);
  const handleInfoClose = () => setInfoOpen(false);
  let cases = {};
  if (props.marker.type === "event") {
    props.marker.data.machines.forEach((machine, index) => {
      const caseData = props.marker.data.cases.find(
        c => c.case_id === props.marker.data.machines[index].case_id
      );
      cases[caseData.id] = cases[caseData.id] || { ...caseData };
      cases[caseData.id]["machines"] = cases[caseData.id]["machines"] || {};
      cases[caseData.id].machines[machine.id] = {
        ...machine,
      };
    });
  }

  let distances = props.marker.data?.distances || [];
  distances.sort((a, b) => a.distance - b.distance);

  return (
    <Marker
      icon={{
        path: svgPathData,
        anchor: new window.google.maps.Point(width / 2, height / 2),
        fillColor: props.marker.color,
        fillOpacity: 1.0,
        scale: 0.075,
      }}
      onClick={handleInfoOpen}
      onMouseOver={props.marker.type === "address" ? handleInfoOpen : null}
      onMouseOut={props.marker.type === "address" ? handleInfoClose : null}
      key={props.marker.title}
      visible={props.marker.visible}
      title={props.marker.title}
      position={props.marker.position}
      clusterer={props.marker.cluster ? props.clusterer : null}
    >
      {infoOpen && (
        <InfoWindow
          position={{
            lat: props.marker.position.lat,
            lng: props.marker.position.lng,
          }}
          onCloseClick={handleInfoClose}
        >
          <div>
            {props.marker.type === "event" ? (
              Object.values(cases).map(caseData => {
                return (
                  <List>
                    <List.Item>
                      <List.Icon name="folder open" />
                      <List.Header>
                        <Link
                          target="_blank"
                          to={`/cases/${caseData.id}/machines?page=1&per_page=20`}
                        >
                          Case: {`${caseData.name} - ${caseData.case_id}`}
                        </Link>
                      </List.Header>
                      <List.Description>Machines</List.Description>
                      <List.List>
                        {Object.values(caseData.machines).map(machine => {
                          return (
                            <List.Item>
                              <List.Icon name="server" />
                              <List.Content>
                                <List.Header>
                                  <Link
                                    target="_blank"
                                    to={`/cases/${caseData.id}/events?page=1&per_page=20&machine_id=${machine.machine_id}`}
                                  >
                                    Machine: {`${machine.machine_id}`}
                                  </Link>
                                </List.Header>
                                <List.Description>
                                  Tier:{" "}
                                  {machine.case_tier === CASE_TIERS.UNUSABLE
                                    ? "Unusable"
                                    : machine.case_tier}
                                </List.Description>
                              </List.Content>
                            </List.Item>
                          );
                        })}
                      </List.List>
                    </List.Item>
                    {distances.map(location => {
                      return (
                        <List.Item>
                          <span>
                            {location.distance.toFixed(2)} meters from{" "}
                            {location.address}
                          </span>
                        </List.Item>
                      );
                    })}
                  </List>
                );
              })
            ) : (
              <div>{props.marker.title}</div>
            )}
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};
export default MapMarker;

import React, { useState } from "react";
import { Button, Dropdown, Header, Modal } from "semantic-ui-react";
import CampaignActivityForm from "./CampaignActivityForm";

export default function CampaignActivityModal(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const onSuccess = async => {
    handleClose();
    props.onSuccess();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        props.button ? (
          <Button
            content="New Campaign Activity"
            onClick={handleOpen}
            size="tiny"
            className="item-adder"
          />
        ) : (
          <Dropdown.Item
            className="primary"
            content={
              props.ccCampaignId
                ? "Edit Campaign Activity"
                : "New Campaign Activity"
            }
            icon={props.ccCampaignId ? "edit" : null}
            onClick={handleOpen}
          />
        )
      }
    >
      <Header
        content={
          props.ccCampaignId
            ? "Edit Campaign Activity"
            : "New Campaign Activity"
        }
      />
      <Modal.Content>
        <CampaignActivityForm
          onSuccess={onSuccess}
          ccCampaignId={props.ccCampaignId}
        />
      </Modal.Content>
    </Modal>
  );
}

import { doRequest, doGenericRequest } from "./helpers";

export default class Attachment {
  static async uploadFileMock(file, onProgress) {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    let shouldContinue = true;
    const total = 1024;
    let loaded = 0;
    onProgress({ loaded, total });
    const interval = setInterval(() => {
      loaded += 8;
      onProgress({ loaded, total });
      if (loaded === total) {
        clearInterval(interval);
        shouldContinue = false;
      }
    }, 25);
    while (shouldContinue) {
      await delay(500);
    }
    return "ok";
  }

  static async uploadFile(file, onProgress, cancelToken) {
    const s3Config = await doRequest("POST", "/attachment/upload/s3", {
      data: {
        file_name: file.name,
        file_type: file.type,
      },
    });

    let formData = new FormData();
    Object.keys(s3Config.fields).forEach(k => {
      formData.set(k, s3Config.fields[k]);
    });
    formData.set("file", file);
    formData.set(
      "Content-Type",
      file.type === "audio/mp3" ? "audio/mpeg" : file.type
    );

    const uploadResponse = await doGenericRequest("POST", s3Config.url, {
      data: formData,
      onUploadProgress: onProgress,
      cancelToken,
    });

    const parser = new DOMParser(),
      xmlDoc = parser.parseFromString(uploadResponse, "text/xml"),
      s3Key = xmlDoc.getElementsByTagName("Key")[0].textContent;

    return {
      s3_key: s3Key,
      file_name: file.name,
      file_size: file.size,
      file_content_type: file.type,
    };
  }

  static async delete(id) {
    return doRequest("DELETE", `/attachment/${id}`);
  }
}

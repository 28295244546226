import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import CustomFieldService from "../../../services/CustomField";

export default function CustomFieldConfigForm(props) {
  let customFieldConfig = props.customFieldConfig;
  const [formData, setFormData] = useState({
    label: "",
    model_type: null,
    field_type: null,
    description: "",
    required: false,
    field_options: {},
  });
  const [customFieldId, setCustomFieldId] = useState(null);
  const [ready, setReady] = useState(false);

  const modelOptions = [
    {
      id: "Contact",
      value: "Contact",
      text: "Contact",
    },
    {
      id: "Entity",
      value: "Entity",
      text: "Entity",
    },
    {
      id: "Campaign",
      value: "Campaign",
      text: "Campaign",
    },
    {
      id: "CampaignTarget",
      value: "CampaignTarget",
      text: "Campaign Target",
    },
    {
      id: "CampaignTargetContact",
      value: "CampaignTargetContact",
      text: "Campaign Target Contact",
    },
    {
      id: "Contract",
      value: "Contract",
      text: "Contract",
    },
    {
      id: "Case",
      value: "Case",
      text: "Case",
    },
    {
      id: "RevenueOpportunity",
      value: "RevenueOpportunity",
      text: "Revenue Opportunity",
    },
    {
      id: "EmailTemplate",
      value: "EmailTemplate",
      text: "Email Template",
    },
  ];

  const fieldTypeOptions = [
    {
      id: "text",
      value: "text",
      text: "Text",
    },
    {
      id: "numeric",
      value: "numeric",
      text: "Numeric",
    },
    {
      id: "boolean",
      value: "boolean",
      text: "Boolean",
    },
    {
      id: "select",
      value: "select",
      text: "Categorical",
    },
    {
      id: "date",
      value: "date",
      text: "Date",
    },
    {
      id: "list",
      value: "list",
      text: "List",
    },
  ];

  const fieldOptions = [
    {
      id: "single",
      value: "single",
      text: "Single",
    },
    {
      id: "multi",
      value: "multi",
      text: "Multiple",
    },
  ];

  const handleChange = (event, { name, value, checked, type }) => {
    const formDataCopy = { ...formData };
    if (name == "field_options") {
      formDataCopy["field_options"]["options"] = value;
    } else {
      formDataCopy[name] = type === "checkbox" ? checked : value;
    }
    setFormData(formDataCopy);
  };

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    if (name == "select_type") {
      formDataCopy["field_options"]["type"] = value;
    } else {
      formDataCopy[name] = value;
      if (formDataCopy.field_type !== "select") {
        formDataCopy["field_options"] = {};
      }
    }
    // Hack to resolve issue of options unexpectedly turning to an array
    if (Array.isArray(formDataCopy["field_options"]["options"])) {
      formDataCopy["field_options"]["options"] = formDataCopy["field_options"][
        "options"
      ]
        .map(o => o.text)
        .join(", ");
    }
    setFormData(formDataCopy);
  };

  const makeFieldOptionsCsv = field_options => {
    let optionsStr = "";
    for (let option in field_options) {
      if (option != field_options.length - 1) {
        optionsStr += `${field_options[option].value}, `;
      } else {
        optionsStr += field_options[option].value;
      }
    }
    return optionsStr;
  };

  useEffect(() => {
    if (props.customFieldConfig) {
      setCustomFieldId(customFieldConfig["id"]);
      let formDataCopy = {
        label: customFieldConfig.label || "",
        model_type: customFieldConfig.model_type || null,
        field_type: customFieldConfig.field_type || null,
        description: customFieldConfig.description || "",
        required: customFieldConfig.required || false,
        field_options: { ...customFieldConfig.field_options } || "",
      };
      formDataCopy.field_options["options"] = makeFieldOptionsCsv(
        formDataCopy.field_options["options"]
      );
      setFormData(formDataCopy);
    }
  }, [props.customFieldConfig]);

  const handleSubmit = async e => {
    if (formData["field_options"]["options"]) {
      const optionsCsv = formData["field_options"]["options"];
      let optionObjects = optionsCsv;
      if (!Array.isArray(optionsCsv)) {
        optionObjects = optionsCsv.split(",").map(option => {
          return {
            id: option.trim(),
            value: option.trim(),
            text: option.trim(),
            name: option.trim(),
          };
        });
      }

      formData["field_options"]["options"] = optionObjects;
    }
    try {
      if (customFieldId) {
        await CustomFieldService.updateCustomFieldConfig(
          customFieldId,
          formData
        );
      } else {
        await CustomFieldService.createCustomFieldConfig(formData);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  useEffect(() => {
    if (formData.field_type !== "select") {
      setReady(false);
      if (
        formData.label &&
        formData.label.trim() !== "" &&
        formData.model_type &&
        formData.field_type
      ) {
        setReady(true);
      }
    } else {
      setReady(false);
      if (
        formData.label &&
        formData.label.trim() !== "" &&
        formData.model_type &&
        formData.field_type &&
        formData.field_options["type"] &&
        formData.field_options["options"]
      ) {
        setReady(true);
      }
    }
  }, [formData]);

  return (
    <RuvixxForm
      ready={ready}
      onSubmit={handleSubmit}
      onSuccess={props.onSuccess}
    >
      <Form.Checkbox
        toggle
        name="required"
        checked={formData.required}
        onChange={handleChange}
        label="Required"
      />
      <Form.Input
        inline
        name="label"
        label="Label"
        required
        value={formData.label}
        onChange={handleChange}
      />
      <Form.Select
        required
        inline
        name="model_type"
        label="Model Type"
        options={modelOptions}
        value={formData.model_type}
        onChange={handleSelect}
        disabled={props.customFieldConfig ? true : false}
      />
      <Form.Select
        required
        inline
        name="field_type"
        label="Field Type"
        options={fieldTypeOptions}
        value={formData.field_type}
        onChange={handleSelect}
      />
      {formData.field_type == "select" ? (
        <>
          <Form.Select
            required
            inline
            name="select_type"
            label="Categorical Select Type"
            options={fieldOptions}
            value={formData.field_options["type"]}
            onChange={handleSelect}
          />
          <Form.TextArea
            inline
            required
            name="field_options"
            label="Field Options"
            value={formData.field_options["options"]}
            placeholder="add your options as a comma separated value ex:(1,apple,blue)"
            onChange={handleChange}
          />
        </>
      ) : null}
      <Form.TextArea
        inline
        name="description"
        label="Description"
        value={formData.description}
        onChange={handleChange}
      />
    </RuvixxForm>
  );
}

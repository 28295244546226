import store from "store";
import { applyFilters, doRequest } from "./helpers";

const activityPaths = {
  Campaign: "/campaign/activity",
  Contact: "/contact/activity",
  Entity: "/entity/activity",
  Task: "/task/activity",
  CampaignTarget: "/campaign/target/activity",
  Case: "/case/activity",
  RevenueOpportunity: "/revenue_opportunity/activity",
};

export default class ActivityService {
  static async getCategories() {
    return doRequest("GET", "/activity/category/");
  }

  static async createCategory(name) {
    return doRequest("POST", "/activity/category/", { data: { name } });
  }

  static async editCategory(id, data) {
    return doRequest("PUT", `/activity/category/${id}`, { data });
  }

  static async deleteCategory(id) {
    return doRequest("DELETE", `/activity/category/${id}`);
  }

  static async getActivityLogs(modelId, modelType, filters = null) {
    let path = `${activityPaths[modelType]}/${modelId}`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getActivityLog(modelType, logId) {
    let path = `${activityPaths[modelType]}/log/${logId}`;
    return doRequest("GET", path);
  }

  static async createActivityLog(data) {
    let path = activityPaths[data.model_type];
    return doRequest("POST", path, { data });
  }

  static async hasNotes(modelId, modelType) {
    let path = `${activityPaths[modelType]}/has_notes/${modelId}`;
    return doRequest("GET", path);
  }

  static async createActivityLogForImport(campaignId, attachments) {
    const data = {
      model_id: campaignId,
      model_type: "Campaign",
      info: {
        comment: "Attached file was used for importing",
        is_import: true,
      },
      creator_user_id: store.get("userAuth").id,
      attachments: attachments,
    };
    return doRequest("POST", "/activity/", { data });
  }

  static async createActivityLogForAuditLogImport(campaignId, attachments) {
    const data = {
      model_id: campaignId,
      model_type: "Campaign",
      info: {
        comment: "Attached file is being used for importing audit logs",
        is_audit_log_import: true,
      },
      creator_user_id: store.get("userAuth").id,
      attachments: attachments,
    };
    return doRequest("POST", "/activity/", { data });
  }

  static async updateActivityLog(logId, data) {
    let path = `${activityPaths[data.model_type]}/log/${logId}`;

    return doRequest("PUT", path, { data });
  }

  static async deleteActivityLog(modelType, logId) {
    let path = `${activityPaths[modelType]}/log/${logId}`;
    return doRequest("DELETE", path);
  }

  static async search(query) {
    return doRequest("GET", `/activity/search/${query}`);
  }
}

import React from "react";
import { Select } from "semantic-ui-react";
import startCase from "lodash/startCase";

import "./NotificationTypeSelector.scoped.scss";

const NotificationTypeSelector = ({
  typeOptions,
  notificationType,
  setNotificationType,
}) => {
  const handleSelectNotificationType = (_, { value }) => {
    setNotificationType(value || null);
  };

  const notificationTypes = typeOptions.map(type => ({
    key: type,
    value: type,
    text: startCase(type),
  }));

  return (
    <Select
      className="notification-type-selector"
      placeholder="Filter by"
      clearable
      options={notificationTypes}
      value={notificationType}
      onChange={handleSelectNotificationType}
    />
  );
};

export default NotificationTypeSelector;

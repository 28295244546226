import React, { useState, useRef, useEffect } from "react";
import { Form } from "semantic-ui-react";
import AuthService from "../../services/Auth";
import RuvixxForm from "../../components/RuvixxForm";
import Validate from "./../../components/Validate";

function ResetPasswordForm({ onCancel, handleResetSuccess }) {
  const [email, setEmail] = useState(null);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successHeader, setSuccessHeader] = useState("");
  const [success, setSuccess] = useState(false);

  const handleChange = (event, data) => {
    setEmail(data.value);
  };

  const handleSubmit = async () => {
    if (!Validate.email(email)) {
      setError(true);
      setErrorMessage("Please enter a valid Email");
      setErrorTitle("Invalid Email");
    } else {
      try {
        let resp = await AuthService.sendPasswordResetEmail(email);
        handleResetSuccess("Check your email for the reset link");
        onCancel();
      } catch (e) {
        setError(true);
        setErrorMessage(e.message);
        setErrorTitle(e.title);
      }
    }
  };

  return (
    <RuvixxForm
      ready={email && !successMessage}
      onSubmit={handleSubmit}
      x
      error={error}
      errorMessage={errorMessage}
      errorTitle={errorTitle}
      successHeader={successHeader}
      successMessage={successMessage}
      success={success}
      cancelButtonText="Cancel"
      onCancel={onCancel}
    >
      <p style={{ marginTop: 0 }}>
        Enter your Email and we will send you a reset link.
      </p>
      <Form.Input
        inline
        name="email"
        label="Email"
        onChange={handleChange}
        required
      />
    </RuvixxForm>
  );
}

export default ResetPasswordForm;

import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

import AssignAgentsForm from "./AssignAgentsForm";

const AssignAgentsModal = forwardRef(({ onSuccess }, ref) => {
  const [open, setOpen] = useState(false);
  const [twilioPhoneNumberIds, setTwilioPhoneNumberIds] = useState([]);
  const [userIds, setUserIds] = useState([]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = (twilioPhoneNumberIds, userIds) => {
    setTwilioPhoneNumberIds(twilioPhoneNumberIds);
    setUserIds(userIds);
    setOpen(true);
  };

  const handleSuccess = () => {
    onSuccess();
    setOpen(false);
  };

  return (
    <Modal
      size="large"
      open={open}
      onClose={() => setOpen(false)}
      closeOnDimmerClick={false}
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      closeIcon
    >
      <Modal.Header>Assign Agents</Modal.Header>
      <Modal.Content>
        <AssignAgentsForm
          twilioPhoneNumberIds={twilioPhoneNumberIds}
          userIds={userIds}
          onSuccess={handleSuccess}
        />
      </Modal.Content>
    </Modal>
  );
});

AssignAgentsModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default AssignAgentsModal;

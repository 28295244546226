const questionTypes = {
  text: 1,
  textbox: 2,
  dropdown: 3,
  checkboxGroup: 4,
  radioButtonGroup: 5,
  section: 6,
  upload: 7,
};

const questionConstants = {
  questionTypes,
};

export default questionConstants;
export { questionTypes };

import "../../../styles/datetime.scss";

import moment from "moment-timezone";
import React, { useState } from "react";
import Datetime from "react-datetime";
import { Button, Header, Input, Modal, Checkbox } from "semantic-ui-react";

import { getUserTimeZone } from "../../../components/helpers";
import { CALL_DISPOSITION_IDS } from "../../../constants/Constants";

export default function CallbackModal({
  dispositionId,
  onSetDispositionId,
  setCallbackDate,
  setAssignToSelf,
  timezone,
  assignToSelf,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState(Datetime.moment().add(1, "days"));
  const timeZone = getUserTimeZone();

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const handleToggle = () => {
    setAssignToSelf(!assignToSelf);
  };

  const handleSubmit = () => {
    onSetDispositionId(CALL_DISPOSITION_IDS.CALLBACK);
    date.seconds(0);
    setCallbackDate(date.utc().format());
    handleClose();
  };

  const handleDateChange = date => setDate(moment(date).tz(timeZone));

  const valid = currentDate =>
    currentDate.isAfter(Datetime.moment().subtract(1, "day"));

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        <Button
          size="tiny"
          onClick={handleOpen}
          className={
            dispositionId === CALL_DISPOSITION_IDS.CALLBACK ? "gray" : "white"
          }
          content="Callback"
        />
      }
    >
      <Header content={"Callback Contact"} />
      <Modal.Content className="callback-content">
        <div>
          <span>Choose time to call back contact</span>
          <Datetime
            closeOnSelect={true}
            onChange={handleDateChange}
            isValidDate={valid}
            displayTimeZone={timeZone}
            renderInput={props => (
              <Input icon="large calendar outline" {...props} />
            )}
            value={date}
            dateFormat={"YYYY-MM-DD"}
          />
        </div>
        <Checkbox
          toggle
          name="submitToSelf"
          checked={assignToSelf}
          onChange={handleToggle}
          style={{ margin: "5px" }}
          className="right"
          label="Set callback to myself"
        />
        <Button
          size="tiny"
          content="Submit"
          primary
          disabled={Datetime.moment() > date}
          onClick={handleSubmit}
        />
      </Modal.Content>
    </Modal>
  );
}

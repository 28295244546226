import React, { useState } from "react";
import DownloadAttachments from "./DownloadAttachments";
import PleteoUpload from "./PleteoUpload";
import { formatDate } from "../helpers";
import { Button, Card, Header, Image, List, Table } from "semantic-ui-react";
import { generatePdfDocument } from "./v2FormResponsePDF";

function DownloadResponsePDF({ formResponse, language, contact }) {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      await generatePdfDocument(formResponse, language, contact);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };
  return (
    <Button
      primary
      loading={loading}
      size="tiny"
      style={{ marginLeft: "auto" }}
      onClick={handleDownload}
    >
      Download PDF
    </Button>
  );
}

const answerByType = answer => {
  switch (answer.type) {
    case "DatePicker":
    case "Dropdown":
    case "RadioButtonGroup":
    case "TextBox":
    case "TextInput":
      return answer[answer.valKey];
    case "Upload":
      return (
        <Card>
          {answer.uploadType === "Image" ? (
            <Image src={answer.url} wrapped ui={false} />
          ) : null}
          <Card.Content>
            <a href={answer.url} style={{ wordBreak: "break-word" }}>
              {answer.uploadFileName}
            </a>
          </Card.Content>
        </Card>
      );
    case "TableV1": {
      let headers = answer.data.columns.filter(
        data => data.dataPath !== "delete"
      );
      let rows = answer.data.rows;
      let colors = [
        "orange",
        "yellow",
        "olive",
        "green",
        "teal",
        "blue",
        "violet",
        "purple",
        "pink",
        "brown",
      ];
      return (
        <Table
          color={colors[Math.floor(Math.random() * 10)]}
          size="small"
          stackable
        >
          <Table.Header>
            <Table.Row>
              {headers.map(h => (
                <Table.HeaderCell key={h.dataPath} textAlign="center">
                  {h.label}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map((row, index) => (
              <Table.Row key={index}>
                {headers.map(h => {
                  return (
                    <Table.Cell key={h.dataPath} style={{ padding: "5px" }}>
                      {row[h.dataPath] || ""}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    }
    case "PleteoUpload":
      return <PleteoUpload activityLogId={answer.activityLogId} />;
    case "CheckboxButtonGroup":
      return (
        <List>
          {answer.checked !== null
            ? answer.checked.map(x => <List.Item key={x}>{x}</List.Item>)
            : "---"}
        </List>
      );
    default:
      return "Unknown Answer Type";
  }
};

function WixFormResponseV2({ form_response, language, contact, ids }) {
  const agentName = form_response.hidden.agent_name;
  return (
    <React.Fragment>
      <Card fluid>
        <Card.Content header={form_response.title} />
        <Card.Content>
          <Table basic="very" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Questions</Table.HeaderCell>
                <Table.HeaderCell>Answers</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {form_response.data.map(question => (
                <Table.Row key={question.id}>
                  <Table.Cell width={6}>
                    <Header as="h4">
                      <Header.Content>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.title,
                          }}
                        />
                        <Header.Subheader>{question.type}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{answerByType(question)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Content>
        <Card.Content extra>
          <p>Submitted at: {formatDate(form_response.submitted_at)}</p>
          {agentName && <p>Submitted by: {agentName}</p>}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <DownloadResponsePDF
              formResponse={form_response}
              language={language}
              contact={contact}
            />
          </div>
        </Card.Content>
      </Card>
      {form_response.data.some(
        event => event && ["Upload", "PleteoUpload"].includes(event.type)
      ) && <DownloadAttachments ids={ids} />}
    </React.Fragment>
  );
}

export default WixFormResponseV2;

import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import PropTypes from "prop-types";

import RuvixxForm from "components/RuvixxForm";
import TwilioPhoneNumberService from "services/TwilioPhoneNumber";
import VoicemailService from "services/Voicemails";

const AssignVoicemailForm = ({
  twilioPhoneNumberId,
  voicemailId: defaultVoicemailId,
  onSuccess,
}) => {
  const [voicemailId, setVoicemailId] = useState(defaultVoicemailId || null);
  const [voicemailOptions, setVoicemailOptions] = useState([]);

  const fetchVoicemails = useCallback(async () => {
    const voicemails = (await VoicemailService.getVoicemailsForFilter()).map(
      ({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      })
    );
    setVoicemailOptions(voicemails);
  }, []);

  const handleSubmit = async () => {
    await TwilioPhoneNumberService.assignVoicemail(
      twilioPhoneNumberId,
      voicemailId
    );
  };

  useEffect(() => {
    fetchVoicemails();
  }, []);

  return (
    <RuvixxForm onSubmit={handleSubmit} onSuccess={onSuccess}>
      <Form.Select
        search
        clearable
        onChange={(_, { value }) => setVoicemailId(value || null)}
        label="Voicemail"
        name="voicemail"
        options={voicemailOptions}
        placeholder="Select voicemail"
        value={voicemailId}
      />
    </RuvixxForm>
  );
};

AssignVoicemailForm.propTypes = {
  twilioPhoneNumberId: PropTypes.number.isRequired,
  voicemailId: PropTypes.number,
  onSuccess: PropTypes.func.isRequired,
};

export default AssignVoicemailForm;

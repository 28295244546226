import React, { useState } from "react";
import { Button, Modal, Dropdown } from "semantic-ui-react";
import CampaignStatusPresetForm from "./CampaignStatusPresetForm";

const CampaignStatusPresetModal = ({ preset, fetchPresets }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const handleSuccess = () => {
    handleClose();
    if (fetchPresets) {
      fetchPresets();
    }
  };

  const header = `${preset ? "Edit" : "New"} Preset`;

  const Trigger = preset ? (
    <Dropdown.Item content={header} icon="edit" onClick={handleOpen} />
  ) : (
    <Button
      content={header}
      size="tiny"
      className="item-adder"
      onClick={handleOpen}
    />
  );

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      trigger={Trigger}
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      size="small"
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <CampaignStatusPresetForm preset={preset} onSuccess={handleSuccess} />
      </Modal.Content>
    </Modal>
  );
};

export default CampaignStatusPresetModal;

import React, { useState } from "react";
import { Container, Divider } from "semantic-ui-react";

import Sidebar from "./components/Sidebar";
import TaskList from "./TaskList";

const TaskOverview = () => {
  const [filterParams, setFilterParams] = useState({});
  const [assignees, setAssignees] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  return (
    <Container fluid className="route divided">
      <Sidebar
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        assignees={assignees}
        campaigns={campaigns}
        setAssignees={setAssignees}
        setCampaigns={setCampaigns}
      />
      <div className="main">
        <TaskList
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          assignees={assignees}
          campaigns={campaigns}
        />
        <Divider hidden />
      </div>
    </Container>
  );
};

export default TaskOverview;

import React from "react";
import { Label } from "semantic-ui-react";
import {
  basicBorder,
  disabledBorder,
  disabledTextColor,
  getTextColor,
  getYiq,
} from "../helpers/tags";

function RvxTag({ tag, onClick = null, tableCell = false, styleProps = {} }) {
  if (!tag) {
    return null;
  }
  const bgColor = tag.enabled ? tag.color : "#ffffff";
  const textColor = tag.enabled ? getTextColor(bgColor) : disabledTextColor;
  const border = !tag.enabled
    ? disabledBorder
    : getYiq(bgColor) >= 225
    ? basicBorder
    : undefined;
  return (
    <Label
      key={tag.id}
      as={onClick ? "a" : "div"}
      size="mini"
      className="ctag"
      style={{
        marginLeft: "2px",
        backgroundColor: bgColor,
        color: textColor,
        border: border,
        boxShadow: "none",
        fontStyle: tag.enabled ? "normal" : "italic",
        ...(!tableCell && { marginBottom: "1px" }),
        ...styleProps,
      }}
      onClick={() => onClick && onClick(tag)}
    >
      {tag.name}
    </Label>
  );
}

export default RvxTag;

import React from "react";
import CampaignService from "../../../services/Campaign";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";

const DeleteCampaignStatusModal = ({ campaignStatus, fetchStatuses }) => {
  const handleDelete = async () => {
    await CampaignService.deleteCampaignStatus(campaignStatus.id);
    await fetchStatuses();
  };

  return (
    <ConfirmationModal
      actionDescription="Delete Campaign Status"
      buttonColor="red"
      onConfirm={handleDelete}
      icon={"trash"}
      menuTrigger
      warning
      content={<p>Are you sure you want to delete this Campaign Status?</p>}
    />
  );
};

export default DeleteCampaignStatusModal;

import { useState, useCallback, useEffect } from "react";
import { isEmpty } from "lodash";
import {
  getQueryParams,
  setQueryParams,
  isCurrentQueryParams,
  areQueryParamsEqual,
  initQueryParams,
} from "./helpers";

const useQueryParams = defaultParams => {
  const [values, setValues] = useState(getQueryParams());
  useEffect(() => {
    initQueryParams(checkAndUpdate);
  }, []);
  const updateValues = useCallback((values, replace) => {
    const queryParams = setQueryParams(values, replace);
    setValues(queryParams);
  }, []);
  const onSetValues = useCallback(
    newValues => {
      if (!isCurrentQueryParams(newValues)) {
        updateValues(newValues);
      }
    },
    [updateValues]
  );
  const checkAndUpdate = (forceUpdate = false) => {
    const queryParams = getQueryParams();
    let newQueryParams;
    if (isEmpty(queryParams)) {
      newQueryParams = defaultParams;
    } else {
      newQueryParams = queryParams;
    }
    const isCurrent = isCurrentQueryParams(newQueryParams);
    const areEqual = areQueryParamsEqual(newQueryParams, values);
    if (isCurrent && !areEqual) {
      setValues(newQueryParams);
    }
    if (!isCurrent || forceUpdate) {
      updateValues(newQueryParams, true);
    }
  };
  useEffect(() => {
    checkAndUpdate();
  }, [updateValues]);
  return [values, onSetValues];
};

export default useQueryParams;

import React, { createRef } from "react";
import { Container, Segment, Dropdown } from "semantic-ui-react";

import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import AuthService from "../../services/Auth";

import CustomDropdown from "../../components/CustomDropdown";
import setPageTitle from "../../helpers/title";
import InvestigationStatusService from "../../services/InvestigationStatus";
import InvestigationStatusModal from "./components/InvestigationStatusModal";
import DeleteInvestigationStatusModal from "./components/DeleteInvestigationStatusModal";
import BaseTable from "../../components/BaseTable";

const NewInvestigationStatusModalWithCheck = withRoleCheck(
  InvestigationStatusModal.NewButton,
  [ACL_RELATIONSHIPS.investigationStatus.create]
);

const EditInvestigationStatusModalWithCheck = withRoleCheck(
  InvestigationStatusModal.EditButton,
  [ACL_RELATIONSHIPS.investigationStatus.edit]
);

const DeleteInvestigationStatusModalWithCheck = withRoleCheck(
  DeleteInvestigationStatusModal,
  [ACL_RELATIONSHIPS.investigationStatus.delete]
);

class InvestigationStatusesTable extends BaseTable {
  constructor(props) {
    super(props);
    this.investigationStatusModal = createRef();

    this.queryMethod =
      InvestigationStatusService.getInvestigationStatusesAsTable;

    this.state = {
      ...this.state,
      enableSearch: true,
      header: "Investigation Status",
      headerIcon: "building",
      tableName: "investigation_statuses",
      noDataText:
        "No Investigation statuses found. Try adjusting your filters.",
      createButton: (
        <NewInvestigationStatusModalWithCheck
          onSuccess={this.fetchData}
          modalRef={this.investigationStatusModal}
        />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Investigation Statuses");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        className: "centered",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Actions",
        id: "actions",
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ original: investigationStatus }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditInvestigationStatusModalWithCheck
                investigationStatus={investigationStatus}
                onSuccess={this.fetchData}
                modalRef={this.investigationStatusModal}
              />
              <DeleteInvestigationStatusModalWithCheck
                investigationStatus={investigationStatus}
                onSuccess={this.fetchData}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <InvestigationStatusModal
            ref={this.investigationStatusModal}
            onSuccess={this.fetchData}
          />
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default InvestigationStatusesTable;

import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "semantic-ui-react";

import TaskForm from "./TaskForm";

const TaskModal = forwardRef(({ onSuccess, modelType, modelId }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [task, setTask] = useState();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = task => {
    setTask(task);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = () => {
    handleClose();
    onSuccess && onSuccess();
  };

  return (
    <Modal
      size="small"
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <Modal.Header content={`${task ? "Edit" : "New"} Task`} />
      <Modal.Content>
        <TaskForm
          task={task}
          onSuccess={handleSuccess}
          modelType={modelType}
          modelId={modelId}
        />
      </Modal.Content>
    </Modal>
  );
});

export default TaskModal;

import React, { useState, useEffect, useCallback } from "react";
import Datetime from "react-datetime";
import { Form } from "semantic-ui-react";
import moment from "moment-timezone";

const DateTimePicker = props => {
  const {
    dateTime: parentDateTime,
    timeZone: parentTimeZone,
    setDateTime: setParentDateTime,
    setTimeZone: setParentTimeZone,
    dateFormat = "YYYY-MM-DD",
    timeFormat = "hh:mm A",
    viewMode,
    label,
    inline,
    required,
    style,
    className,
    name,
  } = props;
  const [dateTime, setDateTime] = useState();
  const [timeZone, setTimeZone] = useState("UTC");
  const [timeZones, setTimeZones] = useState([]);
  const withTimeZone = !!setParentTimeZone;

  useEffect(() => {
    if (!withTimeZone || timeZones.some(({ key }) => key === parentTimeZone)) {
      setTimeZone(parentTimeZone);
    }
  }, [parentTimeZone, timeZones]);

  useEffect(() => {
    if (parentDateTime) {
      setDateTime(parentDateTime);
    }
  }, [parentDateTime]);

  const handleChangeDateTime = dateTime => {
    setParentDateTime(dateTime);
  };

  const handleChangeTimeZone = (_, { value: timeZone }) => {
    setTimeZone(timeZone);
    if (withTimeZone) {
      setParentTimeZone(timeZone);
    }
  };

  const setupTimeZones = useCallback(() => {
    if (!withTimeZone) {
      return;
    }
    const timeZonesNames = moment().tz.names();
    // remove invalid timezone "US/Pacific-New"
    const usPacificNewIdx = timeZonesNames.indexOf("US/Pacific-New");
    if (usPacificNewIdx !== -1) timeZonesNames.splice(usPacificNewIdx, 1);
    const timeZones = timeZonesNames.map(zone => {
      const offset = moment().tz(zone).format("Z");
      return { text: zone + " (" + offset + ")", key: zone, value: zone };
    });
    setTimeZones(timeZones);
  }, []);

  useEffect(() => {
    setupTimeZones();
  }, []);

  const getFormats = () => {
    const formats = { date: dateFormat, time: timeFormat };
    if (!withTimeZone) {
      const zone = ` [${moment().tz(timeZone).format("z")}]`;
      formats[timeFormat ? "time" : "date"] += zone;
    }
    return formats;
  };

  const renderDateTimeSelector = () => {
    const formats = getFormats();
    return (
      <Form.Field
        inline={inline}
        required={required}
        style={withTimeZone ? undefined : style}
        className={`dateTime ${withTimeZone ? "" : className}`}
      >
        <label>{label}</label>
        <Datetime
          dateFormat={formats.date}
          timeFormat={formats.time}
          viewMode={viewMode}
          value={dateTime}
          closeOnSelect
          onChange={handleChangeDateTime}
          utc
        />
      </Form.Field>
    );
  };

  const renderTimeZoneSelector = () => {
    return (
      <Form.Select
        className="timeZone"
        label={inline ? undefined : "Time Zone"}
        placeholder={inline ? "Time Zone" : undefined}
        value={timeZone}
        options={timeZones}
        onChange={handleChangeTimeZone}
      />
    );
  };

  if (!withTimeZone) {
    return renderDateTimeSelector();
  }
  return (
    <Form.Group className={`dateTimeWithTimeZone ${className}`} style={style}>
      {renderDateTimeSelector()}
      {renderTimeZoneSelector()}
    </Form.Group>
  );
};

export default DateTimePicker;

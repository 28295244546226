import React, { useRef, useState, useCallback, useEffect } from "react";
import { List, Icon, Card, Button } from "semantic-ui-react";
import moment from "moment";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./TaskList.scoped.scss";
import FilterButtons from "./components/FilterButtons";
import Pagination from "./components/Pagination";
import StepInfoCard from "./components/StepInfoCard";
import TaskModal from "./components/TaskModal";
import SearchInput from "../../components/SearchInput";
import AdvancedSearch from "../../components/AdvancedSearch";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import taskConstants from "../../constants/Task";
import TaskService from "../../services/Task";
import { mergeObjectValues } from "../../components/helpers";
import useQueryParams from "../../hooks/params/useQueryParams";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import setPageTitle from "../../helpers/title";

const TaskList = ({
  filterParams: parentFilterParams,
  setFilterParams: setParentFilterParams = () => {},
  modelType,
  modelId,
  assignees: parentAssignees,
  campaigns: parentCampaigns,
}) => {
  const taskModal = useRef();
  const cancelToken = useRef();
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
    search: "",
  });
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [filterValues, setFilterValues] = useState({
    advanced: {},
    parent: {},
  });
  const [filterParams, setFilterParams] = useState({
    advanced: {},
    parent: {},
  });
  const [assignees, setAssignees] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  const activePage = queryParams.page;
  const perPage = queryParams.per_page;

  const location = useLocation();

  const setActivePage = page => {
    setQueryParams({
      ...queryParams,
      page,
    });
  };
  const searchQuery = queryParams.search;
  const setSearchQuery = search => {
    setQueryParams({
      ...queryParams,
      page: 1,
      search,
    });
  };
  const getTextForParam = useCallback(
    (id, key) => {
      const obj = (() => {
        switch (key) {
          case "priorities":
            return taskConstants.priorities.find(
              priority => priority.id === id
            );
          case "assignees":
            return assignees.find(assignee => assignee.id === id);
          case "campaigns":
            return campaigns.find(campaign => campaign.id === id);
          case "status":
            return taskConstants.statuses.find(status => status.id === id);
          case "task_type":
            return taskConstants.types.find(type => type.id === id);
          case "visibilities":
            return taskConstants.visibilities.find(
              visibility => visibility.id === id
            );
          default:
            return null;
        }
      })();
      return (obj || {}).name;
    },
    [assignees, campaigns]
  );

  const updateQueryParamsFromFilters = useCallback(
    filterValues => {
      const { per_page, search } = queryParams;
      let allFilters = mergeObjectValues(filterValues);
      allFilters = Object.keys(allFilters).reduce((obj, key) => {
        let value = allFilters[key];
        if (key.match(/:(?:from|to)$/)) {
          value = moment(value).format("YYYY-MM-DD");
        }
        return { ...obj, [key]: value };
      }, {});
      setQueryParams({
        page: 1,
        per_page,
        search,
        ...allFilters,
      });
    },
    [queryParams]
  );

  const filters = {
    parent: [
      {
        key: "priorities",
        title: "Priorities",
        type: "select",
        _type: "parent",
      },
      {
        key: "assignees",
        title: "Assignees",
        type: "select",
        _type: "parent",
      },
      {
        key: "campaigns",
        title: "Campaigns",
        type: "select",
        _type: "parent",
      },
    ],
    advanced: [
      {
        key: "created_date",
        title: "Created Date",
        type: "dateRange",
        _type: "advanced",
      },
      {
        key: "due_date",
        title: "Due Date",
        type: "dateRange",
        _type: "advanced",
      },
      {
        key: "status",
        title: "Status",
        type: "select",
        data: taskConstants.statuses,
        _type: "advanced",
      },
      {
        key: "task_type",
        title: "Task Type",
        type: "select",
        data: taskConstants.types,
        _type: "advanced",
      },
      {
        key: "visibilities",
        title: "Visibility",
        type: "select",
        data: taskConstants.visibilities,
        _type: "advanced",
      },
    ],
  };

  const updateFilters = useCallback(
    (newFilters, attr, type = "both") => {
      if (loading) {
        return;
      }

      const allFilters = mergeObjectValues(filters);
      const updateFn = filters => {
        if (type !== "both") {
          if (type === "parent" && attr === "params") {
            setParentFilterParams(newFilters);
          }
          filters = {
            ...filters,
            [type]: newFilters,
          };
          if (attr === "values") {
            updateQueryParamsFromFilters(filters);
          }
          return filters;
        }
        let advancedFilters = {};
        let parentFilters = {};
        for (const [title, value] of Object.entries(newFilters)) {
          const { _type } = allFilters.find(filter => filter.title === title);
          if (_type === "advanced") {
            advancedFilters = { ...advancedFilters, [title]: value };
          } else if (_type === "parent") {
            parentFilters = { ...parentFilters, [title]: value };
          }
        }
        if (attr === "params") {
          setParentFilterParams(parentFilters);
        }
        filters = {
          ...filters,
          advanced: advancedFilters,
          parent: parentFilters,
        };
        if (attr === "values") {
          updateQueryParamsFromFilters(filters);
        }
        return filters;
      };
      if (attr === "values") {
        setFilterValues(updateFn);
      } else if (attr === "params") {
        setFilterParams(updateFn);
      }
    },
    [loading]
  );

  const fetchTasks = useCallback(async () => {
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = axios.CancelToken.source();
    setLoading(true);
    let allFilters = mergeObjectValues(filterValues);
    if (modelType && modelId) {
      allFilters = {
        ...allFilters,
        target_type: modelType,
        target_id: modelId,
      };
    }
    if (allFilters.campaigns) {
      allFilters = {
        ...allFilters,
        target_type: "Campaign",
        target_id: allFilters.campaigns,
      };
    }
    const { data, pages } = await TaskService.getTasks({
      page: activePage,
      perPage,
      filters: { ...allFilters, search: searchQuery },
      cancelToken: cancelToken.current.token,
    });

    setTotalPages(pages);
    setTasks(data);
    setLoading(false);
  }, [activePage, perPage, filterValues, searchQuery, modelType, modelId]);

  useEffect(() => {
    if (parentAssignees) {
      setAssignees(parentAssignees);
    }
  }, [parentAssignees]);

  useEffect(() => {
    if (parentCampaigns) {
      setCampaigns(parentCampaigns);
    }
  }, [parentCampaigns]);

  useEffect(() => {
    const { page, per_page, search, ...filterQueryParams } = queryParams;
    const allFilters = mergeObjectValues(filters);
    const newFilterValues = Object.keys(filterQueryParams).reduce(
      (obj, cur) => {
        const { type, _type } = allFilters.find(
          ({ key }) => key === cur.replace(/:(?:from|to)$/, "")
        );
        let filterValue = filterQueryParams[cur];
        if (type === "select") {
          filterValue = filterValue.split(",").map(id => +id);
        }
        return {
          ...obj,
          [_type]: {
            ...obj[_type],
            [cur]: filterValue,
          },
        };
      },
      filterValues
    );
    const newFilterParams = Object.keys(filterQueryParams).reduce(
      (obj, cur) => {
        const { type, _type, title, key } = allFilters.find(
          ({ key }) => key === cur.replace(/:(?:from|to)$/, "")
        );
        let filterParam;
        if (type === "select") {
          filterParam = filterQueryParams[cur].split(",").map(id => ({
            key: +id,
            text: getTextForParam(+id, cur),
            value: +id,
          }));
        } else {
          filterParam = {
            ...(obj[_type].from && { from: obj[_type].from }),
            ...(obj[_type].to && { to: obj[_type].from }),
            [cur.replace(`${key}:`, "")]: moment(filterQueryParams[cur]),
          };
        }
        return {
          ...obj,
          [_type]: {
            ...obj[_type],
            [title]: filterParam,
          },
        };
      },
      filterParams
    );
    setParentFilterParams(newFilterParams.parent || {});
    setFilterValues(newFilterValues);
    setFilterParams(newFilterParams);
  }, [queryParams, getTextForParam, assignees, campaigns]);

  useEffect(() => {
    if (!parentFilterParams) {
      return;
    }
    updateFilters(parentFilterParams, "params", "parent");
    const filterValues = Object.keys(parentFilterParams).reduce(
      (filters, key) => {
        const values = parentFilterParams[key].map(({ value }) => value);
        const filter = { [key.toLowerCase()]: values };
        return { ...filters, ...filter };
      },
      {}
    );
    updateFilters(filterValues, "values", "parent");
  }, [parentFilterParams]);

  useEffect(() => {
    const allFilterParams = mergeObjectValues(filterParams);
    const allFilters = mergeObjectValues(filters);
    const filterValues = Object.keys(allFilterParams).reduce(
      (filters, title) => {
        const { key, type } = allFilters.find(filter => filter.title === title);
        const value = allFilterParams[title];
        const filter = (() => {
          switch (type) {
            case "dateRange":
              return ["from", "to"].reduce(
                (filter, cur) => ({
                  ...filter,
                  ...(value[cur] && {
                    [`${key}:${cur}`]: value[cur].format(),
                  }),
                }),
                {}
              );
            case "select":
              return {
                [key]: value.map(({ value }) => value),
              };
            default:
          }
          return null;
        })();
        return { ...filters, ...filter };
      },
      {}
    );
    updateFilters(filterValues, "values", "advanced");
  }, [filterParams]);

  useEffect(() => {
    if (location && location.pathname === "/tasks") {
      setPageTitle("Tasks");
    }
    fetchTasks();
  }, [fetchTasks]);

  const handleCreate = () => {
    taskModal.current.open();
  };

  const handleEdit = task => {
    taskModal.current.open(task);
  };

  const CreateButton = withRoleCheck(
    () => (
      <Button
        size="tiny"
        content="New Task"
        className="item-adder"
        onClick={handleCreate}
      />
    ),
    [
      ACL_RELATIONSHIPS.task.create,
      ACL_RELATIONSHIPS.user.read,
      ACL_RELATIONSHIPS.contact.read,
      ACL_RELATIONSHIPS.entity.read,
      ACL_RELATIONSHIPS.campaign.read,
      ACL_RELATIONSHIPS.adminRoleForFilters.read,
      ACL_RELATIONSHIPS.adminRole.read,
      ACL_RELATIONSHIPS.userSearch.read,
    ]
  );

  return (
    <List className="taskList tabView">
      <TaskModal
        ref={taskModal}
        onSuccess={fetchTasks}
        modelType={modelType}
        modelId={modelId}
      />
      <List.Item className="actions">
        <CreateButton />
        <SearchInput
          className="search"
          query={searchQuery}
          onUpdateQuery={setSearchQuery}
        />
        <AdvancedSearch
          filters={filters.advanced}
          buttonProps={{ className: "standard" }}
          filterParams={filterParams.advanced}
          updateFilterParams={filterParams =>
            updateFilters(filterParams, "params", "advanced")
          }
        />
        <FilterButtons
          filters={mergeObjectValues(filters)}
          filterParams={mergeObjectValues(filterParams)}
          updateFilterParams={filterParams =>
            updateFilters(filterParams, "params")
          }
        />
      </List.Item>
      {loading && totalPages === 0 ? (
        <List.Item className="no-results">
          <Icon loading name="circle notched" size="big" />
        </List.Item>
      ) : tasks.length === 0 ? (
        <List.Item className="no-results">
          <p>
            {`No tasks${modelType ? ` for this ${modelType}` : ""}.`}
            {Object.keys(mergeObjectValues(filterValues)).length > 0 &&
              " Try adjusting your filters."}
          </p>
        </List.Item>
      ) : (
        tasks.map(task => (
          <List.Item key={task.id}>
            <List.Content>
              <Card.Group>
                <StepInfoCard step={task} onEdit={handleEdit} link />
              </Card.Group>
            </List.Content>
          </List.Item>
        ))
      )}
      {totalPages > 0 && (
        <List.Item>
          <Pagination
            activePage={activePage}
            setActivePage={setActivePage}
            totalPages={totalPages}
            loading={loading}
          />
        </List.Item>
      )}
    </List>
  );
};

export default TaskList;

import React from "react";
import { Header as SemanticHeader, Menu } from "semantic-ui-react";
import { snakeCase } from "lodash";
import store from "store";

import ACL_RELATIONSHIPS from "../../../acl-relationships";

import "./../../../styles/sidebar.scss";
import "./Sidebar.scoped.scss";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import { checkIsAuthorized } from "../../../components/helpers";
import CONFIG from "../../../Config";

import { SYSTEM_ROLES } from "../../../constants/Constants";

const Sidebar = ({ activeItem, setActiveItem }) => {
  const user = store.get("userAuth");

  const Header = ({ children }) => {
    return (
      <Menu.Item header>
        <SemanticHeader>{children}</SemanticHeader>
      </Menu.Item>
    );
  };

  const Item = ({
    children,
    permissions = [],
    conditions = [],
    name = null,
  }) => {
    if (!name) {
      name = snakeCase(children.toLowerCase());
    }
    const handleItemClick = () => {
      setActiveItem(name);
    };
    if (conditions.some(c => !c)) {
      return null;
    }
    const MenuItem = withRoleCheck(Menu.Item, permissions);
    return (
      <MenuItem
        name={name}
        content={children}
        active={activeItem === name}
        onClick={handleItemClick}
      />
    );
  };

  const Section = ({ children, conditions = [] }) => {
    if (!Array.isArray(children)) {
      return children || null;
    }
    if (conditions.some(c => !c)) {
      return null;
    }
    const showHeader = children
      .filter(element => element.type === Item)
      .some(
        element =>
          checkIsAuthorized(element.props.permissions || []) &&
          (element.props.conditions || []).every(c => c)
      );
    return children.filter(element => showHeader || element.type === Item);
  };

  const userRole = user?.role || {};
  const isAdmin = userRole.id === SYSTEM_ROLES.ADMIN;

  return (
    <div className="sidebar">
      <aside>
        <Menu vertical fluid borderless compact>
          <Section>
            <Header>Admin</Header>
            {isAdmin && <Item permissions={[]}>Global Settings</Item>}
            <Item permissions={[ACL_RELATIONSHIPS.adminUser.read]}>Users</Item>
            <Item
              permissions={[
                ACL_RELATIONSHIPS.adminRole.read,
                ACL_RELATIONSHIPS.adminRolePermission.read,
              ]}
            >
              Roles
            </Item>
            <Item permissions={[ACL_RELATIONSHIPS.adminGroup.create]}>
              Groups
            </Item>
            <Item
              permissions={[ACL_RELATIONSHIPS.customEmailDomains.readTable]}
            >
              Custom Email Domains
            </Item>
          </Section>
          <Section conditions={[CONFIG.IS_PHONE_ENABLED]}>
            <Header>Call Center</Header>
            <Item permissions={[ACL_RELATIONSHIPS.disposition.read]}>
              Call Dispositions
            </Item>
            <Item
              permissions={[ACL_RELATIONSHIPS.twilioPhoneNumber.readAsTable]}
            >
              Phone Numbers
            </Item>
            <Item permissions={[ACL_RELATIONSHIPS.voicemail.read]}>
              Voicemails
            </Item>
          </Section>
          <Section>
            <Header>Campaigns</Header>
            <Item
              permissions={[ACL_RELATIONSHIPS.campaignStatusPreset.readAsTable]}
            >
              Campaign Status Presets
            </Item>
            <Item
              permissions={[ACL_RELATIONSHIPS.campaignStatuses.readAsTable]}
            >
              Campaign Statuses
            </Item>
            <Item
              permissions={[ACL_RELATIONSHIPS.region.read]}
              conditions={[!user.external]}
            >
              Regions
            </Item>
            <Item
              permissions={[
                ACL_RELATIONSHIPS.revenueOpportunityStatusPreset.readAsTable,
              ]}
            >
              Revenue Opportunity Status Presets
            </Item>
            <Item
              permissions={[ACL_RELATIONSHIPS.revenueOpportunityStatus.read]}
            >
              Revenue Opportunity Statuses
            </Item>
          </Section>
          <Section conditions={[CONFIG.CONSTANT_CONTACT_ENABLED]}>
            <Header>Constant Contact</Header>
            <Item
              name="constant_contact_accounts"
              permissions={[ACL_RELATIONSHIPS.constantContactAccounts.read]}
            >
              Accounts
            </Item>
            <Item
              name="constant_contact_campaigns"
              permissions={[ACL_RELATIONSHIPS.constantContactCampaigns.read]}
            >
              Campaigns
            </Item>
          </Section>
          <Section>
            <Header>Custom Fields</Header>
            <Item permissions={[ACL_RELATIONSHIPS.customFieldConfig.readTable]}>
              Custom Field Configs
            </Item>
            <Item permissions={[ACL_RELATIONSHIPS.customFieldGroup.readTable]}>
              Custom Field Groups
            </Item>
          </Section>
          <Section>
            <Header>Email Assets</Header>
            <Item permissions={[ACL_RELATIONSHIPS.emailTemplates.read]}>
              Email Templates
            </Item>
            <Item permissions={[ACL_RELATIONSHIPS.emailFooters.read]}>
              Email Footers
            </Item>
            <Item
              permissions={[
                ACL_RELATIONSHIPS.emailUrl.readTable,
                ACL_RELATIONSHIPS.emailTemplates.read,
              ]}
            >
              Email URLs
            </Item>
            <Item permissions={[ACL_RELATIONSHIPS.emailSignatures.read]}>
              Email Signatures
            </Item>
          </Section>
          <Section conditions={[CONFIG.ENABLE_PIRACY]}>
            <Header>Piracy</Header>
            <Item permissions={[ACL_RELATIONSHIPS.product.read]}>Products</Item>
            <Item permissions={[ACL_RELATIONSHIPS.MachineApprovalStatus.read]}>
              Machine Approval Statuses
            </Item>
            <Item permissions={[ACL_RELATIONSHIPS.sourceStatus.read]}>
              Source Statuses
            </Item>
            <Item permissions={[ACL_RELATIONSHIPS.recipient.read]}>
              Recipients
            </Item>
            <Item permissions={[ACL_RELATIONSHIPS.caseDomains.read]}>
              Case Domains
            </Item>
            <Item
              permissions={[ACL_RELATIONSHIPS.investigationStatus.readAsTable]}
            >
              Investigation Statuses
            </Item>
          </Section>
          <Section>
            <Header>Tag Management</Header>
            <Item permissions={[ACL_RELATIONSHIPS.tag.readTable]}>Tags</Item>
            <Item permissions={[ACL_RELATIONSHIPS.tagCategory.readTable]}>
              Tag Categories
            </Item>
          </Section>
        </Menu>
      </aside>
    </div>
  );
};

export default Sidebar;

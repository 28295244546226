import { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { setRouteParam, useCurrentPath } from "./helpers";

const useRouteParam = (key, defaultValue) => {
  const params = useParams();
  const currentPath = useCurrentPath();
  const [value, setValue] = useState();
  const setValueCalled = useRef();
  const defaultValueSet = useRef();
  const updateValue = useCallback(
    (value, replace) => {
      const newValue = setRouteParam(currentPath, key, value, replace);
      setValue(newValue);
    },
    [params, key]
  );
  const onSetValue = useCallback(
    newValue => {
      setValueCalled.current = true;
      updateValue(newValue);
    },
    [updateValue]
  );
  useEffect(() => {
    if (value === undefined) {
      defaultValueSet.current = true;
      updateValue(params[key] || defaultValue, true);
    } else {
      if (setValueCalled.current) {
        setValueCalled.current = false;
      } else {
        if (params[key] !== undefined) {
          updateValue(params[key], true);
        }
      }
    }
  }, [params, key, value, defaultValue, updateValue]);
  return [value, onSetValue];
};

export default useRouteParam;

const CONSTANTS = {
  STATUS_TYPES: {
    ACTIVE: true,
    INACTIVE: false,
  },
  EMAIL_STATUS: {
    SCHEDULED: 1,
    PENDING: 2,
    DELIVERED: 3,
    ERROR: 4,
  },
  CAMPAIGN_STATUS_TYPES: {
    ACTIVE: 1,
    INACTIVE: 2,
    ARCHIVED: 3,
  },
  CONTACT_PHONE_TYPES: {
    MOBILE: 3,
    WORK: 2,
    HOME: 1,
    // FAX: 4,
    DEFAULT: 5,
  },

  EMAIL_SERVICES: {
    MAILGUN: 1,
    SENDGRID: 2,
    CONSTANT_CONTACT: 3,
    SMTP: 4,
    OUTLOOK: 6,
  },

  EMAIL_TYPES: {
    MARKETING: "marketing",
    TRANSACTIONAL: "transactional",
  },

  TAG_COLORS: [
    "#8dd3c7",
    "#ffffb3",
    "#bebada",
    "#fb8072",
    "#80b1d3",
    "#fdb462",
    "#b3de69",
    "#fccde5",
    "#d9d9d9",
    "#bc80bd",
    "#ccebc5",
    "#ffed6f",
  ],

  HTTP_METHODS: [
    "get",
    "post",
    "put",
    "delete",
    "head",
    "connect",
    "options",
    "trace",
    "patch",
  ],

  DATA_JOB_STATUSES: {
    SCHEDULED: 1,
    IN_PROGRESS: 2,
    FAILED: 3,
    COMPLETE: 4,
    ABORTED: 5,
    READY: 6,
    EXPIRED: 7,
  },
  IMPORT_TYPES: {
    DATA: 1,
    AUDIT_LOGS: 2,
    CASE_DATA: 3,
  },
  EXPORT_TYPES: {
    CONTACTS: 1,
    AUDIT_LOGS: 2,
    CAMPAIGN_TARGETS: 3,
  },
  AUDIT_LOG_CATEGORIES: {
    USER: "user",
    ENTITY: "entity",
    CONTACT: "contact",
    DATA_JOB: "data_job",
    EMAIL: "email",
    FORM: "form",
    CALL: "call",
    MAIL: "mail",
    WEB: "web",
    MANUAL_UPDATE: "manual_update",
  },
  EMAIL_SUBCATEGORY: {
    DELIVERED: "email_delivered",
    OPENED: "email_opened",
    BOUNCED: "email_errored",
    CLICKED: "email_clicked",
  },
  FORM_SUBCATEGORY: {
    LOADED: "form_loaded",
    SAVED: "form_saved",
    SUBMITTED: "form_submitted",
  },
  MAIL_SUBCATEGORY: {
    SENT: "mail_mailed",
    DELIVERED: "mail_delivered",
  },
  WEB_SUBCATEGORY: {
    WEB: "visit",
  },
};

const TABS = [
  {
    name: "activityLog",
    icon: "clipboard list",
  },
  {
    name: "calls",
    icon: "phone",
  },
  {
    name: "campaigns",
    icon: "bullhorn",
  },
  {
    name: "events",
    icon: "bolt",
  },
  {
    name: "cases",
    icon: "folder open",
  },
  {
    name: "contacts",
    icon: "user outline",
  },
  {
    name: "contracts",
    icon: "handshake outline",
  },
  {
    name: "departments",
    icon: "sitemap",
  },
  {
    name: "3rd_party_leads",
    icon: "user secret",
  },
  {
    name: "machines",
    icon: "server",
  },
  {
    name: "notes",
    icon: "sticky note outline",
  },
  {
    name: "responses",
    icon: "comments outline",
  },
  {
    name: "revenueOpportunities",
    icon: "gem outline",
  },
  {
    name: "tasks",
    icon: "list ul",
  },
  {
    name: "script",
    icon: "file alternate outline",
  },
  {
    name: "survey",
    icon: "cube",
  },
  {
    name: "submittedForms",
    icon: "comments outline",
  },
  {
    name: "invoices",
    icon: "gem outline",
  },
];

const generateTabs = (...names) => {
  const tabNames = TABS.map(tab => tab.name);
  return names
    .filter(name => tabNames.includes(name))
    .map(name => TABS.find(tab => tab.name === name));
};

export const CALL_DISPOSITION_IDS = {
  CALLBACK: -1,
  SKIPPED: -2,
};

export const CALL_DISPOSITION_TYPES = {
  CALLBACK: "callback",
  CONNECTED: "connected",
  NOT_CONNECTED: "not_connected",
  SKIPPED: "skipped",
  VOICEMAIL: "voicemail",
  UNKNOWN: "unknown",
};

export const SYSTEM_ROLES = {
  ADMIN: -1,
};

export const TIME_UNITS = [
  // "seconds",
  // "minutes",
  "hours",
  "days",
  "weeks",
  "months",
  "years",
];

export const TIME_IN_MS = {
  DAY: 24 * 60 * 60 * 1000,
};

export const DEFAULT_CAMPAIGN_ID = 0;

export const EMAIL_URL_LANGS = {
  ENGLISH: "en",
  THAI: "th",
  JAPANESE: "ja",
};

export const EMAIL_SERVICES = CONSTANTS.EMAIL_SERVICES;
export const DEFAULT_PAGE_SIZE = 20;
export const TABLE_PAGE_SIZES = [5, 10, 20, 25, 50, 100];

export const EPOCH_TIMESTAMP = "1970-01-01T00:00:00";
export const USER_ALERTS_FETCH_INTERVAL = 120000;

export const RANGE_FILTER = {
  GREATER_THAN: 1,
  LESS_THAN: 2,
  BETWEEN: 3,
};

export const USER_STATUSES = {
  ONLINE: {
    key: "ONLINE",
    label: "Online",
  },
  OFFLINE: {
    key: "OFFLINE",
    label: "Offline",
  },
  DISCONNECTED: {
    key: "DISCONNECTED",
    label: "Disconnected",
  },
  DISABLED: {
    key: "DISABLED",
    label: "disabled",
  },
  ON_CALL: {
    key: "ON_CALL",
    label: "On a call",
  },
};

export default CONSTANTS;
export { generateTabs };

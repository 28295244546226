import React from "react";
import { List, Checkbox, Icon, Button, Label } from "semantic-ui-react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import "./../../../styles/campaign.scss";
import "./../../../styles/table.scss";

import ContactService from "../../../services/Contact";
import CampaignService from "../../../services/Campaign";
import BaseTable from "../../../components/BaseTable";
import AuthService from "../../../services/Auth";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import { checkIsAuthorized } from "../../../components/helpers";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import { EditContactModal as _EditContactModal } from "../../entity/components/Modals";

const canSetCampaignTarget = checkIsAuthorized(
  [
    ACL_RELATIONSHIPS.campaignTargetContacts.create,
    ACL_RELATIONSHIPS.campaignTargetContacts.delete,
  ],
  { partialMatch: true }
);

const canEditContact = checkIsAuthorized([
  ACL_RELATIONSHIPS.entityContact.edit,
]);

const EditContactModal = withRoleCheck(_EditContactModal, [
  ACL_RELATIONSHIPS.entityContact.edit,
]);

const isCampaignTargetContact = (contact, campaignId) =>
  contact.campaign_targets.length > 0 &&
  contact.campaign_targets.filter(c => c.campaign_id === campaignId).length > 0;

class ReferralContactsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = ContactService.getContacts;

    this.state = {
      ...this.state,
      className: "Contact",
      tableName: "referralContacts",
      noDataText: "No Contacts found.",
      filters: [],
      enableSearch: false,
      createButton: null,
      enableTags: false,
      enableSettings: false,
      defaultFilterParams: {
        entity_id: this.props.entity_id,
      },
      style: {
        height: "400px", // This will force the table body to overflow and scroll, since there is not enough room
      },
      useParamManager: false,
    };
  }

  async componentDidMount() {
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  addOrRemoveCampaignContact = async (
    campaignId,
    targetId,
    contactId,
    isCampaignTargetContact
  ) => {
    if (isCampaignTargetContact) {
      await CampaignService.removeContact(campaignId, targetId, contactId);
    } else {
      await CampaignService.addContact(campaignId, targetId, contactId);
    }
    this.fetchData();
  };

  setColumns = () => {
    const campaignId = this.props.campaign_id;
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => (
          <a
            href={`/contacts/${props.value}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.value}
          </a>
        ),
      },
      {
        Header: "Name",
        accessor: "full_name",
        width: 200,
        Cell: props => (
          <a
            href={`/contacts/${props.original.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.value}
          </a>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        width: 150,
      },
      {
        Header: "Phone Numbers",
        sortable: false,
        Cell: props => (
          <List horizontal bulleted>
            {props.original.phone_numbers.map(phone => (
              <List.Item key={phone.id}>
                {phone.ext ? `${phone.number}x${phone.ext}` : phone.number}
              </List.Item>
            ))}
          </List>
        ),
      },
    ];
    if (campaignId && campaignId > 0) {
      columns.push({
        Header: "Include in Campaign",
        headerClassName: "centered",
        className: "centered",
        Cell: ({ original }) => {
          const { campaign_id, entity_id } = this.props;
          const contact = original;
          const isIncluded = isCampaignTargetContact(contact, campaign_id);
          return (
            <Checkbox
              toggle
              name="isCampaignTarget"
              checked={isIncluded}
              disabled={!canSetCampaignTarget}
              onChange={async () => {
                await this.addOrRemoveCampaignContact(
                  campaign_id,
                  entity_id,
                  original.id,
                  isIncluded
                );
              }}
            />
          );
        },
      });
    }
    columns.push({
      Header: "Edit Contact",
      headerClassName: "centered",
      className: "centered",
      show: canEditContact,
      minWidth: 60,
      sortable: false,
      Cell: props => (
        <EditContactModal
          key={props.original.id}
          entityId={this.props.entity_id}
          contact={props.original}
          onSuccess={this.fetchData}
          iconTrigger={true}
        />
      ),
    });
    columns.push({
      Header: "Call Next",
      headerClassName: "centered",
      className: "centered",
      sortable: false,
      Cell: ({ original }) => {
        const { campaign_id } = this.props;
        const contact = original;
        const isIncluded = isCampaignTargetContact(contact, campaign_id);
        return (
          <Button
            onClick={async () => {
              const contact = original;
              await this.props.callNextContact(contact);
            }}
            as="div"
            labelPosition="right"
            size="tiny"
            disabled={
              original.phone_numbers.length == 0 ||
              !original.phone_numbers.some(({ is_callable }) => is_callable) ||
              !original.phone_numbers.some(({ enabled }) => enabled) ||
              !isIncluded
            }
          >
            <Button icon>
              <Icon name="call" />
            </Button>
            <Label as="a" basic>
              Call Next
            </Label>
          </Button>
        );
      },
    });
    this.initTableSettings(columns);
  };

  render() {
    return <div>{this.renderTable()}</div>;
  }
}

export default ReferralContactsTable;

import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";

import cloneDeep from "lodash/cloneDeep";

import { Header, Icon, Button, Dropdown } from "semantic-ui-react";

import SearchInput from "../SearchInput";
import AdvancedSearch from "../AdvancedSearch";
import TableSettings from "../../routes/campaign/components/TableSettings";
import {
  generateFilterParamsFromQueryParams,
  generateQueryParamsFromFilterParams,
  generateQueryParamsFromCustomFilters,
  generateQueryParamsFromTagFilters,
} from "../helpers";

const TableHeader = ({
  // Header info
  headerIcon,
  header,
  createButton,

  // Search & Filters
  tags,
  enableSearch,
  enableAdvancedSearch,
  onSearch,
  searchQuery,
  queryFilters,
  filterOptions,
  onUpdateQueryFilters,
  onRefetch,

  // Table details
  columns,
  visibleColumns,
  frozenColumns,
  customFields,

  // Actions
  actions,
  disableActions,

  // Table settings
  enableSettings,
  handleUpdateTableSettings,
  enableCustomFieldsSettings,

  tableLeft,
}) => {
  const [filterParams, setFilterParams] = useState({});
  const [tagsFilters, setTagsFilters] = useState([]);
  const [customFilters, setCustomFilters] = useState([]);

  const [queryParams, setQueryParams] = useState({});

  const removeFilter = ({ group, item }) => {
    let _filterParams = cloneDeep({ ...filterParams });

    if (Array.isArray(_filterParams[group])) {
      _filterParams[group] = _filterParams[group].filter(
        _item => item.value !== _item.value
      );
    }
    if (_filterParams[group]?.length === 0) {
      delete _filterParams[group];
    }

    const queryParams = generateQueryParamsFromFilterParams(
      _filterParams,
      filterOptions
    );

    setFilterParams(_filterParams);
    setQueryParams(queryParams);
  };

  const removeAllFilters = () => {
    setFilterParams({});
    setQueryParams({});
  };

  const handleRemoveTagFilter = (uuid, updateQueryParams = false) => {
    let _tagsFilters = tagsFilters.map(tagFilter => ({ ...tagFilter }));
    _tagsFilters = _tagsFilters.filter(tagFilter => tagFilter.uuid !== uuid);
    setTagsFilters(_tagsFilters);
    /*
    if (updateQueryParams) {
      let tags = queryParams.tags.split(";");
      const tagFilter = tagsFilters.find(filter => filter.uuid === uuid);
      const tagsToDelete = tagFilter.tags.join(",");
      tags = tags.filter(tags => tags !== tagsToDelete);
      queryParams = { ...queryParams, tags: tags.join(";") };
    }
    */
  };

  const handleRemoveCustomFilter = (uuid, updateQueryParams = false) => {
    if (updateQueryParams) {
      const customFilter = customFilters.find(filter => filter.uuid === uuid);
      queryParams = Object.keys(queryParams)
        .filter(key => key !== customFilter.selectValue)
        .reduce((obj, key) => ({ ...obj, [key]: queryParams[key] }), {});
    }
    this.setState({
      customFilters: customFilters.filter(filter => filter.uuid !== uuid),
      queryParams,
    });
  };

  const handleUpdateFilterParams = (
    filterParams,
    tagsFilters,
    customFilters
  ) => {
    const queryParams = {
      ...generateQueryParamsFromFilterParams(filterParams, filterOptions),
      ...generateQueryParamsFromTagFilters(tagsFilters),
      ...generateQueryParamsFromCustomFilters(customFilters),
    };
    setQueryParams(queryParams);
    setFilterParams(filterParams);
    setTagsFilters(tagsFilters);
    setCustomFilters(customFilters);
  };

  const displayTagNames = tagIds => {
    let tagNames = tags
      .filter(tag => tagIds.includes(tag.id))
      .map(tag => tag.name)
      .join(", ");
    return tagNames;
  };

  const getFilterButtons = () => {
    //const { filterParams, customFilters, tagsFilters } = this.state;
    let buttons = [];
    Object.keys(filterParams).forEach(groupKey => {
      if (Array.isArray(filterParams[groupKey])) {
        filterParams[groupKey].forEach(item => {
          buttons.push(
            <Button
              basic
              key={groupKey + ":" + item.value}
              size="mini"
              icon="close"
              content={groupKey + ": " + item.text}
              onClick={async () =>
                await removeFilter({
                  group: groupKey,
                  item: item,
                })
              }
            />
          );
        });
      } else if (typeof filterParams[groupKey] === "string") {
        const value = filterParams[groupKey];
        buttons.push(
          <Button
            basic
            key={groupKey + ":" + value}
            size="mini"
            icon="close"
            content={groupKey + ": " + value}
            onClick={async () =>
              await removeFilter({
                group: groupKey,
                item: value,
              })
            }
          />
        );
      } else if (
        !!filterParams[groupKey] &&
        filterParams[groupKey].constructor === Object
      ) {
        const value = filterParams[groupKey];
        const { from, to } = value;
        let content = null;
        if (!!from && !!to) {
          content = `${groupKey} Date From: ${moment(from).format(
            "LL"
          )} - To: ${moment(to).format("LL")}`;
        } else if (!!from) {
          content = `${groupKey} From: ${moment(from).format("LL")}`;
        } else if (!!to) {
          content = `${groupKey} Up to: ${moment(to).format("LL")}`;
        }

        if (content) {
          buttons.push(
            <Button
              basic
              key={groupKey + ":" + from + to}
              size="mini"
              icon="close"
              content={content}
              onClick={async () =>
                await removeFilter({
                  group: groupKey,
                  item: value,
                })
              }
            />
          );
        }
      }
    });

    for (const customFilter of customFilters) {
      if (customFilter.label !== null) {
        buttons.push(
          <Button
            basic
            key={customFilter.uuid}
            size="mini"
            icon="close"
            content={customFilter.label}
            onClick={() => {
              handleRemoveCustomFilter(customFilter.uuid, true);
            }}
          />
        );
      }
    }

    for (const tagFilter of tagsFilters) {
      if (tagFilter.tags.length) {
        const tagNames = displayTagNames(tagFilter.tags);
        buttons.push(
          <Button
            basic
            key={tagFilter.uuid}
            size="mini"
            icon="close"
            content={`${tagFilter.label} ${tagNames}`}
            onClick={() => {
              handleRemoveTagFilter(tagFilter.uuid, true);
            }}
          />
        );
      }
    }

    if (buttons.length) {
      return (
        <div className="filter-buttons">
          <Button
            basic
            key="removeAll"
            size="mini"
            icon="close"
            content="Clear Filters"
            onClick={removeAllFilters}
          />
          {buttons}
        </div>
      );
    }
    return null;
  };

  const handleUpdateQueryFilters = useCallback(() => {
    onUpdateQueryFilters(queryParams);
  }, [queryParams]);

  useEffect(() => {
    handleUpdateQueryFilters();
  }, [handleUpdateQueryFilters]);

  return (
    <>
      <div className="table-top">
        <div>
          <div className="left">
            {headerIcon || header ? (
              <Header>
                {headerIcon ? <Icon name={headerIcon} /> : null}
                {header}
              </Header>
            ) : null}
            <div className="create button">{createButton}</div>
            {enableSearch && onSearch ? (
              <SearchInput
                name="search"
                query={searchQuery}
                onUpdateQuery={onSearch}
              />
            ) : null}
            {enableAdvancedSearch || !!filterOptions ? (
              <AdvancedSearch
                filters={filterOptions}
                filterParams={filterParams}
                tagsFilters={tagsFilters}
                customFilters={customFilters}
                updateFilterParams={handleUpdateFilterParams}
              />
            ) : null}
            {tableLeft && <>{tableLeft}</>}
          </div>
          <div className="right">
            {actions && (
              <Dropdown
                text="Actions"
                className="button mini actions"
                disabled={disableActions}
              >
                <Dropdown.Menu direction="left">{actions}</Dropdown.Menu>
              </Dropdown>
            )}
            {enableSettings && (
              <>
                {/*TODO: implement table settings*/}
                <TableSettings
                  allColumns={columns}
                  visibleColumns={visibleColumns}
                  freezedColumns={frozenColumns}
                  updateTableSettings={handleUpdateTableSettings}
                  enableCustomFieldsSettings={enableCustomFieldsSettings}
                  customFields={customFields}
                />
              </>
            )}
          </div>
        </div>
        <div>{getFilterButtons()}</div>
      </div>
    </>
  );
};

export default TableHeader;

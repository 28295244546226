import React, { useState, useEffect } from "react";
import { Table, Icon } from "semantic-ui-react";
import orderBy from "lodash/orderBy";

const SortableTable = ({
  columns = [],
  data: tableData = [],
  keyExtractor,
  onRowClick,
  noDataText,
  loading = false,
  ...tableProps
}) => {
  const [data, setData] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    let sortInfo = null;
    let data = tableData.map((item, index) =>
      Object.keys(item).reduce((accum, key) => {
        const { cell, string, sort } =
          columns.find(({ value }) => value === key) || {};
        if (sort) {
          sortInfo = { column: key, direction: sort };
        }
        const rawValue = item[key];
        const args = [rawValue, item, index];
        const cellValue = cell ? cell(...args) : rawValue;
        const stringValue = string ? string(...args) : String(rawValue);
        return { ...accum, [key]: { rawValue, cellValue, stringValue } };
      }, {})
    );
    if (column && direction) {
      doSort(data, column, direction);
    } else if (sortInfo) {
      doSort(data, sortInfo.column, sortInfo.direction);
    } else {
      setData(data);
    }
  }, [tableData, columns]);

  const handleSort = clickedColumn => () => {
    if (column !== clickedColumn) {
      doSort(data, clickedColumn);
    } else {
      setData(data.reverse());
      setDirection(direction === "ascending" ? "descending" : "ascending");
    }
  };

  const doSort = (array, column, direction = "ascending") => {
    const dir = direction === "ascending" ? "asc" : "desc";
    const sorted = orderBy(array, `${column}.stringValue`, dir);
    setData(sorted);
    setColumn(column);
    setDirection(direction);
  };

  return (
    <Table
      sortable={data.length > 0}
      fixed
      selectable={data.length > 0 && !!onRowClick}
      {...tableProps}
    >
      <Table.Header>
        <Table.Row>
          {columns.map(({ header, value, headerProps }) => (
            <Table.HeaderCell
              key={value}
              sorted={column === value ? direction : null}
              onClick={handleSort(value)}
              content={header || value}
              {...headerProps}
            />
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.length === 0 ? (
          <Table.Row textAlign="center">
            <Table.Cell colSpan={columns.length}>
              {loading ? (
                <Icon loading name="circle notched" />
              ) : (
                noDataText || "No data"
              )}
            </Table.Cell>
          </Table.Row>
        ) : (
          data.map((item, index) => {
            const rawValues = Object.keys(data[index]).reduce(
              (accum, cur) => ({ ...accum, [cur]: data[index][cur].rawValue }),
              {}
            );
            return (
              <Table.Row
                key={keyExtractor ? keyExtractor(rawValues) : item.id.rawValue}
                onClick={() => onRowClick && onRowClick(rawValues, index)}
                style={{ cursor: onRowClick ? "pointer" : "auto" }}
              >
                {columns.map(({ value, cellProps }) => (
                  <Table.Cell
                    key={value}
                    content={item[value].cellValue}
                    {...cellProps}
                  />
                ))}
              </Table.Row>
            );
          })
        )}
      </Table.Body>
    </Table>
  );
};

export default SortableTable;

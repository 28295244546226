import React, { useState } from "react";
import RuvixxForm from "../../../components/RuvixxForm";
import RuvixxSelect from "../../../components/RuvixxSelect";
import TagService from "../../../services/Tag";
import TagCategoryService from "../../../services/TagCategory";

const BulkUpdateCategoryForm = ({ tagIds, onSuccess }) => {
  const [categoryId, setCategoryId] = useState(null);

  const handleSubmit = async () => {
    await TagService.bulkUpdateCategory(categoryId, tagIds);
    onSuccess();
  };

  return (
    <RuvixxForm ready={true} onSubmit={handleSubmit}>
      <RuvixxSelect
        label="Tag Category"
        value={categoryId}
        queryFn={TagCategoryService.getTagCategoryForFilters}
        createFn={async name => TagCategoryService.createTagCategory({ name })}
        allowAdditions
        clearable
        onChange={(_, { value }) => setCategoryId(value)}
        setValue={value => setCategoryId(value.id)}
      />
    </RuvixxForm>
  );
};

export default BulkUpdateCategoryForm;

import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import SequenceService from "../../../services/Sequence";
import AuditService from "../../../services/Audit";
import RuvixxForm from "../../../components/RuvixxForm";
import PropTypes from "prop-types";

import UserService from "../../../services/User";
import TagService from "../../../services/Tag";
import CallDispositionService from "../../../services/CallDispositions";
import CampaignService from "../../../services/Campaign";
import { TIME_UNITS, CALL_DISPOSITION_IDS } from "../../../constants/Constants";
import taskConstants from "../../../constants/Task";
import sequenceConstants from "../../../constants/Sequence";
import "./SequenceStepForm.scoped.scss";
import { startCase } from "../../../helpers/string";

const actionTypes = Object.keys(sequenceConstants.actionTypes).map(k => ({
  key: sequenceConstants.actionTypes[k],
  value: sequenceConstants.actionTypes[k],
  text: startCase(k),
}));

const timeUnits = TIME_UNITS.map(unit => ({
  key: unit,
  value: unit,
  text: startCase(unit),
}));

const taskTypes = taskConstants.types.map(taskType => ({
  key: taskType.id,
  value: taskType.id,
  text: taskType.name,
  icon: taskType.icon,
}));

const taskPriorities = taskConstants.priorities.map(taskPriority => ({
  key: taskPriority.id,
  value: taskPriority.id,
  text: taskPriority.name,
}));

const typeOfStatus = taskConstants.statusTypes.map(statusType => ({
  key: statusType.id,
  value: statusType.name,
  text: statusType.name,
}));

function SequenceStepForm({ sequence, onSuccess, stepIndex = null }) {
  const [formData, setFormData] = useState({
    auditLogEvent: undefined,
    customField: undefined,
    customFieldValue: undefined,
    actionType: sequenceConstants.actionTypes.task,
    taskType: undefined,
    assignee: undefined,
    priority: undefined,
    dueDateUnit: undefined,
    dueDateValue: undefined,
    taskName: undefined,
    taskDescription: undefined,
    automationAction: undefined,
    automationLevel: undefined,
    automationNewTags: [],
    automationNewCampaignStatus: undefined,
    automationName: undefined,
    automationDescription: undefined,
  });

  const [userOptions, setUserOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [dispositions, setDispositions] = useState([]);
  const [campaignStatuses, setCampaignStatuses] = useState([]);
  const [auditLogEvents, setAuditLogEvents] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchAuditLogEvents = useCallback(async () => {
    let auditLogEvents = await AuditService.getAuditLogEvents();
    const isFirstStep =
      stepIndex !== null ? stepIndex === 0 : sequence.steps.length === 0;
    auditLogEvents = [
      !isFirstStep && {
        text: "Previous step Completed",
        key: "previous_step_completed",
        value: "previous_step_completed",
      },
      ...auditLogEvents.map((event, index) => ({
        text: startCase(event, "_"),
        key: event,
        value: event,
      })),
    ].filter(i => !!i);
    setAuditLogEvents(auditLogEvents);
  }, []);

  const fetchUsers = useCallback(async () => {
    let userOptions = await UserService.getUsers();
    userOptions = userOptions.map(user => ({
      text: user.full_name,
      key: user.id,
      value: user.id,
    }));
    setUserOptions(userOptions);
  });

  const fetchTags = useCallback(async () => {
    const tags = await TagService.getTags();
    setTags(tags);
  });

  const fetchCallDispositions = useCallback(async () => {
    let dispositions = await CallDispositionService.getDispositionsForFilters();
    dispositions = [
      {
        name: "callback_contact",
        id: CALL_DISPOSITION_IDS.CALLBACK,
      },
      {
        name: "skipped",
        id: CALL_DISPOSITION_IDS.SKIPPED,
      },
      ...dispositions,
    ];
    setDispositions(dispositions);
  });

  const fetchCampaignStatuses = useCallback(async () => {
    let resp = await CampaignService.getCampaignStatuses();
    let statuses = resp.map(status => ({
      key: status.id,
      value: status.name,
      text: status.name,
    }));
    setCampaignStatuses(statuses);
  });

  useEffect(() => {
    fetchAuditLogEvents();
    fetchUsers();
    fetchCampaignStatuses();
    fetchTags();
    fetchCallDispositions();

    let formDataCopy = { ...formData };
    if (stepIndex !== null) {
      let step = sequence.steps[stepIndex];
      let customFieldValue = step.trigger.custom_field_value;

      if (step.trigger.custom_field === "tag") {
        try {
          const customFieldObject = JSON.parse(step.trigger.custom_field_value);
          customFieldValue = customFieldObject ? customFieldObject.name : null;
        } catch {}
      }

      formDataCopy = {
        auditLogEvent: step.trigger.event,
        customField: step.trigger.custom_field,
        customFieldValue,
        actionType: step.action_type,
        taskType: step.task_type,
        assignee: step.assignee,
        priority: step.priority,
        dueDateUnit: step.due_date.unit,
        dueDateValue: step.due_date.value,
        taskName: step.task_name,
        taskDescription: step.description,
        automationAction: step.automation_action,
        automationLevel: step.automation_level,
        automationNewTags: JSON.parse(step.automation_new_tags),
        automationNewCampaignStatus: step.automation_new_campaign_status,
        automationName: step.automation_name,
        automationDescription: step.automation_description,
      };
    }
    setFormData(formDataCopy);
  }, []);

  const handleChange = event => {
    const formDataCopy = { ...formData };
    formDataCopy[event.target.name] = event.target.value;
    setFormData(formDataCopy);
  };

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    if (name === "auditLogEvent") {
      formDataCopy["customField"] = "";
      formDataCopy["customFieldValue"] = "";
    }
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const handleSubmit = async e => {
    let {
      auditLogEvent,
      customField,
      customFieldValue,
      actionType,
      taskType,
      assignee,
      priority,
      dueDateUnit,
      dueDateValue,
      taskName,
      taskDescription,
      automationAction,
      automationLevel,
      automationNewTags,
      automationNewCampaignStatus,
      automationName,
      automationDescription,
    } = formData;
    customField = customField || undefined;
    customFieldValue = customFieldValue || undefined;

    const step = {
      trigger: {
        event: auditLogEvent,
        custom_field: isTagTrigger
          ? "tag"
          : isCallTrigger
          ? "new_disposition"
          : customField,
        custom_field_value: isTagTrigger
          ? JSON.stringify({ name: customFieldValue })
          : customFieldValue,
      },
      action_type: actionType,
      task_type: taskType,
      task_name: taskName,
      description: taskDescription,
      assignee: assignee,
      priority: priority,
      due_date: {
        unit: dueDateUnit,
        value: parseInt(dueDateValue),
      },
      automation_action: automationAction,
      automation_level: automationLevel,
      automation_new_tags: JSON.stringify(automationNewTags),
      automation_new_campaign_status: automationNewCampaignStatus,
      automation_name: automationName,
      automation_description: automationDescription,
    };

    try {
      if (stepIndex !== null) {
        await SequenceService.editSequenceStep(sequence.id, stepIndex, step);
      } else {
        await SequenceService.createSequenceStep(sequence.id, step);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  const renderTaskRelatedFields = () => (
    <>
      <Form.Select
        inline
        onChange={handleSelect}
        value={formData.taskType}
        label="Create a Task"
        name="taskType"
        options={taskTypes}
        placeholder="Select a task type"
        required
      />
      <Form.Select
        inline
        search
        onChange={handleSelect}
        value={formData.assignee}
        label="Assignee"
        name="assignee"
        options={userOptions}
        placeholder="Select an assignee"
        required
      />
      <Form.Select
        inline
        clearable
        onChange={handleSelect}
        value={formData.priority}
        label="Priority"
        name="priority"
        options={taskPriorities}
        placeholder="Select a priority"
      />
      <Form.Group inline>
        <Form.Input
          inline
          name="dueDateValue"
          value={formData.dueDateValue}
          type="number"
          label="Due Date"
          onChange={handleChange}
          required
          width={4}
        />
        <Form.Select
          inline
          onChange={handleSelect}
          value={formData.dueDateUnit}
          className="time-unit"
          name="dueDateUnit"
          options={timeUnits}
          placeholder="Time unit"
          required
          width={3}
        />
        <div className="inline field">
          <label>After task creation</label>
        </div>
      </Form.Group>
      <Form.Input
        inline
        name="taskName"
        value={formData.taskName}
        label="Task Name"
        maxLength={255}
        onChange={handleChange}
      />
      <Form.Input
        inline
        name="taskDescription"
        value={formData.taskDescription}
        label="Task Description"
        onChange={handleChange}
      />
    </>
  );

  const renderAutomatedActionSelector = () => {
    if (formData.automationAction === undefined) {
      return null;
    }
    const action = sequenceConstants.automationActions.find(
      ({ id }) => id === formData.automationAction
    );
    if (!action) {
      return null;
    }
    return action.key === "add_tags" ? (
      <Form.Select
        required
        inline
        multiple
        search
        name="automationNewTags"
        value={formData.automationNewTags}
        label="Tags to Add"
        options={tags.map(({ id, name, color }) => ({
          key: id,
          text: name,
          value: id,
          ...(color && {
            className: "hideText",
            label: {
              content: name,
              className: "ctag",
              style: { backgroundColor: color },
            },
          }),
        }))}
        onChange={handleSelect}
        renderLabel={item => item.label || item.text}
      />
    ) : (
      <Form.Select
        required
        inline
        search
        name="automationNewCampaignStatus"
        value={formData.automationNewCampaignStatus}
        label="Campaign Status"
        options={campaignStatuses.map(({ key, text }) => ({
          key,
          value: key,
          text,
        }))}
        onChange={handleSelect}
      />
    );
  };

  const renderAutomationRelatedFields = () => (
    <>
      <Form.Group inline className="step-conditions">
        <Form.Select
          inline
          onChange={handleSelect}
          value={formData.automationAction}
          label="Automated Action"
          name="automationAction"
          options={sequenceConstants.automationActions.map(({ id, name }) => ({
            key: id,
            value: id,
            text: name,
          }))}
          placeholder="Select action to perform"
          required
        />
        {!isEntityOrContactTrigger() && (
          <Form.Select
            inline
            onChange={handleSelect}
            value={formData.automationLevel}
            label="Change at"
            name="automationLevel"
            options={sequenceConstants.automationLevels.map(({ id, name }) => ({
              key: id,
              value: id,
              text: name,
            }))}
            placeholder="Select level"
            required
          />
        )}
      </Form.Group>
      {renderAutomatedActionSelector()}
      <Form.Input
        inline
        name="automationName"
        value={formData.automationName}
        label="Automation Name"
        onChange={handleChange}
      />
      <Form.Input
        inline
        name="automationDescription"
        value={formData.automationDescription}
        label="Automation Description"
        onChange={handleChange}
      />
    </>
  );

  const isReady = () => {
    const {
      auditLogEvent,
      actionType,
      taskType,
      assignee,
      dueDateUnit,
      dueDateValue,
      automationAction,
      automationLevel,
      automationNewTags,
      automationNewCampaignStatus,
    } = formData;
    let ready = !!auditLogEvent && !!actionType;
    if (actionType === sequenceConstants.actionTypes.task) {
      ready =
        ready && !!taskType && !!assignee && !!dueDateUnit && !!dueDateValue;
    } else if (
      actionType === sequenceConstants.actionTypes.automation &&
      !!automationAction
    ) {
      const action = sequenceConstants.automationActions.find(
        ({ id }) => id === automationAction
      );
      if (action.key === "add_tags") {
        ready = ready && automationNewTags && automationNewTags.length > 0;
      } else {
        ready = ready && !!automationNewCampaignStatus;
      }
      if (!isEntityOrContactTrigger()) {
        ready = ready && !!automationLevel;
      }
    }
    return ready;
  };

  const isEntityOrContactTrigger = () => {
    return (
      formData.auditLogEvent &&
      (formData.auditLogEvent.startsWith("entity_") ||
        formData.auditLogEvent.startsWith("contact_"))
    );
  };

  const isTagTrigger =
    !!formData.auditLogEvent && formData.auditLogEvent.includes("tag");
  const isStatusTrigger =
    !!formData.auditLogEvent && formData.auditLogEvent.includes("status");
  const isCallTrigger =
    !!formData.auditLogEvent && formData.auditLogEvent.includes("call");
  const showCustomField =
    !!formData.auditLogEvent &&
    !isTagTrigger &&
    !isStatusTrigger &&
    !isCallTrigger;

  return (
    <RuvixxForm
      ready={isReady()}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      error={error}
      errorMessage={errorMessage}
      className="sequence-step-form"
    >
      <Form.Group inline className="step-conditions">
        <Form.Select
          inline
          clearable
          style={{ marginLeft: !formData.auditLogEvent ? "40px" : "0px" }}
          search
          onChange={handleSelect}
          value={formData.auditLogEvent}
          label="When"
          name="auditLogEvent"
          options={auditLogEvents}
          placeholder="Select Audit Log event"
          width={6}
          required
        />
        {showCustomField && (
          <>
            <Form.Input
              inline
              name="customField"
              label="Custom Field"
              value={formData.customField}
              onChange={handleChange}
              width={5}
              disabled={formData.auditLogEvent === "previous_step_completed"}
            />
            <Form.Input
              inline
              name="customFieldValue"
              label="Value"
              value={formData.customFieldValue}
              onChange={handleChange}
              width={5}
              disabled={formData.auditLogEvent === "previous_step_completed"}
            />
          </>
        )}
        {isStatusTrigger && (
          <>
            <Form.Select
              inline
              clearable
              className="status-select"
              name="customFieldValue"
              value={formData.customFieldValue}
              label="Status"
              search
              options={campaignStatuses}
              onChange={handleSelect}
            />
            <Form.Select
              inline
              clearable
              className="status-select"
              name="customField"
              value={formData.customField}
              label="Type"
              search
              options={typeOfStatus}
              onChange={handleSelect}
            />
          </>
        )}
        {isTagTrigger && (
          <Form.Select
            inline
            clearable
            name="customFieldValue"
            value={formData.customFieldValue}
            label="Tag"
            search
            options={tags.map(item => ({
              key: item.id,
              text: item.name,
              value: item.name,
              ...(item.color && {
                className: "hideText",
                label: {
                  content: item.name,
                  className: "ctag",
                  style: { backgroundColor: item.color },
                },
              }),
            }))}
            onChange={handleSelect}
            renderLabel={item => item.label || item.text}
          />
        )}
        {isCallTrigger && (
          <Form.Select
            inline
            clearable
            name="customFieldValue"
            value={formData.customFieldValue}
            label="Disposition"
            search
            options={dispositions.map(item => ({
              key: item.id,
              text: startCase(item.name, "_"),
              value: item.name,
            }))}
            onChange={handleSelect}
            renderLabel={item => item.label || item.text}
          />
        )}
      </Form.Group>
      <Form.Select
        inline
        onChange={handleSelect}
        value={formData.actionType}
        label="Action Type"
        name="actionType"
        options={actionTypes}
        placeholder="Select an action type"
        required
      />
      {formData.actionType === sequenceConstants.actionTypes.task
        ? renderTaskRelatedFields()
        : renderAutomationRelatedFields()}
    </RuvixxForm>
  );
}

SequenceStepForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default SequenceStepForm;

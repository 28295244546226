import { doRequest, applyFilters } from "./helpers";

const RoleService = {
  getRoles: () => {
    return doRequest("GET", "/role/for_filters");
  },

  getRolesTable: filters => {
    let path = "/role/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  },

  getRolesAsAdmin: () => {
    return doRequest("GET", "/admin/role/for_filters");
  },
  getRolesTableAsAdmin: filters => {
    let path = "/admin/role";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  },
  createRole: (name, permissions) => {
    return doRequest("POST", "/admin/role", {
      data: {
        name,
        permissions,
      },
    });
  },
  updateRole: (roleId, name, permissions) => {
    return doRequest("PUT", `/admin/role/${roleId}`, {
      data: {
        name,
        permissions,
      },
    });
  },
  deleteRole: roleId => {
    return doRequest("DELETE", `/admin/role/${roleId}`);
  },
};

export default RoleService;

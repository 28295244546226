import CONSTANTS from "../constants/Constants";
import { applyFilters, doRequest } from "./helpers";

export default class ContactService {
  static async getContact(id) {
    return doRequest("GET", `/contact/${id}`);
  }

  static async getContacts(filters = null) {
    let path = `/contact/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getContactsForFilters(filters = null) {
    let path = "/contact/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async addReferral(
    contact_id,
    referred_contact_id,
    notes,
    dial_session_id
  ) {
    return doRequest("POST", `/contact/referral`, {
      data: {
        contact_id,
        referred_contact_id,
        notes,
        dial_session_id,
      },
    });
  }

  static async getCustomFields() {
    let path = `/contact/custom_fields`;
    return doRequest("GET", path);
  }

  static async getEmails(contact_id, filters = null) {
    let path = `/contact/${contact_id}/emails/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getEmailAddresses(contact_id) {
    let path = `/contact/${contact_id}/email_addresses/`;
    return doRequest("GET", path);
  }

  static async getEmailableContacts(filters) {
    let path = `/contact/emailable/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async resendEmail(contact_id, campaign_target_email_id) {
    const response = doRequest(
      "POST",
      `/contact/${contact_id}/email/${campaign_target_email_id}/resend`
    );
    return response;
  }

  static async sendEmail({
    contact_id,
    template,
    attachments,
    senderName,
    cc,
    bcc,
    ccAccountManagers,
    ccExternalAccountManagers,
    fromUser,
    smtp_config_id,
    provider,
    footerId,
    signatureId,
    campaignId,
    emailAddressIds,
    follow_up,
  }) {
    return doRequest("POST", `/contact/${contact_id}/email`, {
      data: {
        status: CONSTANTS.EMAIL_STATUS.PENDING,
        template_id: template,
        sender_name: senderName,
        cc,
        bcc,
        attachments,
        cc_account_managers: ccAccountManagers,
        cc_external_account_managers: ccExternalAccountManagers,
        from_user: fromUser,
        provider,
        footer_id: footerId || null,
        signature_id: signatureId,
        ...(smtp_config_id && { smtp_config_id }),
        campaign_id: campaignId,
        email_address_ids: emailAddressIds,
        follow_up,
      },
    });
  }

  static async getCampaigns(contact_id) {
    return doRequest("GET", `/contact/${contact_id}/campaigns`);
  }

  static async getCampaignTable(contact_id, filters = null) {
    let path = `/contact/${contact_id}/campaign_table`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async mergeContacts(data) {
    return doRequest("POST", "/contact/merge", { data });
  }

  static async checkContactCallableHours(contact_id) {
    let path = `/contact/is_callable_hours/${contact_id}`;

    return doRequest("GET", path);
  }

  static async getAvailableRoles(filters = null) {
    let path = "/contact/titles";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }
}

import React from "react";
import { Grid } from "semantic-ui-react";
import setPageTitle from "../../helpers/title";
import "./NotFoundItem.scoped.scss";

const NotFoundItem = props => {
  setPageTitle("Page not found");

  return (
    <Grid centered>
      <Grid.Column width={7} className="notFound">
        <h1>404</h1>
        <h3>Not Found</h3>
        <p>
          The item you're trying to view does not exists or has been deleted
        </p>
        <p>
          If you believe this is an error please contact support if this issue
          perists
        </p>
      </Grid.Column>
    </Grid>
  );
};

export default NotFoundItem;

import { applyFilters, doRequest } from "./helpers";

export default class TableExportService {
  static async exportTable(tableName, filters = {}, savedSearchName = null) {
    let path = `/export/${tableName}`;
    if (savedSearchName) {
      filters = { ...filters, saved_search_name: savedSearchName };
    }
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }
}

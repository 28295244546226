import React, { useState, useEffect } from "react";
import { Form, Icon, Table } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { differenceWith } from "lodash";

import TableButton from "components/TableButton";

const NoCampaignStatuses = () => (
  <Table basic compact>
    <Table.Body>
      <Table.Row>
        <Table.Cell textAlign="center">
          <em>No Campaign Statuses included in this preset</em>
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

const CampaignStatusSelector = ({
  campaignStatuses,
  setCampaignStatuses,
  allCampaignStatuses,
}) => {
  const [options, setOptions] = useState([]);
  const [selection, setSelection] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const newOptions = differenceWith(
      allCampaignStatuses,
      campaignStatuses,
      (a, b) => a.id === b.id
    ).map(({ name }) => name);
    setOptions(newOptions);
  }, [allCampaignStatuses, campaignStatuses]);

  const handleSelect = (_, { value }) => {
    setSearchQuery(value);
    setSelection(value);
  };

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(campaignStatuses);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCampaignStatuses(items);
  };

  const handleSearchChange = (_, { searchQuery }) => {
    setSearchQuery(searchQuery);
  };

  const handleBlur = () => {
    if (searchQuery) {
      setSelection(searchQuery);
    }
  };

  const filterByName = name => {
    return (item = {}) =>
      item.name && name && item.name.toLowerCase() === name.toLowerCase();
  };

  const handleAdd = () => {
    if (!selection) {
      return;
    }
    const byName = filterByName(selection);
    const exists = campaignStatuses.some(byName);
    if (!exists) {
      const { id, name } = allCampaignStatuses.find(byName) || {};
      const newCampaignStatuses = [
        ...campaignStatuses,
        { id, name: name || selection },
      ];
      setCampaignStatuses(newCampaignStatuses);
    }
    setSearchQuery("");
    setSelection(null);
  };

  const handleDelete = name => {
    const index = campaignStatuses.findIndex(item => item.name === name);
    if (index !== -1) {
      const newCampaignStatuses = [
        ...campaignStatuses.slice(0, index),
        ...campaignStatuses.slice(index + 1),
      ];
      setCampaignStatuses(newCampaignStatuses);
    }
  };

  return (
    <>
      <Form.Group>
        <Form.Select
          label="Campaign Statuses"
          inline
          required
          search
          noResultsMessage={null}
          options={[...options].map(name => ({
            key: name,
            text: name,
            value: name,
          }))}
          value={selection}
          searchQuery={searchQuery}
          onChange={handleSelect}
          onSearchChange={handleSearchChange}
          onBlur={handleBlur}
          selectOnBlur={false}
        />
        <Form.Button
          icon="plus"
          content="Add"
          size="tiny"
          basic
          onClick={handleAdd}
        />
      </Form.Group>
      {campaignStatuses.length === 0 ? (
        <NoCampaignStatuses />
      ) : (
        <DragDropContext
          onDragEnd={handleDragEnd}
          getContainer={() => document.body}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Table basic compact definition>
                  <Table.Body>
                    {campaignStatuses.map(({ name }, index) => (
                      <Draggable key={name} draggableId={name} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              top: snapshot.isDragging ? "50" : "initial",
                              left: snapshot.isDragging ? "0" : "initial",
                            }}
                          >
                            <Table.Row
                              key={name}
                              style={{ display: "inline-table", width: "100%" }}
                            >
                              <Table.Cell
                                style={{ width: "5px", background: "white" }}
                              >
                                <Icon name="bars" />
                              </Table.Cell>
                              <Table.Cell
                                style={{
                                  background: "rgb(0,0,0,0.3)",
                                  color: "black",
                                }}
                              >
                                {name}
                              </Table.Cell>
                              <Table.Cell collapsing>
                                <TableButton
                                  delete
                                  onClick={() => handleDelete(name)}
                                />
                              </Table.Cell>
                            </Table.Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </Table.Body>
                </Table>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default CampaignStatusSelector;

import React, { useEffect, useState } from "react";
import { Form, Segment } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import EmailFooterService from "../../../services/EmailFooters";
import EmailTemplateService from "../../../services/EmailTemplate";
import ReactHtmlParser from "react-html-parser";

function EmailFooterForm({ onSuccess, isClone, emailFooterId = null }) {
  const [footerName, setFooterName] = useState("");
  const [footerHtml, setFooterHtml] = useState("");
  const [templateOptions, setTemplateOptions] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [templateHtml, setTemplateHtml] = useState("");
  const [previewHtml, setPreviewHtml] = useState("");

  const fetchFooter = async email_footer_id => {
    let footer = await EmailFooterService.getEmailFooter(email_footer_id);
    setFooterName(footer.name);
    setFooterHtml(footer.html);
    if (isClone) {
      setFooterName("");
    }
  };

  const decodeHtml = html => {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const fetchTemplates = async () => {
    let templates = await EmailTemplateService.getEmailTemplatesForFilters();
    let templateOptions = templates.map(template => ({
      key: template.id,
      value: template.id,
      text: template.name,
      html: template.html,
    }));
    setTemplateOptions(templateOptions);
  };

  const fetchTemplateHtml = async template_id => {
    let template = await EmailTemplateService.getEmailTemplate(template_id);
    let unescaped_template = decodeHtml(template.html);
    setTemplateHtml(unescaped_template);
  };

  const combined_template = (template, footer) => {
    let before = template.indexOf("</body>");
    let combined =
      template.substring(0, before) + footer + template.substring(before);
    setPreviewHtml(combined);
  };

  const handleTemplateChange = (e, data) => {
    fetchTemplateHtml(data.value);
  };

  const handleSubmit = async () => {
    if (emailFooterId) {
      if (isClone) {
        await EmailFooterService.createEmailFooter(footerName, footerHtml);
      } else {
        await EmailFooterService.editEmailFooter(
          emailFooterId,
          footerName,
          footerHtml
        );
      }
    } else {
      await EmailFooterService.createEmailFooter(footerName, footerHtml);
    }
  };

  const handleChange = (e, data) => {
    if (data.name === "email_footer_name") {
      setFooterName(data.value);
    } else {
      setFooterHtml(data.value);
    }
  };

  useEffect(() => {
    if (emailFooterId !== null) {
      fetchFooter(emailFooterId);
    }
    fetchTemplates();
  }, [emailFooterId]);

  useEffect(() => {
    combined_template(templateHtml, footerHtml);
  }, [footerHtml]);

  useEffect(() => {
    combined_template(templateHtml, footerHtml);
  }, [templateHtml]);

  return (
    <>
      <RuvixxForm
        ready={!!footerName && !!footerHtml}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        successHeader="Email Footer Created"
        successMessage="Succesfully created a new footer"
      >
        <Form.Input
          fluid
          name="email_footer_name"
          label="Name"
          value={footerName}
          onChange={handleChange}
          required
        />
        <Form.Select
          required
          label="Choose a Preview Template"
          name="templateId"
          selection
          options={templateOptions}
          onChange={handleTemplateChange}
        />
        <Form.TextArea
          name="email_footer_html"
          label="Custom HTML"
          style={{ minWidth: "100%" }}
          rows={4}
          onChange={handleChange}
          value={footerHtml}
          required
        />
        <label>HTML Preview</label>
        <Segment>{ReactHtmlParser(previewHtml)}</Segment>
      </RuvixxForm>
    </>
  );
}

export default EmailFooterForm;

import React, { useState } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import CustomFieldConfigForm from "../forms/CustomFieldConfig";

export default function CustomFieldConfigModal(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const onSuccess = async => {
    props.onSuccess();
    handleClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="large"
      closeIcon
      trigger={
        <Button
          content="New Custom Field Config"
          onClick={handleOpen}
          size="tiny"
          className="item-adder"
        />
      }
    >
      <Header content={"New Custom Field Config"} />
      <Modal.Content>
        <CustomFieldConfigForm onSuccess={onSuccess} />
      </Modal.Content>
    </Modal>
  );
}

import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import { setRouteParams, useCurrentPath } from "./helpers";

const useRouteParams = defaultParams => {
  const params = useParams();
  const currentPath = useCurrentPath();
  const [value, setValue] = useState(null);
  const updateValues = useCallback(
    (values, replace) => {
      const newParams = setRouteParams(currentPath, values, replace);
      setValue(newParams);
    },
    [params]
  );
  const onSetValue = useCallback(
    newValues => {
      newValues = { ...params, ...newValues };
      updateValues(newValues);
    },
    [updateValues]
  );
  useEffect(() => {
    if (value !== null) {
      return;
    }
    const newRouteParams = {
      ...defaultParams,
      ...omitBy(params, isUndefined),
    };
    updateValues(newRouteParams, true);
  }, [value, params, defaultParams, updateValues]);
  return [value || {}, onSetValue];
};

export default useRouteParams;

import React from "react";

import DataJobService from "../../../services/DataJob";
import AuthService from "../../../services/Auth";
import BaseTable from "../../../components/BaseTable";

import "../../../styles/campaign.scss";
import "../../../styles/table.scss";

class DataJobErrorsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = DataJobService.getDataJobErrors;

    // TODO: any way to use the same param?
    this.queryArgs = [this.props.dataJobId];
    this.downloadParams = { data_job_id: this.props.dataJobId };

    this.state = {
      ...this.state,
      header: "Import Validation Report",
      // headerIcon: "mail outline",
      className: "DataJobErrors",
      tableName: "data_job_errors",
      noDataText: "No validation errors or warnings found with filters",
      createButton: null,
      exportTableName: "DataJobErrorsTable",
      useParamManager: false,
    };
  }

  updateFilterOptions = async () => {
    let filters = [
      {
        key: "error_level",
        title: "Error Level",
        type: "select",
        data: [
          { id: "WARNING", name: "WARNING" },
          { id: "ERROR", name: "ERROR" },
        ],
      },
      {
        key: "error_type",
        title: "Error Type",
        type: "select",
        data: [
          { id: "data", name: "DATA" },
          { id: "validation", name: "VALIDATION" },
        ],
      },
    ];
    this.setState({ filters });
  };

  async componentDidMount() {
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.updateFilterOptions();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Row Number",
        width: 25,
        accessor: "row_number",
      },
      {
        Header: "Error Type",
        width: 50,
        accessor: "error_type",
        Cell: ({ value }) => value.toUpperCase(),
      },
      {
        Header: "Error Level",
        width: 50,
        accessor: "error_level",
      },
      {
        Header: "Error Message",
        accessor: "error_message",
      },
      {
        Header: "Row Data",
        accessor: "row_data",
        Cell: ({ value }) => JSON.stringify(value),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return this.renderTable();
  }
}

export default DataJobErrorsTable;

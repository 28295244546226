import React from "react";
import { Grid, Header, Icon } from "semantic-ui-react";

import SequencesTable from "./SequencesTable";

export function SequenceList() {
  return (
    <Grid className="bg list-grid" divided>
      <Grid.Row>
        <Grid.Column className="eight wide table-top-buttons">
          <Header>Sequences</Header>
        </Grid.Column>
        <Grid.Column className="eight wide table-top-buttons"></Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="sixteen wide">
          <SequencesTable />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

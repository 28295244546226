import { useCallback, useEffect, useState } from "react";
import useStore from "./useStore";

const useAuthenticated = () => {
  const userAuth = useStore("userAuth");
  const shouldChangePassword = useStore("shouldChangePassword");

  const getValue = useCallback(() => {
    return !!(userAuth?.jwt && !shouldChangePassword);
  }, [userAuth, shouldChangePassword]);

  const [value, setValue] = useState(getValue());

  useEffect(() => {
    const newValue = getValue();
    setValue(newValue);
  }, [getValue]);

  return value;
};

export default useAuthenticated;

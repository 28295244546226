import React, { useEffect, useState } from "react";
import { Form, Select } from "semantic-ui-react";
import moment from "moment";

import DateTimePicker from "../../../components/DateTimePicker";

function EmailScheduleSendOptionForm({
  timeZone,
  timeZones,
  sendNow,
  emailDeliveryTime,
  info,
}) {
  const [runContinuously, setRunContinuously] = useState(true);
  const [runOnce, setRunOnce] = useState(false);
  const [runUntil, setRunUntil] = useState(false);
  const [runDateLimit, setRunDateLimit] = useState(null);
  const [runDateLimitTimeZone, setrunDateLimitTimeZone] = useState(null);
  const [sendOptions, setSendOptions] = useState({});

  useEffect(() => {
    if (info["schedule_send_option"]) {
      let options = info["schedule_send_option"];
      setRunOnce(options["run_once"]);
      setRunContinuously(options["run_continuously"]);
      setRunUntil(options["run_until"]);
      setRunDateLimit(
        getEmailDeliveryTime(
          options["run_date_limit"],
          options["run_date_limit_time_zone"]
        )
      );
      setrunDateLimitTimeZone(options["run_date_limit_time_zone"]);
    }
  }, [info]);

  useEffect(() => {
    info["schedule_send_option"] = sendOptions;
  }, [sendOptions]);

  useEffect(() => {
    setSendOptions({
      run_once: runOnce,
      run_continuously: runContinuously,
      run_until: runUntil,
    });
  }, [runOnce, runContinuously, runUntil]);

  useEffect(() => {
    setSendOptions(prevState => ({
      ...prevState,
      run_date_limit_time_zone: runDateLimitTimeZone || "UTC",
    }));
    setSendOptions(prevState => ({
      ...prevState,
      run_date_limit: runDateLimit,
    }));
  }, [runDateLimit, runDateLimitTimeZone]);

  const handleCheck = (data, value) => {
    switch (value.name) {
      case "continuous":
        setRunContinuously(true);
        setRunOnce(false);
        setRunUntil(false);
        setrunDateLimitTimeZone(null);
        setRunDateLimit(null);
        break;
      case "once":
        setRunContinuously(false);
        setRunOnce(true);
        setRunUntil(false);
        setrunDateLimitTimeZone(null);
        setRunDateLimit(null);
        break;
      case "date":
        setRunContinuously(false);
        setRunOnce(false);
        setRunUntil(true);
        break;
    }
  };

  const handleDateChange = value => {
    setRunDateLimit(value);
  };

  const handleTimeZoneChange = value => {
    setrunDateLimitTimeZone(value);
  };

  const getEmailDeliveryTime = (dateTimeStr, timeZone) => {
    if (dateTimeStr) {
      return moment
        .utc(dateTimeStr)
        .tz(timeZone || "UTC")
        .utcOffset(0, true);
    } else {
      return moment().utc().startOf("day");
    }
  };

  return (
    <Form.Group grouped style={{ alignItems: "flex-start" }}>
      <Form.Group inline style={{ margin: 0 }}>
        <Form.Field label="Email Schedule Option" required />
        <Form.Radio
          label="Run Continuously"
          name="continuous"
          checked={runContinuously}
          onChange={handleCheck}
        />
        <Form.Radio
          label="Run Once"
          name="once"
          checked={runOnce}
          onChange={handleCheck}
        />
        <Form.Radio
          label="Run Until"
          name="date"
          checked={runUntil}
          onChange={handleCheck}
        />
      </Form.Group>
      {runUntil && (
        <Form.Group inline style={{ paddingTop: "1em" }}>
          <DateTimePicker
            required={false}
            name="emailDateLimit"
            label="Schedule Time & Date Limit"
            dateTime={runDateLimit}
            timeZone="UTC"
            dateFormat="YYYY-MM-DD"
            style={{ marginTop: "0.5em", marginLeft: "1em", width: "250px" }}
            setDateTime={runDateLimit => handleDateChange(runDateLimit)}
          />
          <Form.Field
            style={{ marginTop: "0.5em", marginLeft: "1em", flex: 1 }}
          >
            <label style={{ display: "block" }}>Time Zone</label>
            <Select
              value={runDateLimitTimeZone ? runDateLimitTimeZone : timeZone}
              options={timeZones}
              onChange={(_, { value: timeZone }) =>
                handleTimeZoneChange(timeZone)
              }
            />
          </Form.Field>
        </Form.Group>
      )}
    </Form.Group>
  );
}

export default EmailScheduleSendOptionForm;

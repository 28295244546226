import React from "react";
import { Button, Divider, Icon, Loader } from "semantic-ui-react";
import { ViewContactsModal } from "./Modals";
import "./SessionContacts.scoped.scss";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import withRoleCheck from "../../../components/hocs/withRoleCheck";

const StartSessionButton = withRoleCheck(Button, [
  ACL_RELATIONSHIPS.dialSession.read,
  ACL_RELATIONSHIPS.callQueueNextContact.read,
  ACL_RELATIONSHIPS.callQueueAddContact.create,
  ACL_RELATIONSHIPS.disposition.read,
  ACL_RELATIONSHIPS.emailUrlForms.read,
  ACL_RELATIONSHIPS.twilioToken.read,
  ACL_RELATIONSHIPS.tag.read,
  ACL_RELATIONSHIPS.user.read,
  ACL_RELATIONSHIPS.campaignStatuses.read,
  ACL_RELATIONSHIPS.twilioDisposition.create,
  ACL_RELATIONSHIPS.twilioAddToCall.create,
  ACL_RELATIONSHIPS.twilioRemoveFromCall.create,
  ACL_RELATIONSHIPS.twilioUpdateParticipant.create,
]);

const StartDialSessionButton = ({
  id,
  callSessionsDisabled,
  callableContacts,
  setActiveSession,
  setTemporaryDisable,
}) => {
  const startSession = () => {
    window.open(
      `/dialer/${id}`,
      "_blank",
      "location=yes,height=750,width=1800,scrollbars=yes,status=yes"
    );
    setTemporaryDisable();
  };

  return (
    <StartSessionButton
      compact
      color="blue"
      disabled={callSessionsDisabled || !callableContacts}
      onClick={startSession}
    >
      <span className="contacts-count">Start Session</span>
      <Icon name="phone" />
    </StartSessionButton>
  );
};

function SessionSummary({
  dialSessionId,
  totalContacts,
  callableContacts,
  urgentContacts,
  urgentCallableContacts,
  loading,
}) {
  return (
    <div className="contacts-labels">
      {loading ? (
        <Loader size="mini" active inline content="Loading" />
      ) : (
        <div className="contacts-inline">
          <ViewContactsModal
            dialSessionId={dialSessionId}
            contactCount={totalContacts}
            btnText="Total Contacts"
            icon="user"
            modalHeader="All Contacts"
            isTotal
          />
          <ViewContactsModal
            dialSessionId={dialSessionId}
            contactCount={urgentContacts}
            btnColor="orange"
            btnText="Urgent"
            modalHeader="Urgent Contacts"
            isUrgent
          />
          <ViewContactsModal
            dialSessionId={dialSessionId}
            contactCount={urgentCallableContacts}
            btnColor="red"
            btnText="Callable Urgent"
            modalHeader="Urgent Callable Contacts"
            isUrgent
            isCallable
          />
          <ViewContactsModal
            dialSessionId={dialSessionId}
            contactCount={callableContacts}
            btnColor="green"
            btnText="Callable Contacts"
            modalHeader="Callable Contacts"
            icon="address book"
            isCallable
          />
        </div>
      )}
    </div>
  );
}

function SessionContacts({
  dialSessionId,
  totalContacts,
  callableContacts,
  urgentContacts,
  urgentCallableContacts,
  disabled,
}) {
  return (
    <div className="campaign-contacts">
      <div>
        <ViewContactsModal
          dialSessionId={dialSessionId}
          contactCount={totalContacts}
          modalHeader="All Contacts"
          btnClass="white"
          icon="user"
          iconColor="blue"
          disabled={disabled}
          sessionContacts
          isTotal
        />
        <ViewContactsModal
          dialSessionId={dialSessionId}
          contactCount={callableContacts}
          modalHeader="Callable Contacts"
          btnColor="green"
          icon="address book"
          disabled={disabled}
          sessionContacts
          isCallable
        />
        <ViewContactsModal
          dialSessionId={dialSessionId}
          contactCount={urgentContacts}
          modalHeader="Urgent Contacts"
          btnClass="light"
          btnColor="orange"
          btnText="Urgent"
          disabled={disabled}
          sessionContacts
          isUrgent
        />
        <ViewContactsModal
          dialSessionId={dialSessionId}
          contactCount={urgentCallableContacts}
          modalHeader="Urgent Callable Contacts"
          btnColor="orange"
          btnText="Callable Urgent"
          disabled={disabled}
          sessionContacts
          isUrgent
          isCallable
        />
      </div>
      <Divider />
    </div>
  );
}

export { SessionSummary, SessionContacts, StartDialSessionButton };

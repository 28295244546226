import { doRequest } from "./helpers";

const QuestionService = {
  getQuestionsForForm: formId => {
    const path = `/form_question/form/${formId}/questions`;
    return doRequest("GET", path);
  },
  updateQuestionsForForm: (formId, questions) => {
    const path = `/form_question/form/${formId}/questions`;
    return doRequest("PUT", path, { data: { questions } });
  },
  addFollowups: (formId, questions) => {
    const path = `/form_question/form/${formId}/followups`;
    return doRequest("PUT", path, { data: { questions } });
  },
};

export default QuestionService;

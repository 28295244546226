import React, {
  useState,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  Modal,
  Button,
  Label,
  Icon,
  Popup,
  Form,
  Input,
} from "semantic-ui-react";
import EmailUrlService from "../../services/EmailUrls";
import RuvixxForm from "../RuvixxForm";
import FadingPopup from "../FadingPopup";

const UrlDisplay = ({ generatedUrl, isEdit }) => {
  const inputRef = useRef();
  const [popupOpen, setPopupOpen] = useState(false);

  const selectText = () => {
    inputRef.current.select();
  };

  const handleCopy = () => {
    selectText();
    document.execCommand("copy");
    setPopupOpen(true);
  };

  return (
    <>
      <Input
        ref={inputRef}
        fluid
        value={generatedUrl}
        readOnly
        onClick={selectText}
      />
      {isEdit ? (
        <Button
          icon="external"
          content="Open Link"
          primary
          size="tiny"
          style={{ marginTop: 10 }}
          onClick={() => window.open(generatedUrl)}
        />
      ) : (
        <FadingPopup
          open={popupOpen}
          setOpen={setPopupOpen}
          content="Link copied to clipboard"
          position="bottom"
          trigger={
            <Button
              icon="copy"
              content="Copy Link"
              primary
              size="tiny"
              style={{ marginTop: 10 }}
              onClick={handleCopy}
            />
          }
        />
      )}
    </>
  );
};

const GenerateUrlForm = ({ setGeneratedUrl, campaignId, contactId }) => {
  const [emailUrl, setEmailUrl] = useState(null);
  const [emailUrls, setEmailUrls] = useState([]);

  const fetchEmailUrls = useCallback(async () => {
    let emailUrls = await EmailUrlService.getEmailUrls();
    emailUrls = emailUrls.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
    setEmailUrls(emailUrls);
  }, []);

  useEffect(() => {
    fetchEmailUrls();
  }, [fetchEmailUrls]);

  const handleSelect = (_, { value }) => {
    setEmailUrl(value);
  };

  const handleSubmit = async () => {
    const url = await EmailUrlService.generateUrl(
      emailUrl,
      campaignId,
      contactId
    );
    setGeneratedUrl(url);
  };

  return (
    <RuvixxForm
      ready={!!emailUrl}
      onSubmit={handleSubmit}
      submitButtonText="Generate Link"
    >
      <Form.Select
        inline
        required
        search
        label="Email URL"
        placeholder="Type to search"
        value={emailUrl}
        onChange={handleSelect}
        options={emailUrls}
      />
    </RuvixxForm>
  );
};

const GenerateUrlModal = ({
  campaignId,
  contactId,
  trigger,
  iconOnly = false,
  disabled = false,
  campaignTargetFormResponseId = null,
  emailUrlId = null,
  isEdit = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [generatedUrl, setGeneratedUrl] = useState(null);

  const handleOpen = () => {
    setModalOpen(true);
    if (campaignTargetFormResponseId && emailUrlId) {
      return fetchURLSubmittedForm();
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setGeneratedUrl(null);
  };

  const fetchURLSubmittedForm = async () => {
    let url = await EmailUrlService.getURLSubmitedForm(
      emailUrlId,
      campaignId,
      contactId,
      campaignTargetFormResponseId
    );
    setGeneratedUrl(url);
  };

  const defaultTrigger = () => (
    <Button
      onClick={handleOpen}
      disabled={disabled}
      as="div"
      labelPosition="right"
      size="tiny"
    >
      <Button icon>
        <Icon name="linkify" />
      </Button>
      <Label as="a" basic>
        Generate Link
      </Label>
    </Button>
  );

  const iconOnlyTrigger = () => (
    <Popup
      content="Generate Link"
      trigger={
        <Button
          onClick={handleOpen}
          icon="linkify"
          size="tiny"
          className="iconOnly"
          disabled={disabled}
        />
      }
    />
  );

  return (
    <Modal
      size="tiny"
      trigger={cloneElement(
        trigger || (iconOnly ? iconOnlyTrigger() : defaultTrigger()),
        {
          onClick: handleOpen,
        }
      )}
      open={modalOpen}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header>
        {isEdit ? "Edit Form Response" : "Generate Landing Page Link"}
      </Modal.Header>
      <Modal.Content>
        {generatedUrl ? (
          <UrlDisplay generatedUrl={generatedUrl} isEdit={isEdit} />
        ) : (
          <GenerateUrlForm
            setGeneratedUrl={setGeneratedUrl}
            campaignId={campaignId}
            contactId={contactId}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default GenerateUrlModal;

import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { Button, Modal } from "semantic-ui-react";
import { formatAddress } from "../helpers/address";
import AddressService from "../services/Address";
import CaseEventService from "../services/CaseEvent";
import MapMarker from "./MapMarker";

const mapContainerStyle = {
  width: "100%",
  height: "800px",
};

const libraries = ["places"];

const DEFAULT_ZOOM = 17;

const MapModal = forwardRef(({ hasAddresses = false, trigger }, ref) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    // ...otherOptions
  });

  const [open, setOpen] = useState(false);
  const [modelType, setModelType] = useState(null);
  const [modelId, setModelId] = useState(null);
  const [locationType, setLocationType] = useState(null);
  const [name, setName] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [events, setEvents] = useState([]);
  const [locations, setLocations] = useState([]);
  const [currentZoom, setCurrentZoom] = useState(DEFAULT_ZOOM);
  const [map, setMap] = useState();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const fetchAddresses = useCallback(async () => {
    const resp = await AddressService.getAddressesForModel({
      model_id: modelId,
      model_type: modelType,
      map: true,
    });
    setAddresses(resp.addresses);
  }, [modelType, modelId]);

  const fetchNearbyEvents = useCallback(async () => {
    // TODO: Include IP events?
    const resp = await CaseEventService.getNearbyEvents({
      model_id: modelId,
      model_type: modelType,
      location_type: locationType,
    });
    setEvents(resp.data);
  }, [modelType, modelId]);

  const onLoad = useCallback((map, markers) => {
    let lat_min;
    let lat_max;
    let lng_min;
    let lng_max;
    markers.forEach(marker => {
      lat_min = lat_min
        ? Math.min(lat_min, marker.position.lat)
        : marker.position.lat;
      lat_max = lat_max
        ? Math.max(lat_max, marker.position.lat)
        : marker.position.lat;
      lng_min = lng_min
        ? Math.min(lng_min, marker.position.lng)
        : marker.position.lng;
      lng_max = lng_max
        ? Math.max(lng_max, marker.position.lng)
        : marker.position.lng;
    });
    map.setCenter(
      new window.google.maps.LatLng(
        (lat_max + lat_min) / 2.0,
        (lng_max + lng_min) / 2.0
      )
    );
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(marker => {
      bounds.extend({ lat: marker.position.lat, lng: marker.position.lng });
    });
    map.fitBounds(bounds);

    setMap(map);

    // Reset zoom after map loads, for some reason the map gets zoomed in
    // to the maximum zoom level (22) when the map loads
    setTimeout(() => setCurrentZoom(DEFAULT_ZOOM), 200);
  }, []);

  const renderMap = (addresses, events) => {
    let markers = addresses
      .filter(address => address.latitude && address.longitude)
      .map(address => {
        return {
          position: {
            lat: address.latitude,
            lng: address.longitude,
          },
          color: "blue",
          title: formatAddress(address),
          type: "address",
          cluster: false,
          visible: true,
        };
      });

    markers = markers.concat(
      events.map(event => {
        let color;
        let visible = true;

        if (!event.distance) {
          color = "grey";
        } else if (event.distance <= 50) {
          color = "green";
        } else if (event.distance <= 250) {
          color = "yellow";
        } else if (event.distance <= 500) {
          color = "red";
        } else {
          color = "grey";
          visible = false;
        }

        return {
          position: {
            lat: event.lat,
            lng: event.lng,
          },
          title: `${event.lat}, ${event.lng}`,
          color: color,
          visible: visible,
          type: "event",
          data: event,
          cluster: true,
        };
      })
    );

    markers = markers.concat(
      locations.map(locationData => {
        return {
          position: {
            lat: locationData.lat,
            lng: locationData.lng,
          },
          title: `${locationData.lat}, ${locationData.lng}`,
          color: "grey",
          visible: true,
          type: "location",
          cluster: true,
          data: locationData.data,
        };
      })
    );

    if (!markers?.length) return;

    function handleZoomChanged() {
      if (map) {
        setCurrentZoom(map.zoom);
      }
    }

    return (
      <GoogleMap
        id="gmap"
        mapContainerStyle={mapContainerStyle}
        onLoad={map => onLoad(map, markers)}
        center={markers[0].position}
        zoom={currentZoom}
        maxZoom={18}
        onZoomChanged={handleZoomChanged}
      >
        {markers.map(marker => {
          return <MapMarker marker={marker} />;
        })}
      </GoogleMap>
    );
  };
  useEffect(() => {
    if (modelId && modelType) {
      if (hasAddresses) fetchAddresses();
      if (modelType !== "Case Event") fetchNearbyEvents();
    }
  }, [modelType, modelId]);

  function handleOpen(data) {
    setLocationType(data.locationType || "wifi");
    setModelType(data.modelType);
    setModelId(data.modelId);
    setName(data.name);
    if (data.locations) setLocations(data.locations);
    return setOpen(true);
  }

  function handleClose() {
    setAddresses([]);
    setEvents([]);
    setLocations([]);
    setModelType(null);
    setModelId(null);
    setOpen(false);
  }

  return (
    <Modal
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      trigger={trigger}
      size="large"
      closeIcon
    >
      <Modal.Header>
        Nearby Events for {name || `${modelType} ${modelId}`}
      </Modal.Header>
      <Modal.Content>
        {isLoaded ? renderMap(addresses, events) : "Loading map..."}
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

MapModal.propTypes = {
  hasAddresses: PropTypes.bool,
  trigger: PropTypes.element.isRequired,
};

export default MapModal;

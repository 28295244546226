import { applyFilters, doRequest } from "./helpers";

export default class EmailFooterService {
  static async getCustomFields() {
    return doRequest("GET", "/email/assets/footers/custom_fields");
  }

  static async getEmailFooters(filters = null) {
    let path = `/email/assets/footers/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getEmailFootersForFilters() {
    return doRequest("GET", "/email/assets/footers/for_filters");
  }

  static async getEmailFooter(id) {
    return doRequest("GET", `/email/assets/footers/${id}`);
  }

  static async editEmailFooter(id, name, html) {
    return doRequest("PUT", `/email/assets/footers/${id}`, {
      data: {
        name,
        html,
      },
    });
  }
  static async deleteEmailFooter(id) {
    return doRequest("DELETE", `/email/assets/footers/${id}`);
  }

  static async createEmailFooter(name, html) {
    return doRequest("POST", "/email/assets/footers/", {
      data: {
        name,
        html,
      },
    });
  }
}

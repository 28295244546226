import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form, Header } from "semantic-ui-react";

import AlternateNameView from "../../../components/AlternateName";
import Addresses from "../../../components/forms/addresses/Addresses";
import CustomFieldForm from "../../../components/forms/CustomFieldForm";
import {
  checkIsCustomFieldsReady,
  extractCustom,
  getCustomFieldsForSubmit,
  keyIsUnique,
} from "../../../components/helpers";
import RuvixxForm from "../../../components/RuvixxForm";
import { mapCustomFieldValues } from "../../../helpers/customFields";
import CustomFieldService from "../../../services/CustomField";
import EntityService from "../../../services/Entity";
import UserService from "../../../services/User";
import RegionService from "services/Regions";
import { getCountryByCode } from "helpers/countries";

class EditEntityForm extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    entityId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      urls: null,
      externalCcEmails: "",
      accountManagers: [],
      users: [],
      addresses: [],
      custom: [],
      customFieldConfigs: [],
      alternateNames: [],
      isCustomFieldsReady: false,
      regionId: null,
      regionsOptions: [],
      regions: [],
      country: null,
      countriesOptions: [],
      primaryAddressId: null,
    };
  }

  handleSubmit = async e => {
    const {
      name,
      urls,
      externalCcEmails,
      addresses,
      customFieldConfigs,
      custom,
      alternateNames,
      accountManagers,
      regionId,
      country,
    } = this.state;
    const cust = getCustomFieldsForSubmit(customFieldConfigs, custom);
    let urls_array = [];
    if (urls) {
      urls_array = urls.split(",");
    }
    await EntityService.editEntity(
      this.props.entityId,
      name,
      urls_array,
      externalCcEmails,
      addresses,
      cust,
      accountManagers,
      alternateNames,
      regionId,
      country
    );
  };

  isUnique = key => {
    return keyIsUnique({
      key: key,
      array: this.state.custom,
    });
  };

  handleChange = (_, { name, value }) => {
    this.setState({ [name]: value });
  };

  makeUrlCsv = urls => {
    let url_csv = "";
    for (var url_index in urls) {
      if (url_index == urls.length - 1) {
        url_csv += urls[url_index];
      } else {
        url_csv += urls[url_index] + ",";
      }
    }
    return url_csv;
  };

  fetchEntity = async () => {
    const entity = await EntityService.getEntity(this.props.entityId);
    const {
      name,
      urls,
      external_cc_emails,
      addresses,
      account_managers,
      custom_fields,
      alternate_names,
      region_id,
      country,
      primary_address_id,
    } = entity;
    const custom = extractCustom(custom_fields);
    this.fetchCustomFieldConfigs(custom);
    var urls_csv = this.makeUrlCsv(urls);
    this.setState({
      name,
      urls: urls_csv,
      externalCcEmails: external_cc_emails,
      addresses: this.prepareAddresses(addresses, primary_address_id),
      custom,
      accountManagers: account_managers.map(manager => manager.user_id),
      alternateNames: alternate_names,
      regionId: region_id,
      country,
      primaryAddressId: primary_address_id,
    });
  };

  prepareAddresses = (addresses, primary_address_id) => {
    if (!addresses) return [];

    if (primary_address_id) {
      for (let i = 0; i < addresses.length; i++) {
        addresses[i]["is_primary"] = addresses[i]["id"] === primary_address_id;
      }
    }
    return addresses;
  };

  fetchCustomFieldConfigs = async custom => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel("Entity");
    mapCustomFieldValues(custom, customFieldConfigs);
    this.setState({ customFieldConfigs });
  };

  handleUpdateCustomFieldConfigs = customFieldConfigs => {
    this.setState({ customFieldConfigs });
    this.handleUpdateCustomFields(
      customFieldConfigs
        .filter(cfc => cfc.required || cfc.selected)
        .map(cfc => ({ key: cfc.label, value: cfc.value }))
    );
  };

  handleUpdateCustomFields = customFields => {
    this.setState({ custom: customFields });
  };

  fetchUsers = async () => {
    const rawUsers = await UserService.getUsers();
    const users = rawUsers.map(user => ({
      key: user.id,
      value: user.id,
      text: user.full_name,
    }));
    this.setState({ users });
  };

  prepareRegionsSelect = async () => {
    const regions = await RegionService.getRegions();
    const options = regions.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
    this.setState({ regionsOptions: options, regions });
  };

  prepareCountriesSelect = async regionId => {
    const region = await RegionService.getRegion(regionId);
    const options = region?.countries.map(code => ({
      key: code,
      value: code,
      text: getCountryByCode(code).text,
    }));

    const updates = { countriesOptions: options };
    if (region?.countries.indexOf(this.state.country) < 0) {
      updates.country = null;
    }
    this.setState(updates);
  };

  updateLocationOptions = () => {
    if (!this.state.regionId) {
      this.setState({ country: null, countriesOptions: [] });
    } else {
      this.prepareCountriesSelect(this.state.regionId);
    }
  };

  componentDidMount() {
    this.fetchEntity(); // don't await
    this.fetchUsers(); // don't await
    this.prepareRegionsSelect();
  }

  componentDidUpdate(_, prevState) {
    if (this.state.regionId !== prevState.regionId) {
      this.updateLocationOptions();
    }
  }

  setAlternateNames = alternateNames => {
    this.setState({ alternateNames });
  };

  checkAddressesReady = addresses => {
    return addresses.every(address => this.isAddressReady(address));
  };

  isAddressReady = address => {
    return (
      address.street1 &&
      address.city &&
      address.region &&
      address.postal_code &&
      address.country
    );
  };

  updatePrimaryAddress = async (address, index, checked) => {
    if (!this.isAddressReady(address)) return;

    const updates = {};
    const addressesCopy = [...this.state.addresses];

    for (let i = 0; i < addressesCopy.length; i++) {
      addressesCopy[i]["is_primary"] = i === index ? checked : false;
    }
    updates.addresses = addressesCopy;

    if (this.state.primaryAddressId === address.id) {
      updates.primaryAddressId = null;
    } else {
      updates.primaryAddressId = address.id;
      const regionsFound = this.state.regions.filter(region =>
        region.countries.includes(address.country)
      );

      if (regionsFound?.length) {
        if (!this.state.country) {
          updates.country = address.country;
        }
        if (!this.state.regionId) {
          updates.regionId = regionsFound[0].id;
        }
      }
    }
    this.setState(updates);
  };

  render() {
    const {
      name,
      urls,
      accountManagers,
      users,
      addresses,
      externalCcEmails,
      alternateNames,
      custom,
      customFieldConfigs,
      regionsOptions,
      regionId,
      countriesOptions,
      country,
      primaryAddressId,
    } = this.state;
    return (
      <RuvixxForm
        ready={
          !!name &&
          name.trim() !== "" &&
          checkIsCustomFieldsReady(customFieldConfigs) &&
          this.checkAddressesReady(addresses)
        }
        onSubmit={this.handleSubmit}
        onSuccess={this.props.onSuccess}
        successHeader="Entity Created"
        successMessage={"Successfully created the " + name + " entity"}
      >
        <Header className="section-header">Basic Info</Header>
        <Form.Input
          inline
          className="fillSpace"
          name="name"
          label="Name"
          required
          value={name}
          onChange={this.handleChange}
        />
        <Form.Field>
          <AlternateNameView
            alternateNames={alternateNames}
            setAlternateNames={this.setAlternateNames}
            type={"Entity"}
          />
        </Form.Field>
        <Form.Input
          inline
          className="fillSpace"
          name="urls"
          label="Urls"
          placeholder="www.domain1.com,www.domain2.com"
          value={urls}
          onChange={this.handleChange}
        />
        <Form.Select
          label="Account Managers"
          clearable
          name="accountManagers"
          inline
          className="fillSpace"
          multiple
          search
          selection
          onChange={this.handleChange}
          options={users}
          value={accountManagers}
        />
        <Form.Input
          inline
          className="fillSpace"
          name="externalCcEmails"
          label="External CC Emails"
          placeholder="email1@test.com,email2@test.com"
          value={externalCcEmails}
          onChange={this.handleChange}
        />
        <Form.Select
          inline
          label="Region"
          name="regionId"
          search
          clearable
          options={regionsOptions}
          value={regionId}
          onChange={this.handleChange}
        />
        <Form.Select
          inline
          label="Country"
          name="country"
          disabled={!regionId}
          search
          clearable
          options={countriesOptions}
          value={country}
          onChange={this.handleChange}
        />
        <Addresses
          addresses={addresses}
          updateAddresses={newAddresses =>
            this.setState({ addresses: newAddresses })
          }
          showPrimaryColumn={true}
          primaryAddressId={primaryAddressId}
          updatePrimaryAddress={this.updatePrimaryAddress}
          showLatLong
        />
        {customFieldConfigs.length > 0 ? (
          <CustomFieldForm
            modelType="Entity"
            customFields={custom}
            customFieldConfigs={customFieldConfigs}
            onUpdateCustomFieldConfigs={this.handleUpdateCustomFieldConfigs}
          />
        ) : null}
      </RuvixxForm>
    );
  }
}

export default EditEntityForm;

import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import {
  Segment,
  Grid,
  Image,
  Button,
  Message,
  Form,
  List,
} from "semantic-ui-react";
import logo from "../../assets/logo_side_pleteo.png";
import AuthService from "../../services/Auth";

function ResetPasswordConfirm({}) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const [tokenHashId, setTokenHashId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState({
    passwordLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasSpecialCharacters: false,
    hasNumber: false,
  });

  const navigate = useNavigate();

  const handleReset = async () => {
    if (newPassword != confirmPassword) {
      setErrorMessage("Passwords do not match");
    } else {
      setErrorMessage("");
      let data = {
        token: tokenHashId,
        new_password: newPassword,
      };
      try {
        let resp = await AuthService.passwordResetByToken(data);
        setSuccessMessage(
          "Password has been successfully reset! Going back to the login page in 5 seconds"
        );
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      } catch (error) {
        setErrorMessage(error.message);
      }
    }
  };

  const handleChangePassword = (_, { value }) => {
    setNewPassword(value);
    const lengthRegex = /^.{8,}$/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharactersRegex = /[!@#$%^*]/;
    const numberRegex = /\d/;
    setPasswordValidation({
      passwordLength: lengthRegex.test(value),
      hasUppercase: uppercaseRegex.test(value),
      hasLowercase: lowercaseRegex.test(value),
      hasSpecialCharacters: specialCharactersRegex.test(value),
      hasNumber: numberRegex.test(value),
    });
  };

  const handleChangeConfirm = (event, data) => {
    setConfirmPassword(data.value);
  };

  useEffect(() => {
    setTokenHashId(token);
  }, [token]);

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      navigate("/");
    }
  });

  return (
    <Grid centered verticalAlign="middle">
      <Grid.Column width={7}>
        <Segment basic>
          <Grid>
            <Grid.Column width="16" textAlign="center">
              <Image src={logo} centered />
            </Grid.Column>
          </Grid>
          {token ? (
            <Form>
              <Form.Input
                label="New Password"
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={handleChangePassword}
              />
              <Form.Input
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChangeConfirm}
              />
              {newPassword.length > 0 && (
                <List>
                  <List.Item
                    className={
                      passwordValidation.passwordLength
                        ? " passwordItem--right"
                        : " passwordItem--wrong"
                    }
                  >
                    <List.Icon
                      name={
                        passwordValidation.passwordLength ? "check" : "times"
                      }
                    />
                    <List.Content>8 characters or longer</List.Content>
                  </List.Item>
                  <List.Item
                    className={
                      passwordValidation.hasUppercase
                        ? " passwordItem--right"
                        : " passwordItem--wrong"
                    }
                  >
                    <List.Icon
                      name={passwordValidation.hasUppercase ? "check" : "times"}
                    />
                    <List.Content>1 uppercase</List.Content>
                  </List.Item>
                  <List.Item
                    className={
                      passwordValidation.hasLowercase
                        ? " passwordItem--right"
                        : " passwordItem--wrong"
                    }
                  >
                    <List.Icon
                      name={passwordValidation.hasLowercase ? "check" : "times"}
                    />
                    <List.Content>1 lowercase</List.Content>
                  </List.Item>
                  <List.Item
                    className={
                      passwordValidation.hasSpecialCharacters
                        ? " passwordItem--right"
                        : " passwordItem--wrong"
                    }
                  >
                    <List.Icon
                      name={
                        passwordValidation.hasSpecialCharacters
                          ? "check"
                          : "times"
                      }
                    />
                    <List.Content>1 special character (!@#$%^*)</List.Content>
                  </List.Item>
                  <List.Item
                    className={
                      passwordValidation.hasNumber
                        ? " passwordItem--right"
                        : " passwordItem--wrong"
                    }
                  >
                    <List.Icon
                      name={passwordValidation.hasNumber ? "check" : "times"}
                    />
                    <List.Content>1 number</List.Content>
                  </List.Item>
                  {confirmPassword.length > 0 && (
                    <List.Item
                      className={
                        newPassword === confirmPassword
                          ? " passwordItem--right"
                          : " passwordItem--wrong"
                      }
                    >
                      <List.Icon
                        name={
                          newPassword === confirmPassword ? "check" : "times"
                        }
                      />
                      <List.Content>Passwords should match</List.Content>
                    </List.Item>
                  )}
                </List>
              )}
              <Segment basic textAlign="center">
                <Button
                  centered
                  disabled={
                    !newPassword ||
                    !confirmPassword ||
                    newPassword !== confirmPassword ||
                    Object.values(passwordValidation).some(v => !v)
                  }
                  content="Submit"
                  onClick={handleReset}
                />
              </Segment>
            </Form>
          ) : (
            <Navigate to="/login" replace />
          )}
          {errorMessage && <Message error content={errorMessage} />}
          {successMessage && <Message success content={successMessage} />}
        </Segment>
      </Grid.Column>
    </Grid>
  );
}

export default ResetPasswordConfirm;

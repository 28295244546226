import { applyFilters, doRequest } from "./helpers";

const tagPaths = {
  Case: "/case/tags",
  CampaignTargetCall: "/call/tags",
  CampaignTargetEmail: "/email/tags",
  Contact: "/contact/tags", // acl
  Entity: "/entity/tags", // acl
  EmailFooter: "/email/assets/footers/tags", //acl
  EmailSignature: "/email/assets/signatures/tags", // acl
  EmailUrl: "/email_url/tags", //acl
  EmailTemplate: "/email/assets/templates/tags",
  Task: "/task/tags",
  DataJob: "/data_job/tags",
  LeadEntity: "/lead/tags",
  RevenueOpportunity: "/revenue_opportunity/tags",
  Machine: "/machine/tags",
};

export default class TagService {
  static async getTags(params = null) {
    let path = `/tag/`;
    path = applyFilters(params, path);
    return doRequest("GET", path);
  }

  static async getTagsTable(filters = null) {
    let path = "/tag/table/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createTag(data) {
    let path = `/tag/`;
    return doRequest("POST", path, { data });
  }

  static async editTag(id, data) {
    let path = `/tag/${id}`;
    return doRequest("PUT", path, { data });
  }

  static async deleteTag(id) {
    let path = `/tag/${id}`;
    return doRequest("DELETE", path);
  }

  static async bulkDeleteTags(tags_ids) {
    let path = `/tag/bulk_delete`;
    return doRequest("DELETE", path, {
      data: {
        tags_ids,
      },
    });
  }

  static async removeTag(tag, className, itemIds) {
    let path = tagPaths[className];
    return doRequest("DELETE", path, {
      data: {
        tag: tag,
        item_ids: itemIds,
      },
    });
  }

  static async addTag(tag, className, itemIds, metadata) {
    let path = tagPaths[className];
    return doRequest("POST", path, {
      data: {
        tag: tag,
        item_ids: itemIds,
        metadata,
      },
    });
  }

  static async updateModelTag(tag, modelType, modelId, metadata) {
    const path = tagPaths[modelType];
    return doRequest("PUT", path, {
      data: {
        tag,
        metadata,
        item_id: modelId,
      },
    });
  }

  static async bulkUpdateCategory(categoryId, tagIds) {
    return doRequest("PUT", "/tag/update_category", {
      data: {
        category_id: categoryId,
        tag_ids: tagIds,
      },
    });
  }
}

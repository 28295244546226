import { applyFilters, doRequest } from "./helpers";
import { DEFAULT_PAGE_SIZE } from "../constants/Constants";

export default class AuditService {
  static async getAuditLogs({
    modelId,
    modelType,
    page = 1,
    perPage = DEFAULT_PAGE_SIZE,
    filters = {},
  }) {
    modelType = modelType.toLowerCase();
    let path = `/audit_log/${modelType}/${modelId}`;
    filters = {
      ...filters,
      page,
      per_page: perPage,
    };
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getAuditLogEntry({
    auditLogId,
    modelId,
    modelType,
    perPage = DEFAULT_PAGE_SIZE,
    filters = {},
  }) {
    modelType = modelType.toLowerCase();
    let path = `/audit_log/${auditLogId}`;
    filters = {
      ...filters,
      per_page: perPage,
      model_id: modelId,
      model_type: modelType,
    };
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getAuditLogEvents() {
    return doRequest("GET", `/audit_log/events`);
  }

  static async postManualLogEvent(data) {
    return doRequest("POST", `/audit_log/`, { data });
  }
}

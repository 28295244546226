import qs from "qs";
import identity from "lodash/identity";
import isEqual from "lodash/isEqual";
import pickBy from "lodash/pickBy";
import mapValues from "lodash/mapValues";
import zipObject from "lodash/zipObject";
import store from "store";
import eventsPlugin from "store/plugins/events";
import { matchRoutes, useLocation } from "react-router-dom";
import { Router } from "../../routes/Router";

const changeUrl = (newUrl, replaceState = false) => {
  if (newUrl.indexOf("?") === -1 && replaceState) {
    newUrl += window.location.search;
  }
  if (newUrl.indexOf("/") === 0) {
    newUrl = window.location.origin + newUrl;
  }
  if (newUrl === window.location.href) {
    return;
  }
  const args = [{ path: newUrl }, "", newUrl];
  try {
    if (replaceState) {
      window.history.replaceState(...args);
    } else {
      window.history.pushState(...args);
    }
  } catch (e) {
    window.href = newUrl;
  }
};

const updateQueryString = (queryString, replace = false) => {
  const { protocol, host, pathname } = window.location;
  let newUrl = `${protocol}//${host}${pathname}`;
  if (queryString) {
    newUrl += `?${queryString}`;
  }
  changeUrl(newUrl, replace);
};

const getQueryStringFromParams = params => {
  params = mapValues(params, param => (param === false ? "false" : param));
  const values = pickBy(params, identity);
  return qs.stringify(values, {
    encodeValuesOnly: false,
    arrayFormat: "comma",
  });
};

const getQueryParamsFromString = string => {
  return qs.parse(string, { ignoreQueryPrefix: true });
};

const getQueryParams = () => {
  const queryString = window.location.search;
  const qp = getQueryParamsFromString(queryString);
  return qp;
};

const setQueryParams = (params, replaceUrl = false, appendParams = false) => {
  if (appendParams) {
    const queryParams = getQueryParams();
    params = { ...queryParams, ...params };
  }
  const queryString = getQueryStringFromParams(params);
  updateQueryString(queryString, replaceUrl);
  const newQueryParams = getQueryParamsFromString(queryString);
  store.set("qp", [
    window.location.origin + window.location.pathname,
    newQueryParams,
  ]);
  return newQueryParams;
};

const getQueryParam = (key, defaultValue) => {
  const queryParams = getQueryParams();
  return queryParams[key] || defaultValue;
};

const setQueryParam = (
  key,
  value,
  replaceUrl = false,
  appendParams = false
) => {
  const params = { [key]: value };
  setQueryParams(params, replaceUrl, appendParams);
};

const areQueryParamsEqual = (params1, params2) => {
  const normalizedParams1 = getQueryParamsFromString(
    getQueryStringFromParams(params1)
  );
  const normalizedParams2 = getQueryParamsFromString(
    getQueryStringFromParams(params2)
  );
  return isEqual(normalizedParams1, normalizedParams2);
};

const isCurrentQueryParams = params => {
  const queryParams = getQueryParams();
  const normalizedParams = getQueryParamsFromString(
    getQueryStringFromParams(params)
  );
  return isEqual(queryParams, normalizedParams);
};

const getCurrentParams = path => {
  const exp = "[a-z]+";
  const keys = path.match(new RegExp("(?<=:)" + exp, "g"));
  const result = window.location.pathname.match(
    new RegExp("^" + path.replace(new RegExp(":" + exp, "g"), "(.+)") + "$")
  );
  if (result) {
    let values = result.slice(1);
    values = values.map(value => value.replace(/\/+.*$/g, ""));
    return zipObject(keys, values);
  } else {
    return {};
  }
};

const setRouteParams = (path, newParams, replace = false) => {
  let params = getCurrentParams(path);
  if (isEqual(params, newParams)) {
    return params;
  }
  params = {
    ...params,
    ...newParams,
  };
  path = getMatchingPath(path, params);
  const newUrl = Object.keys(params).reduce((url, key) => {
    let value = params[key];
    if (!value) {
      value = "";
    }
    const keyPlace = String.raw`:${key}`;
    if (url.includes(keyPlace)) {
      return url.replace(RegExp(keyPlace), value);
    } else {
      return url + "/" + value;
    }
  }, path);
  changeUrl(newUrl, replace);
  return params;
};

const setRouteParam = (path, key, value, replace = false) => {
  const params = setRouteParams(path, { [key]: value }, replace);
  return params[key];
};

let callbackStore = {};
const initQueryParams = callback => {
  const name = window.location.origin + window.location.pathname;
  if (!Object.keys(callbackStore).includes(name)) {
    callbackStore[name] = [];
  }
  callbackStore[name] = [...callbackStore[name], callback];
  const qp = store.get("qp");
  if (qp === undefined) {
    store.set("qp", [name, getQueryParams()]);
  }
  store.addPlugin(eventsPlugin);
  store.watch("qp", (val, oldVal) => {
    const [name, qp] = val;
    const [oldName, oldQp] = oldVal;
    if (name === oldName && !isEqual(qp, oldQp)) {
      Object.values(callbackStore[name]).forEach(callback => callback(true)); // notify all qp hooks/managers
    }
  });
};

const useCurrentPath = () => {
  const location = useLocation();
  const [{ route }] = matchRoutes(Router.routeArray, location);
  return route.path;
};

const getMatchingPath = (path, params) => {
  const keys = Object.keys(params);
  const route = Router.routeArray.find(route => {
    if (!route.path.startsWith(path)) {
      return false;
    }
    if (
      route.path
        .substring(path.length)
        .split("/")
        .slice(1)
        .some(elem => !elem.startsWith(":"))
    ) {
      return false;
    }
    return keys.every(key => route.path.includes(":" + key));
  });
  return route ? route.path : null;
};

export {
  getQueryParams,
  setQueryParams,
  getQueryParam,
  setQueryParam,
  setRouteParams,
  setRouteParam,
  areQueryParamsEqual,
  isCurrentQueryParams,
  initQueryParams,
  useCurrentPath,
};

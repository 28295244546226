const priorities = [
  {
    id: 3,
    key: "low",
    name: "Low Priority",
    color: "green",
  },
  {
    id: 2,
    key: "medium",
    name: "Med. Priority",
    color: "yellow",
  },
  {
    id: 1,
    key: "high",
    name: "High Priority",
    color: "red",
  },
];

const statuses = [
  {
    id: 1,
    key: "open",
    name: "Open",
  },
  {
    id: 2,
    key: "in_progress",
    name: "In Progress",
  },
  {
    id: 3,
    key: "closed",
    name: "Closed",
  },
];

const visibilities = [
  {
    id: 1,
    key: "public",
    name: "Public",
  },
  {
    id: 3,
    key: "private_roles",
    name: "Private (Roles)",
    model: "Role",
  },
  {
    id: 2,
    key: "private_users",
    name: "Private (Users)",
    model: "User",
  },
];

const types = [
  {
    id: 2,
    key: "custom_email",
    name: "Custom Email",
    icon: "mail outline",
  },
  {
    id: 3,
    key: "template_email",
    name: "Template Email",
    icon: "mail square",
  },
  {
    id: 4,
    key: "call",
    name: "Call",
    icon: "call",
  },
  {
    id: 1,
    key: "generic_task",
    name: "Generic Task",
    icon: "cog",
  },
];

const targets = [
  {
    id: 1,
    key: "contact",
    name: "Contact",
    uri: "contacts",
    article: "a",
  },
  {
    id: 2,
    key: "entity",
    name: "Entity",
    uri: "entities",
    article: "an",
  },
  {
    id: 3,
    key: "campaign",
    name: "Campaign",
    uri: "campaigns",
    article: "a",
  },
  {
    id: 4,
    key: "user",
    name: "User",
    article: "a",
  },
];

const campaignChosenTargets = [
  {
    id: 1,
    key: "contact",
    name: "Contact",
    uri: "contacts",
    article: "a",
  },
  {
    id: 2,
    key: "entity",
    name: "Entity",
    uri: "entities",
    article: "an",
  },
  {
    id: 4,
    key: "user",
    name: "User",
    article: "a",
  },
];

const statusTypes = [
  {
    id: 2,
    key: "prev_status",
    name: "prev_status",
  },
  {
    id: 3,
    key: "new_status",
    name: "new_status",
  },
];

const taskConstants = {
  priorities,
  statuses,
  visibilities,
  types,
  targets,
  campaignChosenTargets,
  statusTypes,
};

export default taskConstants;

import store from "store";
import { doRequest } from "./helpers";
import userService from "services/User";

export default class AuthService {
  static async login(email, password) {
    await this.logout(); // remove current credentials first.
    const permissionCategories = await doRequest(
      "GET",
      "/role/permission_categories"
    );
    store.set("permissionCategories", permissionCategories);
    const credentials = await doRequest("POST", "/auth/login", {
      data: {
        email,
        password,
      },
    });
    // TODO: Remove this once we merge the new form system. It's temporary to
    // block any external users that may have been created for leads which have been
    // removed from the system.
    if (credentials.external) {
      // raise an error
      throw new Error(
        "Your account access has been revoked. Please contact your administrator."
      );
      return;
    }
    store.set("userAuth", {
      jwt: credentials.token,
      id: credentials.id,
      email: email,
      full_name: credentials.full_name,
      table_settings:
        credentials.settings.preferences !== undefined &&
        credentials.settings.preferences !== null
          ? credentials.settings.preferences.tables || {
              target_companies: {},
              campaigns: {},
            }
          : {
              target_companies: {},
              campaigns: {},
            },
      pipeline_settings: credentials.settings.preferences?.pipelines || {},
      sidebar_settings: credentials.settings.preferences?.sidebars || {},
      role: credentials.role,
      external: credentials.external,
    });

    if (
      credentials.settings.account !== undefined &&
      credentials.settings.account !== null &&
      credentials.settings.account.time_zone_name !== undefined &&
      credentials.settings.account.time_zone_name !== null
    ) {
      this.setTimeZone(credentials.settings.account.time_zone_name);
    }
  }

  static setName(name) {
    let user = store.get("userAuth");
    user.full_name = name;
    store.set("userAuth", user);
  }

  static setEmail(email) {
    let user = store.get("userAuth");
    user.email = email;
    store.set("userAuth", user);
  }

  static setTimeZone(timeZone) {
    let user = store.get("userAuth");
    user.timeZone = timeZone;
    store.set("userAuth", user);
  }

  // logout() is async because it may involve server-side logic eventually
  static async logout() {
    const user = store.get("userAuth");
    if (user) {
      userService.updateUserStatus(user.id, "OFFLINE");
    }

    store.remove("userAuth");
    store.remove("userStatus");
  }

  static isLoggedIn() {
    const user = store.get("userAuth");
    const shouldChangePassword = store.get("shouldChangePassword");
    return user?.jwt && !shouldChangePassword;
  }

  static async sendPasswordResetEmail(email) {
    let resp = await doRequest("POST", "/auth/send_reset", {
      data: {
        user_email: email,
      },
    });
  }

  static async passwordResetByToken(data) {
    let resp = await doRequest("POST", "/auth/reset_password", { data });
  }
}

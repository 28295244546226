import axios from "axios";
import store from "store";
import filter from "lodash/filter";
import { ApiSetting } from "./ApiSettings";
import AuthService from "./Auth";
import History from "../helpers/history";

let nextCancelToken = null;

async function doRequest(
  method,
  path,
  {
    headers = {},
    data,
    onUploadProgress,
    cancelToken,
    responseType,
    returnResponseObj,
  } = {}
) {
  const url = ApiSetting.DEFAULT_HOST + ApiSetting.DEFAULT_BASE_PATH + path;
  const userAuth = store.get("userAuth");
  if (userAuth) {
    headers.Authorization = userAuth.jwt;
  }

  return doGenericRequest(method, url, {
    headers,
    data,
    onUploadProgress,
    cancelToken,
    responseType,
    returnResponseObj,
  });
}

async function doGenericRequest(...args) {
  return new Promise((resolve, reject) => {
    _doGenericRequest(...args)
      .then(resolve)
      .catch(error => {
        if (error?.message === "Request Cancelled") {
          return; // Avoid displaying cancelled request error in console
        }
        reject(error);
      });
  });
}

async function _doGenericRequest(
  method,
  url,
  {
    headers = {},
    data,
    onUploadProgress,
    cancelToken,
    responseType,
    returnResponseObj,
  } = {}
) {
  if (nextCancelToken) {
    cancelToken = nextCancelToken.token;
    nextCancelToken = null;
  }
  let reqOptions = {
    method,
    url,
    headers,
    onUploadProgress,
    cancelToken,
    responseType,
  };
  if (data) {
    if (data instanceof FormData)
      reqOptions.headers["content-type"] = "multipart/form-data";
    else reqOptions.headers["content-type"] = "application/json";
    reqOptions.data = data;
  }
  let response;
  try {
    response = await axios(reqOptions);
    if (Math.floor(response.status / 100) !== 2) {
      // any 2xx response is valid
      if (response.status === 401) {
        store.remove("userAuth");
      }
      throw new Error(
        `API request failed! - ${response.status}: ${response.statusText}`
      );
      // TODO: In case of HTTP 401, we need to devise some way of allowing
      // the user to login again without losing work. This could be done with a modal
      // login prompt.
    }
    return returnResponseObj ? response : response.data;
  } catch (error) {
    if (error.toString() === "Cancel") {
      error.message = "Request Cancelled";
      throw error; // Don't log errors for cancelled requests
    }

    // React doesn't catch async exceptions, but we can catch
    // most of them here for logging on the server.

    // TODO: Implement global async error handling similar to `create-react-app`
    // for notifying the user during production use.

    error.response = error.response || response || {};
    if (error.response.hasOwnProperty("data") && error.response.data.message) {
      error.message = error.response.data.message;
    }

    if (error.response.status === 403) {
      storeForbiddenError(
        `${method} - ${url.split(ApiSetting.DEFAULT_BASE_PATH)[1]}`
      );
    }

    logErrorRemotely(error, error.stack);
    throw error;
  }
}

function logErrorRemotely(exception, info) {
  // TODO: Build remote error logging facilities.
  if (
    (exception.request && exception.request.status === 401) ||
    (exception.response && exception.response.status === 401)
  ) {
    AuthService.logout();
    window.location.href = "/";
  }
  console.error(exception.toString());
}

function storeForbiddenError(item) {
  let error = store.get("error") || {};
  error.header = "Action Failed";
  error.message = "Your user role does not have permissions for that action";
  error.items = error.items || [];
  if (!error.items.includes(item)) {
    error.items.push(item);
  }
  store.set("error", error);
}

function storeError(message, items = []) {
  let error = store.get("error") || {};
  error.header = "Action Failed";
  error.message = message;
  error.items = items;
  store.set("error", error);
}

function applyFilters(filters, path) {
  if (filters) {
    let queryParams = Object.keys(filters).map(key => {
      const value = filters[key];
      if (value !== undefined && value !== null) {
        if (key === "search" || key === "case_id" || key === "machine_id") {
          return `${key}=${value}`;
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      } else return null;
    });
    queryParams = filter(queryParams);
    const queryStr = queryParams.join("&").replace("+", "%2b");
    if (queryStr.length) path += `?${queryStr}`;
  }
  return path;
}

async function downloadFile(path, options = {}) {
  const { method = "GET", name, ...requestOpts } = options;
  let blob, contentDisposition;
  if (path instanceof Blob) {
    blob = path;
  } else {
    const response = await doRequest("GET", path, {
      responseType: "blob",
      returnResponseObj: true,
      ...requestOpts,
    });
    contentDisposition = response.headers["content-disposition"];
    blob = new Blob([response.data], { type: response.data.type });
  }
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  let fileName = name || "unknown";
  if (!name && contentDisposition) {
    fileName = decodeURIComponent(
      /filename\*?=(?:[^']*'')?([^;]*)/.exec(contentDisposition)[1]
    );
  }
  link.setAttribute("download", fileName);
  link.click();
  URL.revokeObjectURL(url);
}

function attachCancelTokenToNextRequest() {
  const cancelToken = axios.CancelToken.source();
  nextCancelToken = cancelToken;
  return { ...cancelToken };
}

const wait = ms => new Promise(r => setTimeout(r, ms));

/**
 * Retry executing request until it resolves or timeout is reached
 * @param {Promise} request Request which might fail
 * @param {number} [timeout=5000] Timeout to allow for retries (in ms)
 * @param {number} [delay=1000] Delay between retries (in ms)
 */
const retryOnError = (request, timeout = 5000, delay = 1000, _start = 0) => {
  return new Promise((resolve, reject) => {
    _start = _start || new Date();
    const elapsed = new Date() - _start;
    return request()
      .then(resolve)
      .catch(reason => {
        if (elapsed < timeout) {
          return wait(delay)
            .then(retryOnError.bind(null, request, timeout, delay, _start))
            .then(resolve)
            .catch(reject);
        }
        return reject(reason);
      });
  });
};

export {
  doRequest,
  doGenericRequest,
  logErrorRemotely,
  applyFilters,
  downloadFile,
  attachCancelTokenToNextRequest,
  retryOnError,
  storeForbiddenError,
  storeError,
};

import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import TagCategoryService from "../../../services/TagCategory";

const TagCategoryForm = ({ model, onSuccess }) => {
  const [name, setName] = useState(model?.name || "");
  const [description, setDescription] = useState(model?.description || "");

  const handleSubmit = async () => {
    const tagCategoryData = {
      name,
      description,
    };
    if (model?.id) {
      await TagCategoryService.editTagCategory(model.id, {
        ...tagCategoryData,
      });
    } else {
      await TagCategoryService.createTagCategory(tagCategoryData);
    }
    onSuccess();
  };

  return (
    <RuvixxForm ready={!!name} onSubmit={handleSubmit}>
      <Form.Input
        inline
        label="Name"
        required
        value={name}
        onChange={(_, { value }) => setName(value)}
      />
      <Form.TextArea
        inline
        label="Description"
        value={description}
        onChange={(_, { value }) => setDescription(value)}
      />
    </RuvixxForm>
  );
};

export default TagCategoryForm;

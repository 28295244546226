import React, { useState } from "react";
import { Button, Dropdown, Header, Modal } from "semantic-ui-react";
import EmailUrlForm from "./EmailUrlForm";

export default function EmailUrlModal(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const onSuccess = async => {
    handleClose();
    props.onSuccess();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        props.button ? (
          <Button
            content="New Email URL"
            onClick={handleOpen}
            size="tiny"
            className="item-adder"
          />
        ) : (
          <Dropdown.Item
            className="primary"
            content={props.emailUrlId ? "Edit Email URL" : "New Email URL"}
            icon={props.emailUrlId ? "edit" : null}
            onClick={handleOpen}
          />
        )
      }
    >
      <Header content={props.emailUrlId ? "Edit Email URL" : "New Email URL"} />
      <Modal.Content>
        <EmailUrlForm onSuccess={onSuccess} emailUrlId={props.emailUrlId} />
      </Modal.Content>
    </Modal>
  );
}

import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import AssignCampaignForm from "./AssignCampaignForm";

const AssignCampaignModal = forwardRef(({ updateCampaignId }, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSuccess = () => {
    setOpen(false);
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => setOpen(false)}
      closeOnDimmerClick={false}
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      closeIcon
    >
      <Modal.Header>Assign Campaign</Modal.Header>
      <Modal.Content>
        <AssignCampaignForm
          onSuccess={handleSuccess}
          updateCampaignId={updateCampaignId}
        />
      </Modal.Content>
    </Modal>
  );
});

AssignCampaignModal.propTypes = {
  updateCampaignId: PropTypes.func.isRequired,
};

export default AssignCampaignModal;

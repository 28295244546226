import React from "react";
import { logErrorRemotely } from "../services/helpers";

import PropTypes from "prop-types";

export class ErrorBoundary extends React.Component {
  static propTypes = {
    errorDisplay: PropTypes.func.isRequired,
    exception: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      exceptionCaught: false,
      exception: null,
    };
  }

  componentDidCatch(exception, exceptionInfo) {
    // Unexpected errors, specially during production, should
    // be logged remotely for inspection and maintenance.
    this.setState({
      exceptionCaught: true,
      exception,
      exceptionInfo,
    });
    logErrorRemotely(exception, exceptionInfo);
  }

  render() {
    const Display = this.props.errorDisplay;
    if (this.state.exceptionCaught) {
      return (
        <Display
          exception={this.state.exception}
          info={this.state.exceptionInfo}
        />
      );
    }

    return this.props.children;
  }
}

import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import SequenceService from "../../../services/Sequence";
import RuvixxForm from "../../../components/RuvixxForm";
import PropTypes from "prop-types";

import CampaignService from "../../../services/Campaign";

function SequenceForm({ sequenceId, onSuccess }) {
  const [formData, setFormData] = useState({
    id: sequenceId,
    name: "",
    description: "",
    campaignIds: [],
  });
  const [steps, setSteps] = useState([]);

  const [campaignOptions, setCampaignOptions] = useState([]);

  const fetchSequence = useCallback(async () => {
    let sequence = await SequenceService.getSequence(sequenceId);
    const formDataCopy = { ...formData };
    formDataCopy.name = sequence.name;
    formDataCopy.description = sequence.description;
    formDataCopy.campaignIds = sequence.campaigns.map(campaign => campaign.id);
    setFormData(formDataCopy);
    setSteps(sequence.steps);
  }, [sequenceId, formData]);

  const fetchCampaigns = useCallback(async () => {
    let campaignOptions = await CampaignService.getCampaignsForFilters();
    campaignOptions = campaignOptions.map(campaign => ({
      text: campaign.name,
      key: campaign.id,
      value: campaign.id,
    }));
    setCampaignOptions(campaignOptions);
  }, []);

  useEffect(() => {
    if (sequenceId) {
      fetchSequence();
    }
  }, [sequenceId]);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  const handleChange = event => {
    const formDataCopy = { ...formData };
    formDataCopy[event.target.name] = event.target.value;
    setFormData(formDataCopy);
  };

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const handleSubmit = async e => {
    const { id, name, description, campaignIds } = formData;

    const data = {
      name: name,
      description: description,
      campaign_ids: campaignIds,
      steps,
    };
    try {
      if (id) {
        await SequenceService.editSequence(id, data);
      } else {
        await SequenceService.createSequence(data);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  return (
    <RuvixxForm
      ready={!!formData.name}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      <Form.Input
        inline
        name="name"
        value={formData.name}
        label="Name"
        required
        onChange={handleChange}
      />
      <Form.Input
        inline
        name="description"
        value={formData.description}
        label="Description"
        onChange={handleChange}
      />
      <Form.Select
        inline
        clearable
        fluid
        search
        multiple
        onChange={handleSelect}
        value={formData.campaignIds}
        label="Campaigns Attached"
        name="campaignIds"
        options={campaignOptions}
        placeholder="Select Campaigns"
      />
    </RuvixxForm>
  );
}

SequenceForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  campaignIds: PropTypes.array,
};

export default SequenceForm;

import React, { useEffect, useState } from "react";
import {
  Dimmer,
  Icon,
  Loader,
  Popup,
  Segment,
  Statistic,
} from "semantic-ui-react";
import DashboardService from "../../services/Dashboard";
import round from "lodash/round";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

function CallDashboardSummaryStats({ queryFilters }) {
  const [loading, setLoading] = useState(true);
  const [dials, setDials] = useState(null);
  const [pickups, setPickups] = useState(null);
  const [pickupRate, setPickupRate] = useState(null);
  const [hours, setHours] = useState(null);
  const [avgDialsPerDay, setAvgDialsPerDay] = useState(null);
  const [leads, setLeads] = useState(null);
  const [prevQueryFilters, setPrevQueryFilters] = useState({});

  const fetchData = async filterParams => {
    let stats = {};
    let promises = ["dials", "pickups", "leads", "hours", "days"].map(stat => {
      return DashboardService.getCallsSummary(stat, filterParams).then(resp => {
        stats[stat] = resp;
      });
    });
    await Promise.all(Object.values(promises));

    setDials(stats.dials.count || 0);
    setHours(stats.hours.count || 0);
    setPickups(stats.pickups.count || 0);
    setPickupRate(
      round((stats.pickups.count / stats.dials.count) * 100, 2) || 0
    );
    setAvgDialsPerDay(round(stats.dials.count / stats.days.count, 2) || 0);
    setLeads(stats.leads.count || 0);
    setLoading(false);
  };

  useEffect(() => {
    const filters = omit(queryFilters, "tab");
    delete filters["sort_dir"];
    delete filters["sort_by"];
    if (isEqual(filters, prevQueryFilters)) {
      return;
    }
    setLoading(true);
    fetchData(filters);
    setPrevQueryFilters(filters);
  }, [queryFilters]);

  return (
    <Segment>
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Statistic.Group size="tiny" widths={6}>
        <Statistic>
          <Statistic.Label>
            <Icon name="phone" flipped="horizontally" /> Dials Made
          </Statistic.Label>
          <Statistic.Value>{loading ? "--" : dials}</Statistic.Value>
        </Statistic>
        <Statistic>
          <Statistic.Label>
            <Icon.Group>
              <Icon name="phone" />
              <Icon corner="top left" name="chevron up" />
            </Icon.Group>{" "}
            Pickups
          </Statistic.Label>
          <Statistic.Value>{loading ? "--" : pickups}</Statistic.Value>
        </Statistic>
        <Statistic>
          <Statistic.Label>Pickup Rate</Statistic.Label>
          <Statistic.Value>{loading ? "--" : pickupRate + "%"}</Statistic.Value>
        </Statistic>
        <Statistic>
          <Statistic.Label>
            <Icon name="hourglass three" /> Hours Called
          </Statistic.Label>
          <Statistic.Value>{loading ? "--" : hours}</Statistic.Value>
        </Statistic>
        <Statistic>
          <Statistic.Label>
            <Icon name="chart bar" />
            Avg Dials/Day
          </Statistic.Label>
          <Statistic.Value>{loading ? "--" : avgDialsPerDay}</Statistic.Value>
        </Statistic>
        <Statistic>
          <Statistic.Label>
            <Icon name="chart line" />
            Leads{" "}
            <Popup
              trigger={<Icon fitted name="help circle" />}
              content='Leads are calls with a "successful" disposition'
              position="bottom"
              size={"tiny"}
            />
          </Statistic.Label>
          <Statistic.Value>{loading ? "--" : leads}</Statistic.Value>
        </Statistic>
      </Statistic.Group>
    </Segment>
  );
}

export default CallDashboardSummaryStats;

import React, { useState, useEffect, useRef } from "react";
import { Button, Segment, Icon, Input } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import ActionsModal from "./ActionsModal";

import "./OptionsForm.scoped.scss";

const OptionsForm = ({
  question,
  questionIndex,
  questions = [],
  onSuccess,
  updateQuestion,
}) => {
  const [options, setOptions] = useState([]);
  const actionsModal = useRef();

  const isMulti = questions.length > 0;

  useEffect(() => {
    setOptions(question?.options || []);
  }, [question]);

  const handleAdd = () => {
    const emptyOption = {
      label: "",
      value: "",
    };
    const newOptions = [...options, emptyOption];
    setOptions(newOptions);
  };

  const handleRemove = index => {
    updateOptionAtIndex(index);
  };

  const updateOptionAtIndex = (index, option = null) => {
    let newOptions;
    if (option) {
      newOptions = [
        ...options.slice(0, index),
        option,
        ...options.slice(index + 1),
      ];
    } else {
      newOptions = [...options.slice(0, index), ...options.slice(index + 1)];
    }
    setOptions(newOptions);
  };

  const handleChange = (data, index) => {
    const { value } = data;
    const option = {
      label: value,
      value,
    };
    updateOptionAtIndex(index, option);
  };

  const handleSubmit = () => {
    const newQuestion = {
      ...question,
      options,
    };
    updateQuestion(newQuestion);
  };

  const handleEditActions = (option, index) => {
    actionsModal.current.open(option, index);
  };

  const renderOption = (option, index) => {
    const { label } = option;
    return (
      <Segment className="option" key={index}>
        <div className="left">
          <Input
            value={label}
            placeholder={`Option ${index + 1}`}
            onChange={(_, data) => handleChange(data, index)}
            autoFocus={label === ""}
          />
        </div>
        <div className="right">
          {isMulti && (
            <Button
              content="ACTIONS"
              className="actions textLink"
              size="mini"
              onClick={() => handleEditActions(option, index)}
            />
          )}
          <Icon name="close" link onClick={() => handleRemove(index)} />
        </div>
      </Segment>
    );
  };

  return (
    <>
      <ActionsModal
        ref={actionsModal}
        questions={questions}
        questionIndex={questionIndex}
        updateOptionAtIndex={updateOptionAtIndex}
      />
      <RuvixxForm
        ready
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        submitButtonText="SAVE"
        className="options-form"
      >
        <Button
          basic
          size="tiny"
          icon="plus"
          content="Add Option"
          onClick={handleAdd}
        />
        {options.length > 0 && (
          <Segment.Group>{options.map(renderOption)}</Segment.Group>
        )}
      </RuvixxForm>
    </>
  );
};

export default OptionsForm;

import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import CampaignService from "../../../services/Campaign";
import ConstantContactCampaignsService from "../../../services/ConstantContactCampaigns";
import EmailTemplateService from "../../../services/EmailTemplate";
import ConstantContactAccountsService from "../../../services/ConstantContactAccounts";

function CampaignActivityForm(props) {
  const [formData, setFormData] = useState({
    id: null,
    account_id: null,
    cc_campaign_activity_id: "",
    campaign_id: null,
    template_id: null,
    active: true,
  });
  const [accountOptions, setAccountOptions] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);

  const fetchAccounts = useCallback(async () => {
    const accounts = await ConstantContactAccountsService.getForFilters();
    let accountOptions = accounts.map(account => ({
      text: account.name,
      key: account.id,
      value: account.id,
      authenticated: account.authentication_status === "authenticated",
    }));
    setAccountOptions(accountOptions);
  }, []);

  const fetchCampaigns = useCallback(async () => {
    let campaignOptions = await CampaignService.getCampaignsForFilters();
    campaignOptions = campaignOptions.map(campaign => ({
      text: campaign.name,
      key: campaign.id,
      value: campaign.id,
    }));
    setCampaignOptions(campaignOptions);
  }, []);

  const fetchTemplates = useCallback(async () => {
    let templateOptions =
      await EmailTemplateService.getEmailTemplatesForFilters();
    templateOptions = templateOptions.map(template => {
      let disabled =
        template.enabled &&
        template.info.provider_list &&
        !template.info.provider_list.constant_contact;
      return {
        text: template.name,
        key: template.id,
        value: template.id,
        disabled: disabled,
      };
    });
    setTemplateOptions(templateOptions);
  }, []);

  const fetchCampaignActivity = useCallback(async ccCampaignId => {
    const {
      id,
      account_id,
      cc_campaign_activity_id,
      campaign_id,
      template_id,
      active,
    } = await ConstantContactCampaignsService.getCampaignActivity(ccCampaignId);
    setFormData({
      id,
      account_id,
      cc_campaign_activity_id,
      campaign_id,
      template_id,
      active,
    });
  }, []);

  useEffect(() => {
    fetchAccounts();
    fetchCampaigns();
    fetchTemplates();
  }, [fetchCampaigns, fetchTemplates]);

  useEffect(() => {
    if (props.ccCampaignId) {
      fetchCampaignActivity(props.ccCampaignId);
    }
  }, [props.ccCampaignId, fetchCampaignActivity]);

  const handleChange = (event, data) => {
    const formDataCopy = { ...formData };
    formDataCopy[data.name] =
      data.type === "checkbox" ? data.checked : data.value;
    setFormData(formDataCopy);
  };

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const handleSubmit = async e => {
    try {
      if (formData.id) {
        await ConstantContactCampaignsService.editCampaignActivity(
          formData.id,
          formData
        );
      } else {
        await ConstantContactCampaignsService.createCampaignActivity(formData);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  return (
    <RuvixxForm
      ready={
        !!formData.cc_campaign_activity_id &&
        !!formData.campaign_id &&
        !!formData.template_id
      }
      onSubmit={handleSubmit}
      onSuccess={props.onSuccess}
    >
      <Form.Select
        search
        required
        label="Account"
        name="account_id"
        selection
        onChange={handleSelect}
        options={accountOptions}
        value={formData.account_id}
      />
      <Form.Input
        inline
        required
        label="Campaign Activity Id"
        name="cc_campaign_activity_id"
        value={formData.cc_campaign_activity_id}
        onChange={handleChange}
      />
      <Form.Select
        required
        label="Campaign"
        name="campaign_id"
        selection
        search
        onChange={handleSelect}
        options={campaignOptions}
        value={formData.campaign_id}
      />
      <Form.Select
        search
        required
        label="Email Template"
        name="template_id"
        selection
        onChange={handleSelect}
        options={templateOptions}
        value={formData.template_id}
      />
      <Form.Field required>
        <label style={{ fontSize: "0.8em" }}>Active</label>
        <Form.Checkbox
          toggle
          name="active"
          checked={formData.active === true}
          onChange={handleChange}
        />
      </Form.Field>
      ,
    </RuvixxForm>
  );
}

CampaignActivityForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default CampaignActivityForm;

import React, { useState } from "react";
import { Button, Checkbox, Modal } from "semantic-ui-react";
import SequenceService from "../../../services/Sequence";

const noError = {
  error: false,
  message: "",
  title: "",
};

export default function SequenceIsActiveToggle({
  sequence,
  onSuccess,
  disabled = false,
  label,
}) {
  const [error, setError] = useState(noError);

  const toggleIsActive = async sequence => {
    let data = { ...sequence };
    data.campaign_ids = sequence.campaigns.map(campaign => campaign.id);
    data.is_active = !sequence.is_active;
    try {
      await SequenceService.editSequence(sequence.id, data);
      onSuccess();
    } catch (e) {
      const err = {
        error: true,
        message: e.message,
        title: e.title,
      };
      setError(err);
    }
  };

  const clearError = async () => {
    setError(noError);
  };

  return (
    <>
      <Modal
        closeIcon
        size={"mini"}
        onCancel={clearError}
        open={error.error}
        onClose={clearError}
      >
        <Modal.Header>{error.title || "Error"}</Modal.Header>
        <Modal.Content>
          <p>{error.message}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={clearError}>Ok</Button>
        </Modal.Actions>
      </Modal>
      <Checkbox
        toggle
        name="isActive"
        checked={sequence.is_active}
        disabled={disabled}
        label={label}
        onChange={async () => {
          await toggleIsActive(sequence);
        }}
      />
    </>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import SequenceService from "../../../services/Sequence";
import RuvixxForm from "../../../components/RuvixxForm";
import PropTypes from "prop-types";

import { compareCreatedAt } from "../../../components/helpers";
import CampaignService from "../../../services/Campaign";

function SequenceCampaignForm({ sequenceId, onSuccess }) {
  const [formData, setFormData] = useState({
    id: sequenceId,
    campaignIds: [],
  });
  const [sequence, setSequence] = useState(null);

  const [campaignOptions, setCampaignOptions] = useState([]);

  const fetchSequence = useCallback(async () => {
    let sequence = await SequenceService.getSequence(sequenceId);
    const formDataCopy = { ...formData };
    formDataCopy.campaignIds = sequence.campaigns.map(campaign => campaign.id);
    setFormData(formDataCopy);
    setSequence(sequence);
  }, [sequenceId, formData]);

  const fetchCampaigns = useCallback(async () => {
    let campaignOptions = await CampaignService.getCampaignsForFilters();
    campaignOptions = campaignOptions.map(campaign => ({
      text: campaign.name,
      key: campaign.id,
      value: campaign.id,
    }));
    setCampaignOptions(campaignOptions);
  }, []);

  useEffect(() => {
    fetchCampaigns();
    fetchSequence();
  }, []);

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const handleSubmit = async e => {
    const { id, name, description, campaignIds } = formData;

    const data = {
      campaign_ids: campaignIds,
    };
    try {
      SequenceService.setSequenceCampaigns(id, data);
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  return (
    <RuvixxForm onSubmit={handleSubmit} onSuccess={onSuccess}>
      <Form.Select
        inline
        clearable
        fluid
        search
        multiple
        onChange={handleSelect}
        value={formData.campaignIds}
        label="Campaigns Attached"
        name="campaignIds"
        options={campaignOptions}
        placeholder="Select Campaigns"
      />
    </RuvixxForm>
  );
}

SequenceCampaignForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  sequenceId: PropTypes.number.isRequired,
};

export default SequenceCampaignForm;

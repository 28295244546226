import { applyFilters, doRequest } from "./helpers";

export default class CampaignScheduleService {
  static async getSchedule(campaignId, scheduleId) {
    return doRequest("GET", `/campaign/${campaignId}/schedule/${scheduleId}`);
  }

  static async getSchedules(campaignId, filters) {
    let path = `/campaign/${campaignId}/schedule/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createSchedule(campaignId, data) {
    return doRequest("POST", `/campaign/${campaignId}/schedule/`, { data });
  }

  static async editSchedule(campaignId, scheduleId, data) {
    return doRequest("PUT", `/campaign/${campaignId}/schedule/${scheduleId}`, {
      data,
    });
  }

  static async deleteSchedule(campaignId, scheduleId) {
    return doRequest(
      "DELETE",
      `/campaign/${campaignId}/schedule/${scheduleId}`
    );
  }

  static async validateUrl(campaignId, scheduleId, data) {
    return doRequest(
      "POST",
      `/campaign/${campaignId}/schedule/validate/${scheduleId}`,
      {
        data,
      }
    );
  }

  static async getEmailableContactsForSchedule(campaignId, data, filters) {
    let path = `/campaign/${campaignId}/schedule/get_emailable/`;
    path = applyFilters(filters, path);
    return doRequest("POST", path, {
      data,
    });
  }

  static syncScheduleData(campaignId, scheduleId) {
    return doRequest(
      "POST",
      `/campaign/${campaignId}/schedule/${scheduleId}/sync`
    );
  }
}

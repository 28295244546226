import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import DashboardService from "../../services/Dashboard";
import {
  Legend,
  Bar,
  BarChart,
  ResponsiveContainer,
  Surface,
  Symbols,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import round from "lodash/round";
import { Dimmer, Loader } from "semantic-ui-react";
import "./../../styles/call_dashboard.scss";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";

  do {
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  } while (/^#(?:[Ff]{2}){3}$/.test(color));

  return color;
}

function CallsBreakDownByDispositionChart({
  queryFilters,
  setDispositionIds,
  applyFilters,
}) {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [disabled, setDisabled] = useState({});
  const [loading, setLoading] = useState(true);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [prevQueryFilters, setPrevQueryFilters] = useState({});

  const fetchData = async filterParams => {
    const resp = await DashboardService.getBreakdownByDisposition(filterParams);
    if (!resp.data.length) {
      setData([{ name: "No Results", total: 0 }]);
      setDisabled(["No Results"]);
      setTotal(0);
      setLoading(false);
      return;
    }
    setTotal(
      resp.data.reduce(
        (accumulator, current) => accumulator + Number(current["total"]),
        0
      )
    );
    resp.data.map(elm => {
      elm.fill = getRandomColor();
    });
    setData(resp.data);
    setLoading(false);
  };

  useEffect(() => {
    const filters = omit(queryFilters, "tab");
    delete filters["sort_dir"];
    delete filters["sort_by"];
    if (isEqual(filters, prevQueryFilters)) {
      return;
    }
    setLoading(true);
    fetchData(filters);
    setPrevQueryFilters(filters);
  }, [queryFilters]);

  const handleLegendClick = dataKey => {
    if (total === 0) {
      return;
    }
    const index = data.findIndex(function (o) {
      return o.name === dataKey;
    });

    let newDisabled = { ...disabled };
    let newData = [...data];

    if (disabled[dataKey]) {
      newData[index] = newDisabled[dataKey]; // put the old value back
      delete newDisabled[dataKey];
    } else {
      if (Object.keys(disabled).length + 1 == data.length) {
        // NOTE: there is a bug that once all are hidden the chart won't come back so we'll prevent hiding all.
        return;
      }
      const obj = data[index];
      newDisabled[dataKey] = { ...obj };
      obj["total"] = 0;
      newData[index] = obj;
    }

    setTotal(
      newData.reduce(
        (accumulator, current) => accumulator + Number(current["total"]),
        0
      )
    );
    setDisabled(newDisabled);
    setData(newData);
  };

  const handleDataClick = dataItem => {
    if (total === 0) {
      return;
    }
    setDispositionIds([dataItem.id]);
    setShouldRefetch(true);
  };

  useEffect(() => {
    if (shouldRefetch) {
      applyFilters();
    }
    setShouldRefetch(false);
  }, [shouldRefetch]);

  const renderCustomizedLegend = ({ payload }) => {
    return (
      <div className="customized-legend">
        {payload.map(entry => {
          const { dataKey, color } = entry;
          const hidden = !!disabled[dataKey];
          const style = {
            marginRight: 10,
            color: hidden ? "#AAA" : "#000",
          };

          return (
            <span
              className="legend-item"
              onClick={() => handleLegendClick(dataKey)}
              style={style}
            >
              <Surface
                width={10}
                height={10}
                viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
              >
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                {hidden && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type="circle"
                    size={25}
                    fill={"#FFF"}
                  />
                )}
              </Surface>
              <span>{dataKey}</span>
            </span>
          );
        })}
      </div>
    );
  };

  const CustomTooltip = props => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      if (total === 0) {
        return <div className="custom-tooltip">No Results</div>;
      } else {
        const item = payload[0];
        const percentage = round((item.value / total) * 100, 2) || 0;
        return (
          <div className="custom-tooltip">
            {item.payload.name}: {item.value} ({percentage}%)
          </div>
        );
      }
    }
    return null;
  };

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <ResponsiveContainer minHeight={"600px"}>
        <BarChart data={data} layout="vertical" barCategoryGap={1}>
          <XAxis type="number" dataKey="total" />
          <YAxis width={200} type="category" dataKey="name" />
          <Bar dataKey="total" onClick={handleDataClick} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <CartesianGrid horizontal={false} />
          <Legend
            layout="vertical"
            content={renderCustomizedLegend}
            payload={data.map((item, idx) => {
              return {
                dataKey: item.name,
                color: item.fill,
              };
            })}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

CallsBreakDownByDispositionChart.propTypes = { config: PropTypes.any };

export default CallsBreakDownByDispositionChart;

import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Popup, Icon, Dropdown } from "semantic-ui-react";
import RuvixxForm from "../../components/RuvixxForm";
import FadingPopup from "../../components/FadingPopup";
import SmtpAccountService from "../../services/SmtpAccounts";
import ConfirmationModal from "../../components/modals/ConfirmationModal";

const presets = [
  {
    name: "Office 365",
    server: "smtp.office365.com",
    port: "587",
    encryption: "starttls",
  },
  {
    name: "Outlook",
    server: "smtp-mail.outlook.com",
    port: "587",
    encryption: "starttls",
  },
  {
    name: "Gmail",
    server: "smtp.gmail.com",
    port: "587",
    encryption: "starttls",
  },
  { name: "Custom", server: "", port: "", encryption: "" },
];
const encryptionOptions = [
  {
    key: "ssl-tls",
    text: "SSL/TLS",
    value: "ssl-tls",
  },
  {
    key: "starttls",
    text: "STARTTLS",
    value: "starttls",
  },
];

const renderTriggerButton = editMode =>
  editMode ? (
    <Dropdown.Item content="Edit SMTP Account" icon="edit" />
  ) : (
    <Button content="New SMTP Account" size="tiny" className="item-adder" />
  );

const SmtpAccountDeleteModal = ({ configId, onSuccess }) => {
  const handleDelete = async () => {
    await SmtpAccountService.deleteSmtpAccount(configId);
    if (onSuccess) {
      await onSuccess();
    }
  };

  return (
    <ConfirmationModal
      actionDescription="Delete SMTP Account"
      buttonColor="red"
      onConfirm={handleDelete}
      menuTrigger
      icon="trash"
      warning
    >
      <p>Are you sure you want to delete this SMTP Account?</p>
    </ConfirmationModal>
  );
};

const SmtpAccountModal = ({ configId, onSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [presetName, setPresetName] = useState();
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [server, setServer] = useState();
  const [port, setPort] = useState();
  const [encryption, setEncryption] = useState();
  const [isDefault, setIsDefault] = useState();
  const [testPopupOpen, setTestPopupOpen] = useState();
  const [loading, setLoading] = useState();
  const [result, setResult] = useState();

  const isReady = !!(
    presetName &&
    email &&
    username &&
    password &&
    server &&
    port &&
    encryption
  );

  const resetFieldValues = () => {
    setPresetName(null);
    setEmail("");
    setUsername("");
    setPassword("");
    setServer("");
    setPort("");
    setEncryption(null);
    setIsDefault(false);
    setTestPopupOpen(false);
    setLoading(false);
    setResult(null);
  };

  const fetchSmtpAccount = async id => {
    if (!id) {
      return;
    }
    const { username, server, port, encryption, return_addr, is_default } =
      await SmtpAccountService.getSmtpAccount(id);
    setPresetName("Custom");
    setEmail(return_addr);
    setUsername(username);
    setServer(server);
    setPort(port);
    setEncryption(encryption);
    setIsDefault(is_default);
  };

  useEffect(resetFieldValues, []);

  const handlePresetChange = presetName => {
    const { server, port, encryption } = presets.find(
      ({ name }) => name === presetName
    );
    setPresetName(presetName);
    setServer(server);
    setPort(port ? +port : "");
    setEncryption(encryption);
  };

  const handleSubmit = async () => {
    if (!result || !result.success) {
      setTestPopupOpen(true);
      return;
    }
    if (configId) {
      await SmtpAccountService.updateSmtpAccount(configId, {
        email,
        username,
        password,
        server,
        port,
        encryption,
        isDefault,
      });
    } else {
      await SmtpAccountService.createSmtpAccount(
        email,
        username,
        password,
        server,
        port,
        encryption,
        isDefault
      );
    }
    if (onSuccess) {
      await onSuccess();
    }
    setModalOpen(false);
  };

  const handleOpen = async () => {
    setModalOpen(true);
    resetFieldValues();
    await fetchSmtpAccount(configId);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleTestConnection = async () => {
    setLoading(true);
    setResult(null);
    const result = await SmtpAccountService.testSmtpAccount(
      email,
      username,
      password,
      server,
      port,
      encryption
    );
    setLoading(false);
    setResult(result);
  };

  const labelData = (() => {
    if (loading) {
      return { icon: "circle notched", text: "Attempting to connect..." };
    }
    if (result && result.success) {
      return {
        icon: "check",
        text: "Connected successfully.",
        color: "green",
      };
    }
    if (result && !result.success) {
      return {
        icon: "close",
        text: "Failed to connect.",
        color: "red",
        popup: result.message,
      };
    }
    return null;
  })();

  return (
    <Modal
      size="small"
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={renderTriggerButton(!!configId)}
    >
      <Modal.Header content={`${configId ? "Edit" : "New"} SMTP Account`} />
      <Modal.Content>
        <RuvixxForm
          ready={isReady}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          submitButtonText="SAVE"
        >
          <Form.Select
            inline
            required
            name="emailProvider"
            label="Email Provider"
            options={presets.map(({ name }) => ({
              key: name,
              text: name,
              value: name,
            }))}
            value={presetName}
            onChange={(_, { value }) => handlePresetChange(value || null)}
          />
          <Form.Input
            required
            inline
            type="email"
            name="email"
            label="Email"
            value={email}
            onChange={(_, { value }) => setEmail(value)}
          />
          <Form.Input
            required
            inline
            name="username"
            label="Username"
            value={username}
            onChange={(_, { value }) => setUsername(value)}
          />
          <Form.Input
            required
            inline
            type="password"
            name="password"
            label="Password"
            value={password}
            onChange={(_, { value }) => setPassword(value)}
          />
          {presetName === "Custom" && (
            <>
              <Form.Input
                required
                inline
                name="server"
                label="Server"
                value={server}
                onChange={(_, { value }) => setServer(value)}
              />
              <Form.Input
                required
                inline
                name="port"
                label="Port"
                value={port}
                onChange={(_, { value }) => setPort(+value.replace(/\D+/, ""))}
              />
              <Form.Select
                inline
                required
                name="encryption"
                label="Encryption"
                options={encryptionOptions}
                value={encryption}
                onChange={(_, { value }) => setEncryption(value || null)}
              />
            </>
          )}
          {(!configId || !isDefault) && (
            <Form.Checkbox
              toggle
              name="isDefault"
              label="Set as default account"
              checked={isDefault}
              onChange={(_, { checked }) => setIsDefault(checked)}
            />
          )}
          <Form.Group inline>
            <FadingPopup
              open={testPopupOpen}
              setOpen={setTestPopupOpen}
              content="Please test your connection before you save it"
              trigger={
                <Form.Button
                  content="Test Connection"
                  size="tiny"
                  onClick={handleTestConnection}
                  disabled={!isReady}
                />
              }
              style={{ width: 220 }}
            />
            {labelData && (
              <label>
                <Icon
                  name={labelData.icon}
                  loading={loading}
                  color={labelData.color}
                />
                {labelData.text}{" "}
                {labelData.popup && (
                  <Popup
                    position="top center"
                    content={labelData.popup}
                    trigger={
                      <Button className="textLink" content="More info" />
                    }
                  />
                )}
              </label>
            )}
          </Form.Group>
        </RuvixxForm>
      </Modal.Content>
    </Modal>
  );
};

export default SmtpAccountModal;

export { SmtpAccountDeleteModal };

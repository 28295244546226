import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import DataJobErrorsTable from "./DataJobErrorsTable";

const ValidationReport = ({ dataJobId }) => {
  return (
    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Button secondary compact size="tiny" style={{ margin: "0.3em 0 0" }}>
          <Icon name="eye" />
          View Errors
        </Button>
      }
    >
      <Modal.Header>Import Validation Report</Modal.Header>
      <Modal.Content>
        <DataJobErrorsTable dataJobId={dataJobId} />
      </Modal.Content>
    </Modal>
  );
};

export default ValidationReport;

import { useJsApiLoader } from "@react-google-maps/api";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "semantic-ui-react";
import { ISO2TO3, ISO3TO2 } from "../../constants/countries";

const libraries = ["places"];

const SearchLocationInput = props => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    // ...otherOptions
  });
  const [value, setValue] = useState(props.value);
  const [results, setResults] = useState(
    props.value
      ? [{ key: props.value, value: props.value, text: props.value }]
      : []
  );
  const [isLoading, setIsLoading] = useState(false);

  const autoCompleteService = useRef(null);
  const placesService = useRef(null);

  useEffect(() => {
    if (isLoaded) {
      autoCompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(
        document.getElementById("places-field")
      );
    }
  }, [isLoaded]);

  const resetComponent = () => {
    setResults([]);
    setIsLoading(false);
    setValue(null);
  };

  const filterAutocompleteResults = (prediction, searchQuery) => {
    if (/^\d/.test(searchQuery)) return true;
    if (ISO3TO2[props.country] === "IN") return true;
    if (!prediction.types) return false;
    if (prediction.types.includes("street_address")) return true;
    // Results that don't include 'street_address' will go through the check
    let typeCounter = 0;
    if (prediction.types.includes("geocode")) {
      typeCounter++;
    }
    if (prediction.types.includes("route")) {
      typeCounter++;
    }
    return prediction.types.length > typeCounter;
  };

  const handleAutocompleteResult = (searchQuery, predictions, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      setResults(
        predictions
          .filter(prediction =>
            filterAutocompleteResults(prediction, searchQuery)
          )
          .map(prediction => {
            return {
              key: prediction.place_id,
              value: prediction.structured_formatting.main_text,
              text: `${prediction.structured_formatting.main_text} ${prediction.structured_formatting.secondary_text}`,
              data: prediction,
            };
          })
      );
    } else {
      placesService.current.findPlaceFromQuery(
        {
          query: searchQuery,
          fields: ["place_id"],
        },
        (searchResults, status) =>
          handleFindPlaceResult(searchResults, status, searchQuery)
      );
    }
    setIsLoading(false);
  };

  const handleFindPlaceResult = (searchResults, status, searchQuery) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      const placeId = searchResults?.[0]?.place_id;
      setResults([
        {
          key: placeId,
          value: searchQuery,
          text: searchQuery,
          data: { place_id: placeId },
        },
      ]);
    } else {
      setResults([{ key: searchQuery, value: searchQuery, text: searchQuery }]);
    }
  };

  const handleSearchChange = async (e, { searchQuery, firstLoad }) => {
    if (!searchQuery) {
      return resetComponent();
    }
    setIsLoading(true);
    let options = {
      input: searchQuery,
    };
    if (/^\d/.test(searchQuery)) {
      options.types = ["address"];
    }
    if (props.country) {
      options.componentRestrictions = { country: ISO3TO2[props.country] };
    }
    autoCompleteService.current.getPlacePredictions(
      options,
      (predictions, status) =>
        handleAutocompleteResult(searchQuery, predictions, status)
    );
  };

  const fillInAddress = (result, status) => {
    let street1 = "";
    let street2 = "";
    let city = "";
    let postal_code = "";
    let region = "";
    let country = "";
    for (const component of result.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "premise":
          street1 = `${component.long_name} ${street1}`;
          break;

        case "street_number":
          street1 = `${component.long_name} ${street1}`;
          break;

        case "route":
          street1 += component.short_name;
          break;

        case "subpremise":
          street2 = `${component.long_name} ${street2}`;
          break;

        case "postal_code":
          postal_code = `${component.long_name}${postal_code}`;
          break;

        case "postal_code_suffix":
          postal_code = `${postal_code}-${component.long_name}`;
          break;

        case "locality":
          city = component.long_name;
          break;

        case "administrative_area_level_1":
          region = component.long_name;
          break;

        case "country":
          country = ISO2TO3[component.short_name];
          break;
      }
    }
    if (!street1) {
      let pattern;
      let re;
      if (city) {
        pattern = `^(?<address1>.+),\\s+(?<rest>${city})`;
      } else if (region) {
        pattern = `^(?<address1>.+),\\s+(?<rest>${region})`;
      }
      re = new RegExp(pattern);
      const match = re.exec(result.formatted_address);
      if (match) {
        street1 = match.groups?.address1;
      }
    }
    if (!city) {
      city =
        result.address_components.filter(({ types }) =>
          [
            "administrative_area_level_2",
            "administrative_area_level_3",
          ].includes(types[0])
        )?.[0]?.long_name || "";
    }

    props.onChange({
      street1,
      street2,
      city,
      region,
      country,
      postal_code,
      latitude: result.geometry.location.lat(),
      longitude: result.geometry.location.lng(),
      info: result,
    });
    setResults([{ key: street1, value: street1, text: street1 }]);
    setValue(street1);
  };

  const handleResultSelect = (e, { options, value }) => {
    if (!value) {
      return resetComponent();
    }
    const selection = options.find(opt => value === opt.value);
    if (selection?.data?.place_id && placesService.current) {
      placesService.current.getDetails(
        {
          placeId: selection.data.place_id,
          fields: ["address_component", "formatted_address", "geometry"],
        },
        fillInAddress
      );
    } else {
      // Not a Google search result
      setValue(selection.value);
      props.onChange({ street1: selection.value });
    }
  };

  const searchFn = options => {
    return options;
  };

  return isLoaded ? (
    <Form.Field>
      <div id="places-field" />
      <Form.Select
        inline={!!props.inline}
        fluid
        search={searchFn}
        required
        loading={isLoading}
        clearable
        label={props.label}
        value={value}
        options={results}
        placeholder="123 Main St"
        onSearchChange={debounce(handleSearchChange, 500, {
          leading: true,
        })}
        onChange={handleResultSelect}
      />
    </Form.Field>
  ) : (
    "Loading..."
  );
};

SearchLocationInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  address: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

SearchLocationInput.defaultProps = {
  address: {},
};

export default SearchLocationInput;

import React from "react";
import { createDateTimeFormatter } from "../../../components/helpers";
import { Button, Header, Modal } from "semantic-ui-react";

const defaultDateTimeFormatter = createDateTimeFormatter();

const buttonStyles = {
  float: "right",
  marginBottom: "10px",
};

export default function ScheduledCallModal({
  dialerInfo,
  scheduledModal,
  setScheduledModal,
}) {
  const handleClose = () => setScheduledModal(false);
  const handleSubmit = () => handleClose();

  return (
    <Modal
      open={scheduledModal}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <Header content={"Scheduled Call"} />
      <Modal.Content>
        <div>
          <span>
            This is a scheduled call with contact {dialerInfo.contact.full_name}{" "}
            set for {defaultDateTimeFormatter(dialerInfo.callback_time)}
          </span>
        </div>
        <Button
          size="tiny"
          content="OK"
          primary
          onClick={handleSubmit}
          style={buttonStyles}
        />
      </Modal.Content>
    </Modal>
  );
}

import React, { useRef } from "react";
import { List } from "semantic-ui-react";
import TagService from "../services/Tag";
import ApplyTagModal from "./modals/ApplyTagModal";
import RvxTag from "./RvxTag";
import { storeError } from "services/helpers";

function TagList({ tags, modelType, modelId, onUpdate, tableCell }) {
  const applyTagModal = useRef();

  const handleClick = tag => {
    applyTagModal.current.open(tag);
  };

  const handleApplyTag = async (tag, metadata) => {
    try {
      await TagService.addTag(tag, modelType, [modelId], metadata);
    } catch (error) {
      storeError(error.message);
      return;
    }
    if (onUpdate) {
      await onUpdate();
    }
  };

  const sortedTags = (tags ?? []).sort((firstTag, secondTag) =>
    firstTag.name.localeCompare(secondTag.name)
  );

  return (
    <>
      <ApplyTagModal ref={applyTagModal} onApplyTag={handleApplyTag} />
      <List horizontal style={tableCell ? { display: "flex" } : undefined}>
        {sortedTags.map(tag => {
          return (
            <RvxTag
              key={tag.id}
              tag={tag}
              onClick={!tag.disableClick && handleClick}
              tableCell={tableCell}
            />
          );
        })}
      </List>
    </>
  );
}

export default TagList;

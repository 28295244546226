import React from "react";
import { Link } from "react-router-dom";
import "react-table-6/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import BaseTable from "../../../components/BaseTable";
import { Dropdown, List } from "semantic-ui-react";
import AuthService from "../../../services/Auth";
import SequenceService from "../../../services/Sequence";
import "./SequencesTable.scoped.scss";
import CustomDropdown from "../../../components/CustomDropdown";
import _SequenceModal from "./SequenceModal";
import _DeleteSequenceModal from "./DeleteSequenceModal";
import SequenceIsActiveToggle from "./SequenceIsActiveToggle";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import { checkIsAuthorized } from "../../../components/helpers";
import setPageTitle from "../../../helpers/title";

const SequenceModal = withRoleCheck(_SequenceModal, [
  ACL_RELATIONSHIPS.sequence.create,
  ACL_RELATIONSHIPS.campaigns.read,
  ACL_RELATIONSHIPS.user.read,
]);
const EditSequenceModal = withRoleCheck(_SequenceModal, [
  ACL_RELATIONSHIPS.sequence.edit,
  ACL_RELATIONSHIPS.campaigns.read,
  ACL_RELATIONSHIPS.user.read,
]);
const DeleteSequenceModal = withRoleCheck(_DeleteSequenceModal, [
  ACL_RELATIONSHIPS.sequence.delete,
]);

class SequencesTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = SequenceService.getSequences;

    this.state = {
      ...this.state,
      className: "Sequences",
      tableName: "sequences",
      noDataText: "No Sequences found. Try adjusting your filters.",
      enableTags: false,
      createButton: <SequenceModal button onSuccess={this.fetchData} />,
      enableSearch: false,
      enableSettings: false,
      canEditSequences: checkIsAuthorized([ACL_RELATIONSHIPS.sequence.edit]),
    };
  }

  async componentDidMount() {
    setPageTitle("Sequences");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  deleteSequence = async sequenceId => {
    await SequenceService.deleteSequence(sequenceId);
    this.fetchData();
  };

  viewDetails = id => {
    this.props.history.push(`/sequences/${id}`);
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 80,
        Cell: props => <div>{props.value}</div>,
      },
      {
        Header: "Name",
        accessor: "name",
        width: 300,
        Cell: props => (
          <Link to={`/sequences/${props.original.id}`}>{props.value}</Link>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        width: 300,
        Cell: props => <p>{props.value}</p>,
      },
      {
        Header: "Attached to Campaigns",
        accessor: "campaigns",
        sortable: false,
        Cell: props => (
          <List className="sequence campaigns">
            {props.value.map(campaign => (
              <List.Item key={campaign.id}>
                <Link to={`/campaigns/${campaign.id}`} key={campaign.id}>
                  {campaign.name}
                </Link>
              </List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Steps",
        accessor: "steps",
        sortable: false,
        Cell: props => <p>{props.value.length}</p>,
      },
      {
        Header: "Active",
        accessor: "is_active",
        sortable: false,
        Cell: ({ original: sequence }) => {
          return (
            <SequenceIsActiveToggle
              sequence={sequence}
              onSuccess={this.fetchData}
              disabled={!this.state.canEditSequences}
            />
          );
        },
      },
      {
        Header: "Actions",
        id: "actions",
        width: 60,
        className: "action-menu",
        sortable: false,
        Cell: ({ original: { id } }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditSequenceModal sequenceId={id} onSuccess={this.fetchData} />
              <Dropdown.Item
                className="primary"
                content="View Sequence Details"
                icon="eye"
                onClick={() => this.viewDetails(id)}
              />
              <DeleteSequenceModal
                menuTrigger
                onConfirmDelete={() => this.deleteSequence(id)}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return this.renderTable();
  }
}

export default SequencesTable;

import React, { useState, useCallback, useEffect } from "react";
import { Icon, Form } from "semantic-ui-react";
import CampaignService from "../../services/Campaign";
import EntityService from "../../services/Entity";
import RuvixxSelect from "../../components/RuvixxSelect";

import "./Filters.scoped.scss";

const Filters = ({ filters = {}, setFilters }) => {
  const [campaignId, setCampaignId] = useState(filters.campaignId || null);
  const [entityId, setEntityId] = useState(filters.entityId || null);

  useEffect(() => {
    const newFilters = {
      ...filters,
      campaign_id: campaignId,
      entity_id: entityId,
    };
    setFilters(newFilters);
  }, [campaignId, entityId]);

  const handleSelectCampaign = (_, { value }) => {
    setCampaignId(value || null);
  };

  const handleSelectEntity = (_, { value }) => {
    setEntityId(value || null);
  };

  const clearFilters = () => {
    setCampaignId(null);
    setEntityId(null);
  };

  return (
    <Form className="inbox-filters">
      <Form.Group inline>
        <Icon name="filter" />
        <RuvixxSelect
          placeholder="Campaign"
          clearable
          value={campaignId || ""}
          queryFn={CampaignService.getCampaignsForFilters}
          onChange={handleSelectCampaign}
        />
        <RuvixxSelect
          placeholder="Entity"
          clearable
          value={entityId || ""}
          queryFn={EntityService.getEntitiesForFilters}
          onChange={handleSelectEntity}
        />
        <Form.Button
          content="Clear Filters"
          secondary
          size="mini"
          onClick={clearFilters}
        />
      </Form.Group>
    </Form>
  );
};

export default Filters;

import React, { useState } from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import ContactsTable from "../components/ContactsTable";

function ViewContactsModal({
  dialSessionId,
  contactCount,
  btnColor = null,
  btnText,
  btnClass,
  icon = null,
  iconColor,
  modalHeader,
  isTotal,
  isUrgent,
  isCallable,
  disabled,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  return (
    <div>
      <Button
        compact
        basic={isTotal}
        size="tiny"
        className={btnClass}
        color={btnColor}
        disabled={disabled}
        onClick={handleOpen}
      >
        <span className="contacts-count">
          {contactCount} {btnText}
        </span>
        {icon ? <Icon name={icon} /> : null}
      </Button>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        closeOnDimmerClick={false}
        size="large"
        closeIcon
      >
        <Header content={modalHeader} />
        <Modal.Content>
          <ContactsTable
            dialSessionId={dialSessionId}
            total={isTotal}
            isUrgent={isUrgent}
            isCallable={isCallable}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
}

ViewContactsModal.defaultProps = {
  disabled: false,
  isUrgent: false,
  isCallable: false,
  isTotal: false,
};

export { ViewContactsModal };

import React from "react";
import { Container, Dropdown, Segment } from "semantic-ui-react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

import "../styles/table.scss";
import "../styles/tab_view.scss";

import BaseTable from "./BaseTable";
import AuthService from "../services/Auth";
import ACL_RELATIONSHIPS from "../acl-relationships";
import withRoleCheck from "./hocs/withRoleCheck";
import CustomDropdown from "./CustomDropdown";
import setPageTitle from "../helpers/title";
import SourceStatusesService from "../services/SourceStatus";
import _SourceStatusModal from "./modals/SourceStatusModal";
import _DeleteSourceStatusModal from "./modals/DeleteSourceStatusModal";

const SourceStatusModal = withRoleCheck(_SourceStatusModal, [
  ACL_RELATIONSHIPS.sourceStatus.edit,
  ACL_RELATIONSHIPS.sourceStatus.create,
]);

const DeleteSourceStatusModal = withRoleCheck(_DeleteSourceStatusModal, [
  ACL_RELATIONSHIPS.sourceStatus.delete,
]);

class SourceStatusesTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = SourceStatusesService.getTable;

    this.state = {
      ...this.state,
      header: "Source Statuses",
      headerIcon: "building",
      tableName: "source_status",
      noDataText: "No Source Statuses found. Try adjusting your filters.",
      statusOptions: [],
      createButton: (
        <SourceStatusModal fetchStatus={this.fetchData} tableView={true} />
      ),
      enableCustomFieldsSettings: true,
    };
  }

  async componentDidMount() {
    setPageTitle("Source Statuses");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "Source Status ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Actions",
        id: "actions",
        width: 140,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <SourceStatusModal
                fetchStatus={this.fetchData}
                sourceStatus={props.original}
              />
              <DeleteSourceStatusModal
                fetchStatus={this.fetchData}
                sourceStatusId={props.original.id}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default SourceStatusesTable;

import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import "./App.scss";
import { ErrorBoundary } from "./components/ErrorBoundary";
import ErrorModal from "./components/ErrorModal";
import { Header } from "./components/Header";
import History from "./helpers/history";
import { Router } from "./routes/Router";
import Maintenance from "./components/Maintenance";
import { MAINTENANCE_MODE } from "./Config";
import { QueryClient, QueryClientProvider } from "react-query";
import { useCustomNavigate } from "./hooks/useCustomNavigate";
import IncomingCall from "components/IncomingCall/IncomingCall";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const bodyErrorMessage = props => {
  return (
    <div>
      <h2>Oops...</h2>
      <p>
        An unexpected error occurred. Details were logged on the server to help
        in finding the cause. No private information was sent.
      </p>
      <details style={{ whiteSpace: "pre-wrap" }}>
        {props.exception && props.exception.toString()}
        <br />
        {props.info.componentStack}
      </details>
    </div>
  );
};

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const SetHistoryInstance = () => {
  History.historyInstance = useCustomNavigate();
  return null;
};

function App() {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(MAINTENANCE_MODE);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      // Listen for updates from the service worker
      navigator.serviceWorker.addEventListener("message", event => {
        if (event.data && event.data.type === "UPDATE_AVAILABLE") {
          setUpdateAvailable(true);
          setMaintenanceMode(event.data.maintenanceMode);
        }
      });
    }
  }, []);

  if (maintenanceMode) {
    return (
      <div className="App">
        <Maintenance />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
          <BrowserRouter>
            <SetHistoryInstance />
            <Header />
            <IncomingCall />
            <ErrorBoundary errorDisplay={bodyErrorMessage}>
              <ErrorModal>
                <QueryClientProvider client={queryClient}>
                  <Router />
                </QueryClientProvider>
              </ErrorModal>
            </ErrorBoundary>
          </BrowserRouter>
        </Sentry.ErrorBoundary>
      </div>
    );
  }
}

export default App;
export { queryClient };

import React, { useState } from "react";
import { Modal, Button, Header } from "semantic-ui-react";
import ManualActivityForm from "components/forms/ManualActivityForm";

const ManualActivityModal = ({ modelId, modelType, onSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeIcon
      closeOnDimmerClick={false}
      onFocus={e => {
        e.stopPropagation();
      }}
      onClick={e => {
        e.stopPropagation();
      }}
      trigger={<Button content="New Activity" onClick={handleOpen} />}
    >
      <Header content="Create Activity" />
      <Modal.Content>
        <ManualActivityForm
          modelId={modelId}
          modelType={modelType}
          onSuccess={handleSuccess}
        />
      </Modal.Content>
    </Modal>
  );
};

export { ManualActivityModal };

import startCase from "lodash/startCase";
import React, { createRef } from "react";
import { Link } from "react-router-dom";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import {
  Checkbox,
  Container,
  Dropdown,
  Icon,
  List,
  Popup,
  Segment,
} from "semantic-ui-react";
import BaseTable from "components/BaseTable";
import { formatDate, checkIsAuthorized } from "components/helpers";
import MapModal from "components/MapModal";
import { CASE_TIERS } from "constants/Case";
import AuthService from "services/Auth";
import MachineService from "services/Machine";
import MachineApprovalStatusService from "services/MachineApprovalStatus";

import "styles/campaign.scss";
import "styles/table.scss";
import { DEFAULT_PAGE_SIZE } from "constants/Constants";
import ListTableColumn from "components/ListTableColumn";
import TagList from "components/TagList";
import ACL_RELATIONSHIPS from "acl-relationships";
import MachineStatusModal from "./modals/MachineStatusModal";

class MachinesTable extends BaseTable {
  constructor(props) {
    super(props);
    this.mapModal = createRef();

    this.queryMethod = MachineService.getTable;

    this.state = {
      ...this.state,
      defaultFilterParams: { case_id: encodeURIComponent(this.props.caseId) },
      header: "Machines",
      headerIcon: "server",
      className: "Machine",
      tableName: "machines",
      noDataText: "No Machines found. Try adjusting your filters.",
      users: [],
      // createButton: <NewEntityModal fetchEntities={this.fetchData} />,
      enableSearch: true,
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.machineTags.create,
        ACL_RELATIONSHIPS.machineTags.read,
        ACL_RELATIONSHIPS.machineTags.delete,
      ]),
      enableCustomFieldsSettings: true,
      exportTableName: "MachinesTable",
    };
  }

  async componentDidMount() {
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      // this.fetchCustomFields();
      this.updateFilterOptions();
      this.fetchTags();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.refresh !== prevProps.refresh) {
      this.fetchData();
    }
  }

  actionsDropdown() {
    const disableActions = this.state.checkedArr.length === 0;
    return (
      <Dropdown
        text="Actions"
        className="button mini"
        disabled={disableActions}
      >
        <Dropdown.Menu direction="left">
          <MachineStatusModal
            machineIds={this.state.checkedArr}
            fetchFn={this.fetchData}
            isDropdownButton={true}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  openMapModal = data => {
    this.mapModal.current.open(data);
  };

  setColumns = () => {
    const { pageSize } = this.state;
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, machine_id } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={machine_id}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "Machine ID",
        accessor: "machine_id",
        width: 50,
        Cell: ({ value }) => (
          <Link
            to={`/cases/${
              this.props.caseRouteId
            }/events/?machine_id=${encodeURIComponent(value)}&page=1&per_page=${
              pageSize || DEFAULT_PAGE_SIZE
            }`}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Case Tier",
        accessor: "case_tier",
        Cell: ({ value }) =>
          value === CASE_TIERS.UNUSABLE ? "Unusable" : value,
      },
      {
        Header: "Approval Status",
        accessor: "machine_approval_status_id",
        Cell: ({ original: { id, machine_approval_status }, value }) => {
          return (
            <MachineStatusModal
              machineStatusId={value}
              machineStatusName={machine_approval_status}
              machineIds={[id]}
              fetchFn={this.fetchData}
            />
          );
        },
      },
      {
        Header: "Products",
        accessor: "products",
      },
      {
        Header: "First Event",
        accessor: "first_event",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Last Event",
        accessor: "last_event",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Total # Events",
        accessor: "num_events",
      },
      {
        Header: "Recent # Events",
        accessor: "num_recent_events",
      },
      {
        Header: "Quality Score",
        accessor: "quality_score",
      },
      {
        Header: "Event Types",
        accessor: "event_types",
        Cell: ({ value }) => value?.join(", "),
      },
      {
        Header: "Email Domains",
        accessor: "email_domains",
        Cell: this.renderCountCell,
      },
      {
        Header: "Computer Domains",
        accessor: "computer_domains",
        Cell: this.renderCountCell,
      },
      {
        Header: "Active MAC",
        accessor: "active_mac",
      },
      {
        Header: "IP Country",
        accessor: "ip_country",
      },
      {
        Header: "Machine Locale",
        accessor: "machine_locale",
      },
      {
        Header: "Nearby Events",
        accessor: "proximity",
        headerClassName: "non-sortable",
        className: "centered",
        sortable: false,
        Cell: ({ original: { id, machine_id, distance }, value }) => {
          const { color, limit } = value;
          let content;
          if (limit) {
            content = (
              <div>
                Case events detected within {limit} meters.{" "}
                <strong>({distance} meters)</strong>
                <br />
                <a
                  onClick={() =>
                    this.openMapModal({
                      modelType: "Machine",
                      modelId: id,
                      name: `Machine ${machine_id}`,
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  Show on map
                </a>
              </div>
            );
          } else {
            content = (
              <>
                No nearby case events detected
                {!!distance && <strong>({distance} meters)</strong>}
              </>
            );
          }
          return (
            <Popup
              hoverable
              content={content}
              position={"top center"}
              trigger={
                <Icon
                  key={`proximity`}
                  size="large"
                  color={color}
                  name="circle"
                />
              }
            />
          );
        },
      },
      {
        Header: "WiFi Locations",
        accessor: "wifi_locations",
        Cell: this.renderCoordCell,
      },
      {
        Header: "IP Locations",
        accessor: "ip_locations",
        Cell: props => this.renderCoordCell(props, "ip"),
      },
      {
        Header: "Hostname",
        accessor: "hostname",
        sortable: false,
        Cell: props => <ListTableColumn data={props.value || []} />,
      },
      {
        Header: "Username",
        accessor: "username",
        sortable: false,
        Cell: props => <ListTableColumn data={props.value || []} />,
      },
      {
        Header: "Active WiFi Access Point",
        accessor: "active_wifi_access_point",
        sortable: false,
        Cell: props => <ListTableColumn data={props.value || []} />,
      },
      {
        Header: "Additional Email Addresses",
        accessor: "additional_email_addresses",
        sortable: false,
        Cell: props => <ListTableColumn data={props.value || []} />,
      },
    ];
    this.initTableSettings(columns);
  };

  renderCoordCell = ({ original, value }, locationType) => {
    if (!value) {
      return null;
    }
    return (
      <List horizontal bulleted style={{ display: "flex" }}>
        {Object.entries(value)
          .sort((a, b) => (a[1] > b[1] ? -1 : 1))
          .map(entry => {
            const hasLocations = entry[0] != "No Data";
            const textVal = `${entry[0]}: ${entry[1]}`;
            const [lat, lng] = entry[0].split(",");
            const center = { lat, lng };
            if (hasLocations) {
              return (
                <List.Item>
                  <a
                    key={entry[0]}
                    onClick={() =>
                      this.openMapModal({
                        modelType: "Machine",
                        modelId: original.id,
                        locationType: locationType || "wifi",
                        name: `Machine ${original.machine_id}`,
                        center,
                      })
                    }
                  >
                    {textVal}
                  </a>
                </List.Item>
              );
            } else {
              return <List.Item key={entry[0]}>{textVal}</List.Item>;
            }
          })}
      </List>
    );
  };

  renderCountCell({ value }) {
    if (!value) {
      return null;
    }
    return (
      <List horizontal bulleted style={{ display: "flex" }}>
        {Object.entries(value)
          .sort((a, b) => (a[1] > b[1] ? -1 : 1))
          .map(entry => (
            <List.Item key={entry[0]}>{`${entry[0]}: ${entry[1]}`}</List.Item>
          ))}
      </List>
    );
  }

  updateFilterOptions = async () => {
    const { tags } = this.state;
    const nullNotNullOptions = [
      {
        id: "null",
        name: "Is null",
      },
      {
        id: "not_null",
        name: "Is not null",
      },
    ];

    const filters = [
      {
        key: "first_event",
        title: "First Event Date",
        type: "dateRange",
      },
      {
        key: "last_event",
        title: "Last Event Date",
        type: "dateRange",
      },
      {
        key: "case_tier",
        title: "Case Tier",
        type: "select",
        data: Object.keys(CASE_TIERS).map(key => {
          return {
            id: CASE_TIERS[key],
            name: startCase(key.toLowerCase()),
          };
        }),
      },
      {
        key: "machine_approval_status_id",
        title: "Machine Approval Status",
        type: "ruvixxSelect",
        queryFn: filters =>
          MachineApprovalStatusService.getForFilters({
            ...filters,
          }),
      },
      {
        key: "machine_id",
        title: "Machine ID",
        type: "ruvixxSelect",
        queryFn: filters =>
          MachineService.getForFilters({
            case_id: encodeURIComponent(this.props.caseId),
            ...filters,
          }),
      },
      {
        key: "num_events",
        title: "Total # Events",
        type: "range",
      },
      {
        key: "num_recent_events",
        title: "Recent # Events",
        type: "range",
      },
      {
        key: "quality_score",
        title: "Quality Score",
        type: "range",
      },
      {
        key: "email_domains",
        title: "Email Domains",
        type: "select",
        data: nullNotNullOptions,
        multiple: false,
        clearable: true,
      },
      {
        key: "computer_domains",
        title: "Computer Domains",
        type: "select",
        data: nullNotNullOptions,
        multiple: false,
        clearable: true,
      },
      {
        key: "wifi_locations",
        title: "WiFi Locations",
        type: "select",
        data: nullNotNullOptions,
        multiple: false,
        clearable: true,
      },
      {
        key: "ip_locations",
        title: "IP Locations",
        type: "select",
        data: nullNotNullOptions,
        multiple: false,
        clearable: true,
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
    ];
    this.setState({ filters });
  };

  render() {
    return (
      <Container fluid className="route">
        <MapModal hasAddresses ref={this.mapModal} />
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default MachinesTable;

import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BaseTableV2 from "../../components/BaseTableV2/BaseTableV2";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import Actions from "./Actions";
import NotificationTypeSelector from "./NotificationTypeSelector";
import { formatDate } from "../../components/helpers";
import NotificationService from "../../services/Notification";
import { startCase, omitBy, isNil } from "lodash";

import "./NotificationsTable.scoped.scss";

const getModelTypeForDisplay = modelType => {
  const modelTypes = {
    ActivityLog: "Note",
  };
  return startCase(modelTypes[modelType] || modelType);
};

const getAssociatedModel = notification => {
  const { campaign, entity, contact } = notification;
  let model = { modelId: null, modelType: null, modelPath: null, name: null };
  if (campaign.id) {
    model = {
      modelId: campaign.id,
      modelType: "campaign",
      modelPath: "campaigns",
      name: campaign.name,
    };
  } else if (entity.id) {
    model = {
      modelId: entity.id,
      modelType: "entity",
      modelPath: "entities",
      name: entity.name,
    };
  } else if (contact.id) {
    model = {
      modelId: contact.id,
      modelType: "contact",
      modelPath: "contacts",
      name: contact.full_name,
    };
  }
  return model;
};

const Link = ({ notificationId, to, children }) => {
  const navigate = useNavigate();
  const handleClick = async event => {
    event.preventDefault();
    await NotificationService.markAsRead(notificationId);
    navigate(to);
  };
  return (
    <a href={to} onClick={handleClick}>
      {children}
    </a>
  );
};

const getNameForTaskNotification = notification => {
  const { id: notificationId, model_id: modelId, info } = notification;
  const taskName = info.task.name;
  const model = getAssociatedModel(notification);
  return (
    <>
      <Link notificationId={notificationId} to={`/tasks/${modelId}`}>
        {taskName}
      </Link>
      {model.modelId && (
        <>
          {" - "}
          <Link
            notificationId={notificationId}
            to={`/${model.modelPath}/${model.modelId}`}
          >
            {model.name}
          </Link>
        </>
      )}
    </>
  );
};

const getLinkForNotification = notification => {
  let {
    model_type: modelType,
    model_id: modelId,
    info,
    campaign,
    entity,
    contact,
    action,
  } = notification;
  const model = getAssociatedModel(notification);
  if (modelType === "ActivityLog") {
    return `/${model.modelPath}/${model.modelId}/notes`;
  }
  if (modelType === "CampaignTargetFormResponse") {
    return `/contacts/${info.response.contact_id}`;
  }
  if (modelType === "CampaignTargetEmail") {
    return `/campaigns/${campaign.id}/target/${entity.id}`;
  }
  if (modelType === "AuditLog" && action === "error") {
    return `/contacts/${contact.id}`;
  }
  if (modelType === "DataJob") {
    return `/data`;
  }
  const modelPaths = {
    Task: "tasks",
  };
  const modelPath = modelPaths[modelType];
  return "/" + modelPath + "/" + modelId;
};

const getDescriptionForNotification = notification => {
  const {
    action,
    model_type: modelType,
    model_id: modelId,
    info,
    content,
  } = notification;
  if (content) {
    return content;
  }
  const infoKeys = {
    ActivityLog: "note",
    Task: "task",
    CampaignTargetEmail: "template",
    CampaignTargetFormResponse: "response",
  };
  const getInfoValue = strChain => {
    const chain = strChain.split(".");
    const infoKey = infoKeys[modelType];
    let data = infoKey ? info[infoKey] : info;
    for (const key of chain) {
      if (!data) {
        break;
      }
      data = data[key];
    }
    return data;
  };
  const formatDescription = params => {
    if (!params) {
      return null;
    }
    const [template, ...args] = params;
    let description = template;
    for (let value of args) {
      let transformation = i => i;
      if (Array.isArray(value)) {
        transformation = value[1];
        value = value[0];
      }
      let repl = "";
      const infoValue = getInfoValue(value);
      if (infoValue) {
        repl = transformation(infoValue);
      }
      description = description.replace(
        /(\s*)\{\}(\s*)/,
        repl ? "$1" + repl + "$2" : ""
      );
    }
    return description;
  };
  const dModelType = getModelTypeForDisplay(modelType).toLowerCase();
  const descriptions = {
    error: ["Error {}", "error_message"],
    created: ["Created"],
    assigned: [`This ${dModelType} was assigned to you`],
    completed: [`This ${dModelType} was marked as completed`],
    near_due: [
      `This ${dModelType} is near its due date {}`,
      ["due_date", formatDate],
    ],
    past_due: [
      `This ${dModelType} is past its due date {}`,
      ["due_date", formatDate],
    ],
    delivered: [`This ${dModelType} was delivered with template {}`, "name"],
    response: [`A form has been submitted in campaign {}`, "campaign_name"],
    mention: [`{} mentioned you on a ${dModelType}`, "user.full_name"],
    import_job_finished_validation: [
      `The ${dModelType} has finished validation (ID: ${modelId})`,
    ],
    import_job_completed: [`The ${dModelType} has completed (ID: ${modelId})`],
    import_job_failed: [`The ${dModelType} has failed (ID: ${modelId})`],
  };

  const description = formatDescription(descriptions[action]);
  return description || "";
};

const NotificationsTable = ({
  paneName,
  filters,
  setFilters,
  modelType,
  isArchived,
  typeOptions,
  page,
  setPage,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [queryFilters, setQueryFilters] = useState({});
  const [notificationType, setNotificationType] = useState(null);

  useEffect(() => {
    setNotificationType(null);
  }, [paneName]);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original: notification } }) => {
        if (notification.model_type === "Task") {
          return getNameForTaskNotification(notification);
        }
        const link = getLinkForNotification(notification);
        return (
          <Link notificationId={notification.id} to={link}>
            {notification.name}
          </Link>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      disableSortBy: true,
      Cell: ({ row: { original: notification } }) =>
        getDescriptionForNotification(notification),
    },
    {
      Header: "Date",
      accessor: "created_at",
      Cell: ({ value }) => formatDate(value),
    },
  ];

  const queryMethod = NotificationService.getNotifications;

  useEffect(() => {
    let newFilters = {
      ...filters,
      model_type: modelType,
      is_archived: isArchived,
      action: notificationType,
    };
    newFilters = omitBy(newFilters, isNil);
    setQueryFilters(newFilters);
  }, [filters, modelType, isArchived, notificationType]);

  const handleSelectedRows = useCallback(
    rows => {
      setSelectedRows(rows);
    },
    [setSelectedRows]
  );

  const handleFetched = () => {
    setShouldRefetch(false);
  };

  const handleRefetch = () => {
    setShouldRefetch(true);
  };

  return (
    <div className="notifications-table">
      <header>
        <NotificationTypeSelector
          typeOptions={typeOptions}
          notificationType={notificationType}
          setNotificationType={setNotificationType}
        />
        <Actions
          selectedRows={selectedRows}
          handleRefetch={handleRefetch}
          queryFilters={queryFilters}
          isArchived={isArchived}
        />
      </header>
      <BaseTableV2
        columns={columns}
        queryMethod={queryMethod}
        queryFilters={queryFilters}
        onUpdateQueryFilters={setFilters}
        config={{
          enableHeader: false,
          enableFooter: true,
          enablePagination: true,
          pageSize: DEFAULT_PAGE_SIZE,
        }}
        onFetched={handleFetched}
        onSelectedRows={handleSelectedRows}
        shouldRefetch={shouldRefetch}
        page={page}
        setPage={setPage}
        rowProps={row => ({
          className: row.original.is_read ? "" : "unread-notif",
        })}
      />
    </div>
  );
};

export default NotificationsTable;

import React from "react";
import { Icon, Pagination as SemanticPagination } from "semantic-ui-react";

import "./Pagination.scoped.scss";

const Pagination = ({
  activePage,
  setActivePage,
  totalPages,
  loading,
  ...otherProps
}) => {
  return (
    <div className="paginationContainer">
      <div className="paginationContainerInner">
        <SemanticPagination
          activePage={activePage}
          totalPages={totalPages}
          disabled={loading}
          onPageChange={(_, { activePage }) => setActivePage(activePage)}
          {...otherProps}
        />
        {loading && <Icon loading name="circle notched" />}
      </div>
    </div>
  );
};

export default Pagination;

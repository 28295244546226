import React from "react";
import ConfirmationModal from "./ConfirmationModal";
import SourceStatusesService from "../../services/SourceStatus";

const DeleteSourceStatusModal = ({ sourceStatusId, fetchStatus }) => {
  const handleDelete = async () => {
    try {
      await SourceStatusesService.delete(sourceStatusId);
    } catch (e) {
      return e;
    }
    fetchStatus();
  };

  return (
    <ConfirmationModal
      actionDescription="Delete Source Status"
      buttonColor="grey"
      onConfirm={handleDelete}
      menuTrigger
      icon="trash"
      warning
    >
      <p>Are you sure you want to delete this Source Status?</p>
    </ConfirmationModal>
  );
};

export default DeleteSourceStatusModal;

import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import AddContactForm from "../forms/AddContact";

const AddContactModal = forwardRef(
  ({ onSuccess, model, entityId, campaignId, noTrigger, phoneNumber }, ref) => {
    const [modalOpen, setModalOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
    }));

    const handleOpen = () => {
      setModalOpen(true);
    };

    const handleClose = () => {
      setModalOpen(false);
    };

    const handleSuccess = async contact => {
      await onSuccess(contact);
      handleClose();
    };

    return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        closeOnDimmerClick={false}
        size="small"
        closeIcon
        trigger={
          !noTrigger && (
            <Button
              content="New Contact"
              onClick={handleOpen}
              size="tiny"
              className="item-adder"
            />
          )
        }
      >
        <Header content="New Contact" />
        <Modal.Content>
          <AddContactForm
            onSuccess={handleSuccess}
            entityId={entityId}
            campaignId={campaignId}
            model={model}
            phoneNumber={phoneNumber}
          />
        </Modal.Content>
      </Modal>
    );
  }
);

AddContactModal.propTypes = {
  onSuccess: PropTypes.func,
  model: PropTypes.string,
  entityId: PropTypes.string,
  campaignId: PropTypes.string,
  noTrigger: PropTypes.bool,
};

AddContactModal.defaultProps = {
  onSuccess: () => {},
  noTrigger: false,
};

export default AddContactModal;

import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useEffect, useState, useRef } from "react";
import Datetime from "react-datetime";
import PhoneInput from "react-phone-number-input";
import {
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Popup,
  Segment,
} from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import CONFIG from "../../../Config";
import { titleCase } from "../../../helpers/string";
import SettingsService from "../../../services/Settings";
import AttachmentService from "services/Attachment";

import "./GlobalSettings.scoped.scss";
import FileInput from "components/FileInput";

const GlobalSettings = () => {
  const [settings, setSettings] = useState({});
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const fileInputs = useRef({});

  const fetchSettings = useCallback(async () => {
    const settings = await SettingsService.getSettings(true);
    settings["call_center"] = {
      ...settings.call_center,
      call_window_start: {
        value: new Date(
          0,
          0,
          0,
          settings?.["call_center"]?.["call_window_start"]?.value || 9,
          0,
          0
        ),
      },
      call_window_end: {
        value: new Date(
          0,
          0,
          0,
          settings?.["call_center"]?.["call_window_end"]?.value || 17,
          0,
          0
        ),
      },
    };
    setSettings(settings);
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const handleChange = (_, data) => {
    let { name, value, type, min } = data;
    if (type === "number" && min === "0" && value) {
      value = String(Math.abs(value));
    }
    const [category, field] = name.split(".");
    const obj = settings?.[category]?.[field];
    if (obj && !("originalValue" in obj)) {
      obj.originalValue = obj.value;
    }
    const changed = obj?.originalValue !== value;
    const newSettings = {
      ...settings,
      [category]: {
        ...settings?.[category],
        [field]: {
          ...obj,
          value,
          changed,
        },
      },
    };
    setSettings(newSettings);
  };

  const processFiles = async updateSettings => {
    const updatedSettings = { ...updateSettings };
    for (const [key, file] of Object.entries(fileInputs.current)) {
      if (!file) {
        continue;
      }
      const previousValue = JSON.parse(
        updatedSettings[section]?.[name].value ?? "null"
      );
      if (previousValue?.file_name === file.name) {
        continue;
      }
      const attachment = await AttachmentService.uploadFile(file);
      const [section, name] = key.split("|");
      updatedSettings[section][name] = {
        ...updatedSettings[section][name],
        value: JSON.stringify(attachment),
        changed: true,
      };
    }
    return updatedSettings;
  };

  const handleSubmit = async () => {
    const missingFields = [];

    // Iterate over all sections and fields
    sections.forEach(section => {
      section.fields.forEach(field => {
        if (field.required) {
          if (field.type === "group") {
            // If it's a group, check all subfields
            field.fields.forEach(subField => {
              if (
                !settings[section.name] ||
                !settings[section.name][subField.name] ||
                settings[section.name][subField.name].value === ""
              ) {
                missingFields.push(subField.label || titleCase(subField.name));
              }
            });
          } else if (
            !settings[section.name] ||
            !settings[section.name][field.name] ||
            settings[section.name][field.name].value === ""
          ) {
            // If it's not a group, just check the field
            missingFields.push(field.label || titleCase(field.name));
          }
        }
      });
    });

    if (missingFields.length > 0) {
      // Set an error message with all missing field names
      setError(true);
      setErrorMessage(`Missing required fields: ${missingFields.join(", ")}`);
      return;
    } else {
      setError(false);
      setErrorMessage("");
    }

    let updateSettings = cloneDeep(settings);
    const getHour = dateTime => {
      // if dateTime is momemnt, use hour(), else use getHours()
      if (typeof dateTime.hour === "function") {
        return dateTime.hour();
      } else {
        return dateTime.getHours();
      }
    };
    updateSettings["call_center"]["call_window_start"]["value"] = getHour(
      updateSettings?.call_center?.call_window_start?.value
    ).toString();
    updateSettings["call_center"]["call_window_end"]["value"] = getHour(
      updateSettings?.call_center?.call_window_end?.value
    ).toString();

    let updatedSettings = await processFiles(updateSettings);
    updatedSettings = Object.entries(updatedSettings)
      .flatMap(([category, settingsObj]) => {
        return Object.entries(settingsObj).map(([name, setting]) => {
          return {
            ...setting,
            name,
            category,
          };
        });
      })
      .filter(({ changed }) => changed)
      .map(({ changed, originalValue, id, ...setting }) => {
        return { ...setting, value: setting.value || "" };
      });

    await SettingsService.updateSettings(updatedSettings);

    setSuccess(true);
    setMessage("Settings updated.");
  };

  const handleFileChange = (section, name, file) => {
    const key = `${section}|${name}`;
    fileInputs.current[key] = file;
  };

  const sections = [
    {
      name: "call_center",
      title: "Call Center",
      fields: [
        {
          type: "group",
          required: true,
          label: "Default Call Window",
          fields: [
            {
              label: "Call Window Start",
              type: "datetime",
              name: "call_window_start",
              required: CONFIG.IS_PHONE_ENABLED,
              viewMode: "time",
              closeOnSelect: true,
              dateFormat: false,
              input: false,
              timeFormat: "HH a",
              onChange: value => {
                if (value >= settings?.call_center?.call_window_end?.value) {
                  value = settings?.call_center?.call_window_start?.value;
                }
                handleChange(null, {
                  name: "call_center.call_window_start",
                  value,
                });
              },
            },
            {
              label: "Call Window End",
              type: "datetime",
              name: "call_window_end",
              required: CONFIG.IS_PHONE_ENABLED,
              viewMode: "time",
              closeOnSelect: true,
              dateFormat: false,
              input: false,
              timeFormat: "HH a",
              onChange: value => {
                if (value <= settings?.call_center?.call_window_start?.value) {
                  value = settings?.call_center?.call_window_end?.value;
                }
                handleChange(null, {
                  name: "call_center.call_window_end",
                  value,
                });
              },
            },
          ],
        },
        {
          type: "phone_number",
          name: "zendesk_inbound_number",
          label: (
            <label>
              Zendesk Inbound Number
              <Popup
                content="Number used to forward inbound calls. Ex. when a contact calls back the caller id number."
                position="top center"
                on="hover"
                trigger={
                  <Icon
                    style={{ verticalAlign: "super" }}
                    secondary
                    name="circle question"
                  />
                }
              ></Popup>
            </label>
          ),
        },
        {
          type: "file",
          name: "system_voicemail",
          label: "System Voicemail",
          inputProps: {
            accept: ".mp3",
          },
        },
      ],
    },
    {
      name: "piracy",
      title: "Piracy",
      fields: [
        {
          required: true,
          type: "number",
          min: "0",
          name: "time_limit",
          label: "Recent Event Time Limit",
          className: "piracy-time-limit",
          unit: "months",
        },
        {
          type: "group",
          className: "piracy-traffic-light",
          required: true,
          fields: [
            {
              label: "Nearby Event Distance Limits",
              required: true,
              type: "number",
              min: "0",
              name: "distance_limit_min",
              icon: "green circle",
            },
            {
              required: true,
              type: "number",
              min: "0",
              name: "distance_limit_mid",
              icon: "yellow circle",
            },
            {
              required: true,
              type: "number",
              min: "0",
              name: "distance_limit_max",
              icon: "red circle",
            },
          ],
        },
      ],
    },
  ];

  const renderField = (settings, sectionName, field) => {
    const value = settings?.[sectionName]?.[field.name]?.value;
    switch (field.type) {
      case "number":
        return (
          <>
            <Form.Input
              inline
              required={field.required}
              icon={field.icon}
              label={field.label}
              type="number"
              min={field.min}
              name={`${sectionName}.${field.name}`}
              value={value}
              onChange={handleChange}
            />
            {field.unit}
          </>
        );
      case "group":
        return field.fields.map(subField => (
          <>
            {renderField(settings, sectionName, subField)}
            {subField.unit}
          </>
        ));
      case "phone_number":
        return (
          <Form.Field inline>
            {field.label}
            <Input>
              <PhoneInput
                placeholder="+18885555555"
                name={`${sectionName}.${field.name}`}
                value={value}
                onChange={number => {
                  handleChange(undefined, {
                    name: `${sectionName}.${field.name}`,
                    value: number,
                  });
                }}
              />
            </Input>
          </Form.Field>
        );
      case "datetime":
        return (
          <Form.Field>
            <label>{field.label}</label>
            <Datetime
              name={field.name}
              viewMode={field.viewMode}
              value={value}
              closeOnSelect={field.closeOnSelect}
              dateFormat={field.dateFormat}
              input={field.input}
              timeFormat={field.timeFormat}
              onChange={field.onChange}
              inputProps={field.inputProps}
            />
          </Form.Field>
        );
      case "file":
        return (
          <Form.Field inline>
            <label>{field.label}</label>
            <FileInput
              name={field.name}
              value={value ? JSON.parse(value).file_name : undefined}
              onChange={file => handleFileChange(sectionName, field.name, file)}
              {...field.inputProps}
            />
          </Form.Field>
        );
      // Add other types of fields here...
      default:
        return null;
    }
  };

  return (
    <Container fluid className="route global-settings">
      <Grid className="bg list-grid" divided>
        <Grid.Row>
          <Grid.Column className="sixteen wide">
            <Header className="table-top-buttons global-settings">
              <Icon name="setting" />
              Global Settings
            </Header>
            <RuvixxForm
              submitButtonText="SAVE"
              onSubmit={handleSubmit}
              actionBarPadding={20}
              successMessage={message}
              success={success}
              errorMessage={errorMessage}
              error={error}
            >
              {sections.map(section => (
                <>
                  <Header dividing content={section.title} />
                  <Segment basic>
                    {section.fields.map(field => (
                      <Form.Group className={field.className}>
                        {renderField(
                          settings,
                          section.name,
                          field,
                          handleChange
                        )}
                      </Form.Group>
                    ))}
                  </Segment>
                </>
              ))}
            </RuvixxForm>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default GlobalSettings;

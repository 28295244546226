import React, { createContext, useState, useContext, useEffect } from "react";
import { USER_STATUSES } from "constants/Constants";

import store from "store";
import "./UserStatus.scoped.scss";
import { checkIsAuthorized } from "components/helpers";
import ACL_RELATIONSHIPS from "acl-relationships";
import userService from "services/User";
import { useSocket } from "providers/SocketProvider";

const UserStatusContext = createContext();

export const useUserStatus = () => useContext(UserStatusContext);

export const UserStatusProvider = ({ children }) => {
  const user = store.get("userAuth");
  const [status, setStatus] = useState(USER_STATUSES.DISCONNECTED.key);
  const [isDisabled, setIsDisabled] = useState(false);
  const { socket } = useSocket();
  const [canEditStatus, setCanEditStatus] = useState(false);

  useEffect(() => {
    syncStatus();

    if (socket) {
      socket.on("disconnect", () => {
        setStatus(USER_STATUSES.DISCONNECTED.key);
      });
      socket.on("connect", () => {
        syncStatus();
      });
      socket.on("status_update", () => {
        syncStatus();
      });
    }

    setCanEditStatus(
      checkIsAuthorized([ACL_RELATIONSHIPS.callAgentStatus.edit])
    );
  }, [socket]);

  useEffect(() => {
    setIsDisabled(
      !canEditStatus ||
        status == USER_STATUSES.DISCONNECTED.key ||
        status === USER_STATUSES.DISABLED.key ||
        status === USER_STATUSES.ON_CALL.key
    );
  }, [socket, canEditStatus, status]);

  const syncStatus = async () => {
    if (user) {
      const savedStatus = await userService.getUserStatus(user.id);
      setStatus(savedStatus);
    }
  };

  const updateStatus = async status => {
    await userService.updateUserStatus(user.id, { status });
    setStatus(status);
  };

  return (
    <UserStatusContext.Provider
      value={{
        isDisabled,
        status,
        updateStatus,
      }}
    >
      {children}
    </UserStatusContext.Provider>
  );
};

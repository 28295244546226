import { applyFilters, doRequest } from "./helpers";

export default class DashboardService {
  static async getTotalCallsBarChart(filters = null) {
    let path = "/dashboard/total_calls_bar_chart";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getTotalCallsSummary(filters = null) {
    let path = "/dashboard/total_calls_summary";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getTotalCallsSummaryAvgDials(filters = null) {
    let path = "/dashboard/total_calls_summary_avg_dials";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getTotalCallsSummaryPickups(filters = null) {
    let path = "/dashboard/total_calls_summary_pickups";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getTotalCallsSummaryLeads(filters = null) {
    let path = "/dashboard/total_calls_summary_leads";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getDispositionSummaryConnected(filters = null) {
    let path = "/dashboard/disposition_summary_connected";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getAgentTable(filters = null) {
    let path = "/dashboard/agent_metrics_table";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getCallsSummary(stat, filters = null) {
    let path = `/dashboard/calls_summary/${stat}`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getBreakdownByDisposition(filters = null) {
    let path = "/dashboard/breakdown_by_disposition";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getBreakdownByDialSession(filters = null) {
    let path = "/dashboard/breakdown_by_dial_session";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }
}

import { applyFilters, doRequest } from "./helpers";

const CaseDomainService = {
  getTable: filters => {
    let path = "/case_domain/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  },
  create: data => {
    return doRequest("POST", "/case_domain/", {
      data,
    });
  },
  edit: (caseDomainId, data) => {
    return doRequest("PUT", `/case_domain/${caseDomainId}`, {
      data,
    });
  },
  delete: caseDomainId => {
    return doRequest("DELETE", `/case_domain/${caseDomainId}`);
  },
};

export default CaseDomainService;

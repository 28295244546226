import { doRequest } from "./helpers";

const getEmailProviders = async (
  smtp = false,
  isSchedule = false,
  onlySmtp = false
) => {
  const disableSmtp = !smtp;
  let path = `/email_providers/`;
  let response;
  try {
    response = await doRequest("POST", path, {
      data: {
        is_schedule: isSchedule,
        disableSmtp: disableSmtp,
        only_smtp: onlySmtp,
      },
    });
  } catch (e) {
    return { error: true, message: e.message };
  }

  return response;
};

const getEmailAliasesForOutlook = async () => {
  let response;
  try {
    response = await doRequest("GET", "/outlook-aliases/");
  } catch (e) {
    return { error: true, message: e.message };
  }

  return response;
};

const EmailProviderService = {
  getEmailProviders,
  getEmailAliasesForOutlook,
};

export default EmailProviderService;

import React, { useEffect, useState } from "react";
import { Divider, Form, Segment, Message, List } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import RenderTemplateForm from "./RenderEmailTemplate";
import EmailTemplateService from "../../../services/EmailTemplate";
import { NoHTMLWarningForm } from "./WarningEmailTemplate";

function RenderEmailTemplateList({
  templateFormId,
  onSuccess,
  changeTab,
  name,
}) {
  const [providerList, setProviderList] = useState({
    mailgun_sendgrid: false,
    constant_contact: false,
    smtp: false,
    manual: false,
  });
  const [templateHtml, setTemplateHtml] = useState(null);
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [isMgSgVisible, setIsMgSgVisible] = useState(false);
  const [isCcVisible, setIsCcVisible] = useState(false);
  const [isOfficeVisible, setIsOfficeVisible] = useState(false);
  const [isManualVisible, setIsManualVisible] = useState(false);
  const [error, setError] = useState(undefined);

  const navigate = useNavigate();

  const updateProviderList = provider => {
    let pl = providerList;
    pl[provider] = !pl[provider];
    setProviderList(pl);
  };

  const fetchProviderList = async id => {
    let template = await EmailTemplateService.getEmailTemplate(id);
    if ("provider_list" in template.info) {
      let list = template.info.provider_list;
      setProviderList({
        ...providerList,
        mailgun_sendgrid: list["mailgun_sendgrid"],
        constant_contact: list["constant_contact"],
        smtp: list["smtp"],
        manual: list["manual"],
      });
    }
  };

  const setVisibility = () => {
    if (providerList["mailgun_sendgrid"]) {
      setIsMgSgVisible(true);
    }
    if (providerList["constant_contact"]) {
      setIsCcVisible(true);
    }
    if (providerList.smtp) {
      setIsOfficeVisible(true);
    }
    if (providerList["manual"]) {
      setIsManualVisible(true);
    }
  };

  const handleSubmit = async () => {
    const template = await EmailTemplateService.getEmailTemplate(
      templateFormId
    );
    const info = template.info;
    info["provider_list"] = providerList;
    try {
      await EmailTemplateService.editEmailTemplate({
        id: templateFormId,
        info,
      });
      navigate("/settings/email_templates");
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      setError([error.message]);
    }
  };

  useEffect(() => {
    (async () => {
      if (templateFormId) {
        const template = await EmailTemplateService.getEmailTemplate(
          templateFormId
        );
        if (template.html) {
          setTemplateHtml(template.html);
        }
        setTemplateName(template.name);
        setTemplateSubject(template.subject);
        fetchProviderList(templateFormId);
      }
    })();
  }, [templateFormId]);

  useEffect(() => {
    setVisibility();
  }, [providerList]);

  return (
    <>
      <h2>{name}</h2>
      <Divider />
      {!templateFormId ? (
        <NoHTMLWarningForm changeTab={changeTab} />
      ) : (
        <>
          <Segment>
            <h2 style={{ display: "inline-block" }}>SendGrid / MailGun</h2>
            <RenderTemplateForm
              templateFormId={templateFormId}
              providerId={"mailgun_sendgrid"}
              updateProviderList={updateProviderList}
              previewHtml={templateHtml}
              isVisible={isMgSgVisible}
            />
          </Segment>
          <Segment>
            <h2 style={{ display: "inline-block" }}>Constant Contact</h2>
            <RenderTemplateForm
              templateFormId={templateFormId}
              providerId={"constant_contact"}
              updateProviderList={updateProviderList}
              templateSubject={templateSubject}
              templateName={templateName}
              isVisible={isCcVisible}
            />
          </Segment>
          <Segment>
            <h2 style={{ display: "inline-block" }}>SMTP</h2>
            <RenderTemplateForm
              templateFormId={templateFormId}
              providerId={"smtp"}
              updateProviderList={updateProviderList}
              previewHtml={templateHtml}
              isVisible={isOfficeVisible}
            />
          </Segment>
          <Segment>
            <h2 style={{ display: "inline-block" }}>Manual Send</h2>
            <RenderTemplateForm
              templateFormId={templateFormId}
              providerId={"manual"}
              updateProviderList={updateProviderList}
              previewHtml={templateHtml}
              isVisible={isManualVisible}
            />
          </Segment>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Form.Button
              primary
              size="tiny"
              type="button"
              onClick={handleSubmit}
              content="SUBMIT"
            />
          </div>
          {error ? (
            <Message negative>
              <Message.Header>Error</Message.Header>
              <List bulleted>
                {error.map((error, index) => (
                  <List.Item>{error}</List.Item>
                ))}
              </List>
            </Message>
          ) : null}
        </>
      )}
    </>
  );
}

export default RenderEmailTemplateList;

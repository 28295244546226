import React from "react";
import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import "react-table-hoc-fixed-columns/lib/styles.css";
import { Container, Dropdown, Icon, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import BaseTable from "../../../components/BaseTable";
import CustomDropdown from "../../../components/CustomDropdown";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import FormModal from "../../../components/modals/FormModal";
import setPageTitle from "../../../helpers/title";
import AuthService from "../../../services/Auth";
import ConstantContactAccountsService from "../../../services/ConstantContactAccounts";
import _AccountAuthenticationModal from "./AccountAuthenticationModal";
import AccountForm from "./AccountForm";

const CreateAccountModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.constantContactAccounts.create,
]);
const EditAccountModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.constantContactAccounts.edit,
]);
const DeleteAccountModal = withRoleCheck(ConfirmationModal, [
  ACL_RELATIONSHIPS.constantContactAccounts.delete,
]);

const AccountAuthenticationModal = withRoleCheck(_AccountAuthenticationModal, [
  ACL_RELATIONSHIPS.constantContactAccounts.auth,
]);

class AccountsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = ConstantContactAccountsService.getTable;

    this.state = {
      ...this.state,
      className: "Account",
      tableName: "campaignActivities",
      noDataText: "No Campaign Activities found. Try adjusting your filters.",
      createButton: (
        <CreateAccountModal
          onSuccess={this.fetchData}
          modelType={"Account"}
          FormComponent={AccountForm}
        />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Constant Contact Accounts");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  deleteAccount = async accountId => {
    await ConstantContactAccountsService.delete(accountId);
    this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        headerClassName: "centered",
        className: "centered",
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        headerClassName: "centered",
        className: "centered",
        Header: "Email Address",
        accessor: "email_address",
      },
      {
        Header: "Active",
        accessor: "active",
        headerClassName: "centered",
        className: "centered",
        Cell: ({ value }) => <Icon name={value ? "check" : "close"} />,
      },
      {
        Header: "Authentication Status",
        accessor: "authentication_status",
        headerClassName: "centered",
        className: "centered",
        Cell: ({ value }) => <p>{value.toUpperCase()}</p>,
      },
      {
        Header: "Actions",
        id: "actions",
        width: 100,
        headerClassName: "centered non-sortable",
        className: "action-menu",
        sortable: false,
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <AccountAuthenticationModal
                onClose={this.fetchData}
                account={props.original}
              />
              <EditAccountModal
                dropdown
                onSuccess={this.fetchData}
                modelType={"Account"}
                model={props.original}
                FormComponent={AccountForm}
              />
              <DeleteAccountModal
                actionDescription="Delete Account"
                buttonColor="grey"
                onConfirm={() => this.deleteAccount(props.original.id)}
                menuTrigger
                icon="trash"
                warning
              >
                <p>Are you sure you want to delete this Account?</p>
              </DeleteAccountModal>
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default AccountsTable;

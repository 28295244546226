import React, { useEffect, useState, useCallback } from "react";
import { Form, Table, Icon } from "semantic-ui-react";
import ContactService from "../../../services/Contact";
import differenceBy from "lodash/differenceBy";

const DepartmentContacts = ({ entityId, contacts, setContacts }) => {
  const [options, setOptions] = useState([]);
  const [contact, setContact] = useState(null);

  const fetchOptions = useCallback(async () => {
    let options = await ContactService.getContactsForFilters({
      entity_ids: [entityId],
    });
    options = differenceBy(options, contacts, "id"); // remove already linked contacts
    options = options.map(mapOption);
    setOptions(options);
  }, []);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const handleContactSelect = (_, { value }) => {
    if (!value) {
      return;
    }
    const { text } = options.find(option => option.value === value);
    setContact({ id: value, name: text });
  };

  const addContact = () => {
    const newContacts = arrayWithAddedItem(contacts, contact);
    const newOptions = arrayWithRemovedItem(options, contact);
    setContacts(newContacts);
    setOptions(newOptions);
    setContact(null);
  };

  const removeContact = contact => {
    const newContacts = arrayWithRemovedItem(contacts, contact);
    const newOptions = arrayWithAddedItem(options, contact, mapOption);
    setContacts(newContacts);
    setOptions(newOptions);
  };

  const arrayWithAddedItem = (array, item, mapper) => {
    if (mapper) {
      item = mapper(item);
    }
    return [...array, item];
  };

  const arrayWithRemovedItem = (array, item) => {
    const index = array.findIndex(elem => (elem.id || elem.key) == item.id);
    return [...array.slice(0, index), ...array.slice(index + 1)];
  };

  const mapOption = ({ id, name }) => ({
    key: id,
    value: id,
    text: name,
  });

  return (
    <>
      <Form.Group>
        <Form.Select
          search
          inline
          options={options}
          label="Add Contact"
          value={contact ? contact.id : null}
          onChange={handleContactSelect}
          placeholder="Search for a Contact"
        />
        <Form.Button
          disabled={!contact}
          icon="plus"
          size="tiny"
          basic
          title="Add Contact to Department"
          style={{ marginLeft: "-1em" }}
          onClick={addContact}
        />
      </Form.Group>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Contacts</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {contacts.length > 0 ? (
            contacts.map(contact => (
              <Table.Row>
                <Table.Cell>{contact.name}</Table.Cell>
                <Table.Cell>
                  <Icon
                    link
                    name="trash alternate outline"
                    onClick={() => removeContact(contact)}
                    title="Remove Contact from Department"
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell>
                <em>No Contacts linked to this Department</em>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default DepartmentContacts;

import React, { Component } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import countryOptions from "../../components/Countries";
import RuvixxForm from "../../components/RuvixxForm";

export default class NewRegionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      name: "",
      countries: [],
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSubmit = async () => {
    const name = this.state.name;
    const countries = this.state.countries.join(",");

    await this.props.handleSubmit(name, countries); // maybe-promise
    this.clearForm();
    this.handleClose();
  };

  cancel = () => {
    this.handleClose();
  };

  clearForm = () => {
    this.setState({
      name: "",
      countries: [],
    });
  };

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  render() {
    const { name, countries } = this.state;
    return (
      <Modal
        size="small"
        trigger={
          <Button
            size="tiny"
            className="item-adder"
            content="New Region"
            onClick={this.handleOpen}
          />
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>New Region</Modal.Header>
        <Modal.Content>
          <RuvixxForm
            ready={!!name && countries.length !== 0}
            onSubmit={this.handleSubmit}
            onCancel={this.cancel}
          >
            <Form.Input
              inline
              name="name"
              label="Name"
              required
              onChange={this.handleChange}
            />
            <Form.Select
              inline
              search
              multiple
              clearable
              name="countries"
              label="Countries"
              placeholder="Select Country"
              onChange={this.handleChange}
              options={countryOptions}
              value={this.state.countries}
            />
          </RuvixxForm>
        </Modal.Content>
      </Modal>
    );
  }
}

import React, {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Tab, List } from "semantic-ui-react";
import LogItem from "../../../components/LogItem";
import useQueryParam from "../../../hooks/params/useQueryParam";
import AuditService from "../../../services/Audit";
import Pagination from "./Pagination";

const ActivityTab = forwardRef(({ id }, ref) => {
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useQueryParam("page", 1);
  const [log, setLog] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  useImperativeHandle(ref, () => ({
    refresh: fetchLog,
  }));

  const fetchLog = useCallback(async () => {
    if (!id) {
      return;
    }
    setLoading(true);
    const { data, pages } = await AuditService.getAuditLogs({
      modelId: id,
      modelType: "Task",
      page: activePage,
    });
    setTotalPages(pages);
    setLog(data);
    setLoading(false);
  }, [id, activePage]);

  useEffect(() => {
    fetchLog();
  }, [fetchLog]);

  return (
    <Tab.Pane loading={loading}>
      <List>
        <List.Item>
          {log.map((item, index) => (
            <LogItem key={index} item={item} />
          ))}
        </List.Item>
        <List.Item>
          <Pagination
            activePage={activePage}
            setActivePage={setActivePage}
            totalPages={totalPages}
          />
        </List.Item>
      </List>
    </Tab.Pane>
  );
});

export default ActivityTab;

import React from "react";
import { List } from "semantic-ui-react";
import { extractCustom, isUrl } from "./helpers";

const CustomItems = ({ items, useHeaders = false, noEmptyLabel = false }) => {
  let custom = extractCustom(items);
  custom = custom.filter(elem => elem["key"] !== "description");
  if (custom.length === 0) {
    return noEmptyLabel ? null : "None";
  }
  return custom.map((field, index) => (
    <List.Item key={index} style={{ wordWrap: "break-word" }}>
      {useHeaders ? <List.Header>{field.key}:</List.Header> : `${field.key}:`}
      {isUrl(field.value) ? (
        <a href={field.value} target="_blank" rel="noopener noreferrer">
          {field.value}
        </a>
      ) : Array.isArray(field.value) ? (
        field.value.join(", ")
      ) : (
        field.value
      )}
    </List.Item>
  ));
};

export default CustomItems;

import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { applyFilters, doRequest } from "./helpers";

export default class LeadService {
  static async getForFilters(filters = null) {
    let path = "/lead/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async table(filters = null) {
    let path = `/lead/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async tableForCase(caseId, filters = null) {
    filters = filters || {};
    filters["case_id"] = caseId;
    return await LeadService.table(filters);
  }

  static async get(id) {
    return doRequest("GET", `/lead/${id}`);
  }

  static async create(data) {
    return doRequest("POST", `/lead/`, {
      data: omitBy(data, isNil),
    });
  }

  static async update(leadId, data) {
    return doRequest("PUT", `/lead/${leadId}`, {
      data: omitBy(data, isNil),
    });
  }

  static async delete(id) {
    return doRequest("DELETE", `/lead/${id}`);
  }

  static async approve(data) {
    return doRequest("PUT", "/lead/approve", {
      data: data,
    });
  }

  static async reject(data) {
    return doRequest("PUT", "/lead/reject", {
      data: data,
    });
  }

  static async addToCase(data) {
    return doRequest("PUT", "/lead/add_to_case", {
      data: data,
    });
  }
}

import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Dropdown,
  Header,
  Segment,
  Checkbox,
} from "semantic-ui-react";
import ConfirmationModal from "./ConfirmationModal";
import { sortByKey } from "../helpers";
import RuvixxForm from "../RuvixxForm";
import CampaignService from "../../services/Campaign";
import ContactService from "../../services/Contact";
import Link from "../../components/Link";

import BaseTableV2 from "../../components/BaseTableV2/BaseTableV2";
import "../../components/BaseTableV2/BaseTableV2.scoped.scss";

import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import CONSTANTS from "constants/Constants";

const AddToCampaignModal = ({
  entityId,
  contactId,
  campaignsAdded,
  onSuccess,
}) => {
  const [campaignId, setCampaignId] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [inCampaign, setInCampaign] = useState({});
  const [parentEntityId, setParentEntityId] = useState(null);
  const [error, setError] = useState(null);
  const [contactIds, setContactIds] = useState([]);
  const [includeAll, setIncludeAll] = useState(false);

  const fetchCampaigns = async () => {
    let filters = null;
    if (contactId) {
      const parentEntityId = (
        await ContactService.getContacts({
          id: contactId,
        })
      ).data[0].entity_id;
      filters = { entity_id: parentEntityId };
      setParentEntityId(parentEntityId);
    }
    const added = (await campaignsAdded()).map(
      ({ campaign_id }) => campaign_id
    );
    const rest = (await CampaignService.getCampaignsForFilters(filters)).filter(
      ({ id }) => !added.includes(id)
    );
    const sortedCampaigns = sortByKey(rest, "name");
    setCampaigns(sortedCampaigns);
  };

  const handleInclude = (contactId, checked) => {
    let _contactIds = contactIds;
    _contactIds = _contactIds.filter(c => c != contactId);
    if (checked) {
      _contactIds.push(contactId);
    } else if (!checked && includeAll) {
      _contactIds = _contactIds.filter(c => c != contactId && c != -contactId);
      _contactIds.push(-contactId);
    }

    setContactIds(_contactIds);
  };

  const handleIncludeAll = () => {
    setContactIds([]);
    setIncludeAll(!includeAll);
  };

  const handleSelect = (_, { value }) => {
    setCampaignId(value);
  };

  const handleSubmit = async () => {
    const campaignStatus = campaigns.find(
      campaign => campaign.id === campaignId
    ).status;
    if (campaignStatus !== CONSTANTS.CAMPAIGN_STATUS_TYPES.ACTIVE) {
      setError(
        `This campaign is ${
          campaignStatus === CONSTANTS.CAMPAIGN_STATUS_TYPES.INACTIVE
            ? "inactive"
            : "archived"
        }`
      );
      return;
    }
    setError(null);

    if (entityId) {
      await CampaignService.createCampaignTarget(
        campaignId,
        parseInt(entityId)
      );
      await CampaignService.addContacts(
        campaignId,
        entityId,
        contactIds,
        includeAll
      );
    } else {
      await CampaignService.addContacts(campaignId, parentEntityId, [
        parseInt(contactId),
      ]);
    }
    handleClose();
  };

  const handleOpen = () => {
    setModalOpen(true);
    setError(null);
    fetchCampaigns();
  };

  const handleClose = () => {
    setCampaignId(null);
    setModalOpen(false);
  };

  return (
    <Modal
      size="large"
      trigger={
        <Button size="tiny" className="item-adder">
          Add to Campaign
        </Button>
      }
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>Add to Campaign</Modal.Header>
      <Modal.Content className="noPadding">
        <RuvixxForm
          ready={!!campaignId}
          onSubmit={handleSubmit}
          onSuccess={onSuccess}
          actionBarPadding={"0 2.7rem 1.5rem"}
          error={!!error}
          errorMessage={error}
        >
          <Segment basic className="pad">
            <Form.Select
              inline
              label="Campaign"
              search
              options={campaigns.map(({ id, name }) => ({
                key: id,
                text: name,
                value: id,
              }))}
              onChange={handleSelect}
            />
          </Segment>
          {!!entityId && (
            <>
              <Header size="small" dividing content="Include Contacts" />
              <Segment basic className="pad">
                <IncludeContactsTable
                  entityId={entityId}
                  inCampaign={inCampaign}
                  setInCampaign={setInCampaign}
                  onInclude={handleInclude}
                  onIncludeAll={handleIncludeAll}
                  contactIds={contactIds}
                  includeAll={includeAll}
                />
              </Segment>
            </>
          )}
        </RuvixxForm>
      </Modal.Content>
    </Modal>
  );
};

const IncludeContactsTable = ({
  entityId,
  campaignId,
  inCampaign,
  setInCampaign,
  onInclude,
  onIncludeAll,
  includeAll,
  contactIds,
}) => {
  const queryMethod = ContactService.getContacts;
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [queryFilters, setQueryFilters] = useState({
    entity_id: entityId,
    ignore_cache: true,
  });

  const handleUpdateQueryFilters = updatedFilters => {
    setQueryFilters(updatedFilters);
  };
  const handleRefetch = () => {
    setShouldRefetch(true);
  };

  const handleFetched = (a, apiRespone) => {
    setShouldRefetch(false);
  };

  useEffect(() => {}, [contactIds, includeAll]);
  const handleInclude = async (contactId, checked) => {
    if (!!onInclude) {
      onInclude(contactId, checked);
      handleRefetch();
      return;
    }

    await CampaignService[checked ? "addContact" : "removeContact"](
      campaignId,
      entityId,
      contactId
    );
    handleRefetch();
  };

  const handleIncludeAll = async () => {
    if (!!onIncludeAll) {
      return onIncludeAll();
    }
    await CampaignService.addContacts(campaignId, entityId, [], true);
    handleRefetch();
  };

  const queryKey = `entity_${entityId}_contacts`;
  const columns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: props => (
        <Link to={`/contacts/${props.row.original.id}`}>{props.value}</Link>
      ),
    },
    { Header: "Email", accessor: "email" },
    {
      Header: "Include in campaign",
      accessor: "campaign_targets",
      className: "toggle column",
      Cell: props => (
        <Checkbox
          toggle
          name={`${props.row.original.id}in_campaign_${campaignId}`}
          checked={(() => {
            return (
              (includeAll && !contactIds.includes(-props.row.original.id)) ||
              (contactIds || []).includes(props.row.original.id) ||
              props.value.some(ct => ct.campaign_id == campaignId)
            );
          })()}
          onClick={(_, { checked }) =>
            handleInclude(props.row.original.id, checked)
          }
        />
      ),
    },
  ];
  return (
    <>
      <BaseTableV2
        columns={columns}
        queryMethod={queryMethod}
        queryFilters={queryFilters}
        onFetched={() => {}}
        queryKey={queryKey}
        shouldRefetch={shouldRefetch}
        onFetched={handleFetched}
        config={{
          enableHeader: false,
          enableFooter: true,
          enablePagination: true,
          pageSize: DEFAULT_PAGE_SIZE,
        }}
        onUpdateQueryFilters={handleUpdateQueryFilters}
      />
      <div style={{ textAlign: "center", marginTop: "1em" }}>
        <Button
          primary
          size="tiny"
          content={
            includeAll
              ? "Deselect all contacts"
              : "Include All Contacts to Campaign"
          }
          onClick={handleIncludeAll}
        />
      </div>
    </>
  );
};

const IncludeContactsModal = ({ entityId, campaignId, onSuccess }) => {
  const [inCampaign, setInCampaign] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      size="large"
      trigger={<Dropdown.Item>Include Contacts</Dropdown.Item>}
      closeIcon
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Include Contacts</Modal.Header>
      <Modal.Content>
        <IncludeContactsTable
          entityId={entityId}
          campaignId={campaignId}
          inCampaign={inCampaign}
          setInCampaign={setInCampaign}
        />
      </Modal.Content>
    </Modal>
  );
};

const RemoveCampaignModal = ({
  menuTrigger,
  campaignName,
  modelType,
  onConfirmDelete,
}) => (
  <ConfirmationModal
    actionDescription="Remove Campaign"
    buttonColor="red"
    onConfirm={onConfirmDelete}
    menuTrigger={menuTrigger}
    warning
  >
    <p>
      Are you sure you want to remove this {modelType} from{" "}
      <strong>{campaignName}</strong>?
    </p>
  </ConfirmationModal>
);

export { AddToCampaignModal, RemoveCampaignModal, IncludeContactsModal };

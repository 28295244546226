import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import FormResponseService from "../../services/FormResponse";
import withRoleCheck from "../hocs/withRoleCheck";
import ACL_RELATIONSHIPS from "../../acl-relationships";

const DownloadAttachments = withRoleCheck(
  ({ ids }) => {
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
      setLoading(true);
      try {
        await FormResponseService.downloadAttachments(ids);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    return (
      <div className="download-attachments-container">
        <Button
          primary
          size="tiny"
          content="Download All Files"
          onClick={handleClick}
          loading={loading}
          disabled={loading}
        />
      </div>
    );
  },
  [ACL_RELATIONSHIPS.formResponseAttachments.read]
);

export default DownloadAttachments;

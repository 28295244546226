import React from "react";
import { Modal, Button, Icon } from "semantic-ui-react";

export default function PreventClosingModal({ open, setModal }) {
  return (
    <Modal size="tiny" open={open} closeOnDimmerClick={false}>
      <Modal.Header>Dial Session</Modal.Header>
      <Modal.Content>
        <p>You haven't selected a Call Disposition</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" onClick={() => setModal(false)}>
          <Icon name="checkmark" /> Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

import React, { useState } from "react";
import RuvixxForm from "../../../components/RuvixxForm";
import { Form } from "semantic-ui-react";
import RevenueOpportunityStatusSelector from "./RevenueOpportunityStatusSelector";

import RevenueOpportunityService from "../../../services/RevenueOpportunities";
import RevenueOpportunityStatusPresetService from "../../../services/RevenueOpportunityStatusPreset";

const RevenueOpportunityStatusPresetForm = ({ preset, onSuccess }) => {
  const [name, setName] = useState("");
  const [revenueOpportunityStatuses, setRevenueOpportunityStatuses] = useState(
    []
  );
  const [allRevenueOpportunityStatuses, setAllRevenueOpportunityStatuses] =
    useState([]);

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(revenueOpportunityStatuses);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRevenueOpportunityStatuses(items);
  };

  const fetchAllRevenueOpportunityStatuses = async () => {
    const statuses =
      await RevenueOpportunityService.getRevenueOpportunityStatusesForFilters();
    setAllRevenueOpportunityStatuses(statuses);
  };

  useState(() => {
    fetchAllRevenueOpportunityStatuses();
  }, [fetchAllRevenueOpportunityStatuses]);

  useState(() => {
    if (preset) {
      setName(preset.name);
      setRevenueOpportunityStatuses(preset.info.revenue_opportunity_statuses);
    }
  }, [preset]);

  const handleSubmit = async () => {
    if (preset) {
      await RevenueOpportunityStatusPresetService.editRevenueOpportunityStatusPreset(
        preset.id,
        name,
        revenueOpportunityStatuses
      );
    } else {
      await RevenueOpportunityStatusPresetService.createRevenueOpportunityStatusPreset(
        name,
        revenueOpportunityStatuses
      );
    }
  };

  return (
    <RuvixxForm
      ready={!!name && revenueOpportunityStatuses.length > 0}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      <Form.Input
        label="Name"
        inline
        required
        value={name}
        onChange={(_, { value }) => setName(value)}
      />
      <RevenueOpportunityStatusSelector
        revenueOpportunityStatuses={revenueOpportunityStatuses}
        setRevenueOpportunityStatuses={setRevenueOpportunityStatuses}
        allRevenueOpportunityStatuses={allRevenueOpportunityStatuses}
        handleDragEnd={handleDragEnd}
      />
    </RuvixxForm>
  );
};

export default RevenueOpportunityStatusPresetForm;

import React, { useState, useEffect } from "react";
import { Button, Modal, Popup } from "semantic-ui-react";
import ScheduleViewContactsTable from "../ScheduleViewContactsTable";

function EmailableContactsModal({ disabled, campaignId, data }) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = async () => {
    setModalOpen(true);
  };

  const handleClose = async () => {
    setModalOpen(false);
  };

  return (
    <Modal
      trigger={
        <Popup
          disabled={!disabled}
          content={
            "Please select a template and fill out at least one filter condition"
          }
          trigger={
            <span>
              <Button
                type="button"
                size="tiny"
                content="View Contacts"
                disabled={disabled}
                onClick={handleOpen}
              />
            </span>
          }
        />
      }
      size="large"
      open={modalOpen}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header>Email Schedule Contacts</Modal.Header>
      <Modal.Content>
        <ScheduleViewContactsTable campaignId={campaignId} data={data} />
      </Modal.Content>
    </Modal>
  );
}

export default EmailableContactsModal;

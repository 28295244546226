import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Confirm } from "semantic-ui-react";
import isFunction from "lodash/isFunction";
import isObject from "lodash/isObject";

const ConfirmModal = forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [args, setArgs] = useState([]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = (...args) => {
    setModalOpen(true);
    setArgs(args);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    const onConfirm = getArg("onConfirm");
    await onConfirm(...args);
    setLoading(false);
    handleClose();
  };

  const getArg = key => {
    if (key in props) {
      return props[key];
    }
    return (args => (args ? args[key] : undefined))(...args);
  };

  const renderTitle = () => {
    const title = getArg("title");
    return isFunction(title) ? title(...args) : title;
  };

  const renderContent = () => {
    const content = getArg("content");
    return isFunction(content) ? content(...args) : content;
  };

  const renderConfirmButton = () => {
    let confirmButton = getArg("confirmButton");
    confirmButton = isFunction(confirmButton)
      ? confirmButton(...args)
      : confirmButton;
    const buttonProps = isObject(confirmButton)
      ? confirmButton
      : { content: confirmButton };
    return (
      <Button
        primary={!buttonProps.color}
        {...buttonProps}
        disabled={loading || buttonProps.disabled}
        loading={loading}
      />
    );
  };

  if (!modalOpen) {
    return;
  }

  return (
    <Confirm
      className="confirm"
      open={modalOpen}
      onCancel={handleClose}
      onConfirm={handleConfirm}
      cancelButton={<Button content="Cancel" disabled={loading} />}
      confirmButton={renderConfirmButton()}
      header={renderTitle()}
      content={<div className="content">{renderContent()}</div>}
      closeOnDimmerClick={!loading}
    />
  );
});

export default ConfirmModal;

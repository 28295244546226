import React, { Component } from "react";
import { Popup, Icon } from "semantic-ui-react";
import CONFIG from "../Config";

const timeoutLength = 1000;

class CopyCCEmail extends Component {
  state = { isOpen: false };

  handleOpen = () => {
    this.setState({ isOpen: true });

    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false });
    }, timeoutLength);
  };

  handleClose = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
    this.setState({ isOpen: false });
    clearTimeout(this.timeout);
  };

  copyCCEmailToClipboard = e => {
    const textField = document.createElement("textarea");
    textField.innerText = CONFIG.CC_EMAIL;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  wrapWithPopup(el) {
    return (
      <Popup
        content="Copied!"
        on="click"
        open={this.state.isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        position="top left"
        offset={this.props.offset}
        trigger={el}
      />
    );
  }

  render() {
    const el = (
      <div
        className={this.props.className}
        style={{ cursor: "pointer" }}
        onClick={this.copyCCEmailToClipboard}
      >
        <Icon name="copy" />
        {this.props.textContent || CONFIG.CC_EMAIL}
      </div>
    );

    // When used in the header dropdown, the dropdown closes before the popup
    // can be attached, causing the popup to appear in the top left corner of the
    // window. So nopopup option just prevents using the popup at all.
    return this.props.nopopup ? el : this.wrapWithPopup(el);
  }
}

export default CopyCCEmail;

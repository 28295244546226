import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { Form, Header, Message, Modal, Segment } from "semantic-ui-react";
import CampaignService from "../../../services/Campaign";
import ConstantContactCampaignsService from "../../../services/ConstantContactCampaigns";
import ContactService from "../../../services/Contact";
import EmailTemplateService from "../../../services/EmailTemplate";
import EmailFooterService from "../../../services/EmailFooters";

function RenderEmailTemplatePreviewModal({
  templateId,
  footerId,
  providerId,
  templateSubject,
  templateName,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [footer, setFooter] = useState(null);
  const [combinedTemplate, setCombinedTemplate] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const [contacts, setContacts] = useState([]);
  const [contactId, setContactId] = useState(null);
  const [ccCombinedTemplate, setCcCombinedTemplate] = useState(null);
  const [search, setSearch] = useState(null);
  const [error, setError] = useState("");

  const decodeHTML = html => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setCampaignId(null);
    setContactId(null);
    setCombinedTemplate("");
    setModalOpen(false);
  };

  const fetchFooter = async footerId => {
    let footerHtml = await EmailFooterService.getEmailFooter(footerId);
    setFooter(decodeHTML(footerHtml.html));
  };

  const combineTemplate = (template, footer) => {
    let before = template.indexOf("</body>");
    let combined = template;
    if (footer) {
      combined =
        template.substring(0, before) + footer + template.substring(before);
    }
    setCombinedTemplate(combined);
    if (providerId === "constant_contact") {
      ccPreviewRender(combined);
    }
  };

  const mapForSelect = array =>
    array.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));

  const contactMapForSelect = array =>
    array.map(({ id, full_name }) => ({
      key: id,
      text: full_name,
      value: id,
    }));

  const handleChange = (_, { value }) => {
    setCampaignId(value);
    let obj = campaigns.find(o => o.key === value);
    setCampaignName(obj.text);
  };

  const handleContactChange = (_, { value }) => {
    setContactId(value);
  };

  const handleSearchChange = (e, { searchQuery }) => {
    setSearch(searchQuery);
  };

  const handleRender = async () => {
    const filters = {
      campaign_id: campaignId,
      contact_id: contactId,
    };
    const rt = await EmailTemplateService.getRenderedTemplate(
      templateId,
      filters
    );
    combineTemplate(rt, footer);
  };

  const ccPreviewRender = async html => {
    let data = {
      campaign_name: campaignName,
      email_template_subject: templateSubject,
      email_template_name: templateName,
      email_template_html: html,
    };
    let cc =
      await ConstantContactCampaignsService.createPreviewCampaignActivity(data);
    if (cc.error) {
      setError(cc);
    } else {
      setCcCombinedTemplate(cc.preview_data.preview_html_content);
    }
  };

  useEffect(() => {
    (async () => {
      const filters = {
        per_page: 100,
        page: 1,
        sort_dir: "asc",
        sort_by: "full_name",
      };
      if (campaignId) {
        filters["campaign_id"] = campaignId;
      }
      if (search) {
        filters["search"] = search;
      }
      const contacts = await ContactService.getContacts(filters);
      setContacts(contactMapForSelect(contacts.data));
    })();
  }, [campaignId, search]);

  useEffect(() => {
    (async () => {
      const campaigns = await CampaignService.getCampaignsForFilters();
      setCampaigns(mapForSelect(campaigns));
    })();
  }, []);

  useEffect(() => {
    if (footerId != null) {
      fetchFooter(footerId);
    }
  }, [footerId]);

  const createMarkup = html => {
    return { __html: html };
  };

  return (
    <>
      <Form.Button content="Preview" onClick={handleOpen} size="tiny" />
      <Modal
        open={modalOpen}
        onClose={handleClose}
        size="large"
        closeIcon
        closeOnDimmerClick={false}
      >
        <Header content="Preview" />
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Select
                required
                inline
                search
                label="Campaign"
                name="campaignId"
                options={campaigns}
                onChange={handleChange}
              />
              <Form.Select
                inline
                search
                disabled={!campaignId}
                onSearchChange={debounce(handleSearchChange, 300)}
                label="Contact"
                name="contactId"
                options={contacts}
                onChange={handleContactChange}
              />
              <Form.Button
                disabled={!campaignId && !contactId}
                content="Render"
                type="button"
                onClick={handleRender}
              />
            </Form.Group>
          </Form>
          {error ? (
            <Message negative>
              <Message.Header>{error.error_message}</Message.Header>
            </Message>
          ) : null}
          {ccCombinedTemplate ? (
            <>
              <Segment
                dangerouslySetInnerHTML={createMarkup(ccCombinedTemplate)}
              />
            </>
          ) : (
            <Segment dangerouslySetInnerHTML={createMarkup(combinedTemplate)} />
          )}
        </Modal.Content>
      </Modal>
    </>
  );
}

export default RenderEmailTemplatePreviewModal;

import React, { Component } from "react";
import { Icon, Popup, Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";

export default class TableButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    edit: PropTypes.bool,
    delete: PropTypes.bool,
    description: PropTypes.string,
    icon: PropTypes.string,
    dropdown: PropTypes.string,
  };

  getDescription = () => {
    let description = null;
    if (this.props.description !== undefined) {
      description = this.props.description;
    } else if (this.props.edit) {
      description = "Edit";
    } else if (this.props.delete) {
      description = "Delete";
    }
    return description;
  };

  renderPopup = () => (
    <Popup
      position={`top ${this.props.delete ? "right" : "left"}`}
      content={this.getDescription()}
      trigger={this.renderButton()}
    />
  );

  renderButton = () => {
    if (this.props.edit) {
      return this.renderIcon("pencil");
    }
    if (this.props.delete) {
      return this.renderDeleteButton();
    }
    if (this.props.icon) {
      return this.renderIcon(this.props.icon);
    }
    return null;
  };

  renderIcon = name => (
    <Icon
      name={name}
      link
      onClick={this.props.onClick}
      style={{ padding: "0 0.9em", ...this.props.style }}
    />
  );

  renderDeleteButton = () => (
    <Icon.Group
      className="link"
      onClick={this.props.onClick}
      style={{
        verticalAlign: "3px",
        fontSize: "11px",
        padding: "0 0.9em",
        ...this.props.style,
      }}
    >
      <Icon name="circle outline" size="large" />
      <Icon name="close" />
    </Icon.Group>
  );

  render = () => {
    return this.getDescription() ? this.renderPopup() : this.renderButton();
  };
}

import React from "react";
import { List, Container, Dropdown, Segment, Icon } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "acl-relationships";

import BaseTable from "components/BaseTable";
import CustomDropdown from "components/CustomDropdown";
import withRoleCheck from "components/hocs/withRoleCheck";
import ConfirmationModal from "components/modals/ConfirmationModal";
import FormModal from "components/modals/FormModal";
import setPageTitle from "helpers/title";
import AuthService from "services/Auth";
import RecipientService from "services/Recipient";
import "styles/campaign.scss";
import "styles/table.scss";
import { upperFirst } from "helpers/string";
import RecipientForm from "./RecipientForm";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

const CreateRecipientModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.recipient.create,
]);
const EditRecipientModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.recipient.edit,
]);
const DeleteRecipientModal = withRoleCheck(ConfirmationModal, [
  ACL_RELATIONSHIPS.recipient.delete,
]);

class RecipientsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = RecipientService.getTable;

    this.state = {
      ...this.state,
      enableSearch: false,
      header: "Recipients",
      headerIcon: "shop",
      className: "Recipients",
      tableName: "Recipients",
      noDataText: "No Recipients found. Create one.",
      createButton: (
        <CreateRecipientModal
          onSuccess={this.fetchData}
          modelType={"Recipient"}
          FormComponent={RecipientForm}
        />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Recipients");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  deleteRecipient = async id => {
    await RecipientService.delete(id);
    this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "types",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return (
              <List horizontal bulleted>
                {value.sort().map(type => (
                  <List.Item>{upperFirst(type)}</List.Item>
                ))}
              </List>
            );
          }
          return null;
        },
      },
      {
        Header: "Enabled",
        accessor: "enabled",
        Cell: ({ value }) => <Icon name={value ? "check" : "close"} />,
      },
      {
        Header: "Actions",
        id: "actions",
        width: 60,
        className: "action-menu",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditRecipientModal
                dropdown
                onSuccess={this.fetchData}
                modelType={"Recipient"}
                model={props.original}
                FormComponent={RecipientForm}
              />
              <DeleteRecipientModal
                actionDescription="Delete Recipient"
                buttonColor="grey"
                onConfirm={() => this.deleteRecipient(props.original.id)}
                menuTrigger
                icon="trash"
                warning
              >
                <p>Are you sure you want to delete this Recipient?</p>
              </DeleteRecipientModal>
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default RecipientsTable;

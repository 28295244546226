import React, { useCallback, useEffect, useState } from "react";
import Datetime from "react-datetime";
import {
  Checkbox,
  Divider,
  Form,
  Header,
  Icon,
  Input,
} from "semantic-ui-react";
import CustomFieldDropdown from "../../components/CustomFieldDropdown";
import CustomFieldGroupDropdown from "../../components/CustomFieldGroupDropdown";
import { mapCustomFieldValues } from "../../helpers/customFields";
import "../../styles/datetime.scss";
import CustomFieldGroupsService from "../../services/CustomFieldGroups";

export function CustomFieldFormField({
  customFieldConfig,
  customFieldConfigs,
  onUpdateCustomFieldConfigs,
}) {
  const handleUpdateCustomFieldConfig = (key, value) => {
    const newCustomFieldConfigs = customFieldConfigs.map(cfc => {
      cfc.value = cfc.label === key ? value : cfc.value;
      return cfc;
    });
    onUpdateCustomFieldConfigs(newCustomFieldConfigs);
  };

  const handleChange = (event, data) => {
    const key = data.name;

    let newValue = data.value;
    if (data.type === "checkbox") {
      newValue = data.checked;
    }
    handleUpdateCustomFieldConfig(key, newValue);
  };

  const handleDateChange = (value, name) => {
    handleUpdateCustomFieldConfig(name, value);
  };

  const handleRemoveCustomFieldConfig = cf => {
    const newCustomFieldConfigs = customFieldConfigs.map(cfc => ({
      ...cfc,
      selected: cfc.id === cf.id ? false : cfc.selected,
    }));
    onUpdateCustomFieldConfigs(newCustomFieldConfigs);
  };

  const makeFormElement = cf => {
    cf.value = cf.value || "";
    switch (cf.field_type) {
      case "text":
        return (
          <Form.Input
            inline
            name={cf.label}
            label={cf.label}
            required={cf.required}
            onChange={handleChange}
            value={cf.value}
          />
        );
      case "numeric":
        return (
          <Form.Input
            inline
            type="number"
            min="0"
            name={cf.label}
            label={cf.label}
            required={cf.required}
            value={cf.value}
            onChange={handleChange}
          />
        );
      case "boolean":
        return (
          <Form.Field inline required={cf.required}>
            <label>{cf.label}</label>
            <Checkbox
              toggle
              name={cf.label}
              checked={cf.value === "false" ? false : cf.value}
              onChange={handleChange}
            />
          </Form.Field>
        );
      case "select":
        let multiSelect = cf.field_options.type === "multi";
        return (
          <Form.Select
            inline
            clearable
            search
            multiple={multiSelect}
            name={cf.label}
            label={cf.label}
            required={cf.required}
            options={cf.field_options["options"]}
            value={
              multiSelect
                ? cf.value || []
                : Array.isArray(cf.value)
                ? cf.value[0]
                : cf.value
            }
            onChange={handleChange}
          />
        );
      case "date":
        return (
          <Form.Field inline required={cf.required} className="dateTime">
            <label>{cf.label}</label>
            <Datetime
              closeOnSelect={true}
              name={cf.label}
              value={cf.value}
              onChange={value => {
                handleDateChange(value, cf.label);
              }}
              renderInput={props => (
                <Input icon="large calendar outline" {...props} />
              )}
              dateFormat={"YYYY-MM-DD"}
              timeFormat={false}
              className="above"
            />
          </Form.Field>
        );
      case "list":
        return (
          <Form.Select
            inline
            clearable
            search
            multiple
            name={cf.label}
            label={cf.label}
            required={cf.required}
            options={cf.value.map(val => ({ key: val, value: val, text: val }))}
            value={cf.value}
            onChange={handleChange}
            allowAdditions
          />
        );
      default:
        return null;
    }
  };

  return (
    <Form.Group>
      {makeFormElement(customFieldConfig)}
      {!customFieldConfig.required && (
        <Form.Field style={{ marginLeft: "-1em" }}>
          <Icon
            link
            name="cancel"
            title="Remove Custom Field"
            onClick={() => handleRemoveCustomFieldConfig(customFieldConfig)}
          />
        </Form.Field>
      )}
    </Form.Group>
  );
}

export default function CustomFieldForm({
  modelType,
  customFields,
  customFieldConfigs,
  onUpdateCustomFieldConfigs,
}) {
  const [mappedCustomFieldConfigs, setMappedCustomFieldConfigs] = useState([]);
  const [customFieldGroups, setCustomFieldGroups] = useState([]);

  const fetchCustomFieldGroups = useCallback(async () => {
    let fetchedCustomFieldGroups =
      await CustomFieldGroupsService.getCustomFieldGroupsForModelType(
        modelType
      );
    setCustomFieldGroups(fetchedCustomFieldGroups);
  }, [modelType]);

  useEffect(() => {
    if (modelType) {
      fetchCustomFieldGroups();
    }
  }, [modelType]);

  useEffect(() => {
    setMappedCustomFieldConfigs([
      ...mapCustomFieldValues(customFields, customFieldConfigs),
    ]);
  }, [customFields, customFieldConfigs]);

  return mappedCustomFieldConfigs.length > 0 ? (
    <>
      <Divider hidden />
      <Header className="section-header">Custom Fields</Header>
      <Form.Group>
        <Form.Field>
          <CustomFieldDropdown
            customFieldConfigs={mappedCustomFieldConfigs}
            onUpdateCustomFieldConfigs={onUpdateCustomFieldConfigs}
          />
        </Form.Field>
        {customFieldGroups.length > 0 && (
          <Form.Field>
            <CustomFieldGroupDropdown
              customFieldGroups={customFieldGroups}
              customFieldConfigs={customFieldConfigs}
              onUpdateCustomFieldConfigs={onUpdateCustomFieldConfigs}
            />
          </Form.Field>
        )}
      </Form.Group>
      {mappedCustomFieldConfigs
        .filter(cfc => cfc.required || cfc.selected)
        .map(customFieldConfig => (
          <Form.Field key={customFieldConfig.label}>
            <CustomFieldFormField
              customFieldConfig={customFieldConfig}
              customFieldConfigs={mappedCustomFieldConfigs}
              onUpdateCustomFieldConfigs={onUpdateCustomFieldConfigs}
            />
          </Form.Field>
        ))}
    </>
  ) : null;
}

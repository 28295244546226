import React from "react";
import filter from "lodash/filter";
import { checkIsAuthorized } from "../helpers";
import { Container, Segment, Message } from "semantic-ui-react";
import { storeForbiddenError } from "../../services/helpers";

const withRoleCheck = (
  WrappedComponent,
  permissions, // [{method: "get", path: "campaigns"}]
  { showMsg = false, msg = "Access Denied", partialMatch = false } = {}
) => {
  return class RoleCheck extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isAuthorized: false,
      };
    }

    componentDidMount() {
      const { isAuthorized, grantedInfo } = checkIsAuthorized(permissions, {
        partialMatch,
        shouldReturnGrantedInfo: true,
      });
      const notGrantedPermissions = filter(grantedInfo, { granted: false });
      if (!isAuthorized && showMsg) {
        for (const { method, path } of notGrantedPermissions) {
          storeForbiddenError(`${method.toUpperCase()} - ${path}`);
        }
      }
      this.setState({ isAuthorized: isAuthorized });
    }
    render = () => (
      <>
        {this.state.isAuthorized ? (
          <WrappedComponent {...this.props} />
        ) : (
          showMsg && (
            <Container>
              <Segment>
                <Message error>{msg}</Message>
              </Segment>
            </Container>
          )
        )}
      </>
    );
  };
};

export default withRoleCheck;

import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import store from "store";
import useInterval from "./../hooks/useInterval";

const ErrorModal = props => {
  const [error, setError] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  useInterval(() => {
    const storedError = store.get("error");
    if (!storedError) return;
    if (storedError.message === error.message) {
      return;
    }
    setError(storedError);
    setModalOpen(true);
  }, 1000);

  const handleClose = () => {
    setError({});
    store.remove("error");
    setModalOpen(false);
  };

  return (
    <>
      {props.children}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>{error.header || "Error"}</Modal.Header>
        <Modal.Content>
          <p>{error.message}</p>
          {error.items && (
            <ul>
              {error.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ErrorModal;

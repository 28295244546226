import React, { forwardRef } from "react";
import { Form, Input as FormInput } from "semantic-ui-react";

const Input = forwardRef(
  ({ visible = true, label, name, onSubmit = () => {}, ...props }, ref) =>
    visible && (
      <Form.Field>
        <label htmlFor={name}>{label}</label>
        <FormInput
          ref={ref}
          id={name}
          name={name}
          onKeyDown={({ keyCode }) => keyCode === 13 && onSubmit()}
          {...props}
        />
      </Form.Field>
    )
);

export default Input;

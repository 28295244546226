import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import store from "store";

import ACL_RELATIONSHIPS from "../acl-relationships";
import withRoleCheck from "../components/hocs/withRoleCheck";
import CONFIG from "../Config";
import { useAdminStatus } from "../helpers/user";
import NotFoundItem from "./404/NotFoundItem";
import _AgentDashboardTable from "./agent_dashboard/AgentDashboardTable";
import _CallsTable from "../components/CallsTable";
import _CampaignDetail from "./campaign/CampaignDetail";
import _CampaignsTable from "./campaign/CampaignsTable";
import CampaignTarget from "./campaign/CampaignTarget";
import _CaseDetail from "./case/CaseDetail";
import _CasesTable from "./case/CasesTable";
import _CustomFieldGroupsTable from "./custom_field_group/CustomFieldGroupsTable";
import _Contact from "./contact/Contact";
import _ContactsTable from "./contact/ContactsTable";
import _CallDashboard from "./dashboard/CallDashboard";
import _DataJobTable from "./data_tab/DataJobTable";
import _DialSessionsTable from "./dial_sessions/components/DialSessionsTable";
import _PowerDialer from "./dial_sessions/PowerDialer";
import _PowerDialerMonitor from "./dial_sessions/PowerDialerMonitor";
import _EmailFooter from "./email/EmailFooter";
import _EmailSignature from "./email/EmailSignature";
import _EmailTemplate from "./email/EmailTemplate";
import _ContractsTable from "./entity/components/ContractsTable";
import _EntitiesTable from "./entity/EntitiesTable";
import _Entity from "./entity/Entity";

import RequireAuth from "./higher-order/RequireAuthHOC";
import _Inbox from "./inbox/Inbox";
import Login from "./login/Login";
import ResetPasswordConfirm from "./login/ResetConfirmForm";
import _SequenceDetail from "./sequences/SequenceDetail";
import _Sequences from "./sequences/Sequences";
import _SmtpAccountsTable from "./smtp_accounts/SmtpAccountsTable";
import _TaskDetails from "./tasks/TaskDetails";
import _TaskOverview from "./tasks/TaskOverview";
import _UserSettings from "./user/User";
import _Settings from "./settings/Settings";
import _RevenueOpportunitiesTable from "components/RevenueOpportunityTable";
import AuthService from "../services/Auth";
import RevenueOpportunity from "./revenue_opportunity/RevenueOpportunity";

const CampaignsTable = withRoleCheck(
  _CampaignsTable,
  [
    ACL_RELATIONSHIPS.campaign.read,
    ACL_RELATIONSHIPS.campaigns.read,
    ACL_RELATIONSHIPS.region.read,
  ],
  { showMsg: true }
);

const CampaignDetail = withRoleCheck(
  _CampaignDetail,
  [
    ACL_RELATIONSHIPS.campaign.read,
    ACL_RELATIONSHIPS.campaigns.read,
    ACL_RELATIONSHIPS.region.read,
    ACL_RELATIONSHIPS.tag.read,
    ACL_RELATIONSHIPS.campaignActivity.read,
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.emailTemplates.read,
    ACL_RELATIONSHIPS.campaignTarget.read,
  ],
  { showMsg: true }
);

const CasesTable = withRoleCheck(_CasesTable, [ACL_RELATIONSHIPS.case.read], {
  showMsg: true,
});

const CaseDetail = withRoleCheck(_CaseDetail, [ACL_RELATIONSHIPS.case.read], {
  showMsg: true,
});

const EmailTemplate = withRoleCheck(
  _EmailTemplate,
  [ACL_RELATIONSHIPS.emailTemplates.read],
  { showMsg: true }
);

const EmailFooter = withRoleCheck(
  _EmailFooter,
  [ACL_RELATIONSHIPS.emailFooters.read],
  { showMsg: true }
);

const EmailSignature = withRoleCheck(
  _EmailSignature,
  [ACL_RELATIONSHIPS.emailSignatures.read],
  { showMsg: true }
);

const EntitiesTable = withRoleCheck(
  _EntitiesTable,
  [
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.tag.read,
    ACL_RELATIONSHIPS.entityCustomFields.read,
    ACL_RELATIONSHIPS.entity.read,
  ],
  { showMsg: true }
);

const Entity = withRoleCheck(
  _Entity,
  [
    ACL_RELATIONSHIPS.entity.read,
    ACL_RELATIONSHIPS.tag.read,
    ACL_RELATIONSHIPS.entityCampaigns.read,
    ACL_RELATIONSHIPS.campaigns.read,
    ACL_RELATIONSHIPS.auditLog.read,
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.twilioToken.read,
    ACL_RELATIONSHIPS.disposition.read,
  ],
  { showMsg: true }
);
const UserSettings = withRoleCheck(
  _UserSettings,
  [ACL_RELATIONSHIPS.userSettings.read],
  { showMsg: true }
);
const Settings = withRoleCheck(
  _Settings,
  [
    ACL_RELATIONSHIPS.adminUser.read,
    ACL_RELATIONSHIPS.adminRole.read,
    ACL_RELATIONSHIPS.adminGroup.create,
    ACL_RELATIONSHIPS.disposition.read,
    ACL_RELATIONSHIPS.voicemail.read,
    ACL_RELATIONSHIPS.campaignStatusPreset.readAsTable,
    ACL_RELATIONSHIPS.region.read,
    ACL_RELATIONSHIPS.revenueOpportunityStatus.read,
    ACL_RELATIONSHIPS.constantContactAccounts.read,
    ACL_RELATIONSHIPS.constantContactCampaigns.read,
    ACL_RELATIONSHIPS.customFieldGroup.read,
    ACL_RELATIONSHIPS.customFieldConfig.readTable,
    ACL_RELATIONSHIPS.product.read,
    ACL_RELATIONSHIPS.tag.readTable,
    ACL_RELATIONSHIPS.tagCategory.readTable,
  ],
  { showMsg: true, partialMatch: true }
);
const ContactsTable = withRoleCheck(
  _ContactsTable,
  [
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.tag.read,
    ACL_RELATIONSHIPS.contactCustomFields.read,
    ACL_RELATIONSHIPS.contact.read,
  ],
  { showMsg: true }
);
const ContractsTable = withRoleCheck(
  _ContractsTable,
  [
    ACL_RELATIONSHIPS.contract.readTable,
    ACL_RELATIONSHIPS.contractCustomField.read,
  ],
  { showMsg: true }
);
const Contact = withRoleCheck(
  _Contact,
  [
    ACL_RELATIONSHIPS.contact.read,
    ACL_RELATIONSHIPS.contactCampaigns.read,
    ACL_RELATIONSHIPS.tag.read,
    ACL_RELATIONSHIPS.entity.read,
    ACL_RELATIONSHIPS.campaigns.read,
    ACL_RELATIONSHIPS.auditLog.read,
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.twilioToken.read,
    ACL_RELATIONSHIPS.disposition.read,
  ],
  { showMsg: true }
);
const DialSessions = withRoleCheck(
  _DialSessionsTable,
  [ACL_RELATIONSHIPS.dialSession.read],
  { showMsg: true }
);

const CallsTable = withRoleCheck(
  _CallsTable,
  [ACL_RELATIONSHIPS.campaignTargetCall.readTable],
  {
    showMsg: true,
  }
);

const PowerDialer = withRoleCheck(
  _PowerDialer,
  [
    ACL_RELATIONSHIPS.dialSession.read,
    ACL_RELATIONSHIPS.callQueueNextContact.read,
    ACL_RELATIONSHIPS.callQueueAddContact.create,
    ACL_RELATIONSHIPS.disposition.read,
    ACL_RELATIONSHIPS.emailUrlForms.read,
    ACL_RELATIONSHIPS.twilioToken.read,
    ACL_RELATIONSHIPS.tag.read,
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.campaignStatuses.read,
    ACL_RELATIONSHIPS.twilioDisposition.create,
    ACL_RELATIONSHIPS.twilioAddToCall.create,
    ACL_RELATIONSHIPS.twilioRemoveFromCall.create,
    ACL_RELATIONSHIPS.twilioUpdateParticipant.create,
  ],
  { showMsg: true }
);

const PowerDialerMonitor = withRoleCheck(
  _PowerDialerMonitor,
  [
    ACL_RELATIONSHIPS.dialSession.read,
    ACL_RELATIONSHIPS.callQueueNextContact.read,
    ACL_RELATIONSHIPS.callQueueAddContact.create,
    ACL_RELATIONSHIPS.disposition.read,
    ACL_RELATIONSHIPS.emailUrlForms.read,
    ACL_RELATIONSHIPS.twilioToken.read,
    ACL_RELATIONSHIPS.tag.read,
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.campaignStatuses.read,
    ACL_RELATIONSHIPS.callQueueItemsWithAgentAssigned.read,
    ACL_RELATIONSHIPS.twilioDisposition.create,
    ACL_RELATIONSHIPS.twilioAddToCall.create,
    ACL_RELATIONSHIPS.twilioRemoveFromCall.create,
    ACL_RELATIONSHIPS.twilioUpdateParticipant.create,
  ],
  { showMsg: true }
);

const AgentDashboard = withRoleCheck(
  _AgentDashboardTable,
  [
    ACL_RELATIONSHIPS.dialSession.read,
    ACL_RELATIONSHIPS.callQueueNextContact.read,
    ACL_RELATIONSHIPS.disposition.read,
    ACL_RELATIONSHIPS.emailUrlForms.read,
    ACL_RELATIONSHIPS.twilioToken.read,
    ACL_RELATIONSHIPS.tag.read,
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.campaignStatuses.read,
    ACL_RELATIONSHIPS.callQueueItemsWithAgentAssigned.read,
    ACL_RELATIONSHIPS.twilioDisposition.create,
    ACL_RELATIONSHIPS.twilioAddToCall.create,
    ACL_RELATIONSHIPS.twilioRemoveFromCall.create,
    ACL_RELATIONSHIPS.twilioUpdateParticipant.create,
  ],
  { showMsg: true }
);

const CallDashboard = withRoleCheck(
  _CallDashboard,
  [
    ACL_RELATIONSHIPS.campaigns.read,
    ACL_RELATIONSHIPS.dialSession.read,
    ACL_RELATIONSHIPS.disposition.read,
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.campaignTargetCall.read,
  ],
  { showMsg: true }
);

const SmtpAccountsTable = withRoleCheck(
  _SmtpAccountsTable,
  [
    ACL_RELATIONSHIPS.emailConfigSmtp.read,
    ACL_RELATIONSHIPS.emailConfigSmtpTable.read,
  ],
  { showMsg: true }
);

const Sequences = withRoleCheck(_Sequences, [ACL_RELATIONSHIPS.sequence.read], {
  showMsg: true,
});
const SequenceDetail = withRoleCheck(
  _SequenceDetail,
  [
    ACL_RELATIONSHIPS.sequence.read,
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.auditLog.events,
  ],
  {
    showMsg: true,
  }
);

const DataJobTable = withRoleCheck(
  _DataJobTable,
  [ACL_RELATIONSHIPS.dataJob.read],
  { showMsg: true }
);

const TaskOverview = withRoleCheck(
  _TaskOverview,
  [ACL_RELATIONSHIPS.task.read],
  { showMsg: true }
);

const TaskDetails = withRoleCheck(
  _TaskDetails,
  [
    ACL_RELATIONSHIPS.task.read,
    ACL_RELATIONSHIPS.auditLog.read,
    ACL_RELATIONSHIPS.taskActivity.read,
    ACL_RELATIONSHIPS.user.read,
  ],
  {
    showMsg: true,
  }
);

const Inbox = withRoleCheck(_Inbox, [ACL_RELATIONSHIPS.notifications.read], {
  showMsg: true,
});

const RevenueOpportunitiesTable = withRoleCheck(
  _RevenueOpportunitiesTable,
  [ACL_RELATIONSHIPS.revenueOpportunity.readTable],
  { showMsg: true }
);

const Router = () => {
  useAdminStatus();
  const user = store.get("userAuth");
  const externalUser = user?.external;
  if (externalUser) {
    AuthService.logout();
  }
  const defaultPath = externalUser ? "/login" : "/";
  const DefaultComponent = externalUser ? Login : CampaignsTable;

  const routes = (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <DefaultComponent />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/404" element={<NotFoundItem />} />
      <Route path="/reset_password" element={<ResetPasswordConfirm />} />
      <Route
        path="/calls"
        element={
          <RequireAuth>
            <CallsTable topLevel />
          </RequireAuth>
        }
      />
      <Route
        path="/campaigns"
        element={
          <RequireAuth>
            <CampaignsTable />
          </RequireAuth>
        }
      />
      <Route
        path="/campaigns/:id/:tab"
        element={
          <RequireAuth>
            <CampaignDetail />
          </RequireAuth>
        }
      />
      <Route
        path="/campaigns/:id"
        element={
          <RequireAuth>
            <CampaignDetail />
          </RequireAuth>
        }
      />
      <Route
        path="/campaigns/:id/target/:entity_id/:tab"
        element={
          <RequireAuth>
            <CampaignTarget />
          </RequireAuth>
        }
      />
      <Route
        path="/campaigns/:id/target/:entity_id"
        element={
          <RequireAuth>
            <CampaignTarget />
          </RequireAuth>
        }
      />
      <Route
        path="/settings/email_templates/:id"
        element={
          <RequireAuth>
            <EmailTemplate />
          </RequireAuth>
        }
      />
      <Route
        path="/settings/email_footers/:id"
        element={
          <RequireAuth>
            <EmailFooter />
          </RequireAuth>
        }
      />
      <Route
        path="/settings/email_signatures/:id"
        element={
          <RequireAuth>
            <EmailSignature />
          </RequireAuth>
        }
      />
      <Route
        path="/entities"
        element={
          <RequireAuth>
            <EntitiesTable />
          </RequireAuth>
        }
      />
      <Route
        path="/entities/:id/:tab"
        element={
          <RequireAuth>
            <Entity />
          </RequireAuth>
        }
      />
      <Route
        path="/entities/:id"
        element={
          <RequireAuth>
            <Entity />
          </RequireAuth>
        }
      />
      <Route
        path="/revenue-opportunities/:id/:tab"
        element={
          <RequireAuth>
            <RevenueOpportunity />
          </RequireAuth>
        }
      />
      <Route
        path="/revenue-opportunities/:id"
        element={
          <RequireAuth>
            <RevenueOpportunity />
          </RequireAuth>
        }
      />
      <Route
        path="/contacts"
        element={
          <RequireAuth>
            <ContactsTable />
          </RequireAuth>
        }
      />
      <Route
        path="/contacts/:id/:tab"
        element={
          <RequireAuth>
            <Contact />
          </RequireAuth>
        }
      />
      <Route
        path="/contacts/:id"
        element={
          <RequireAuth>
            <Contact />
          </RequireAuth>
        }
      />
      <Route
        path="/user/settings"
        element={
          <RequireAuth>
            <UserSettings />
          </RequireAuth>
        }
      />
      <Route
        path="/settings"
        element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        }
      />
      <Route
        path="/settings/:item"
        element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        }
      />
      <Route
        path="/call_dashboard"
        element={
          <RequireAuth>
            <CallDashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/smtp_accounts"
        element={
          <RequireAuth>
            <SmtpAccountsTable />
          </RequireAuth>
        }
      />
      <Route
        path="/sequences"
        element={
          <RequireAuth>
            <Sequences />
          </RequireAuth>
        }
      />
      <Route
        path="/sequences/:id"
        element={
          <RequireAuth>
            <SequenceDetail />
          </RequireAuth>
        }
      />
      {CONFIG.IS_PHONE_ENABLED ? (
        <Route
          path="/dial-sessions"
          element={
            <RequireAuth>
              <DialSessions />
            </RequireAuth>
          }
        />
      ) : null}
      <Route
        path="/dialer/:id"
        element={
          <RequireAuth>
            <PowerDialer />
          </RequireAuth>
        }
      />
      <Route
        path="/agent-dashboard"
        element={
          <RequireAuth>
            <AgentDashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/data"
        element={
          <RequireAuth>
            <DataJobTable />
          </RequireAuth>
        }
      />
      <Route
        path="/dialer-monitor/:campaignId/:callId"
        element={
          <RequireAuth>
            <PowerDialerMonitor />
          </RequireAuth>
        }
      />
      <Route
        path="/tasks"
        element={
          <RequireAuth>
            <TaskOverview />
          </RequireAuth>
        }
      />
      <Route
        path="/tasks/:id/:tab"
        element={
          <RequireAuth>
            <TaskDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/tasks/:id"
        element={
          <RequireAuth>
            <TaskDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/inbox/:tab"
        element={
          <RequireAuth>
            <Inbox />
          </RequireAuth>
        }
      />
      <Route
        path="/inbox"
        element={
          <RequireAuth>
            <Inbox />
          </RequireAuth>
        }
      />
      <Route
        path="/cases"
        element={
          <RequireAuth>
            <CasesTable />
          </RequireAuth>
        }
      />
      <Route
        path="/cases/:id/:tab"
        element={
          <RequireAuth>
            <CaseDetail />
          </RequireAuth>
        }
      />
      <Route
        path="/cases/:id"
        element={
          <RequireAuth>
            <CaseDetail />
          </RequireAuth>
        }
      />
      <Route
        path="/revenue-opportunities"
        element={
          <RequireAuth>
            <RevenueOpportunitiesTable />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to={defaultPath} replace />} />
    </Routes>
  );
  if (Router.routeArray === null) {
    Router.routeArray = routes.props.children
      .filter(route => !!route)
      .map(route => ({
        path: route.props.path,
      }));
  }
  return routes;
};

Router.routeArray = null;

export { Router };

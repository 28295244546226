import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";

import "./ActionsForm.scoped.scss";

const EMPTY_ACTIONS = { shows: [], hides: [] };

const ActionsForm = ({
  questions,
  questionIndex,
  optionIndex,
  option,
  onSuccess,
  updateOptionAtIndex,
}) => {
  const [questionsOptions, setQuestionsOptions] = useState([]);
  const [actions, setActions] = useState(EMPTY_ACTIONS);

  useEffect(() => {
    const questionsOptions = questions
      .map((q, index) => ({ ...q, globalOrder: index + 1 }))
      .filter((_, index) => index !== questionIndex)
      .map(({ ref, title, globalOrder }) => {
        return {
          key: ref,
          text: `Question ${globalOrder}`,
          value: ref,
          description: title,
        };
      });
    setQuestionsOptions(questionsOptions);
  }, [questions]);

  useEffect(() => {
    setActions(option?.actions || EMPTY_ACTIONS);
  }, [option]);

  const handleChange = data => {
    const { name, value } = data;
    const newActions = {
      ...actions,
      [name]: value,
    };
    setActions(newActions);
  };

  const handleSubmit = () => {
    const newOption = {
      ...option,
      actions: {
        ...option?.actions,
        ...actions,
      },
    };
    updateOptionAtIndex(optionIndex, newOption);
  };

  return (
    <RuvixxForm
      ready
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      submitButtonText="SAVE"
      className="actions-form"
    >
      <Form.Select
        label="Shows"
        placeholder="Select questions"
        clearable
        multiple
        name="shows"
        value={actions.shows}
        options={questionsOptions.filter(
          ({ value }) => !actions.hides.includes(value)
        )}
        onChange={(_, data) => handleChange(data)}
      />
      <Form.Select
        label="Hides"
        placeholder="Select questions"
        clearable
        multiple
        name="hides"
        value={actions.hides}
        options={questionsOptions.filter(
          ({ value }) => !actions.shows.includes(value)
        )}
        onChange={(_, data) => handleChange(data)}
      />
    </RuvixxForm>
  );
};

export default ActionsForm;

import React, { Component } from "react";
import AttachmentService from "../services/Attachment";
import _DeleteFileModal from "./modals/DeleteFileModal";
import { formatDate, CustomLoader } from "./helpers";
import ReactTable from "react-table-6";
import withDraggableColumns from "react-table-hoc-draggable-columns";
import _ from "lodash";
import debounce from "lodash/debounce";
import TableButton from "./TableButton";
import withRoleCheck from "./hocs/withRoleCheck";
import ACL_RELATIONSHIPS from "../acl-relationships";
import UserService from "../services/User";
import store from "store";

const DeleteFileModal = withRoleCheck(_DeleteFileModal, [
  ACL_RELATIONSHIPS.attachment.delete,
]);

const ReactTableDraggableColumns = withDraggableColumns(ReactTable);

class FileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableName: "file_list",
      columns: [],
      resizedColumns: {},
    };
    this.oldResized = [];
  }

  componentDidMount() {
    this.setColumns();
  }

  extractAttachments = () => {
    const { logs, files } = this.props;
    let attachments = [];
    if (logs) {
      attachments = logs.flatMap(({ attachments }) => attachments);
    } else if (files) {
      attachments = files;
    }
    return attachments;
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  deleteFile = async id => {
    const { fetchFiles, fetchActivityLogs, onDelete } = this.props;
    await AttachmentService.delete(id);
    fetchFiles && fetchFiles();
    fetchActivityLogs && fetchActivityLogs(); // don't await, it must occur after delete modal closes
    onDelete && onDelete();
  };

  initTableSettings = columns => {
    const { resizedColumns } = this.getTableSettings(columns);
    this.setState({ columns }, () => {
      this.resizeColumns(resizedColumns, { updateBackend: false });
    });
  };

  getTableSettings = columns => {
    const settings = store.get("userAuth").table_settings;
    const tableSettings =
      settings !== undefined && settings !== null
        ? settings[this.state.tableName] || {}
        : {};
    const resizedColumns = tableSettings.resized_columns || {};
    return { resizedColumns };
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "File Name",
        accessor: "file_name",
        headerClassName: "padded",
        className: "padded bold",
        minWidth: 100,
      },
      {
        Header: "Size",
        accessor: "file_size",
        width: 100,
        Cell: ({ value }) => this.formatBytes(value, 2),
      },
      {
        Header: "Last Modified",
        accessor: "updated_at",
        width: 160,
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Actions",
        id: "actions",
        headerClassName: "centered",
        className: "centered",
        maxWidth: 200,
        Cell: ({ original: attachment }) => (
          <>
            <TableButton
              icon="download"
              description="Download File"
              onClick={() => window.open(attachment.url)}
            />
            <DeleteFileModal
              onConfirmDelete={() => this.deleteFile(attachment.id)}
            />
          </>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  resizeColumns = async (
    resizedColumns,
    { updateColumns = true, updateBackend = true } = {}
  ) => {
    let { columns } = this.state;
    if (updateColumns) {
      columns = columns.map(column => {
        const width = resizedColumns[column.accessor];
        return width ? { ...column, width } : column;
      });
    }
    this.setState({
      columns,
      resizedColumns,
    });
    if (updateBackend) {
      await UserService.updateUserTableSettings(
        this.state.tableName,
        "resized_columns",
        resizedColumns
      );
    }
  };

  handleResizedChange = async newResized => {
    const column = newResized.find(elem => !this.oldResized.includes(elem));
    this.oldResized = newResized;
    if (!column) {
      return;
    }
    const { id, value } = column;
    const newResizedColumns = {
      ...this.state.resizedColumns,
      [id]: value,
    };
    this.resizeColumns(newResizedColumns, { updateColumns: false });
  };

  render = () => {
    const { isLoading } = this.props;
    const data = this.extractAttachments();
    return (
      <ReactTableDraggableColumns
        data={data}
        columns={this.state.columns}
        noDataText="No Files found. Try adjusting your filters."
        loading={isLoading}
        LoadingComponent={CustomLoader}
        draggableColumns={{
          mode: "reorder",
          draggable: _(this.state.columns).map("Header").compact().value(),
        }}
        style={{ backgroundColor: "white" }}
        onResizedChange={debounce(this.handleResizedChange, 300)}
      />
    );
  };
}

export default FileList;

import { applyFilters, doRequest } from "./helpers";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

export default class MachineApprovalStatusesService {
  static BASE_PATH = "/machine_approval_status";

  static async getTable(filters = null) {
    const path = applyFilters(
      filters,
      `${MachineApprovalStatusesService.BASE_PATH}/`
    );
    return doRequest("GET", path);
  }

  static async getForFilters(filters = null) {
    let path = `${MachineApprovalStatusesService.BASE_PATH}/for_filters`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async get(id) {
    return doRequest(
      "GET",
      `${MachineApprovalStatusesService.BASE_PATH}/${id}`
    );
  }

  static async create(data) {
    return doRequest("POST", `${MachineApprovalStatusesService.BASE_PATH}/`, {
      data: omitBy(data, isNil),
    });
  }

  static async update(id, data) {
    return doRequest(
      "PUT",
      `${MachineApprovalStatusesService.BASE_PATH}/${id}`,
      { data: omitBy(data, isNil) }
    );
  }

  static async delete(id) {
    return doRequest(
      "DELETE",
      `${MachineApprovalStatusesService.BASE_PATH}/${id}`
    );
  }
}

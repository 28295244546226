import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import useQueryParams from "./useQueryParams";

const QueryParamsManager = ({
  defaultParams,
  queryParams: parentQueryParams,
  setQueryParams: parentSetQueryParams,
  dependencies = [],
}) => {
  const [queryParams, setQueryParams] = useQueryParams(defaultParams);
  const [lastDeps, setLastDeps] = useState(dependencies);

  useEffect(() => {
    if (
      !isEmpty(parentQueryParams) &&
      !isEqual(queryParams, parentQueryParams)
    ) {
      setQueryParams(parentQueryParams);
    }
  }, [parentQueryParams]);

  useEffect(() => {
    let forceUpdate = false;
    if (!isEqual(dependencies, lastDeps)) {
      setLastDeps(dependencies);
      forceUpdate = true;
    }
    if (
      forceUpdate ||
      (parentSetQueryParams && !isEqual(queryParams, parentQueryParams))
    ) {
      parentSetQueryParams(queryParams);
    }
  }, [queryParams, ...dependencies]);

  return null;
};

export default QueryParamsManager;

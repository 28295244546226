import React from "react";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import RevenueOpportunityStatusPresetService from "../../../services/RevenueOpportunityStatusPreset";

const DeleteRevenueOpportunityStatusPresetModal = ({
  preset,
  fetchPresets,
}) => {
  const handleConfirm = async () => {
    await RevenueOpportunityStatusPresetService.deleteRevenueOpportunityStatusPreset(
      preset.id
    );
    fetchPresets();
  };

  return (
    <ConfirmationModal
      actionDescription="Delete Preset"
      buttonColor="red"
      onConfirm={handleConfirm}
      menuTrigger
      icon="trash"
      warning
    >
      <p>
        Are you sure you want to delete this Revenue Opportunity Status Preset?
      </p>
    </ConfirmationModal>
  );
};

export default DeleteRevenueOpportunityStatusPresetModal;

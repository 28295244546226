import React from "react";
import {
  Container,
  Header as SemanticHeader,
  Icon,
  Image,
  Menu,
  Segment,
} from "semantic-ui-react";
import pleteo_isotype from "../assets/Icon_Pleteo_White.png";
import CONFIG from "../Config";

const logoSrc = CONFIG.LOGO_SRC || pleteo_isotype;

const Maintenance = () => {
  return (
    <>
      <Menu secondary>
        <Menu.Item header content={<Image src={logoSrc} size="mini" />} />
      </Menu>

      <Container text style={{ paddingTop: "2rem" }}>
        <Segment>
          <SemanticHeader style={{ textAlign: "center" }} as="h3">
            <Icon name={"wrench"} />
            Temporarily down for maintenance
          </SemanticHeader>
          <div>
            We are performing scheduled maintenance. We should be back online
            shortly. Sorry for any inconvenience.
          </div>
        </Segment>
      </Container>
    </>
  );
};

export default Maintenance;

import React, { useEffect, useState } from "react";
import { Dropdown, Form, Input } from "semantic-ui-react";
import Datetime from "react-datetime";
import "../styles/datetime.scss";
import { mapSelectTagOption } from "../helpers/tags";
import EntityService from "../services/Entity";
import CallDispositionService from "../services/CallDispositions";
import RuvixxSelect from "./RuvixxSelect";

function OutreachFilterForm({
  uuid,
  campaignStatuses,
  emailTemplateOptions,
  userOptions,
  conditions_list,
  condition,
  entityTagOptions,
  contactTagOptions,
  conditionIdx,
  removeFilterElement,
  checkIfCanValidate = null,
  emailDomainFilter = false,
  campaignIds,
  isFilterReady,
  callTagsOptions,
}) {
  const [formData, setFormData] = useState({
    id: uuid,
    filter_context: condition.filter_context || undefined,
    filter_operand1: condition.filter_operand1 || undefined,
    filter_operator: condition.filter_operator || undefined,
    filter_operand2: condition.filter_operand2 || undefined,
    filter_template: condition.filter_template || undefined,
  });

  useEffect(() => {
    conditions_list[conditionIdx] = formData;
    isFilterReady();
  }, [formData, conditionIdx, conditions_list]);

  useEffect(() => {
    if (condition.filter_email_template) {
      let formDataCopy = formData;
      formDataCopy["filter_email_template"] = condition.filter_email_template;
      setFormData(formDataCopy);
    }
  }, [condition]);

  const comparisonOptions = [
    {
      key: 1,
      text: "Greater Than",
      value: {
        context: "operator",
        value: "gt",
        name: "filter_operator",
        next_operand: "filter_operand2",
        group: "comparisonOptions",
      },
    },
    {
      key: 2,
      text: "Greater Than or Equal to",
      value: {
        context: "operator",
        value: "ge",
        name: "filter_operator",
        next_operand: "filter_operand2",
        group: "comparisonOptions",
      },
    },
    {
      key: 3,
      text: "Less Than",
      value: {
        context: "operator",
        value: "lt",
        name: "filter_operator",
        next_operand: "filter_operand2",
        group: "comparisonOptions",
      },
    },
    {
      key: 4,
      text: "Less Than or Equal to",
      value: {
        context: "operator",
        value: "le",
        name: "filter_operator",
        next_operand: "filter_operand2",
        group: "comparisonOptions",
      },
    },
    {
      key: 5,
      text: "Exactly",
      value: {
        context: "operator",
        value: "eq",
        name: "filter_operator",
        next_operand: "filter_operand2",
        group: "comparisonOptions",
      },
    },
  ];

  const dateOperatorOptions = [
    {
      key: 1,
      text: "Exactly",
      value: {
        context: "date",
        value: "exactly",
        name: "filter_operator",
        next_operand: "filter_operand2",
        group: "dateOperatorOptions",
      },
    },
    {
      key: 2,
      text: "Between",
      value: {
        context: "date_range",
        value: "between",
        name: "filter_operator",
        next_operand: "filter_operand2",
        group: "dateOperatorOptions",
      },
    },
    {
      key: 3,
      text: "Before",
      value: {
        context: "date",
        value: "before",
        name: "filter_operator",
        next_operand: "filter_operand2",
        group: "dateOperatorOptions",
      },
    },
    {
      key: 4,
      text: "After",
      value: {
        context: "date",
        name: "filter_operator",
        value: "after",
        next_operand: "filter_operand2",
        group: "dateOperatorOptions",
      },
    },
    {
      key: 5,
      text: "Is",
      value: {
        context: "is",
        name: "filter_operator",
        value: "is",
        next_operand: "filter_operand2",
        group: "dateOperatorOptions",
      },
    },
  ];

  const nullOptions = [
    { key: 1, text: "Null", value: "NULL" },
    { key: 2, text: "Not Null", value: "NOT NULL" },
  ];

  const emailDomainOptions = [
    {
      key: "google",
      value: "google",
      text: "Google",
      name: "filter_operand2",
      group: "emailDomains",
    },
    {
      key: "microsoft",
      value: "microsoft",
      text: "Microsoft",
      name: "filter_operand2",
      group: "emailDomains",
    },
  ];

  const multipleSelectOptions = context => [
    {
      key: 1,
      value: {
        context: context,
        value: "contains",
        name: "filter_operator",
        next_operand: "filter_operand2",
        group: "multipleSelectOptions",
      },
      text: "Contains",
    },
    {
      key: 2,
      value: {
        context: context,
        value: "excludes",
        name: "filter_operator",
        next_operand: "filter_operand2",
        group: "multipleSelectOptions",
      },
      text: "Excludes",
    },
  ];

  const filterOperand1Options = [
    {
      key: 0,
      text: "Email Last Delivered At",
      value: {
        value: "last_delivered_at",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: dateOperatorOptions,
      },
    },
    {
      key: 1,
      text: "Email Last Opened At",
      value: {
        value: "last_opened_at",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: dateOperatorOptions,
      },
    },
    {
      key: 2,
      text: "Email Last Clicked At",
      value: {
        value: "last_clicked_at",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: dateOperatorOptions,
      },
    },
    {
      key: 3,
      text: "Last Delivered Template",
      value: {
        value: "last_delivered_template",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: multipleSelectOptions("templates"),
      },
    },
    {
      key: 4,
      text: "Last Call Status",
      value: {
        key: 1,
        value: "call_status",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: multipleSelectOptions("call_status"),
      },
    },
    {
      key: 5,
      text: "Last Form Response Date",
      value: {
        value: "last_form_response_date",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: dateOperatorOptions,
      },
    },
    {
      key: 6,
      text: "Last Called Date",
      value: {
        value: "last_called_date",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: dateOperatorOptions,
      },
    },
    {
      key: 7,
      text: "Days Since Last Called",
      value: {
        value: "days_since_last_called",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: comparisonOptions,
      },
    },
    {
      key: 8,
      text: "Number of Times Called",
      value: {
        value: "number_of_times_called",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: comparisonOptions,
      },
    },
    {
      key: 9,
      text: "Tags",
      value: {
        value: "tags",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: multipleSelectOptions("tags"),
      },
    },
    {
      key: 10,
      text: "Campaign Status",
      value: {
        key: 1,
        value: "campaign_status",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: multipleSelectOptions("campaign_status"),
      },
    },
    {
      key: 11,
      text: "Assignee",
      value: {
        key: 1,
        value: "assigned_user_id",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: multipleSelectOptions("assigned_user"),
      },
    },
    {
      key: 12,
      text: "Account Manager",
      value: {
        key: 1,
        value: "account_manager_id",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: multipleSelectOptions("account_manager"),
      },
    },
    {
      key: 14,
      text: "Email Domain",
      hideEntity: true,
      value: {
        value: "email_domain",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: multipleSelectOptions("emailDomains"),
      },
    },
    {
      key: 15,
      text: "Values",
      hideContact: true,
      value: {
        value: "entity_values",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: multipleSelectOptions("entities"),
      },
    },
    {
      key: 15,
      text: "Number of Emails Opened",
      value: {
        value: "number_of_emails_opened",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: comparisonOptions,
      },
    },
    {
      key: 16,
      text: "Number of Emails Clicked",
      value: {
        value: "number_of_emails_clicked",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: comparisonOptions,
      },
    },
    {
      key: 17,
      text: "Call Tags",
      hideEntity: true,
      value: {
        value: "call_tags",
        name: "filter_operand1",
        next_operand: "filter_operator",
        context: "default",
        options: multipleSelectOptions("call_tags"),
      },
    },
  ];

  const contextOptions = [
    {
      key: "contact",
      text: "Contact",
      value: "contact",
    },
    {
      key: "entity",
      text: "Entity",
      value: "entity",
    },
  ];

  const handleDateChange = (moment, name) => {
    let data = "";
    if (typeof moment === "string") {
      data = moment;
    } else {
      data = moment.format("YYYY-MM-DD");
    }
    setFormData(prevState => {
      let newState = { ...prevState };
      newState["filter_operand2"][0] = newState["filter_operand2"][0] || {};
      newState["filter_operand2"][0][name] = data;
      return newState;
    });
    if (checkIfCanValidate) {
      checkIfCanValidate();
    }
  };

  const handleOperandOneChange = (e, { name, value }) => {
    resetForm(name, formData);
    setFormData(prevState => ({ ...prevState, [name]: value.value }));
  };

  const handleOperatorChange = (e, { name, value }) => {
    resetForm(name, formData);
    setFormData(prevState => ({ ...prevState, [name]: value.value }));
  };

  const renderFilterContextElement = () => {
    return (
      <Dropdown
        placeholder="Filter Context"
        selection
        name="filter_context"
        style={{ minWidth: "100px", marginRight: "10px" }}
        value={formData.filter_context}
        options={contextOptions}
        onChange={handleContextChange}
      />
    );
  };

  const renderOperandOneEl = () => {
    return renderDefaultElement(
      "filter_operand1",
      filterOperand1Options,
      handleOperandOneChange
    );
  };

  const renderOperatorEl = () => {
    let filter_operand1 = filterOperand1Options.find(
      obj => obj.value.value === formData.filter_operand1
    );
    let addOn = null;
    if (
      filter_operand1.value.value === "last_opened_at" ||
      filter_operand1.value.value === "last_delivered_at" ||
      filter_operand1.value.value === "last_clicked_at"
    ) {
      addOn = renderSelect(emailTemplateOptions, "Template");
    }

    return renderDefaultElement(
      "filter_operator",
      filter_operand1.value.options,
      handleOperatorChange,
      addOn
    );
  };

  const makeDefaultOptions = (options, context) => {
    let opt;
    if (!emailDomainFilter) {
      if (context === "entity") {
        opt = options.filter(opt => !opt.hideEntity);
      } else if (context === "contact") {
        opt = options.filter(opt => !opt.hideContact);
      } else {
        opt = options.filter(opt => !opt.hideContact && !opt.hideEntity);
      }
    } else {
      opt = options;
    }
    return opt;
  };

  const renderDefaultElement = (
    nextElementName,
    options,
    callbackFn,
    addOn = null
  ) => {
    let value = options.find(
      obj => obj.value.value === formData[nextElementName]
    );

    return (
      <>
        {addOn ? addOn : null}
        <Dropdown
          search
          placeholder="Filter By"
          selection
          name={nextElementName}
          style={{ marginRight: "10px", minWidth: "100px" }}
          options={makeDefaultOptions(options, formData.filter_context)}
          value={value ? value.value : undefined}
          onChange={callbackFn}
        />
      </>
    );
  };

  const renderNullElement = (name, options) => {
    return (
      <Dropdown
        placeholder="Filter By"
        selection
        fluid
        name="filter_operand2"
        options={options}
        value={formData[name][0]}
        onChange={handleOperandTwoChange}
      />
    );
  };
  const renderNumberElement = name => {
    return (
      <Input
        required
        type="number"
        size="small"
        placeholder="Number"
        name={name}
        value={formData[name][0] || ""}
        onChange={handleOperandTwoChange}
      />
    );
  };

  const renderDateElement = (name, field) => {
    let date_element;
    if (field === "both") {
      date_element = (
        <>
          <Form.Field>
            <Datetime
              name="filter_operand2"
              closeOnSelect={true}
              onChange={moment => handleDateChange(moment, "from")}
              value={
                formData.filter_operand2 &&
                formData.filter_operand2[0] &&
                formData.filter_operand2[0].from
              }
              inputProps={{ placeholder: "From" }}
              dateFormat={"YYYY-MM-DD"}
              timeFormat={false}
              renderInput={props => (
                <Input icon="calendar outline" {...props} />
              )}
            />
          </Form.Field>
          <Datetime
            name="filter_operand2"
            closeOnSelect={true}
            onChange={moment => handleDateChange(moment, "to")}
            value={
              formData.filter_operand2 &&
              formData.filter_operand2[0] &&
              formData.filter_operand2[0].to
            }
            dateFormat={"YYYY-MM-DD"}
            timeFormat={false}
            inputProps={{ placeholder: "To" }}
            renderInput={props => <Input icon="calendar outline" {...props} />}
          />
        </>
      );
    } else {
      date_element = (
        <Datetime
          closeOnSelect={true}
          name="filter_operand2"
          onChange={moment => handleDateChange(moment, field)}
          value={
            formData.filter_operand2 &&
            formData.filter_operand2[0] &&
            formData.filter_operand2[0][field]
          }
          timeFormat={false}
          dateFormat={"YYYY-MM-DD"}
          inputProps={{ placeholder: "Date" }}
          renderInput={props => <Input icon="calendar outline" {...props} />}
        />
      );
    }

    return date_element;
  };

  const fetchCallDispositions = async () => {
    let dispositions = await CallDispositionService.getDispositionsForFilters({
      per_page: 100,
    });
    return [{ id: null, name: "None", value: null }, ...dispositions];
  };

  const renderMultipleSelect = (options, placeholder) => {
    let multiple_select;

    if (placeholder === "Entities") {
      multiple_select = (
        <RuvixxSelect
          required={true}
          placeholder={placeholder}
          name="filter_operand2"
          value={formData.filter_operand2}
          onChange={handleOperandTwoChange}
          queryFn={EntityService.getEntitiesForFilters}
          multiple={true}
          campaignIds={campaignIds}
          className="or-filter"
        />
      );
    } else if (placeholder === "Call Status") {
      multiple_select = (
        <RuvixxSelect
          name="filter_operand2"
          required={true}
          placeholder={placeholder}
          multiple
          clearable={false}
          value={formData.filter_operand2}
          onChange={handleOperandTwoChange}
          queryFn={fetchCallDispositions}
          sortParam="id"
          className="or-filter"
        />
      );
    } else if (placeholder === "Tags" || placeholder === "Call Tags") {
      multiple_select = (
        <Form.Select
          multiple
          search
          name="filter_operand2"
          className="fluid-field or-filter"
          options={options.map(tag =>
            mapSelectTagOption(tag, { maxWidth: "100%" })
          )}
          onChange={handleOperandTwoChange}
          renderLabel={item => item.label || item.text}
          defaultValue={formData.filter_operand2}
          style={{ minWidth: "100%" }}
        />
      );
    } else {
      multiple_select = (
        <Dropdown
          multiple
          placeholder={placeholder}
          selection
          fluid
          search
          name="filter_operand2"
          options={options}
          value={formData.filter_operand2}
          onChange={handleOperandTwoChange}
          className="or-filter"
        />
      );
    }
    return multiple_select;
  };

  const handleSelect = (e, { name, value }) => {
    let prevFormData = { ...formData };
    prevFormData[name] = value;
    setFormData(prevFormData);
  };

  const renderSelect = (options, placeholder) => {
    return (
      <Dropdown
        placeholder={placeholder}
        selection
        search
        name="filter_template"
        options={options}
        value={formData.filter_template}
        style={{ marginRight: "10px", minWidth: "150px", maxWith: "150px" }}
        onChange={handleSelect}
      />
    );
  };

  const renderOperandTwoEl = () => {
    let filter_operand1 = filterOperand1Options.find(
      obj => obj.value.value === formData.filter_operand1
    );

    let value = filter_operand1.value.options.find(
      obj => obj.value.value === formData.filter_operator
    );

    const name = "filter_operand2";
    let el;
    switch (value.value.context) {
      case "operator":
        el = renderNumberElement(name);
        break;
      case "date":
        el = renderDateElement(name, "from");
        break;
      case "date_range":
        el = renderDateElement(name, "both");
        break;
      case "tags":
        if (formData.filter_context === "contact") {
          el = renderMultipleSelect(contactTagOptions, "Tags");
        } else {
          el = renderMultipleSelect(entityTagOptions, "Tags");
        }
        break;
      case "call_tags":
        el = renderMultipleSelect(callTagsOptions, "Call Tags");
        break;
      case "templates":
        if (formData.filter_operand1 === "last_delivered_template") {
          el = renderMultipleSelect(
            emailTemplateOptions.filter(t => t.value !== "any"),
            "Templates"
          );
        } else {
          el = renderMultipleSelect(emailTemplateOptions, "Templates");
        }
        break;
      case "campaign_status":
        el = renderMultipleSelect(campaignStatuses, "Campaign Status");
        break;
      case "call_status":
        el = renderMultipleSelect(null, "Call Status");
        break;
      case "is":
        el = renderNullElement(name, nullOptions);
        break;
      case "assigned_user":
        el = renderMultipleSelect(userOptions, "Assignee");
        break;
      case "account_manager":
        el = renderMultipleSelect(userOptions, "Account Manager");
        break;
      case "emailDomains":
        el = renderMultipleSelect(emailDomainOptions, "Email Domain");
        break;
      case "entities":
        el = renderMultipleSelect(null, "Entities");
        break;
      default:
    }
    return el;
  };

  const handleContextChange = (e, { name, value }) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleOperandTwoChange = (e, { name, value }) => {
    if (!Array.isArray(value)) {
      value = [value];
    }
    setFormData(prevState => ({ ...prevState, [name]: value }));
    if (checkIfCanValidate) {
      checkIfCanValidate();
    }
  };

  const handleRemoveFilter = () => {
    removeFilterElement(conditionIdx);
  };

  const resetForm = name => {
    switch (name) {
      case "filter_context":
        delete formData["filter_operand1"];
        delete formData["filter_operator"];
        formData["filter_operand2"] = [];
        break;
      case "filter_operand1":
        delete formData["filter_operator"];
        formData["filter_operand2"] = [];
        break;
      case "filter_operator":
        formData["filter_operand2"] = [];
        break;
      case "filter_operand2":
        break;
      default:
    }
    setFormData(formData);
  };

  return (
    <Form.Group>
      {renderFilterContextElement()}
      {formData.filter_context ? renderOperandOneEl() : null}
      {formData.filter_operand1 ? renderOperatorEl() : null}
      {formData.filter_operator ? renderOperandTwoEl() : null}
      <i className="delete link icon red" onClick={handleRemoveFilter} />
    </Form.Group>
  );
}

export default OutreachFilterForm;

import React, { Component } from "react";
import { Form, Button, Modal, Header, Icon } from "semantic-ui-react";
import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import size from "lodash/size";
import CampaignService from "../../../../services/Campaign";
import TagService from "../../../../services/Tag";
import RegionService from "../../../../services/Regions";
import UserService from "../../../../services/User";
import DataJobService from "../../../../services/DataJob";
import AdvancedSearch from "../../../../components/AdvancedSearch";
import Constants from "../../../../constants/Constants";
import RuvixxForm from "../../../../components/RuvixxForm";
import ContactService from "../../../../services/Contact";
import CallDispositionService from "../../../../services/CallDispositions";
import { handleDownload } from "../../helpers";
import debounce from "lodash/debounce";
import RuvixxSelect from "../../../../components/RuvixxSelect";
import EntityService from "../../../../services/Entity";
import ExportTags from "../ExportTags";
import { v4 as uuidv4 } from "uuid";

const { EXPORT_TYPES, AUDIT_LOG_CATEGORIES } = Constants;

const { CONTACTS, AUDIT_LOGS, CAMPAIGN_TARGETS } = EXPORT_TYPES;

class ExportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataJobId: null,
      stage: "init",
      csvData: [],
      status: null,
      export: null,
      isExportComplete: false,
      isExportEmpty: false,
      isLargeExport: false,
      exportFailed: false,
      name: null,
      error: false,
      success: false,
      errorMessage: "",
      exportConfirmed: false,
      categories: [],
      subcategories: [],
      campaigns: [],
      contactTags: [],
      entityTags: [],
      contacts: [],
      campaignStatuses: [],
      regions: [],
      accountManagers: [],
      filterParams: {},
      queryParams: {},
      contactTagFilters: [],
      entityTagFilters: [],
    };
  }

  componentDidMount() {
    const { type } = this.props;
    this.fetchCampaigns();

    if (type === AUDIT_LOGS) {
      this.formatCategories();
      this.fetchContacts();
    } else {
      this.fetchTags();
      this.fetchCampaignStatuses();
    }

    if (type === CONTACTS) {
      this.fetchRegions();
      this.fetchUsers();
    }
  }

  getExportModel = () => {
    const { type } = this.props;
    let typeString;

    switch (type) {
      case CONTACTS:
        typeString = "contact";
        break;
      case AUDIT_LOGS:
        typeString = "audit_log";
        break;
      case CAMPAIGN_TARGETS:
        typeString = "campaign_target";
        break;
      default:
        typeString = null;
    }

    return typeString;
  };

  handleDownload = async () => {
    const { csvData } = this.state;
    const { type } = this.props;
    const fileName = `exported_${this.getExportModel(type)}s.xlsx`;
    const exportConfig = [
      {
        data: csvData,
        sheetName: "data",
      },
    ];

    const res = handleDownload(exportConfig, fileName);

    if (res) {
      this.setState({ isExportComplete: false, isExporting: false }, () =>
        this.props.onClose()
      );
      this.props.refreshTable();
    }
  };

  exportData = async () => {
    const { queryParams } = this.state;
    const model = this.getExportModel();
    const dataJob = await DataJobService.createExportJob(model);
    const response = await DataJobService.exportData(
      dataJob.id,
      queryParams,
      model
    );

    if (typeof response === "object") {
      if (response["scheduled"]) {
        this.setState({
          isExportComplete: true,
          isLargeExport: true,
        });
        return;
      } else {
        if (response["items"] === 0)
          this.setState({
            isExportComplete: true,
            isExportEmpty: true,
          });
      }
    }

    this.setState({
      csvData: response,
      isExportComplete: true,
    });
  };

  appendFilters = (params, filters, type) => {
    let newParams = params;

    for (let filter of filters) {
      if (filter.tags.length) {
        newParams[`${type}_tags:${filter.uuid}`] = JSON.stringify({
          operationType: filter.operationType,
          tags: filter.tags,
        });
      }
    }

    return newParams;
  };

  isExportType = exportTypes => {
    let isCorrectType;

    exportTypes.map(exportType => {
      if (exportType === this.props.type) {
        isCorrectType = true;
      }
    });

    return isCorrectType;
  };

  filterOptions = () => [
    {
      key: "region_id",
      title: "Region/Country",
      type: "select",
      clearable: true,
      data: this.state.regions,
    },
    {
      key: "account_manager_id",
      title: "Account Manager",
      type: "select",
      clearable: true,
      data: this.state.accountManagers,
    },
    {
      key: "is_callable",
      title: "Is Callable",
      type: "checkbox",
    },
    {
      key: "is_emailable",
      title: "Email Subscribed",
      type: "checkbox",
    },
  ];

  formatCategories = () => {
    let categories = [];
    let key = 0;

    for (const category in AUDIT_LOG_CATEGORIES) {
      categories.push({
        key,
        text: category,
        value: String(category).toLowerCase(),
      });
      key++;
    }
    this.setState({ categories });
  };

  getSubCategories = category => {
    let subcategories = [];
    let key = 0;
    const AUDIT_LOG_SUBCATEGORY =
      Constants[`${category.toUpperCase()}_SUBCATEGORY`];

    if (category == "call") {
      this.fetchDispositions();
      return;
    }

    if (!AUDIT_LOG_SUBCATEGORY) {
      const queryParams = this.state.queryParams;
      delete queryParams.action;

      this.setState({
        subcategories: [],
        queryParams,
      });
      return;
    }

    for (const subcategory in AUDIT_LOG_SUBCATEGORY) {
      subcategories.push({
        key,
        text: subcategory,
        value: AUDIT_LOG_SUBCATEGORY[subcategory].toLowerCase(),
      });
      key++;
    }

    this.setState({ subcategories });
  };

  fetchDispositions = async () => {
    let res = await CallDispositionService.getDispositionsForFilters();

    const dispositions = res.map(({ text, name }) => ({
      key: name,
      text: text,
      value: name,
    }));

    this.setState({
      subcategories: dispositions,
    });
  };

  fetchCampaigns = async () => {
    const res = await CampaignService.getCampaignsForFilters();
    const campaigns = res.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
    this.setState({ campaigns });
  };

  fetchTags = async () => {
    const contactTags = await TagService.getTags({ model: "contact" });
    const entityTags = await TagService.getTags({ model: "entity" });
    this.setState({ contactTags, entityTags });
  };

  fetchCampaignStatuses = async () => {
    let statuses = await CampaignService.getCampaignStatuses();
    let campaignStatuses = statuses.map(status => ({
      key: status.id,
      value: status.id,
      text: status.name,
    }));
    this.setState({
      campaignStatuses,
    });
  };

  fetchContacts = async (search = "") => {
    const filters = {
      per_page: 100,
      page: 1,
      sort_dir: "asc",
      sort_by: "full_name",
    };

    if (search) {
      filters["search"] = search;
    }

    let res = await ContactService.getContacts(filters);
    let contacts = res.data.map(contact => ({
      key: contact.id,
      value: contact.id,
      text: contact.full_name,
    }));
    this.setState({
      contacts,
    });
  };

  handleSearchChange = async (e, { searchQuery }) => {
    this.fetchContacts(searchQuery);
  };

  fetchRegions = async () => {
    if (this.props.type === CONTACTS) {
      const rawRegions = await RegionService.getRegions();
      const regions = rawRegions.map(region => ({
        name: region.name,
        key: region.id,
        id: region.id,
      }));
      this.setState({ regions });
    }
  };

  fetchUsers = async () => {
    if (this.props.type === CONTACTS) {
      const res = await UserService.getUsers();
      const accountManagers = res.map(user => ({
        key: user.id,
        id: user.id,
        name: user.full_name,
      }));
      this.setState({ accountManagers });
    }
  };

  updateParams = filterParams => {
    let queryParams = {};
    let filterOptions = this.filterOptions();
    filterOptions.forEach(({ key, title, type }) => {
      if (type === "input" || type === "checkbox") {
        queryParams[key] = filterParams[title];
      } else if (type === "select") {
        if (filterParams[title] && filterParams[title].length) {
          queryParams[key] = filterParams[title].map(item => item.value);
        }
      } else if (type === "dateRange") {
        if (filterParams[title] && filterParams[title].from) {
          queryParams[key + ":from"] =
            filterParams[title].from.format("YYYY-MM-DD");
        }
        if (filterParams[title] && filterParams[title].to) {
          queryParams[key + ":to"] =
            filterParams[title].to.format("YYYY-MM-DD");
        }
      }
    });

    this.setState({
      queryParams: {
        ...this.state.queryParams,
        ...pickBy(queryParams, identity),
      },
      filterParams,
    });
  };

  onAddTagFilters = type => {
    if (type === "contact") {
      this.setState({
        contactTagFilters: [
          ...this.state.contactTagFilters,
          {
            operationType: "contains",
            label: "Tags: ",
            tags: [],
            uuid: uuidv4(),
          },
        ],
      });
    } else {
      this.setState({
        entityTagFilters: [
          ...this.state.entityTagFilters,
          {
            operationType: "contains",
            label: "Tags: ",
            tags: [],
            uuid: uuidv4(),
          },
        ],
      });
    }
  };

  onUpdateTagFilters = (newTagFilters, type) => {
    const { queryParams } = this.state;
    if (type === "contact") {
      this.setState({ contactTagFilters: newTagFilters });
      this.appendFilters(queryParams, newTagFilters, "contact");
    } else {
      this.setState({ entityTagFilters: newTagFilters });
      this.appendFilters(queryParams, newTagFilters, "entity");
    }

    this.setState({ queryParams });
  };

  onDeleteTagFilter = (uuid, type) => {
    if (type === "contact") {
      const { contactTagFilters } = this.state;
      this.setState({
        contactTagFilters: contactTagFilters.filter(
          filter => filter.uuid !== uuid
        ),
      });
    } else {
      const { entityTagFilters } = this.state;
      this.setState({
        entityTagFilters: entityTagFilters.filter(
          filter => filter.uuid !== uuid
        ),
      });
    }
  };

  handleSelect = (e, { name, value }) => {
    let filters = this.state.queryParams;

    if (!value || value.length < 1) {
      delete filters[name];
    } else {
      filters[name] = value;
    }

    if (name === "category") {
      this.getSubCategories(value);
    }

    this.setState({
      queryParams: filters,
    });
  };

  closeExportModal = () => {
    if (this.state.isExportEmpty) {
      return this.setState({ isExportComplete: false, isExportEmpty: false });
    }
    this.setState({ isExportComplete: false });
    this.props.refreshTable();
    this.props.onClose();
  };

  render() {
    const { type, onClose } = this.props;
    const {
      campaigns,
      categories,
      subcategories,
      contactTags,
      entityTags,
      contactTagFilters,
      entityTagFilters,
      campaignStatuses,
      contacts,
      filterParams,
      queryParams,
      isExportComplete,
      isExportEmpty,
      isLargeExport,
    } = this.state;
    return (
      <RuvixxForm
        ready={
          size(queryParams) > 0 &&
          (type !== AUDIT_LOGS || queryParams.hasOwnProperty("category"))
        }
        cancelButtonText={"CANCEL"}
        onCancel={onClose}
        onSubmit={this.exportData}
        submitButtonText={"EXPORT"}
      >
        {type === AUDIT_LOGS && (
          <Form.Select
            inline
            search
            clearable
            name="category"
            label="Audit Log Category"
            options={categories}
            onChange={this.handleSelect}
          />
        )}
        {type === AUDIT_LOGS && subcategories.length !== 0 && (
          <Form.Select
            inline
            search
            clearable
            name="action"
            label="Sub Category"
            options={subcategories}
            onChange={this.handleSelect}
          />
        )}
        <Form.Select
          inline
          multiple
          clearable
          search
          name="campaign_id"
          label="Campaign Name"
          options={campaigns}
          onChange={this.handleSelect}
        />
        <RuvixxSelect
          label="Entity"
          name="entity_id"
          multiple={true}
          clearable={true}
          value={queryParams["entity_id"] || []}
          onChange={this.handleSelect}
          queryFn={EntityService.getEntitiesForFilters}
        />
        {type !== AUDIT_LOGS && (
          <Form.Select
            inline
            clearable
            name="campaign_status_id"
            label="Campaign Status"
            search
            multiple
            options={campaignStatuses}
            onChange={this.handleSelect}
          />
        )}
        {type === CONTACTS && (
          <ExportTags
            type={"Contact"}
            tagsData={contactTags}
            tagsFilters={contactTagFilters}
            onUpdate={this.onUpdateTagFilters}
            onDelete={this.onDeleteTagFilter}
            onAdd={this.onAddTagFilters}
          />
        )}
        {type !== AUDIT_LOGS && (
          <ExportTags
            type={"Entity"}
            tagsData={entityTags}
            tagsFilters={entityTagFilters}
            onUpdate={this.onUpdateTagFilters}
            onDelete={this.onDeleteTagFilter}
            onAdd={this.onAddTagFilters}
          />
        )}
        {type === AUDIT_LOGS && (
          <Form.Select
            inline
            clearable
            name="contact_id"
            label="Contact Name"
            search
            multiple
            options={contacts}
            onChange={this.handleSelect}
            onSearchChange={debounce(this.handleSearchChange, 300)}
          />
        )}
        {type === CONTACTS ? (
          <AdvancedSearch
            name="Export Filters"
            filters={this.filterOptions()}
            filterParams={filterParams}
            updateFilterParams={this.updateParams.bind(this)}
          />
        ) : null}
        {isExportComplete ? (
          <Modal open={isExportComplete || isExportEmpty} size="small">
            <Header content="Export" />
            <Modal.Content>
              {!isExportEmpty
                ? isLargeExport
                  ? "An export has been scheduled, you will receive a download link to your email account"
                  : `The export is completed!, download XLS file down below`
                : "The export did not return any information, update filters and try again"}
            </Modal.Content>
            <Modal.Actions>
              {isExportEmpty || isLargeExport ? (
                <Button onClick={this.closeExportModal}>CLOSE</Button>
              ) : (
                <Button primary onClick={this.handleDownload}>
                  <Icon name="checkmark" /> DOWNLOAD
                </Button>
              )}
            </Modal.Actions>
          </Modal>
        ) : null}
      </RuvixxForm>
    );
  }
}

export default ExportForm;

import React, { useState } from "react";
import CampaignService from "../../../services/Campaign";
import { Button, Form, Modal } from "semantic-ui-react";

const NewCampaignStatusModal = ({ fetchStatuses }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const handleSubmit = async () => {
    await CampaignService.createCampaignStatus(name);
    fetchStatuses();
    setOpen(false);
    setName("");
  };

  const header = "New Campaign Status";

  const Trigger = (
    <Button
      content={header}
      size="tiny"
      className="item-adder"
      onClick={() => setOpen(true)}
    />
  );

  return (
    <Modal trigger={Trigger} open={open} onClose={() => setOpen(false)}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Name</label>
            <input
              placeholder="Campaign Status Name"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </Form.Field>
          <Button type="submit" primary>
            Add
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default NewCampaignStatusModal;

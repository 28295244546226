import React, { useState, useEffect } from "react";
import { Input, Checkbox, Select } from "semantic-ui-react";
import Datetime from "react-datetime";
import moment from "moment";
import "../styles/adaptable_input.scss";
import usePrevious from "../hooks/usePrevious";

const AdaptableInput = ({
  type,
  disabled,
  options,
  value: parentValue,
  ...props
}) => {
  const [value, setValue] = useState(parentValue || "");
  const previousType = usePrevious(type);

  useEffect(() => {
    setValue(parentValue || "");
  }, [parentValue]);

  const handleChange = (event, data) => {
    let value = data.value;
    let valid = true;
    if (type === "number") {
      if (!/^[+-]?[0-9]*\.?[0-9]{0,2}$/.test(value)) {
        return;
      }
      if (isNaN(value)) {
        valid = false;
      }
    } else if (type === "date") {
      if (moment.isMoment(value)) {
        value = moment(value).format("YYYY-MM-DD");
      } else {
        valid = false;
      }
    }
    if (valid) {
      props.onChange(event, {
        ...data,
        value,
      });
    }
    setValue(value);
  };

  const ensureNumericInput = event => {
    const character = String.fromCharCode(event.which);
    const allowedCharacters = [
      "+",
      "-",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
    ];
    if (!allowedCharacters.includes(character)) {
      event.preventDefault();
    }
  };

  const renderInput = () => {
    return <Input value={value} {...props} disabled={disabled} />;
  };

  const renderNumericInput = () => {
    return (
      <Input
        {...props}
        disabled={disabled}
        type="number"
        step={0.01}
        onKeyPress={ensureNumericInput}
        value={value}
        onChange={handleChange}
      />
    );
  };

  const renderCheckbox = () => {
    const isString = typeof parentValue === "string";
    return (
      <Checkbox
        toggle
        {...props}
        disabled={disabled}
        checked={isString ? parentValue === "true" : parentValue}
        onChange={(event, data) => {
          const value = isString ? String(data.checked) : data.checked;
          props.onChange(event, {
            ...data,
            checked: value,
            value,
          });
        }}
      />
    );
  };

  const renderDatePicker = () => {
    return (
      <Datetime
        {...props}
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
        className="above"
        value={value}
        onChange={value => handleChange(null, { ...props, value })}
      />
    );
  };

  const renderList = () => {
    let val = value;
    const isMultiple =
      type === "list" || (type === "categorical" && options?.type === "multi");
    if (typeof val === "string" && isMultiple) {
      val = (val || "{}").replace(/^{|}$/g, "");
      val = val ? val.split(",") : [];
      val = val.map(e => e.replace(/^[\\"]+|[\\"]+$/g, ""));
    }
    let listOptions = type === "list" ? val : options?.options || [];
    listOptions = listOptions.map(v => ({ key: v, value: v, text: v }));
    return (
      <Select
        {...props}
        disabled={disabled}
        inline
        clearable
        search
        multiple={isMultiple}
        options={listOptions}
        value={val}
        onChange={handleChange}
        allowAdditions={type === "list"}
        className="adaptable-select"
      />
    );
  };

  switch (type) {
    case "text":
    case "string":
      return renderInput();
    case "number":
      return renderNumericInput();
    case "boolean":
      return renderCheckbox();
    case "date":
      return renderDatePicker();
    case "list":
    case "categorical":
      return renderList();
    default:
      return null;
  }
};

export default AdaptableInput;

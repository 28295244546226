export const DIALER_STATES = {
  CALLING: "calling",
  CONNECTED: "connected",
  READY: "ready",
  INCOMING: "incoming",
  OFFLINE: "offline",
};

export const CALL_RECORDING_ACTIONS = {
  START: "start",
  PAUSE: "pause",
  DELETE: "delete", // stops and deletes recording
};
export const CALL_RECORDING_LAW = {
  NO_RECORDING: 0,
  SINGLE_PARTY: 1,
  TWO_PARTY: 2,
};

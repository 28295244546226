import React, { useState } from "react";
import RuvixxForm from "../../../components/RuvixxForm";
import Question from "../../email_urls/components/Question";
import { v4 as uuid } from "uuid";

const FollowupForm = ({
  parent_question,
  form_response_id,
  onSuccess,
  onEditOptions,
}) => {
  const [question, setQuestion] = useState({
    parent_question_id: parent_question.id,
    title: "",
    question_type: 1,
    order: 1,
    page: 1,
    collapsed: false,
    ref: uuid(),
    form_response_id: form_response_id,
    options: [],
    form_id: parent_question.form_id,
  });

  return (
    <RuvixxForm ready onSuccess={() => onSuccess(question)}>
      <Question
        question={question}
        onUpdate={setQuestion}
        onEditOptions={onEditOptions}
      />
    </RuvixxForm>
  );
};

export default FollowupForm;

import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import RuvixxForm from "components/RuvixxForm";
import ContactService from "services/Contact";
import EntityService from "services/Entity";
import CONSTANTS from "constants/Constants";

const AssignContactForm = ({
  onSuccess,
  updateContactId,
  contacts,
  phoneNumber,
}) => {
  const [contactOptions, setContactOptions] = useState([]);
  const [contactId, setContactId] = useState(null);

  const updateContactOptions = async searchQuery => {
    let fetchedContacts = [];
    if (searchQuery) {
      const filters = { search_query: searchQuery };
      fetchedContacts = await ContactService.getContactsForFilters(filters);
    }
    const allContacts = [...contacts, ...fetchedContacts];
    const contactOptions = allContacts.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
    setContactOptions(contactOptions);
  };

  useEffect(() => {
    updateContactOptions();
  }, [contacts]);

  const handleSubmit = async () => {
    const contact = await ContactService.getContact(contactId);
    const contactHavePhone = contact.phone_numbers.some(
      phone_number => phone_number.number === phoneNumber
    );

    if (!contactHavePhone) {
      const newPhoneNumbers = [
        ...contact.phone_numbers,
        {
          is_primary: false,
          number: phoneNumber,
          ext: "",
          type: CONSTANTS.CONTACT_PHONE_TYPES.DEFAULT,
          enabled: true,
          is_callable: true,
        },
      ];

      await EntityService.editContact(contact.entity_id, contactId, {
        ...contact,
        phone_numbers: newPhoneNumbers,
      });
    }
    updateContactId(contactId);
    onSuccess();
  };

  return (
    <RuvixxForm onSubmit={handleSubmit} onSuccess={onSuccess}>
      <Form.Select
        label="Contact"
        placeholder="Select contact or type to search"
        search
        options={contactOptions}
        value={contactId}
        onChange={(_, { value }) => setContactId(value)}
        onSearchChange={debounce(
          (_, { searchQuery }) => updateContactOptions(searchQuery),
          300
        )}
      />
    </RuvixxForm>
  );
};

const contactPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

AssignContactForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  updateContactId: PropTypes.func.isRequired,
  contacts: PropTypes.arrayOf(contactPropType),
};

AssignContactForm.defaultProps = {
  contacts: [],
};

export default AssignContactForm;

import React from "react";
import ConfirmationModal from "./ConfirmationModal";
import CaseDomainService from "../../services/CaseDomain";

const DeleteCaseDomainModal = ({ caseDomainId, fetchData }) => {
  const handleDelete = async () => {
    try {
      await CaseDomainService.delete(caseDomainId);
    } catch (e) {
      return e;
    }
    fetchData();
  };

  return (
    <ConfirmationModal
      actionDescription="Delete Case Domain"
      buttonColor="grey"
      onConfirm={handleDelete}
      menuTrigger
      icon="trash"
      warning
    >
      <p>Are you sure you want to delete this Case Domain?</p>
    </ConfirmationModal>
  );
};

export default DeleteCaseDomainModal;

import { applyFilters, doRequest } from "./helpers";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

export default class GroupService {
  static async getTable(filters = null) {
    let path = "/admin/group/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getForFilters(filters = null) {
    let path = "/admin/group/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async get(id) {
    return doRequest("GET", `/admin/group/${id}`);
  }

  static async create(data) {
    return doRequest("POST", "/admin/group/", { data: omitBy(data, isNil) });
  }

  static async update(id, data) {
    return doRequest("PUT", `/admin/group/${id}`, {
      data: omitBy(data, isNil),
    });
  }

  static async delete(id) {
    return doRequest("DELETE", `/admin/group/${id}`);
  }

  static async addUsers(data) {
    return doRequest("PUT", "/admin/group/add_users", {
      data: data,
    });
  }

  static async removeUsers(data) {
    return doRequest("PUT", "/admin/group/remove_users", {
      data: data,
    });
  }
}

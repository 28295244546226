import React, { useState, useEffect } from "react";
import { Form, Button, Icon, Accordion, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

import ReferralContactsTable from "./ReferralContactsTable";
import CampaignService from "../../../services/Campaign";
import ContactService from "../../../services/Contact";
import EntityService from "../../../services/Entity";
import CallQueueService from "../../../services/CallQueue";
import EmailAddresses from "../../../components/EmailsAddresses";
import PhoneNumbers from "../../../components/PhoneNumbers";
import RuvixxForm from "../../../components/RuvixxForm";

import "../../../styles/rdw_custom.scss";

function AddReferralForm({
  onSuccess,
  contact_id,
  entity_id,
  campaign_id,
  addContactToQueue,
  dialSessionId,
  privateSession,
}) {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [formData, setFormData] = useState({
    fullName: "",
    title: "",
    notes: "",
  });
  const [err, setErr] = useState({
    error: false,
    message: "",
    title: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [addedReferred, setAddedReferred] = useState(false);
  const [addedToQueue, setAddedToQueue] = useState(false);
  const [addedContact, setAddedContact] = useState("");

  const handleChange = (event, data) => {
    const formDataCopy = { ...formData };
    formDataCopy[data.name] =
      data.type === "checkbox" ? data.checked : data.value;
    setFormData(formDataCopy);
  };

  const handleSubmit = async (e, dialReferred = false) => {
    setSubmitting(true);
    const campaignTarget = await CampaignService.getCampaignTarget(
      campaign_id,
      entity_id
    );
    if (!campaignTarget) {
      await CampaignService.createCampaignTarget(campaign_id, entity_id);
    }

    const { fullName, title, notes } = formData;
    const nonEmptyEmailAddresses = emailAddresses.filter((email, i) =>
      email.email?.trim()
    );
    const nonEmptyPhoneNumbers = phoneNumbers.filter((pn, i) =>
      pn.number?.trim()
    );

    const data = {
      full_name: fullName,
      email_addresses: nonEmptyEmailAddresses,
      title,
      phone_numbers: nonEmptyPhoneNumbers,
    };
    // call new Contact API
    try {
      const new_contact = await EntityService.addContact(entity_id, data);
      // call add referral API
      await ContactService.addReferral(
        contact_id,
        new_contact.id,
        notes,
        dialSessionId
      );
      if (!privateSession) {
        await CampaignService.addContact(
          campaign_id,
          entity_id,
          new_contact.id
        );
      }
      if (phoneNumbers.length > 0) {
        addContactToQueue(new_contact, !!dialReferred);
      }
      setAddedReferred(true);
    } catch (e) {
      setSubmitting(false);
      throw e;
    }
  };

  const callNextContact = async contact => {
    try {
      await CallQueueService.addContact(
        dialSessionId,
        contact["id"],
        true,
        true
      );
      setAddedContact(contact["full_name"]);
      setAddedToQueue(true);
    } catch (error) {
      setOpenTable(false);
      setErr({
        error: true,
        message: error.message,
      });
    }
  };

  const addEmailAddress = (email, error) => {
    if (error) {
      setErr({
        error: true,
        message: error.message,
        title: error.title,
      });
      return;
    }
    let emails = [...emailAddresses];
    emails.push(email);
    setEmailAddresses(emails);
    setErr({ error: false, message: undefined, title: undefined });
  };

  const removeEmailAddress = index => {
    let emails = [...emailAddresses];
    emails.splice(index, 1);
    setEmailAddresses(emails);
  };

  const updateEmailAddress = (email, index) => {
    let emails = [...emailAddresses];
    emails[index] = email;
    setEmailAddresses(emails);
  };

  const addPhoneNumber = (pn, error) => {
    if (error) {
      setErr({
        error: true,
        message: error.message,
        title: error.title,
      });
      return;
    }

    const isDuplicate = phoneNumbers.filter(p => p === pn).length > 0;

    if (!isDuplicate) {
      let pns = [...phoneNumbers];
      pns.push(pn);
      setPhoneNumbers(pns);
      setErr({
        error: false,
        message: "",
        title: "",
      });
    }
  };

  const removePhoneNumber = index => {
    let pns = [...phoneNumbers];
    pns.splice(index, 1);
    setPhoneNumbers(pns);
  };

  const updatePhoneNumber = (phoneNumber, index) => {
    setPhoneNumbers(phoneNumbers => {
      let phoneNumbersCopy = phoneNumbers.map(p => ({ ...p }));
      phoneNumbersCopy[index] = phoneNumber;
      return phoneNumbersCopy;
    });
  };

  const handleClick = () => {
    setAddedContact(null);
    setAddedToQueue(false);
    setAddedReferred(false);
    onSuccess();
  };

  const getSecondButton = () => {
    return {
      content: (
        <div>
          Save and Dial next <Icon name="phone" />
        </div>
      ),
      size: "tiny",
      color: "green",
      disabled: !(isReady && canCall),
      onSubmit: e => handleSubmit(e, true),
    };
  };

  const hasPhone = phoneNumbers.length > 0;
  const isReady =
    !submitting &&
    !!formData.fullName &&
    (emailAddresses.length > 0 || phoneNumbers.length > 0);
  const isCallable = phoneNumbers.some(({ is_callable }) => is_callable);
  const enableCall = phoneNumbers.some(({ enabled }) => enabled);
  const canCall = hasPhone && isCallable && enableCall;
  return (
    <RuvixxForm
      ready={isReady}
      onSubmit={handleSubmit}
      error={err.error}
      errorMessage={err.message}
      errorTitle={err.title}
      submitButtonText={"Save"}
      secondButton={getSecondButton()}
    >
      <Form.Input
        inline
        name="fullName"
        value={formData.fullName}
        label="Name"
        required
        onChange={handleChange}
      />
      <Form.Input
        inline
        name="title"
        value={formData.title}
        label="Title"
        onChange={handleChange}
      />
      <EmailAddresses
        emailAddresses={emailAddresses}
        addEmailAddress={addEmailAddress}
        removeEmailAddress={removeEmailAddress}
        updateEmailAddress={updateEmailAddress}
      />
      <PhoneNumbers
        phoneNumbers={phoneNumbers}
        addPhoneNumber={addPhoneNumber}
        removePhoneNumber={removePhoneNumber}
        updatePhoneNumber={updatePhoneNumber}
      />
      <Accordion>
        <Accordion.Title onClick={() => setOpenTable(!openTable)}>
          <Icon name={openTable ? "caret down" : "caret right"} />
          Entity's Contacts
        </Accordion.Title>
        <Accordion.Content active={openTable}>
          <ReferralContactsTable
            entity_id={entity_id}
            campaign_id={campaign_id}
            callNextContact={callNextContact}
          />
        </Accordion.Content>
      </Accordion>
      <Form.TextArea
        inline
        name="notes"
        value={formData.notes}
        label="Notes"
        onChange={handleChange}
      />
      <Modal
        open={addedToQueue}
        onClose={() => onSuccess()}
        closeOnDimmerClick={false}
        size="small"
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header icon="warning sign" content={"Call Queue"} />
        <Modal.Content>
          The contact {addedContact} was added to the Call Queue succesfully
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={handleClick}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={addedReferred}
        onClose={() => onSuccess()}
        closeOnDimmerClick={false}
        size="small"
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header icon="warning sign" content={"Referred Contact"} />
        <Modal.Content>A referred Contact was added succesfully</Modal.Content>
        <Modal.Actions>
          <Button basic onClick={handleClick}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </RuvixxForm>
  );
}

AddReferralForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  contact_id: PropTypes.number.isRequired,
  entity_id: PropTypes.number.isRequired,
};

export default AddReferralForm;

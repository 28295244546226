import { useCallback, useEffect, useState } from "react";
import TagService from "../services/Tag";

const useTags = model => {
  const [value, setValue] = useState([]);

  const fetchTags = useCallback(async () => {
    const tags = await TagService.getTags({ model });
    setValue(tags);
  }, [model]);

  useEffect(() => {
    if (model) {
      fetchTags();
    }
  }, [model]);

  return value;
};

export default useTags;

import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Divider,
  Header,
  Icon,
  List,
  Segment,
} from "semantic-ui-react";
import store from "store";
import ACL_RELATIONSHIPS from "../acl-relationships";
import { checkIsAuthorized } from "../components/helpers";
import CallModal from "../components/modals/CallModal";
import CONFIG from "../Config";
import { getSession } from "../helpers/sessionHelpers";
import UserService from "../services/User";

import "./../styles/sidebar.scss";
import SideBarCampaigns from "./SidebarCampaigns";
import SideBarCustomFields from "./SideBarCustomFields";
import TagDropdown from "./TagDropdown";
import TagList from "./TagList";
import { titleCase } from "../helpers/string";

const MODEL_TYPES = {
  entities: "Entity",
  contacts: "Contact",
  cases: "Case",
};

function Sidebar({
  modelId,
  modelName,
  name,
  campaignId,
  campaignName,
  contact_id,
  entity_id,
  entityCustom,
  dialer,
  fetchModel,
  icon,
  disableCall,
  disableEmail,
  composeEmailModal,
  editButton,
  handleTabChange,
  info,
  extraInfo,
  custom,
  onCreateNote,
  refreshSidebar,
}) {
  const ComposeEmailModal = composeEmailModal;
  const [activeSession, setActiveSession] = useState(null);
  const [showDisabledTags, setShowDisabledTags] = useState({});
  const [showDisabledTagsDisabled, setShowDisabledTagsDisabled] =
    useState(false);
  const canCreateNotes = checkIsAuthorized([ACL_RELATIONSHIPS.activity.create]);
  const canCall =
    !dialer &&
    checkIsAuthorized([
      ACL_RELATIONSHIPS.dialSession.read,
      ACL_RELATIONSHIPS.dialSessionPrivate.create,
      ACL_RELATIONSHIPS.callQueueNextContact.read,
      ACL_RELATIONSHIPS.callQueueAddContact.create,
      ACL_RELATIONSHIPS.disposition.read,
      ACL_RELATIONSHIPS.emailUrlForms.read,
      ACL_RELATIONSHIPS.voicemailForFilters.read,
      ACL_RELATIONSHIPS.twilioToken.read,
      ACL_RELATIONSHIPS.tag.read,
      ACL_RELATIONSHIPS.user.read,
      ACL_RELATIONSHIPS.campaignStatuses.read,
      ACL_RELATIONSHIPS.twilioDisposition.create,
      ACL_RELATIONSHIPS.twilioAddToCall.create,
      ACL_RELATIONSHIPS.twilioRemoveFromCall.create,
      ACL_RELATIONSHIPS.twilioUpdateParticipant.create,
    ]);
  const canSendEmail = contact_id
    ? checkIsAuthorized([
        ACL_RELATIONSHIPS.emailTemplates.read,
        ACL_RELATIONSHIPS.contactEmail.create,
      ])
    : entity_id
    ? checkIsAuthorized([
        ACL_RELATIONSHIPS.emailTemplates.read,
        ACL_RELATIONSHIPS.entityEmail.create,
      ])
    : false;

  const getSidebarSettings = () => {
    const { show_disabled_tags: showDisabledTags } =
      store.get("userAuth").sidebar_settings?.[modelName] || {};
    if (showDisabledTags) {
      setShowDisabledTags(showDisabledTags);
    }
  };

  useEffect(() => {
    getSidebarSettings();
  }, []);

  const storageUpdated = useCallback(
    event => {
      const sessionId = getSession();
      setActiveSession(sessionId);
    },
    [getSession]
  );

  useEffect(() => {
    window.addEventListener("storage", storageUpdated);
  }, [storageUpdated]);

  const renderTagItem = (item, index) => {
    const { title, tags, modelType, model, value } = item;
    if (!model) {
      return null;
    }
    let filteredTags = tags;
    let filteredModelTags = value;
    if (!showDisabledTags[modelType]) {
      filteredTags = tags.filter(({ enabled }) => enabled === true);
      filteredModelTags = value.filter(({ enabled }) => enabled === true);
    }
    return (
      <List.Item key={index}>
        <List.Header>
          <div
            className="tags"
            style={{ display: "flex", alignItems: "center" }}
          >
            {title || "Tags"}
            <TagDropdown
              key={`sidebar-tag-dropdown-${modelType}-${model.id}`}
              tags={filteredTags}
              modelType={modelType}
              rows={[model]}
              checkedArr={[model.id]}
              onSuccess={fetchModel}
              direction="right"
            />
          </div>
        </List.Header>
        <List.Content>
          <TagList
            key={`sidebar-tags-${modelType}-${model.id}`}
            tags={filteredModelTags}
            modelType={MODEL_TYPES[modelName]}
            modelId={modelId}
            onUpdate={fetchModel}
          />
          <Checkbox
            style={{ margin: "5px 0" }}
            label="Show disabled tags"
            checked={showDisabledTags[modelType]}
            disabled={showDisabledTagsDisabled}
            onChange={(_, { checked }) => changeShowDisabledTags(checked, item)}
          />
        </List.Content>
      </List.Item>
    );
  };

  const changeShowDisabledTags = async (checked, item) => {
    setShowDisabledTagsDisabled(true);
    const newShowDisabledTags = {
      ...showDisabledTags,
      [item.modelType]: checked,
    };
    await UserService.updateUserSidebarSettings(
      modelName,
      "show_disabled_tags",
      newShowDisabledTags
    );
    setShowDisabledTags(newShowDisabledTags);
    setShowDisabledTagsDisabled(false);
  };

  const handleCreateNote = event => {
    if (onCreateNote) {
      onCreateNote(event);
    } else if (handleTabChange) {
      handleTabChange(event, {
        name: "notes",
        focusOnNewNote: true,
      });
    }
  };

  const renderCampaignTargetBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to="/campaigns/" target={dialer ? "_blank" : null}>
          All Campaigns
        </Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section>
        <Link to={`/campaigns/${campaignId}`} target={dialer ? "_blank" : null}>
          {campaignName}
        </Link>
      </Breadcrumb.Section>
      <br />
      <Breadcrumb.Section active>{name}</Breadcrumb.Section>
    </Breadcrumb>
  );

  const renderDefaultBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={`/${modelName}`} target={dialer ? "_blank" : null}>
          All {titleCase(modelName)}
        </Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle"></Breadcrumb.Divider>
      <Breadcrumb.Section active>{name}</Breadcrumb.Section>
    </Breadcrumb>
  );

  return (
    <div className="sidebar">
      <aside>
        {modelName === "campaignTargets"
          ? renderCampaignTargetBreadcrumb()
          : renderDefaultBreadcrumb()}
        <Segment basic clearing className="title">
          <Header floated="left">
            {icon && <Icon name={icon} />}
            {name}
          </Header>
          {editButton}
        </Segment>
        <Segment basic className="actions">
          {canCreateNotes && (
            <div>
              <Button icon onClick={handleCreateNote}>
                <Icon name="sticky note outline" size="large" />
              </Button>
              <label>Note</label>
            </div>
          )}
          {canCall && contact_id && (
            <div hidden={!CONFIG.IS_PHONE_ENABLED && modelName !== "contacts"}>
              <CallModal
                disabled={!!activeSession || disableCall}
                contactId={contact_id}
                trigger={
                  <Button icon>
                    <Icon name="call" size="large" />
                  </Button>
                }
              />
              <label>Call</label>
            </div>
          )}
          {canSendEmail && composeEmailModal && (
            <div>
              <ComposeEmailModal
                trigger={
                  <Button icon disabled={disableEmail || false}>
                    <Icon name="mail outline" size="large" />
                  </Button>
                }
              />
              <label>Email</label>
            </div>
          )}
        </Segment>
        <Divider />
        <Segment basic className="info">
          <List>
            {info
              .map(item => ({
                ...item,
                value: Array.isArray(item.value) ? item.value : [item.value],
              }))
              .filter(item => {
                const val = item.value[0];
                return val === 0 || !!val;
              })
              .map((item, index) =>
                item.type === "tags" ? (
                  renderTagItem(item, index)
                ) : (
                  <List.Item key={index}>
                    <List.Header>{item.title}</List.Header>
                    <List.Content>
                      {item.value.map((val, index) => (
                        <div key={index}>{val}</div>
                      ))}
                    </List.Content>
                  </List.Item>
                )
              )}
          </List>
        </Segment>
        {custom && Object.keys(custom).length > 0 && (
          <SideBarCustomFields custom={custom} />
        )}
        {modelName === "cases" && modelId && (
          <SideBarCampaigns campaignId={campaignId} caseId={modelId} />
        )}
        {contact_id ? (
          <SideBarCampaigns
            dialer={dialer}
            campaignId={campaignId}
            contactId={contact_id}
            refreshSidebar={refreshSidebar}
          />
        ) : (
          modelName === "entities" &&
          entity_id && (
            <SideBarCampaigns
              dialer={dialer}
              campaignId={campaignId}
              entityId={entity_id}
            />
          )
        )}
        {extraInfo &&
          extraInfo.map(({ title, href, fields }, index) => (
            <Segment key={index} basic className="gray">
              <Header>
                {href ? (
                  <Link to={href} target={dialer ? "_blank" : null}>
                    {title}
                  </Link>
                ) : (
                  title
                )}
              </Header>
              <List>
                {fields
                  .map(item => ({
                    ...item,
                    value: Array.isArray(item.value)
                      ? item.value
                      : [item.value],
                  }))
                  .filter(item => item.value[0])
                  .map((item, index) =>
                    item.type === "tags" ? (
                      renderTagItem(item, index)
                    ) : (
                      <List.Item key={index}>
                        <List.Header>{item.title}</List.Header>
                        <List.Content>
                          {item.value.map((val, index) => (
                            <p key={index}>{val}</p>
                          ))}
                        </List.Content>
                      </List.Item>
                    )
                  )}
              </List>
            </Segment>
          ))}
        {modelName !== "entities" && entityCustom && (
          <SideBarCustomFields
            custom={entityCustom}
            header="Entity Custom Fields"
          />
        )}
        {modelName !== "entities" && !!entity_id && (
          <SideBarCampaigns
            dialer={dialer}
            campaignId={campaignId}
            entityId={entity_id}
            prefix={"Entity"}
          />
        )}
        <Divider hidden />
      </aside>
    </div>
  );
}

export default Sidebar;

import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import UserService from "../../../services/User";
import RoleService from "services/Role";
import { useEffect, useCallback } from "react";

const EditRoleForm = ({ userIds, onSuccess, onCancel }) => {
  const [userRole, setUserRole] = useState(null);
  const [rolesOptions, setRolesOptions] = useState([]);

  const handleSubmit = async () => {
    for (const id of userIds) {
      const data = {
        rolesOnly: true,
        role: userRole,
      };
      UserService.updateUser(id, data);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const fetchRoles = useCallback(async () => {
    const roles = await RoleService.getRoles();
    const options = roles.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
    setRolesOptions(options);
  }, []);

  return (
    <RuvixxForm
      ready={!!userRole}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      onCancel={onCancel}
    >
      <Form.Select
        required
        inline
        label="Role"
        className="fillSpace"
        options={rolesOptions}
        value={userRole}
        onChange={(_, { value }) => setUserRole(value)}
      />
      <p>
        <span className="requiredStar" /> Indicates a required field
      </p>
    </RuvixxForm>
  );
};

export default EditRoleForm;

import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import DashboardService from "../../services/Dashboard";
import { attachCancelTokenToNextRequest } from "../../services/helpers";
import { Dimmer, Loader } from "semantic-ui-react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./../../styles/call_dashboard.scss";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";

const colors = [
  "#000066",
  "#470068",
  "#6f0066",
  "#900061",
  "#ac005a",
  "#c40052",
  "#d82b49",
  "#e64c3f",
  "#f06b36",
  "#f6882e",
];

function TotalCallsChart({ queryFilters }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prevQueryFilters, setPrevQueryFilters] = useState({});

  let cancelToken = null;
  const fetchData = async filterParams => {
    if (cancelToken) {
      cancelToken.cancel();
    }
    cancelToken = attachCancelTokenToNextRequest();
    const resp = await DashboardService.getTotalCallsBarChart(filterParams);
    setData(resp.data);
    setLoading(false);
  };

  useEffect(() => {
    const filters = omit(queryFilters, "tab");
    delete filters["sort_dir"];
    delete filters["sort_by"];
    if (isEqual(filters, prevQueryFilters)) {
      return;
    }
    setLoading(true);
    fetchData(filters);
    setPrevQueryFilters(filters);
  }, [queryFilters]);

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="4 2 4" />
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="num_dials" fill={colors[0]} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

TotalCallsChart.propTypes = { config: PropTypes.any };

export default TotalCallsChart;

import React, { Component } from "react";
import { Grid, Progress } from "semantic-ui-react";
import PropTypes from "prop-types";

export default class UploadProgressBar extends Component {
  static propTypes = {
    uploads: PropTypes.array,
  };

  static defaultProps = {
    uploads: [],
    validation: false,
  };

  render = () => {
    return (
      <div style={{ marginTop: "1em" }}>
        {this.props.uploads.map(upload => {
          return (
            <Progress
              key={upload.id}
              progress
              size={"small"}
              label={upload.file.name}
              color={upload.percent < 100 ? "blue" : "green"}
              indicating={upload.percent < 100}
              percent={upload.percent}
            />
          );
        })}
      </div>
    );
  };
}

import React from "react";
import { Link } from "react-router-dom";
import "react-table-6/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import pLimit from "p-limit";

import { SessionSummary, StartDialSessionButton } from "./SessionContacts";
import BaseTable from "components/BaseTable";
import { Dropdown, Container, Segment } from "semantic-ui-react";
import { getSession, setSession } from "helpers/sessionHelpers";
import AuthService from "services/Auth";
import DialSessionService from "services/DialSessions";
import "./DialSessionsTable.scoped.scss";
import CustomDropdown from "components/CustomDropdown";
import _DialSessionModal from "./DialSessionModal";
import _DeleteDialSessionModal from "./DeleteDialSessionModal";
import ACL_RELATIONSHIPS from "acl-relationships";
import withRoleCheck from "components/hocs/withRoleCheck";
import setPageTitle from "helpers/title";
import CONSTANTS from "constants/Constants";
import CampaignService from "services/Campaign";
import EmailUrlService from "services/EmailUrls";

const DialSessionModal = withRoleCheck(_DialSessionModal, [
  ACL_RELATIONSHIPS.dialSession.create,
  ACL_RELATIONSHIPS.voicemailForFilters.read,
  ACL_RELATIONSHIPS.emailUrlForms.read,
  ACL_RELATIONSHIPS.campaigns.read,
  ACL_RELATIONSHIPS.tag.read,
  ACL_RELATIONSHIPS.user.read,
  ACL_RELATIONSHIPS.campaignStatuses.read,
]);
const EditDialSessionModal = withRoleCheck(_DialSessionModal, [
  ACL_RELATIONSHIPS.dialSession.edit,
  ACL_RELATIONSHIPS.voicemailForFilters.read,
  ACL_RELATIONSHIPS.emailUrlForms.read,
  ACL_RELATIONSHIPS.campaigns.read,
  ACL_RELATIONSHIPS.tag.read,
  ACL_RELATIONSHIPS.user.read,
  ACL_RELATIONSHIPS.campaignStatuses.read,
]);
const CloneDialSessionModal = withRoleCheck(_DialSessionModal, [
  ACL_RELATIONSHIPS.dialSession.edit,
  ACL_RELATIONSHIPS.voicemailForFilters.read,
  ACL_RELATIONSHIPS.emailUrlForms.read,
  ACL_RELATIONSHIPS.campaigns.read,
  ACL_RELATIONSHIPS.tag.read,
  ACL_RELATIONSHIPS.user.read,
  ACL_RELATIONSHIPS.campaignStatuses.read,
]);
const DeleteDialSessionModal = withRoleCheck(_DeleteDialSessionModal, [
  ACL_RELATIONSHIPS.dialSession.delete,
]);

class DialSessionsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = DialSessionService.getSessions;

    const statusOptions = Object.keys(CONSTANTS.STATUS_TYPES).map(key => ({
      id: CONSTANTS.STATUS_TYPES[key],
      name: key.charAt(0) + key.substring(1).toLowerCase(),
    }));

    this.state = {
      ...this.state,
      className: "DialSessions",
      header: "Dial Sessions",
      headerIcon: "call",
      tableName: "dialSessions",
      noDataText: "No Sessions found. Try adjusting your filters.",
      enableTags: false,
      createButton: <DialSessionModal button onSuccess={this.fetchData} />,
      activeSession: getSession(),
      actionDisabled: true,
      temporaryDisabled: false,
      statusOptions,
      questionnaireOptions: [],
      campaignOptions: [],
      dialSessionOptions: [],
      emailUrlOptions: [],
      defaultQueryParams: {
        status: true,
      },
    };
  }

  async componentDidMount() {
    setPageTitle("Dial Sessions");
    window.addEventListener("storage", this.storageUpdated);
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchDialSessions();
      this.fetchQuestionnaires();
      this.updateFilterOptions();
    }
  }

  storageUpdated = event => {
    const sessionId = getSession();
    this.setState({ activeSession: sessionId });

    if (event.key === "active-session") {
      this.fetchData();
    }
  };

  deleteSession = async sessionId => {
    await DialSessionService.deleteSession(sessionId);
    this.fetchData();
  };

  setActiveSession = sessionId => {
    if (sessionId !== null) {
      setSession(sessionId);
    }
  };

  setTemporaryDisable = () => {
    this.setState({ temporaryDisabled: true });
    setTimeout(() => {
      this.setState({ temporaryDisabled: false });
    }, 3000);
  };

  updateSessionStats = async (dialSessionId, index, rows) => {
    const row = rows[index];
    const summaryStats = await DialSessionService.getSessionSummary(
      dialSessionId
    );
    row.total_contacts = summaryStats.total_contacts;
    row.urgent_contacts = summaryStats.urgent_contacts;
    row.urgent_callable_contacts = summaryStats.urgent_callable_contacts;
    row.callable_contacts = summaryStats.callable_contacts;
    rows[index] = row;
    this.setState(rows);
  };

  fetchSummaryStats = async () => {
    this.setState({ actionDisabled: true });
    const rows = this.state.rows;
    if (rows.length) {
      const limit = pLimit(3);
      const input = rows.map((row, index) =>
        limit(this.updateSessionStats, row.id, index, rows)
      );
      await Promise.all(input);
    }
    this.setState({ actionDisabled: false });
  };

  fetchDialSessions = async () => {
    const dialSessionOptions = (
      await DialSessionService.getDialSessionsForFilters()
    ).map(dialSession => ({ id: dialSession.id, name: dialSession.text }));
    this.setState({ dialSessionOptions }, this.updateFilterOptions);
  };

  fetchQuestionnaires = async () => {
    const questionnaireOptions = (await EmailUrlService.getForms()).map(
      form => ({ id: form.id, name: form.name })
    );
    this.setState({ questionnaireOptions }, this.updateFilterOptions);
  };

  setColumns = () => {
    if (!this.state.isLoading) {
      this.fetchSummaryStats();
    }

    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 80,
        Cell: props => <div>{props.value}</div>,
      },
      {
        Header: "Campaign Name & Session",
        accessor: "name",
        width: 300,
        Cell: props => (
          <div className="column-cell">
            <Link to={"/campaigns/" + props.original.campaign_id}>
              {props.original.campaign_name}
            </Link>
            <p>{props.value}</p>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        width: 80,
        Cell: props => (props.value ? <div>Active</div> : <div>Inactive</div>),
      },
      {
        Header: "Questionnaire",
        accessor: "form_url",
        width: 120,
        Cell: props => (
          <a
            href={props.original.form_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.original.form_url}
          </a>
        ),
      },
      {
        Header: "Contacts",
        accessor: "contacts",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <SessionSummary
            dialSessionId={props.original.id}
            totalContacts={props.original.total_contacts}
            callableContacts={props.original.callable_contacts}
            urgentContacts={props.original.urgent_contacts}
            urgentCallableContacts={props.original.urgent_callable_contacts}
            loading={props.original.total_contacts === undefined}
          />
        ),
      },
      {
        Header: "Actions",
        id: "actions",
        width: 200,
        className: "action-menu",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <>
            <StartDialSessionButton
              id={props.original.id}
              callSessionsDisabled={
                this.state.activeSession !== null ||
                !props.original.status ||
                this.state.temporaryDisabled
              }
              setActiveSession={this.setActiveSession}
              temporaryDisabled={this.state.temporaryDisabled}
              setTemporaryDisable={this.setTemporaryDisable}
              callableContacts={props.original.callable_contacts}
            />
            <CustomDropdown
              disabled={this.state.actionDisabled}
              icon="ellipsis horizontal"
            >
              <Dropdown.Menu direction="left">
                <EditDialSessionModal
                  dialSessionId={props.original.id}
                  campaignId={props.original.campaign_id}
                  onSuccess={this.fetchData}
                />
                <DeleteDialSessionModal
                  menuTrigger
                  onConfirmDelete={() => this.deleteSession(props.original.id)}
                />
                <CloneDialSessionModal
                  dialSessionId={props.original.id}
                  campaignId={props.original.campaign_id}
                  isClone={true}
                  onSuccess={this.fetchData}
                />
              </Dropdown.Menu>
            </CustomDropdown>
          </>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  updateFilterOptions = async () => {
    const { statusOptions, questionnaireOptions, dialSessionOptions } =
      this.state;
    const filters = [
      {
        key: "campaign_id",
        title: "Campaign",
        type: "ruvixxSelect",
        queryFn: CampaignService.getCampaignsForFilters,
      },
      {
        key: "dial_session_id",
        title: "Dial Sessions",
        type: "select",
        data: dialSessionOptions,
      },
      {
        key: "form_id",
        title: "Questionnaire",
        type: "select",
        data: questionnaireOptions,
      },
      {
        key: "status",
        title: "Status",
        type: "select",
        data: statusOptions,
        multiple: false,
      },
    ];
    this.setState({ filters });
  };

  render() {
    return (
      <Container className="route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default DialSessionsTable;

import React from "react";

const itemColors = {
  item: "rgb(24, 113, 187)",
  error: "red",
  warning: "rgb(255, 122, 0)",
  empty: "rgb(207, 207, 207)",
};

const SummaryItem = ({ title, item, color = "item" }) => {
  return (
    <div className="summary-circle">
      <p>{title}</p>
      <div style={{ backgroundColor: itemColors[color] }}>
        <p>{item}</p>
      </div>
    </div>
  );
};

export default SummaryItem;

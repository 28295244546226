import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Form,
  Segment,
  Container,
  Grid,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";
import EmailFooterService from "../../services/EmailFooters";
import EmailTemplateService from "../../services/EmailTemplate";
import ReactHtmlParser from "react-html-parser";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import { checkIsAuthorized } from "../../components/helpers";
import setPageTitle from "../../helpers/title";

function EmailFooter({ id }) {
  const params = useParams();
  const [footerName, setFooterName] = useState("");
  const [footerHtml, setFooterHtml] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [templateOptions, setTemplateOptions] = useState([]);
  const [templateHtml, setTemplateHtml] = useState("");
  const [previewHtml, setPreviewHtml] = useState("");

  const navigate = useNavigate();

  const canEditEmailFooter = checkIsAuthorized([
    !!id
      ? ACL_RELATIONSHIPS.emailFooters.edit
      : ACL_RELATIONSHIPS.emailFooters.create,
  ]);

  const handleSubmit = async () => {
    if (!canEditEmailFooter) return;
    if (footerName !== "" && footerHtml !== "") {
      try {
        await EmailFooterService.editEmailFooter(
          params.id,
          footerName,
          footerHtml
        );
        navigate("/settings/email_footers");
      } catch (error) {
        setError(true);
        setErrorMessage(error.message);
      }
    }
  };

  const handleChange = (e, data) => {
    if (!canEditEmailFooter) return;
    if (data.name === "email_footer_name") {
      setFooterName(data.value);
    } else {
      setFooterHtml(data.value);
    }
  };

  const fetchFooter = async footer_id => {
    let footer = await EmailFooterService.getEmailFooter(footer_id);

    setFooterName(footer.name);
    setPageTitle(footer.name);
    setFooterHtml(decodeHtml(footer.html));
  };

  const fetchTemplates = async () => {
    let templates = await EmailTemplateService.getEmailTemplatesForFilters();
    let templateOptions = templates.map(template => ({
      key: template.id,
      value: template.id,
      text: template.name,
      html: template.html,
    }));
    setTemplateOptions(templateOptions);
  };

  const fetchTemplateHtml = async template_id => {
    let template = await EmailTemplateService.getEmailTemplate(template_id);
    let unescaped_template = decodeHtml(template.html);
    setTemplateHtml(unescaped_template);
  };

  const decodeHtml = html => {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const combinedTemplate = (template, footer) => {
    let before = template.indexOf("</body>");
    let combined =
      template.substring(0, before) + footer + template.substring(before);
    setPreviewHtml(combined);
  };

  const handleTemplateChange = (e, data) => {
    fetchTemplateHtml(data.value);
  };

  useEffect(() => {
    fetchFooter(params.id);
  }, [params]);

  useEffect(() => {
    fetchTemplates();
  }, [id]);

  useEffect(() => {
    combinedTemplate(templateHtml, footerHtml);
  }, [footerHtml]);

  useEffect(() => {
    combinedTemplate(templateHtml, footerHtml);
  }, [templateHtml]);

  return (
    <Container fluid className="route">
      <Grid className="bg list-grid" divided>
        <Grid.Row>
          <Grid.Column className="sixteen wide">
            <Breadcrumb style={{ margin: "1em 0 0 1em" }}>
              <Breadcrumb.Section>
                <Link to="/settings/email_footers">Email Footers</Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right chevron" />
              <Breadcrumb.Section active>{footerName}</Breadcrumb.Section>
            </Breadcrumb>
            <Header>
              <Icon name="envelope" />
              Edit Email Footer
            </Header>
            <Segment>
              <Form onSubmit={handleSubmit} error={error}>
                <Form.Input
                  fluid
                  name="email_footer_name"
                  label="Name"
                  value={footerName}
                  onChange={handleChange}
                  required
                  className={`${!canEditEmailFooter && "permission-disabled"}`}
                />
                <Form.Select
                  required
                  label="Choose a Preview Template"
                  name="templateId"
                  selection
                  options={templateOptions}
                  onChange={handleTemplateChange}
                  className={`${!canEditEmailFooter && "permission-disabled"}`}
                />
                <Form.TextArea
                  name="email_footer_html"
                  label="Custom HTML"
                  style={{ minWidth: "100%" }}
                  rows={4}
                  value={footerHtml}
                  onChange={handleChange}
                  required
                  className={`${!canEditEmailFooter && "permission-disabled"}`}
                />
                <label>HTML Preview</label>
                <Segment>{ReactHtmlParser(previewHtml)}</Segment>
                <Message
                  error
                  header="Action Forbidden"
                  content={errorMessage}
                />
                {canEditEmailFooter && (
                  <Form.Button size="tiny" type="submit">
                    Submit
                  </Form.Button>
                )}
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default EmailFooter;

import React from "react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import { Container, Dropdown, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import AudioElement from "../../../components/AudioElement";

import BaseTable from "../../../components/BaseTable";
import CustomDropdown from "../../../components/CustomDropdown";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import setPageTitle from "../../../helpers/title";
import AuthService from "../../../services/Auth";
import VoicemailService from "../../../services/Voicemails";

import "../../../styles/campaign.scss";
import "../../../styles/table.scss";
import _DeleteVoicemailModal from "./DeleteVoicemailModal";
import _VoicemailModal from "./VoicemailModal";

const VoicemailModal = withRoleCheck(_VoicemailModal, [
  ACL_RELATIONSHIPS.voicemail.create,
]);
const EditVoicemailModal = withRoleCheck(_VoicemailModal, [
  ACL_RELATIONSHIPS.voicemail.edit,
]);
const DeleteVoicemailModal = withRoleCheck(_DeleteVoicemailModal, [
  ACL_RELATIONSHIPS.voicemail.delete,
]);

class VoicemailsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = VoicemailService.getVoicemails;

    this.state = {
      ...this.state,
      enableSearch: false,
      header: "Voicemails",
      headerIcon: "file audio",
      className: "Voicemail",
      tableName: "voicemails",
      noDataText: "No Voicemails found. Create one.",
      createButton: <VoicemailModal button onSuccess={this.fetchData} />,
      playingId: undefined,
    };
  }

  async componentDidMount() {
    setPageTitle("Voicemails");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  deleteVoicemail = async id => {
    await VoicemailService.deleteVoicemail(id);
    this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "File",
        sortable: false,
        headerClassName: "non-sortable",
        accessor: "attachment.url",
        minWidth: 310,
        Cell: props => (
          <AudioElement id={`audio-el-${props.index}`} src={props.value} wide />
        ),
      },
      {
        Header: "Shared",
        accessor: "is_shared",
        Cell: props => <p>{props.value ? "Yes" : "No"}</p>,
      },
      {
        Header: "Actions",
        id: "actions",
        width: 60,
        className: "action-menu",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditVoicemailModal
                voicemailId={props.original.id}
                onSuccess={this.fetchData}
              />
              <DeleteVoicemailModal
                menuTrigger
                onConfirmDelete={() => this.deleteVoicemail(props.original.id)}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route" {...this.props}>
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default VoicemailsTable;

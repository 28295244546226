import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Confirm } from "semantic-ui-react";

const UpdateExistingTagsModal = forwardRef(
  ({ onSubmit: submitParentModal }, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
    }));

    const handleOpen = () => {
      setOpen(true);
    };

    const handleSubmit = async updateExistingTags => {
      await submitParentModal(updateExistingTags);
      setOpen(false);
    };

    return (
      <Confirm
        className="confirm"
        open={open}
        onCancel={() => handleSubmit(false)}
        onConfirm={() => handleSubmit(true)}
        size="tiny"
        header="Update Existing"
        content="Update previously tagged records with new default value(s)?"
        cancelButton="No"
        confirmButton="Yes"
      />
    );
  }
);

export default UpdateExistingTagsModal;

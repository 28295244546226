import React from "react";
import { Container, Segment, Dropdown } from "semantic-ui-react";
import BaseTable from "../../components/BaseTable";
import CampaignStatusPresetModal from "./components/CampaignStatusPresetModal";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import AuthService from "../../services/Auth";
import CampaignStatusPresetService from "../../services/CampaignStatusPreset";
import CustomDropdown from "../../components/CustomDropdown";
import _DeleteCampaignStatusPresetModal from "./components/DeleteCampaignStatusPresetModal";
import setPageTitle from "../../helpers/title";

const NewCampaignStatusPresetModal = withRoleCheck(CampaignStatusPresetModal, [
  ACL_RELATIONSHIPS.campaignStatusPreset.create,
  ACL_RELATIONSHIPS.campaignStatuses.read,
]);

const EditCampaignStatusPresetModal = withRoleCheck(CampaignStatusPresetModal, [
  ACL_RELATIONSHIPS.campaignStatusPreset.edit,
  ACL_RELATIONSHIPS.campaignStatuses.read,
]);

const DeleteCampaignStatusPresetModal = withRoleCheck(
  _DeleteCampaignStatusPresetModal,
  [ACL_RELATIONSHIPS.campaignStatusPreset.delete]
);

class CampaignsStatusPresetsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod =
      CampaignStatusPresetService.getCampaignStatusPresetsAsTable;

    this.state = {
      ...this.state,
      header: "Campaign Status Presets",
      headerIcon: "bullseye",
      tableName: "campaign_status_presets",
      noDataText: "No presets found. Try adjusting your filters.",
      createButton: (
        <NewCampaignStatusPresetModal fetchPresets={this.fetchData} />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Campaign Status Presets");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
      },
      {
        Header: "Name",
        accessor: "name",
        width: 250,
      },
      {
        Header: "Campaign Statuses",
        accessor: "info.campaign_statuses",
        sortable: false,
        Cell: ({ value }) =>
          value && value.length > 0
            ? value.map(({ name }) => name).join(", ")
            : "--",
      },
      {
        Header: "Actions",
        id: "actions",
        width: 120,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ original: preset }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditCampaignStatusPresetModal
                preset={preset}
                fetchPresets={this.fetchData}
              />
              <DeleteCampaignStatusPresetModal
                preset={preset}
                fetchPresets={this.fetchData}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default CampaignsStatusPresetsTable;

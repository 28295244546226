import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import Constants from "../../../constants/Constants";

const PermissionForm = ({ filters, onFilter, onSuccess }) => {
  const [route, setRoute] = useState(filters.path || "");
  const [method, setMethod] = useState(filters.method || []);
  const [enabled, setEnabled] = useState(filters.is_allowed);

  const handleSubmit = () => {
    const newPermission = {
      path: route,
      method,
      is_allowed: enabled,
    };
    onFilter(newPermission);
  };

  return (
    <RuvixxForm ready onSubmit={handleSubmit} onSuccess={onSuccess}>
      <Form.Input
        inline
        name="route"
        label="Route"
        value={route}
        onChange={(_, { value }) => setRoute(value)}
      />
      <Form.Select
        multiple
        inline
        search
        name="method"
        label="Method"
        options={Constants.HTTP_METHODS.map(method => ({
          key: method,
          text: method.toUpperCase(),
          value: method,
        }))}
        value={method}
        onChange={(_, { value }) => setMethod(value)}
      />
      <Form.Select
        inline
        clearable
        name="enabled"
        label="Enabled"
        options={[
          { key: "Yes", text: "Yes", value: true },
          { key: "No", text: "No", value: false },
        ]}
        value={enabled}
        onChange={(_, { value }) =>
          setEnabled(value === "" ? undefined : value)
        }
      />
    </RuvixxForm>
  );
};

export default PermissionForm;

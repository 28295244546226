import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Header, Modal } from "semantic-ui-react";
import SequenceStepForm from "./SequenceStepForm";

const StepModal = forwardRef(({ sequence, onSuccess }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(null);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = (stepIndex = null) => {
    setStepIndex(stepIndex);
    setModalOpen(true);
  };

  const handleClose = () => setModalOpen(false);

  const handleSuccess = async () => {
    handleClose();
    onSuccess();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="large"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <Header content={stepIndex !== null ? "Edit Step" : "New Step"} />
      <Modal.Content>
        <SequenceStepForm
          sequence={sequence}
          stepIndex={stepIndex}
          onSuccess={handleSuccess}
        />
      </Modal.Content>
    </Modal>
  );
});

export default StepModal;

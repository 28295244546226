import React from "react";
import {
  Checkbox,
  Container,
  Dropdown,
  List,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import moment from "moment";

import "./../../styles/campaign.scss";
import "./../../styles/table.scss";
import CampaignService from "../../services/Campaign";

import EntityService from "../../services/Entity";
import UserService from "../../services/User";
import BaseTable from "../../components/BaseTable";
import CustomDropdown from "../../components/CustomDropdown";
import AuthService from "../../services/Auth";
import {
  DeleteEntityModal as _DeleteEntityModal,
  EditEntityModal as _EditEntityModal,
  NewEntityModal as _NewEntityModal,
} from "./components/Modals";
import TagList from "../../components/TagList";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import { checkIsAuthorized } from "../../components/helpers";
import _MergeEntitiesModal from "./components/MergeEntitiesModal";
import setPageTitle from "../../helpers/title";
import ListTableColumn from "../../components/ListTableColumn";
import { getCountryByCode } from "helpers/countries";
import RegionService from "services/Regions";

const NewEntityModal = withRoleCheck(_NewEntityModal, [
  ACL_RELATIONSHIPS.entity.create,
  ACL_RELATIONSHIPS.user.read,
]);
const EditEntityModal = withRoleCheck(_EditEntityModal, [
  ACL_RELATIONSHIPS.entity.edit,
  ACL_RELATIONSHIPS.user.read,
]);
const DeleteEntityModal = withRoleCheck(_DeleteEntityModal, [
  ACL_RELATIONSHIPS.entity.delete,
]);

const MergeEntitiesModal = withRoleCheck(_MergeEntitiesModal, [
  ACL_RELATIONSHIPS.entityMerge.create,
]);

class EntitiesTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = EntityService.getEntities;

    this.state = {
      ...this.state,
      header: "Entities",
      headerIcon: "building",
      className: "Entity",
      tableName: "entities",
      noDataText: "No Entities found. Try adjusting your filters.",
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.entityTags.create,
        ACL_RELATIONSHIPS.entityTags.read,
        ACL_RELATIONSHIPS.entityTags.delete,
      ]),
      enableMerge: checkIsAuthorized([ACL_RELATIONSHIPS.entityMerge.create]),
      users: [],
      regions: [],
      countries: [],
      createButton: <NewEntityModal fetchEntities={this.fetchData} />,
      enableCustomFieldsSettings: true,
      exportTableName: "EntitiesTable",
    };
  }

  async componentDidMount() {
    setPageTitle("Entities");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchUsers();
      this.fetchTags();
      this.fetchCustomFields();
      this.fetchRegions();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => (
          <Link to={"/entities/" + props.value}>{props.value}</Link>
        ),
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: props => (
          <Link to={"/entities/" + props.original.id}>{props.value}</Link>
        ),
      },
      {
        Header: "Entity Alternate Names",
        accessor: "alternate_names",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ value }) => (
          <ListTableColumn
            itemKey={"id"}
            itemField={"name"}
            data={value || []}
          />
        ),
      },
      {
        Header: "URLs",
        accessor: "urls",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return (
              <List horizontal bulleted>
                {value.map(url => (
                  <List.Item>{url}</List.Item>
                ))}
              </List>
            );
          }
          return null;
        },
      },
      {
        Header: "Account Manager",
        accessor: "account_managers",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <List horizontal bulleted>
            {props.value.map(manager => (
              <List.Item key={manager.user_id}>
                {manager.user_full_name}
              </List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Last Activity Date",
        accessor: "last_activity_date",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return null;
        },
      },
      {
        Header: "Campaigns",
        accessor: "campaigns",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ value }) => (
          <List horizontal bulleted style={{ display: "flex" }}>
            {value.map(({ id: campaign_id, name: campaign_name }) => (
              <List.Item>
                <Link to={`/campaigns/${campaign_id}`}>{campaign_name}</Link>
              </List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Region",
        accessor: "region_name",
      },
      {
        Header: "Country",
        accessor: "country",
        Cell: ({ value }) => getCountryByCode(value)?.text,
      },
      {
        Header: "Actions",
        id: "actions",
        width: 140,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ original: { id } }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditEntityModal entityId={id} fetchEntities={this.fetchData} />
              <DeleteEntityModal
                onConfirmDelete={() => this.deleteEntity(id)}
                menuTrigger
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  deleteEntity = async id => {
    await EntityService.deleteEntity(id);
    this.fetchData();
  };

  fetchCustomFields = async () => {
    const customFields = await EntityService.getCustomFields();
    this.setState(
      {
        customFields: [
          {
            accessorPrefix: "custom_fields",
            fields: customFields,
            model: "Entity",
          },
        ],
      },
      this.updateFilterOptions
    );
  };

  fetchRegions = async () => {
    const rawRegions = await RegionService.getRegions();
    const regions = rawRegions.map(({ id, name, countries }) => ({
      id,
      name,
      extraData: { countries },
    }));

    const allCountries = rawRegions.reduce((countries, region) => {
      countries.push(...region.countries);
      return countries;
    }, []);
    allCountries.sort();
    const countries = Array.from(new Set(allCountries)).map(country => ({
      id: country,
      name: getCountryByCode(country).text,
      value: country,
      key: country,
    }));
    this.setState({ regions, countries }, this.updateFilterOptions);
  };

  fetchUsers = async () => {
    const rawUsers = await UserService.getUsers();
    const users = await rawUsers.map(user => ({
      id: user.id,
      name: user.full_name,
    }));

    this.setState({ users }, this.updateFilterOptions);
  };

  mergeModal = () => {
    const { mergeItems } = this.state;
    return (
      <MergeEntitiesModal
        entities={mergeItems}
        finishMerge={this.finishMerge}
      />
    );
  };

  updateFilterOptions = async () => {
    const { tags, customFields, regions, countries } = this.state;

    let filters = [
      {
        key: "last_activity_date",
        title: "Last Activity",
        type: "dateRange",
      },
      {
        key: "search:entity_url",
        title: "Entity Url",
        type: "input",
      },
      {
        key: "search:contact_name",
        title: "Contact Name",
        type: "input",
      },
      {
        key: "search:contact_email",
        title: "Contact Email",
        type: "input",
      },
      {
        key: "search:contact_phone",
        title: "Contact Phone",
        type: "input",
      },
      {
        key: "campaign_ids",
        title: "Campaign",
        type: "ruvixxSelect",
        queryFn: CampaignService.getCampaignsForFilters,
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "account_manager_id",
        title: "Account Manager",
        type: "ruvixxSelect",
        queryFn: UserService.getUsers,
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "assigned_user_id",
        title: "Assignee",
        type: "ruvixxSelect",
        queryFn: UserService.getUsers,
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
      {
        key: "entity_id",
        title: "Entity",
        type: "ruvixxSelect",
        queryFn: EntityService.getEntitiesForFilters,
      },
      {
        key: "regions",
        title: "Region",
        type: "select",
        data: regions,
      },
      {
        key: "countries",
        title: "Country",
        type: "select",
        data: countries,
      },
    ];
    const defaultFilterList = filters.map(f => f.title);
    customFields.forEach(cf =>
      cf.fields.forEach(field => {
        if (!defaultFilterList.includes(field)) {
          filters.push({
            key: `search:custom:${cf.model}:${field}`,
            title: field,
            type: "input",
            model: cf.model,
          });
        }
      })
    );
    this.setState({ filters });
  };

  render() {
    return (
      <Container fluid className="route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default EntitiesTable;

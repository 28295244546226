import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "semantic-ui-react";
import UserForm from "./UserForm";

const UserModal = forwardRef(({ onSuccess }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useState();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = user => {
    setUser(user);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = () => {
    handleClose();
    onSuccess();
  };

  const title = `${!user ? "Add New" : "Edit"} User`;

  return (
    <Modal
      size="small"
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      className="user-modal"
    >
      <Modal.Header content={title} />
      <Modal.Content>
        <UserForm
          user={user}
          onSuccess={handleSuccess}
          onCancel={handleClose}
          submitButtonText={title}
        />
      </Modal.Content>
    </Modal>
  );
});

export default UserModal;

import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Modal, Header, Button, Form } from "semantic-ui-react";
import UserService from "../../services/User";
import RuvixxForm from "../RuvixxForm";

const SaveSearchParamsModal = forwardRef(
  ({ loadSearchParams, ...formProps }, ref) => {
    const [modalOpen, setModalOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
    }));

    const handleOpen = () => {
      setModalOpen(true);
    };

    const handleClose = () => {
      setModalOpen(false);
    };

    const handleSuccess = () => {
      loadSearchParams();
      handleClose();
    };

    return (
      <Modal open={modalOpen} onClose={handleClose} size="tiny" closeIcon>
        <Header content="Save Search" />
        <Modal.Content>
          <SaveSearchParamsForm {...formProps} onSuccess={handleSuccess} />
        </Modal.Content>
      </Modal>
    );
  }
);

const SaveSearchParamsForm = ({
  tableName,
  searchParams,
  filterParams,
  tagsFilters,
  customFilters,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async () => {
    const newSearchParams = [
      ...searchParams,
      {
        name,
        description,
        state: {
          filterParams,
          tagsFilters,
          customFilters,
        },
      },
    ];
    await UserService.updateUserTableSettings(
      tableName,
      "search_params",
      newSearchParams
    );
    onSuccess();
  };

  return (
    <RuvixxForm ready={!!name} onSubmit={handleSubmit} submitButtonText="Save">
      <Form.Input
        inline
        required
        label="Name"
        autoFocus
        value={name}
        onChange={(_, { value }) => setName(value)}
      />
      <Form.Input
        inline
        label="Description"
        value={description}
        onChange={(_, { value }) => setDescription(value)}
      />
    </RuvixxForm>
  );
};

const SaveSearchParamsButton = ({ modalRef }) => (
  <Button
    primary
    icon="magnify"
    size="tiny"
    content="Save Search"
    onClick={() => modalRef.current.open()}
  />
);

SaveSearchParamsModal.Button = SaveSearchParamsButton;
export default SaveSearchParamsModal;

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form, Icon, Table, Divider, Header } from "semantic-ui-react";

import AlternateNameView from "../../../components/AlternateName";
import Addresses from "../../../components/forms/addresses/Addresses";
import CustomFieldForm from "../../../components/forms/CustomFieldForm";
import {
  checkIsCustomFieldsReady,
  getCustomFieldsForSubmit,
  keyIsUnique,
} from "../../../components/helpers";
import RuvixxForm from "../../../components/RuvixxForm";
import CustomFieldService from "../../../services/CustomField";
import EntityService from "../../../services/Entity";
import UserService from "../../../services/User";
import { AppendContactModal } from "../components/Modals";
import RegionService from "services/Regions";
import { getCountryByCode } from "helpers/countries";

const ContactsTable = props => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Full Name</Table.HeaderCell>
          <Table.HeaderCell>Preferred Name</Table.HeaderCell>
          <Table.HeaderCell>Email Addresses</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.contacts.map((contact, index) => (
          <Table.Row key={contact.email}>
            <Table.Cell collapsing>
              <Icon name="user" />
            </Table.Cell>
            <Table.Cell>{contact.full_name}</Table.Cell>
            <Table.Cell>{contact.preferred_name}</Table.Cell>
            <Table.Cell>
              {contact.email_addresses.map(e =>
                e.is_primary ? `${e.email}*` : e.email
              )}
            </Table.Cell>
            <Table.Cell collapsing>
              <Icon onClick={() => props.removeContact(index)} name="remove" />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

class NewEntityForm extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      url: "",
      externalCcEmails: "",
      accountManagers: [],
      users: [],
      addresses: [],
      contacts: [],
      custom: [],
      customFieldConfigs: [],
      alternateNames: [],
      regionId: null,
      regionsOptions: [],
      regions: [],
      country: null,
      countriesOptions: [],
      primaryAddressId: null,
    };
  }

  fetchUsers = async () => {
    const res = await UserService.getUsers();
    const users = res.map(user => ({
      key: user.id,
      value: user.id,
      text: user.full_name,
    }));
    this.setState({ users });
  };

  fetchCustomFieldConfigs = async () => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel("Entity");
    this.setState({ customFieldConfigs: customFieldConfigs });
  };

  handleUpdateCustomFieldConfigs = customFieldConfigs => {
    this.setState({ customFieldConfigs });
    this.handleUpdateCustomFields(
      customFieldConfigs
        .filter(cfc => cfc.required || cfc.selected)
        .map(cfc => ({ key: cfc.label, value: cfc.value }))
    );
  };
  handleUpdateCustomFields = customFields => {
    this.setState({ custom: customFields });
  };

  handleSubmit = async e => {
    const {
      name,
      urls,
      externalCcEmails,
      addresses,
      contacts,
      custom,
      accountManagers,
      alternateNames,
      customFieldConfigs,
      regionId,
      country,
    } = this.state;
    const cust = getCustomFieldsForSubmit(customFieldConfigs, custom);
    let urls_array = urls ? urls.replace(/\s+/g, "").split(",") : [];
    await EntityService.createEntity(
      name,
      urls_array,
      externalCcEmails,
      addresses,
      contacts,
      cust,
      accountManagers,
      alternateNames,
      regionId,
      country
    );
  };

  isUnique = key => {
    return keyIsUnique({
      key: key,
      array: this.state.custom,
    });
  };

  removeContact = index => {
    let array = this.state.contacts;
    array.splice(index, 1);
    this.setState({
      contacts: array,
    });
  };

  handleChange = (_, { name, value }) => {
    this.setState({ [name]: value });
  };

  addContact = contact => {
    let contacts = this.state.contacts;
    contacts.push(contact);
    this.setState({
      contacts: contacts,
    });
  };

  setAlternateNames = alternateNames => {
    this.setState({ alternateNames });
  };

  prepareRegionsSelect = async () => {
    const regions = await RegionService.getRegions();
    const options = regions.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
    this.setState({ regionsOptions: options, regions });
  };

  prepareCountriesSelect = async regionId => {
    const region = await RegionService.getRegion(regionId);
    const options = region?.countries.map(code => ({
      key: code,
      value: code,
      text: getCountryByCode(code).text,
    }));

    const updates = { countriesOptions: options };
    if (region?.countries.indexOf(this.state.country) < 0) {
      updates.country = null;
    }
    this.setState(updates);
  };

  updateLocationOptions = () => {
    if (!this.state.regionId) {
      this.setState({ country: null, countriesOptions: [] });
    } else {
      this.prepareCountriesSelect(this.state.regionId);
    }
  };

  componentDidUpdate(_, prevState) {
    if (this.state.regionId !== prevState.regionId) {
      this.updateLocationOptions();
    }
  }

  componentDidMount() {
    this.fetchCustomFieldConfigs();
    this.fetchUsers(); // don't await
    this.prepareRegionsSelect();
  }

  isAddressReady = address => {
    return (
      address.street1 &&
      address.city &&
      address.region &&
      address.postal_code &&
      address.country
    );
  };

  updatePrimaryAddress = async (address, index, checked) => {
    if (!this.isAddressReady(address)) return;

    const updates = {};
    const addressesCopy = [...this.state.addresses];

    for (let i = 0; i < addressesCopy.length; i++) {
      addressesCopy[i]["is_primary"] = i === index ? checked : false;
    }
    updates.addresses = addressesCopy;

    if (this.state.primaryAddressId === address.id) {
      updates.primaryAddressId = null;
    } else {
      updates.primaryAddressId = address.id;
      const regionsFound = this.state.regions.filter(region =>
        region.countries.includes(address.country)
      );

      if (regionsFound?.length) {
        if (!this.state.country) {
          updates.country = address.country;
        }
        if (!this.state.regionId) {
          updates.regionId = regionsFound[0].id;
        }
      }
    }
    this.setState(updates);
  };

  render() {
    const {
      name,
      accountManagers,
      users,
      addresses,
      contacts,
      custom,
      customFieldConfigs,
      alternateNames,
      regionsOptions,
      regionId,
      countriesOptions,
      country,
      primaryAddressId,
    } = this.state;
    return (
      <RuvixxForm
        ready={
          !!name &&
          name.trim() !== "" &&
          checkIsCustomFieldsReady(customFieldConfigs)
        }
        onSubmit={this.handleSubmit}
        onSuccess={this.props.onSuccess}
        successHeader="Entity Created"
        successMessage={"Successfully created the " + name + " entity"}
      >
        <Form.Input
          inline
          className="fillSpace"
          name="name"
          label="Name"
          required
          onChange={this.handleChange}
        />
        <Form.Input
          inline
          className="fillSpace"
          name="urls"
          label="Urls"
          placeholder="www.domain1.com,www.domain2.com"
          onChange={this.handleChange}
        />
        <Form.Select
          inline
          className="fillSpace"
          label="Account Managers"
          clearable
          multiple
          search
          name="accountManagers"
          onChange={this.handleChange}
          options={users}
          value={accountManagers}
        />
        <Form.Input
          inline
          className="fillSpace"
          name="externalCcEmails"
          label="External CC Emails"
          placeholder="email1@test.com,email2@test.com"
          onChange={this.handleChange}
        />
        <Form.Select
          inline
          label="Region"
          name="regionId"
          search
          clearable
          options={regionsOptions}
          value={regionId}
          onChange={this.handleChange}
        />
        <Form.Select
          inline
          label="Country"
          name="country"
          disabled={!regionId}
          search
          clearable
          options={countriesOptions}
          value={country}
          onChange={this.handleChange}
        />
        <Addresses
          addresses={addresses}
          updateAddresses={newAddresses =>
            this.setState({ addresses: newAddresses })
          }
          showPrimaryColumn={true}
          primaryAddressId={primaryAddressId}
          updatePrimaryAddress={this.updatePrimaryAddress}
          showLatLong
        />
        <Divider hidden />
        <Header className="section-header sub-header">Contacts</Header>
        {contacts.length > 0 ? (
          <ContactsTable
            contacts={contacts}
            removeContact={this.removeContact}
          />
        ) : (
          <p>No Contacts</p>
        )}
        <AppendContactModal addContact={this.addContact} contacts={contacts} />
        {customFieldConfigs && (
          <Form.Field>
            <CustomFieldForm
              modelType="Entity"
              customFields={custom}
              customFieldConfigs={customFieldConfigs}
              onUpdateCustomFieldConfigs={this.handleUpdateCustomFieldConfigs}
            />
          </Form.Field>
        )}
        <Form.Field>
          <AlternateNameView
            alternateNames={alternateNames}
            setAlternateNames={this.setAlternateNames}
            type={"Entity"}
          />
        </Form.Field>
      </RuvixxForm>
    );
  }
}

export default NewEntityForm;

import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import {
  getDefaultKeyBinding,
  RichUtils,
  ContentState,
  EditorState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import createUndoPlugin from "@draft-js-plugins/undo";

import "draft-js/dist/Draft.css";
import "@draft-js-plugins/mention/lib/plugin.css";

import { v4 as uuidv4 } from "uuid";
import linkifyPlugin, { LinkifyModal } from "./plugins/linkify";
import { convertToHTML as _convertToHTML } from "draft-convert";
import Controls, { getBlockStyle } from "./Controls";

import "./RichTextEditor.scss";

import UserService from "../../services/User";

const convertToHTML = _convertToHTML({
  blockToHTML: block => {
    if (block.type === "code-block") {
      return <code />;
    }
  },
});

const RichTextEditor = forwardRef((props, ref) => {
  const {
    editorState: parentEditorState,
    onStateChange,
    onAddMention,
    readOnly,
    style,
  } = props;

  useImperativeHandle(ref, () => ({
    importState,
    importStateFromHtml,
    importStateFromPlain,
    exportState,
    exportStateAsHtml,
    exportStateAsPlain,
    resetState,
  }));

  const importState = editorState => {
    const contentState = convertFromRaw(editorState);
    setEditorStateFromContentState(contentState);
  };

  const importStateFromHtml = html => {
    const { contentBlocks, entityMap } = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    setEditorStateFromContentState(contentState);
  };

  const importStateFromPlain = text => {
    const contentState = ContentState.createFromText(text);
    setEditorStateFromContentState(contentState);
  };

  const exportState = () => {
    const contentState = editorState.getCurrentContent();
    return convertToRaw(contentState);
  };

  const exportStateAsHtml = () => {
    const contentState = editorState.getCurrentContent();
    return convertToHTML(contentState);
  };

  const exportStateAsPlain = () => {
    const contentState = editorState.getCurrentContent();
    return contentState.getPlainText();
  };

  const resetState = () => {
    const contentState = ContentState.createFromText("");
    setEditorStateFromContentState(contentState);
  };

  const setEditorStateFromContentState = contentState => {
    setNextContentState(contentState);
  };

  const editor = useRef();
  const linkifyModal = useRef();
  const [open, setOpen] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [nextContentState, setNextContentState] = useState();

  useEffect(() => {
    if (parentEditorState) {
      importState(parentEditorState);
    }
  }, [parentEditorState]);

  useEffect(() => {
    if (!nextContentState || editorState.getDecorator() === null) {
      return;
    }
    const newEditorState = EditorState.push(editorState, nextContentState);
    setNextContentState(null);
    setEditorState(newEditorState);
  }, [nextContentState, editorState]);

  useEffect(() => {
    if (onStateChange) {
      const contentState = editorState.getCurrentContent();
      const hasText = contentState.hasText();
      onStateChange({ editorState, contentState, hasText });
    }
  }, [editorState]);

  const { plugins, MentionSuggestions, UndoButton, RedoButton } =
    useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        mentionPrefix: "@",
        entityMutability: "IMMUTABLE",
      });
      const { MentionSuggestions } = mentionPlugin;

      const undoPlugin = createUndoPlugin();
      const { UndoButton, RedoButton } = undoPlugin;

      const plugins = [mentionPlugin, linkifyPlugin, undoPlugin];
      return { plugins, MentionSuggestions, UndoButton, RedoButton };
    }, []);

  const onOpenChange = useCallback(_open => {
    setOpen(_open);
  }, []);

  const onSearchMentions = useCallback(
    ({ value }) => {
      if (value) {
        UserService.search(value).then(users => {
          users = users.map(user => ({
            id: user.id,
            name: user.full_name,
          }));
          setSuggestions(users);
        });
      } else {
        UserService.getUsers().then(users => {
          users = users.map(user => ({
            id: user.id,
            name: user.full_name,
          }));
          setSuggestions(users);
        });
      }
    },
    [setSuggestions]
  );

  const focus = () => {
    if (editor.current) editor.current.focus();
  };

  const handleKeyCommand = useCallback(
    (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return "handled";
      }
      return "not-handled";
    },
    [editorState, setEditorState]
  );

  const mapKeyToEditorCommand = useCallback(
    e => {
      switch (e.keyCode) {
        case 9: // TAB
          const newEditorState = RichUtils.onTab(
            e,
            editorState,
            4 /* maxDepth */
          );
          if (newEditorState !== editorState) {
            setEditorState(newEditorState);
          }
          return null;
      }
      return getDefaultKeyBinding(e);
    },
    [editorState, setEditorState]
  );

  return (
    <>
      <div className="RichEditor-root" style={style}>
        {!readOnly && (
          <Controls
            editorState={editorState}
            setEditorState={setEditorState}
            buttons={{
              undo: UndoButton,
              redo: RedoButton,
            }}
            refs={{
              linkifyModal,
            }}
          />
        )}
        <div
          className={`RichEditor-editor ${readOnly ? "readonly" : ""}`}
          onClick={focus}
        >
          <Editor
            className="editor"
            readOnly={readOnly}
            editorKey={`editor-${uuidv4()}`}
            editorState={editorState}
            onChange={setEditorState}
            plugins={plugins}
            ref={editor}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            spellCheck
          />
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestions}
            onSearchChange={onSearchMentions}
            onAddMention={onAddMention}
          />
        </div>
      </div>
      <LinkifyModal ref={linkifyModal} />
    </>
  );
});

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
  STRIKETHROUGH: {
    textDecoration: "line-through",
  },
};

RichTextEditor.propTypes = {
  editorState: PropTypes.object,
  onStateChange: PropTypes.func,
  onAddMention: PropTypes.func,
  readOnly: PropTypes.bool,
  style: PropTypes.object,
};

RichTextEditor.defaultProps = {
  onStateChange: () => {},
  onAddMention: () => {},
  readOnly: false,
};

export default RichTextEditor;

import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { RichUtils, KeyBindingUtil, EditorState } from "draft-js";
import { getSelectionEntity } from "draftjs-utils";
import { Form, Modal } from "semantic-ui-react";
import RuvixxForm from "../../RuvixxForm";
import { isUrl } from "../../helpers";

export const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export const Link = props => {
  const { contentState, entityKey } = props;
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      className="link"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={url}
    >
      {props.children}
    </a>
  );
};

const linkifyPlugin = {
  keyBindingFn(event, { getEditorState }) {
    const editorState = getEditorState();
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      return;
    }
    if (KeyBindingUtil.hasCommandModifier(event) && event.which === 75) {
      return "add-link";
    }
  },

  handleKeyCommand(command, editorState, { setEditorState }) {
    if (command !== "add-link") {
      return "not-handled";
    }
    return onAddLink(editorState, setEditorState);
  },

  decorators: [
    {
      strategy: linkStrategy,
      component: Link,
    },
  ],
};

const onAddLink = (editorState, setEditorState, { linkifyModal }) => {
  linkifyModal.current.open(editorState, setEditorState);
  return "handled";
};

const onRemoveLink = (editorState, setEditorState) => {
  const selection = editorState.getSelection();
  const selectedKey = getSelectionEntity(editorState);
  const selectedEntity = selectedKey
    ? editorState.getCurrentContent().getEntity(selectedKey)
    : null;
  if (!selectedEntity || selectedEntity.getType() !== "LINK") {
    return "not-handled";
  }
  setEditorState(RichUtils.toggleLink(editorState, selection, null));
  return "handled";
};

const LinkifyModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState();
  const editorStateRef = useRef();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const getCurrentUrl = () => {
    const [editorState, setEditorState] = editorStateRef.current;
    const selectedKey = getSelectionEntity(editorState);
    const selectedEntity = selectedKey
      ? editorState.getCurrentContent().getEntity(selectedKey)
      : null;
    const selectedData = selectedEntity ? selectedEntity.getData() : {};
    return selectedData.url || "";
  };

  const handleOpen = (editorState, setEditorState) => {
    editorStateRef.current = [editorState, setEditorState];
    setUrl(getCurrentUrl());
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const [editorState, setEditorState] = editorStateRef.current;
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();
    const href = isUrl(url) ? url : `http://${url}`;
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: href,
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
    handleClose();
  };

  return (
    <Modal
      size="small"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <Modal.Header content="Add Link" />
      <Modal.Content>
        <RuvixxForm
          ready={!!url}
          onSubmit={handleSubmit}
          submitButtonText="ADD LINK"
        >
          <Form.Input
            label="URL"
            autoFocus
            value={url}
            onChange={(_, { value }) => setUrl(value)}
          />
        </RuvixxForm>
      </Modal.Content>
    </Modal>
  );
});

export { onAddLink, onRemoveLink, LinkifyModal };
export default linkifyPlugin;

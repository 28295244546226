import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "semantic-ui-less/semantic.less";
import * as Sentry from "@sentry/react";
import { IncomingCallProvider } from "components/IncomingCall/IncomingCallContext";
import { UserStatusProvider } from "components/UserStatus/UserStatusContext";
import { SocketProvider } from "providers/SocketProvider";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    // TODO: set user
    // Sentry.setUser({ email: "john.doe@example.com" });
    // integrations: [new BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    // tracesSampleRate: 0.1,
  });
  Sentry.setTag("domain", process.env.REACT_APP_DOMAIN || "unknown");
}

ReactDOM.render(
  <React.StrictMode>
    <SocketProvider>
      <UserStatusProvider>
        <IncomingCallProvider>
          <App />
        </IncomingCallProvider>
      </UserStatusProvider>
    </SocketProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

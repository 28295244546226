import React, { useEffect, useState } from "react";
import saveAs from "file-saver";
import {
  Document,
  Font,
  Image,
  Link,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import ActivityService from "../../services/Activity";
import { EMAIL_URL_LANGS } from "../../constants/Constants";
import { createDateTimeFormatter } from "../helpers";
import { questionTypes } from "../../constants/Question";

const styles = StyleSheet.create({
  body: {
    fontSize: 12,
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  header: {
    fontSize: 16,
    fontWeight: 700,
  },
  row: {
    marginTop: 10,
  },
  question: {
    fontWeight: 700,
  },
  answer: {
    paddingLeft: 20,
  },
  listItem: {
    display: "list-item",
  },
  tableAnswer: {
    marginTop: 5,
    paddingTop: 5,
    borderTopWidth: 0.75,
    borderTopColor: "rgb(25, 25, 25, 0.1)",
    borderTopStyle: "solid",
  },
  submittedInfo: {
    marginTop: 10,
  },
});

const BULLET = "\u2022";

const UnorderedListItem = ({ children }) => {
  return (
    <View style={styles.listItem}>
      <Text>
        {BULLET}&nbsp;<Text>{children}</Text>
      </Text>
    </View>
  );
};

const UnorderedList = ({ children }) => {
  return <View style={styles.list}>{children}</View>;
};

const formatDate = createDateTimeFormatter();

const FormResponsePDF = ({
  formResponse,
  questions,
  answers,
  language,
  contact,
}) => {
  const agentName = formResponse?.hidden?.agent_name;
  let fontFamily, regularSrc, boldSrc;
  if (language === EMAIL_URL_LANGS.THAI) {
    fontFamily = "Sarabun";
    regularSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/Sarabun/Sarabun-Regular.ttf";
    boldSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/Sarabun/Sarabun-Bold.ttf";
  } else if (language === EMAIL_URL_LANGS.JAPANESE) {
    fontFamily = "Noto Sans JP";
    regularSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/MPLUSRounded1c/MPLUSRounded1c-Regular.ttf";
    boldSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/MPLUSRounded1c/MPLUSRounded1c-Bold.ttf";
  } else {
    fontFamily = "Open Sans";
    regularSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/OpenSans/OpenSans-Regular.ttf";
    boldSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/OpenSans/OpenSans-Bold.ttf";
  }
  Font.register({
    family: fontFamily,
    format: "truetype",
    fonts: [
      {
        src: regularSrc,
      },
      {
        src: boldSrc,
        fontWeight: 700,
      },
    ],
  });

  styles.body.fontFamily = fontFamily;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header}>{formResponse.title}</Text>
        {contact ? (
          <View>
            <Text>
              {contact.full_name} ({contact.email})
            </Text>
          </View>
        ) : null}
        {questions.map((question, index) => {
          const answer = answers.find(
            answer => answer.form_question_id === question.id
          );
          return (
            <View key={index} style={styles.row}>
              <View style={styles.question}>
                <Text>{question.title}</Text>
              </View>
              <View style={styles.answer}>
                {answerByType(question, answer)}
              </View>
            </View>
          );
        })}
        <View style={styles.submittedInfo}>
          <Text>Submitted at: {formatDate(formResponse.submitted_at)}</Text>
          {agentName && <Text>Submitted by: {agentName}</Text>}
        </View>
      </Page>
    </Document>
  );
};

const answerByType = (question, answer) => {
  switch (question.question_type) {
    case questionTypes.text:
    case questionTypes.textbox:
    case questionTypes.dropdown:
    case questionTypes.radioButtonGroup:
      return answer?.info.answer ? (
        <Text>{answer?.info.answer}</Text>
      ) : (
        <Text></Text>
      );
    case questionTypes.checkboxGroup:
      return (
        <UnorderedList>
          {answer.answer !== null ? (
            answer.answer.map((x, i) => (
              <UnorderedListItem key={i}>{x}</UnorderedListItem>
            ))
          ) : (
            <Text>---</Text>
          )}
        </UnorderedList>
      );
    case questionTypes.upload:
      return <PleteoUpload activityLogId={answer.answer} />;
    default:
      return <Text>{answer.type} not yet supported in PDF generation</Text>;
  }
};

const PleteoUpload = props => {
  const [attachments, setAttachments] = useState([]);
  useEffect(() => {
    const fetchActivityLog = async () => {
      if (props.activityLogId !== null) {
        const activityLog = await ActivityService.getActivityLog(
          "Contact",
          props.activityLogId
        );
        setAttachments(activityLog.attachments);
      }
    };

    fetchActivityLog().catch(console.error);
  }, []);

  return (
    <UnorderedList>
      {attachments.map(attachment => (
        <UnorderedListItem key={attachment.id}>
          <Link src={attachment.url}>{attachment.file_name}</Link>
        </UnorderedListItem>
      ))}
    </UnorderedList>
  );
};

export const generatePdfDocument = async (
  formResponse,
  questions,
  answers,
  language,
  contact
) => {
  const blob = await pdf(
    <FormResponsePDF
      questions={questions}
      answers={answers}
      formResponse={formResponse}
      language={language}
      contact={contact}
    />
  ).toBlob();
  const filename = [
    formResponse.title,
    contact && contact.email,
    formResponse.submitted_at,
  ]
    .filter(el => !!el)
    .join("-")
    .replace(/\s/g, "_");
  saveAs(blob, `${filename}.pdf`);
};

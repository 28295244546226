import { applyFilters, doRequest } from "./helpers";

export default class CallQueueService {
  static async getContacts(filters) {
    let path = "/call_queue/items";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async addContact(
    dial_session_id,
    contact_id,
    dial_next,
    from_entity_list = false
  ) {
    return doRequest("POST", `/call_queue/add_contact`, {
      data: {
        contact_id,
        dial_session_id,
        dial_next,
        from_entity_list,
      },
    });
  }

  static async getNextContact(dialSessionId) {
    return doRequest(
      "GET",
      `/call_queue/next_contact?dial_session_id=${dialSessionId}`
    );
  }

  static async getQueueItem(dial_session_id, contact_id) {
    return doRequest("GET", `/call_queue/${dial_session_id}/${contact_id}`);
  }

  static async getAgentDashboardTable(filters) {
    let path = "/call_queue/items_with_agent_assigned";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async nextContactExists(dialSessionId) {
    return doRequest(
      "GET",
      `/call_queue/next_contact_exists?dial_session_id=${dialSessionId}`
    );
  }

  static async skipContact(data) {
    return doRequest("POST", "/call_queue/skip_contact", { data });
  }
}

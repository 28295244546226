import React, { useState } from "react";
import { Form, Button, Input, Message } from "semantic-ui-react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import PropTypes from "prop-types";

class TransferCallError {
  constructor({ title, message }) {
    this.name = "TransferCallError";
    this.title = title;
    this.message = message;
  }
}

const noError = {
  error: false,
  message: "",
  title: "",
};

const TransferCallForm = ({
  onSuccess,
  onDirectTransfer,
  startWarmTransfer,
  cancelWarmTransfer,
  completeWarmTransfer,
  transferCallData,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(
    transferCallData.phoneNumber || ""
  );
  const [err, setErr] = useState(noError);

  const handleInputChange = number => {
    setPhoneNumber(number);
    setErr(noError);
  };

  const handleSubmit = async (event, { name }) => {
    try {
      if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
        throw new TransferCallError({
          title: "Invalid Phone Number",
          message: "Please check the phone number submitted",
        });
      }
      let isFinalStep = true;
      switch (name) {
        case "directTransfer":
          await onDirectTransfer(phoneNumber);
          break;
        case "startWarmTransfer":
          await startWarmTransfer(phoneNumber);
          isFinalStep = false;
          break;
        case "cancelWarmTransfer":
          await cancelWarmTransfer();
          break;
        case "completeWarmTransfer":
          await completeWarmTransfer();
          break;
        default:
      }
      isFinalStep && (await onSuccess());
    } catch (error) {
      if (error.name === "TransferCallError") {
        setErr({
          error: true,
          message: error.message,
          title: error.title,
        });
      } else {
        throw error;
      }
    }
  };

  return (
    <>
      <Form>
        <Form.Field inline>
          <label>Number to Call</label>
          <Input>
            <PhoneInput
              placeholder="Enter Phone Number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleInputChange}
              disabled={!!transferCallData.callSid}
              autoFocus
            />
          </Input>
        </Form.Field>
        {!!transferCallData.callSid ? (
          <Form.Group>
            <Form.Field>
              <Button
                className="red"
                size="tiny"
                content="Cancel Warm Transfer"
                name="cancelWarmTransfer"
                onClick={handleSubmit}
              />
              <Button
                className="lightblue"
                size="tiny"
                content="Complete Warm Transfer"
                name="completeWarmTransfer"
                onClick={handleSubmit}
              />
            </Form.Field>
          </Form.Group>
        ) : (
          <Form.Group>
            <Form.Field>
              <Button
                className="lightblue"
                size="tiny"
                content="Transfer Directly"
                name="directTransfer"
                onClick={handleSubmit}
              />
            </Form.Field>
            <Form.Field>
              <Button
                className="lightblue"
                size="tiny"
                content="Warm Transfer"
                name="startWarmTransfer"
                onClick={handleSubmit}
              />
            </Form.Field>
          </Form.Group>
        )}
      </Form>
      {err.error && <Message error header={err.title} content={err.message} />}
    </>
  );
};

TransferCallForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default TransferCallForm;

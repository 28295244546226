import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

import Datetime from "react-datetime";
import {
  Accordion,
  Button,
  Checkbox,
  Form,
  Grid,
  Icon,
  List,
  Tab,
} from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import DraggableContainer from "../../../components/DraggableContainer";

import { compareCreatedAt } from "../../../components/helpers";
import OutreachFiltersForm from "../../../components/OutreachFiltersForm";
import RichTextEditor from "../../../components/RichEditor/RichTextEditor";
import RuvixxForm from "../../../components/RuvixxForm";
import CONSTANTS from "../../../constants/Constants";
import { startCase } from "../../../helpers/string";
import { mapSelectTagOption } from "../../../helpers/tags";
import useTags from "../../../hooks/useTags";
import CallDispositionService from "../../../services/CallDispositions";
import CampaignService from "../../../services/Campaign";
import DialSessionsService from "../../../services/DialSessions";
import EmailTemplateService from "../../../services/EmailTemplate";
import EmailUrlService from "../../../services/EmailUrls";
import UserService from "../../../services/User";
import VoicemailService from "../../../services/Voicemails";

import "./DialSessionForm.scoped.scss";

const CALL_WINDOW_START_DEFAULT = 9;
const CALL_WINDOW_END_DEFAULT = 17;

function SessionForm({ dialSessionId, campaignId, onSuccess, isClone = null }) {
  const [formData, setFormData] = useState({
    id: dialSessionId,
    name: "",
    campaignId: campaignId,
    status: true,
    shouldAllowDuplicates: true,
    limitCallTags: [],
    autoDial: false,
    dialAllContactNumbers: false,
    conditions: [],
    dispositions: [],
    phoneNumberTypes: Object.entries(CONSTANTS.CONTACT_PHONE_TYPES)
      .map(([key, value]) => ({ type: key, value: value, enabled: false }))
      .sort((a, b) => a.value - b.value),
    callWindowStart: new Date(0, 0, 0, CALL_WINDOW_START_DEFAULT, 0, 0),
    callWindowEnd: new Date(0, 0, 0, CALL_WINDOW_END_DEFAULT, 0, 0),
  });
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [formOptions, setFormOptions] = useState([]);
  const [voicemailOptions, setVoicemailOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [campaignStatuses, setCampaignStatuses] = useState([]);
  const [callDispositions, setCallDispositions] = useState([]);
  const [filterParams, setFilterParams] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [filterReady, setFilterReady] = useState(false);
  const [tabActiveIndex, setTabActiveIndex] = useState(0);
  const [accordionActiveIndex, setAccordionActiveIndex] = useState(-1);
  const [limitCallTagsToggle, setLimitCallTagsToggle] = useState(false);

  const contactTags = useTags("contact");
  const entityTags = useTags("entity");
  const callTags = useTags("CampaignTargetCall");
  const textEditor = useRef();

  const fetchDialSession = useCallback(async () => {
    const dialSession = await DialSessionsService.getSession(dialSessionId);
    const formDataCopy = { ...formData };
    formDataCopy.name = isClone ? null : dialSession.name;
    formDataCopy.formId = dialSession.form.id;
    formDataCopy.voicemailId = dialSession.voicemail.id;
    formDataCopy.conditions = dialSession.conditions
      ? dialSession.conditions
      : [];
    formDataCopy.dispositions = dialSession.info.dispositions || [];
    formDataCopy.status = dialSession.status;
    formDataCopy.enableRecording = dialSession.info.enable_recording;
    formDataCopy.shouldAllowDuplicates = dialSession.should_allow_duplicates;
    formDataCopy.autoDial = dialSession.auto_dial;
    formDataCopy.dialAllContactNumbers = dialSession.dial_all_contact_numbers;
    formDataCopy.callWindowStart = new Date(
      0,
      0,
      0,
      dialSession.call_window_start,
      0,
      0
    );
    formDataCopy.callWindowEnd = new Date(
      0,
      0,
      0,
      dialSession.call_window_end,
      0,
      0
    );

    const enabledNumberTypes = Object.values(
      dialSession.info.phone_number_types || {}
    );
    formDataCopy.phoneNumberTypes = [
      ...formData.phoneNumberTypes
        .filter(pn => enabledNumberTypes.includes(pn.value))
        .sort(
          (a, b) =>
            enabledNumberTypes.indexOf(a.value) -
            enabledNumberTypes.indexOf(b.value)
        )
        .map(pn => {
          pn.enabled = true;
          return pn;
        }),
      ...formData.phoneNumberTypes.filter(
        pn => !enabledNumberTypes.includes(pn.value)
      ),
    ];
    formDataCopy.enableNumberTypes = enabledNumberTypes.length > 0;
    formDataCopy.limitCallTags = dialSession.info.limit_call_tags || [];
    setLimitCallTagsToggle(formDataCopy.limitCallTags.length > 0);

    // TODO: merge dialSession info number types with form data
    /*
    if (dialSession.info.phone_number_types) {
      formDataCopy.phoneNumberTypes = dialSession.info.phone_number_types || {};
    }
    */

    if (isClone) {
      formDataCopy.id = null;
    }
    if (dialSession.script) {
      textEditor.current.importState(JSON.parse(dialSession.script || {}));
    }
    setFilterParams(dialSession.conditions ? dialSession.conditions : []);
    setFormData(formDataCopy);
  }, [dialSessionId, formData]);

  const fetchCallDispositions = useCallback(async () => {
    const resp = await CallDispositionService.getDispositions({
      per_page: 999,
      has_parent: "false",
    });
    let dispositions = resp.data;
    setCallDispositions(dispositions);
  }, []);

  const fetchCampaigns = useCallback(async () => {
    let campaignOptions = await CampaignService.getCampaignsForFilters();
    campaignOptions = makeOptions(campaignOptions);
    setCampaignOptions(campaignOptions);
  }, []);

  const fetchCampaignStatuses = useCallback(async () => {
    let statuses = await CampaignService.getCampaignStatuses();
    let statusOptions = makeOptions(statuses);
    statusOptions.unshift({
      id: null,
      info: null,
      key: null,
      text: "None/Unset",
      value: null,
    });
    setCampaignStatuses(statusOptions);
  }, []);

  const fetchForms = useCallback(async () => {
    let formOptions = await EmailUrlService.getForms();
    formOptions.sort(compareCreatedAt);
    formOptions = makeOptions(formOptions);
    setFormOptions(formOptions);
  }, []);

  const fetchVoicemails = useCallback(async () => {
    let voicemailOptions = await VoicemailService.getVoicemailsForFilter();
    voicemailOptions = makeOptions(voicemailOptions);
    setVoicemailOptions(voicemailOptions);
  }, []);

  const fetchUsers = useCallback(async () => {
    const users = await UserService.getUsers();
    let userOptions = makeOptions(users, true);
    setUsers(userOptions);
  }, []);

  const fetchTemplates = useCallback(async () => {
    const templates = await EmailTemplateService.getEmailTemplatesForFilters();
    let templateOptions = makeOptions(templates);
    templateOptions.unshift({
      key: "any",
      text: "Any Template",
      value: "any",
    });
    setTemplates(templateOptions);
  }, []);

  const makeOptions = (data, users = false) => {
    let options;
    if (users) {
      options = data.map(entry => ({
        text: entry.full_name,
        key: entry.id,
        value: entry.id,
      }));
    } else {
      options = data.map(entry => ({
        text: entry.name,
        key: entry.id,
        value: entry.id,
      }));
    }
    return options;
  };
  useEffect(() => {
    if (dialSessionId) {
      fetchDialSession();
    }
  }, [dialSessionId]);

  useEffect(() => {
    fetchCallDispositions();
    if (!campaignId || isClone) {
      fetchCampaigns();
    }
    fetchForms();
    fetchVoicemails();
    fetchUsers();
    fetchCampaignStatuses();
    fetchTemplates();
    fetchUsers();
  }, [
    campaignId,
    fetchCallDispositions,
    fetchCampaignStatuses,
    fetchCampaigns,
    fetchForms,
    fetchUsers,
    fetchVoicemails,
    fetchTemplates,
  ]);

  const handleTimeChange = (value, name) => {
    const formDataCopy = { ...formData };
    if (name === "callWindowStart" && value >= formDataCopy.callWindowEnd) {
      value = formDataCopy.callWindowStart;
    } else if (
      name === "callWindowEnd" &&
      value <= formDataCopy.callWindowStart
    ) {
      value = formDataCopy.callWindowEnd;
    }
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const handleChange = event => {
    const formDataCopy = { ...formData };
    formDataCopy[event.target.name] = event.target.value;
    setFormData(formDataCopy);
  };

  const handleToggle = (event, data) => {
    const formDataCopy = { ...formData };
    formDataCopy[data.name] = data.checked;
    if (data.name === "autoDial" && !data.checked) {
      formDataCopy["dialAllContactNumbers"] = false;
    }
    setFormData(formDataCopy);
  };

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const handleUpdateNumberOrder = (key, sIndex, dIndex) => {
    const formDataCopy = { ...formData };
    const phoneNumberTypes = [...formData.phoneNumberTypes];

    if (dIndex >= phoneNumberTypes.length) {
      dIndex = phoneNumberTypes.length - 1;
    }

    const element = phoneNumberTypes[sIndex];
    phoneNumberTypes.splice(sIndex, 1);
    phoneNumberTypes.splice(dIndex, 0, element);
    formDataCopy.phoneNumberTypes = phoneNumberTypes;
    setFormData(formDataCopy);
  };

  const validCondition = condition => {
    if (condition.filter_operand2 === undefined) return false;
    if (condition.filter_operand2 === "") return false;
    return !(
      Array.isArray(condition.filter_operand2) &&
      condition.filter_operand2.length === 0
    );
  };

  const isReady = () => {
    if (!formData.name) return false;
    if (!formData.campaignId) return false;
    if (!formData.voicemailId) return false;
    if (limitCallTagsToggle && formData.limitCallTags.length === 0)
      return false;
    else {
      return true;
    }
  };

  const isFilterReady = () => {
    if (filterParams.length > 0) {
      setFilterReady(filterParams.every(cond => validCondition(cond)));
    } else {
      setFilterReady(true);
    }
  };

  const makeParamForm = (filterParams, param, paramIdx) => {
    return (
      <OutreachFiltersForm
        key={param.id}
        uuid={param.id}
        campaignStatuses={campaignStatuses}
        emailTemplateOptions={templates}
        userOptions={users}
        campaignId={campaignId}
        conditions_list={filterParams}
        condition={param}
        contactTagOptions={contactTags}
        entityTagOptions={entityTags}
        callTagsOptions={callTags}
        conditionIdx={paramIdx}
        removeFilterElement={removeParam}
        campaignIds={[campaignId]}
        isFilterReady={isFilterReady}
      />
    );
  };

  useEffect(() => {
    isFilterReady();
  }, [filterParams]);

  const addParam = () => {
    let params = [...filterParams];
    params.push({
      id: uuidv4(),
      filter_context: undefined,
      filter_operand1: undefined,
      filter_operator: undefined,
      filter_operand2: undefined,
    });
    setFilterParams(params);
  };

  const removeParam = paramIdx => {
    let params = [...filterParams];
    if (params) {
      params.splice(paramIdx, 1);
    }
    setFilterParams(params);
  };

  const dialSessionParamsRender = (
    <>
      <Form.Field label="Dial Session Params" style={{ marginTop: "10px" }} />
      <div className="and-filter">
        {filterParams.map((param, paramIdx) => {
          return makeParamForm(filterParams, param, paramIdx);
        })}
      </div>
      <Button
        basic
        color="grey"
        size="mini"
        content="Add Param"
        name="fromAddress"
        type="button"
        onClick={addParam}
      />
    </>
  );

  const handleSubmit = async e => {
    const {
      id,
      name,
      campaignId,
      status,
      voicemailId,
      formId,
      enableRecording,
      shouldAllowDuplicates,
      dispositions,
      autoDial,
      dialAllContactNumbers,
      enableNumberTypes,
      phoneNumberTypes,
      limitCallTags,
      callWindowStart,
      callWindowEnd,
    } = formData;

    const info = {
      enable_recording: enableRecording,
      dispositions: dispositions,
      phone_number_types: enableNumberTypes
        ? phoneNumberTypes
            .filter(pn => pn.enabled)
            .reduce((obj, pn, index) => {
              obj[`${index}`] = pn.value;
              return obj;
            }, {})
        : {},
      limit_call_tags: limitCallTagsToggle ? limitCallTags : [],
    };
    const script = JSON.stringify(textEditor.current.exportState());

    const data = {
      name: name,
      campaign_id: campaignId,
      status: status,
      script: script,
      form_id: formId,
      voicemail_id: voicemailId,
      info: info,
      should_allow_duplicates: shouldAllowDuplicates,
      conditions: filterParams,
      auto_dial: autoDial,
      dial_all_contact_numbers: dialAllContactNumbers,
      call_window_start:
        typeof callWindowStart.hour === "function"
          ? callWindowStart.hour()
          : callWindowStart.getHours(),
      call_window_end:
        typeof callWindowEnd.hour === "function"
          ? callWindowEnd.hour()
          : callWindowEnd.getHours(),
    };
    try {
      if (id) {
        await DialSessionsService.editSession(id, data);
      } else {
        await DialSessionsService.createSession(data);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  const handleTabChange = (_, { activeIndex }) => {
    setTabActiveIndex(activeIndex);
  };

  const handleAccordionClick = (_, { index }) => {
    const newIndex = accordionActiveIndex === index ? -1 : index;
    setAccordionActiveIndex(newIndex);
  };

  const handleDispositionCheck = (e, { checked, value }) => {
    const formDataCopy = { ...formData };
    let disposition = callDispositions.find(d => d.id == value);
    if (!disposition) {
      disposition = callDispositions
        .flatMap(d => d.child_dispositions || [])
        .find(cd => cd.id == value);
    }

    if (!disposition) {
      return;
    }

    const childDispositions = disposition.child_dispositions
      ? disposition.child_dispositions.map(d => d.id)
      : [];
    let values = childDispositions.concat([disposition.id]);

    if (checked) {
      if (disposition.parent_id) {
        values = values.concat([disposition.parent_id]);
      }
      formDataCopy.dispositions = formDataCopy.dispositions.concat(values);
    } else {
      if (childDispositions.length > 0) {
        e.stopPropagation();
      }
      formDataCopy.dispositions = formDataCopy.dispositions.filter(
        d => !values.includes(d)
      );
    }
    formDataCopy.dispositions = [...new Set(formDataCopy.dispositions)];
    setFormData(formDataCopy);
  };

  const handlePhoneNumberCheck = (e, { checked, value }) => {
    const index = formData.phoneNumberTypes.findIndex(pn => pn.value == value);
    const formDataCopy = { ...formData };

    if (checked) {
      formData.phoneNumberTypes[index].enabled = true;
    } else {
      formData.phoneNumberTypes[index].enabled = false;
    }

    setFormData(formDataCopy);
  };

  const getCheckboxIndeterminateStatus = childDispositions => {
    let dispositionArr = [];
    let indeterminateStatus = false;
    if (childDispositions.length > 1) {
      childDispositions.map(cd => {
        if (formData.dispositions.includes(cd.id)) {
          dispositionArr.push(true);
        } else {
          dispositionArr.push(false);
        }
      });
    }
    let some = dispositionArr.some(disposition => disposition === true);
    let all = dispositionArr.every(disposition => disposition === true);
    if (some && !all) {
      indeterminateStatus = true;
    }
    return indeterminateStatus;
  };

  const tabPanes = [
    {
      menuItem: "General",
      pane: {
        key: "general",
        content: (
          <>
            <Form.Checkbox
              toggle
              name="status"
              checked={formData.status}
              label={`Status [${formData.status ? "Active" : "Inactive"}]`}
              onChange={handleToggle}
              required
            />
            <Form.Checkbox
              toggle
              name="autoDial"
              checked={formData.autoDial}
              label={`Auto Dial Next Contact [${
                formData.autoDial ? "Active" : "Inactive"
              }]`}
              onChange={handleToggle}
              required
            />
            <Form.Checkbox
              toggle
              disabled={!formData.autoDial}
              name="dialAllContactNumbers"
              checked={formData.dialAllContactNumbers}
              label={`Dial All Contact Numbers [${
                formData.dialAllContactNumbers ? "Active" : "Inactive"
              }]`}
              onChange={handleToggle}
              required
            />
            <Form.Checkbox
              toggle
              name="enableRecording"
              checked={formData.enableRecording}
              label={`Call Recording [${
                formData.enableRecording ? "On" : "Off"
              }]`}
              onChange={handleToggle}
              required
            />
            <Form.Checkbox
              toggle
              name="shouldAllowDuplicates"
              checked={formData.shouldAllowDuplicates}
              label={`Allow duplicate numbers [${
                formData.shouldAllowDuplicates ? "Active" : "Inactive"
              }]`}
              onChange={handleToggle}
              required
            />
            <Form.Group inline>
              <Form.Field>
                <label>Call Window Start</label>
                <Datetime
                  name={"callWindowStart"}
                  viewMode="time"
                  value={formData.callWindowStart}
                  input={false}
                  closeOnSelect
                  dateFormat={false}
                  timeFormat="HH a"
                  onChange={value => handleTimeChange(value, "callWindowStart")}
                />
              </Form.Field>
              <Form.Field>
                <label>Call Window End</label>
                <Datetime
                  name={"callWindowEnd"}
                  viewMode="time"
                  value={formData.callWindowEnd}
                  input={false}
                  timeFormat="HH a"
                  closeOnSelect
                  dateFormat={false}
                  onChange={value => handleTimeChange(value, "callWindowEnd")}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Checkbox
                className="limit-call-tags-toggle"
                toggle
                checked={limitCallTagsToggle}
                label="Limit Call Tags"
                onChange={(_, { checked }) => setLimitCallTagsToggle(checked)}
                required
              />
              {limitCallTagsToggle && (
                <Form.Select
                  inline
                  multiple
                  search
                  onChange={handleSelect}
                  value={formData.limitCallTags}
                  name="limitCallTags"
                  options={callTags.map(tag =>
                    mapSelectTagOption(tag, { maxWidth: "100%" })
                  )}
                  renderLabel={item => item.label || item.text}
                  placeholder="Select Call Tags"
                />
              )}
            </Form.Group>
            <Form.Input
              inline
              name="name"
              value={formData.name}
              label="Name"
              required
              onChange={handleChange}
            />
            {campaignId && !isClone ? null : (
              <Form.Select
                inline
                fluid
                required
                search
                onChange={handleSelect}
                value={formData.campaignId}
                label="Campaign"
                name="campaignId"
                options={campaignOptions}
                placeholder="Select Campaign"
              />
            )}
            <Form.Select
              inline
              fluid
              required
              search
              onChange={handleSelect}
              value={formData.voicemailId}
              label="Voicemail"
              name="voicemailId"
              options={voicemailOptions}
              placeholder="Select Voicemail"
            />
            <Form.Select
              inline
              fluid
              search
              clearable
              onChange={handleSelect}
              value={formData.formId}
              label="Questionnaire"
              name="formId"
              options={formOptions}
              placeholder="Select Questionnaire"
            />
            <Form.Checkbox
              toggle
              name="enableNumberTypes"
              checked={formData.enableNumberTypes}
              label="Phone number type selection"
              onChange={handleToggle}
            />

            {formData.enableNumberTypes && (
              <Form.Group>
                <DraggableContainer
                  container={List}
                  onUpdateChildrenOrder={handleUpdateNumberOrder}
                  direction="horizontal"
                  className="phone-type"
                >
                  {Object.keys(formData.phoneNumberTypes)
                    .sort((a, b) => a - b)
                    .map(index => (
                      <List.Item>
                        <Checkbox
                          label={`${parseInt(index) + 1}. ${startCase(
                            formData.phoneNumberTypes[index].type.toLowerCase()
                          )}`}
                          value={formData.phoneNumberTypes[index].value}
                          onChange={handlePhoneNumberCheck}
                          checked={formData.phoneNumberTypes[index].enabled}
                        />
                      </List.Item>
                    ))}
                </DraggableContainer>
              </Form.Group>
            )}
            <Form.Field>
              <label>Script</label>
              <RichTextEditor ref={textEditor} />
            </Form.Field>
            {dialSessionParamsRender}
          </>
        ),
      },
    },
    {
      menuItem: "Dispositions",
      pane: {
        key: "dispositions",
        content: (
          <Grid stackable columns={2}>
            {callDispositions
              .filter(d => d.parent_id === null)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((parentDisposition, index) => (
                <Grid.Column>
                  <Accordion styled>
                    <Accordion.Title
                      inline
                      active={accordionActiveIndex === index}
                      index={index}
                      onClick={
                        parentDisposition.child_dispositions.length > 0
                          ? handleAccordionClick
                          : () => {}
                      }
                    >
                      <Form.Checkbox
                        label={startCase(parentDisposition.name)}
                        name="disposition"
                        value={parentDisposition.id}
                        onChange={handleDispositionCheck}
                        checked={formData.dispositions.includes(
                          parentDisposition.id
                        )}
                        indeterminate={getCheckboxIndeterminateStatus(
                          parentDisposition.child_dispositions
                        )}
                      />
                      {parentDisposition.child_dispositions.length > 0 && (
                        <Icon name="dropdown" />
                      )}
                    </Accordion.Title>
                    {parentDisposition.child_dispositions.length > 0 && (
                      <Accordion.Content
                        active={accordionActiveIndex === index}
                      >
                        <Form.Group grouped>
                          {parentDisposition.child_dispositions.map(
                            childDisposition => (
                              <>
                                <Form.Checkbox
                                  label={startCase(childDisposition.name)}
                                  name="disposition"
                                  value={childDisposition.id}
                                  onChange={handleDispositionCheck}
                                  checked={formData.dispositions.includes(
                                    childDisposition.id
                                  )}
                                />
                              </>
                            )
                          )}
                        </Form.Group>
                      </Accordion.Content>
                    )}
                  </Accordion>
                </Grid.Column>
              ))}
          </Grid>
        ),
      },
    },
  ];

  return (
    <RuvixxForm
      ready={isReady() && filterReady}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      className="dial-session"
    >
      <Tab
        panes={tabPanes}
        activeIndex={tabActiveIndex}
        onTabChange={handleTabChange}
        renderActiveOnly={false}
      />
    </RuvixxForm>
  );
}

SessionForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  campaignId: PropTypes.number,
};

export default SessionForm;

import { applyFilters, doRequest } from "./helpers";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

export default class ContractService {
  static async getContracts(filters = null) {
    let path = `/entity/${filters["entity_id"]}/contracts/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getContractForFilters(filters) {
    let path = `/entity/${filters["entity_id"]}/contracts/for_filters`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getContract(id, entityId) {
    return doRequest("GET", `/entity/${entityId}/contracts/${id}`);
  }

  static async getCustomFields(filters = null) {
    let path = `/entity/${filters["entity_id"]}/contracts/custom_fields`;
    return doRequest("GET", path);
  }

  static async editContract(id, entityId, data) {
    return doRequest("PUT", `/entity/${entityId}/contracts/${id}`, {
      id,
      entityId,
      data: omitBy(data, isNil),
    });
  }
  static async deleteContract(id, entityId) {
    return doRequest("DELETE", `/entity/${entityId}/contracts/${id}`);
  }

  static async createContract(entityId, data) {
    return doRequest("POST", `/entity/${entityId}/contracts/`, {
      entityId,
      data: omitBy(data, isNil),
    });
  }
}

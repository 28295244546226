// BaseTableV2.js // TODO: Update name once update completes?
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Icon, Message, Modal } from "semantic-ui-react";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import TableExportService from "../../services/TableExport";

// Table Components
import TableHeader from "./TableHeader";
import TableFooter from "./TableFooter";

import "./../../styles/table.scss";
import "./BaseTableV2.scoped.scss";
import { useQuery } from "react-query";

/*
 * TODO: Possible improvements
 * - Allow pagination widget to be set as "top" || "bottom" || "both" || false
 * - Allow to pass data directly to the table and handle fetching separately
 */

let cancelToken = null; //TODO: cancel request support

const Loading = () => {
  return (
    <div className="-loading -active">
      <Message icon className="-loading-inner">
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Just one second</Message.Header>
          We are fetching that content for you.
        </Message.Content>
      </Message>
    </div>
  );
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox ref={resolvedRef} {...rest} />;
});

const TableQuery = ({
  queryMethod,
  queryArgs,
  queryFilters: parentQueryFilters,
  prevFilters,
  onUpdateQueryFilters,
  queryKey,
  columns,
  pagination,
  onUpdatePagination,
  onSortBy,
  shouldRefetch,
  onFetched,
  onSelectedRows,
  page,
  setPage,
  rowProps,
  useQueryOptions,
  exportTableName,
  ...props
}) => {
  const { pageSize, pageCount, totalResults } = pagination;
  const queryFilters = { ...parentQueryFilters };

  const queryKeys = [
    `${queryMethod.name}${queryKey ? "_" + queryKey : ""}`,
    { page, pageSize, queryFilters },
  ];
  queryFilters.page = page;
  queryFilters.per_page = pageSize;

  if (!isEqual(omit(queryFilters, ["page", "per_page"]), prevFilters)) {
    // When filters change return to the first page
    queryFilters.page = 1;
    setPage(1);
  }

  const handleSortBy = sortArr => {
    if (sortArr.length < 1) {
      return;
    }
    const sortBy = sortArr[0].id;
    const sortDir = sortArr[0].desc ? "desc" : "asc";
    if ((queryFilters.sort_by === sortBy, queryFilters.sort_dir === sortDir)) {
      return;
    }
    onUpdateQueryFilters({ sort_by: sortBy, sort_dir: sortDir });
  };

  const [tableData, setTableData] = useState([]);
  const {
    data: apiResponse,
    isLoading,
    error,
    refetch,
  } = useQuery(
    [...queryKeys],
    async () => {
      return !!queryArgs && queryArgs.length
        ? await queryMethod(...queryArgs, queryFilters)
        : await queryMethod(queryFilters);
    },
    useQueryOptions
  );

  useEffect(() => {
    if (!apiResponse) return;
    const {
      data,
      page,
      pages: pageCount,
      per_page: pageSize,
      total: totalResults,
    } = apiResponse;
    setTableData(data);
    onUpdatePagination({
      page,
      pageCount,
      pageSize,
      totalResults,
    });
    onFetched(apiResponse);
  }, [apiResponse]);

  useEffect(() => {
    refetch();
  }, [shouldRefetch, refetch]);

  const displayToolbar = !!exportTableName;

  return (
    <div className="table-container">
      {displayToolbar && (
        <div className="table-toolbar">
          {exportTableName && (
            <DownloadModal
              exportTableName={exportTableName}
              queryFilters={queryFilters}
              visibleColumns={columns.map(col => col.Header)}
            />
          )}
        </div>
      )}
      <div
        className="table-inner"
        style={{ ...(!displayToolbar && { height: "100%" }) }}
      >
        <Table
          isLoading={isLoading}
          tableData={tableData}
          tableColumns={columns}
          pagination={pagination}
          onSortBy={handleSortBy}
          onSelectedRows={onSelectedRows}
          rowProps={rowProps}
          queryFilters={queryFilters}
          {...props}
        />
      </div>
    </div>
  );
};

const Table = ({
  tableColumns,
  tableData,
  pagination,
  isLoading,
  onSortBy,
  onSelectedRows,
  rowProps,
  exportTableName,
  queryFilters,
  ...props
}) => {
  const { pageSize } = pagination;

  const [columns, data] = useMemo(
    () => [tableColumns, tableData],
    [tableData, tableColumns]
  );
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );
  //const [columns, data] = [tableColumns, tableData];
  const table = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualSortBy: true,
      disableSortRemove: true,
    },
    useSortBy,
    useRowSelect,
    useResizeColumns,
    useFlexLayout,
    hooks => {
      hooks.visibleColumns.push(columns => {
        let cols = [];
        if (onSelectedRows) {
          cols = [
            // Let's make a column for selection
            {
              id: "selection",
              totalWidth: 30,
              width: 30,
              disableResizing: true,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
          ];
        }
        return [...cols, ...columns];
      });
    }
  );

  const handleSortBy = useCallback(
    sortArr => {
      if (sortArr.length < 1) {
        return;
      }
      const { id: sortBy, desc: sortDir } = sortArr[0];
      onSortBy(sortBy, sortDir);
    },
    [onSortBy]
  );

  useEffect(() => {
    onSortBy(table.state.sortBy);
  }, [table.state.sortBy]);

  useEffect(() => {
    if (!onSelectedRows) {
      return;
    }
    const selectedRows = table.selectedFlatRows.map(row => row.original);
    onSelectedRows(selectedRows);
  }, [table.state.selectedRowIds]); //, [selectedRowIds]);

  return (
    <TableLayout
      pageSize={pageSize}
      isLoading={isLoading}
      rowProps={rowProps}
      queryFilters={queryFilters}
      {...table}
      {...props}
    />
  );
};

const DownloadModal = ({ exportTableName, queryFilters, visibleColumns }) => {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const navigate = useNavigate();

  const exportTable = async () => {
    queryFilters = omit(queryFilters, ["page", "per_page", "tab"]);
    const resp = await TableExportService.exportTable(exportTableName, {
      ...queryFilters,
      visible_columns: visibleColumns,
    });
    if (resp === true) {
      setMsg(
        "Your download has started. You'll receive an email when it's finished. You can also check the 'Data' tab for progress and download."
      );
    } else {
      setMsg("An error occurred initiating download.");
    }
    setOpen(true);
  };

  const redirectToData = () => {
    setOpen(false);
    navigate("/data");
  };

  return (
    <>
      <Button
        size="mini"
        icon="download"
        className="standard"
        onClick={exportTable}
      />
      <Modal
        closeIcon
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Content>
          <p>{msg}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>OK</Button>
          <Button primary onClick={() => redirectToData()}>
            Go to Data
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const TableLayout = ({
  // Table Data
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  pageSize,
  prepareRow,
  isLoading,
  width,
  maxWidth,
  minWidth,
  rowProps: getRowProps = () => ({}),
  exportTableName,
  queryFilters,
  // Table Header
}) => {
  let tableRows = [];
  for (let i = 0; i < pageSize; i++) {
    let row = rows[i];
    if (row) {
      prepareRow(row);
    }
    const {
      className: rowClassName,
      style: rowStyle,
      ...rowProps
    } = row ? row.getRowProps(getRowProps(row)) : {};
    tableRows.push(
      <div
        className={`rt-tr-group ${rowClassName ? rowClassName : ""}`}
        style={{ rowStyle, width: width }}
      >
        {row ? (
          <div
            className={`rt-tr ${i % 2 === 0 ? "-even" : "-odd"}`}
            {...rowProps}
          >
            {row.cells.map(cell => (
              <div
                className={`rt-td ${
                  cell.column.className ? cell.column.className : ""
                }`}
                {...cell.getCellProps()}
              >
                {cell.render("Cell")}
              </div>
            ))}
          </div>
        ) : (
          <div className="rt-tr -padRow -odd">
            <div className="rt-td" />
          </div>
        )}
      </div>
    );
  }

  const handleScroll = event => {
    const table = event.target;
    const tBody = table.querySelector(":scope > .rt-tbody");
    if (tBody) {
      const newWidth = table.offsetWidth + table.scrollLeft;
      if (newWidth <= table.scrollWidth) {
        tBody.style.width = `${newWidth}px`;
      }
    }
  };

  return (
    <div>
      {isLoading && <Loading />}
      <div className="rt-table" onScroll={handleScroll} {...getTableProps()}>
        <div className="rt-thead">
          {headerGroups.map(headerGroup => (
            <div className="rt-tr" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <>
                  <div
                    {...column.getHeaderProps([
                      {
                        className: `rt-th ${
                          column.isSorted
                            ? column.isSortedDesc
                              ? "-sort-desc"
                              : "-sort-asc"
                            : ""
                        } ${column.className}`,
                        style: column.style,
                      },
                      column.getSortByToggleProps(),
                    ])}
                  >
                    {column.render("Header")}
                  </div>
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    ></div>
                  )}
                </>
              ))}
            </div>
          ))}
        </div>
        <div className="rt-tbody" {...getTableBodyProps()}>
          {tableRows}
        </div>
      </div>
    </div>
  );
};

const BaseTableV2 = ({
  queryClient,
  config,
  actions,
  page: parentPage,
  setPage: setParentPage,
  rowProps,
  queryFilters,
  useQueryOptions,
  ...props
}) => {
  const [page, setPage] = useState(parentPage || 1);
  const [prevFilters, setPrevFilters] = useState({});

  useEffect(() => {
    if (parentPage) {
      setPage(parentPage);
    }
  }, [parentPage]);

  useEffect(() => {
    if (setParentPage) {
      setParentPage(page);
    }
  }, [page]);

  const defaultConfig = {
    enableHeader: false,
    enableFooter: true,
    enableSearch: true,
    enableAdvancedSearch: false,
    enablePagination: true,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  config = { ...defaultConfig, ...config };

  const headerProps = {
    header: props.header,
    headerIcon: props.headerIcon,
    createButton: props.createButton,
    enableSearch: config.enableSearch,
    enableAdvancedSearch: config.enableAdvancedSearch,
    onSearch: props.onSearch,
    onUpdateFilters: props.onUpdateFilters,
  };

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    pageCount: 1,
    totalResults: 1,
  });

  useEffect(() => {
    const filters = omit(queryFilters, ["page", "per_page"]);
    if (!isEqual(filters, prevFilters)) {
      setPrevFilters(filters);
    }
  }, [queryFilters]);

  const handlePageChange = pageIndex => {
    let _pagination = { ...pagination };
    _pagination.page = pageIndex;
    setPagination(_pagination);
    setPage(pageIndex);
  };

  const handlePageSizeChange = pageSize => {
    let _pagination = { ...pagination };
    _pagination.pageSize = pageSize;
    setPagination(_pagination);
  };

  const handleUpdatePagination = ({
    page,
    pageSize,
    pageCount,
    totalResults,
  }) => {
    let _pagination = { ...pagination };
    _pagination.page = page || pagination.page;
    _pagination.pageSize = pageSize || pagination.pageSize;
    _pagination.pageCount = pageCount || pagination.pageCount;
    _pagination.totalResults = totalResults || pagination.totalResults;
    setPagination(_pagination);
  };

  const tableProps = {
    pagination: pagination,
    onUpdatePagination: handleUpdatePagination,
    shouldRefetch: props.shouldRefetch,
    onFetched: props.onFetched,
    onSelectedRows: props.onSelectedRows,
  };

  const footerProps = {
    enablePagination: config.enablePagination,
    onPageChange: handlePageChange,
    onPageSizeChange: handlePageSizeChange,
    pagination: pagination,
  };

  return (
    <>
      <div
        className={`ReactTable ${
          !!tableProps.onSelectedRows ? "select-column" : ""
        }`}
      >
        {config.enableHeader && <TableHeader {...headerProps} />}
        <TableQuery
          {...props}
          {...tableProps}
          rowProps={rowProps}
          page={page}
          setPage={setPage}
          queryFilters={queryFilters}
          prevFilters={prevFilters}
          useQueryOptions={useQueryOptions}
        />
        {config.enableFooter && <TableFooter {...footerProps} />}
      </div>
    </>
  );
};
export default BaseTableV2;

import React, { useRef, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Container, Grid, Header, Breadcrumb } from "semantic-ui-react";
import "./SequenceDetail.scoped.scss";
import StepModal from "./components/StepModal";
import _SequenceCampaignModal from "./components/SequenceCampaignModal";
import StepList from "./components/StepList";
import SequenceIsActiveToggle from "./components/SequenceIsActiveToggle";
import SequenceService from "../../services/Sequence";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import { checkIsAuthorized } from "../../components/helpers";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import setPageTitle from "../../helpers/title";

const SequenceCampaignModal = withRoleCheck(_SequenceCampaignModal, [
  ACL_RELATIONSHIPS.sequence.read,
  ACL_RELATIONSHIPS.campaignForFilters.read,
  ACL_RELATIONSHIPS.sequenceCampaigns.create,
]);

export default function SequenceDetail() {
  const stepList = useRef();
  const stepModal = useRef();
  const [loading, setLoading] = useState(true);
  const [sequence, setSequence] = useState({});
  const canEditSequence = checkIsAuthorized([ACL_RELATIONSHIPS.sequence.edit]);

  const params = useParams();

  const fetchSequence = async () => {
    setLoading(true);
    const sequence = await SequenceService.getSequence(params.id);
    const pageTitle = sequence.name ? sequence.name : "Sequence";
    setPageTitle(pageTitle);
    setSequence(sequence);
    setLoading(false);
  };
  const handleNewStep = () => {
    stepModal.current.open();
  };

  const handleSuccess = () => {
    fetchSequence();
  };

  const NewStepButton = withRoleCheck(
    () => (
      <Button
        content="New Step"
        onClick={handleNewStep}
        size="tiny"
        className="item-adder"
      />
    ),
    [
      ACL_RELATIONSHIPS.sequence.read,
      ACL_RELATIONSHIPS.auditLog.events,
      ACL_RELATIONSHIPS.user.read,
      ACL_RELATIONSHIPS.sequence.edit,
    ]
  );

  useEffect(() => {
    fetchSequence();
  }, []);

  return (
    <Container fluid className="route sequence-detail">
      <StepModal
        ref={stepModal}
        sequence={sequence}
        onSuccess={handleSuccess}
      />
      <Grid className="bg list-grid">
        <Grid.Row>
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section link>
                <Link to="/sequences">All Sequences</Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
            </Breadcrumb>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="sixteen wide detail-top-buttons">
            {sequence.name && <Header>{sequence.name}</Header>}
            <NewStepButton />
            <div className="spacer" />
            <SequenceIsActiveToggle
              sequence={sequence}
              onSuccess={fetchSequence}
              disabled={!canEditSequence}
              label="Is Active"
            />
            <SequenceCampaignModal button sequenceId={params.id} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="step-list">
            <StepList
              ref={stepList}
              stepModal={stepModal}
              sequence={sequence}
              stepsLoading={loading}
              fetchSequence={fetchSequence}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

import { doRequest, applyFilters } from "./helpers";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

const SourceStatus = {
  getTable: filters => {
    let path = "/source_status/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  },
  getForFilters: filters => {
    let path = "/source_status/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  },
  create: data => {
    return doRequest("POST", "/source_status/", {
      data: omitBy(data, isNil),
    });
  },
  edit: (statusId, data) => {
    return doRequest("PUT", `/source_status/${statusId}`, {
      data: omitBy(data, isNil),
    });
  },
  delete: statusId => {
    return doRequest("DELETE", `/source_status/${statusId}`);
  },
};

export default SourceStatus;

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Container,
  Dropdown,
  Grid,
  List,
  Tab,
} from "semantic-ui-react";
import "./../../styles/campaign.scss";
import CampaignService from "./../../services/Campaign";
import CampaignStatusPresetService from "./../../services/CampaignStatusPreset";
import AuthService from "./../../services/Auth";
import TagService from "../../services/Tag";
import {
  ComposeNoticeModal as _ComposeNoticeModal,
  DeleteCampaignModal as _DeleteCampaignModal,
  EditCampaignModal as _EditCampaignModal,
} from "./components/Modals";
import _DialSessionModal from "../dial_sessions/components/DialSessionModal";
import CustomItems from "./../../components/CustomItems";
import NoticesTable from "./NoticesTable";
import EmailSchedulesTable from "./EmailSchedulesTable";
import CallsTable from "../../components/CallsTable";
import CampaignTargetsTable from "./CampaignTargetsTable";
import PipelineView from "./PipelineView";
import { formatDate, formatCampaignStatus } from "./../../components/helpers";
import "./../../styles/table.scss";
import CONFIG from "../../Config";
import { NewNoteModal as _NewNoteModal } from "./../../components/modals/NoteModals";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import { checkIsAuthorized } from "../../components/helpers";
import RouteParamsManager from "../../hooks/params/RouteParamsManager";
import setPageTitle from "../../helpers/title";
import ContactsTable from "./components/ContactsTable";
import NotesView from "../../components/views/NotesView";
import CasesTable from "../case/CasesTable";
import _RevenueOpportunitiesTable from "components/RevenueOpportunityTable";

const NewNoteModal = withRoleCheck(_NewNoteModal, [
  ACL_RELATIONSHIPS.activity.create,
]);

const EditCampaignModal = withRoleCheck(_EditCampaignModal, [
  ACL_RELATIONSHIPS.campaign.edit,
]);
const ComposeNoticeModal = withRoleCheck(_ComposeNoticeModal, [
  ACL_RELATIONSHIPS.campaignActionEmail.create,
]);

const DialSessionModal = withRoleCheck(_DialSessionModal, [
  ACL_RELATIONSHIPS.dialSession.create,
  ACL_RELATIONSHIPS.voicemailForFilters.read,
  ACL_RELATIONSHIPS.emailUrlForms.read,
]);
const DeleteCampaignModal = withRoleCheck(_DeleteCampaignModal, [
  ACL_RELATIONSHIPS.campaign.delete,
]);

const RevenueOpportunitiesTable = withRoleCheck(
  _RevenueOpportunitiesTable,
  [ACL_RELATIONSHIPS.revenueOpportunity.readTable],
  { showMsg: true }
);

const LeftColumn = props => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <NewNoteModal
            modelType="Campaign"
            modelId={parseInt(props.campaignId)}
          />
          <Dropdown as={Button} className="standard" text="Actions">
            <Dropdown.Menu direction="right">
              <ComposeNoticeModal
                campaignId={parseInt(props.campaignId)}
                emailService={props.campaign.email_service}
              />
              <DeleteCampaignModal
                onConfirmDelete={() => props.deleteCampaign(props.campaignId)}
                menuTrigger
                noIcon
              />
              <EditCampaignModal
                fetchCampaigns={props.fetchCampaign}
                campaignId={parseInt(props.campaignId)}
                noIcon
              />
              {CONFIG.IS_PHONE_ENABLED ? (
                <DialSessionModal campaignId={parseInt(props.campaignId)} />
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
          <List relaxed>
            <List.Item>
              <List.Icon name="circle" />
              <List.Content>
                <List.Header>Status:</List.Header>
                <List.Description>
                  {formatCampaignStatus(props.campaign.status)}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="flag" />
              <List.Content>
                <List.Header>Start:</List.Header>
                <List.Description>
                  {formatDate(props.campaign.start_time)}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="clock" />
              <List.Content>
                <List.Header>End:</List.Header>
                <List.Description>
                  {formatDate(props.campaign.end_time) || "?"}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="building" />
              <List.Content>
                <List.Header># Entities:</List.Header>
                <List.Description>
                  {props.campaign.metrics.entity_count}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="user" />
              <List.Content>
                <List.Header># Contacts:</List.Header>
                <List.Description>
                  {props.campaign.metrics.contact_count}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="mail" />
              <List.Content>
                <List.Header># Emailable Contacts:</List.Header>
                <List.Description>
                  {props.campaign.metrics.emailable_contact_count}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="call" />
              <List.Content>
                <List.Header># Callable Contacts:</List.Header>
                <List.Description>
                  {props.campaign.metrics.callable_contact_count}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="star" />
              <List.Content>
                <List.Header>Creator:</List.Header>
                <List.Description>
                  {props.campaign.creator_user_full_name}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="user outline" />
              <List.Content>
                <List.Header>Assignee:</List.Header>
                <List.Description>
                  {props.campaign.assigned_user_full_name}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="file outline" />
              <List.Content>
                <List.Header>Form:</List.Header>
                <List.Description>{props.campaign.form_name}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="pencil" />
              <List.Content>
                <List.Header>Created at:</List.Header>
                <List.Description>
                  {formatDate(props.campaign.created_at)}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="info circle" />
              <List.Content>
                <List.Header>Description:</List.Header>
                <List.Description>
                  {props.campaign.info !== null
                    ? props.campaign.info.description || "none"
                    : "none"}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="plus" />
              <List.Content style={{ maxWidth: 0 }}>
                <List.Header>Custom Fields</List.Header>
                <List.List style={{ paddingTop: 0 }}>
                  <CustomItems items={props.campaign.custom_fields} />
                </List.List>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const BreadcrumbNav = props => (
  <Breadcrumb>
    <Breadcrumb.Section>
      <Link to="/campaigns/">All Campaigns</Link>
    </Breadcrumb.Section>
    <Breadcrumb.Divider icon="right chevron" />
    <Breadcrumb.Section active>{props.name}</Breadcrumb.Section>
  </Breadcrumb>
);

const CampaignDetail = () => {
  const [campaignData, setCampaignData] = useState({
    id: null,
    name: null,
    creator_user_id: null,
    assigned_user_id: null,
    assigned_user_full_name: null,
    info: { custom: null },
    created_at: null,
    metrics: {},
  });
  const [campaignTargetsRefresh, setCampaignTargetsRefresh] = useState(0);
  const [tags, setTags] = useState([]);
  const [routeParams, setRouteParams] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [isPipelineEnabled, setIsPipelineEnabled] = useState(false);
  const [campaignStatusPresets, setCampaignStatusPresets] = useState([]);

  const params = useParams();
  const navigate = useNavigate();

  const deleteCampaign = async () => {
    await CampaignService.deleteCampaign(params.id);
    navigate("/");
  };

  const fetchCampaign = async () => {
    let campaign = await CampaignService.getCampaign(params.id);

    // TODO: is the following line really needed? And does the server return undefined?
    campaign = campaign !== undefined ? campaign : campaignData;
    setPageTitle(campaign.name);
    setCampaignData(campaign);
  };

  const fetchTags = async () => {
    const tags = await TagService.getTags({ model: "entity" });
    setTags(tags);
  };

  const fetchCampaignStatusPresets = async () => {
    const campaignStatusPresets =
      await CampaignStatusPresetService.getCampaignStatusPresets();
    setCampaignStatusPresets(campaignStatusPresets);
  };

  const handleRefresh = () => {
    setCampaignTargetsRefresh(
      campaignTargetsRefresh => campaignTargetsRefresh + 1
    );
  };

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      fetchCampaign(); // don't await
      fetchTags();
      fetchCampaignStatusPresets();
      getTabs();
    }
  }, []);

  const getTabs = () => {
    const canViewCampaignSchedules = checkIsAuthorized([
      ACL_RELATIONSHIPS.campaignSchedule.read,
    ]);
    let tabs = [
      {
        name: "targetCompanies",
      },
      {
        name: "contacts",
      },
      {
        name: "notes",
      },
      {
        name: "notices",
      },
      {
        name: "emailSchedules",
        visible: canViewCampaignSchedules,
      },
      {
        name: "calls",
        visible: CONFIG.IS_PHONE_ENABLED,
      },
      {
        name: "cases",
        visible: CONFIG.ENABLE_PIRACY,
      },
      {
        name: "revenueOpportunities",
      },
    ];
    tabs = tabs
      .filter(({ visible }) => visible === undefined || visible === true)
      .map(({ name }) => name);
    setTabs(tabs);
  };

  const handleTabChange = (_, { activeIndex }) => {
    const tab = tabs[activeIndex];
    setRouteParams({ ...routeParams, tab });
  };

  const updateRouteParams = routeParams => {
    const activeIndex = tabs.findIndex(tab => tab === routeParams.tab);
    setRouteParams(routeParams);
    setActiveIndex(activeIndex);
  };

  const togglePipelineView = (event, data) => {
    setIsPipelineEnabled(data.checked);
  };

  const getPanes = () => {
    let panes = [
      {
        key: "targetCompanies",
        menuItem: "Target Companies",
        render: () => (
          <Tab.Pane>
            <>
              {isPipelineEnabled ? (
                <PipelineView
                  campaign={campaignData}
                  campaignId={params.id}
                  refresh={campaignTargetsRefresh}
                  tags={tags}
                  isPipelineEnabled={isPipelineEnabled}
                  onTogglePipelineView={togglePipelineView}
                  campaignStatusPreset={
                    campaignStatusPresets.find(
                      statusPreset =>
                        campaignData.info.campaign_status_preset ==
                        statusPreset.id
                    )?.info?.campaign_statuses
                  }
                />
              ) : (
                <CampaignTargetsTable
                  campaign={campaignData}
                  campaignId={params.id}
                  refresh={campaignTargetsRefresh}
                  tags={tags}
                  tableLeft={
                    <Checkbox
                      toggle
                      className="pipeline"
                      name="toggle_pipeline"
                      checked={isPipelineEnabled}
                      onChange={togglePipelineView}
                      label="Pipeline View"
                    />
                  }
                />
              )}
            </>
          </Tab.Pane>
        ),
      },
      {
        key: "contacts",
        menuItem: "Contacts",
        render: () => (
          <Tab.Pane>
            <ContactsTable
              tableName="campaign_contacts"
              campaignId={parseInt(params.id)}
            />
          </Tab.Pane>
        ),
      },
      {
        key: "notes",
        menuItem: "Notes",
        render: () => (
          <Tab.Pane>
            <NotesView modelType="Campaign" modelId={params.id} fluid />
          </Tab.Pane>
        ),
      },
      {
        key: "notices",
        menuItem: "Notices",
        render: () => (
          <Tab.Pane>
            <NoticesTable campaignId={params.id} />
          </Tab.Pane>
        ),
      },
      {
        key: "emailSchedules",
        menuItem: "Email Schedules",
        render: () => (
          <Tab.Pane>
            <EmailSchedulesTable campaignId={params.id} />
          </Tab.Pane>
        ),
      },
      {
        key: "calls",
        menuItem: "Calls",
        render: () => (
          <Tab.Pane>
            <CallsTable campaignId={params.id} />
          </Tab.Pane>
        ),
      },
      {
        key: "cases",
        menuItem: "Cases",
        render: () => (
          <Tab.Pane>
            <CasesTable campaignId={params.id} />
          </Tab.Pane>
        ),
      },
      {
        key: "revenueOpportunities",
        menuItem: "Revenue Opportunities",
        render: () => (
          <Tab.Pane>
            <RevenueOpportunitiesTable campaignId={params.id} />
          </Tab.Pane>
        ),
      },
    ];
    panes = panes.filter(({ key }) => tabs.includes(key));
    return panes;
  };

  if (tabs.length === 0 || !campaignData.id) {
    return null;
  }

  const panes = getPanes();
  return (
    <Container fluid className="route">
      <RouteParamsManager
        defaultParams={{ tab: tabs[0] }}
        routeParams={routeParams}
        setRouteParams={updateRouteParams}
      />
      <Grid className="bg" divided style={{ height: "100%" }}>
        <Grid.Row columns="2">
          <Grid.Column width={3} className="aside">
            <BreadcrumbNav name={campaignData.name || "?"} />
            <LeftColumn
              campaignId={params.id}
              campaign={campaignData}
              deleteCampaign={deleteCampaign}
              fetchCampaign={fetchCampaign}
              handleRefresh={handleRefresh}
              tags={tags}
              fetchTags={fetchTags}
            />
          </Grid.Column>
          <Grid.Column width={13}>
            <div className="campaign-segment">
              <Tab
                panes={panes}
                activeIndex={activeIndex}
                onTabChange={handleTabChange}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default CampaignDetail;

import { applyFilters, doRequest } from "./helpers";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

export default class CaseService {
  static async getTable(filters = null) {
    let path = "/case/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getForFilters(filters = null) {
    let path = "/case/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async get(id) {
    return doRequest("GET", `/case/${id}`);
  }

  static async create(data) {
    return doRequest("POST", "/case/", { data: omitBy(data, isNil) });
  }

  static async update(id, data) {
    return doRequest("PUT", `/case/${id}`, { data });
  }

  static async delete(id) {
    return doRequest("DELETE", `/case/${id}`);
  }

  static async getInvestigationStatusesForFilters() {
    return doRequest("GET", "/case/statuses");
  }

  static async getCustomFields() {
    let path = `/case/custom_fields`;
    return doRequest("GET", path);
  }

  static async assignInvestigators(caseIds, userIds) {
    return doRequest("PUT", "/case/assign_investigators", {
      data: {
        case_ids: caseIds,
        user_ids: userIds,
      },
    });
  }

  static async createOrUpdateCampaignCase(data) {
    return doRequest("PUT", "/campaign_cases/", {
      data: data,
    });
  }

  static async removeCaseFromCampaign(data) {
    return doRequest("DELETE", "/campaign_cases/", {
      data: data,
    });
  }

  static async getCampaigns(caseId) {
    return doRequest("GET", `/case/${caseId}/campaigns`);
  }
}

import React from "react";
import {
  Checkbox,
  Dropdown,
  Container,
  Grid,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import _EmailSignatureModal from "./components/EmailSignatureModal";
import _DeleteEmailSignatureModal from "./components/DeleteEmailSignatureModal";
import EmailSignatureService from "../../services/EmailSignatures";
import {
  checkIsAuthorized,
  createDateTimeFormatter,
} from "../../components/helpers";
import CustomDropdown from "../../components/CustomDropdown";
import BaseTable from "../../components/BaseTable";
import AuthService from "../../services/Auth";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";

import "../../styles/table.scss";
import TagList from "../../components/TagList";
import setPageTitle from "../../helpers/title";

const EmailSignatureModal = withRoleCheck(_EmailSignatureModal, [
  ACL_RELATIONSHIPS.emailSignatures.read,
  ACL_RELATIONSHIPS.emailSignatures.create,
]);
const EditEmailSignatureModal = withRoleCheck(_EmailSignatureModal, [
  ACL_RELATIONSHIPS.emailSignatures.create,
  ACL_RELATIONSHIPS.emailSignatures.edit,
]);
const DeleteEmailSignatureModal = withRoleCheck(_DeleteEmailSignatureModal, [
  ACL_RELATIONSHIPS.emailSignatures.read,
  ACL_RELATIONSHIPS.emailSignatures.delete,
]);

class EmailSignatureTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = EmailSignatureService.getEmailSignatures;

    this.state = {
      ...this.state,
      header: "Email Signatures",
      headerIcon: "mail outline",
      className: "EmailSignature",
      tableName: "email_signatures",
      noDataText: "No Email Signatures found. Create one.",
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.emailSignaturesTags.create,
        ACL_RELATIONSHIPS.emailSignaturesTags.read,
        ACL_RELATIONSHIPS.emailSignaturesTags.delete,
      ]),
      createButton: (
        <EmailSignatureModal button fetchSignatures={this.fetchData} />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Email Signatures");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
      await this.fetchTags();
      await this.fetchCustomFields();
      this.setState({
        createButton: (
          <EmailSignatureModal
            button
            onSuccess={this.fetchData}
            fetchSignatures={this.fetchData}
            isNew={true}
          />
        ),
      });
    }
  }

  fetchCustomFields = async () => {
    const customFields = await EmailSignatureService.getCustomFields();
    this.setState(
      {
        customFields: [
          {
            accessorPrefix: "custom_fields",
            headerPrefix: "Email Signatures",
            fields: customFields,
            model: "EmailSignature",
          },
        ],
      },
      this.updateFilterOptions
    );
  };

  deleteEmailSignature = async email_signature_id => {
    await EmailSignatureService.deleteEmailSignature(email_signature_id);
    this.fetchData();
  };

  formatDate = () => createDateTimeFormatter({ format: "YYYY-MM-DD HH:mm" });

  setColumns = () => {
    const footerColumns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 40,
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Email Signature Name",
        accessor: "name",
        Cell: props => (
          <Link
            to={{
              pathname: "/settings/email_signatures/" + props.original.id,
              state: {
                signatureId: props.original.id,
              },
            }}
          >
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: props => (
          <p>{this.formatDate("UTC")(props.original.created_at)}</p>
        ),
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        Cell: props => (
          <p>{this.formatDate("UTC")(props.original.updated_at)}</p>
        ),
      },
      {
        Header: "Actions",
        id: "actions",
        headerClassName: "centered",
        className: "centered",
        width: 100,
        Cell: ({ original: { id } }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditEmailSignatureModal
                fetchSignatures={this.fetchData}
                isClone={false}
                emailSignatureId={id}
              />
              <DeleteEmailSignatureModal
                menuTrigger
                onConfirmDelete={() => this.deleteEmailSignature(id)}
              />
              <EmailSignatureModal
                fetchSignatures={this.fetchData}
                isClone={true}
                emailSignatureId={id}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(footerColumns);
  };

  updateFilterOptions = async () => {
    const { tags, customFields } = this.state;

    let filters = [
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
    ];
    customFields.forEach(field => {
      filters.push({
        key: `search:custom:${field.model}:${field}`,
        title: field,
        type: "input",
        model: field.model,
      });
    });
    this.setState({ filters });
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default EmailSignatureTable;

import React, { createRef } from "react";
import { Container, Segment, Button, Dropdown } from "semantic-ui-react";

import BaseTable from "../../components/BaseTable";
import TagCategoryService from "../../services/TagCategory";
import setPageTitle from "../../helpers/title";
import AuthService from "../../services/Auth";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import FormModal from "../../components/modals/FormModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import TagCategoryForm from "./forms/TagCategoryForm";
import CustomDropdown from "../../components/CustomDropdown";

const CreateTagCategoryModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.tagCategory.create,
]);

const UpdateTagCategoryModal = withRoleCheck(FormModal, [
  ACL_RELATIONSHIPS.tagCategory.edit,
]);

const DeleteTagCategoryModal = withRoleCheck(ConfirmationModal, [
  ACL_RELATIONSHIPS.tagCategory.delete,
]);

class TagCategoryTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = TagCategoryService.getTagCategoriesTable;

    this.updateModal = createRef();
    this.deleteModal = createRef();

    this.state = {
      ...this.state,
      header: "Tag Categories",
      headerIcon: "tags",
      className: "TagCategory",
      tableName: "tag_categories",
      noDataText: "No Tag Categories found. Try adjusting your filters.",
      createButton: (
        <CreateTagCategoryModal
          onSuccess={this.fetchData}
          modelType={"Tag Category"}
          FormComponent={TagCategoryForm}
        />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Tag Categories");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  deleteTagCategory = async tagCategoryId => {
    await TagCategoryService.deleteTagCategory(tagCategoryId);
    this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Actions",
        id: "actions",
        headerClassName: "centered non-sortable",
        className: "centered action-menu",
        sortable: false,
        minWidth: 60,
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <UpdateTagCategoryModal
                dropdown
                onSuccess={this.fetchData}
                modelType={"Tag Category"}
                model={props.original}
                FormComponent={TagCategoryForm}
              />
              <DeleteTagCategoryModal
                actionDescription="Delete Tag Category"
                buttonColor="grey"
                onConfirm={() => this.deleteTagCategory(props.original.id)}
                menuTrigger
                icon="trash"
                warning
              >
                <p>Are you sure you want to delete this Tag Category?</p>
              </DeleteTagCategoryModal>
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default TagCategoryTable;

import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  List,
  Modal,
  Segment,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DataJobService from "../../../../services/DataJob";
import RuvixxForm from "../../../../components/RuvixxForm";
import FilePicker from "../../../../components/FilePicker";
import UploadProgressBar from "../../components/UploadProgressBar";
import UploadManager from "../../../../components/UploadManager";
import Constants from "../../../../constants/Constants";
import { handleDownload } from "../../helpers";
import CampaignService from "../../../../services/Campaign";
import { sortByKey } from "../../../../components/helpers";
import ValidationReport from "../ValidationReport";

const { IMPORT_TYPES } = Constants;

const timeoutMilis = 2000;

let importAborted = false;

const ImportOptions = ({ showHide, handleHide }) => {
  const getImportTemplate = async () => {
    let guidelines = [];
    let fields = [];
    const rawTemplate = await DataJobService.getImportTemplate();

    for (const row in rawTemplate) {
      fields.push(row);
      guidelines.push([row, rawTemplate[row]]);
    }

    fields.shift(); // Remove ["Import Field", "Description"] column

    const templateConfig = [
      {
        data: [fields],
        sheetName: `Data Import Template`,
      },
      {
        data: guidelines,
        sheetName: `Data Import Guidelines`,
      },
    ];

    handleDownload(templateConfig, `Data Import Template.xlsx`);
  };

  return (
    <div style={{ display: "flex" }}>
      <Button onClick={getImportTemplate}>
        Download Data Import Template (.xlsx)
      </Button>
      {showHide && (
        <Button onClick={handleHide} compact size="tiny">
          Hide Window
        </Button>
      )}
    </div>
  );
};

class LegacyImportForm extends Component {
  static propTypes = {
    campaignId: PropTypes.number,
    onSuccess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.uploadManager = new UploadManager();
    this.uploadManager.addProgressListener(this.handleProgress);
    this.uploadManager.addSuccessListener(this.handleUpload);
    this.state = {
      dataJobId: null,
      stage: "init",
      status: null,
      validation: null,
      duplicateCheck: null,
      import: null,
      isImportComplete: false,
      isImportHidden: false,
      importFailed: false,
      name: null,
      error: false,
      success: false,
      errorMessage: "",
      files: [],
      shouldReplace: false,
      importConfirmed: false,
      campaignId: null,
      campaigns: [],
    };
  }

  componentDidMount() {
    if (this.props.confirmId) {
      this.setState({
        stage: "importing",
        dataJobId: this.props.confirmId,
      });
      setTimeout(() => {
        this.monitor(this.props.confirmId);
      }, timeoutMilis);
    }
    this.fetchCampaigns();
  }

  componentWillUnmount() {
    const { stage, files, dataJobId, isImportComplete, isImportHidden } = {
      ...this.state,
    };
    if (isImportHidden) {
      return;
    } else if (stage === "uploading") {
      files.forEach(this.uploadManager.removeUpload);
    } else if (dataJobId && !isImportComplete) {
      DataJobService.abortImport(dataJobId);
      DataJobService.confirmImport(dataJobId, false, false);
    }
  }

  fetchCampaigns = async () => {
    const rest = await CampaignService.getCampaignsForFilters();
    const sortedCampaigns = sortByKey(
      rest.filter(c => c.id > 0),
      "name"
    );
    const noneCampaign = { id: "none", name: "None" };
    sortedCampaigns.unshift(noneCampaign);
    this.setState({
      campaigns: sortedCampaigns,
    });
  };

  startUpload = async (files, event, dropped) => {
    if (!files) {
      this.setState({
        success: false,
        name: null,
        error: false,
        errorMessage: null,
      });
      return;
    }
    if (files.length === 0) {
      this.setState({
        success: false,
        name: null,
        error: true,
        errorMessage: "No file was selected.",
      });
      return;
    }
    if (files.length > 1) {
      this.setState({
        success: false,
        name: null,
        error: true,
        errorMessage: "More than file selected",
      });
      return;
    }
    const file = files[0];
    let errorMessage = null;
    let isFileValid = true;
    if (dropped) {
      const validTypes = ["officedocument.spreadsheetml", "ms-excel"];
      isFileValid = !!file && validTypes.some(type => file.type.includes(type));
      errorMessage = !isFileValid
        ? `File must be a xlsx or xls for import.`
        : null;
    }

    this.setState({
      isFileValid: true,
      success: false,
      name: file.name,
      isImportComplete: false,
      error: !isFileValid,
      errorMessage,
    });

    if (isFileValid) {
      this.setState({
        stage: "uploading",
      });
      try {
        await this.uploadManager.uploadFiles(files);
      } catch (error) {
        this.setState({
          stage: "init",
          error: true,
          errorMessage: "message" in error ? error.message : error.toString(),
        });
      }
    }
  };

  handleProgress = files => {
    this.setState({
      files: [...files], // careful, not enough to be considered immutable...
    });
  };

  handleUpload = async file => {
    this.setState({ file });
    this.setState({
      stage: "uploadFinished",
    });
    // enable "continue" button
    this.setState({ fileReady: true });
  };

  submitForValidation = async () => {
    let { file, campaignId } = this.state;
    campaignId = campaignId === "none" ? null : campaignId;
    try {
      const dataJobId = (
        await DataJobService.createImportJob(campaignId, [file.attachment])
      ).id;
      this.setState({ stage: "importing", dataJobId });
      // Kick off import job
      // await DataJobService.importData(dataJobId, campaignId, type);
      setTimeout(() => {
        this.monitor(dataJobId);
      }, timeoutMilis);
    } catch (error) {
      this.setState({
        stage: "failed",
        importFailed: true,
        success: false,
        error: true,
        errorMessage: "message" in error ? error.message : error.toString(),
      });
      return;
    }
  };

  monitor = async jobId => {
    try {
      const dataJob = await DataJobService.getDataJob(jobId);
      const info = dataJob.info;
      if (info && info.hasOwnProperty("is_import")) {
        if (
          info.import &&
          info.import.hasOwnProperty("is_error") &&
          info.import.is_error
        ) {
          this.setState({
            stage: "failed",
            success: false,
            error: true,
            errorMessage: info.import.error_msg,
          });
        } else {
          const status = info.log_status;
          const validation = info.validation;
          const importStep = info.import;
          const stage = this.state.stage;

          this.setState({
            campaignId: dataJob.campaign_id ? dataJob.campaign_id : "none",
            status,
            validation,
            importStep,
          });

          if (stage !== "importing" || importAborted) {
            return;
          }
          if (status === "validation complete") {
            this.setState({
              stage: "validation_complete",
            });
          }
          // TODO: don't poll when paused in ready state
          if (status !== "import complete") {
            setTimeout(() => {
              this.monitor(jobId);
            }, timeoutMilis);
          } else {
            this.setState({
              isImportComplete: true,
              stage: "finished",
            });
            return;
          }
        }
      }
    } catch (error) {
      this.setState({
        stage: "init",
        importFailed: true,
        success: false,
        error: true,
        errorMessage: "message" in error ? error.message : error.toString(),
      });
    }
  };

  handleCancelRequest = () => {
    if (["uploading", "importing"].includes(this.state.stage)) {
      this.setState({ cancelRequest: true });
    } else {
      this.props.refreshTable();
      this.props.onClose();
    }
  };

  handleContinue = () => {
    this.setState({ cancelRequest: false });
  };

  handleAbort = () => {
    const { dataJobId } = this.state;
    const { type } = this.props;

    if (dataJobId) {
      DataJobService.abortImport(dataJobId, type);
    }

    importAborted = true;
    this.props.refreshTable();
    this.props.onClose();
  };

  handleHide = () => {
    this.setState(
      {
        isImportHidden: true,
      },
      () => {
        this.props.refreshTable();
        this.props.onClose();
      }
    );
  };

  handleConfirm = async () => {
    await DataJobService.confirmImport(
      this.state.dataJobId,
      true,
      this.state.shouldReplace
    );
    // restart the monitor
    this.setState({
      importConfirmed: true,
      stage: "importing",
    });
    setTimeout(() => {
      this.monitor(this.state.dataJobId);
    }, timeoutMilis);
  };

  toggleReplace = () => {
    this.setState(prevState => ({
      shouldReplace: !prevState.shouldReplace,
    }));
  };

  setCampaignId = campaignId => {
    this.setState({
      campaignId,
    });
  };

  handleCampaignSelect = (_, { value }) => {
    this.setCampaignId(value);
  };

  render() {
    const { type, confirmId } = this.props;
    const {
      dataJobId,
      status,
      stage,
      validation,
      importStep,
      isImportComplete,
      error,
      success,
      errorMessage,
      successMessage,
      files,
      cancelRequest,
      shouldReplace,
      importConfirmed,
      campaignId,
      file,
    } = this.state;
    return (
      <RuvixxForm
        ready={isImportComplete || !!this.state.campaignId}
        error={error}
        errorMessage={errorMessage}
        success={success}
        successMessage={successMessage}
        onSuccess={isImportComplete ? this.props.onSuccess : null}
        onCancel={!isImportComplete ? this.handleCancelRequest : null}
        submitButtonText="DONE"
      >
        <Segment placeholder style={{ justifyContent: "flex-start" }}>
          <Grid columns="equal">
            <Grid.Column width={5}>
              <Form.Select
                search
                clearable
                required
                disabled={!!dataJobId}
                label="Choose a Campaign"
                style={{ marginTop: "1em" }}
                options={this.state.campaigns.map(({ id, name }) => ({
                  key: id,
                  text: name,
                  value: id,
                }))}
                onChange={this.handleCampaignSelect}
                value={this.state.campaignId}
                placeholder="Choose a Campaign"
              />
              {!confirmId && (
                <FilePicker
                  onSelect={this.startUpload}
                  disabled={isImportComplete}
                  accept={
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  }
                />
              )}
            </Grid.Column>
            <Grid.Column>
              <Segment style={{ minHeight: "200px" }}>
                {stage === "uploading" ? <p>Uploading file...</p> : null}
                <UploadProgressBar uploads={files} />
                {stage === "importing" ? (
                  <p>Your import has begun processing...</p>
                ) : null}
                <List>
                  {validation ? (
                    validation.current_row < validation.max_rows ? (
                      <List.Item>
                        <List.Icon name="spinner" />
                        <List.Content>
                          Validating: {validation.current_row} row
                          {validation.current_row === 1
                            ? ""
                            : "s"} processed,{" "}
                          {validation.rows_with_error_or_warning} row
                          {validation.rows_with_error_or_warning === 1
                            ? " has"
                            : "s have"}{" "}
                          errors or warnings.
                          {validation.rows_with_error_or_warning ? (
                            <ValidationReport dataJobId={dataJobId} />
                          ) : null}
                        </List.Content>
                      </List.Item>
                    ) : (
                      <List.Item>
                        <List.Icon name="check" />
                        <List.Content>
                          File validated. {validation.max_rows} row
                          {validation.max_rows === 1 ? "" : "s"} processed,{" "}
                          {validation.rows_with_error_or_warning} row
                          {validation.rows_with_error_or_warning === 1
                            ? " has"
                            : "s have"}{" "}
                          errors or warnings.
                          {validation.rows_with_error_or_warning ? (
                            <ValidationReport dataJobId={dataJobId} />
                          ) : null}
                        </List.Content>
                      </List.Item>
                    )
                  ) : null}

                  {!dataJobId && campaignId && file && (
                    <List.Item>
                      <List.Content>
                        <Button
                          primary
                          compact
                          size="tiny"
                          floated="right"
                          onClick={this.submitForValidation}
                          disabled={dataJobId || !campaignId || !file}
                        >
                          Begin Validation
                        </Button>
                      </List.Content>
                    </List.Item>
                  )}

                  {validation && status === "validation complete" && (
                    <List.Item>
                      <List.Icon name="check" inverted />
                      <List.Content>
                        <Checkbox
                          toggle
                          checked={shouldReplace}
                          onChange={this.toggleReplace}
                          className="small"
                          disabled={importConfirmed}
                          label="Replace existing entries?"
                        />
                        <Button
                          primary
                          compact
                          size="tiny"
                          floated="right"
                          onClick={this.handleConfirm}
                          disabled={
                            importConfirmed ||
                            (!this.state.campaignId &&
                              type === IMPORT_TYPES.DATA)
                          }
                        >
                          Confirm import{" "}
                          {validation.has_errors
                            ? "(excluding errored rows)"
                            : null}
                        </Button>
                      </List.Content>
                    </List.Item>
                  )}
                  {importStep &&
                    (importStep.current_row < importStep.max_rows ||
                    !isImportComplete ? (
                      <List.Item>
                        <List.Icon name="spinner" />
                        <List.Content>
                          Importing: {importStep.current_row} row
                          {importStep.current_row === 1 ? "" : "s"} imported
                          into database.
                        </List.Content>
                      </List.Item>
                    ) : (
                      <List.Item>
                        <List.Icon name="check" />
                        <List.Content>Import complete.</List.Content>
                      </List.Item>
                    ))}
                </List>
              </Segment>
              <ImportOptions
                handleHide={this.handleHide}
                type={type}
                showHide={
                  stage !== "init" &&
                  stage !== "finished" &&
                  stage !== "uploading"
                }
              />
            </Grid.Column>
          </Grid>
        </Segment>
        {cancelRequest ? (
          <Modal open={true} onClose={this.handleContinue} size="small">
            <Header icon="warning sign" content="Abort import?" />
            <Modal.Content>
              Cancelling this window will abort the process and you will have to
              start again.
            </Modal.Content>
            <Modal.Actions>
              <Button className="warning cancel" onClick={this.handleContinue}>
                <Icon name="remove" /> No
              </Button>
              <Button
                className="warning confirm"
                inverted
                onClick={this.handleAbort}
              >
                <Icon name="checkmark" /> Yes
              </Button>
            </Modal.Actions>
          </Modal>
        ) : null}
      </RuvixxForm>
    );
  }
}

export default LegacyImportForm;

import React from "react";
import PropTypes from "prop-types";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";

const DeleteEmailModal = ({ onConfirmDelete, disabled, icon }) => {
  return (
    <ConfirmationModal
      actionDescription="Delete"
      icon={icon}
      onConfirm={onConfirmDelete}
      disabled={disabled}
      menuTrigger
      warning
    >
      <p>Are you sure you want to delete this Email?</p>
    </ConfirmationModal>
  );
};

DeleteEmailModal.propTypes = {
  onConfirmDelete: PropTypes.func.isRequired,
  icon: PropTypes.string,
};

export default DeleteEmailModal;

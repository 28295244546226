import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import debounce from "lodash/debounce";

const InputComponent = props => {
  const {
    name,
    label,
    type = "text",
    initialValue = "",
    onValueChange,
  } = props;

  const [value, setValue] = useState(initialValue);

  const debouncedChange = React.useCallback(
    debounce(eventValue => {
      const eventParams = {
        target: {
          name,
          value: eventValue,
        },
      };
      onValueChange(eventParams);
    }, 500),
    [name, onValueChange]
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = event => {
    setValue(event.target.value);
    debouncedChange(event.target.value);
  };

  return (
    <Form.Input
      name={name}
      label={label}
      type={type}
      value={value}
      onChange={handleChange}
    />
  );
};

export default InputComponent;

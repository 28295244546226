import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import CaseService from "../../services/Case";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import RuvixxSelect from "../../components/RuvixxSelect";
import UserService from "../../services/User";
import CampaignService from "../../services/Campaign";

export const UpdateCampaignCaseModal = forwardRef(
  (
    {
      campaignId,
      entityId,
      modalHeader,
      onSuccess,
      showCaseSelector = false,
      filterCampaigns = false,
      filterEntities = false,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [selectedCaseIds, setSelectedCaseIds] = useState([]);
    const [selectedCampaignId, setSelectedCampaignId] = useState("");
    const [assigneeId, setAssigneeId] = useState("");
    const [invalidCaseMessages, setInvalidCaseMessages] = useState([]);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
    }));

    const handleOpen = ({ caseIds, invalidCases } = {}) => {
      setSelectedCaseIds(caseIds);
      if (invalidCases?.length > 0) {
        const invalidCaseMessages = invalidCases.map(
          invalidCase =>
            `${invalidCase.name} (ID: ${invalidCase.case_id}) can't be added to the campaign because it doesn't have an entity associated yet.`
        );
        setInvalidCaseMessages(invalidCaseMessages);
      }
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      onSuccess();
    };

    const handleCaseSelect = (e, { value }) => {
      setSelectedCaseIds(value);
    };

    const handleCampaignSelect = (e, { name, value }) => {
      setSelectedCampaignId(value);
    };
    const handleAssigneeSelect = (e, { name, value }) => {
      setAssigneeId(value);
    };

    const handleSubmit = useCallback(async () => {
      await CaseService.createOrUpdateCampaignCase({
        case_ids: selectedCaseIds,
        campaign_id: parseInt(campaignId) || parseInt(selectedCampaignId),
        assigned_user_id: assigneeId,
      });
      handleClose();
    }, [selectedCaseIds, campaignId, assigneeId, selectedCampaignId]);

    return (
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>{modalHeader}</Modal.Header>
        <Modal.Content>
          {invalidCaseMessages.length > 0 && (
            <Message negative>
              <Message.Header>
                The cases listed below cannot be added:
              </Message.Header>
              <ul>
                {invalidCaseMessages.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            </Message>
          )}
          <Form>
            {(!selectedCaseIds?.length || showCaseSelector) && (
              <RuvixxSelect
                name="caseIds"
                multiple
                label="Case"
                value={selectedCaseIds}
                onChange={handleCaseSelect}
                placeholder="Select Case"
                queryFn={async filters => {
                  filters["has_entity"] = true;
                  if (entityId && filterEntities) {
                    filters["entity_id"] = entityId;
                  }
                  if (campaignId && filterCampaigns) {
                    filters["campaign_id"] = [campaignId];
                  }
                  return CaseService.getForFilters(filters);
                }}
              />
            )}
            {!campaignId && (
              <RuvixxSelect
                name={"campaignIds"}
                queryFn={CampaignService.getCampaignsForFilters}
                label={"Campaign"}
                value={selectedCampaignId}
                onChange={handleCampaignSelect}
              />
            )}
            <RuvixxSelect
              inline
              placeholder="Select Assignee"
              label="Assignee"
              name="assignee"
              onChange={handleAssigneeSelect}
              queryFn={UserService.getUsersForFilters}
              search
              value={assigneeId}
            />
            {!campaignId && !entityId && (
              <div>
                Note: Associating this case to this campaign will also associate
                its entity if it isn't already.
              </div>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            secondary
            size="tiny"
            onClick={handleClose}
            content="Cancel"
          />
          <Button
            size="tiny"
            secondary
            onClick={handleSubmit}
            content="Submit"
          />
        </Modal.Actions>
      </Modal>
    );
  }
);

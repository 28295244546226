import React, { useState } from "react";
import { Button, Header, Modal, Popup } from "semantic-ui-react";
import AddReferralForm from "./AddReferralForm";

export default function AddReferralModal({
  contact_id,
  entity_id,
  campaign_id,
  addContactToQueue,
  dialSessionId,
  privateSession,
  disabled,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const onSuccess = async => handleClose();

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="large"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        <Popup
          content={"To Add a Referal a Contact and a Campaign must be assigned"}
          on="hover"
          onOpen={() => {
            setPopupIsOpen(true);
          }}
          onClose={() => {
            setPopupIsOpen(false);
          }}
          open={popupIsOpen}
          trigger={
            <span>
              <Button
                size="tiny"
                onClick={handleOpen}
                className="white"
                content="Add Referral"
                disabled={disabled}
              />
            </span>
          }
        ></Popup>
      }
    >
      <Header content={"Add Referral"} />
      <Modal.Content>
        <AddReferralForm
          onSuccess={onSuccess}
          contact_id={contact_id}
          entity_id={entity_id}
          campaign_id={campaign_id}
          addContactToQueue={addContactToQueue}
          dialSessionId={dialSessionId}
          privateSession={privateSession}
        />
      </Modal.Content>
    </Modal>
  );
}

import React, { useState } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import TwilioService from "../../services/twilio";
import useInterval from "../../hooks/useInterval";

const VALIDATING = "validating";
const SUCCESS = "success";
const FAILED = "failed";

export default function ValidatePhoneNumberModal({ phoneNumber, onClose }) {
  const { id: phoneId, number, ext, changed } = phoneNumber;
  const [validationAttempted, setValidationAttempted] = useState(false);
  const [validationCode, setValidationCode] = useState("");
  const [validationStatus, setValidationStatus] = useState("");
  const [validationError, setValidationError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  useInterval(
    async () => {
      if (modalOpen && !changed && phoneId) {
        const validationStatus = await TwilioService.checkValidationStatus(
          phoneId
        );
        if (validationStatus === SUCCESS) {
          setValidationStatus(SUCCESS);
        } else if (validationStatus === FAILED) {
          setValidationStatus(FAILED);
        }
        setValidationAttempted(true);
      }
    },
    modalOpen && !changed && phoneId ? 1000 : null
  );

  const handleOpen = () => {
    setModalOpen(true);
    if (!changed && phoneId) {
      setValidationStatus(VALIDATING);
      TwilioService.validateNumber(phoneId)
        .then(({ code }) => {
          if (code === -1) {
            setValidationStatus(SUCCESS);
          }
          setValidationCode(code);
        })
        .catch(error => {
          setValidationStatus(FAILED);
          setValidationError(error.message);
        })
        .finally(() => {
          setValidationAttempted(true);
        });
    }
  };

  const handleClose = () => {
    if (validationAttempted) {
      onClose(validationStatus);
    }
    setModalOpen(false);
  };

  const renderContent = () => {
    if (changed || !phoneId) {
      return <div>Numbers must be saved to profile before validating.</div>;
    }
    if (validationCode.toString() === "-1") {
      return (
        <div>
          {number}
          {ext ? `x${ext}` : ""} is already verified.
        </div>
      );
    }

    if (validationStatus === SUCCESS) {
      return <div>Validation Successful!</div>;
    } else if (validationStatus === FAILED) {
      return <div>Validation Failed. {validationError}</div>;
    }

    return (
      <>
        <div>
          Twilio is calling {number}
          {ext ? `x${ext}` : ""} from +1 (415) 723-4000
        </div>
        <div>
          Enter the validation code when prompted:{" "}
          <span style={{ fontWeight: "bold" }}>{validationCode}</span>
        </div>
      </>
    );
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        <Button
          size="mini textLink"
          onClick={handleOpen}
          content="Validate Number"
        />
      }
    >
      <Header content={"Validate Number"} />
      <Modal.Content>{renderContent()}</Modal.Content>
    </Modal>
  );
}

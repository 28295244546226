import React from "react";
import { Button, Select, Input } from "semantic-ui-react";

import { TABLE_PAGE_SIZES } from "../../constants/Constants";

const PaginationWidget = ({
  pagination,
  onPageChange,
  onPageSizeChange,
  ...props
}) => {
  const { page, pageSize, pageCount, totalResults } = pagination;
  // Allow arbitrary page sizes
  const pageSizeOptions = (
    !TABLE_PAGE_SIZES.includes(pageSize)
      ? TABLE_PAGE_SIZES.concat(pageSize).sort((a, b) => a - b)
      : TABLE_PAGE_SIZES
  ).map(i => ({
    key: i,
    value: i,
    text: `${i} rows`,
  }));

  const handleSelectPageSize = (_, { value }) => {
    onPageSizeChange(value);
  };
  const handleNextPage = () => {
    if (page == pageCount) {
      return;
    }
    onPageChange(page + 1);
  };

  const handlePreviousPage = () => {
    if (page == 1) {
      return;
    }
    onPageChange(page - 1);
  };

  const handlePageChange = (_, { value }) => {
    if (value < 1 || value > pageCount) {
      return;
    }
    onPageChange(value);
  };

  return (
    <div className="-pagination">
      <div className="-previous">
        <Button
          type="button"
          disabled={page === 1}
          className="-btn"
          onClick={handlePreviousPage}
          content="Previous"
        />
      </div>
      <div className="-center">
        <span className="-pageInfo">
          Page
          <div className="-pageJump">
            <Input
              type="number"
              value={page}
              onChange={handlePageChange}
              min={1}
              max={pageCount}
            />
          </div>{" "}
          of <span className="-totalPages">{pageCount}</span>
        </span>
        <Select
          inline
          className="-pageSizeOptions"
          onChange={handleSelectPageSize}
          options={pageSizeOptions}
          value={pageSize}
        />
      </div>
      <div className="-next">
        <Button
          type="button"
          disabled={page === pageCount}
          className="-btn"
          onClick={handleNextPage}
          content="Next"
        />
      </div>
    </div>
  );
};

export default PaginationWidget;

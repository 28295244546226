import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "semantic-ui-react";
import GroupForm from "./GroupForm";

const GroupModal = forwardRef(({ onSuccess }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [group, setGroup] = useState();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = group => {
    setGroup(group);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = () => {
    handleClose();
    onSuccess();
  };

  const title = `${!group ? "Add New" : "Edit"} Group`;

  return (
    <Modal
      size="small"
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      className="group-modal"
    >
      <Modal.Header content={title} />
      <Modal.Content>
        <GroupForm
          model={group}
          onSuccess={handleSuccess}
          onCancel={handleClose}
          submitButtonText={title}
        />
      </Modal.Content>
    </Modal>
  );
});

export default GroupModal;

import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import omit from "lodash/omit";

import "./FilterButtons.scoped.scss";

const FilterButtons = ({ filters, filterParams, updateFilterParams }) => {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    const buttons = Object.keys(filterParams).reduce(
      (buttons, title) => {
        const { type } = filters.find(filter => filter.title === title);
        const value = filterParams[title];
        const labelValue = (() => {
          switch (type) {
            case "dateRange":
              return ["from", "to"]
                .reduce((val, cur) => {
                  const date = value[cur];
                  if (!date) {
                    return val;
                  }
                  const label = `${cur} ${date.format("ll")}`;
                  return [...val, label];
                }, [])
                .join(" ");
            case "select":
              return value.map(({ text }) => text).join(", ");
            default:
          }
          return "";
        })();
        const label = `${title}: ${labelValue}`;
        const button = { title, label };
        return [...buttons, button];
      },
      [{ title: "", label: "Clear All" }]
    );
    setButtons(buttons);
  }, [filterParams]);

  const clearFilter = title => {
    let newFilterParams = {};
    if (title) {
      newFilterParams = omit(filterParams, title);
    }
    updateFilterParams(newFilterParams);
  };

  if (buttons.length < 2) {
    return null;
  }

  return (
    <div className="filterButtons">
      Filters:
      {buttons.map(({ title, label }, index) => (
        <Button
          key={index}
          basic
          size="mini"
          icon="close"
          content={label}
          onClick={() => clearFilter(title)}
        />
      ))}
    </div>
  );
};

export default FilterButtons;

import { doRequest, applyFilters } from "./helpers";

const getSmtpAccounts = async () => {
  return doRequest("GET", "/email_config/smtp/");
};

const getSmtpAccountsTable = async (filters = null) => {
  let path = "/email_config/smtp/table";
  path = applyFilters(filters, path);
  return doRequest("GET", path);
};

const getSmtpAccount = async id => {
  return doRequest("GET", `/email_config/smtp/${id}`);
};

const testSmtpAccount = async (
  email,
  username,
  password,
  server,
  port,
  encryption
) => {
  const domain = email.split("@").pop();
  let response = {};
  try {
    response = await doRequest("POST", "/email_config/smtp/test", {
      data: {
        username,
        password,
        server,
        domain,
        port,
        encryption,
        return_addr: email,
      },
    });
  } catch (error) {
    response = {
      success: false,
      message: error.message,
    };
  }
  return response;
};

const createSmtpAccount = async (
  email,
  username,
  password,
  server,
  port,
  encryption,
  isDefault
) => {
  const domain = email.split("@").pop();
  return doRequest("POST", "/email_config/smtp/", {
    data: {
      username,
      password,
      server,
      domain,
      port,
      encryption,
      return_addr: email,
      is_default: isDefault,
    },
  });
};

const updateSmtpAccount = async (
  id,
  { email, username, password, server, port, encryption, isDefault }
) => {
  const domain = email ? email.split("@").pop() : undefined;
  return doRequest("PUT", `/email_config/smtp/${id}`, {
    data: {
      username,
      password,
      server,
      domain,
      port,
      encryption,
      return_addr: email,
      is_default: isDefault,
    },
  });
};

const deleteSmtpAccount = async id => {
  return doRequest("DELETE", `/email_config/smtp/${id}`);
};

const getDefaultSMTPConfig = async (filters = null) => {
  let path = `/email_config/smtp/default`;
  path = applyFilters(filters, path);
  let response;

  try {
    response = await doRequest("GET", path);
  } catch (e) {
    if (e.message === "User does not have SMTP configurations") {
      response = false;
    }
  }

  return response;
};

const SmtpAccountService = {
  getSmtpAccounts,
  getSmtpAccountsTable,
  getSmtpAccount,
  testSmtpAccount,
  createSmtpAccount,
  updateSmtpAccount,
  deleteSmtpAccount,
  getDefaultSMTPConfig,
};

export default SmtpAccountService;

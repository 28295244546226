import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Button, Message, Modal } from "semantic-ui-react";
import NewEmailForm from "../forms/NewEmail";

const FollowupEmailModal = forwardRef(({ onSuccess }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [sentModalOpen, setSentModalOpen] = useState(false);
  const [formResponse, setFormResponse] = useState([]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = formResponse => {
    setFormResponse(formResponse);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSentModalOpen(false);
  };

  const handleSuccess = () => {
    setSentModalOpen(true);
    onSuccess();
  };

  return (
    <Modal open={modalOpen} onClose={handleClose} closeIcon>
      <Modal.Header>Send Followup Email</Modal.Header>
      <Modal.Content>
        <Modal
          open={sentModalOpen}
          onClose={handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <Message info>
              <Message.Header>Email Sent</Message.Header>
            </Message>
            <Button content="ok" onClick={handleClose} />
          </Modal.Content>
        </Modal>
        <NewEmailForm
          campaignId={formResponse.campaign_id}
          entities={[]}
          forEntity={false}
          contactId={formResponse.contact_id || 0}
          contactEmail={""}
          onSuccess={handleSuccess}
          followUp
        />
      </Modal.Content>
    </Modal>
  );
});

export default FollowupEmailModal;

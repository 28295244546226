import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Modal, Header } from "semantic-ui-react";
import ActionsForm from "./ActionsForm";

const ActionsModal = forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [option, setOption] = useState();
  const [index, setIndex] = useState();

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = (option, index) => {
    setOption(option);
    setIndex(index);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = () => {
    handleClose();
  };

  return (
    <Modal open={modalOpen} onClose={handleClose} size="tiny" closeIcon>
      <Header content="Edit Actions" />
      <Modal.Content>
        <ActionsForm
          option={option}
          optionIndex={index}
          {...props}
          onSuccess={handleSuccess}
        />
      </Modal.Content>
    </Modal>
  );
});

export default ActionsModal;

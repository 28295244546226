import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";

import "./TargetSelector.scoped.scss";
import taskConstants from "../../../constants/Task";
import UserService from "../../../services/User";
import ContactService from "../../../services/Contact";
import EntityService from "../../../services/Entity";
import CampaignService from "../../../services/Campaign";
import RuvixxSelect from "../../../components/RuvixxSelect";

const TargetSelector = ({
  targetType,
  setTargetType,
  targetId,
  setTargetId,
  campaignIds,
}) => {
  const [targetDropdown, setTargetDropdown] = useState(null);

  const handleTypeChange = async (_, { value }) => {
    const type = taskConstants.targets.find(({ id }) => id === value);
    setTargetType(type);
    setTargetId(null);
  };

  const handleTargetSelect = (e, { value }) => {
    setTargetId(value);
  };

  useEffect(() => {
    if (!targetType.id) {
      setTargetDropdown(null);
    } else {
      const { key } = targetType;
      let queryFn;
      switch (key) {
        case "entity":
          queryFn = EntityService.getEntitiesForFilters;
          break;
        case "contact":
          queryFn = ContactService.getContactsForFilters;
          break;
        case "campaign":
          queryFn = CampaignService.getCampaignsForFilters;
          break;
        case "user":
          queryFn = UserService.getUsers;
          break;
      }
      setTargetDropdown(
        <RuvixxSelect
          name={key}
          value={targetId}
          onChange={handleTargetSelect}
          placeholder={`Start typing to search for ${targetType.article} ${targetType.name}`}
          hideLabel={true}
          queryFn={queryFn}
          campaignIds={campaignIds}
        />
      );
    }
  }, [campaignIds, targetType.id, targetId]);

  return (
    <div className="targetSelector">
      <Form.Group>
        <Form.Field required inline>
          <label>Target</label>
        </Form.Field>
        <Form.Select
          fluid
          value={targetType.id}
          className="targetType"
          options={taskConstants.targets.map(({ id, name }) => ({
            key: id,
            value: id,
            text: name,
          }))}
          onChange={handleTypeChange}
        />
        {targetDropdown}
      </Form.Group>
    </div>
  );
};

export default TargetSelector;

import React from "react";

const ADDRESS_PARTS = [
  "street1",
  "street2",
  "city",
  "region",
  "postal_code",
  "country",
];

const formatAddress = address => {
  const addressComponents = [];
  ADDRESS_PARTS.forEach(part => {
    const component = address[part];
    if (component) {
      addressComponents.push(component);
    }
  });
  return addressComponents.join(", ");
};

const buildSidebarAddresses = entity => {
  const { addresses, primary_address_id } = entity;

  const sortedAddresses = addresses?.sort((a, b) => {
    if (!primary_address_id) {
      return 0;
    }
    if (a.id === primary_address_id) {
      return -1;
    }
    if (b.id === primary_address_id) {
      return 1;
    }
    return 0;
  });

  return sortedAddresses?.map((address, index) => {
    if (index == 0 && address.id === primary_address_id) {
      return <b>{formatAddress(address)}</b>;
    } else {
      return formatAddress(address);
    }
  });
};

export { formatAddress, buildSidebarAddresses };

import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import CustomFieldForm from "../../../components/forms/CustomFieldForm";
import {
  checkIsCustomFieldsReady,
  getCustomFieldsForSubmit,
  keyIsUnique,
} from "../../../components/helpers";
import RuvixxForm from "../../../components/RuvixxForm";
import RuvixxSelect from "../../../components/RuvixxSelect";
import CampaignService from "../../../services/Campaign";
import CustomFieldService from "../../../services/CustomField";
import EntityService from "../../../services/Entity";
import UserService from "../../../services/User";

class NewCampaignTargetForm extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    campaignId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      entity: null,
      assignee: "",
      searchQuery: null,
      custom: [],
      customFieldConfigs: [],
      users: [],
      entities: [],
      entitySearch: null,
      loadingEntities: false,
    };
  }

  handleSearchChange = async (e, { searchQuery }) => {
    this.setState({ searchQuery });
    await this.fetchUsers(searchQuery);
  };

  handleSelect = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  fetchUsers = async () => {
    const users = await UserService.getUsers();
    this.setState({
      users,
    });
  };

  fetchCustomFieldConfigs = async () => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel("CampaignTarget");

    this.setState({ customFieldConfigs: customFieldConfigs });
  };

  componentDidMount() {
    this.fetchUsers(); // don't await
    this.fetchCustomFieldConfigs();
  }

  handleUpdateCustomFieldConfigs = customFieldConfigs => {
    this.setState({ customFieldConfigs });
    this.handleUpdateCustomFields(
      customFieldConfigs
        .filter(cfc => cfc.required || cfc.selected)
        .map(cfc => ({ key: cfc.label, value: cfc.value }))
    );
  };

  handleUpdateCustomFields = customFields => {
    this.setState({ custom: customFields });
  };

  handleSubmit = async e => {
    const { entity, assignee, custom, customFieldConfigs } = this.state;
    const cust = getCustomFieldsForSubmit(customFieldConfigs, custom);
    try {
      await CampaignService.createCampaignTarget(
        this.props.campaignId,
        entity,
        assignee,
        cust
      );
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  isUnique = key => {
    return keyIsUnique({
      key: key,
      array: this.state.custom,
    });
  };

  render() {
    const { users, entity, assignee, custom, customFieldConfigs } = this.state;
    const userOptions = users.map(user => {
      return { key: user.id, text: user.full_name, value: user.email };
    });
    return (
      <RuvixxForm
        ready={!!entity && checkIsCustomFieldsReady(customFieldConfigs)}
        onSubmit={this.handleSubmit}
        onSuccess={this.props.onSuccess}
        successHeader="Campaign Target Added"
      >
        <RuvixxSelect
          required={true}
          label="Entity"
          name="entity"
          value={entity}
          onChange={this.handleSelect}
          queryFn={EntityService.getEntitiesForFilters}
        />
        <Form.Select
          inline
          placeholder="Select Assignee"
          label="Assignee"
          name="assignee"
          onChange={this.handleSelect}
          search
          options={userOptions}
          value={assignee}
        />
        {customFieldConfigs && (
          <CustomFieldForm
            modelType="CampaignTarget"
            customFields={custom}
            customFieldConfigs={customFieldConfigs}
            onUpdateCustomFieldConfigs={this.handleUpdateCustomFieldConfigs}
          />
        )}
      </RuvixxForm>
    );
  }
}

export default NewCampaignTargetForm;

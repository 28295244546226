import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Icon, List, Segment, Checkbox } from "semantic-ui-react";
import { getCampaignStatusName } from "../routes/campaign/components/CampaignStatusModal";
import store from "store";
import "./../styles/sidebar.scss";
import ContactService from "../services/Contact";
import EntityService from "../services/Entity";
import CaseService from "../services/Case";
import CONSTANTS from "constants/Constants";
import UserService from "services/User";

function SideBarCampaigns({
  campaignId,
  contactId,
  caseId,
  dialer,
  entityId,
  prefix = "",
  refreshSidebar,
}) {
  const [open, setOpen] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [showInactiveCampaigns, setShowInactiveCampaigns] = useState(false);

  const fetchCampaigns = useCallback(async () => {
    let _campaigns = [];
    if (contactId) {
      _campaigns = await ContactService.getCampaigns(contactId);
      _campaigns.sort(
        (a, b) => a.campaign_status_type - b.campaign_status_type
      );
    } else if (entityId) {
      _campaigns = await EntityService.getCampaigns(entityId);
    } else if (caseId) {
      _campaigns = await CaseService.getCampaigns(caseId);
    }

    if (campaignId && _campaigns.length > 0) {
      let index = _campaigns.findIndex(c => c.id === campaignId);
      _campaigns.unshift(_campaigns.splice(index, 1)[0]);
      _campaigns.sort(
        (a, b) => new Date(b.start_time) - new Date(a.start_time)
      );
    }
    setCampaigns(_campaigns);
  }, [campaignId]);

  useEffect(() => {
    fetchCampaigns();
    fetchUserSettings();
  }, [fetchCampaigns, contactId, entityId, caseId, refreshSidebar]);

  const fetchUserSettings = async () => {
    const { show_inactive_campaigns } =
      store.get("userAuth").sidebar_settings?.contacts || {};
    setShowInactiveCampaigns(show_inactive_campaigns);
  };

  const isInactiveStyle = campaign_status_type => {
    if (contactId) {
      return campaign_status_type !== CONSTANTS.CAMPAIGN_STATUS_TYPES.ACTIVE;
    }
  };

  const changeShowInactiveCampaigns = async checked => {
    await UserService.updateUserSidebarSettings(
      "contacts",
      "show_inactive_campaigns",
      checked
    );
    setShowInactiveCampaigns(checked);
  };

  const buildCampaignsList = () => {
    return campaigns
      .filter(({ campaign_status_type }) => {
        if (contactId) {
          if (!showInactiveCampaigns) {
            return (
              campaign_status_type === CONSTANTS.CAMPAIGN_STATUS_TYPES.ACTIVE
            );
          }
        }
        return true;
      })
      .map(
        (
          {
            campaign_id,
            entity_id,
            campaign_name,
            campaign_status,
            campaign_status_type,
            status_last_updated_at,
          },
          index
        ) => {
          return (
            <List.Item key={index}>
              <List.Header>
                <Link
                  style={
                    isInactiveStyle(campaign_status_type)
                      ? { color: "grey" }
                      : {}
                  }
                  to={
                    caseId
                      ? `/campaigns/${campaign_id}/cases`
                      : `/campaigns/${campaign_id}/target/${entity_id}`
                  }
                  {...(dialer ? { target: "_blank" } : {})}
                >
                  {campaign_id === campaignId && "*"}
                  {`${campaign_name} - (ID: ${campaign_id})`}
                </Link>
              </List.Header>
              <List.Content>
                {`Campaign Status: ${getCampaignStatusName(campaign_status)}`}
              </List.Content>
              <List.Content>
                {status_last_updated_at
                  ? `Status Last Updated: ${moment(
                      status_last_updated_at
                    ).format("YYYY-MM-DD")}`
                  : "Status Last Updated: N/A"}
              </List.Content>
            </List.Item>
          );
        }
      );
  };

  return campaigns.length ? (
    <Segment basic className="gray">
      <Accordion>
        <Accordion.Title
          active={open}
          disabled={campaigns.length === 0}
          index={0}
          onClick={() => setOpen(!open)}
          style={{ fontSize: "14px", fontWeight: "700" }}
        >
          {prefix} Campaigns
          <Icon name="dropdown" />
          {contactId && (
            <Checkbox
              style={{ fontSize: "11.3px" }}
              label="Show inactive campaigns"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
              checked={showInactiveCampaigns}
              onChange={(_, { checked }) =>
                changeShowInactiveCampaigns(checked)
              }
            />
          )}
        </Accordion.Title>
        <Accordion.Content active={open}>
          <List>{buildCampaignsList()}</List>
        </Accordion.Content>
      </Accordion>
    </Segment>
  ) : null;
}

export default SideBarCampaigns;

import React, { Component } from "react";
import { Divider, Form } from "semantic-ui-react";

import AlternateNameView from "../../../components/AlternateName";
import EmailAddresses from "../../../components/EmailsAddresses";
import CustomFieldForm from "../../../components/forms/CustomFieldForm";
import {
  checkIsCustomFieldsReady,
  extractCustom,
  getCustomFieldsForSubmit,
  keyIsUnique,
} from "../../../components/helpers";
import PhoneNumbers from "../../../components/PhoneNumbers";
import RuvixxForm from "../../../components/RuvixxForm";
import RuvixxSelect from "../../../components/RuvixxSelect";
import CONSTANTS from "../../../constants/Constants";
import CustomFieldService from "../../../services/CustomField";
import DepartmentService from "../../../services/Department";
import EntityService from "../../../services/Entity";

const blankEmail = {
  email: "",
  is_primary: false,
  enabled: true,
  subscribed: true,
};

const blankNumber = {
  is_primary: false,
  number: "",
  ext: "",
  type: CONSTANTS.CONTACT_PHONE_TYPES.DEFAULT,
  enabled: true,
  is_callable: true,
};

export default class EditContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: this.props.contact.full_name,
      preferredName: this.props.contact.preferred_name || "",
      emailAddresses:
        this.props.contact.email_addresses?.length > 0
          ? this.props.contact.email_addresses.map(e => ({ ...e }))
          : [{ ...blankEmail }],
      title: this.props.contact.title,
      phoneNumbers:
        this.props.contact.phone_numbers?.length > 0
          ? this.props.contact.phone_numbers.map(pn => ({ ...pn }))
          : [{ ...blankNumber }],
      custom: extractCustom(this.props.contact.custom_fields),
      customFieldConfigs: [],
      alternateNames: this.props.contact.alternate_names,
      departmentId: this.props.contact.department_id,
      error: false,
      errorMessage: "",
      errorTitle: "",
    };
  }

  handleSubmit = async e => {
    const {
      fullName,
      preferredName,
      emailAddresses,
      title,
      phoneNumbers,
      custom,
      alternateNames,
      departmentId,
      customFieldConfigs,
    } = this.state;
    const cust = getCustomFieldsForSubmit(customFieldConfigs, custom);
    const nonEmptyEmailAddresses = emailAddresses.filter((email, i) =>
      email.email?.trim()
    );
    const nonEmptyPhoneNumbers = phoneNumbers.filter((pn, i) =>
      pn.number?.trim()
    );
    await EntityService.editContact(
      this.props.entityId,
      this.props.contact.id,
      {
        full_name: fullName,
        preferred_name: preferredName,
        email_addresses: nonEmptyEmailAddresses,
        title: title,
        phone_numbers: nonEmptyPhoneNumbers,
        custom_fields: cust,
        alternate_names: alternateNames,
        department_id: departmentId || null,
      }
    );
  };

  fetchCustomFieldConfigs = async () => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel("Contact");
    this.setState({ customFieldConfigs });
  };

  handleUpdateCustomFieldConfigs = customFieldConfigs => {
    this.setState({ customFieldConfigs });
    this.handleUpdateCustomFields(
      customFieldConfigs
        .filter(cfc => cfc.required || cfc.selected)
        .map(cfc => ({ key: cfc.label, value: cfc.value }))
    );
  };

  handleUpdateCustomFields = customFields => {
    this.setState({ custom: customFields });
  };

  handleChange = (event, data) => {
    this.setState({
      [data.name]: data.type === "checkbox" ? data.checked : data.value,
      error: false,
    });
  };

  isUnique = key => {
    return keyIsUnique({
      key: key,
      array: this.state.custom,
    });
  };

  setAlternateNames = alternateNames => {
    this.setState({ alternateNames });
  };

  setDepartment = departmentId => {
    this.setState({ departmentId });
  };

  addPhoneNumber = (pn, error) => {
    if (error) {
      this.setState({
        error: true,
        errorMessage: error.message,
        errorTitle: error.title,
      });
      return;
    }
    let phoneNumbers = this.state.phoneNumbers;
    phoneNumbers.push(pn);
    this.setState({
      phoneNumbers: phoneNumbers,
      error: false,
    });
  };

  removePhoneNumber = index => {
    let { phoneNumbers } = this.state;
    phoneNumbers.splice(index, 1);
    this.setState({
      phoneNumbers: phoneNumbers,
    });
  };

  updatePhoneNumber = (phoneNumber, index) => {
    let { phoneNumbers } = this.state;
    phoneNumbers[index] = phoneNumber;
    this.setState({ phoneNumbers: phoneNumbers });
  };

  addEmailAddress = (email, error) => {
    if (error) {
      this.setState({
        error: true,
        errorMessage: error.message,
        errorTitle: error.title,
      });
      return;
    }
    let { emailAddresses } = this.state;
    emailAddresses.push(email);
    this.setState({
      emailAddresses: emailAddresses,
      error: false,
    });
  };

  removeEmailAddress = index => {
    let { emailAddresses } = this.state;
    emailAddresses.splice(index, 1);
    this.setState({
      emailAddresses: emailAddresses,
    });
  };

  updateEmailAddress = (email, index) => {
    let { emailAddresses } = this.state;
    emailAddresses[index] = email;
    this.setState({ emailAddresses: emailAddresses });
  };

  componentDidMount() {
    this.fetchCustomFieldConfigs();
  }

  render() {
    const {
      fullName,
      preferredName,
      emailAddresses,
      title,
      phoneNumbers,
      custom,
      customFieldConfigs,
      error,
      errorMessage,
      errorTitle,
      alternateNames,
      departmentId,
    } = this.state;

    const isReady =
      !!fullName &&
      (emailAddresses.length > 0 || phoneNumbers.length > 0) &&
      checkIsCustomFieldsReady(customFieldConfigs);
    return (
      <RuvixxForm
        ready={isReady}
        onSubmit={this.handleSubmit}
        onSuccess={this.props.onSuccess}
        error={error}
        errorMessage={errorMessage}
        errorTitle={errorTitle}
      >
        <Form.Input
          inline
          name="fullName"
          label="Full Name"
          required
          value={fullName}
          onChange={this.handleChange}
        />
        <Form.Input
          inline
          name="preferredName"
          label="Preferred Name"
          value={preferredName}
          onChange={this.handleChange}
        />
        <Form.Input
          inline
          name="title"
          label="Title"
          value={title}
          onChange={this.handleChange}
        />
        <RuvixxSelect
          placeholder="Pick a Department"
          label="Department"
          name="departmentId"
          value={departmentId}
          onChange={this.handleChange}
          queryFn={DepartmentService.getForFilters}
          createFn={DepartmentService.createDepartment}
          allowAdditions
          clearable
          setValue={value => this.setDepartment(value.id)}
        />
        <Form.Field>
          <label>Email Addresses</label>
        </Form.Field>
        <EmailAddresses
          key={emailAddresses}
          emailAddresses={emailAddresses}
          addEmailAddress={this.addEmailAddress}
          removeEmailAddress={this.removeEmailAddress}
          updateEmailAddress={this.updateEmailAddress}
        />
        <Divider hidden />
        <Form.Field>
          <label>Phone Numbers</label>
        </Form.Field>
        <PhoneNumbers
          phoneNumbers={phoneNumbers}
          addPhoneNumber={this.addPhoneNumber}
          removePhoneNumber={this.removePhoneNumber}
          updatePhoneNumber={this.updatePhoneNumber}
        />
        <Divider hidden />
        <AlternateNameView
          alternateNames={alternateNames}
          setAlternateNames={this.setAlternateNames}
          type={"Contact"}
        />
        {customFieldConfigs && (
          <CustomFieldForm
            modelType="Contact"
            customFields={custom}
            customFieldConfigs={customFieldConfigs}
            onUpdateCustomFieldConfigs={this.handleUpdateCustomFieldConfigs}
          />
        )}
      </RuvixxForm>
    );
  }
}

import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Header, Modal, Form } from "semantic-ui-react";
import CaseDomainService from "../../services/CaseDomain";
import RuvixxForm from "../RuvixxForm";

const CaseDomainModal = forwardRef(({ fetchData }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [caseDomain, setCaseDomain] = useState({});

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = caseDomain => {
    if (!caseDomain) {
      caseDomain = {
        name: "",
        computer_domain: true,
        email_domain: true,
      };
    }
    setCaseDomain(caseDomain);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const onSuccess = async () => {
    fetchData();
    handleClose();
  };

  const handleChange = (_, data) => {
    const value = data.type === "checkbox" ? data.checked : data.value;
    const newCaseDomain = {
      ...caseDomain,
      [data.name]: value,
    };
    setCaseDomain(newCaseDomain);
  };

  const handleSubmit = async () => {
    if (caseDomain.id) {
      await CaseDomainService.edit(caseDomain.id, caseDomain);
    } else {
      await CaseDomainService.create(caseDomain);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={onSuccess}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <Header content={`${caseDomain.id ? "Edit" : "New"} Case Domain`} />
      <Modal.Content>
        <RuvixxForm
          ready={!!caseDomain.name}
          onSubmit={handleSubmit}
          onSuccess={onSuccess}
        >
          <Form.Input
            required
            inline
            name="name"
            label="Name"
            value={caseDomain.name}
            onChange={handleChange}
            autoFocus
          />
          <Form.Checkbox
            inline
            label="Computer Domain"
            name="computer_domain"
            checked={caseDomain.computer_domain}
            onChange={handleChange}
          />
          <Form.Checkbox
            inline
            label="Email Domain"
            name="email_domain"
            checked={caseDomain.email_domain}
            onChange={handleChange}
          />
        </RuvixxForm>
      </Modal.Content>
    </Modal>
  );
});

export default CaseDomainModal;

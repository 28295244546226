import React from "react";

import "./../styles/sidebar.scss";
import { Header, List, Segment } from "semantic-ui-react";
import CustomItems from "./CustomItems";

function SideBarCustomFields({ header, custom }) {
  return (
    <Segment basic className="gray custom">
      <Header>{header || "Custom Fields"}</Header>
      <List>
        <CustomItems items={custom} />
      </List>
    </Segment>
  );
}

export default SideBarCustomFields;

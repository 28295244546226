import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import CampaignService from "../../../services/Campaign";
import UserService from "../../../services/User";
import PropTypes from "prop-types";

class AssignUserForm extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    campaignTargets: PropTypes.array.isRequired,
    onSuccess: PropTypes.func.isRequired,
    assignedUserId: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      user: null,
    };
  }

  async componentDidMount() {
    const users = await UserService.getUsers();
    const { assignedUserId } = this.props;

    let options = users.map(user => ({
      key: user.id,
      value: user.email,
      text: user.full_name,
    }));
    this.setState({ options });
    if (assignedUserId) {
      this.setState({
        user: options.find(u => u.key === assignedUserId)?.value,
      });
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    const targets = this.props.campaignTargets;
    await CampaignService.assignCampaignTarget(this.props.campaignId, {
      entity_ids: targets,
      assigned_email: this.state.user,
    });
  };

  render() {
    const { options, user } = this.state;
    return (
      <RuvixxForm
        ready={!!user}
        onSubmit={this.handleSubmit}
        onSuccess={this.props.onSuccess}
        submitButtonText="Set User"
      >
        <Form.Select
          search
          onChange={this.handleChange}
          label="User"
          name="user"
          options={options}
          placeholder="User"
          value={user}
        />
      </RuvixxForm>
    );
  }
}

export default AssignUserForm;

import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Checkbox, List } from "semantic-ui-react";

import ACL_RELATIONSHIPS from "../../../acl-relationships";
import tablesSettings from "constants/Table";
import { checkIsAuthorized, tableTimestamp } from "../../../components/helpers";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import CallModal from "../../../components/modals/CallModal";
import _GenerateUrlModal from "../../../components/modals/GenerateUrlModal";
import TagList from "../../../components/TagList";
import {
  isContactEmailable,
  isContactCallable,
} from "../../../helpers/contacts";
import { getSession } from "../../../helpers/sessionHelpers";
import AuthService from "../../../services/Auth";
import CampaignService from "../../../services/Campaign";
import CampaignTargetService from "../../../services/CampaignTarget";
import ContactService from "../../../services/Contact";
import DepartmentService from "../../../services/Department";
import EntityService from "../../../services/Entity";
import _ContactsTable from "../../contact/ContactsTable";
import AddContactModal from "../../entity/components/AddContactModal";
import CampaignStatusModal from "../components/CampaignStatusModal";
import { SendEmailModal as _SendEmailModal } from "../components/Modals";
import ListTableColumn from "components/ListTableColumn";
import { EPOCH_TIMESTAMP } from "constants/Constants";
import RuvixxDate from "components/RuvixxDate";

const SendEmailModal = withRoleCheck(_SendEmailModal, [
  ACL_RELATIONSHIPS.campaignActionEmail.create,
]);

const GenerateUrlModal = withRoleCheck(_GenerateUrlModal, [
  ACL_RELATIONSHIPS.emailUrl.read,
  ACL_RELATIONSHIPS.emailUrl.generateUrl,
]);

const isCampaignTargetContact = (contact, campaignId) =>
  contact.campaign_targets.length > 0 &&
  contact.campaign_targets.filter(c => c.campaign_id === campaignId).length > 0;

class ContactsTable extends _ContactsTable {
  constructor(props) {
    super(props);

    let defaultFilterParams;
    if (this.props.entityId) {
      // Campaign Target detail
      this.queryMethod = CampaignTargetService.getContacts;
      this.queryArgs = [this.props.campaignId, this.props.entityId];
      this.downloadParams = {
        campaign_target_campaign_id: this.props.campaignId,
        entity_id: this.props.entityId,
      };
      defaultFilterParams = {
        entity_id: this.props.entityId,
        campaign_target_campaign_id: this.props.campaignId,
      };
    } else {
      // Campaign detail
      this.queryMethod = ContactService.getContacts;
      defaultFilterParams = {
        campaign_id: this.props.campaignId,
      };
    }

    const canSetCampaignTarget = checkIsAuthorized([
      ACL_RELATIONSHIPS.campaignTargetContacts.create,
      ACL_RELATIONSHIPS.campaignTargetContacts.delete,
    ]);

    const canCall = checkIsAuthorized([
      ACL_RELATIONSHIPS.dialSession.read,
      ACL_RELATIONSHIPS.dialSessionPrivate.create,
      ACL_RELATIONSHIPS.callQueueNextContact.read,
      ACL_RELATIONSHIPS.callQueueAddContact.create,
      ACL_RELATIONSHIPS.disposition.read,
      ACL_RELATIONSHIPS.emailUrlForms.read,
      ACL_RELATIONSHIPS.voicemailForFilters.read,
      ACL_RELATIONSHIPS.twilioToken.read,
      ACL_RELATIONSHIPS.tag.read,
      ACL_RELATIONSHIPS.user.read,
      ACL_RELATIONSHIPS.campaignStatuses.read,
      ACL_RELATIONSHIPS.twilioDisposition.create,
      ACL_RELATIONSHIPS.twilioAddToCall.create,
      ACL_RELATIONSHIPS.twilioRemoveFromCall.create,
      ACL_RELATIONSHIPS.twilioUpdateParticipant.create,
    ]);

    const canEditStatus = checkIsAuthorized([
      ACL_RELATIONSHIPS.campaignTargetChangeStatus.edit,
    ]);

    this.state = {
      ...this.state,
      tableName: this.props.tableName,
      noDataText: "No Contacts found.",
      activeSession: null,
      revenueStatusOptions: [],
      className: "Contact",
      canSetCampaignTarget,
      canCall,
      canEditStatus,
      defaultFilterParams,
      enableSearch: true,
      createButton: (
        <AddContactModal
          onSuccess={this.fetchData}
          entityId={this.props.entityId}
          campaignId={this.props.entityId ? null : this.props.campaignId}
          model="CampaignTargetContact"
        />
      ),
      includedInCampaign: {},
      exportTableName: "ContactsTable",
    };
  }

  async componentDidMount() {
    window.addEventListener("storage", this.storageUpdated);
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchTags();
      this.fetchUsers();
      this.fetchCustomFields();
    }
  }

  storageUpdated = event => {
    const sessionId = getSession();
    this.setState({
      activeSession: sessionId,
    });
  };

  addOrRemoveCampaignContact = async (contactId, isCampaignTargetContact) => {
    const { campaignId, entityId } = this.props;
    if (isCampaignTargetContact) {
      await CampaignService.removeContact(campaignId, entityId, contactId);
    } else {
      await CampaignService.addContact(campaignId, entityId, contactId);
    }
    const includedInCampaign = {
      ...this.state.includedInCampaign,
      [contactId]: !isCampaignTargetContact,
    };
    this.setState({
      includedInCampaign,
    });
  };

  fetchIncludedInCampaign = () => {
    const data = this.state.rows;
    const includedInCampaign = data.reduce((obj, contact) => {
      const isIncluded = isCampaignTargetContact(
        contact,
        this.props.campaignId
      );
      return { ...obj, [contact.id]: isIncluded };
    }, {});
    this.setState({
      includedInCampaign,
    });
  };

  doEveryLoad = () => {
    this.fetchIncludedInCampaign();
  };

  setColumns = () => {
    const { canCall, activeSession, voicemails, forms } = this.state;
    let columns = [
      {
        Header: props => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        accessor: this.state.primaryKey,
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: props => (
          <Checkbox
            onChange={this.handleChange}
            name={props.original.entity_name}
            id={props.value}
            checked={this.state.checked[props.value]}
          />
        ),
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Contact ID",
        accessor: "contact_id",
        width: 110,
        Cell: props => (
          <Link to={`/contacts/${props.original.id}`}>{props.original.id}</Link>
        ),
      },
      {
        Header: "Name",
        accessor: "full_name",
        Cell: props => (
          <Link to={"/contacts/" + props.original.id}>{props.value}</Link>
        ),
      },
      {
        Header: "Last Voicemail",
        accessor: "last_voicemail",
        Cell: ({ value }) => {
          if (value === EPOCH_TIMESTAMP || !value) {
            return null;
          }
          return <RuvixxDate date={value} />;
        },
      },
      {
        Header: "Alternate Names",
        accessor: "alternate_names",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ value }) => (
          <ListTableColumn
            itemKey={"id"}
            itemField={"name"}
            data={value || []}
          />
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Department",
        accessor: "department_name",
        className: "centered",
      },
      {
        Header: "Alternate Emails",
        accessor: "email_addresses",
        sortable: false,
        Cell: ({ value }) => (
          <ListTableColumn
            itemKey={"id"}
            itemField={"email"}
            filterFn={email => !email.is_primary}
            data={value || []}
          />
        ),
      },
      ...(!this.props.entityId
        ? [
            {
              Header: "Entity",
              accessor: "entity_name",
              Cell: props => (
                <Link to={"/entities/" + props.original.entity_id}>
                  {props.value}
                </Link>
              ),
            },
          ]
        : []),
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Phone Numbers",
        accessor: "phone_numbers",
        sortable: false,
        width: 120,
        Cell: props => (
          <List verticalAlign="middle">
            {props.value.map(phone => (
              <List.Item key={phone.id}>
                {phone.ext ? `${phone.number}x${phone.ext}` : phone.number}
              </List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Last Activity Date",
        accessor: "last_activity_date",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return null;
        },
      },

      {
        Header: "Actions",
        headerClassName: "centered",
        className: "centered",
        minWidth: 165,
        Cell: ({ original: contact }) => {
          const isIncluded = this.state.includedInCampaign[contact.id];
          const enableCallModal =
            canCall &&
            !activeSession &&
            isContactCallable(contact) &&
            isIncluded;
          return (
            <>
              <SendEmailModal
                campaignId={this.props.campaignId}
                entityId={this.props.entityId}
                contactId={contact.id}
                contactEmail={contact.email}
                disabled={!isIncluded || !isContactEmailable(contact)}
                iconOnly
              />
              <CallModal
                contactId={contact.id}
                campaignId={this.props.campaignId}
                disabled={!enableCallModal}
                iconOnly
              />
              <GenerateUrlModal
                campaignId={this.props.campaignId}
                contactId={contact.id}
                iconOnly
                disabled={!isIncluded}
              />
            </>
          );
        },
      },
      {
        Header: "Status",
        headerClassName: "centered",
        minWidth: tablesSettings.statusColumnMinWidth,
        className: "centered",
        Cell: ({ original: contact }) => {
          const isIncluded = this.state.includedInCampaign[contact.id];
          const campaignStatus = (() => {
            const campaignTarget = contact.campaign_targets.find(
              c => c.campaign_id === this.props.campaignId
            );
            return campaignTarget
              ? campaignTarget.campaign_status
              : { id: 0, name: "" };
          })();
          return (
            <CampaignStatusModal
              campaignStatus={campaignStatus}
              campaignId={this.props.campaignId}
              entityId={this.props.entityId || contact.entity_id}
              contactId={contact.id}
              updateData={this.fetchData}
              entityName={this.props.entityName || contact.entity_name}
              disabled={!isIncluded || !this.state.canEditStatus}
            />
          );
        },
      },
      {
        Header: "Effective Date",
        Cell: ({ original: contact }) => {
          const campaignTarget = contact.campaign_targets.filter(
            c => c.campaign_id === this.props.campaignId
          )[0];
          return campaignTarget ? campaignTarget.effective_date : "";
        },
      },
      {
        Header: "Status Last Updated",
        headerClassName: "centered",
        className: "centered",
        accessor: "status_last_updated_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return "N/A";
        },
      },
    ];
    if (this.props.entityId) {
      columns = [
        ...columns,
        {
          Header: "Include in Campaign",
          headerClassName: "centered",
          className: "centered",
          width: 140,
          Cell: ({ original: contact }) => {
            const isIncluded = this.state.includedInCampaign[contact.id];
            return (
              <Checkbox
                toggle
                name="isCampaignTarget"
                checked={isIncluded}
                disabled={!this.state.canSetCampaignTarget}
                onChange={async () => {
                  await this.addOrRemoveCampaignContact(contact.id, isIncluded);
                }}
              />
            );
          },
        },
      ];
    }
    this.initTableSettings(columns);
  };

  updateFilterOptions = async () => {
    const { users, tags, customFields } = this.state;
    const { campaignId } = this.props;
    let filters = [
      {
        key: "last_activity_date",
        title: "Last Activity",
        type: "dateRange",
      },
      {
        key: "effective_date",
        title: "Effective Date",
        type: "dateRange",
      },
      {
        key: "search:contact_name",
        title: "Contact Name",
        type: "input",
      },
      {
        key: "search:contact_email",
        title: "Contact Email",
        type: "input",
      },
      {
        key: "search:contact_phone",
        title: "Contact Phone",
        type: "input",
      },
      {
        key: "department_id",
        title: "Department Name",
        type: "ruvixxSelect",
        queryFn: DepartmentService.getForFilters,
      },
      {
        key: "account_manager_id",
        title: "Account Manager",
        type: "select",
        data: users,
      },
      {
        key: "assigned_user_id",
        title: "Assignee",
        type: "select",
        data: users,
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
      {
        key: "entity_id",
        title: "Entity",
        type: "ruvixxSelect",
        queryFn: EntityService.getEntitiesForFilters,
        props: {
          campaignIds: [campaignId],
        },
      },
      {
        title: "Outreach Permissions",
        key: "grid_1",
        type: "grid",
        props: {
          columns: 2,
          style: { width: "50%" },
        },
        componentProps: {
          type: "checkbox",
        },
        components: [
          { key: "is_callable", title: "Callable" },
          { key: "not_callable", title: "Not Callable" },
          { key: "is_emailable", title: "Emailable" },
          { key: "not_emailable", title: "Not Emailable" },
        ],
      },
    ];
    customFields.forEach(cf =>
      cf.fields.forEach(field => {
        filters.push({
          key: `search:custom:${cf.model}:${field}`,
          title: field,
          type: "input",
          model: cf.model,
        });
      })
    );
    this.setState({ filters });
  };

  render() {
    return this.renderTable();
  }
}

export default ContactsTable;

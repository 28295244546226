import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { applyFilters, doRequest } from "./helpers";

const basePath = `/constant_contact/account/`;

export default class ConstantContactAccountsService {
  static async getTable(filters) {
    let path = applyFilters(filters, basePath);
    return doRequest("GET", path);
  }

  static async getForFilters(filters = null) {
    let path = basePath + "for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async create(data) {
    return doRequest("POST", basePath, { data: omitBy(data, isNil) });
  }

  static async get(id) {
    return doRequest("GET", `${basePath}${id}`);
  }

  static async update(id, data) {
    return doRequest("PUT", `${basePath}${id}`, {
      data: omitBy(data, isNil),
    });
  }

  static async delete(id) {
    return doRequest("DELETE", `${basePath}${id}`);
  }

  static async getAuthURL(id) {
    return doRequest("GET", `${basePath}${id}/auth`);
  }
}

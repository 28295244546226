import React from "react";
import { Form, Select } from "semantic-ui-react";

import DateTimePicker from "../../../components/DateTimePicker";

const EmailDeliveryTime = ({
  timeZone,
  timeZones,
  sendNow,
  emailDeliveryTime,
  setEmailDeliveryTime,
}) => (
  <Form.Group grouped style={{ alignItems: "flex-start" }}>
    <Form.Group inline style={{ margin: 0 }}>
      <Form.Field label="Email Delivery Time" required />
      <Form.Radio
        label="Send Now"
        checked={sendNow}
        onChange={() => setEmailDeliveryTime({ sendNow: true })}
      />
      <Form.Radio
        label="Send Later"
        checked={!sendNow}
        onChange={() => setEmailDeliveryTime({ sendNow: false })}
      />
    </Form.Group>
    {!sendNow && (
      <Form.Group inline style={{ paddingTop: "1em" }}>
        <DateTimePicker
          required={false}
          name="emailDeliveryTime"
          label="Delivery Time & Date"
          dateTime={emailDeliveryTime}
          timeZone="UTC"
          dateFormat="YYYY-MM-DD"
          style={{ marginTop: "0.5em", marginLeft: "1em", width: "250px" }}
          setDateTime={emailDeliveryTime =>
            setEmailDeliveryTime({ emailDeliveryTime })
          }
        />
        <Form.Field style={{ marginTop: "0.5em", marginLeft: "1em", flex: 1 }}>
          <label style={{ display: "block" }}>Time Zone</label>
          <Select
            value={timeZone}
            options={timeZones}
            onChange={(_, { value: timeZone }) =>
              setEmailDeliveryTime({ timeZone })
            }
          />
        </Form.Field>
      </Form.Group>
    )}
  </Form.Group>
);

export default EmailDeliveryTime;

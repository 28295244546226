export const dialSessionNameFormatted = dialSessionName => {
  if (dialSessionName.startsWith("Contact ")) {
    const split_results = dialSessionName.split(" - ");
    const format_data = new Date(split_results[1]).toISOString().slice(0, 10);
    return `${split_results[0]} - ${format_data}`;
  } else {
    return dialSessionName;
  }
};

// Used setting the current dial session so a user doesn't open multiple
export const setSession = sessionId =>
  localStorage.setItem("active-session", sessionId);

export const getSession = () => localStorage.getItem("active-session");

export const removeSession = () => localStorage.removeItem("active-session");

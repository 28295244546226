import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Modal } from "semantic-ui-react";
import EditRoleForm from "./EditRoleForm";

const EditRoleModal = forwardRef(({ onSuccess }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [userIds, setUserIds] = useState([]);

  const handleOpen = userIds => {
    setUserIds(userIds);
    setModalOpen(true);
  };

  const handleClose = () => setModalOpen(false);

  const handleSubmit = () => {
    handleClose();
    onSuccess();
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  return (
    <Modal
      size="small"
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      className="user-modal"
    >
      <Modal.Header content="Change User roles" />
      <Modal.Content>
        <EditRoleForm
          userIds={userIds}
          onSuccess={handleSubmit}
          onCancel={handleClose}
        />
      </Modal.Content>
    </Modal>
  );
});

export default EditRoleModal;

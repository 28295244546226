import { useState, useCallback, useEffect } from "react";
import { getQueryParam, setQueryParam, initQueryParams } from "./helpers";

const useQueryParam = (key, defaultValue, appendParams) => {
  const [value, setValue] = useState();
  useEffect(() => {
    initQueryParams(checkAndUpdate);
  }, []);
  const updateValue = useCallback(
    (value, replace) => {
      setValue(value);
      setQueryParam(key, value, replace, appendParams);
    },
    [key]
  );
  const onSetValue = useCallback(
    newValue => {
      updateValue(newValue);
    },
    [key]
  );
  const checkAndUpdate = (forceUpdate = false) => {
    const queryParam = getQueryParam(key);
    if (queryParam !== value || queryParam === undefined || forceUpdate) {
      updateValue(queryParam || defaultValue, true);
    }
  };
  useEffect(() => {
    checkAndUpdate();
  }, [defaultValue, key, updateValue]);
  return [value || defaultValue, onSetValue];
};

export default useQueryParam;

import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Form,
  Segment,
  Container,
  Grid,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";
import RuvixxForm from "../../components/RuvixxForm";
import EmailSignatureService from "../../services/EmailSignatures";
import EmailFooterService from "../../services/EmailFooters";
import EmailTemplateService from "../../services/EmailTemplate";
import ReactHtmlParser from "react-html-parser";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import { checkIsAuthorized } from "../../components/helpers";

function EmailSignature() {
  const params = useParams();
  const [signatureName, setSignatureName] = useState("");
  const [signatureHtml, setSignatureHtml] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [templateOptions, setTemplateOptions] = useState([]);
  const [templateHtml, setTemplateHtml] = useState("");
  const [footerOptions, setFooterOptions] = useState([]);
  const [footerHtml, setFooterHtml] = useState("");
  const [previewHtml, setPreviewHtml] = useState("");

  const navigate = useNavigate();

  const canEditEmailSignature = checkIsAuthorized([
    !!params.id
      ? ACL_RELATIONSHIPS.emailSignatures.edit
      : ACL_RELATIONSHIPS.emailSignatures.create,
  ]);
  const [actionForbidden, setActionForbidden] = useState(false);
  const [actionForbiddenMessage, setActionForbiddenMessage] = useState("");

  const fetchSignature = async email_signature_id => {
    let signature = await EmailSignatureService.getEmailSignature(
      email_signature_id
    );
    setSignatureName(signature.name);
    setSignatureHtml(signature.html);
  };

  const decodeHtml = html => {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const mapForSelect = array =>
    array.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));

  const fetchTemplates = async () => {
    let templates = await EmailTemplateService.getEmailTemplatesForFilters();
    let templateOptions = templates.map(template => ({
      key: template.id,
      value: template.id,
      text: template.name,
      html: template.html,
    }));
    setTemplateOptions(templateOptions);
  };

  const fetchFooters = async () => {
    const footers = await EmailFooterService.getEmailFootersForFilters();
    setFooterOptions(mapForSelect(footers));
  };

  const fetchFooterHtml = async footer_id => {
    let footer = await EmailFooterService.getEmailFooter(footer_id);
    let unescaped_template = decodeHtml(footer.html);
    setFooterHtml(unescaped_template);
  };

  const fetchTemplateHtml = async template_id => {
    let template = await EmailTemplateService.getEmailTemplate(template_id);
    let unescaped_template = decodeHtml(template.html);
    setTemplateHtml(unescaped_template);
  };

  const combined_template = (template = "", footer = "", signature = "") => {
    let before = template.indexOf("</body>");
    let combined =
      template.substring(0, before) +
      signature +
      footer +
      template.substring(before);
    setPreviewHtml(combined);
  };

  const handleTemplateChange = (e, data) => {
    fetchTemplateHtml(data.value);
  };

  const handleFooterChange = (e, data) => {
    fetchFooterHtml(data.value);
  };

  const handleSubmit = async () => {
    if (!canEditEmailSignature) {
      setActionForbidden(true);
      setActionForbiddenMessage(
        "You are not authorized to perform this action."
      );
      return;
    }

    if (signatureName !== "" && signatureHtml !== "") {
      try {
        await EmailSignatureService.editEmailSignature(
          params.id,
          signatureName,
          signatureHtml
        );
        navigate("/settings/email_signatures");
      } catch (error) {
        setError(true);
        setErrorMessage(error.message);
      }
    }
  };

  const handleChange = (e, data) => {
    if (!canEditEmailSignature) return;
    if (data.name === "email_signature_name") {
      setSignatureName(data.value);
    } else {
      setSignatureHtml(data.value);
    }
  };

  useEffect(() => {
    fetchSignature(params.id);
    fetchTemplates();
    fetchFooters();
  }, [params]);

  useEffect(() => {
    combined_template(templateHtml, footerHtml, signatureHtml);
  }, [signatureHtml, templateHtml, footerHtml]);

  return (
    <Container fluid className="route">
      <Grid className="bg list-grid" divided>
        <Grid.Row>
          <Grid.Column className="sixteen wide">
            <Breadcrumb style={{ margin: "1em 0 0 1em" }}>
              <Breadcrumb.Section>
                <Link to="/settings/email_signatures">Email Signatures</Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right chevron" />
              <Breadcrumb.Section active>{signatureName}</Breadcrumb.Section>
            </Breadcrumb>
            <Header>
              <Icon name="envelope" />
              Edit Email Signature
            </Header>
            <Segment>
              <RuvixxForm
                ready={!!signatureName && !!signatureHtml}
                onSubmit={handleSubmit}
                successHeader="Email Signature Created"
                successMessage="Successfully created a new signature"
              >
                <Form.Input
                  fluid
                  name="email_signature_name"
                  label="Name"
                  value={signatureName}
                  onChange={handleChange}
                  required
                />
                <Form.Select
                  label="Choose a Preview Template"
                  name="templateId"
                  selection
                  options={templateOptions}
                  onChange={handleTemplateChange}
                />
                <Form.Select
                  label="Choose a Preview Footer"
                  name="footerId"
                  selection
                  options={footerOptions}
                  onChange={handleFooterChange}
                />
                <Form.TextArea
                  name="email_signature_html"
                  label="Custom HTML"
                  style={{ minWidth: "100%" }}
                  rows={4}
                  onChange={handleChange}
                  value={signatureHtml}
                  required
                />
                <label>HTML Preview</label>
                <Segment>{ReactHtmlParser(previewHtml)}</Segment>
              </RuvixxForm>
              {actionForbidden && (
                <Message
                  error
                  header="Action Forbidden"
                  content={actionForbiddenMessage}
                />
              )}
              {error && <Message error header="Error" content={errorMessage} />}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default EmailSignature;

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Dropdown, Modal } from "semantic-ui-react";

import AssignInvestigatorsForm from "./AssignInvestigatorsForm";

const AssignInvestigatorsModal = props => {
  const [open, setOpen] = useState(false);

  function handleSuccess() {
    props.onSuccess();
    setOpen(false);
  }

  return (
    <Modal
      size="large"
      trigger={
        <Dropdown.Item
          onClick={() => setOpen(true)}
          content="Assign Investigators"
        />
      }
      open={open}
      onClose={() => setOpen(false)}
      closeOnDimmerClick={false}
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      closeIcon
    >
      <Modal.Header>Assign Investigators</Modal.Header>
      <Modal.Content>
        <AssignInvestigatorsForm
          caseIds={props.caseIds}
          onSuccess={handleSuccess}
        />
      </Modal.Content>
    </Modal>
  );
};

AssignInvestigatorsModal.propTypes = {
  caseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AssignInvestigatorsModal;

import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Select,
  Table,
  List,
} from "semantic-ui-react";
import AdaptableInput from "../../../components/AdaptableInput";
import { capitalize } from "../../../components/helpers";
import tagConstants from "../../../constants/Tag";
import CategoricalTypeOptionsModal from "./CategoricalTypeOptionsModal";
import DraggableContainer from "components/DraggableContainer";

const TagMetadata = ({
  metadata: fields,
  setMetadata: setFields,
  userRoles,
}) => {
  const [lastUsedFieldType, setLastUsedFieldType] = useState(
    tagConstants.metadataTypes.text
  );
  const categoricalTypeOptions = useRef();

  const handleAdd = () => {
    const newField = {
      field_name: "",
      field_type: lastUsedFieldType,
      default_value: "",
      allow_override: false,
      required: false,
      required_roles: [0],
      field_options: {},
    };
    setFields([...fields, newField]);
  };

  useEffect(() => {
    fields.map(field => {
      if (field.required_roles.length === 0) {
        field.required_roles = [0];
      }
    });
    orderMetadata();
  }, []);

  const orderMetadata = () => {
    const sorted = fields.sort((a, b) => a.order - b.order);
    setFields([...sorted]);
  };

  const handleChange = (index, data) => {
    const key = data.name;
    const value = data.type === "checkbox" ? data.checked : data.value;
    const updatedField = {
      ...fields[index],
      [key]: value,
      ...(key === "field_type" && { default_value: "", field_options: {} }),
    };
    const newFields = [
      ...fields.slice(0, index),
      updatedField,
      ...fields.slice(index + 1),
    ];
    if (key === "field_type") {
      setLastUsedFieldType(value);
    }
    setFields(newFields);
  };

  const handleRemove = index => {
    const newFields = [...fields.slice(0, index), ...fields.slice(index + 1)];
    setFields(newFields);
  };

  const renderDefaultField = (field, index) => {
    const type = Object.keys(tagConstants.metadataTypes).find(
      metaDataType =>
        tagConstants.metadataTypes[metaDataType] === field.field_type
    );
    return (
      <AdaptableInput
        type={field.field_type}
        options={field.field_options}
        name="default_value"
        value={field.default_value}
        onChange={(_, data) => handleChange(index, data)}
        maxLength={2048}
      />
    );
  };

  const handleSwapOrder = (_, aIndex, bIndex) => {
    const copyFields = [...fields];
    if (bIndex >= copyFields.length) {
      bIndex = copyFields.length - 1;
    }

    const draggedItem = copyFields[aIndex];
    copyFields.splice(aIndex, 1);
    copyFields.splice(bIndex, 0, draggedItem);
    setFields(copyFields);
  };

  const openCategoricalOptions = (index, fieldOptions) => {
    categoricalTypeOptions.current.open(index, fieldOptions);
  };

  const renderTable = () => {
    const metadataTypes = Object.entries(tagConstants.metadataTypes).map(
      ([key, value]) => ({
        key: value,
        value,
        text: capitalize(key),
      })
    );
    return (
      <>
        <CategoricalTypeOptionsModal
          ref={categoricalTypeOptions}
          doChange={handleChange}
        />
        <Table
          size="small"
          basic="very"
          className="phone-numbers-list"
          style={{ tableLayout: "fixed" }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ width: "30px", background: "white" }}
              />
              <Table.HeaderCell style={{ width: "170px" }}>
                Field Name<span style={{ color: "red" }}>*</span>
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "170px" }}>
                Type
              </Table.HeaderCell>
              <Table.HeaderCell style={{ width: "170px" }}>
                Default Value
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" style={{ width: "115px" }}>
                Allow Override
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" style={{ width: "115px" }}>
                Required
              </Table.HeaderCell>
              <Table.HeaderCell>
                Roles<span style={{ color: "red" }}>*</span>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <DraggableContainer
              container={List}
              onUpdateChildrenOrder={handleSwapOrder}
              direction="vertical"
              className="phone-type"
            >
              {fields.map((field, index) => {
                const isCategorical =
                  field.field_type.startsWith("categorical");

                return (
                  <Table.Row>
                    <Table.Cell style={{ width: "5px", background: "white" }}>
                      <Icon name="bars" />
                    </Table.Cell>
                    <Table.Cell
                      style={{ minWidth: "170px", maxWidth: "170px" }}
                    >
                      <Input
                        name="field_name"
                        style={{ width: "100%" }}
                        value={field.field_name}
                        onChange={(_, data) => {
                          handleChange(index, data);
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell
                      style={{ minWidth: "170px", maxWidth: "170px" }}
                    >
                      <Table.Row>
                        <Table.Cell>
                          <Select
                            name="field_type"
                            value={field.field_type}
                            style={{
                              width: "100%",
                              minWidth: isCategorical ? 110 : 140,
                            }}
                            options={metadataTypes}
                            onChange={(_, data) => {
                              handleChange(index, data);
                            }}
                          />
                        </Table.Cell>
                        {isCategorical && (
                          <Table.Cell style={{ padding: 0 }}>
                            <Button
                              size="mini"
                              icon="setting"
                              basic
                              style={{ marginLeft: 5 }}
                              onClick={() =>
                                openCategoricalOptions(
                                  index,
                                  field.field_options
                                )
                              }
                            />
                          </Table.Cell>
                        )}
                      </Table.Row>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        minWidth: "170px",
                        maxWidth: "170px",
                        verticalAlign: "middle",
                      }}
                    >
                      <Table.Row>
                        <Table.Cell>
                          {renderDefaultField(field, index)}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Cell>
                    <Table.Cell
                      style={{ minWidth: "115px", maxWidth: "115px" }}
                      textAlign="center"
                    >
                      <Checkbox
                        name="allow_override"
                        checked={field.allow_override}
                        onChange={(_, data) => {
                          handleChange(index, data);
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell
                      style={{ minWidth: "115px", maxWidth: "115px" }}
                      textAlign="center"
                    >
                      <Checkbox
                        name="required"
                        checked={field.required}
                        onChange={(_, data) => {
                          handleChange(index, data);
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "top" }}>
                      <Select
                        multiple
                        clearable
                        search
                        required
                        name="required_roles"
                        value={field.required_roles}
                        options={userRoles}
                        onChange={(_, data) => {
                          if (data.value.includes(0)) data.value = [0];
                          handleChange(index, data);
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Icon
                        link
                        name="cancel"
                        color="red"
                        title="Remove Metadata Field"
                        style={{ verticalAlign: 5 }}
                        onClick={() => handleRemove(index)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </DraggableContainer>
          </Table.Body>
        </Table>
      </>
    );
  };

  return (
    <>
      {fields.length > 0 && renderTable()}
      <Button size="tiny" basic onClick={() => handleAdd()}>
        <Icon name="plus" />
        <strong>Add Metadata Field</strong>
      </Button>
    </>
  );
};

export default TagMetadata;

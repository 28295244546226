import React, { useEffect, useState, useCallback } from "react";
import { Form, Message, Segment } from "semantic-ui-react";
import EmailTemplateService from "../../../services/EmailTemplate";
import CustomFieldService from "../../../services/CustomField";
import CustomFieldForm from "../../../components/forms/CustomFieldForm";
import {
  extractCustom,
  getCustomFieldsForSubmit,
} from "../../../components/helpers";

function GeneralEmailTemplateForm({ template, changeTab, showSuccessMessage }) {
  const [subject, setSubject] = useState(template.subject);
  const [name, setName] = useState(template.name);
  const [info, setInfo] = useState(template.info || {});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [custom, setCustom] = useState([]);
  const [customFieldConfigs, setCustomFieldConfigs] = useState([]);

  const fetchCustomFieldConfigs = useCallback(async () => {
    const customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel("EmailTemplate");

    setCustomFieldConfigs(customFieldConfigs);
  }, []);

  const handleUpdateCustomFieldConfigs = customFieldConfigs => {
    setCustomFieldConfigs(customFieldConfigs);
    const newCustomFields = customFieldConfigs
      .filter(cfc => cfc.required || cfc.selected)
      .map(cfc => ({ key: cfc.label, value: cfc.value }));
    setCustom(newCustomFields);
  };

  useEffect(() => {
    fetchCustomFieldConfigs();
  }, [fetchCustomFieldConfigs]);

  useEffect(() => {
    setSubject(template.subject);
    setName(template.name);
    setInfo(template.info || {});
    setCustom(extractCustom(template.custom_fields));
  }, [template.subject, template.name, template.info, template.custom_fields]);

  const decodeHtml = html => {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const handleSubmit = async () => {
    const cust = getCustomFieldsForSubmit(customFieldConfigs, custom);
    if (template.id) {
      try {
        const updatedTemplate = await EmailTemplateService.editEmailTemplate({
          id: template.id,
          name: name,
          subject: subject,
          info: info,
          customFields: cust,
        });
        changeTab("graphical", updatedTemplate);
        showSuccessMessage();
      } catch (error) {
        setError(true);
        setErrorMsg(error.message);
      }
    } else {
      try {
        let updatedTemplate = await EmailTemplateService.createEmailTemplate(
          name,
          subject,
          null,
          null,
          info,
          cust
        );
        changeTab("graphical", updatedTemplate);
        showSuccessMessage();
      } catch (error) {
        setError(true);
        setErrorMsg(error.message);
      }
    }
  };

  const handleChange = (event, data) => {
    if (data.name == "subject") {
      setSubject(data.value);
    } else if (data.name == "name") {
      setName(data.value);
    } else if (data.name == "preview") {
      setInfo({ ...info, preview_text: data.value });
    }
  };

  const createMarkup = html => {
    return { __html: html };
  };

  return (
    <>
      <Form>
        <Form.Input
          fluid
          name="name"
          label="Name"
          value={name}
          onChange={handleChange}
          required
        />
        <Form.Input
          fluid
          name="subject"
          label="Subject"
          value={subject}
          onChange={handleChange}
          required
        />
        <Form.Input
          name="preview"
          label="Preview Text"
          value={info?.preview_text}
          onChange={handleChange}
        />
        {customFieldConfigs && (
          <CustomFieldForm
            modelType="EmailTemplate"
            customFields={custom}
            customFieldConfigs={customFieldConfigs}
            onUpdateCustomFieldConfigs={handleUpdateCustomFieldConfigs}
          />
        )}
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Form.Button
            size="tiny"
            primary
            type="submit"
            onClick={handleSubmit}
            disabled={!name || !subject}
          >
            SUBMIT
          </Form.Button>
        </div>
      </Form>
      <Segment
        dangerouslySetInnerHTML={createMarkup(
          decodeHtml(unescape(template.html))
        )}
      />
      {error ? (
        <Message negative>
          <Message.Header>Action Failed</Message.Header>
          <p>{errorMsg}</p>
        </Message>
      ) : null}
    </>
  );
}

export default GeneralEmailTemplateForm;

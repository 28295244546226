import React, { Component } from "react";
import PropTypes from "prop-types";

import ConfirmationModal from "./ConfirmationModal";

class DeleteFileModal extends Component {
  static propTypes = {
    onConfirmDelete: PropTypes.func.isRequired,
  };

  render() {
    return (
      <ConfirmationModal
        actionDescription="Delete File"
        tableButton="delete"
        buttonColor="red"
        onConfirm={this.props.onConfirmDelete}
        warning
      >
        <p>Are you sure you want to delete this File?</p>
        <p>It will be deleted from the Activity Log as well</p>
      </ConfirmationModal>
    );
  }
}

export default DeleteFileModal;

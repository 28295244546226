import React, { useEffect, useState } from "react";
import { Dropdown, Icon } from "semantic-ui-react";

export default function CustomFieldGroupDropdown({
  customFieldGroups,
  onUpdateCustomFieldConfigs,
  customFieldConfigs,
}) {
  const [customFieldFormOptions, setCustomFieldsFieldFormOptions] = useState(
    []
  );
  const [customFieldFormValue, setCustomFieldsFieldFormValue] = useState([]);

  /**
   * Builds an array of Custom Fields.
   * The result is a merge between the [current used Custom Fields] and the [Custom Fields in the selected Custom Field Group].
   * If a Custom Field with the same ID exists in both places, just the one being already used will be on the final array.
   * @param {string} selectedCustomFieldGroupID
   * @returns {Array<Object>}
   */
  const buildCustomFields = selectedCustomFieldGroupID => {
    const result = [];
    const usedCustomFieldsMap = customFieldConfigs.reduce(
      (map, customFieldConfig) => {
        if (customFieldConfig.selected || customFieldConfig.required) {
          map[customFieldConfig.id] = customFieldConfig;
          result.push(customFieldConfig);
        }
        return map;
      },
      {}
    );

    const selectedGroup = customFieldGroups.find(
      cfg => cfg.id == selectedCustomFieldGroupID
    ).custom_field_configs;

    for (const customField of selectedGroup) {
      if (!usedCustomFieldsMap[customField.id]) {
        const customFieldToAdd = {
          ...customField,
          selected: true,
        };

        if (customField.field_type === "boolean") {
          customFieldToAdd.value = false;
        }
        result.push(customFieldToAdd);
      }
    }
    return result;
  };

  const handleCustomFieldGroupSelect = (_, data) => {
    const customFields = buildCustomFields(data.value);
    onUpdateCustomFieldConfigs(customFields);
  };

  useEffect(() => {
    const customFieldFormOptions = customFieldGroups.map(cf => ({
      id: cf.id,
      value: cf.id,
      text: cf.name,
    }));
    setCustomFieldsFieldFormOptions(customFieldFormOptions);
    setCustomFieldsFieldFormValue(
      customFieldGroups.filter(cf => cf.selected).map(cf => cf.id)
    );
  }, [customFieldGroups]);

  return (
    <>
      <Dropdown
        className="button icon scrolling tiny cf-dropdown"
        basic
        search
        text={
          <>
            <Icon name="plus" />
            <strong>Custom Field Groups</strong>
          </>
        }
        name="customFieldGroups"
        options={customFieldFormOptions}
        value={customFieldFormValue}
        onChange={handleCustomFieldGroupSelect}
        selectOnBlur={false}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Form, Segment } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import EmailSignatureService from "../../../services/EmailSignatures";
import EmailFooterService from "../../../services/EmailFooters";
import EmailTemplateService from "../../../services/EmailTemplate";
import ReactHtmlParser from "react-html-parser";

function EmailSignatureForm({ onSuccess, isClone, emailSignatureId }) {
  const [signatureName, setSignatureName] = useState("");
  const [signatureHtml, setSignatureHtml] = useState("");
  const [templateOptions, setTemplateOptions] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [templateHtml, setTemplateHtml] = useState("");
  const [footerOptions, setFooterOptions] = useState([]);
  const [footerId, setFooterId] = useState("");
  const [footerHtml, setFooterHtml] = useState("");
  const [previewHtml, setPreviewHtml] = useState("");

  const fetchSignature = async email_signature_id => {
    let signature = await EmailSignatureService.getEmailSignature(
      email_signature_id
    );
    setSignatureName(signature.name);
    setSignatureHtml(signature.html);
    if (isClone) {
      setSignatureName("");
    }
  };

  const mapForSelect = array =>
    array.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));

  const decodeHtml = html => {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const fetchTemplates = async () => {
    let templates = await EmailTemplateService.getEmailTemplatesForFilters();
    let templateOptions = templates.map(template => ({
      key: template.id,
      value: template.id,
      text: template.name,
      html: template.html,
    }));
    setTemplateOptions(templateOptions);
  };

  const fetchFooters = async () => {
    const footers = await EmailFooterService.getEmailFootersForFilters();
    setFooterOptions(mapForSelect(footers));
  };

  const fetchFooterHtml = async footer_id => {
    let footer = await EmailFooterService.getEmailFooter(footer_id);
    let unescaped_template = decodeHtml(footer.html);
    setFooterHtml(unescaped_template);
  };

  const fetchTemplateHtml = async template_id => {
    let template = await EmailTemplateService.getEmailTemplate(template_id);
    let unescaped_template = decodeHtml(template.html);
    setTemplateHtml(unescaped_template);
  };

  const combined_template = (template = "", footer = "", signature = "") => {
    let before = template.indexOf("</body>");
    let combined =
      template.substring(0, before) +
      signature +
      footer +
      template.substring(before);
    setPreviewHtml(combined);
  };

  const handleTemplateChange = (e, data) => {
    fetchTemplateHtml(data.value);
  };

  const handleFooterChange = (e, data) => {
    fetchFooterHtml(data.value);
  };

  const handleSubmit = async () => {
    if (emailSignatureId) {
      if (isClone) {
        await EmailSignatureService.createEmailSignature(
          signatureName,
          signatureHtml
        );
      } else {
        await EmailSignatureService.editEmailSignature(
          emailSignatureId,
          signatureName,
          signatureHtml
        );
      }
    } else {
      await EmailSignatureService.createEmailSignature(
        signatureName,
        signatureHtml
      );
    }
  };

  const handleChange = (e, data) => {
    if (data.name === "email_signature_name") {
      setSignatureName(data.value);
    } else {
      setSignatureHtml(data.value);
    }
  };

  useEffect(() => {
    if (emailSignatureId) {
      fetchSignature(emailSignatureId);
    }
    fetchTemplates();
    fetchFooters();
  }, [emailSignatureId]);

  useEffect(() => {
    combined_template(templateHtml, footerHtml, signatureHtml);
  }, [signatureHtml, templateHtml, footerHtml]);

  return (
    <>
      <RuvixxForm
        ready={!!signatureName && !!signatureHtml}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        successHeader="Email Signature Created"
        successMessage="Successfully created a new signature"
      >
        <Form.Input
          fluid
          name="email_signature_name"
          label="Name"
          value={signatureName}
          onChange={handleChange}
          required
        />
        <Form.Select
          required
          label="Choose a Preview Template"
          name="templateId"
          selection
          options={templateOptions}
          onChange={handleTemplateChange}
        />
        <Form.Select
          required
          label="Choose a Preview Footer"
          name="footerId"
          selection
          options={footerOptions}
          onChange={handleFooterChange}
        />
        <Form.TextArea
          name="email_signature_html"
          label="Custom HTML"
          style={{ minWidth: "100%" }}
          rows={4}
          onChange={handleChange}
          value={signatureHtml}
          required
        />
        <label>HTML Preview</label>
        <Segment>{ReactHtmlParser(previewHtml)}</Segment>
      </RuvixxForm>
    </>
  );
}

export default EmailSignatureForm;

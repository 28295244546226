import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import isEqual from "lodash/isEqual";

import RuvixxForm from "../../../components/RuvixxForm";
import RuvixxSelect from "../../../components/RuvixxSelect";
import EntityService from "../../../services/Entity";
import DepartmentService from "../../../services/Department";
import DepartmentContacts from "../components/DepartmentContacts";

const DepartmentForm = ({ entityId, onSuccess, department }) => {
  const [name, setName] = useState(department ? department.name : "");
  const [departmentId, setDepartmentId] = useState(null);
  const [contacts, setContacts] = useState(
    department ? department.contacts : []
  );

  const handleSubmit = async () => {
    if (department) {
      await EntityService.updateDepartment(
        entityId,
        department.id,
        name,
        contacts
      );
    } else {
      await EntityService.assignDepartment(entityId, departmentId);
    }
  };

  return (
    <RuvixxForm
      ready={
        department
          ? (!!name && name !== department.name) ||
            !isEqual(contacts, department.contacts)
          : !!departmentId
      }
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      {department ? (
        <Form.Input
          inline
          name="name"
          label="Name"
          required
          value={name}
          onChange={(_, { value }) => setName(value)}
          autoFocus
        />
      ) : (
        <RuvixxSelect
          label="Name"
          required
          value={departmentId}
          queryFn={DepartmentService.getForFilters}
          createFn={DepartmentService.createDepartment}
          allowAdditions
          onChange={(_, { value }) => setDepartmentId(value)}
          setValue={value => setDepartmentId(value.id)}
        />
      )}
      {department && (
        <DepartmentContacts
          entityId={entityId}
          contacts={contacts}
          setContacts={setContacts}
        />
      )}
    </RuvixxForm>
  );
};

export default DepartmentForm;

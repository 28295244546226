import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import InvestigationStatusForm from "routes/entity/forms/NewInvestigationStatus";

const InvestigationStatusModal = forwardRef(({ onSuccess }, ref) => {
  const [open, setOpen] = useState(false);
  const [investigationStatus, setInvestigationStatus] = useState(null);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = investigationStatus => {
    setInvestigationStatus(investigationStatus);
    setOpen(true);
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  return (
    <Modal
      size="tiny"
      closeIcon
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Modal.Header>{`${
        investigationStatus ? "Edit" : "New"
      } Investigation Status`}</Modal.Header>
      <Modal.Content>
        <InvestigationStatusForm
          investigationStatus={investigationStatus}
          onSuccess={handleSuccess}
        />
      </Modal.Content>
    </Modal>
  );
});

InvestigationStatusModal.NewButton = ({ modalRef }) => (
  <Button
    content="New Investigation Status"
    size="tiny"
    className="item-adder"
    onClick={() => modalRef.current.open()}
  />
);

InvestigationStatusModal.EditButton = ({ investigationStatus, modalRef }) => (
  <Dropdown.Item
    content="Edit"
    icon="edit"
    onClick={() => modalRef.current.open(investigationStatus)}
  />
);

export default InvestigationStatusModal;

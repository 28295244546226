import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "semantic-ui-react";
import Datetime from "react-datetime";

export class DateRangePicker extends Component {
  static propTypes = {
    from: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    updateDateRange: PropTypes.func.isRequired,
  };

  handleFrom = moment => {
    this.props.updateDateRange({ from: moment });
  };

  handleTo = moment => {
    this.props.updateDateRange({ to: moment });
  };

  render() {
    return (
      <Form>
        <Form.Group>
          <Form.Field>
            <label>From</label>
            <Datetime
              closeOnSelect={true}
              timeFormat={false}
              onChange={this.handleFrom}
              value={this.props.from}
              renderInput={props => (
                <Input icon="large calendar outline" {...props} />
              )}
              dateFormat={"YYYY-MM-DD"}
            />
          </Form.Field>
          <Form.Field>
            <label>To</label>
            <Datetime
              closeOnSelect={true}
              timeFormat={false}
              onChange={this.handleTo}
              value={this.props.to}
              renderInput={props => (
                <Input icon="large calendar outline" {...props} />
              )}
              dateFormat={"YYYY-MM-DD"}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

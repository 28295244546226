import { doRequest, applyFilters } from "./helpers";

const NotificationService = {
  getNotifications: async (filters = null) => {
    const path = applyFilters(filters, "/notification/");
    return doRequest("GET", path);
  },
  markAsRead: async (notificationIds = [], queryFilters = {}) => {
    if (!Array.isArray(notificationIds)) {
      notificationIds = [notificationIds];
    }
    const data = {
      all: notificationIds.length === 0,
      items: notificationIds,
      filters: queryFilters,
    };
    return doRequest("POST", "/notification/mark_as_read/", { data });
  },
  archive: async (notificationIds = [], queryFilters = {}) => {
    if (!Array.isArray(notificationIds)) {
      notificationIds = [notificationIds];
    }
    const data = {
      all: notificationIds.length === 0,
      items: notificationIds,
      filters: queryFilters,
    };
    return doRequest("POST", "/notification/mark_as_archived/", { data });
  },
};

export default NotificationService;

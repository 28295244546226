import React, { useState, useEffect } from "react";
import PaginationWidget from "./PaginationWidget";

const TableFooter = ({
  enablePagination,
  onPageChange,
  onPageSizeChange,
  pagination,
}) => {
  return (
    <>
      {enablePagination && (
        <PaginationWidget
          pagination={pagination}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      )}
    </>
  );
};
export default TableFooter;

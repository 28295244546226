import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Button, Modal, Dropdown } from "semantic-ui-react";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import EntityService from "../../../services/Entity";
import DepartmentForm from "../forms/DepartmentForm";

const DepartmentModal = forwardRef(({ entityId, onSuccess }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [department, setDepartment] = useState(null);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = department => {
    setDepartment(department);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  return (
    <Modal
      size="small"
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header content={`${department ? "Edit" : "New"} Department`} />
      <Modal.Content>
        <DepartmentForm
          entityId={entityId}
          onSuccess={handleSuccess}
          department={department}
        />
      </Modal.Content>
    </Modal>
  );
});

DepartmentModal.NewButton = ({ modalRef }) => (
  <Button
    content="New Department"
    size="tiny"
    className="item-adder"
    onClick={() => modalRef.current.open()}
  />
);

DepartmentModal.EditButton = ({ department, modalRef }) => (
  <Dropdown.Item
    content="Edit"
    icon="edit"
    onClick={() => modalRef.current.open(department)}
  />
);

const DeleteDepartmentModal = ({ entityId, departmentId, onSuccess }) => {
  const handleDelete = async () => {
    await EntityService.deleteDepartment(entityId, departmentId);
    onSuccess();
  };

  return (
    <ConfirmationModal
      actionDescription="Delete"
      icon="trash"
      buttonColor="red"
      onConfirm={handleDelete}
      menuTrigger
    >
      <p>Are you sure you want to remove this Department?</p>
    </ConfirmationModal>
  );
};

export { DepartmentModal, DeleteDepartmentModal };

import React from "react";
import getPhoneType from "../helpers/PhoneTypes";
import "./PhoneDetails.scoped.scss";

function PhoneDetails({ number, type, ext }) {
  return (
    <>
      {!ext ? (
        <>
          <span className="type">{getPhoneType(type)}</span>
          <span className="number">{number}</span>
        </>
      ) : (
        <>
          <span className="type">{getPhoneType(type)}</span>
          <span className="number">
            {number}x{ext}
          </span>
        </>
      )}
    </>
  );
}

export default PhoneDetails;

import React, { useState } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";

export default function RecordingConsentModal({
  open,
  handleStopRecording,
  handleUpdateRecordingLegalStatus,
  setGrantedConsent,
  toggleConsentModal,
  hanging,
  hangup,
}) {
  const handleGrant = () => {
    setGrantedConsent(true);
    handleUpdateRecordingLegalStatus(null);
    toggleConsentModal();

    if (hanging) {
      hangup();
    }
  };

  const handleDeny = async () => {
    handleStopRecording();
    setGrantedConsent(false);
    handleUpdateRecordingLegalStatus(null);
    toggleConsentModal();

    if (hanging) {
      hangup();
    }
  };

  return (
    <Modal size="tiny" open={open} closeOnDimmerClick={false}>
      <Modal.Header>Call recording consent required</Modal.Header>
      <Modal.Content>
        <p>
          Ask the contact for consent to record this call and reflect their
          answer below.
        </p>
        <p>
          You can stop and remove the recording data at any point during the
          call by clicking the stop icon ({<Icon name="stop" />}) in the call
          recording controls.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={handleDeny}>
          <Icon name="cancel" /> Deny
        </Button>
        <Button color="blue" onClick={handleGrant}>
          <Icon name="checkmark" /> Grant
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Form, Icon, Image, Menu, Modal } from "semantic-ui-react";
import store from "store";
import observe from "store/plugins/observe";
import ACL_RELATIONSHIPS from "../acl-relationships";
import CONFIG from "../Config";
import { USER_ALERTS_FETCH_INTERVAL } from "../constants/Constants";
import useInterval from "../hooks/useInterval";
import AuthService from "../services/Auth";
import SupportService from "../services/Support";
import UserService from "../services/User";
import pleteo_isotype from "./../assets/Icon_Pleteo_White.png";
import CopyCCEmail from "./CopyCCEmail";
import ee from "./Emitter";
import FilePicker from "./FilePicker";

import "./Header.scoped.scss";
import { checkIsAuthorized } from "./helpers";
import withRoleCheck from "./hocs/withRoleCheck";
import RuvixxForm from "./RuvixxForm";
import UploadManager from "./UploadManager";
import _UserStatus from "components/UserStatus/UserStatus";
import UploadProgressRing from "./UploadProgressRing";
import useAuthorized from "../hooks/useAuthorized";

const logoSrc = CONFIG.HEADER_LOGO_SRC || CONFIG.LOGO_SRC || pleteo_isotype;
const logoStyleOverride = {
  height: CONFIG.HEADER_LOGO_H,
  width: CONFIG.HEADER_LOGO_W,
};

function HeaderLogo() {
  return (
    <Menu.Item className="logo">
      <Image size="mini" src={logoSrc} style={logoStyleOverride} />
    </Menu.Item>
  );
}

const UserStatus = withRoleCheck(_UserStatus, [
  ACL_RELATIONSHIPS.callAgentStatus.read,
]);

const CampaignsNavLink = withRoleCheck(NavLink, [
  ACL_RELATIONSHIPS.campaigns.read,
  ACL_RELATIONSHIPS.region.read,
]);
const EntitiesNavLink = withRoleCheck(NavLink, [
  ACL_RELATIONSHIPS.user.read,
  ACL_RELATIONSHIPS.tag.read,
  ACL_RELATIONSHIPS.entity.read,
]);
const ContactsNavLink = withRoleCheck(NavLink, [
  ACL_RELATIONSHIPS.user.read,
  ACL_RELATIONSHIPS.tag.read,
  ACL_RELATIONSHIPS.contact.read,
  ACL_RELATIONSHIPS.contactCustomFields.read,
]);
const canViewCallsTable = checkIsAuthorized([
  ACL_RELATIONSHIPS.campaignTargetCall.readTable,
]);
const canViewDialSessions = checkIsAuthorized([
  ACL_RELATIONSHIPS.dialSession.read,
]);
const canViewCallDashboard = checkIsAuthorized([
  ACL_RELATIONSHIPS.callDashboard.read,
]);
const canViewAgentDashboard = checkIsAuthorized([
  ACL_RELATIONSHIPS.dialSession.read,
  ACL_RELATIONSHIPS.callQueueNextContact.read,
  ACL_RELATIONSHIPS.callQueueAddContact.create,
  ACL_RELATIONSHIPS.disposition.read,
  ACL_RELATIONSHIPS.emailUrlForms.read,
  ACL_RELATIONSHIPS.twilioToken.read,
  ACL_RELATIONSHIPS.tag.read,
  ACL_RELATIONSHIPS.user.read,
  ACL_RELATIONSHIPS.campaignStatuses.read,
  ACL_RELATIONSHIPS.callQueueItemsWithAgentAssigned.read,
  ACL_RELATIONSHIPS.twilioDisposition.create,
  ACL_RELATIONSHIPS.twilioAddToCall.create,
  ACL_RELATIONSHIPS.twilioRemoveFromCall.create,
  ACL_RELATIONSHIPS.twilioUpdateParticipant.create,
]);
const canViewCallMenu =
  CONFIG.IS_PHONE_ENABLED &&
  (canViewDialSessions ||
    canViewCallDashboard ||
    canViewAgentDashboard ||
    canViewCallsTable);

const TasksNavLink = withRoleCheck(NavLink, [ACL_RELATIONSHIPS.task.read]);

const UserSettingsNavLink = withRoleCheck(NavLink, [
  ACL_RELATIONSHIPS.userSettings.read,
]);
const SettingsNavLink = withRoleCheck(
  NavLink,
  [
    ACL_RELATIONSHIPS.adminUser.read,
    ACL_RELATIONSHIPS.adminRole.read,
    ACL_RELATIONSHIPS.adminGroup.create,
    ACL_RELATIONSHIPS.disposition.read,
    ACL_RELATIONSHIPS.voicemail.read,
    ACL_RELATIONSHIPS.campaignStatusPreset.readAsTable,
    ACL_RELATIONSHIPS.region.read,
    ACL_RELATIONSHIPS.revenueOpportunityStatus.read,
    ACL_RELATIONSHIPS.constantContactAccounts.read,
    ACL_RELATIONSHIPS.constantContactCampaigns.read,
    ACL_RELATIONSHIPS.customFieldConfig.readTable,
    ACL_RELATIONSHIPS.product.read,
    ACL_RELATIONSHIPS.tag.readTable,
    ACL_RELATIONSHIPS.tagCategory.readTable,
  ],
  { partialMatch: true }
);
const DataTabNavLink = withRoleCheck(NavLink, [ACL_RELATIONSHIPS.dataJob.read]);

const PiracyMenu = withRoleCheck(
  Dropdown,
  [ACL_RELATIONSHIPS.case.read, ACL_RELATIONSHIPS.revenueOpportunity.readTable],
  { partialMatch: true }
);
const CasesNavLink = withRoleCheck(NavLink, [ACL_RELATIONSHIPS.case.read]);
const RevenueOpportunitiesNavLink = withRoleCheck(NavLink, [
  ACL_RELATIONSHIPS.revenueOpportunity.readTable,
]);

const PleteoRequest = forwardRef(({}, ref) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [progressListenerId, setProgressListenerId] = useState(null);
  const uploadManager = useMemo(() => new UploadManager(), []);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleFileProgress = files => {
    setAttachments([...files]);
  };

  useEffect(() => {
    const listenerId = uploadManager.addProgressListener(handleFileProgress);
    setProgressListenerId(listenerId);

    return function cleanup() {
      uploadManager.removeProgressListener(progressListenerId || 0);
    };
  }, [uploadManager]);

  const handleFileChange = async files => {
    setAttachments(files);
    await uploadManager.uploadFiles(files);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setAttachments([]);
    setTopic("");
    setDescription("");
    setModalOpen(false);
  };

  const handleSubmit = async _e => {
    const data = {
      topic,
      description,
      attachments: attachments.map(f => f.attachment),
    };

    try {
      await SupportService.createTicket(data);
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  return (
    <Modal
      size="small"
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      className="role-modal"
    >
      <Modal.Header content="New Usability Issue or Feature Request" />
      <Modal.Content>
        <RuvixxForm onSubmit={handleSubmit} onSuccess={handleClose}>
          <Form.Input
            autoFocus
            required
            inline
            name="topic"
            label="Topic"
            onChange={(e, { value }) => setTopic(value)}
          />
          <Form.TextArea
            required
            inline
            name="description"
            label="Description"
            placeholder="Please describe the issue or feature request in detail."
            onChange={(e, { value }) => setDescription(value)}
          />
          <FilePicker primary onSelect={handleFileChange} multiple />
          <UploadProgressRing
            uploads={attachments}
            onDelete={uploadManager.removeUpload}
            xOnComplete={true}
          />
          <dl>
            {attachments.map(({ name }, index) => (
              <dd key={index}>{name}</dd>
            ))}
          </dl>
        </RuvixxForm>
      </Modal.Content>
    </Modal>
  );
});

const PleteoWiki = () => {
  return (
    <Dropdown.Item>
      <a
        target="_blank"
        href="https://pleteo.notion.site/Welcome-to-the-Pleteo-Knowledge-Base-e3cee5febf2b49dea62f0411b3bc2ef7"
      >
        Wiki / Help Guide
      </a>
    </Dropdown.Item>
  );
};

const TopNav = () => {
  const [hasNotifications, setHasNotifications] = useState(false);
  const requestModal = createRef();

  const location = useLocation();
  const navigate = useNavigate();

  const isItDialer = location.pathname.substring(1, 7) === "dialer";
  const user = store.get("userAuth");

  const canViewDialSessions = useAuthorized([
    ACL_RELATIONSHIPS.dialSession.read,
  ]);
  const canViewCallDashboard = useAuthorized([
    ACL_RELATIONSHIPS.callDashboard.read,
  ]);
  const canViewAgentDashboard = useAuthorized([
    ACL_RELATIONSHIPS.dialSession.read,
    ACL_RELATIONSHIPS.callQueueNextContact.read,
    ACL_RELATIONSHIPS.callQueueAddContact.create,
    ACL_RELATIONSHIPS.disposition.read,
    ACL_RELATIONSHIPS.emailUrlForms.read,
    ACL_RELATIONSHIPS.twilioToken.read,
    ACL_RELATIONSHIPS.tag.read,
    ACL_RELATIONSHIPS.user.read,
    ACL_RELATIONSHIPS.campaignStatuses.read,
    ACL_RELATIONSHIPS.callQueueItemsWithAgentAssigned.read,
    ACL_RELATIONSHIPS.twilioDisposition.create,
    ACL_RELATIONSHIPS.twilioAddToCall.create,
    ACL_RELATIONSHIPS.twilioRemoveFromCall.create,
    ACL_RELATIONSHIPS.twilioUpdateParticipant.create,
  ]);
  const canViewCallsTable = useAuthorized([
    ACL_RELATIONSHIPS.campaignTargetCall.readTable,
  ]);
  const canViewCallMenu =
    CONFIG.IS_PHONE_ENABLED &&
    (canViewDialSessions ||
      canViewCallDashboard ||
      canViewAgentDashboard ||
      canViewCallsTable);

  const fetchUserAlerts = useCallback(async () => {
    const canGetAlerts = checkIsAuthorized([ACL_RELATIONSHIPS.userAlerts.read]);
    if (canGetAlerts) {
      const alerts = await UserService.getUserAlerts();
      setHasNotifications(alerts && alerts.has_notifications);
      store.set("userAlerts", alerts);
    }
  }, []);

  useInterval(fetchUserAlerts, USER_ALERTS_FETCH_INTERVAL, true);

  useEffect(() => {
    store.addPlugin(observe);
    store.observe("userAuth", fetchUserAlerts);
  }, []);

  const AlertsNavLink = withRoleCheck(
    () => (
      <NavLink className="item inbox" to="/inbox">
        Inbox
        <div className={`${hasNotifications ? "new-notif-indicator" : ""}`} />
      </NavLink>
    ),
    [ACL_RELATIONSHIPS.notifications.read, ACL_RELATIONSHIPS.userAlerts.read]
  );

  const DocsNavLink = () => (
    <>
      <PleteoRequest ref={requestModal} />
      <Dropdown
        className="help-links"
        trigger={<Icon name="help circle" color="yellow" size="large" />}
      >
        <Dropdown.Menu>
          <PleteoWiki />
          <Dropdown.Item
            onClick={() => requestModal.current?.open()}
            content="Submit An Inquiry"
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );

  //render dialer header
  if (isItDialer) {
    return (
      <Menu secondary>
        <HeaderLogo />
        <Menu secondary floated="right">
          <Dropdown text={user.full_name} pointing className="link item">
            <Dropdown.Menu>
              <CopyCCEmail
                nopopup={true}
                className="item"
                textContent="Copy Email BCC"
              />
              <NavLink className="item" to="/sites" target="_blank">
                Sites
              </NavLink>
              <UserSettingsNavLink
                className="item"
                to="/user/settings"
                target="_blank"
              >
                My Account
              </UserSettingsNavLink>
              <SettingsNavLink className="item" to="/settings" target="_blank">
                System Settings
              </SettingsNavLink>
              <Menu.Item
                onClick={async () => {
                  await AuthService.logout();
                  navigate("/");
                }}
                position="right"
                name="LogOut"
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      </Menu>
    );
  } else if (AuthService.isLoggedIn()) {
    return (
      <Menu secondary className="header">
        <HeaderLogo />
        <CampaignsNavLink className="item" to="/campaigns">
          Campaigns
        </CampaignsNavLink>
        <EntitiesNavLink className="item" to="/entities">
          Entities
        </EntitiesNavLink>
        <ContactsNavLink className="item" to="/contacts">
          Contacts
        </ContactsNavLink>
        {canViewCallMenu && (
          <Dropdown text="Call Center" pointing className="item">
            <Dropdown.Menu>
              {canViewDialSessions && (
                <NavLink className="item" to="/dial-sessions">
                  Dial Sessions
                </NavLink>
              )}
              {canViewCallDashboard && (
                <NavLink className="item" to="/call_dashboard">
                  Call Dashboard
                </NavLink>
              )}
              {canViewAgentDashboard && (
                <NavLink className="item" to="/agent-dashboard">
                  Call Monitoring
                </NavLink>
              )}
              {canViewCallsTable && (
                <NavLink className="item" to="/calls">
                  Calls
                </NavLink>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {CONFIG.ENABLE_PIRACY && (
          <PiracyMenu text="Piracy" className="item">
            <Dropdown.Menu>
              <CasesNavLink className="item" to="/cases">
                Cases
              </CasesNavLink>
              <RevenueOpportunitiesNavLink
                className="item"
                to="/revenue-opportunities"
              >
                Revenue Opportunities
              </RevenueOpportunitiesNavLink>
            </Dropdown.Menu>
          </PiracyMenu>
        )}
        <DataTabNavLink className="item" to="/data">
          Data
        </DataTabNavLink>
        <TasksNavLink className="item" to="/tasks">
          Tasks
        </TasksNavLink>
        {!user.external && <AlertsNavLink />}
        <Menu secondary floated="right" className="right-menu">
          <UserStatus />
          <DocsNavLink />
          <Dropdown text={user.full_name} pointing className="link item">
            <Dropdown.Menu>
              <UserSettingsNavLink className="item" to="/user/settings">
                My Account
              </UserSettingsNavLink>
              <SettingsNavLink className="item" to="/settings">
                System Settings
              </SettingsNavLink>
              <Menu.Item
                onClick={async () => {
                  await AuthService.logout();
                  navigate("/");
                }}
                position="right"
                name="LogOut"
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      </Menu>
    );
  } else {
    return (
      <Menu secondary>
        <Menu.Item>
          <Image size="tiny" src={logoSrc} />
        </Menu.Item>
      </Menu>
    );
  }
};

class Header extends React.Component {
  forceHeaderUpdate = () => {
    this.forceUpdate();
  };

  componentDidMount() {
    ee.addListener("forceHeaderUpdate", this.forceHeaderUpdate);
  }

  componentWillUnmount() {
    ee.removeListener("forceHeaderUpdate", this.forceHeaderUpdate);
  }

  render() {
    return <TopNav />;
  }
}

export { Header };

import React from "react";
import { Container } from "semantic-ui-react";
import GroupsTable from "../admin/components/GroupsTable";
import RolesTable from "../admin/components/RolesTable";
import UsersTable from "../admin/components/UsersTable";
import Sidebar from "./components/Sidebar";
import useRouteParam from "../../hooks/params/useRouteParam";

import "./Settings.scoped.scss";

import setPageTitle from "../../helpers/title";
import CallDispositionsTable from "../call_dispositions/components/CallDispositionsTable";
import TwilioPhoneNumbersTable from "routes/twilio_phone_numbers/TwilioPhoneNumbersTable";
import VoicemailsTable from "../voicemail/components/VoicemailsTable";
import CampaignsStatusPresetsTable from "../campaign_status_presets/CampaignStatusPresetsTable";
import Regions from "../regions/Regions";
import RevenueOpportunityStatusesTable from "../../components/RevenueOpportunityStatusTable";
import AccountsTable from "../constant_contact/components/AccountsTable";
import CampaignActivityTable from "../constant_contact/components/CampaignActivityTable";
import CustomFieldConfigTable from "../custom_field/CustomFieldConfigTable";
import CustomFieldGroupsTable from "../custom_field_group/CustomFieldGroupsTable";
import EmailTemplateTable from "../email/EmailTemplateTable";
import EmailFooterTable from "../email/EmailFooterTable";
import EmailUrlsTable from "../email_urls/components/EmailUrlsTable";
import EmailSignatureTable from "../email/EmailSignatureTable";
import CustomEmailDomainsTable from "../custom_email_domains/CustomEmailDomainsTable";
import ProductsTable from "../product/ProductsTable";
import RecipientsTable from "routes/recipients/RecipientsTable";
import TagManagementTable from "../tags/TagManagementTable";
import TagCategoryTable from "../tag_category/TagCategoryTable";
import SourceStatusesTable from "../../components/SourceStatusesTable";
import CaseDomainsTable from "../../components/CaseDomainsTable";
import InvestigationStatusesTable from "../investigation_status/InvestigationStatusesTable";
import GlobalSettings from "./global/GlobalSettings";
import CampaignsStatusesTable from "../campaign_status/CampaignStatusTable";
import RevenueOpportunityStatusPresetsTable from "../revenue_opportunity_status_presets/RevenueOpportunityStatusPresetsTable";
import MachineApprovalStatusTable from "routes/machine_approval_statuses/MachineApprovalStatusTable";

const components = {
  global_settings: GlobalSettings,
  users: UsersTable,
  roles: RolesTable,
  groups: GroupsTable,
  call_dispositions: CallDispositionsTable,
  phone_numbers: TwilioPhoneNumbersTable,
  voicemails: VoicemailsTable,
  campaign_status_presets: CampaignsStatusPresetsTable,
  campaign_statuses: CampaignsStatusesTable,
  regions: Regions,
  revenue_opportunity_statuses: RevenueOpportunityStatusesTable,
  revenue_opportunity_status_presets: RevenueOpportunityStatusPresetsTable,
  constant_contact_accounts: AccountsTable,
  constant_contact_campaigns: CampaignActivityTable,
  custom_field_configs: CustomFieldConfigTable,
  email_templates: EmailTemplateTable,
  email_footers: EmailFooterTable,
  email_urls: EmailUrlsTable,
  email_signatures: EmailSignatureTable,
  products: ProductsTable,
  custom_email_domains: CustomEmailDomainsTable,
  recipients: RecipientsTable,
  tags: TagManagementTable,
  tag_categories: TagCategoryTable,
  source_statuses: SourceStatusesTable,
  case_domains: CaseDomainsTable,
  custom_field_groups: CustomFieldGroupsTable,
  investigation_statuses: InvestigationStatusesTable,
  machine_approval_statuses: MachineApprovalStatusTable,
};

const Settings = () => {
  setPageTitle("System Settings");
  const [activeItem, setActiveItem] = useRouteParam("item");

  const DefaultView = () => (
    <div className="default-view">
      <p>
        From this page you can customize Pleteo to match how your business
        operates.
      </p>
      <p>Please select an option from the menu on the left to continue.</p>
    </div>
  );

  const Component = activeItem ? components[activeItem] : null;
  return (
    <Container fluid className="route divided settings">
      <Sidebar
        style={{ maxWidth: "250px" }}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />
      {Component ? (
        <Component style={{ width: "calc(100vw - 250px)" }} />
      ) : (
        <DefaultView />
      )}
    </Container>
  );
};

export default Settings;

import React from "react";
import "react-table-6/react-table.css";
import { Container, Dropdown, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import BaseTable from "../../components/BaseTable";
import countryOptions from "../../components/Countries";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import setPageTitle from "../../helpers/title";
import AuthService from "../../services/Auth";
import RegionService from "../../services/Regions";
import _DeleteRegionModal from "./modals/DeleteRegionModal";
import _EditRegionModal from "./modals/EditRegionModal";
import _NewRegionModal from "./NewRegionModal";
import CustomDropdown from "../../components/CustomDropdown";

const NewRegionModal = withRoleCheck(_NewRegionModal, [
  ACL_RELATIONSHIPS.region.create,
]);
const EditRegionModal = withRoleCheck(_EditRegionModal, [
  ACL_RELATIONSHIPS.region.edit,
]);
const DeleteRegionModal = withRoleCheck(_DeleteRegionModal, [
  ACL_RELATIONSHIPS.region.delete,
]);

export default class Regions extends BaseTable {
  constructor(props) {
    super(props);
    this.queryMethod = RegionService.getRegionsTable;
    this.state = {
      ...this.state,
      header: "Regions",
      headerIcon: "map marker alternate",
      tableName: "regions",
      noDataText: "No Regions found.",
      enableSearch: false,
      createButton: <NewRegionModal handleSubmit={this.handleSubmit} />,
    };
  }

  deleteRegion = async id => {
    await RegionService.deleteRegion(id);
    await this.fetchData();
  };

  componentDidMount() {
    setPageTitle("Regions");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  handleSubmit = async (name, countries) => {
    this.setState({
      error: false,
      errorMessage: "",
    });

    try {
      await RegionService.createRegion(name, countries);
      await this.fetchData();
    } catch (error) {
      this.setState({
        error: true,
        errorMessage: error.message,
      });
    }
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Name",
        accessor: "name",
        headerClassName: "padded",
        className: "padded",
      },
      {
        Header: "Countries",
        accessor: "countries",
        Cell: ({ value: countries }) =>
          countries
            .map(
              country => countryOptions.filter(r => r.value === country)[0].text
            )
            .join(", "),
      },
      {
        Header: "Actions",
        id: "actions",
        minWidth: 60,
        sortable: false,
        headerClassName: "non-sortable",
        className: "action-menu",
        Cell: ({ original: { id } }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditRegionModal id={id} fetchRegions={this.fetchData} />
              <DeleteRegionModal deleteRegion={this.deleteRegion} id={id} />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

import React from "react";
import { RichUtils } from "draft-js";
import { Icon, Popup } from "semantic-ui-react";
import { onAddLink, onRemoveLink } from "./plugins/linkify";
import { setBlockData, getSelectedBlock } from "draftjs-utils";

function StyleButton({
  onToggle,
  active,
  label,
  style,
  icon,
  buttons,
  button,
  className,
}) {
  className = `RichEditor-styleButton ${className}`;
  if (active) {
    className += " RichEditor-activeButton";
  }

  const renderTrigger = () => {
    if (icon) {
      return (
        <span
          className={className}
          onMouseDown={e => {
            e.preventDefault();
            onToggle(style);
          }}
        >
          {icon}
        </span>
      );
    }
    if (button) {
      const Btn = buttons[button];
      return <Btn className={className} />;
    }
    return null;
  };

  return (
    <Popup
      inverted
      size="tiny"
      offset={[-7]}
      mouseEnterDelay={300}
      content={<strong>{label}</strong>}
      trigger={renderTrigger()}
    />
  );
}

const HeadingIcon = ({ level }) => (
  <span>
    <Icon name="heading" fitted />
    <sub>{level}</sub>
  </span>
);

const onTextAlign = textAlignment => (editorState, setEditorState) => {
  const newEditorState = setBlockData(editorState, {
    "text-align": textAlignment,
  });
  setEditorState(newEditorState);
};

const getTextAlignBlockStyle = block => {
  const textAlignment = block.getData() && block.getData().get("text-align");
  return textAlignment ? `RichEditor-textAlignment ${textAlignment}` : null;
};

const isTextAlignActive = (editorState, style) => {
  const block = getSelectedBlock(editorState);
  const textAlignment = block.getData() && block.getData().get("text-align");
  return textAlignment ? `text-align-${textAlignment}` === style : null;
};

const CONTROL_TYPES = {
  INLINE: "CONTROL_TYPE/INLINE",
  BLOCK: "CONTROL_TYPE/BLOCK",
};

const CONTROLS = [
  {
    label: "Heading 1",
    style: "header-one",
    type: CONTROL_TYPES.BLOCK,
    icon: <HeadingIcon level={1} />,
  },
  {
    label: "Heading 2",
    style: "header-two",
    type: CONTROL_TYPES.BLOCK,
    icon: <HeadingIcon level={2} />,
  },
  {
    label: "Heading 3",
    style: "header-three",
    type: CONTROL_TYPES.BLOCK,
    icon: <HeadingIcon level={3} />,
  },
  {
    label: "Heading 4",
    style: "header-four",
    type: CONTROL_TYPES.BLOCK,
    icon: <HeadingIcon level={4} />,
  },
  {
    label: "Bold",
    style: "BOLD",
    type: CONTROL_TYPES.INLINE,
    icon: <Icon name="bold" />,
  },
  {
    label: "Italic",
    style: "ITALIC",
    type: CONTROL_TYPES.INLINE,
    icon: <Icon name="italic" />,
  },
  {
    label: "Underline",
    style: "UNDERLINE",
    type: CONTROL_TYPES.INLINE,
    icon: <Icon name="underline" />,
  },
  {
    label: "Strikethrough",
    style: "STRIKETHROUGH",
    type: CONTROL_TYPES.INLINE,
    icon: <Icon name="strikethrough" />,
  },
  {
    label: "Add Link",
    style: "LINK",
    type: CONTROL_TYPES.INLINE,
    icon: <Icon name="linkify" />,
    onToggle: onAddLink,
  },
  {
    label: "Remove Link",
    style: "UNLINK",
    type: CONTROL_TYPES.INLINE,
    icon: <Icon name="unlink" />,
    onToggle: onRemoveLink,
  },
  {
    label: "Blockquote",
    style: "blockquote",
    type: CONTROL_TYPES.BLOCK,
    icon: <Icon name="quote left" />,
    getBlockStyle: block =>
      block.getType() === "blockquote" ? "RichEditor-blockquote" : null,
  },
  {
    label: "Unordered List",
    style: "unordered-list-item",
    type: CONTROL_TYPES.BLOCK,
    icon: <Icon name="list ul" />,
  },
  {
    label: "Ordered List",
    style: "ordered-list-item",
    type: CONTROL_TYPES.BLOCK,
    icon: <Icon name="list ol" />,
  },
  {
    label: "Code Block",
    style: "code-block",
    type: CONTROL_TYPES.BLOCK,
    icon: <Icon name="code" />,
  },
  {
    label: "Align Left",
    style: "text-align-left",
    type: CONTROL_TYPES.BLOCK,
    icon: <Icon name="align left" />,
    onToggle: onTextAlign("left"),
    getBlockStyle: getTextAlignBlockStyle,
    isActive: isTextAlignActive,
  },
  {
    label: "Align Center",
    style: "text-align-center",
    type: CONTROL_TYPES.BLOCK,
    icon: <Icon name="align center" />,
    onToggle: onTextAlign("center"),
    getBlockStyle: getTextAlignBlockStyle,
    isActive: isTextAlignActive,
  },
  {
    label: "Align Right",
    style: "text-align-right",
    type: CONTROL_TYPES.BLOCK,
    icon: <Icon name="align right" />,
    onToggle: onTextAlign("right"),
    getBlockStyle: getTextAlignBlockStyle,
    isActive: isTextAlignActive,
  },
  {
    label: "Justify",
    style: "text-align-justify",
    type: CONTROL_TYPES.BLOCK,
    icon: <Icon name="align justify" />,
    onToggle: onTextAlign("justify"),
    getBlockStyle: getTextAlignBlockStyle,
    isActive: isTextAlignActive,
  },
  {
    label: "Undo",
    button: "undo",
    className: "UndoRedoButton",
  },
  {
    label: "Redo",
    button: "redo",
    className: "UndoRedoButton",
  },
];

function Controls({ editorState, setEditorState, buttons, refs }) {
  const isActive = (type, style, parentIsActive) => {
    if (parentIsActive) {
      return parentIsActive(editorState, style);
    }
    let active;
    if (type === CONTROL_TYPES.INLINE) {
      const currentStyle = editorState.getCurrentInlineStyle();
      active = currentStyle.has(style);
    } else {
      const block = getSelectedBlock(editorState);
      const blockType = block.getType();
      active = style === blockType;
    }
    return active;
  };
  const handleToggle = (type, parentOnToggle) => style => {
    if (parentOnToggle) {
      parentOnToggle(editorState, setEditorState, refs);
    } else {
      const toggle =
        type === CONTROL_TYPES.INLINE
          ? RichUtils.toggleInlineStyle
          : RichUtils.toggleBlockType;
      const newState = toggle(editorState, style);
      setEditorState(newState);
    }
  };

  return (
    <div className="RichEditor-controls">
      {CONTROLS.map(
        ({
          label,
          style,
          type,
          icon,
          onToggle: parentOnToggle,
          isActive: parentIsActive,
          button,
          className,
        }) => (
          <StyleButton
            key={label}
            active={isActive(type, style, parentIsActive)}
            label={label}
            icon={icon}
            onToggle={handleToggle(type, parentOnToggle)}
            style={style}
            buttons={buttons}
            button={button}
            className={className}
          />
        )
      )}
    </div>
  );
}

function getBlockStyle(block) {
  let style = null;
  for (const { getBlockStyle } of CONTROLS) {
    if (!getBlockStyle) {
      continue;
    }
    const newStyle = getBlockStyle(block);
    if (newStyle) {
      style = newStyle;
      break;
    }
  }
  return style;
}

export default Controls;
export { getBlockStyle };

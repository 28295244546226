import React, { useRef, useState } from "react";
import { Button, Checkbox } from "semantic-ui-react";
import PermissionModal from "./PermissionModal";
import SortableTable from "../../../components/SortableTable";

const PermissionsTable = ({ permissions, onSetPermissions, loading }) => {
  const permissionModal = useRef();
  const [filters, setFilters] = useState({});
  const columns = [
    {
      header: "Route",
      value: "path",
      headerProps: { width: "five" },
      sort: "ascending",
    },
    {
      header: "Method",
      value: "method",
      headerProps: {
        width: "one",
      },
      cell: value => value.toUpperCase(),
      string: value => value.toUpperCase(),
    },
    {
      header: "Description",
      value: "description",
      headerProps: {
        width: "three",
      },
    },
    {
      header: "Enabled",
      value: "is_allowed",
      headerProps: {
        width: "one",
      },
      cellProps: {
        textAlign: "center",
      },
      cell: (value, item) => (
        <Checkbox
          checked={item.is_allowed}
          onChange={(_, { checked }) => handleChange(item, checked)}
        />
      ),
      string: value => (value ? "Yes" : "No"),
    },
  ];

  const handleChange = async (permission, enabled) => {
    const index = permissions.findIndex(
      ({ path, method }) =>
        path === permission.path && method === permission.method
    );
    const newPermission = {
      ...permission,
      is_allowed: enabled,
    };
    const newPermissions = [
      ...permissions.slice(0, index),
      newPermission,
      ...permissions.slice(index + 1),
    ];
    onSetPermissions(newPermissions);
  };

  const handleFilter = ({ path, method, is_allowed }) => {
    const filters = {
      ...(path && { path }),
      ...(method.length > 0 && { method }),
      ...(is_allowed !== undefined && { is_allowed }),
    };
    setFilters(filters);
  };

  const applyFilters = permissions => {
    const filtered = permissions.filter(({ path, method, is_allowed }) => {
      let condition = true;
      if ("path" in filters) {
        condition = condition && path.includes(filters.path);
      }
      if ("method" in filters) {
        condition = condition && filters.method.includes(method);
      }
      if ("is_allowed" in filters) {
        condition = condition && is_allowed === filters.is_allowed;
      }
      return condition;
    });
    return filtered;
  };

  const removeFilter = filter => {
    const { [filter]: _, ...newFilters } = filters;
    setFilters(newFilters);
  };

  const getFilterButtonText = (filter, value) => {
    const { header, string = i => i } = columns.find(
      ({ value }) => value === filter
    );
    let val;
    if (Array.isArray(value)) {
      val = value.map(string).join(", ");
    } else {
      val = string(value);
    }
    return `${header}: ${val}`;
  };

  return (
    <div className="permissions-table">
      <PermissionModal ref={permissionModal} onFilter={handleFilter} />
      <Button
        icon="filter"
        size="tiny"
        className="standard"
        onClick={() => permissionModal.current.open(filters)}
      />
      {Object.entries(filters).map(([filter, value]) => (
        <Button
          key={filter}
          icon="close"
          size="tiny"
          content={getFilterButtonText(filter, value)}
          onClick={() => removeFilter(filter)}
        />
      ))}
      <SortableTable
        columns={columns}
        data={applyFilters(permissions)}
        keyExtractor={({ path, method }) => path + method}
        noDataText="No permissions"
        loading={loading}
      />
    </div>
  );
};

export default PermissionsTable;

import React, { Component } from "react";
import { Button, Header, Icon, Modal, Dropdown } from "semantic-ui-react";

export default class DeleteRegionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  deleteRegion = async () => {
    await this.props.deleteRegion(this.props.id); // maybe-promise
    this.handleClose();
  };

  render() {
    return (
      <Modal
        trigger={
          <Dropdown.Item
            onClick={this.handleOpen}
            content="Delete"
            icon="trash"
          />
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        size="small"
        closeIcon
      >
        <Header icon="warning sign" content="Delete Region" />
        <Modal.Content>
          <p>Are you sure you want to delete this Region?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button className="warning cancel" onClick={this.handleClose}>
            <Icon name="remove" /> No
          </Button>
          <Button className="warning confirm" onClick={this.deleteRegion}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Confirm } from "semantic-ui-react";
import CustomFieldService from "../../../services/CustomField";

const DeleteFieldModal = forwardRef(({ refreshTable }, ref) => {
  const [open, setOpen] = useState(false);
  const [modelType, setModelType] = useState(null);
  const [field, setField] = useState(null);
  const [customFieldId, setCustomFieldId] = useState(null);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = (modelType, label, id) => {
    setModelType(modelType);
    setField(label);
    setCustomFieldId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    await CustomFieldService.deleteCustomFieldConfig(customFieldId);
    handleSuccess();
  };

  const handleSuccess = () => {
    handleClose();
    refreshTable();
  };

  return (
    <Confirm
      open={open}
      onConfirm={handleSubmit}
      onCancel={handleClose}
      header="Delete Custom Field Config"
      confirmButton="YES"
      cancelButton="NO"
      content={
        <div className="content">
          Are you sure you wish to delete custom field config{" "}
          <strong>'{field}'</strong> and model type{" "}
          <strong>'{modelType}'</strong>?
        </div>
      }
    />
  );
});

export default DeleteFieldModal;

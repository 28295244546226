import React from "react";
import {
  Checkbox,
  Container,
  Dropdown,
  List,
  Segment,
  Icon,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import moment from "moment";

import "./../../styles/campaign.scss";
import "./../../styles/table.scss";

import ContactService from "../../services/Contact";
import UserService from "../../services/User";
import BaseTable from "../../components/BaseTable";
import CustomDropdown from "../../components/CustomDropdown";
import _AddContactModal from "../entity/components/AddContactModal";
import _MergeContactsModal from "./components/MergeContactsModal";
import {
  DeleteContactModal as _DeleteContactModal,
  EditContactModal as _EditContactModal,
} from "../entity/components/Modals";
import AuthService from "../../services/Auth";
import TagList from "../../components/TagList";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import { checkIsAuthorized } from "../../components/helpers";
import EntityService from "../../services/Entity";
import CampaignService from "../../services/Campaign";
import CallModal from "../../components/modals/CallModal";
import VoicemailService from "../../services/Voicemails";
import setPageTitle from "../../helpers/title";
import DepartmentService from "../../services/Department";
import ListTableColumn from "components/ListTableColumn";
import _TransferContactsModal from "components/modals/TransferContactsModal";
import { SendEmailModal as _SendEmailModal } from "routes/campaign/components/Modals";

const AddContactModal = withRoleCheck(_AddContactModal, [
  ACL_RELATIONSHIPS.entityContact.create,
  ACL_RELATIONSHIPS.entityForFilters.read,
]);
const EditContactModal = withRoleCheck(_EditContactModal, [
  ACL_RELATIONSHIPS.entityContact.edit,
  ACL_RELATIONSHIPS.entityForFilters.read,
]);
const MergeContactsModal = withRoleCheck(_MergeContactsModal, [
  ACL_RELATIONSHIPS.contactMerge.create,
]);
const DeleteContactModal = withRoleCheck(_DeleteContactModal, [
  ACL_RELATIONSHIPS.entityContact.delete,
]);
const TransferContactsModal = withRoleCheck(_TransferContactsModal, [
  ACL_RELATIONSHIPS.entityContact.create,
  ACL_RELATIONSHIPS.entityForFilters.read,
]);
const SendEmailModal = withRoleCheck(_SendEmailModal, [
  ACL_RELATIONSHIPS.emailTemplates.read,
  ACL_RELATIONSHIPS.contactEmail.create,
]);

class ContactsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = ContactService.getContacts;
    this.enableCalling = false;

    this.state = {
      ...this.state,
      header: "Contacts",
      headerIcon: "user",
      className: "Contact",
      tableName: "contacts",
      noDataText: "No Contacts found. Try adjusting your filters.",
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.contactTags.create,
        ACL_RELATIONSHIPS.contactTags.read,
        ACL_RELATIONSHIPS.contactTags.delete,
      ]),
      enableMergeContacts: checkIsAuthorized([
        ACL_RELATIONSHIPS.contactMerge.create,
      ]),
      users: [],
      createButton: (
        <AddContactModal onSuccess={this.fetchData} model="Contact" />
      ),
      enableCustomFieldsSettings: true,
      // calling props
      activeSession: null,
      voicemails: [],
      exportTableName: "ContactsTable",
    };
  }

  async componentDidMount() {
    if (!this.props.entityId) {
      setPageTitle("Contacts");
    }
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchUsers();
      this.fetchTags();
      this.fetchCustomFields();

      if (this.enableCalling) {
        this.fetchVoicemails();
      }
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => (
          <Link to={"/contacts/" + props.value}>{props.value}</Link>
        ),
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => {
          return (
            <TagList
              tags={props.value}
              modelType={this.state.className}
              modelId={props.original.id}
              onUpdate={this.fetchData}
              tableCell
            />
          );
        },
      },
      {
        Header: "Name",
        accessor: "full_name",
        Cell: props => (
          <Link to={"/contacts/" + props.original.id}>{props.value}</Link>
        ),
      },
      {
        Header: "Alternate Names",
        accessor: "alternate_names",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ value }) => (
          <ListTableColumn
            itemKey={"id"}
            itemField={"name"}
            data={value || []}
          />
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Emailable",
        accessor: "emailable",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }}>
              <Icon name={props.value ? "check" : "close"} />
            </div>
          );
        },
      },
      {
        Header: "Callable",
        accessor: "callable",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }}>
              <Icon name={props.value ? "check" : "close"} />
            </div>
          );
        },
      },
      {
        Header: "Department",
        accessor: "department_name",
        className: "centered",
      },
      {
        Header: "Alternate Emails",
        accessor: "email_addresses",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ value }) => (
          <ListTableColumn
            itemKey={"id"}
            itemField={"email"}
            filterFn={email => !email.is_primary}
            data={value || []}
          />
        ),
      },
      {
        Header: "Phone Numbers",
        accessor: "phone_numbers",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ value }) => (
          <ListTableColumn
            data={value || []}
            itemKey={"id"}
            itemValue={phone =>
              phone.ext ? `${phone.number}x${phone.ext}` : phone.number
            }
          />
        ),
      },
      {
        Header: "Timezone",
        accessor: "timezone",
      },
      {
        Header: "Entity",
        accessor: "entity_name",
        Cell: props => (
          <Link to={"/entities/" + props.original.entity_id}>
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Campaigns",
        accessor: "campaign_targets",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ value }) => (
          <List horizontal bulleted style={{ display: "flex" }}>
            {value.map(({ campaign_id, campaign_name }) => (
              <List.Item>
                <Link to={`/campaigns/${campaign_id}`}>{campaign_name}</Link>
              </List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Account Managers",
        sortable: false,
        headerClassName: "non-sortable",
        accessor: "account_managers",
        Cell: props => (
          <List horizontal bulleted>
            {props.value.map(manager => (
              <List.Item key={manager.user_id}>
                {manager.user_full_name}
              </List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Note Count",
        accessor: "notes_count",
        className: "centered",
        Cell: props => (
          <span>
            <Link to={"/contacts/" + props.original.id + "/notes"}>
              {props.value ? props.value : 0}
            </Link>
          </span>
        ),
      },
      {
        Header: "Last Activity Date",
        accessor: "last_activity_date",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return null;
        },
      },
    ];

    if (this.enableCalling) {
      columns.push({
        Header: "Call",
        id: "call",
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 140,
        Cell: ({ original }) => {
          const contact = original;
          const { voicemails, activeSession } = this.state;
          return (
            <CallModal
              contactId={contact.id}
              disabled={
                !!activeSession ||
                !contact.phone_numbers.length ||
                !contact.phone_numbers.some(({ is_callable }) => is_callable) ||
                !contact.phone_numbers.some(({ enabled }) => enabled)
              }
              voicemails={voicemails}
            />
          );
        },
      });
    }

    columns.push({
      Header: "Actions",
      id: "actions",
      minWidth: 60,
      sortable: false,
      headerClassName: "centered non-sortable",
      className: "action-menu",
      Cell: props => (
        <CustomDropdown icon="ellipsis horizontal">
          <Dropdown.Menu direction="left">
            <EditContactModal
              key={props.original.id}
              entityId={props.original.entity_id}
              contact={props.original}
              onSuccess={this.fetchData}
            />
            <DeleteContactModal
              onConfirmDelete={async () => {
                await EntityService.deleteContact(
                  props.original.entity_id,
                  props.original.id
                );
                this.fetchData();
              }}
              menuTrigger
            />
          </Dropdown.Menu>
        </CustomDropdown>
      ),
    });
    this.initTableSettings(columns);
  };

  actionsDropdown() {
    const { checkedArr, mergeMode, enableMergeContacts, rows } = this.state;
    return (
      <Dropdown
        text="Actions"
        className="button mini"
        disabled={checkedArr.length === 0 && false}
      >
        <Dropdown.Menu direction="left">
          <TransferContactsModal
            checkedArr={checkedArr}
            uncheckAll={this.uncheckAll}
            fetchData={this.fetchData}
            isMerging={mergeMode}
          />
          {enableMergeContacts && this.renderMergeButton(true)}
          <SendEmailModal
            trigger={
              <Dropdown.Item
                content="Compose Email"
                disabled={checkedArr.length == 0 || mergeMode}
              />
            }
            contactRows={rows}
            checkedArr={checkedArr}
            campaignId={this.props.campaignId}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  fetchCustomFields = async () => {
    const customFields = await ContactService.getCustomFields();
    const entitiesCustomFields = await EntityService.getCustomFields();
    this.setState(
      {
        customFields: [
          {
            accessorPrefix: "custom_fields",
            fields: customFields,
            model: "Contact",
          },
          {
            accessorPrefix: "entity_custom_fields",
            headerPrefix: "Entity",
            fields: entitiesCustomFields,
            model: "Entity",
          },
        ],
      },
      this.updateFilterOptions
    );
  };

  fetchUsers = async () => {
    const rawUsers = await UserService.getUsers();
    const users = await rawUsers.map(user => ({
      id: user.id,
      name: user.full_name,
    }));

    this.setState({ users }, this.updateFilterOptions);
  };

  fetchVoicemails = async () => {
    let voicemails = await VoicemailService.getVoicemailsForFilter();
    this.setState({
      voicemails,
    });
  };

  mergeModal = () => {
    const { mergeItems } = this.state;
    return (
      <MergeContactsModal
        contacts={mergeItems}
        finishMerge={this.finishMerge}
      />
    );
  };

  updateFilterOptions = async () => {
    const { tags, customFields } = this.state;
    let filters = [
      {
        key: "last_activity_date",
        title: "Last Activity",
        type: "dateRange",
      },
      {
        key: "search:contact_name",
        title: "Contact Name",
        type: "input",
      },
      {
        key: "search:contact_email",
        title: "Contact Email",
        type: "input",
      },
      {
        key: "search:contact_phone",
        title: "Contact Phone",
        type: "input",
      },
      {
        key: "department_id",
        title: "Department Name",
        type: "ruvixxSelect",
        queryFn: DepartmentService.getForFilters,
      },
      {
        key: "account_manager_id",
        title: "Account Manager",
        type: "ruvixxSelect",
        queryFn: UserService.getUsers,
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "assigned_user_id",
        title: "Assignee",
        type: "ruvixxSelect",
        queryFn: UserService.getUsers,
        props: {
          includeNoneOption: true,
        },
      },
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
      {
        key: "entity_id",
        title: "Entity",
        type: "ruvixxSelect",
        queryFn: EntityService.getEntitiesForFilters,
      },
      {
        key: "campaign_id",
        title: "Campaign",
        type: "ruvixxSelect",
        queryFn: CampaignService.getCampaignsForFilters,
        props: {
          includeNoneOption: true,
        },
      },
      {
        title: "Outreach Permissions",
        key: "grid_1",
        type: "grid",
        props: {
          columns: 2,
          style: { width: "50%" },
        },
        componentProps: {
          type: "checkbox",
        },
        components: [
          { key: "is_callable", title: "Callable" },
          { key: "not_callable", title: "Not Callable" },
          { key: "is_emailable", title: "Emailable" },
          { key: "not_emailable", title: "Not Emailable" },
        ],
      },
    ];
    customFields.forEach(cf =>
      cf.fields.forEach(field => {
        filters.push({
          key: `search:custom:${cf.model}:${field}`,
          title: field,
          type: "input",
          model: cf.model,
        });
      })
    );
    this.setState({ filters });
  };

  // callback method for edit call status
  onSuccess = async (call, index) => {
    const { rows } = this.state;
    rows.splice(index, 1, call);
    this.setState({ rows });
  };

  render() {
    return (
      <Container fluid className="route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default ContactsTable;

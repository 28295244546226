import React, { useState } from "react";
import RuvixxForm from "../../../components/RuvixxForm";
import { Form } from "semantic-ui-react";
import CampaignStatusSelector from "./CampaignStatusSelector";
import CampaignService from "../../../services/Campaign";
import CampaignStatusPresetService from "../../../services/CampaignStatusPreset";

const CampaignStatusPresetForm = ({ preset, onSuccess }) => {
  const [name, setName] = useState("");
  const [campaignStatuses, setCampaignStatuses] = useState([]);
  const [allCampaignStatuses, setAllCampaignStatuses] = useState([]);

  const fetchAllCampaignStatuses = async () => {
    const statuses = await CampaignService.getCampaignStatuses();
    setAllCampaignStatuses(statuses);
  };

  useState(() => {
    fetchAllCampaignStatuses();
  }, [fetchAllCampaignStatuses]);

  useState(() => {
    if (preset) {
      setName(preset.name);
      setCampaignStatuses(preset.info.campaign_statuses);
    }
  }, [preset]);

  const handleSubmit = async () => {
    if (preset) {
      await CampaignStatusPresetService.editCampaignStatusPreset(
        preset.id,
        name,
        campaignStatuses
      );
    } else {
      await CampaignStatusPresetService.createCampaignStatusPreset(
        name,
        campaignStatuses
      );
    }
  };

  return (
    <RuvixxForm
      ready={!!name && campaignStatuses.length > 0}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      <Form.Input
        label="Name"
        inline
        required
        value={name}
        onChange={(_, { value }) => setName(value)}
      />
      <CampaignStatusSelector
        campaignStatuses={campaignStatuses}
        setCampaignStatuses={setCampaignStatuses}
        allCampaignStatuses={allCampaignStatuses}
      />
    </RuvixxForm>
  );
};

export default CampaignStatusPresetForm;

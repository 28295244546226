import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Form } from "semantic-ui-react";
import ColorPicker from "../../../components/ColorPicker";
import RuvixxForm from "../../../components/RuvixxForm";
import RuvixxSelect from "../../../components/RuvixxSelect";
import tagConstants from "../../../constants/Tag";
import { getRandomColor } from "../../../helpers/color";
import roleService from "../../../services/Role";
import TagService from "../../../services/Tag";
import TagCategoryService from "../../../services/TagCategory";

import "./TagForm.scoped.scss";
import TagMetadata from "./TagMetadata";
import UpdateExistingTagsModal from "./UpdateExistingTags";

const modelsOptions = Object.entries(tagConstants.taggableModels).map(
  ([key, value]) => ({
    key,
    value: key,
    text: value,
  })
);

const TagForm = ({ model, onSuccess, isClone = false }) => {
  const [name, setName] = useState(isClone ? "" : model?.name || "");
  const [description, setDescription] = useState(model?.description || "");
  const [color, setColor] = useState(model?.color || getRandomColor());
  const [models, setModels] = useState(model?.taggable_models || []);
  const [metadata, setMetadata] = useState(model?.tag_metadata_fields || []);
  const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [tagCategoryId, setTagCategoryId] = useState(
    model?.tag_category_id || null
  );
  const [enabled, setEnabled] = useState(
    model?.enabled !== undefined ? model?.enabled : true
  );
  const [errorMessage, setErrorMessage] = useState();
  const updateExistingTagsModal = useRef();

  const shouldPromptForUpdatingExistingTags = () => {
    let shouldPrompt = false;
    if (model?.tag_metadata_fields) {
      shouldPrompt = metadata.some(
        ({
          id,
          allow_override: allowOverride,
          default_value: defaultValue,
        }) => {
          const originalValue = model.tag_metadata_fields.find(
            field => field.id === id
          );
          if (!originalValue || allowOverride) {
            return false;
          }
          return originalValue.default_value !== defaultValue;
        }
      );
    }
    return shouldPrompt;
  };

  const handlePreSubmit = async () => {
    try {
      const shouldPrompt = shouldPromptForUpdatingExistingTags();
      if (shouldPrompt) {
        updateExistingTagsModal.current.open();
      } else {
        await handleSubmit();
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  const getMetadataWithOrdering = () => {
    return metadata.map((tag, index) => {
      return {
        ...tag,
        order: index,
      };
    });
  };

  const handleSubmit = async (updateExistingTags = false) => {
    try {
      const tagData = {
        name,
        description,
        color,
        required_roles: roles,
        taggable_models: models,
        tag_metadata_fields: getMetadataWithOrdering(),
        tag_category_id: tagCategoryId,
        enabled,
      };
      if (model?.id && !isClone) {
        await TagService.editTag(model?.id, {
          ...tagData,
          update_model_tags: updateExistingTags,
        });
      } else {
        await TagService.createTag(tagData);
      }
      onSuccess();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const fetchRoles = async () => {
    let roles = await roleService.getRoles();
    roles = mapOptions(roles);
    let allRoles = {
      text: "All Roles",
      value: 0,
    };
    roles.unshift(allRoles);
    setUserRoles(roles);
  };

  const mapRoles = roles => {
    let roles_copy = roles.map(({ id, name }) => id);
    if (roles_copy.length === 0) {
      roles_copy = [0];
    }
    setRoles(roles_copy);
  };

  const mapOptions = data => {
    let options = data.map(option => ({
      text: option.name,
      value: option.id,
    }));
    return options;
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    if (model?.required_roles) {
      mapRoles(model?.required_roles);
    }
  }, [model]);

  return (
    <>
      <UpdateExistingTagsModal
        ref={updateExistingTagsModal}
        onSubmit={handleSubmit}
      />
      <RuvixxForm
        className="tag-form"
        ready={
          !!name &&
          !!description &&
          !!color &&
          models.length > 0 &&
          roles.length > 0 &&
          metadata.every(
            ({ field_name, required_roles }) =>
              !!field_name && required_roles.length > 0
          )
        }
        onSubmit={handlePreSubmit}
        error={!!errorMessage}
        errorMessage={errorMessage}
      >
        <Form.Input
          inline
          label="Name"
          required
          value={name}
          onChange={(_, { value }) => setName(value)}
          autoFocus={isClone || !model?.name}
        />
        <Form.TextArea
          className="textarea"
          inline
          label="Description"
          placeholder="What is this tag for?"
          required
          rows={2}
          value={description}
          onChange={(_, { value }) => setDescription(value)}
        />
        <Form.Field inline required>
          <label>Color</label>
          <ColorPicker color={color} setColor={setColor} />
        </Form.Field>
        <Form.Select
          multiple
          search
          required
          inline
          label="Taggable Models"
          options={modelsOptions}
          value={models}
          onChange={(_, { value }) => {
            setModels(value.includes("all_models") ? ["all_models"] : value);
          }}
        />
        <Form.Select
          multiple
          clearable
          search
          required
          inline
          label="Required Roles"
          value={roles}
          options={userRoles}
          onChange={(_, { value }) => {
            setRoles(value.includes(0) ? [0] : value);
          }}
        />
        <Form.Field inline>
          <label>
            Enabled
            <span className="requiredStar" />
          </label>
          <Checkbox
            toggle
            checked={enabled}
            onChange={(_, { checked }) => setEnabled(checked)}
          />
        </Form.Field>
        <RuvixxSelect
          name="tagCategoryId"
          label="Tag Category"
          value={tagCategoryId}
          queryFn={TagCategoryService.getTagCategoryForFilters}
          createFn={async name =>
            TagCategoryService.createTagCategory({ name })
          }
          allowAdditions
          clearable
          onChange={(_, { value }) => setTagCategoryId(value)}
          setValue={value => setTagCategoryId(value.id)}
        />
        <Form.Field>
          <label>Metadata</label>
          <TagMetadata
            metadata={metadata}
            setMetadata={setMetadata}
            userRoles={userRoles}
          />
        </Form.Field>
        {model?.created_by_user?.full_name && (
          <p style={{ fontVariant: "all-small-caps" }}>
            Created by <strong>{model.created_by_user.full_name}</strong>
          </p>
        )}
        {model?.created_by_user?.full_name && (
          <p style={{ fontVariant: "all-small-caps" }}>
            Updated by <strong>{model.updated_by_user.full_name}</strong>
          </p>
        )}
      </RuvixxForm>
    </>
  );
};

export default TagForm;

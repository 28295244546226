import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import RuvixxForm from "../RuvixxForm";
import RevenueOpportunityService from "../../services/RevenueOpportunities";

const RevenueOpportunityStatusForm = ({ statusId, onSuccess }) => {
  const [statusName, setStatusName] = useState("");
  const [children, setChildren] = useState([]);

  const fetchRevenueOpportunityStatus = useCallback(async () => {
    if (!statusId) {
      return;
    }
    const status = await RevenueOpportunityService.getRevenueOpportunityStatus(
      statusId
    );
    setStatusName(status.name);
    setChildren(status.children);
  }, [statusId]);

  useEffect(() => {
    fetchRevenueOpportunityStatus();
  }, [fetchRevenueOpportunityStatus]);

  const addChild = () => {
    const newChildren = [...children, { name: "" }];
    setChildren(newChildren);
  };

  const deleteChild = index => {
    const newChildren = [
      ...children.slice(0, index),
      ...children.slice(index + 1),
    ];
    setChildren(newChildren);
  };

  const updateChild = (index, value) => {
    const newChild = { ...children[index], name: value };
    const newChildren = [
      ...children.slice(0, index),
      newChild,
      ...children.slice(index + 1),
    ];
    setChildren(newChildren);
  };

  const handleSubmit = async () => {
    const data = { name: statusName, children };
    if (statusId) {
      await RevenueOpportunityService.editRevenueOpportunityStatus(
        statusId,
        data
      );
    } else {
      await RevenueOpportunityService.createRevenueOpportunityStatus(data);
    }
  };

  return (
    <RuvixxForm
      ready={statusName}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      <Form.Input
        inline
        name="name"
        label="Name"
        value={statusName}
        onChange={(_, { value }) => setStatusName(value)}
        autoFocus
      />
      <Form.Field>
        <label>Children</label>
      </Form.Field>
      {children.map((child, index) => (
        <Form.Group inline>
          <Form.Input
            inline
            value={child.name}
            onChange={(_, { value }) => updateChild(index, value)}
            autoFocus={!child.id}
          />{" "}
          <Icon
            name="close"
            color="red"
            link
            onClick={(_, { value }) => deleteChild(index, value)}
          />
        </Form.Group>
      ))}
      <Button
        basic
        size="tiny"
        icon="plus"
        content="Add Child"
        onClick={addChild}
      />
    </RuvixxForm>
  );
};

export default RevenueOpportunityStatusForm;

import React from "react";
import { Button, Icon } from "semantic-ui-react";
import "./DialerFooter.scoped.scss";

function FooterButtons({
  disabled,
  callInfo,
  micState,
  unmuteToAgent,
  unmuteToAll,
  mute,
}) {
  return (
    <div className="dialer-footer-btns center">
      <Button
        size="tiny"
        color="green"
        icon="phone volume"
        content={
          micState !== "unmute-agent"
            ? `Unmute to (${callInfo.agent_full_name || "agent"})`
            : "Mute"
        }
        onClick={micState !== "unmute-agent" ? unmuteToAgent : mute}
        disabled={disabled}
      />
      <Button
        size="tiny"
        color="green"
        icon="phone volume"
        content={micState !== "unmute-all" ? "Unmute to All" : "Mute"}
        onClick={micState !== "unmute-all" ? unmuteToAll : mute}
        disabled={disabled}
      />
    </div>
  );
}

export default function DialerMonitorFooter({
  handleLeaveCall,
  deviceStatus,
  callInfo,
  micState,
  unmuteToAgent,
  unmuteToAll,
  mute,
}) {
  const inCall = deviceStatus === "connected";
  return (
    <div className="dialer-footer">
      <div></div>
      <FooterButtons
        disabled={!inCall}
        callInfo={callInfo}
        micState={micState}
        unmuteToAgent={unmuteToAgent}
        unmuteToAll={unmuteToAll}
        mute={mute}
      />
      <Button size="tiny" className="red right" icon onClick={handleLeaveCall}>
        Leave Call
        <Icon name="arrow right" />
      </Button>
    </div>
  );
}

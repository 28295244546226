import React from "react";
import { Link } from "react-router-dom";
import { Checkbox, Container, Dropdown, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import BaseTable from "../../components/BaseTable";
import CustomDropdown from "../../components/CustomDropdown";
import {
  checkIsAuthorized,
  createDateTimeFormatter,
} from "../../components/helpers";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import TagList from "../../components/TagList";
import setPageTitle from "../../helpers/title";
import AuthService from "../../services/Auth";
import EmailFooterService from "../../services/EmailFooters";

import "../../styles/table.scss";
import _DeleteEmailFooterModal from "./components/DeleteEmailFooterModal";
import _EmailFooterModal from "./components/EmailFooterModal";

const EmailFooterModal = withRoleCheck(_EmailFooterModal, [
  ACL_RELATIONSHIPS.emailFooters.read,
  ACL_RELATIONSHIPS.emailFooters.create,
]);
const EditEmailFooterModal = withRoleCheck(_EmailFooterModal, [
  ACL_RELATIONSHIPS.emailFooters.create,
  ACL_RELATIONSHIPS.emailFooters.edit,
]);
const DeleteEmailFooterModal = withRoleCheck(_DeleteEmailFooterModal, [
  ACL_RELATIONSHIPS.emailFooters.read,
  ACL_RELATIONSHIPS.emailFooters.delete,
]);

class EmailFooterTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = EmailFooterService.getEmailFooters;

    this.state = {
      ...this.state,
      header: "Email Footers",
      headerIcon: "mail outline",
      className: "EmailFooter",
      tableName: "email_footers",
      noDataText: "No Email Footers found. Create one.",
      enableTags: checkIsAuthorized([
        ACL_RELATIONSHIPS.emailFootersTags.create,
        ACL_RELATIONSHIPS.emailFootersTags.read,
        ACL_RELATIONSHIPS.emailFootersTags.delete,
      ]),
      createButton: <EmailFooterModal button fetchFooters={this.fetchData} />,
    };
  }

  async componentDidMount() {
    setPageTitle("Email Footers");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
      await this.fetchTags();
      await this.fetchCustomFields();
      this.setState({
        createButton: (
          <EmailFooterModal
            button
            onSuccess={this.fetchData}
            fetchFooters={this.fetchData}
          />
        ),
      });
    }
  }

  fetchCustomFields = async () => {
    const customFields = await EmailFooterService.getCustomFields();
    this.setState(
      {
        customFields: [
          {
            accessorPrefix: "custom_fields",
            headerPrefix: "Email Footers",
            fields: customFields,
            model: "EmailFooter",
          },
        ],
      },
      this.updateFilterOptions
    );
  };

  deleteEmailFooter = async email_footer_id => {
    await EmailFooterService.deleteEmailFooter(email_footer_id);
    this.fetchData();
  };

  formatDate = () => createDateTimeFormatter({ format: "YYYY-MM-DD HH:mm" });

  setColumns = () => {
    const footerColumns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 40,
      },
      {
        Header: "Tags",
        accessor: "tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Email Footer Name",
        accessor: "name",
        Cell: props => (
          <Link
            to={{
              pathname: "/settings/email_footers/" + props.original.id,
              state: {
                footerId: props.original.id,
              },
            }}
          >
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: props => (
          <p>{this.formatDate("UTC")(props.original.created_at)}</p>
        ),
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        Cell: props => (
          <p>{this.formatDate("UTC")(props.original.updated_at)}</p>
        ),
      },
      {
        Header: "Actions",
        id: "actions",
        headerClassName: "centered",
        className: "centered",
        width: 100,
        Cell: ({ original: { id } }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditEmailFooterModal
                fetchFooters={this.fetchData}
                isClone={false}
                emailFooterId={id}
              />
              <DeleteEmailFooterModal
                menuTrigger
                onConfirmDelete={() => this.deleteEmailFooter(id)}
              />
              <EmailFooterModal
                fetchFooters={this.fetchData}
                isClone={true}
                emailFooterId={id}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(footerColumns);
  };

  updateFilterOptions = async () => {
    const { tags, customFields } = this.state;

    let filters = [
      {
        key: "tag_id",
        title: "Tag",
        type: "select",
        data: tags,
      },
    ];
    customFields.forEach(field => {
      filters.push({
        key: `search:custom:${field.model}:${field}`,
        title: field,
        type: "input",
        model: field.model,
      });
    });
    this.setState({ filters });
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default EmailFooterTable;

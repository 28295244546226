import React, { Component } from "react";
import { Form, Message, Segment, Button } from "semantic-ui-react";
import PropTypes from "prop-types";

class RuvixxForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    ready: PropTypes.bool,
    successHeader: PropTypes.string,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    errorTitle: PropTypes.string,
    submitButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    submitButtonClassName: PropTypes.string,
    cancelButtonClassName: PropTypes.string,
    success: PropTypes.bool,
    error: PropTypes.bool,
    actionBarPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    ready: true,
    actionBarPadding: 0,
    errorTitle: "",
    submitButtonText: "SUBMIT",
    cancelButtonText: "CANCEL",
    submitButtonClassName: "long",
    cancelButtonClassName: "long",
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorMessage: "",
      submitted: false,
    };
  }

  handleSubmit = async e => {
    e.preventDefault();
    if (this.state.submitted) {
      return;
    }
    this.setState({
      success: false,
      error: false,
      errorMessage: "",
      submitted: true,
    });
    try {
      if (this.props.onSubmit) {
        await this.props.onSubmit(e); // maybe-promise
      }
      this.setState({
        success: true,
      });
      if (this.props.onSuccess) {
        await this.props.onSuccess(e); // maybe-promise
      }
      setTimeout(() => {
        this.setState({
          submitted: false,
        });
      }, 1000); // allow resubmitting one second after last submission
    } catch (error) {
      this.setState({
        error: true,
        errorMessage: error.message,
        submitted: false,
      });
    }
  };

  render() {
    return (
      <Form
        success={this.state.success && this.props.success}
        error={this.state.error || this.props.error}
        style={{ margin: "1em" }}
      >
        <Segment basic style={{ padding: 0 }}>
          {this.props.children}

          {(this.props.errorMessage || this.state.errorMessage) && (
            <Message
              error
              header={`Action Failed${
                this.props.errorTitle ? ": " + this.props.errorTitle : ""
              }`}
              content={this.props.errorMessage || this.state.errorMessage}
            />
          )}
          {(this.props.successMessage || this.props.successHeader) && (
            <Message
              success
              header={this.props.successHeader || ""}
              content={this.props.successMessage || ""}
            />
          )}
        </Segment>
        <Segment
          basic
          clearing
          style={{ padding: this.props.actionBarPadding }}
          className="actionBar"
        >
          <Form.Group inline>
            {this.props.onCancel && (
              <Form.Field>
                <Button
                  content={this.props.cancelButtonText}
                  onClick={this.props.onCancel}
                  size="tiny"
                  className={this.props.cancelButtonClassName}
                />
              </Form.Field>
            )}
            {(this.props.onSubmit || this.props.onSuccess) && (
              <Form.Field>
                <Button
                  content={this.props.submitButtonText}
                  primary
                  onClick={this.handleSubmit}
                  disabled={!this.props.ready}
                  size="tiny"
                  className={this.props.submitButtonClassName}
                />
              </Form.Field>
            )}
          </Form.Group>
        </Segment>
      </Form>
    );
  }
}

export default RuvixxForm;

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import isObject from "lodash/isObject";

export default function Link({ to, ...restProps }) {
  if (to !== undefined) {
    const toIsObject = isObject(to);
    let pathname = toIsObject ? to.pathname : to;
    to = toIsObject ? { ...to, pathname } : pathname;
  }
  return <RouterLink to={to} {...restProps} />;
}

import { getRGBFromHex } from "./color";

// NOTE: this is the semantic ui border style when using basic label
const basicBorder = "1px solid rgba(34, 36, 38, 0.15)";
const disabledTextColor = "rgba(143, 143, 143, 1)";
const disabledBorder = `1px solid ${disabledTextColor}`;

function getYiq(hex) {
  const { r, g, b } = getRGBFromHex(hex);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq;
}

function getTextColor(hex) {
  const yiq = getYiq(hex);
  return yiq >= 128 ? "rgba(0, 0, 0, 0.7)" : "rgba(255, 255, 255, 1)";
}

function mapSelectTagOption(tag, styleOptions = {}) {
  const bgColor = tag.enabled ? tag.color : "#ffffff";
  const textColor = tag.enabled ? getTextColor(bgColor) : disabledTextColor;
  const border = !tag.enabled
    ? disabledBorder
    : getYiq(bgColor) >= 225
    ? basicBorder
    : undefined;

  return {
    key: tag.id,
    text: tag.name,
    value: tag.id,
    ...(tag.color && {
      className: "hideText",
      label: {
        content: tag.name,
        className: "ctag",
        style: {
          backgroundColor: bgColor,
          color: textColor,
          border: border,
          boxShadow: "none",
          fontStyle: tag.enabled ? "normal" : "italic",
          ...styleOptions,
        },
      },
    }),
  };
}

export {
  basicBorder,
  disabledBorder,
  disabledTextColor,
  getTextColor,
  getYiq,
  mapSelectTagOption,
};

import { applyFilters, doRequest } from "./helpers";

export default class RevenueOpportunityStatusPresetService {
  static async getCampaignStatusPresets() {
    return doRequest("GET", "/revenue_opportunity_status_preset/");
  }

  static async getRevenueOpportunityStatusPresetsAsTable(filters = null) {
    let path = "/revenue_opportunity_status_preset/table";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createRevenueOpportunityStatusPreset(
    name,
    revenueOpportunityStatuses
  ) {
    const data = {
      name,
      revenue_opportunity_statuses: revenueOpportunityStatuses,
    };
    return doRequest("POST", "/revenue_opportunity_status_preset/", {
      data,
    });
  }

  static async getRevenueOpportunityStatusPreset(id) {
    return doRequest("GET", `/revenue_opportunity_status_preset/${id}`);
  }

  static async editRevenueOpportunityStatusPreset(
    id,
    name,
    revenueOpportunityStatuses
  ) {
    const data = {
      name,
      revenue_opportunity_statuses: revenueOpportunityStatuses,
    };
    return doRequest("PUT", `/revenue_opportunity_status_preset/${id}`, {
      data,
    });
  }

  static async deleteRevenueOpportunityStatusPreset(id) {
    return doRequest("DELETE", `/revenue_opportunity_status_preset/${id}`);
  }
}

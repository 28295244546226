import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Form } from "semantic-ui-react";
import Addresses from "../../components/forms/addresses/Addresses";

import RuvixxForm from "../../components/RuvixxForm";
import RuvixxSelect from "../../components/RuvixxSelect";
import CaseService from "../../services/Case";
import EntityService from "../../services/Entity";
import LeadService from "../../services/Lead";
import UserService from "../../services/User";
import RegionService from "../../services/Regions";
import SourceStatusService from "../../services/SourceStatus";

import CustomFieldForm from "../../components/forms/CustomFieldForm";
import CustomFieldService from "../../services/CustomField";
import {
  extractCustom,
  getCustomFieldsForSubmit,
} from "../../components/helpers";
import { getCountryByCode } from "helpers/countries";

const formReducer = (s, a) => ({ ...s, ...a });
const initializer = model => {
  return {
    id: model?.id,
    name: "",
    entityId: null,
    investigationStatusId: null,
    website: "",
    industry: "",
    companyDescription: "",
    actionableDomain: "",
    regionId: null,
    sourceStatusId: null,
    addresses: [],
    custom: [],
    customFields: [],
    caseInvestigators: [],
  };
};

function CaseForm({ caseId, onSuccess, model, lead }) {
  const [formData, setFormData] = useReducer(formReducer, model, initializer);
  const [users, setUsers] = useState([]);
  const [investigationStatuses, setInvestigationStatuses] = useState([]);
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [countriesOptions, setContriesOptions] = useState([]);
  const [sourceStatuses, setSourceStatuses] = useState([]);
  const [customFieldConfigs, setCustomFieldConfigs] = useState([]);
  const [custom, setCustom] = useState([]);

  const fetchCase = useCallback(async caseId => {
    const resp = await CaseService.get(caseId);
    const formDataCopy = { ...formData };
    Object.keys(resp).forEach(key => {
      formDataCopy[camelCase(key)] = resp[key];
    });
    formDataCopy["caseInvestigators"] = formDataCopy["caseInvestigators"].map(
      ({ id }) => id
    );
    setFormData(formDataCopy);
    const custom = extractCustom(formDataCopy.customFields);
    setCustom(custom);
  }, []);

  const fetchUsers = async () => {
    const users = (await UserService.getUsers()).map(
      ({ id, full_name: name }) => ({ key: id, text: name, value: id })
    );
    setUsers(users);
  };

  const fetchInvestigationStatuses = async () => {
    const statuses = (
      await CaseService.getInvestigationStatusesForFilters()
    ).map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
    setInvestigationStatuses(statuses);
  };

  const fetchCustomFieldConfigs = useCallback(async () => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel("Case");

    setCustomFieldConfigs(customFieldConfigs);
  }, []);

  const handleUpdateCustomFieldConfigs = customFieldConfigs => {
    setCustomFieldConfigs(customFieldConfigs);
    const newCustomFields = customFieldConfigs
      .filter(cfc => cfc.required || cfc.selected)
      .map(cfc => ({ key: cfc.label, value: cfc.value }));
    setCustom(newCustomFields);
  };

  const fetchRegions = useCallback(async () => {
    const regions = await RegionService.getRegions();
    const options = regions.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
    setRegionsOptions(options);
  }, []);

  useEffect(() => {
    const prepareCountriesSelect = async () => {
      const region = await RegionService.getRegion(formData.regionId);
      const options = region?.countries.map(code => ({
        key: code,
        value: code,
        text: getCountryByCode(code).text,
      }));
      setContriesOptions(options);

      if (region?.countries.indexOf(formData.country) < 0) {
        setFormData({ ...formData, country: null });
      }
    };

    if (!formData.regionId) {
      setFormData({ ...formData, country: null });
      setContriesOptions([]);
      return;
    }

    prepareCountriesSelect();
  }, [formData.regionId]);

  const fetchSourceStatuses = useCallback(async () => {
    const sourceStatuses = await SourceStatusService.getForFilters();
    const options = sourceStatuses.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
    setSourceStatuses(options);
  }, []);

  useEffect(() => {
    if (caseId) {
      fetchCase(caseId);
    }
    fetchUsers();
    fetchInvestigationStatuses();
    fetchRegions();
    fetchSourceStatuses();
  }, [fetchCase, caseId]);

  useEffect(() => {
    fetchCustomFieldConfigs();
  }, []);

  useEffect(() => {
    if (lead?.id) {
      setFormData({
        id: model?.id,
        name: lead.name,
        organization: lead.name,
        entityId: null,
        investigationStatusId: null,
        website: lead.urls?.join(", "),
        industry: undefined,
        companyDescription: "",
        addresses: lead.addresses || [],
        custom: [],
        caseInvestigators: [],
      });
    }
  }, [lead, model]);

  const handleChange = (_, { name, type, checked, value }) => {
    if (!name) return;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const updateAddresses = addresses => {
    setFormData({ ...formData, addresses: addresses });
  };

  const handleSubmit = async e => {
    const cust = getCustomFieldsForSubmit(customFieldConfigs, custom);
    formData["customFields"] = cust;
    const data = {};
    Object.keys(formData).forEach(key => {
      data[snakeCase(key)] = !!formData[key] ? formData[key] : null;
    });

    try {
      if (caseId) {
        await CaseService.update(caseId, data);
      } else {
        const { id } = await CaseService.create(data);
        if (lead?.id) {
          // lead is present when creating a new case from lead
          await LeadService.addToCase({
            lead_ids: [parseInt(lead.id)],
            case_id: id,
            custom_fields: cust,
          });
        }
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  return (
    <RuvixxForm
      ready={!!formData.name}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    >
      <Form.Input
        inline
        required
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      {lead ? (
        <Form.Input
          inline
          required
          label="Organization"
          name="organization"
          value={formData.name}
          onChange={handleChange}
        />
      ) : (
        <RuvixxSelect
          label="Entity"
          name="entityId"
          clearable
          allowAdditions
          createFn={EntityService.createEntity}
          value={formData.entityId}
          onChange={handleChange}
          queryFn={EntityService.getEntitiesForFilters}
          setValue={value =>
            setFormData({ ...formData, entityId: value?.id || null })
          }
        />
      )}
      <Form.Select
        label="Investigators"
        inline
        clearable
        search
        selection
        multiple
        name="caseInvestigators"
        options={users}
        value={formData.caseInvestigators}
        onChange={handleChange}
      />
      <Form.Select
        label="Investigation Status"
        inline
        clearable
        search
        selection
        name="investigationStatusId"
        options={investigationStatuses}
        value={formData.investigationStatusId}
        onChange={handleChange}
      />
      <Form.Input
        inline
        label="Website"
        name="website"
        value={formData.website}
        onChange={handleChange}
      />
      <Form.Input
        inline
        label="Industry"
        name="industry"
        value={formData.industry}
        onChange={handleChange}
      />
      <Form.Input
        inline
        label="Company Description"
        name="companyDescription"
        value={formData.companyDescription}
        onChange={handleChange}
      />
      <Form.Input
        inline
        label="Actionable Domain"
        name="actionableDomain"
        value={formData.actionableDomain}
        onChange={handleChange}
      />
      <Form.Select
        inline
        label="Region"
        name="regionId"
        search
        clearable
        options={regionsOptions}
        value={formData.regionId}
        onChange={handleChange}
      />
      <Form.Select
        inline
        label="Country"
        name="country"
        disabled={!formData.regionId}
        search
        clearable
        options={countriesOptions}
        value={formData.country}
        onChange={handleChange}
      />
      <Form.Select
        inline
        label="Source Status"
        name="sourceStatusId"
        search
        clearable
        options={sourceStatuses}
        value={formData.sourceStatusId}
        onChange={handleChange}
      />
      <Addresses
        addresses={formData.addresses}
        updateAddresses={updateAddresses}
      />
      {customFieldConfigs && (
        <CustomFieldForm
          modelType="Case"
          customFields={custom}
          customFieldConfigs={customFieldConfigs}
          onUpdateCustomFieldConfigs={handleUpdateCustomFieldConfigs}
        />
      )}
    </RuvixxForm>
  );
}

CaseForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default CaseForm;

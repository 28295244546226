import React, { useState } from "react";
import { Modal, Button, Message, Form } from "semantic-ui-react";
import CampaignService from "services/Campaign";
import RuvixxForm from "components/RuvixxForm";
import campaignConstants from "constants/Campaign";

export default function BatchEditCampaignModal({ campaigns, finishBatchEdit }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSuccess(false);
    setError(null);
    finishBatchEdit();
  };

  const handleSubmit = async () => {
    setError(null);
    try {
      const editStatusPromises = campaigns.map(campaignID => {
        return CampaignService.editStatusInCampaign(campaignID, selectedStatus);
      });

      Promise.all(editStatusPromises)
        .then(_ => {
          setSuccess(true);
        })
        .catch(error => {
          setError(error.toString());
        });
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  const handleSelect = (_, { value }) => {
    setSelectedStatus(value);
  };

  const statusOptions = campaignConstants.status.map(status => {
    return { key: status.id, text: status.name, value: status.id };
  });

  return (
    <Modal
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      open={modalOpen}
      trigger={
        <Button onClick={handleOpen} size="mini">
          Batch Edit Campaigns
        </Button>
      }
    >
      <Modal.Header>Batch Edit</Modal.Header>
      <Modal.Content>
        {!!error && (
          <Message error header="Batch edit failed" content={error} />
        )}
        {success ? (
          <p>Success!</p>
        ) : (
          <>
            <p>
              Editing campaigns: <b>{campaigns.join()}</b>
            </p>
            <RuvixxForm onSubmit={handleSubmit}>
              <Form.Select
                inline
                required
                label="Status"
                name="status"
                onChange={handleSelect}
                search
                options={statusOptions}
              />
            </RuvixxForm>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
}

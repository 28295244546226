import React from "react";
import { Tab } from "semantic-ui-react";

import NotesView from "../../../components/views/NotesView";

const NotesTab = ({ id }) => (
  <Tab.Pane>
    <NotesView modelType="Task" modelId={id} fluid />
  </Tab.Pane>
);

export default NotesTab;

import countries from "constants/countries";

function getCountryByCode(code) {
  return (
    code &&
    countries.COUNTRY_OPTIONS.find(country => {
      return country.value === code;
    })
  );
}

export { getCountryByCode };

import { Button, Card, Grid, Icon } from "semantic-ui-react";
import React, { useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";

import ActivityService from "../services/Activity";
import withRoleCheck from "./hocs/withRoleCheck";
import { EditNoteModal as _EditNoteModal } from "./modals/NoteModals";
import ACL_RELATIONSHIPS from "../acl-relationships";
import ConfirmationModal from "./modals/ConfirmationModal";
import { formatDate } from "./helpers";
import RichTextEditor from "./RichEditor/RichTextEditor";

const EditNoteModal = withRoleCheck(_EditNoteModal, [
  ACL_RELATIONSHIPS.activity.edit,
]);
const DeleteNoteConfirmationModal = withRoleCheck(ConfirmationModal, [
  ACL_RELATIONSHIPS.activity.delete,
]);

async function deleteNote(note) {
  await ActivityService.deleteActivityLog(note.model_type, note.id);
}

const modelMap = {
  AuditLog: "Activity Log",
};

const NoteItem = props => {
  const { note, categories, handleTabChange, onChange } = props;

  const category =
    note.category_id && categories.find(({ key }) => key === note.category_id);

  return (
    <Card fluid>
      <Card.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column className="icon">
              <Icon name="sticky note outline" />
            </Grid.Column>
            <Grid.Column className="main">
              <DeleteNoteConfirmationModal
                actionDescription="Delete Note"
                onConfirm={async () => {
                  await deleteNote(note);
                  onChange();
                }}
                trigger={
                  <Button
                    basic
                    icon="delete"
                    floated="right"
                    className="edit"
                  />
                }
                warning
              >
                <p>Are you sure you want to delete this Note?</p>
              </DeleteNoteConfirmationModal>
              <EditNoteModal
                note={note}
                categories={categories}
                onSuccess={onChange}
                trigger={
                  <Button
                    basic
                    icon="edit outline"
                    floated="right"
                    className="edit"
                  />
                }
              />
              <Card.Header>Note</Card.Header>
              <span>
                Submitted by <strong>{note.creator_user_full_name}</strong>
              </span>
              <span>{formatDate(note.created_at)}</span>
              {category && <em>{` - ${category.text}`}</em>}

              {note.info.comment ? (
                <p>{note.info.comment}</p>
              ) : (
                <RichTextEditor
                  editorState={JSON.parse(note.editor_state)}
                  readOnly
                  style={{ marginTop: 10 }}
                />
              )}

              {note.attachments && note.attachments.length > 0 && (
                <p>
                  Attached files:{" "}
                  <div>
                    {note.attachments.map((a, i) => (
                      <>
                        <a
                          href={a.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {a.file_name}
                        </a>
                        {i + 1 < note.attachments.length && ", "}
                      </>
                    ))}
                  </div>
                </p>
              )}
            </Grid.Column>
          </Grid.Row>
          {note.info && note.info.refers_to_id && (
            <Grid.Row className="buttons">
              <Button
                className="textLink"
                onClick={e =>
                  handleTabChange &&
                  handleTabChange(e, {
                    name: "activityLog",
                    auditLogId: note.info.refers_to_id,
                  })
                }
              >
                See Original {modelMap[note.info.refers_to_model]}{" "}
                <Icon name="external" />
              </Button>
            </Grid.Row>
          )}
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default NoteItem;

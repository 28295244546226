import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import PropTypes from "prop-types";

import RuvixxForm from "components/RuvixxForm";
import CampaignService from "services/Campaign";

const AssignCampaignForm = ({ onSuccess, updateCampaignId }) => {
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [campaignId, setCampaignId] = useState(null);

  const fetchCampaigns = async () => {
    const campaigns = await CampaignService.getCampaignsForFilters();
    const campaignOptions = campaigns.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
    setCampaignOptions(campaignOptions);
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const handleSubmit = () => {
    updateCampaignId(campaignId);
    onSuccess();
  };

  return (
    <RuvixxForm onSubmit={handleSubmit} onSuccess={onSuccess}>
      <Form.Select
        label="Campaign"
        placeholder="Select campaign or type to search"
        search
        options={campaignOptions}
        value={campaignId}
        onChange={(_, { value }) => setCampaignId(value)}
      />
    </RuvixxForm>
  );
};

AssignCampaignForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  updateCampaignId: PropTypes.func.isRequired,
};

export default AssignCampaignForm;

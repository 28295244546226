import React, { useEffect, useState } from "react";
import { Dropdown, Icon } from "semantic-ui-react";

export default function CustomFieldDropdown({
  customFieldConfigs,
  onUpdateCustomFieldConfigs,
}) {
  const [customFieldFormOptions, setCustomFieldsFieldFormOptions] = useState(
    []
  );
  const [customFieldFormValue, setCustomFieldsFieldFormValue] = useState([]);

  const handleCustomFieldConfigSelect = (event, data) => {
    let customFieldConfigsCopy = customFieldConfigs.map(cfc => ({
      ...cfc,
      selected: cfc.selected || cfc.id == data.value,
    }));
    onUpdateCustomFieldConfigs(customFieldConfigsCopy);
  };

  useEffect(() => {
    let customFieldFormOptions = customFieldConfigs.map(cf => ({
      id: cf.id,
      value: cf.id,
      text: cf.label,
      disabled: cf.selected || cf.required,
    }));
    setCustomFieldsFieldFormOptions(customFieldFormOptions);
    setCustomFieldsFieldFormValue(
      customFieldConfigs.filter(cf => cf.selected).map(cf => cf.id)
    );
  }, [customFieldConfigs]);

  return (
    <>
      <Dropdown
        className="button icon scrolling tiny cf-dropdown"
        basic
        search
        text={
          <>
            <Icon name="plus" />
            <strong>Add Custom Fields</strong>
          </>
        }
        name="customFieldConfigs"
        options={customFieldFormOptions}
        value={customFieldFormValue}
        onChange={handleCustomFieldConfigSelect}
        selectOnBlur={false}
      />
    </>
  );
}

import React, { useCallback, useEffect, useState, forwardRef } from "react";
import { Icon } from "semantic-ui-react";
import SequenceService from "../../../services/Sequence";
import UserService from "../../../services/User";
import AuditService from "../../../services/Audit";
import StepInfo from "./StepInfo";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import { checkIsAuthorized } from "../../../components/helpers";
import { startCase } from "../../../helpers/string";

const StepList = forwardRef(
  ({ sequence, stepsLoading, stepModal, fetchSequence }, ref) => {
    const [usersLoading, setUsersLoading] = useState(true);
    const [auditLogEventsLoading, setAuditLogEventsLoading] = useState(true);
    const { steps } = sequence;
    const [users, setUsers] = useState([]);
    const [auditLogEvents, setAuditLogEvents] = useState([]);
    const [canEdit] = useState(
      checkIsAuthorized([
        ACL_RELATIONSHIPS.sequence.read,
        ACL_RELATIONSHIPS.auditLog.events,
        ACL_RELATIONSHIPS.user.read,
        ACL_RELATIONSHIPS.sequence.edit,
      ])
    );

    const fetchUsers = useCallback(async () => {
      setUsersLoading(true);
      const users = await UserService.getUsers();
      setUsers(users);
      setUsersLoading(false);
    }, []);

    const fetchAuditLogEvents = useCallback(async () => {
      setAuditLogEventsLoading(true);
      let auditLogEvents = await AuditService.getAuditLogEvents();
      auditLogEvents = [
        {
          text: "Previous step Completed",
          key: "previous_step_completed",
          value: "previous_step_completed",
        },
        ...auditLogEvents.map((event, index) => ({
          text: startCase(event),
          key: event,
          value: event,
        })),
      ];
      setAuditLogEvents(auditLogEvents);
      setAuditLogEventsLoading(false);
    }, []);

    useEffect(() => {
      fetchUsers();
      fetchAuditLogEvents();
    }, [fetchUsers, fetchAuditLogEvents]);

    const handleEdit = stepIndex => {
      stepModal.current.open(stepIndex);
    };

    const handleDelete = async stepIndex => {
      let data = { ...sequence };
      data.campaign_ids = sequence.campaigns.map(campaign => campaign.id);
      delete data.campaigns;
      data.steps.splice(stepIndex, 1);
      await SequenceService.editSequence(sequence.id, data);
      fetchSequence();
    };

    if (stepsLoading || usersLoading || auditLogEventsLoading) {
      return (
        <Icon size="large" name="circle notched" loading className="centered" />
      );
    }

    if (steps.length === 0) {
      return <p className="centered">No Steps</p>;
    }

    return (
      <div className="stepList">
        {steps.map((step, index) => (
          <StepInfo
            key={index}
            step={step}
            stepIndex={index}
            users={users}
            auditLogEvents={auditLogEvents}
            onEdit={handleEdit}
            onDelete={handleDelete}
            canEdit={canEdit}
          />
        ))}
      </div>
    );
  }
);

export default StepList;

import React from "react";
import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import "react-table-hoc-fixed-columns/lib/styles.css";
import { Container, Dropdown, Icon, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import BaseTable from "../../../components/BaseTable";
import CustomDropdown from "../../../components/CustomDropdown";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import setPageTitle from "../../../helpers/title";
import AuthService from "../../../services/Auth";
import ConstantContactCampaignsService from "../../../services/ConstantContactCampaigns";
import _CampaignActivityModal from "./CampaignActivityModal";
import _DeleteCampaignActivityModal from "./DeleteCampaignActivityModal";

const CampaignActivityModal = withRoleCheck(_CampaignActivityModal, [
  ACL_RELATIONSHIPS.constantContactCampaigns.create,
]);
const EditCampaignActivityModal = withRoleCheck(_CampaignActivityModal, [
  ACL_RELATIONSHIPS.constantContactCampaigns.edit,
]);
const DeleteCampaignActivityModal = withRoleCheck(
  _DeleteCampaignActivityModal,
  [ACL_RELATIONSHIPS.constantContactCampaigns.delete]
);

class CampaignActivityTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = ConstantContactCampaignsService.getCampaignActivities;

    this.state = {
      ...this.state,
      className: "CampaignActivity",
      tableName: "campaignActivities",
      noDataText: "No Campaign Activities found. Try adjusting your filters.",
      createButton: <CampaignActivityModal button onSuccess={this.fetchData} />,
    };
  }

  async componentDidMount() {
    setPageTitle("Constant Contact Campaigns");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  deleteCampaignActvitity = async ccCampaignId => {
    await ConstantContactCampaignsService.deleteCampaignActivity(ccCampaignId);
    this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Campaign Activity ID",
        accessor: "cc_campaign_activity_id",
      },
      {
        Header: "Account",
        accessor: "account.email_address",
      },
      {
        Header: "Campaign",
        accessor: "campaign.name",
      },
      {
        Header: "Email Template",
        accessor: "template.name",
      },
      {
        Header: "Active",
        accessor: "active",
        Cell: ({ value }) => <Icon name={value ? "check" : "close"} />,
      },
      {
        Header: "Actions",
        id: "actions",
        width: 60,
        className: "action-menu",
        sortable: false,
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditCampaignActivityModal
                ccCampaignId={props.original.id}
                onSuccess={this.fetchData}
              />
              <DeleteCampaignActivityModal
                menuTrigger
                onConfirmDelete={() =>
                  this.deleteCampaignActvitity(props.original.id)
                }
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default CampaignActivityTable;

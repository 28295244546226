export const CASE_TIERS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  UNUSABLE: 4,
};

export const MACHINE_APPROVAL = {
  UNASSIGNED: 0,
  APPROVED: 1,
  REJECTED: 2,
  REVIEW: 3,
};

import { downloadFile as _downloadFile } from "./helpers";

const ExportService = {
  downloadFile: async (url, options = {}) => {
    const { mimetype, direct, ...restOptions } = options;
    let path;
    if (URL.canParse(url)) {
      path = `/export/download_file?url=${url}&name=${options.name}&mimetype=${mimetype}`;
    } else {
      path = new Blob([url], { type: mimetype });
    }
    await _downloadFile(path, restOptions);
  },
};

export default ExportService;

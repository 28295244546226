import { applyFilters, doRequest } from "./helpers";

export default class CustomEmailDomainsService {
  static async getDomains(filters = null) {
    let path = `/mailgun_domain/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async addDomain(formData) {
    const data = {
      name: formData.domainName,
      verified: formData.verificationStatus,
      is_default: formData.isDefault,
    };
    return doRequest("POST", `/mailgun_domain/`, { data });
  }

  static async getVerifiedDomains() {
    return doRequest("GET", `/mailgun_domain/verified`);
  }

  static async verifyDomain(domainName) {
    return doRequest("GET", `/mailgun_domain/verify/${domainName}`);
  }

  static async deleteDomain(domainId) {
    return doRequest("DELETE", `/mailgun_domain/${domainId}`);
  }

  static async getDomainDetails(domainId) {
    return doRequest("GET", `/mailgun_domain/${domainId}`);
  }

  static async updateDomain(domainId, formData) {
    const data = {
      name: formData.domainName,
      verified: formData.verificationStatus,
      is_default: formData.isDefault,
    };
    return doRequest("PUT", `/mailgun_domain/${domainId}`, { data });
  }
}

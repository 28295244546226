import React from "react";
import BaseTable from "../../components/BaseTable";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import CustomFieldGroupsService from "../../services/CustomFieldGroups";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import { Container, Dropdown, Segment } from "semantic-ui-react";
import CustomDropdown from "../../components/CustomDropdown";
import AuthService from "../../services/Auth";
import setPageTitle from "../../helpers/title";
import CustomFieldGroupModal from "./components/CustomFieldGroupModal";

const NewCustomFieldGroupModal = withRoleCheck(CustomFieldGroupModal, [
  ACL_RELATIONSHIPS.customFieldGroup.create,
]);

const EditCustomFieldGroupModalWithCheck = withRoleCheck(
  CustomFieldGroupModal,
  [ACL_RELATIONSHIPS.customFieldGroup.edit]
);

const DeleteCustomFieldGroupModal = props => {
  return (
    <ConfirmationModal
      actionDescription="Delete Custom Field Config"
      buttonColor="grey"
      onConfirm={props.onConfirmDelete}
      menuTrigger={props.menuTrigger}
      icon="trash"
      warning
    >
      <p>Are you sure you want to delete this Custom Field Group?</p>
    </ConfirmationModal>
  );
};

class CustomFieldGroupsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CustomFieldGroupsService.getCustomFieldGroups;

    this.state = {
      ...this.state,
      enableSearch: true,
      header: "Custom Field Groups",
      headerIcon: "bullseye",
      tableName: "custom_field_groups",
      noDataText: "No custom field groups found. Try adjusting your filters.",
      createButton: (
        <NewCustomFieldGroupModal button fetchData={this.fetchData} />
      ),
    };
  }

  deleteCustomFieldGroup = async customFieldGroupId => {
    await CustomFieldGroupsService.deleteCustomFieldGroup(customFieldGroupId);
    this.fetchData();
  };

  async componentDidMount() {
    setPageTitle("Custom Field Groups");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        className: "centered",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Custom Fields",
        accessor: "custom_field_configs",
        Cell: ({ original, value }) => {
          return value.map(item => item.label).join(", ");
        },
      },
      {
        Header: "Model Type",
        accessor: "model_type",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Actions",
        id: "actions",
        minWidth: 60,
        className: "action-menu",
        sortable: false,
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditCustomFieldGroupModalWithCheck
                customFieldGroup={props.original}
                fetchData={this.fetchData}
              />
              <DeleteCustomFieldGroupModal
                actionDescription="Delete Custom Field Group"
                onConfirmDelete={() =>
                  this.deleteCustomFieldGroup(props.original.id)
                }
                menuTrigger
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default CustomFieldGroupsTable;

export const ISO2TO3 = {
  BD: "BGD",
  BE: "BEL",
  BF: "BFA",
  BG: "BGR",
  BA: "BIH",
  BB: "BRB",
  WF: "WLF",
  BL: "BLM",
  BM: "BMU",
  BN: "BRN",
  BO: "BOL",
  BH: "BHR",
  BI: "BDI",
  BJ: "BEN",
  BT: "BTN",
  JM: "JAM",
  BV: "BVT",
  BW: "BWA",
  WS: "WSM",
  BQ: "BES",
  BR: "BRA",
  BS: "BHS",
  JE: "JEY",
  BY: "BLR",
  BZ: "BLZ",
  RU: "RUS",
  RW: "RWA",
  RS: "SRB",
  TL: "TLS",
  RE: "REU",
  TM: "TKM",
  TJ: "TJK",
  RO: "ROU",
  TK: "TKL",
  GW: "GNB",
  GU: "GUM",
  GT: "GTM",
  GS: "SGS",
  GR: "GRC",
  GQ: "GNQ",
  GP: "GLP",
  JP: "JPN",
  GY: "GUY",
  GG: "GGY",
  GF: "GUF",
  GE: "GEO",
  GD: "GRD",
  GB: "GBR",
  GA: "GAB",
  SV: "SLV",
  GN: "GIN",
  GM: "GMB",
  GL: "GRL",
  GI: "GIB",
  GH: "GHA",
  OM: "OMN",
  TN: "TUN",
  JO: "JOR",
  HR: "HRV",
  HT: "HTI",
  HU: "HUN",
  HK: "HKG",
  HN: "HND",
  HM: "HMD",
  VE: "VEN",
  PR: "PRI",
  PS: "PSE",
  PW: "PLW",
  PT: "PRT",
  SJ: "SJM",
  PY: "PRY",
  IQ: "IRQ",
  PA: "PAN",
  PF: "PYF",
  PG: "PNG",
  PE: "PER",
  PK: "PAK",
  PH: "PHL",
  PN: "PCN",
  PL: "POL",
  PM: "SPM",
  ZM: "ZMB",
  EH: "ESH",
  EE: "EST",
  EG: "EGY",
  ZA: "ZAF",
  EC: "ECU",
  IT: "ITA",
  VN: "VNM",
  SB: "SLB",
  ET: "ETH",
  SO: "SOM",
  ZW: "ZWE",
  SA: "SAU",
  ES: "ESP",
  ER: "ERI",
  ME: "MNE",
  MD: "MDA",
  MG: "MDG",
  MF: "MAF",
  MA: "MAR",
  MC: "MCO",
  UZ: "UZB",
  MM: "MMR",
  ML: "MLI",
  MO: "MAC",
  MN: "MNG",
  MH: "MHL",
  MK: "MKD",
  MU: "MUS",
  MT: "MLT",
  MW: "MWI",
  MV: "MDV",
  MQ: "MTQ",
  MP: "MNP",
  MS: "MSR",
  MR: "MRT",
  IM: "IMN",
  UG: "UGA",
  TZ: "TZA",
  MY: "MYS",
  MX: "MEX",
  IL: "ISR",
  FR: "FRA",
  IO: "IOT",
  SH: "SHN",
  FI: "FIN",
  FJ: "FJI",
  FK: "FLK",
  FM: "FSM",
  FO: "FRO",
  NI: "NIC",
  NL: "NLD",
  NO: "NOR",
  NA: "NAM",
  VU: "VUT",
  NC: "NCL",
  NE: "NER",
  NF: "NFK",
  NG: "NGA",
  NZ: "NZL",
  NP: "NPL",
  NR: "NRU",
  NU: "NIU",
  CK: "COK",
  XK: "XKX",
  CI: "CIV",
  CH: "CHE",
  CO: "COL",
  CN: "CHN",
  CM: "CMR",
  CL: "CHL",
  CC: "CCK",
  CA: "CAN",
  CG: "COG",
  CF: "CAF",
  CD: "COD",
  CZ: "CZE",
  CY: "CYP",
  CX: "CXR",
  CR: "CRI",
  CW: "CUW",
  CV: "CPV",
  CU: "CUB",
  SZ: "SWZ",
  SY: "SYR",
  SX: "SXM",
  KG: "KGZ",
  KE: "KEN",
  SS: "SSD",
  SR: "SUR",
  KI: "KIR",
  KH: "KHM",
  KN: "KNA",
  KM: "COM",
  ST: "STP",
  SK: "SVK",
  KR: "KOR",
  SI: "SVN",
  KP: "PRK",
  KW: "KWT",
  SN: "SEN",
  SM: "SMR",
  SL: "SLE",
  SC: "SYC",
  KZ: "KAZ",
  KY: "CYM",
  SG: "SGP",
  SE: "SWE",
  SD: "SDN",
  DO: "DOM",
  DM: "DMA",
  DJ: "DJI",
  DK: "DNK",
  VG: "VGB",
  DE: "DEU",
  YE: "YEM",
  DZ: "DZA",
  US: "USA",
  UY: "URY",
  YT: "MYT",
  UM: "UMI",
  LB: "LBN",
  LC: "LCA",
  LA: "LAO",
  TV: "TUV",
  TW: "TWN",
  TT: "TTO",
  TR: "TUR",
  LK: "LKA",
  LI: "LIE",
  LV: "LVA",
  TO: "TON",
  LT: "LTU",
  LU: "LUX",
  LR: "LBR",
  LS: "LSO",
  TH: "THA",
  TF: "ATF",
  TG: "TGO",
  TD: "TCD",
  TC: "TCA",
  LY: "LBY",
  VA: "VAT",
  VC: "VCT",
  AE: "ARE",
  AD: "AND",
  AG: "ATG",
  AF: "AFG",
  AI: "AIA",
  VI: "VIR",
  IS: "ISL",
  IR: "IRN",
  AM: "ARM",
  AL: "ALB",
  AO: "AGO",
  AQ: "ATA",
  AS: "ASM",
  AR: "ARG",
  AU: "AUS",
  AT: "AUT",
  AW: "ABW",
  IN: "IND",
  AX: "ALA",
  AZ: "AZE",
  IE: "IRL",
  ID: "IDN",
  UA: "UKR",
  QA: "QAT",
  MZ: "MOZ",
};

export const ISO3TO2 = {
  BGD: "BD",
  BEL: "BE",
  BFA: "BF",
  BGR: "BG",
  BIH: "BA",
  BRB: "BB",
  WLF: "WF",
  BLM: "BL",
  BMU: "BM",
  BRN: "BN",
  BOL: "BO",
  BHR: "BH",
  BDI: "BI",
  BEN: "BJ",
  BTN: "BT",
  JAM: "JM",
  BVT: "BV",
  BWA: "BW",
  WSM: "WS",
  BES: "BQ",
  BRA: "BR",
  BHS: "BS",
  JEY: "JE",
  BLR: "BY",
  BLZ: "BZ",
  RUS: "RU",
  RWA: "RW",
  SRB: "RS",
  TLS: "TL",
  REU: "RE",
  TKM: "TM",
  TJK: "TJ",
  ROU: "RO",
  TKL: "TK",
  GNB: "GW",
  GUM: "GU",
  GTM: "GT",
  SGS: "GS",
  GRC: "GR",
  GNQ: "GQ",
  GLP: "GP",
  JPN: "JP",
  GUY: "GY",
  GGY: "GG",
  GUF: "GF",
  GEO: "GE",
  GRD: "GD",
  GBR: "GB",
  GAB: "GA",
  SLV: "SV",
  GIN: "GN",
  GMB: "GM",
  GRL: "GL",
  GIB: "GI",
  GHA: "GH",
  OMN: "OM",
  TUN: "TN",
  JOR: "JO",
  HRV: "HR",
  HTI: "HT",
  HUN: "HU",
  HKG: "HK",
  HND: "HN",
  HMD: "HM",
  VEN: "VE",
  PRI: "PR",
  PSE: "PS",
  PLW: "PW",
  PRT: "PT",
  SJM: "SJ",
  PRY: "PY",
  IRQ: "IQ",
  PAN: "PA",
  PYF: "PF",
  PNG: "PG",
  PER: "PE",
  PAK: "PK",
  PHL: "PH",
  PCN: "PN",
  POL: "PL",
  SPM: "PM",
  ZMB: "ZM",
  ESH: "EH",
  EST: "EE",
  EGY: "EG",
  ZAF: "ZA",
  ECU: "EC",
  ITA: "IT",
  VNM: "VN",
  SLB: "SB",
  ETH: "ET",
  SOM: "SO",
  ZWE: "ZW",
  SAU: "SA",
  ESP: "ES",
  ERI: "ER",
  MNE: "ME",
  MDA: "MD",
  MDG: "MG",
  MAF: "MF",
  MAR: "MA",
  MCO: "MC",
  UZB: "UZ",
  MMR: "MM",
  MLI: "ML",
  MAC: "MO",
  MNG: "MN",
  MHL: "MH",
  MKD: "MK",
  MUS: "MU",
  MLT: "MT",
  MWI: "MW",
  MDV: "MV",
  MTQ: "MQ",
  MNP: "MP",
  MSR: "MS",
  MRT: "MR",
  IMN: "IM",
  UGA: "UG",
  TZA: "TZ",
  MYS: "MY",
  MEX: "MX",
  ISR: "IL",
  FRA: "FR",
  IOT: "IO",
  SHN: "SH",
  FIN: "FI",
  FJI: "FJ",
  FLK: "FK",
  FSM: "FM",
  FRO: "FO",
  NIC: "NI",
  NLD: "NL",
  NOR: "NO",
  NAM: "NA",
  VUT: "VU",
  NCL: "NC",
  NER: "NE",
  NFK: "NF",
  NGA: "NG",
  NZL: "NZ",
  NPL: "NP",
  NRU: "NR",
  NIU: "NU",
  COK: "CK",
  XKX: "XK",
  CIV: "CI",
  CHE: "CH",
  COL: "CO",
  CHN: "CN",
  CMR: "CM",
  CHL: "CL",
  CCK: "CC",
  CAN: "CA",
  COG: "CG",
  CAF: "CF",
  COD: "CD",
  CZE: "CZ",
  CYP: "CY",
  CXR: "CX",
  CRI: "CR",
  CUW: "CW",
  CPV: "CV",
  CUB: "CU",
  SWZ: "SZ",
  SYR: "SY",
  SXM: "SX",
  KGZ: "KG",
  KEN: "KE",
  SSD: "SS",
  SUR: "SR",
  KIR: "KI",
  KHM: "KH",
  KNA: "KN",
  COM: "KM",
  STP: "ST",
  SVK: "SK",
  KOR: "KR",
  SVN: "SI",
  PRK: "KP",
  KWT: "KW",
  SEN: "SN",
  SMR: "SM",
  SLE: "SL",
  SYC: "SC",
  KAZ: "KZ",
  CYM: "KY",
  SGP: "SG",
  SWE: "SE",
  SDN: "SD",
  DOM: "DO",
  DMA: "DM",
  DJI: "DJ",
  DNK: "DK",
  VGB: "VG",
  DEU: "DE",
  YEM: "YE",
  DZA: "DZ",
  USA: "US",
  URY: "UY",
  MYT: "YT",
  UMI: "UM",
  LBN: "LB",
  LCA: "LC",
  LAO: "LA",
  TUV: "TV",
  TWN: "TW",
  TTO: "TT",
  TUR: "TR",
  LKA: "LK",
  LIE: "LI",
  LVA: "LV",
  TON: "TO",
  LTU: "LT",
  LUX: "LU",
  LBR: "LR",
  LSO: "LS",
  THA: "TH",
  ATF: "TF",
  TGO: "TG",
  TCD: "TD",
  TCA: "TC",
  LBY: "LY",
  VAT: "VA",
  VCT: "VC",
  ARE: "AE",
  AND: "AD",
  ATG: "AG",
  AFG: "AF",
  AIA: "AI",
  VIR: "VI",
  ISL: "IS",
  IRN: "IR",
  ARM: "AM",
  ALB: "AL",
  AGO: "AO",
  ATA: "AQ",
  ASM: "AS",
  ARG: "AR",
  AUS: "AU",
  AUT: "AT",
  ABW: "AW",
  IND: "IN",
  ALA: "AX",
  AZE: "AZ",
  IRL: "IE",
  IDN: "ID",
  UKR: "UA",
  QAT: "QA",
  MOZ: "MZ",
};

const COUNTRY_OPTIONS = [
  { text: "Afghanistan", key: 0, value: "AFG", flag: "af" },
  { text: "Albania", key: 1, value: "ALB", flag: "al" },
  { text: "Algeria", key: 2, value: "DZA", flag: "dz" },
  { text: "American Samoa", key: 3, value: "ASM", flag: "as" },
  { text: "Andorra", key: 4, value: "AND", flag: "ad" },
  { text: "Angola", key: 5, value: "AGO", flag: "ao" },
  { text: "Anguilla", key: 6, value: "AIA", flag: "ai" },
  // {text:"Antarctica",key:7,value:"ATA",flag:"aq"},
  { text: "Antigua and Barbuda", key: 8, value: "ATG", flag: "ag" },
  { text: "Argentina", key: 9, value: "ARG", flag: "ar" },
  { text: "Armenia", key: 10, value: "ARM", flag: "am" },
  { text: "Aruba", key: 11, value: "ABW", flag: "aw" },
  { text: "Australia", key: 12, value: "AUS", flag: "au" },
  { text: "Austria", key: 13, value: "AUT", flag: "at" },
  { text: "Azerbaijan", key: 14, value: "AZE", flag: "az" },
  { text: "Bahamas", key: 15, value: "BHS", flag: "bs" },
  { text: "Bahrain", key: 16, value: "BHR", flag: "bh" },
  { text: "Bangladesh", key: 17, value: "BGD", flag: "bd" },
  { text: "Barbados", key: 18, value: "BRB", flag: "bb" },
  { text: "Belarus", key: 19, value: "BLR", flag: "by" },
  { text: "Belgium", key: 20, value: "BEL", flag: "be" },
  { text: "Belize", key: 21, value: "BLZ", flag: "bz" },
  { text: "Benin", key: 22, value: "BEN", flag: "bj" },
  { text: "Bermuda", key: 23, value: "BMU", flag: "bm" },
  { text: "Bhutan", key: 24, value: "BTN", flag: "bt" },
  {
    text: "Bolivia, Plurinational State of",
    key: 25,
    value: "BOL",
    flag: "bo",
  },
  // {text:"Bonaire, Sint Eustatius and Saba",key:26,value:"BES",flag:"bq"},
  { text: "Bosnia and Herzegovina", key: 27, value: "BIH", flag: "ba" },
  { text: "Botswana", key: 28, value: "BWA", flag: "bw" },
  { text: "Bouvet Island", key: 29, value: "BVT", flag: "bv" },
  { text: "Brazil", key: 30, value: "BRA", flag: "br" },
  // {text:"British Indian Ocean Territory",key:31,value:"IOT",flag:"io"},
  { text: "Brunei Darussalam", key: 32, value: "BRN", flag: "bn" },
  { text: "Bulgaria", key: 33, value: "BGR", flag: "bg" },
  { text: "Burkina Faso", key: 34, value: "BFA", flag: "bf" },
  { text: "Burundi", key: 35, value: "BDI", flag: "bi" },
  { text: "Cambodia", key: 36, value: "KHM", flag: "kh" },
  { text: "Cameroon", key: 37, value: "CMR", flag: "cm" },
  { text: "Canada", key: 38, value: "CAN", flag: "ca" },
  { text: "Cape Verde", key: 39, value: "CPV", flag: "cv" },
  { text: "Cayman Islands", key: 40, value: "CYM", flag: "ky" },
  { text: "Central African Republic", key: 41, value: "CAF", flag: "cf" },
  { text: "Chad", key: 42, value: "TCD", flag: "td" },
  { text: "Chile", key: 43, value: "CHL", flag: "cl" },
  { text: "China", key: 44, value: "CHN", flag: "cn" },
  { text: "Christmas Island", key: 45, value: "CXR", flag: "cx" },
  { text: "Cocos (Keeling) Islands", key: 46, value: "CCK", flag: "cc" },
  { text: "Colombia", key: 47, value: "COL", flag: "co" },
  { text: "Comoros", key: 48, value: "COM", flag: "km" },
  { text: "Congo", key: 49, value: "COG", flag: "cg" },
  {
    text: "Congo, the Democratic Republic of the",
    key: 50,
    value: "COD",
    flag: "cd",
  },
  { text: "Cook Islands", key: 51, value: "COK", flag: "ck" },
  { text: "Costa Rica", key: 52, value: "CRI", flag: "cr" },
  { text: "Croatia", key: 53, value: "HRV", flag: "hr" },
  { text: "Cuba", key: 54, value: "CUB", flag: "cu" },
  // {text:"Curaçao",key:55,value:"CUW",flag:"cw"},
  { text: "Cyprus", key: 56, value: "CYP", flag: "cy" },
  { text: "Czech Republic", key: 57, value: "CZE", flag: "cz" },
  { text: "Côte d'Ivoire", key: 58, value: "CIV", flag: "ci" },
  { text: "Denmark", key: 59, value: "DNK", flag: "dk" },
  { text: "Djibouti", key: 60, value: "DJI", flag: "dj" },
  { text: "Dominica", key: 61, value: "DMA", flag: "dm" },
  { text: "Dominican Republic", key: 62, value: "DOM", flag: "do" },
  { text: "Ecuador", key: 63, value: "ECU", flag: "ec" },
  { text: "Egypt", key: 64, value: "EGY", flag: "eg" },
  { text: "El Salvador", key: 65, value: "SLV", flag: "sv" },
  { text: "Equatorial Guinea", key: 66, value: "GNQ", flag: "gq" },
  { text: "Eritrea", key: 67, value: "ERI", flag: "er" },
  { text: "Estonia", key: 68, value: "EST", flag: "ee" },
  { text: "Ethiopia", key: 69, value: "ETH", flag: "et" },
  { text: "Falkland Islands (Malvinas)", key: 70, value: "FLK", flag: "fk" },
  { text: "Faroe Islands", key: 71, value: "FRO", flag: "fo" },
  { text: "Fiji", key: 72, value: "FJI", flag: "fj" },
  { text: "Finland", key: 73, value: "FIN", flag: "fi" },
  { text: "France", key: 74, value: "FRA", flag: "fr" },
  { text: "French Guiana", key: 75, value: "GUF", flag: "gf" },
  { text: "French Polynesia", key: 76, value: "PYF", flag: "pf" },
  { text: "French Southern Territories", key: 77, value: "ATF", flag: "tf" },
  { text: "Gabon", key: 78, value: "GAB", flag: "ga" },
  { text: "Gambia", key: 79, value: "GMB", flag: "gm" },
  { text: "Georgia", key: 80, value: "GEO", flag: "ge" },
  { text: "Germany", key: 81, value: "DEU", flag: "de" },
  { text: "Ghana", key: 82, value: "GHA", flag: "gh" },
  { text: "Gibraltar", key: 83, value: "GIB", flag: "gi" },
  { text: "Greece", key: 84, value: "GRC", flag: "gr" },
  { text: "Greenland", key: 85, value: "GRL", flag: "gl" },
  { text: "Grenada", key: 86, value: "GRD", flag: "gd" },
  { text: "Guadeloupe", key: 87, value: "GLP", flag: "gp" },
  { text: "Guam", key: 88, value: "GUM", flag: "gu" },
  { text: "Guatemala", key: 89, value: "GTM", flag: "gt" },
  // {text:"Guernsey",key:90,value:"GGY",flag:"gg"},
  { text: "Guinea", key: 91, value: "GIN", flag: "gn" },
  { text: "Guinea-Bissau", key: 92, value: "GNB", flag: "gw" },
  { text: "Guyana", key: 93, value: "GUY", flag: "gy" },
  { text: "Haiti", key: 94, value: "HTI", flag: "ht" },
  {
    text: "Heard Island and McDonald Islands",
    key: 95,
    value: "HMD",
    flag: "hm",
  },
  { text: "Holy See (Vatican City State)", key: 96, value: "VAT", flag: "va" },
  { text: "Honduras", key: 97, value: "HND", flag: "hn" },
  { text: "Hong Kong", key: 98, value: "HKG", flag: "hk" },
  { text: "Hungary", key: 99, value: "HUN", flag: "hu" },
  { text: "Iceland", key: 100, value: "ISL", flag: "is" },
  { text: "India", key: 101, value: "IND", flag: "in" },
  { text: "Indonesia", key: 102, value: "IDN", flag: "id" },
  { text: "Iran, Islamic Republic of", key: 103, value: "IRN", flag: "ir" },
  { text: "Iraq", key: 104, value: "IRQ", flag: "iq" },
  { text: "Ireland", key: 105, value: "IRL", flag: "ie" },
  // {text:"Isle of Man",key:106,value:"IMN",flag:"im"},
  { text: "Israel", key: 107, value: "ISR", flag: "il" },
  { text: "Italy", key: 108, value: "ITA", flag: "it" },
  { text: "Jamaica", key: 109, value: "JAM", flag: "jm" },
  { text: "Japan", key: 110, value: "JPN", flag: "jp" },
  // {text:"Jersey",key:111,value:"JEY",flag:"je"},
  { text: "Jordan", key: 112, value: "JOR", flag: "jo" },
  { text: "Kazakhstan", key: 113, value: "KAZ", flag: "kz" },
  { text: "Kenya", key: 114, value: "KEN", flag: "ke" },
  { text: "Kiribati", key: 115, value: "KIR", flag: "ki" },
  {
    text: "Korea, Democratic People's Republic of",
    key: 116,
    value: "PRK",
    flag: "kp",
  },
  { text: "Korea, Republic of", key: 117, value: "KOR", flag: "kr" },
  { text: "Kuwait", key: 118, value: "KWT", flag: "kw" },
  { text: "Kyrgyzstan", key: 119, value: "KGZ", flag: "kg" },
  {
    text: "Lao People's Democratic Republic",
    key: 120,
    value: "LAO",
    flag: "la",
  },
  { text: "Latvia", key: 121, value: "LVA", flag: "lv" },
  { text: "Lebanon", key: 122, value: "LBN", flag: "lb" },
  { text: "Lesotho", key: 123, value: "LSO", flag: "ls" },
  { text: "Liberia", key: 124, value: "LBR", flag: "lr" },
  { text: "Libya", key: 125, value: "LBY", flag: "ly" },
  { text: "Liechtenstein", key: 126, value: "LIE", flag: "li" },
  { text: "Lithuania", key: 127, value: "LTU", flag: "lt" },
  { text: "Luxembourg", key: 128, value: "LUX", flag: "lu" },
  { text: "Macao", key: 129, value: "MAC", flag: "mo" },
  {
    text: "North Macedonia",
    key: 130,
    value: "MKD",
    flag: "mk",
  },
  { text: "Madagascar", key: 131, value: "MDG", flag: "mg" },
  { text: "Malawi", key: 132, value: "MWI", flag: "mw" },
  { text: "Malaysia", key: 133, value: "MYS", flag: "my" },
  { text: "Maldives", key: 134, value: "MDV", flag: "mv" },
  { text: "Mali", key: 135, value: "MLI", flag: "ml" },
  { text: "Malta", key: 136, value: "MLT", flag: "mt" },
  { text: "Marshall Islands", key: 137, value: "MHL", flag: "mh" },
  { text: "Martinique", key: 138, value: "MTQ", flag: "mq" },
  { text: "Mauritania", key: 139, value: "MRT", flag: "mr" },
  { text: "Mauritius", key: 140, value: "MUS", flag: "mu" },
  { text: "Mayotte", key: 141, value: "MYT", flag: "yt" },
  { text: "Mexico", key: 142, value: "MEX", flag: "mx" },
  {
    text: "Micronesia, Federated States of",
    key: 143,
    value: "FSM",
    flag: "fm",
  },
  { text: "Moldova, Republic of", key: 144, value: "MDA", flag: "md" },
  { text: "Monaco", key: 145, value: "MCO", flag: "mc" },
  { text: "Mongolia", key: 146, value: "MNG", flag: "mn" },
  { text: "Montenegro", key: 147, value: "MNE", flag: "me" },
  { text: "Montserrat", key: 148, value: "MSR", flag: "ms" },
  { text: "Morocco", key: 149, value: "MAR", flag: "ma" },
  { text: "Mozambique", key: 150, value: "MOZ", flag: "mz" },
  // {text:"Myanmar",key:151,value:"MMR",flag:"mm"},
  { text: "Namibia", key: 152, value: "NAM", flag: "na" },
  { text: "Nauru", key: 153, value: "NRU", flag: "nr" },
  { text: "Nepal", key: 154, value: "NPL", flag: "np" },
  { text: "Netherlands", key: 155, value: "NLD", flag: "nl" },
  { text: "New Caledonia", key: 156, value: "NCL", flag: "nc" },
  { text: "New Zealand", key: 157, value: "NZL", flag: "nz" },
  { text: "Nicaragua", key: 158, value: "NIC", flag: "ni" },
  { text: "Niger", key: 159, value: "NER", flag: "ne" },
  { text: "Nigeria", key: 160, value: "NGA", flag: "ng" },
  { text: "Niue", key: 161, value: "NIU", flag: "nu" },
  { text: "Norfolk Island", key: 162, value: "NFK", flag: "nf" },
  { text: "Northern Mariana Islands", key: 163, value: "MNP", flag: "mp" },
  { text: "Norway", key: 164, value: "NOR", flag: "no" },
  { text: "Oman", key: 165, value: "OMN", flag: "om" },
  { text: "Pakistan", key: 166, value: "PAK", flag: "pk" },
  { text: "Palau", key: 167, value: "PLW", flag: "pw" },
  { text: "Palestine, State of", key: 168, value: "PSE", flag: "ps" },
  { text: "Panama", key: 169, value: "PAN", flag: "pa" },
  { text: "Papua New Guinea", key: 170, value: "PNG", flag: "pg" },
  { text: "Paraguay", key: 171, value: "PRY", flag: "py" },
  { text: "Peru", key: 172, value: "PER", flag: "pe" },
  { text: "Philippines", key: 173, value: "PHL", flag: "ph" },
  { text: "Pitcairn", key: 174, value: "PCN", flag: "pn" },
  { text: "Poland", key: 175, value: "POL", flag: "pl" },
  { text: "Portugal", key: 176, value: "PRT", flag: "pt" },
  { text: "Puerto Rico", key: 177, value: "PRI", flag: "pr" },
  { text: "Qatar", key: 178, value: "QAT", flag: "qa" },
  { text: "Romania", key: 179, value: "ROU", flag: "ro" },
  { text: "Russian Federation", key: 180, value: "RUS", flag: "ru" },
  { text: "Rwanda", key: 181, value: "RWA", flag: "rw" },
  { text: "Réunion", key: 182, value: "REU", flag: "re" },
  // {text:"Saint Barthélemy",key:183,value:"BLM",flag:"bl"},
  {
    text: "Saint Helena, Ascension and Tristan da Cunha",
    key: 184,
    value: "SHN",
    flag: "sh",
  },
  { text: "Saint Kitts and Nevis", key: 185, value: "KNA", flag: "kn" },
  { text: "Saint Lucia", key: 186, value: "LCA", flag: "lc" },
  // {text:"Saint Martin (French part)",key:187,value:"MAF",flag:"mf"},
  { text: "Saint Pierre and Miquelon", key: 188, value: "SPM", flag: "pm" },
  {
    text: "Saint Vincent and the Grenadines",
    key: 189,
    value: "VCT",
    flag: "vc",
  },
  { text: "Samoa", key: 190, value: "WSM", flag: "ws" },
  { text: "San Marino", key: 191, value: "SMR", flag: "sm" },
  { text: "Sao Tome and Principe", key: 192, value: "STP", flag: "st" },
  { text: "Saudi Arabia", key: 193, value: "SAU", flag: "sa" },
  { text: "Senegal", key: 194, value: "SEN", flag: "sn" },
  { text: "Serbia", key: 195, value: "SRB", flag: "rs" },
  { text: "Seychelles", key: 196, value: "SYC", flag: "sc" },
  { text: "Sierra Leone", key: 197, value: "SLE", flag: "sl" },
  { text: "Singapore", key: 198, value: "SGP", flag: "sg" },
  // {text:"Sint Maarten (Dutch part)",key:199,value:"SXM",flag:"sx"},
  { text: "Slovakia", key: 200, value: "SVK", flag: "sk" },
  { text: "Slovenia", key: 201, value: "SVN", flag: "si" },
  { text: "Solomon Islands", key: 202, value: "SLB", flag: "sb" },
  { text: "Somalia", key: 203, value: "SOM", flag: "so" },
  { text: "South Africa", key: 204, value: "ZAF", flag: "za" },
  {
    text: "South Georgia and the South Sandwich Islands",
    key: 205,
    value: "SGS",
    flag: "gs",
  },
  // {text:"South Sudan",key:206,value:"SSD",flag:"ss"},
  { text: "Spain", key: 207, value: "ESP", flag: "es" },
  { text: "Sri Lanka", key: 208, value: "LKA", flag: "lk" },
  { text: "Sudan", key: 209, value: "SDN", flag: "sd" },
  { text: "Suriname", key: 210, value: "SUR", flag: "sr" },
  { text: "Svalbard and Jan Mayen", key: 211, value: "SJM", flag: "sj" },
  { text: "Swaziland", key: 212, value: "SWZ", flag: "sz" },
  { text: "Sweden", key: 213, value: "SWE", flag: "se" },
  { text: "Switzerland", key: 214, value: "CHE", flag: "ch" },
  { text: "Syrian Arab Republic", key: 215, value: "SYR", flag: "sy" },
  { text: "Taiwan, Province of China", key: 216, value: "TWN", flag: "tw" },
  { text: "Tajikistan", key: 217, value: "TJK", flag: "tj" },
  { text: "Tanzania, United Republic of", key: 218, value: "TZA", flag: "tz" },
  { text: "Thailand", key: 219, value: "THA", flag: "th" },
  { text: "Timor-Leste", key: 220, value: "TLS", flag: "tl" },
  { text: "Togo", key: 221, value: "TGO", flag: "tg" },
  { text: "Tokelau", key: 222, value: "TKL", flag: "tk" },
  { text: "Tonga", key: 223, value: "TON", flag: "to" },
  { text: "Trinidad and Tobago", key: 224, value: "TTO", flag: "tt" },
  { text: "Tunisia", key: 225, value: "TUN", flag: "tn" },
  { text: "Turkey", key: 226, value: "TUR", flag: "tr" },
  { text: "Turkmenistan", key: 227, value: "TKM", flag: "tm" },
  { text: "Turks and Caicos Islands", key: 228, value: "TCA", flag: "tc" },
  { text: "Tuvalu", key: 229, value: "TUV", flag: "tv" },
  { text: "Uganda", key: 230, value: "UGA", flag: "ug" },
  { text: "Ukraine", key: 231, value: "UKR", flag: "ua" },
  { text: "United Arab Emirates", key: 232, value: "ARE", flag: "ae" },
  { text: "United Kingdom", key: 233, value: "GBR", flag: "gb" },
  { text: "United States", key: 234, value: "USA", flag: "us" },
  {
    text: "United States Minor Outlying Islands",
    key: 235,
    value: "UMI",
    flag: "um",
  },
  { text: "Uruguay", key: 236, value: "URY", flag: "uy" },
  { text: "Uzbekistan", key: 237, value: "UZB", flag: "uz" },
  { text: "Vanuatu", key: 238, value: "VUT", flag: "vu" },
  {
    text: "Venezuela, Bolivarian Republic of",
    key: 239,
    value: "VEN",
    flag: "ve",
  },
  { text: "Viet Nam", key: 240, value: "VNM", flag: "vn" },
  { text: "Virgin Islands, British", key: 241, value: "VGB", flag: "vg" },
  { text: "Virgin Islands, U.S.", key: 242, value: "VIR", flag: "vi" },
  { text: "Wallis and Futuna", key: 243, value: "WLF", flag: "wf" },
  { text: "Western Sahara", key: 244, value: "ESH", flag: "eh" },
  { text: "Yemen", key: 245, value: "YEM", flag: "ye" },
  { text: "Zambia", key: 246, value: "ZMB", flag: "zm" },
  { text: "Zimbabwe", key: 247, value: "ZWE", flag: "zw" },
  { text: "Åland Islands", key: 248, value: "ALA", flag: "ax" },
];

export default { ISO2TO3, COUNTRY_OPTIONS };

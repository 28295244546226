import React from "react";
import { Menu } from "semantic-ui-react";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import CONFIG from "../../../Config";
import {
  ExportAuditLogModal as _ExportAuditLogModal,
  ExportContactsModal as _ExportContactsModal,
  ExportTargetsModal as _ExportTargetsModal,
  ImportModal,
} from "./Modals";

import "./DataTabSideButtons.scoped.scss";

const ExportContactsModal = withRoleCheck(_ExportContactsModal, [
  ACL_RELATIONSHIPS.exportContacts.create,
]);
const ExportTargetsModal = withRoleCheck(_ExportTargetsModal, [
  ACL_RELATIONSHIPS.exportTargets.create,
]);
const ExportAuditLogModal = withRoleCheck(_ExportAuditLogModal, [
  ACL_RELATIONSHIPS.exportLogs.create,
]);

const ImportModalTrigger = withRoleCheck(ImportModal.Trigger, [
  ACL_RELATIONSHIPS.dataJob.read,
  ACL_RELATIONSHIPS.dataJob.create,
  ACL_RELATIONSHIPS.importData.edit,
]);

export default function DataTabSideButtons({
  fetchData,
  openImportModal,
  openCaseDataImportModal,
}) {
  return (
    <Menu className="data-tab-buttons" vertical size="massive" borderless>
      <Menu.Item>
        <Menu.Header>Imports</Menu.Header>

        <Menu.Menu>
          <Menu.Item>
            <ImportModalTrigger
              content="Import Data"
              onClick={() => openImportModal()}
            />
          </Menu.Item>
          {CONFIG.ENABLE_PIRACY && (
            <Menu.Item>
              <ImportModalTrigger
                content="Import Case Data"
                onClick={() => openCaseDataImportModal()}
              />
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu.Item>

      <Menu.Item>
        <Menu.Header>Exports</Menu.Header>

        <Menu.Menu>
          <Menu.Item>
            <ExportContactsModal fetchData={fetchData} />
          </Menu.Item>

          <Menu.Item>
            <ExportTargetsModal fetchData={fetchData} />
          </Menu.Item>

          <Menu.Item>
            <ExportAuditLogModal fetchData={fetchData} />
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
    </Menu>
  );
}

import { doRequest } from "./helpers";

export default class TextMessageService {
  static async postTextMessage(data) {
    const path = "/text_message/";
    return doRequest("POST", path, { data });
  }

  static async deleteTextMessage(id) {
    const path = `/text_message/${id}`;
    return doRequest("DELETE", path);
  }

  static async getTextMessage(id) {
    let path = `/text_message/${id}`;
    return doRequest("GET", path);
  }
}

import React, { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import TagDropdown from "../../../components/TagDropdown";
import { DIALER_STATES } from "../../../constants/Dialer";
import { dialSessionNameFormatted } from "../../../helpers/sessionHelpers";
import "./DialerHeader.scoped.scss";
import { SessionContacts } from "./SessionContacts";

function pad(num, size) {
  var s = "0000000" + num;
  return s.substr(s.length - size);
}

function secondsToHms(seconds) {
  let h = Math.floor((seconds % (3600 * 24)) / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let s = Math.floor(seconds % 60);

  var hDisplay = h > 0 ? h + ":" : "";
  return hDisplay + pad(m, 2) + ":" + pad(s, 2);
}

function DialerHeader({
  call,
  callTags,
  onTagChange,
  deviceStatus,
  dialerInfo,
  counters,
  onPauseSession,
  paused,
  onEndSession,
  monitorMode,
  privateSession,
  toggleHold,
  dialSessionSummary,
  dialSessionId,
  autoDial,
  nextCallCountdown,
}) {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) setDuration(duration => duration + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [onPauseSession]);

  async function handlePauseContinue() {
    if (deviceStatus === "connected") {
      await toggleHold();
    } else {
      onPauseSession && onPauseSession(!paused);
    }
  }

  const buttonsDisabled =
    (!autoDial &&
      !(
        dialerInfo.contact &&
        dialerInfo.contact.id &&
        ["calling", "connected", "incoming"].includes(deviceStatus)
      )) ||
    monitorMode;

  let pauseButtonText;
  if (paused && deviceStatus !== DIALER_STATES.CONNECTED) {
    pauseButtonText = "Continue Dialing";
  } else if (!paused && deviceStatus === DIALER_STATES.CONNECTED) {
    pauseButtonText = "Hold";
  } else if (deviceStatus !== DIALER_STATES.CONNECTED) {
    pauseButtonText = "Pause Dialing";
  } else {
    pauseButtonText = "On Hold";
  }

  return (
    <div className="dialer-header">
      {!privateSession && (
        <div className="dialer-info">
          <p>
            {dialerInfo && dialerInfo.dial_session
              ? dialSessionNameFormatted(dialerInfo.dial_session.name)
              : ""}
          </p>
          <p>
            {dialerInfo && dialerInfo.dial_session
              ? dialerInfo.dial_session.campaign_name
              : ""}
          </p>
          <SessionContacts
            dialSessionId={dialSessionId}
            disabled={monitorMode}
            totalContacts={
              dialSessionSummary ? dialSessionSummary.total_contacts : 0
            }
            callableContacts={
              dialSessionSummary ? dialSessionSummary.callable_contacts : 0
            }
            urgentContacts={
              dialSessionSummary ? dialSessionSummary.urgent_contacts : 0
            }
            urgentCallableContacts={
              dialSessionSummary
                ? dialSessionSummary.urgent_callable_contacts
                : 0
            }
          />
          {monitorMode && (
            <Button
              className="blue right"
              size="tiny"
              content="Monitoring"
              onClick={() => {}}
              style={{ marginTop: "-1em", right: "1em", position: "absolute" }}
            />
          )}
        </div>
      )}
      <div className="control-details">
        <div className="dialer-controls">
          <Button.Group className="divided">
            <Button
              className="white"
              icon
              onClick={handlePauseContinue}
              disabled={buttonsDisabled}
            >
              <Icon name={paused ? "play" : "pause"} color="blue" />
              {pauseButtonText}
            </Button>
            <Button
              className="white"
              icon
              onClick={onEndSession}
              disabled={monitorMode}
            >
              <Icon name="stop" color="red" />
              End Dialing
            </Button>
          </Button.Group>
          {nextCallCountdown ? nextCallCountdown : null}
        </div>
        <div className="dialer-details">
          <div className="dialer-detail">
            <span>Duration</span>
            <p>{secondsToHms(duration)}</p>
          </div>
          {!privateSession && (
            <>
              <div className={`dialer-detail ${monitorMode && "disabled"}`}>
                <span>Contacts Called</span>
                <p>{counters.contactsCalled.confirmed}</p>
              </div>
              <div className={`dialer-detail ${monitorMode && "disabled"}`}>
                <span>Live Calls</span>
                <p>{counters.liveCalls.confirmed}</p>
              </div>
              <div className={`dialer-detail ${monitorMode && "disabled"}`}>
                <span>Callable Remaining</span>
                <p>
                  {dialSessionSummary
                    ? dialSessionSummary.callable_contacts
                    : 0}
                </p>
              </div>
              <div className={`dialer-detail ${monitorMode && "disabled"}`}>
                <span>Voicemails</span>
                <p>{counters.voiceMails.confirmed}</p>
              </div>
              <div className={`dialer-detail ${monitorMode && "disabled"}`}>
                <span>Urgent Callable Remaining</span>
                <p>
                  {dialSessionSummary
                    ? dialSessionSummary.urgent_callable_contacts
                    : 0}
                </p>
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              maxHeight: "3em",
              paddingRight: "0.5em",
            }}
          >
            <TagDropdown
              className="white"
              tags={callTags}
              modelType="CampaignTargetCall"
              rows={call ? [call] : []}
              checkedArr={call ? [call.id] : []}
              onSuccess={onTagChange}
              direction="left"
              disabled={!callTags.length || !call}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialerHeader;

import React, { useEffect, useMemo, useState } from "react";
import { Divider, Form } from "semantic-ui-react";

import AlternateNameView from "components/AlternateName";
import EmailAddresses from "components/EmailsAddresses";
import CustomFieldForm from "components/forms/CustomFieldForm";
import {
  checkIsCustomFieldsReady,
  getCustomFieldsForSubmit,
} from "components/helpers";
import PhoneNumbers from "components/PhoneNumbers";
import RuvixxForm from "components/RuvixxForm";
import RuvixxSelect from "components/RuvixxSelect";
import CONSTANTS from "constants/Constants";
import CustomFieldService from "services/CustomField";
import DepartmentService from "services/Department";
import EntityService from "services/Entity";

const blankEmail = {
  email: "",
  is_primary: false,
  enabled: true,
  subscribed: true,
};

const blankNumber = {
  is_primary: false,
  number: "",
  ext: "",
  type: CONSTANTS.CONTACT_PHONE_TYPES.DEFAULT,
  enabled: true,
  is_callable: true,
};

const AddContactForm = props => {
  const getInitialPhoneNumber = () => {
    if (props.phoneNumber) {
      return [
        {
          is_primary: true,
          number: props.phoneNumber,
          ext: "",
          type: CONSTANTS.CONTACT_PHONE_TYPES.DEFAULT,
          enabled: true,
          is_callable: true,
        },
      ];
    }
    return [{ ...blankNumber }];
  };

  const [state, setState] = useState({
    fullName: "",
    preferredName: "",
    emailAddresses: [{ ...blankEmail }],
    title: "",
    phoneNumbers: getInitialPhoneNumber(),
    entityId: null,
    departmentId: null,
    custom: [],
    customFieldConfigs: [],
    alternateNames: [],
    error: false,
    errorMessage: "",
    errorTitle: "",
  });

  useEffect(() => {
    const fetchCustomFieldConfigs = async () => {
      let customFieldConfigs =
        await CustomFieldService.getCustomFieldConfigsForModel("Contact");
      setState(prevState => ({ ...prevState, customFieldConfigs }));
    };

    fetchCustomFieldConfigs();
  }, [props.model]);

  const handleChange = (_, { name, type, checked, value }) => {
    if (name) {
      setState(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = async e => {
    const {
      fullName,
      preferredName,
      emailAddresses,
      title,
      phoneNumbers,
      entityId,
      departmentId,
      custom,
      alternateNames,
      customFieldConfigs,
    } = state;

    if (props.handleSubmit) {
      try {
        await props.handleSubmit({
          fullName,
          alternateNames,
          preferredName,
          emailAddresses,
          title,
          phoneNumbers,
          custom,
        });
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          error: true,
          errorMessage: error.message,
          errorTitle: "",
        }));
      }
      return;
    }

    const cust = getCustomFieldsForSubmit(customFieldConfigs, custom);
    const nonEmptyEmailAddresses = emailAddresses.filter(email =>
      email.email?.trim()
    );
    const nonEmptyPhoneNumbers = phoneNumbers.filter(pn => pn.number?.trim());
    const contact = await EntityService.addContact(props.entityId || entityId, {
      full_name: fullName,
      preferred_name: preferredName,
      email_addresses: nonEmptyEmailAddresses,
      title: title,
      phone_numbers: nonEmptyPhoneNumbers,
      custom_fields: cust,
      alternate_names: alternateNames,
      department_id: departmentId || null,
      campaign_id: props.campaignId,
    });
    props.onSuccess(contact);
  };

  const addPhoneNumber = (pn, error) => {
    if (error) {
      setState(prevState => ({
        ...prevState,
        error: true,
        errorMessage: error.message,
        errorTitle: error.title,
      }));
      return;
    }
    setState(prevState => ({
      ...prevState,
      phoneNumbers: [...prevState.phoneNumbers, pn],
      error: false,
    }));
  };

  const removePhoneNumber = index => {
    setState(prevState => {
      const phoneNumbers = prevState.phoneNumbers.filter((_, i) => i !== index);
      return { ...prevState, phoneNumbers };
    });
  };

  const updatePhoneNumber = (phoneNumber, index) => {
    setState(prevState => {
      const phoneNumbers = prevState.phoneNumbers.map((pn, i) =>
        i === index ? phoneNumber : pn
      );
      return { ...prevState, phoneNumbers };
    });
  };

  const addEmailAddress = (email, error) => {
    if (error) {
      setState(prevState => ({
        ...prevState,
        error: true,
        errorMessage: error.message,
        errorTitle: error.title,
      }));
      return;
    }
    setState(prevState => ({
      ...prevState,
      emailAddresses: [...prevState.emailAddresses, email],
      error: false,
    }));
  };

  const removeEmailAddress = index => {
    setState(prevState => {
      const emailAddresses = prevState.emailAddresses.filter(
        (_, i) => i !== index
      );
      return { ...prevState, emailAddresses };
    });
  };
  const updateEmailAddress = (email, index) => {
    setState(prevState => {
      const emailAddresses = prevState.emailAddresses.map((e, i) =>
        i === index ? email : e
      );
      return { ...prevState, emailAddresses };
    });
  };

  const setAlternateNames = alternateNames => {
    setState(prevState => ({ ...prevState, alternateNames }));
  };

  const setDepartment = departmentId => {
    setState(prevState => ({ ...prevState, departmentId }));
  };

  const handleUpdateCustomFields = customFields => {
    setState(prevState => ({ ...prevState, custom: customFields }));
  };

  const handleUpdateCustomFieldConfigs = customFieldConfigs => {
    setState(prevState => ({ ...prevState, customFieldConfigs }));
    handleUpdateCustomFields(
      customFieldConfigs
        .filter(cfc => cfc.required || cfc.selected)
        .map(cfc => ({ key: cfc.label, value: cfc.value }))
    );
  };

  const {
    fullName,
    entityId,
    emailAddresses,
    phoneNumbers,
    custom,
    customFieldConfigs,
    error,
    errorMessage,
    errorTitle,
    alternateNames,
    departmentId,
  } = state;

  const campaignIds = useMemo(() => {
    return props.campaignId ? [props.campaignId] : null;
  }, [props.campaignId]);

  const isReady =
    !!fullName &&
    (emailAddresses.length > 0 || phoneNumbers.length > 0) &&
    (!!props.append || !!props.entityId || !!entityId) &&
    checkIsCustomFieldsReady(customFieldConfigs);

  return (
    <RuvixxForm
      ready={isReady}
      onSubmit={handleSubmit}
      error={error}
      errorMessage={errorMessage}
      errorTitle={errorTitle}
    >
      <Form.Input
        inline
        name="fullName"
        label="Full Name"
        required
        onChange={handleChange}
      />
      <Form.Input
        inline
        name="preferredName"
        label="Preferred Name"
        onChange={handleChange}
      />
      <Form.Input inline name="title" label="Title" onChange={handleChange} />
      {props.entityId || props.append ? null : (
        <RuvixxSelect
          required={true}
          label="Entity"
          name="entityId"
          value={entityId}
          onChange={handleChange}
          queryFn={EntityService.getEntitiesForFilters}
          campaignIds={campaignIds}
        />
      )}
      <RuvixxSelect
        placeholder="Pick a Department"
        label="Department"
        name="departmentId"
        value={departmentId}
        onChange={handleChange}
        queryFn={DepartmentService.getForFilters}
        createFn={DepartmentService.createDepartment}
        allowAdditions
        clearable
        setValue={value => setDepartment(value.id)}
      />
      <Form.Field>
        <label>Email Addresses</label>
      </Form.Field>
      <EmailAddresses
        emailAddresses={emailAddresses}
        addEmailAddress={addEmailAddress}
        removeEmailAddress={removeEmailAddress}
        updateEmailAddress={updateEmailAddress}
      />
      <Divider hidden />
      <Form.Field>
        <label>Phone Numbers</label>
      </Form.Field>
      <PhoneNumbers
        phoneNumbers={phoneNumbers}
        addPhoneNumber={addPhoneNumber}
        removePhoneNumber={removePhoneNumber}
        updatePhoneNumber={updatePhoneNumber}
      />
      <Divider hidden />
      <AlternateNameView
        alternateNames={alternateNames}
        setAlternateNames={setAlternateNames}
        type={"Contact"}
      />
      {customFieldConfigs && (
        <CustomFieldForm
          modelType="Contact"
          customFields={custom}
          customFieldConfigs={customFieldConfigs}
          onUpdateCustomFieldConfigs={handleUpdateCustomFieldConfigs}
        />
      )}
    </RuvixxForm>
  );
};

export default AddContactForm;

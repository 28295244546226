import React, { createRef } from "react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import {
  Container,
  Segment,
  Checkbox,
  Dropdown,
  Button,
} from "semantic-ui-react";

import BaseTable from "components/BaseTable";
import setPageTitle from "helpers/title";
import AuthService from "services/Auth";
import TwilioPhoneNumberService from "services/TwilioPhoneNumber";
import AssignAgentsModal from "./components/AssignAgentsModal";
import AssignVoicemailModal from "./components/AssignVoicemailModal";
import ACL_RELATIONSHIPS from "acl-relationships";
import withRoleCheck from "components/hocs/withRoleCheck";
import CustomDropdown from "components/CustomDropdown";
import AudioElement from "components/AudioElement";

import "styles/campaign.scss";
import "styles/table.scss";

const AssignAgentsActionButton = withRoleCheck(Button, [
  ACL_RELATIONSHIPS.twilioPhoneNumber.assignAgents,
]);

const AssignAgentsDropdownItem = withRoleCheck(Dropdown.Item, [
  ACL_RELATIONSHIPS.twilioPhoneNumber.assignAgents,
]);

class TwilioPhoneNumbersTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = TwilioPhoneNumberService.getTable;
    this.assignAgentsRef = createRef();
    this.assignVoicemailRef = createRef();

    this.state = {
      ...this.state,
      enableSearch: false,
      header: "Phone Numbers",
      headerIcon: "phone",
      tableName: "twilio_phone_numbers",
      noDataText: "No Phone Numbers found.",
      parentDispositions: [],
    };
  }

  async componentDidMount() {
    setPageTitle("Phone Numbers");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Number",
        accessor: "number",
      },
      {
        Header: "Country Code",
        accessor: "country_code",
      },
      {
        Header: "Agents",
        accessor: "agents",
        Cell: ({ value }) => value.map(agent => agent.name).join(", "),
      },
      {
        Header: "Voicemail",
        sortable: false,
        headerClassName: "non-sortable",
        accessor: "voicemail_attachment.url",
        minWidth: 310,
        Cell: props =>
          props.value && (
            <AudioElement
              id={`audio-el-${props.index}`}
              src={props.value}
              wide
            />
          ),
      },
      {
        Header: "Actions",
        id: "actions",
        width: 70,
        className: "action-menu",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: ({ original: { id, voicemail_id, agents } }) => {
          const agentIds = agents.map(agent => agent.id);
          return (
            <CustomDropdown icon="ellipsis horizontal">
              <Dropdown.Menu direction="left">
                <AssignAgentsDropdownItem
                  className="primary"
                  content="Assign Agents"
                  icon="user"
                  onClick={() => this.handleAssignAgents([id], agentIds)}
                />
                <Dropdown.Item
                  className="primary"
                  content="Assign Voicemail"
                  icon="file audio"
                  onClick={() => this.handleAssignVoicemail(id, voicemail_id)}
                />
              </Dropdown.Menu>
            </CustomDropdown>
          );
        },
      },
    ];
    this.initTableSettings(columns);
  };

  handleAssignAgents(twilioPhoneNumberIds, userIds) {
    this.assignAgentsRef.current.open(twilioPhoneNumberIds, userIds);
  }

  handleAssignVoicemail(twilioPhoneNumberId, voicemailId) {
    this.assignVoicemailRef.current.open(twilioPhoneNumberId, voicemailId);
  }

  actionsDropdown() {
    const twilioPhoneNumberIds = this.state.checkedArr;
    return (
      <AssignAgentsActionButton
        size="mini"
        onClick={() => this.handleAssignAgents(twilioPhoneNumberIds)}
        content="Assign Agents"
        disabled={twilioPhoneNumberIds.length === 0}
      />
    );
  }

  render() {
    return (
      <Container fluid className="route sub-route" {...this.props}>
        <AssignAgentsModal
          ref={this.assignAgentsRef}
          onSuccess={this.fetchData}
        />
        <AssignVoicemailModal
          ref={this.assignVoicemailRef}
          onSuccess={this.fetchData}
        />
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default TwilioPhoneNumbersTable;

import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Button, Form, Grid, Image, Message, Segment } from "semantic-ui-react";
import moment from "moment";
import store from "store";
import CONFIG from "../../Config";
import pleteo_isotype from "../../assets/Icon_Pleteo_White.png";
import { removeSession } from "../../helpers/sessionHelpers";
import Validate from "./../../components/Validate";
import AuthService from "./../../services/Auth";
import UserService from "./../../services/User";
import ChangePasswordForm from "./ChangePasswordForm";
import Input from "./Input";
import ResetPasswordModal from "./ResetPasswordModal";
import { useSocket } from "providers/SocketProvider";

const logoSrc = CONFIG.LOGO_SRC || pleteo_isotype;

const LoginForm = () => {
  const { createSocket } = useSocket();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shouldChangePassword, setShouldChangePassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const submitButton = useRef();
  const location = useLocation();

  const handleLogin = async () => {
    setLoading(true);
    if (!Validate.email(email)) {
      setErrorMessage("Email must be valid");
      setLoading(false);
    } else {
      try {
        await AuthService.login(email, password);
        checkShouldChangePassword();
        removeSession();
        createSocket();
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChangePassword = async () => {
    setLoading(true);
    try {
      await UserService.editUserSettings({
        oldPassword: password,
        newPassword,
      });
      store.remove("shouldChangePassword");
      setRedirectToReferrer(true);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    const submitBtn = submitButton.current.ref.current;
    if (!submitBtn.disabled) {
      submitBtn.click();
    }
  };

  const isPasswordOlderThanSixMonths = lastUpdatedStr => {
    const lastUpdatedDate = moment.utc(lastUpdatedStr);
    const sixMonthsAgo = moment.utc().subtract(6, "months");
    return lastUpdatedDate.isBefore(sixMonthsAgo);
  };

  const checkShouldChangePassword = async () => {
    const userSettings = await UserService.getUserSettings();
    if (
      userSettings.should_change_password ||
      isPasswordOlderThanSixMonths(userSettings.password_last_updated_at)
    ) {
      setShouldChangePassword(true);
      store.set("shouldChangePassword", true);
    } else {
      setRedirectToReferrer(true);
    }
  };

  useEffect(() => {
    if (store.get("shouldChangePassword")) {
      setShouldChangePassword(true);
    }
  });

  const renderLoginForm = () => {
    return (
      <Form>
        <Input
          label="Email"
          type="email"
          name="email"
          value={email}
          onChange={(_, { value }) => setEmail(value)}
          onSubmit={handleSubmit}
          autoFocus
        />
        <Input
          label="Password"
          type="password"
          name="password"
          value={password}
          onChange={(_, { value }) => setPassword(value)}
          onSubmit={handleSubmit}
        />
      </Form>
    );
  };

  const renderChangePasswordForm = () => {
    return (
      <ChangePasswordForm
        password={password}
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        setPassword={setPassword}
        setNewPassword={setNewPassword}
        setConfirmPassword={setConfirmPassword}
        onSubmit={handleSubmit}
      />
    );
  };

  const renderSubmitButton = () => {
    let label = "Sign In";
    let disabled = !email || !password;
    let passwordFormatRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*]).{8,}$/;
    let handler = handleLogin;
    if (shouldChangePassword) {
      label = "Change Password";
      disabled =
        !password ||
        !newPassword ||
        newPassword === password ||
        newPassword !== confirmPassword ||
        !passwordFormatRegex.test(newPassword);
      handler = handleChangePassword;
    }
    return (
      <Segment basic textAlign="center">
        <Button
          ref={submitButton}
          primary
          size="tiny"
          type="submit"
          disabled={disabled || loading}
          loading={loading}
          content={label}
          onClick={handler}
        />
      </Segment>
    );
  };

  const handleResetSuccess = message => {
    setSuccessMessage(message);
  };

  const user = store.get("userAuth");
  const { from } = location.state || {
    from: { pathname: user?.external ? "/leads" : "/campaigns" },
  };
  if (redirectToReferrer === true) {
    return <Navigate to={from} />;
  }
  return (
    <Segment basic>
      <Grid>
        <Grid.Column width="16" textAlign="center">
          <Image src={logoSrc} centered />
        </Grid.Column>
      </Grid>
      {shouldChangePassword ? renderChangePasswordForm() : renderLoginForm()}
      {errorMessage && <Message error content={errorMessage} />}
      {renderSubmitButton()}
      <ResetPasswordModal handleResetSuccess={handleResetSuccess} />
      {successMessage && <Message success content={successMessage} />}
    </Segment>
  );
};

export default LoginForm;

import { applyFilters, doRequest } from "./helpers";

export default class RegionService {
  static async getRegions() {
    return doRequest("GET", "/region/");
  }

  static async getRegionsTable(filters = null) {
    let path = "/region/table";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getRegion(id) {
    return doRequest("GET", `/region/${id}`);
  }

  static async createRegion(name, countries) {
    return doRequest("POST", "/region/", {
      data: {
        name: name,
        countries: countries,
      },
    });
  }

  static async deleteRegion(id) {
    return doRequest("DELETE", `/region/${id}`);
  }

  static async editRegion(id, name, countries) {
    return doRequest("PUT", `/region/${id}`, {
      data: {
        name: name,
        countries: countries,
      },
    });
  }
}

import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Icon, Input } from "semantic-ui-react";
import useQueryParam from "../hooks/params/useQueryParam";
import TagService from "../services/Tag";
import CustomDropdown from "./CustomDropdown";
import ApplyTagModal from "./modals/ApplyTagModal";
import RvxTag from "./RvxTag";
import usePrevious from "../hooks/usePrevious";
import { isEqual } from "lodash";
import { storeError } from "services/helpers";

const TagDropdown = props => {
  const {
    modelType,
    checkedArr,
    onSuccess,
    direction,
    accessor,
    queryParams,
    className,
    isWithinModal,
  } = props;
  const [filter, setFilter] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState({});
  const [tagIds, setTagIds] = useQueryParam("tags", undefined, true);
  const applyTagModal = useRef();
  const prevRows = usePrevious(props.rows);

  useEffect(() => {
    let selectedTagIds = {};
    if (queryParams && tagIds !== undefined) {
      tagIds.split(",").forEach(tagId => {
        if (tagId) {
          selectedTagIds[Math.abs(tagId)] =
            parseInt(tagId) > 0 ? "check" : "ban";
        }
      });
    }
    setSelectedTagIds(selectedTagIds);
  }, [queryParams, tagIds]);

  const getTags = async () => {
    return props.tags
      ? props.tags
      : await TagService.getTags({ model: modelType });
  };

  useEffect(() => {
    (async () => {
      sortTags(await getTags(), props.rows, accessor, checkedArr);
    })();
  }, [props.tags]);

  useEffect(() => {
    (async () => {
      if (props.rows.length > 0 && !isEqual(props.rows, prevRows)) {
        sortTags(await getTags(), props.rows, accessor, checkedArr);
      }
    })();
  }, [props.rows]);

  const disableTags = tags.length === 0;
  const disabled = props.disabled || props.isMerging || false;
  const rows = props.rows ? props.rows.filter(row => row) : [];
  const isFiltering = checkedArr.length === 0;

  const addTag = async (tag, metadata) => {
    if (!isFiltering) {
      if (
        !metadata &&
        tag.tag_metadata_fields.some(({ allow_override }) => allow_override)
      ) {
        applyTagModal.current.open(tag);
        return;
      }
      if (!metadata && tag.tag_metadata_fields) {
        metadata = tag.tag_metadata_fields.map(field => ({
          ...field,
          value: field.default_value,
        }));
      }
      try {
        await TagService.addTag(tag, modelType, checkedArr, metadata);
      } catch (error) {
        storeError(error.message);
        return;
      }
    }
    updateSelectedTags(tag);
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleApplyTag = (tag, metadata) => {
    addTag(tag, metadata);
  };

  const removeTag = async tag => {
    if (!isFiltering) {
      await TagService.removeTag(tag, modelType, checkedArr);
    }
    updateSelectedTags(tag, true);
    if (onSuccess) {
      onSuccess();
    }
  };

  const tagIsSelected = selectedTag => !!selectedTagIds[selectedTag.id];

  const updateSelectedTags = (selectedTag, remove = false) => {
    let selectedTagIdsCopy = { ...selectedTagIds };
    if (remove) {
      delete selectedTagIdsCopy[selectedTag.id];
    }

    if (isFiltering) {
      if (!tagIsSelected(selectedTag)) {
        selectedTagIdsCopy[selectedTag.id] = "check";
      }

      let newTagIds = [];
      Object.keys(selectedTagIdsCopy).forEach(tagId => {
        if (selectedTagIdsCopy[tagId] === "check") {
          newTagIds.push(tagId);
        } else {
          newTagIds.push(-tagId);
        }
      });
      setTagIds(newTagIds.join(","));
      setSelectedTagIds(selectedTagIdsCopy);
      setFilter("");
    }
  };

  const tagListItem = tag => {
    let tagged = 0;
    let untagged = 0;
    let method = addTag;
    let icon = null;

    if (isFiltering) {
      if (tagIsSelected(tag)) {
        method = removeTag;
        icon = selectedTagIds[tag.id];
      }
    } else {
      rows.length &&
        checkedArr.forEach(rowId => {
          const row = rows.find(({ [accessor || "id"]: id }) => id === rowId);
          if (row?.tags?.find(({ id }) => id === tag.id)) {
            tagged += 1;
          } else {
            untagged += 1;
          }
        });

      if (tagged > 0 && untagged > 0) {
        icon = "minus";
      } else if (tagged > 0) {
        method = removeTag;
        icon = "check";
      }
    }

    return [
      <Dropdown.Item
        key={tag.id}
        disabled={!isFiltering && !tag.enabled}
        onClick={() => method(tag)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {icon ? <Icon size="small" name={icon} /> : <div />}
        <RvxTag
          tag={tag}
          styleProps={{
            marginRight: "0",
            maxWidth: "300px",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        />
      </Dropdown.Item>,
    ];
  };

  const showTag = tag => (isFiltering ? true : tag.auto !== true);

  const handleChange = (_, { value }) => setFilter(value);

  const sortTagsAlphabetically = tags => {
    return tags.sort((firstTag, secondTag) =>
      firstTag.name.localeCompare(secondTag.name)
    );
  };

  const sortTags = (tags, rows, accessor, checkedArr) => {
    const tagged = [];
    const untagged = [];
    sortTagsAlphabetically(tags).forEach(tag => {
      const isTagged =
        tagIsSelected(tag) ||
        checkedArr.some(rowId => {
          const row = rows.find(({ [accessor || "id"]: id }) => id === rowId);
          return row?.tags?.find(({ id }) => id === tag.id);
        });
      if (isTagged) {
        tagged.push(tag);
      } else {
        untagged.push(tag);
      }
    });
    let sortedTags = [...tagged, ...untagged];
    sortedTags = sortedTags.length > 0 ? sortedTags : tags;
    setTags(sortedTags);
  };

  const handleOpen = () => {
    setFilter("");
  };

  return (
    <>
      <ApplyTagModal ref={applyTagModal} onApplyTag={handleApplyTag} />
      <CustomDropdown
        className={`mini ${className}`}
        icon="tag"
        disabled={disableTags || disabled}
        scrolling
        button
        open={true}
        search={true}
        handleOpen={handleOpen}
        isWithinModal={isWithinModal}
      >
        <Dropdown.Menu
          key={"tags"}
          onClick={e => e.stopPropagation()}
          direction={direction || "left"}
        >
          <Input
            icon="search"
            iconPosition="left"
            className="search"
            value={filter}
            onChange={handleChange}
          />
          <Dropdown.Divider />
          {tags
            .filter(showTag)
            .map(tag =>
              tag.name.toLowerCase().includes(filter.toLowerCase())
                ? tagListItem(tag)
                : null
            )}
        </Dropdown.Menu>
      </CustomDropdown>
    </>
  );
};

export default TagDropdown;

import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../components/RuvixxForm";
import ProductService from "../../services/Product";

import "../../styles/forms.scss";

const formReducer = (s, a) => ({ ...s, ...a });
const initializer = model => {
  return {
    id: model?.id,
    name: "",
    list_price: 0.0,
  };
};

function ProductForm(props) {
  const [formData, setFormData] = useReducer(
    formReducer,
    props.model,
    initializer
  );
  const [error, setError] = useState(null);

  const fetchModel = useCallback(async () => {
    if (!formData.id) {
      return;
    }
    const model = await ProductService.get(formData.id);
    setFormData({
      ...formData,
      id: model.id,
      name: model.name,
      list_price: model.list_price,
    });
  }, []);

  useEffect(() => {
    if (formData.id) {
      fetchModel();
    }
  }, [formData.id]);

  const handleChange = event => {
    setFormData({ [event.currentTarget.name]: event.currentTarget.value });
  };

  const handleSubmit = async () => {
    const { id, name, list_price } = formData;
    const data = {
      name,
      list_price: parseFloat(list_price),
    };

    try {
      if (id) {
        await ProductService.update(id, data);
      } else {
        await ProductService.create(data);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  return (
    <RuvixxForm
      ready={!!formData.name}
      onSubmit={handleSubmit}
      onSuccess={props.onSuccess}
      error={error?.error}
      errorMessage={error?.errorMessage}
      errorTitle={error?.errorTitle}
    >
      <Form.Input
        required
        autoFocus
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      {/* TODO: currency input? */}
      <Form.Input
        type="currency"
        required
        label="List Price"
        name="list_price"
        value={formData.list_price}
        onChange={handleChange}
      />
    </RuvixxForm>
  );
}

export default ProductForm;

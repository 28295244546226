import React, { Component } from "react";
import PropTypes from "prop-types";
import ConfirmationModal from "./ConfirmationModal";

export default class DeleteCategoryModal extends Component {
  static propTypes = {
    onConfirmDelete: PropTypes.func.isRequired,
    menuTrigger: PropTypes.bool,
    noIcon: PropTypes.bool,
  };

  render() {
    return (
      <ConfirmationModal
        disabled={this.props.disabled}
        actionDescription="Delete Category"
        buttonText="Delete Category"
        buttonColor="red"
        onConfirm={this.props.onConfirmDelete}
        menuTrigger={this.props.menuTrigger}
        icon={null}
        warning
      >
        <p>Are you sure you want to delete this Category?</p>
      </ConfirmationModal>
    );
  }
}

import React from "react";
import { Button, Radio, Table } from "semantic-ui-react";

import "./ValidationOptions.scoped.scss";

const VALIDATION_OPTIONS = [
  {
    text: "Contact with same name on another entity",
    key: "contact_dup_name",
  },
  { text: "Invalid phone numbers (ex. 555-555-5555)", key: "pn_invalid" },
  {
    text: "Tags set to be both added and removed",
    key: "check_add_and_remove_tags",
  },
  { text: "Set to remove a non-existent tag", key: "check_existing_tag" },
  {
    text: "Same tag added and removed on different rows",
    key: "tag_add_remove",
  },
  { text: "Empty phone numbers", key: "pn_empty" },
  { text: "Phone number used by other contact", key: "pn_dup_db" },
  { text: "Duplicate phone number in file", key: "pn_dup_file" },
  { text: "Empty email", key: "email_empty" },
  { text: "Custom field already exists on model", key: "cf_exists" },
  { text: "Require link_clicked on click events", key: "link_clicked" },
  { text: "Require bounce_reason on bounce events", key: "bounce_reason" },
  {
    text: "Require bounce_description on bounce events",
    key: "bounce_description",
  },
];

const ValidationOptions = ({
  options,
  useDefault,
  handleToggle,
  finished = false,
}) => {
  const DefaultOptions = () => (
    <div className="default-options">
      <Button onClick={useDefault} primary size="tiny">
        Choose Default Options
      </Button>
    </div>
  );

  return (
    <div className="validation-options">
      {!finished && (
        <p className="validation-msg">Please, choose your validation options</p>
      )}
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Validation Option</Table.HeaderCell>
            <Table.HeaderCell>Error Level</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {VALIDATION_OPTIONS.map(option => (
            <Table.Row>
              <Table.Cell>{option.text}</Table.Cell>
              <Table.Cell>
                <Radio
                  label={"Ignore"}
                  onChange={handleToggle}
                  name={option.key}
                  value={null}
                  checked={!options[option.key]}
                  disabled={finished}
                />
                <Radio
                  label={"Error"}
                  onChange={handleToggle}
                  name={option.key}
                  value={"error"}
                  checked={options[option.key] === "error"}
                  disabled={finished}
                />
                <Radio
                  label={"Warning"}
                  onChange={handleToggle}
                  name={option.key}
                  value={"warning"}
                  checked={options[option.key] === "warning"}
                  disabled={finished}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {!finished && <DefaultOptions />}
    </div>
  );
};

export default ValidationOptions;

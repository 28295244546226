import React, { useState, useEffect, useRef } from "react";
import { Input } from "semantic-ui-react";
import "./../styles/search.scss";

const UPDATE_TIMEOUT = 300; // milliseconds to wait before submitting update

const SearchInput = ({ query = "", placeholder, onUpdateQuery }) => {
  const [value, setValue] = useState(query);
  const [lastValue, setLastValue] = useState(query);
  const timeout = useRef();

  useEffect(() => {
    setValue(query);
    setLastValue(query);
  }, [query]);

  const handleChange = (_, { value }) => {
    setValue(value);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => submitChange(value), UPDATE_TIMEOUT);
  };

  const submitChange = value => {
    if (value !== lastValue) {
      const finalValue = value.trim();
      setLastValue(finalValue);
      onUpdateQuery(finalValue);
    }
  };

  return (
    <Input
      className="search"
      name="search"
      type="text"
      size="mini"
      placeholder={placeholder || "Search..."}
      value={value}
      onChange={handleChange}
      icon="search"
    />
  );
};

export default SearchInput;

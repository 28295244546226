import { applyFilters, doRequest } from "./helpers";

export default class CampaignStatusPresetService {
  static async getCampaignStatusPresets() {
    return doRequest("GET", "/campaign_status_preset/");
  }

  static async getCampaignStatusPresetsAsTable(filters = null) {
    let path = "/campaign_status_preset/table";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createCampaignStatusPreset(name, campaignStatuses) {
    const data = {
      name,
      campaign_statuses: campaignStatuses,
    };
    return doRequest("POST", "/campaign_status_preset/", {
      data,
    });
  }

  static async editCampaignStatusPreset(id, name, campaignStatuses) {
    const data = {
      name,
      campaign_statuses: campaignStatuses,
    };
    return doRequest("PUT", `/campaign_status_preset/${id}`, {
      data,
    });
  }

  static async deleteCampaignStatusPreset(id) {
    return doRequest("DELETE", `/campaign_status_preset/${id}`);
  }
}

import React, { useState, useEffect } from "react";
import { Card, Grid, Icon, Label } from "semantic-ui-react";
import Link from "../../../components/Link";

import "./StepInfoCard.scoped.scss";
import taskConstants from "../../../constants/Task";
import {
  checkIsAuthorized,
  formatDate,
  convertToLocalDateTime,
} from "../../../components/helpers";
import { TIME_IN_MS } from "../../../constants/Constants";
import PriorityLabel from "./PriorityLabel";
import ACL_RELATIONSHIPS from "../../../acl-relationships";

const StepInfoCard = ({
  step,
  onEdit,
  onDelete,
  link,
  loading,
  canEdit: parentCanEdit,
}) => {
  const [canEdit, setCanEdit] = useState(
    checkIsAuthorized([
      ACL_RELATIONSHIPS.task.read,
      ACL_RELATIONSHIPS.task.edit,
      ACL_RELATIONSHIPS.user.read,
      ACL_RELATIONSHIPS.contact.read,
      ACL_RELATIONSHIPS.entity.read,
      ACL_RELATIONSHIPS.campaign.read,
      ACL_RELATIONSHIPS.adminRoleForFilters.read,
      ACL_RELATIONSHIPS.adminRole.read,
      ACL_RELATIONSHIPS.userSearch.read,
    ])
  );
  const [stepInfo, setStepInfo] = useState({});

  useEffect(() => {
    if (parentCanEdit !== undefined) {
      setCanEdit(parentCanEdit);
    }
  }, [parentCanEdit]);

  useEffect(() => {
    if (!step) {
      return;
    }
    const {
      id,
      name,
      type,
      created_at,
      target,
      priority: priorityId,
      description,
      status: statusId,
      assigned_users,
      due_date,
    } = step;
    const { icon } = taskConstants.types.find(({ id }) => id === type);
    const targetType = target
      ? taskConstants.targets.find(({ name }) => name === target.type)
      : null;
    const priority = taskConstants.priorities.find(
      ({ id }) => id === priorityId
    );
    const { name: status } = statusId
      ? taskConstants.statuses.find(({ id }) => id === statusId)
      : {};
    const assignees = (assigned_users || [])
      .map(({ full_name }) => full_name)
      .join(", ");
    const date = formatDate(created_at);
    const dueDate = formatDate(due_date);
    setStepInfo({
      id,
      icon,
      name,
      targetType,
      target,
      date,
      dueDate,
      priority,
      assignees,
      description,
      status,
    });
  }, [step]);

  const renderLoading = () => <Icon name="circle notched" loading />;

  const renderGrid = () => {
    const {
      icon,
      name,
      targetType,
      target,
      date,
      dueDate,
      priority,
      assignees,
      description,
      status,
    } = stepInfo;

    const dueDateDelta = convertToLocalDateTime(step.due_date) - new Date();

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column className="icon">
            <Icon name={icon} />
          </Grid.Column>
          <Grid.Column className="main">
            <Card.Header>{name}</Card.Header>
            <div className="targetRow">
              {target && targetType && (
                <span>
                  {`${targetType.name}: `}
                  {!target.id || target.deleted_at || !targetType.uri ? (
                    target.name
                  ) : (
                    <Link to={`/${targetType.uri}/${target.id}`}>
                      {target.name}
                    </Link>
                  )}
                </span>
              )}
              <span>{date}</span>
            </div>
            <div className="secondRow">
              <PriorityLabel priority={priority} />
              {assignees && (
                <span className="assignees">
                  <Icon name="user" size="mini" className="brand" />
                  {assignees}
                </span>
              )}
            </div>
            {description && <div className="message">{description}</div>}
          </Grid.Column>
          <Grid.Column className="right">
            <span
              className="status"
              style={canEdit ? { marginRight: "2em" } : undefined}
            >
              {status && (
                <>
                  <strong>Status:</strong> {status}
                </>
              )}
            </span>
            {dueDate && (
              <>
                <Label
                  className={`dueDate ${
                    dueDateDelta < 0
                      ? "red"
                      : dueDateDelta <= TIME_IN_MS.DAY
                      ? "yellow"
                      : ""
                  }`}
                  content={`Due by: ${dueDate}`}
                />
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderCardContent = () => (
    <Card.Content>{loading ? renderLoading() : renderGrid()}</Card.Content>
  );

  return (
    <Card fluid className="stepInfo brand">
      {link && stepInfo.id ? (
        <Link to={`/tasks/${stepInfo.id}`}>{renderCardContent()}</Link>
      ) : (
        renderCardContent()
      )}
      {canEdit && onDelete && !loading && (
        <Icon
          name="delete"
          className="delete"
          link
          onClick={() => onDelete(step)}
        />
      )}
      {canEdit && onEdit && !loading && (
        <Icon
          name="pencil"
          className="primary edit"
          link
          onClick={() => onEdit(step)}
        />
      )}
    </Card>
  );
};

export default StepInfoCard;

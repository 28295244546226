import React, { createRef } from "react";
import PropTypes from "prop-types";
import { Container, Dropdown, Segment } from "semantic-ui-react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

import "../../../styles/table.scss";
import "./../../../styles/tab_view.scss";

import BaseTable from "../../../components/BaseTable";
import AuthService from "../../../services/Auth";
import EntityService from "../../../services/Entity";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import CustomDropdown from "../../../components/CustomDropdown";
import setPageTitle from "../../../helpers/title";
import {
  DepartmentModal,
  DeleteDepartmentModal as _DeleteDepartmentModal,
} from "../../entity/components/DepartmentsModals";

const NewDepartmentButton = withRoleCheck(DepartmentModal.NewButton, [
  ACL_RELATIONSHIPS.entityDepartment.create,
]);

const EditDepartmentButton = withRoleCheck(DepartmentModal.EditButton, [
  ACL_RELATIONSHIPS.entityDepartment.edit,
]);

const DeleteDepartmentModal = withRoleCheck(_DeleteDepartmentModal, [
  ACL_RELATIONSHIPS.entityDepartment.delete,
]);

class DepartmentTable extends BaseTable {
  static propTypes = {
    entityId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.queryMethod = EntityService.getDepartmentsTable;
    this.queryArgs = [props.entityId];
    this.departmentModal = createRef();

    this.state = {
      ...this.state,
      defaultFilterParams: { entity_id: this.props.entityId },
      header: "Departments",
      headerIcon: "building",
      className: "Departments",
      tableName: "departments",
      noDataText: "No Departments found. Try adjusting your filters.",
      enableSearch: false,
      DepartmentOptions: [],
      createButton: <NewDepartmentButton modalRef={this.departmentModal} />,
      enableCustomFieldsSettings: true,
    };
  }

  async componentDidMount() {
    setPageTitle("Departments");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "Department ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Actions",
        id: "actions",
        width: 140,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ original: department }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditDepartmentButton
                department={department}
                modalRef={this.departmentModal}
              />
              <DeleteDepartmentModal
                entityId={this.props.entityId}
                departmentId={department.id}
                onSuccess={this.fetchData}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  deleteDepartment = async id => {
    await EntityService.deleteDepartment(id, this.props.entityId);
    this.fetchData();
  };

  render() {
    return (
      <Container fluid className="route">
        <div className="campaign-segment">
          <DepartmentModal
            ref={this.departmentModal}
            entityId={this.props.entityId}
            onSuccess={this.fetchData}
          />
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default DepartmentTable;

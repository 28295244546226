import React, { useState } from "react";
import { Button, Modal, Popup, Icon } from "semantic-ui-react";
import FollowupForm from "./FollowupForm";

function FollowupModal({
  parent_question,
  form_response_id,
  callback,
  onEditOptions,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = async () => {
    setModalOpen(true);
  };

  const handleClose = async () => {
    setModalOpen(false);
  };

  const handleSuccess = async followup => {
    callback(followup);
    handleClose();
  };
  return (
    <Modal
      trigger={
        <Popup
          position="top right"
          content="Add follow-up question"
          trigger={
            <Button icon onClick={handleOpen}>
              <Icon name="plus circle" />
            </Button>
          }
        />
      }
      size="tiny"
      open={modalOpen}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header>Add Follow-Up Question</Modal.Header>
      <Modal.Content>
        <FollowupForm
          onSuccess={handleSuccess}
          onEditOptions={onEditOptions}
          parent_question={parent_question}
          form_response_id={form_response_id}
        />
      </Modal.Content>
    </Modal>
  );
}

export default FollowupModal;

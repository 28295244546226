const recipientType = {
  RESELLER: "reseller",
  DISTRIBUTOR: "distributor",
  CLIENT: "client",
};

const revOppsConstants = {
  recipientType,
};

export default revOppsConstants;

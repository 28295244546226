import { applyFilters, doRequest } from "./helpers";

export default class SequenceService {
  static async getSequences(filters) {
    let path = `/sequence/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getSequence(id) {
    let path = `/sequence/${id}`;
    return doRequest("GET", path);
  }

  static async createSequence(data) {
    return doRequest("POST", "/sequence/", { data });
  }

  static async editSequence(id, data) {
    return doRequest("PUT", `/sequence/${id}`, { data });
  }

  static async createSequenceStep(id, data) {
    return doRequest("POST", `/sequence/${id}/step/`, { data });
  }

  static async editSequenceStep(id, stepIndex, data) {
    return doRequest("PUT", `/sequence/${id}/step/${stepIndex}`, { data });
  }

  static async deleteSequence(id) {
    return doRequest("DELETE", `/sequence/${id}`);
  }
  static async setSequenceCampaigns(id, data) {
    return doRequest("POST", `/sequence/${id}/campaigns`, { data });
  }
}

import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";

import PropTypes from "prop-types";
import CallDispositionService from "../../../services/CallDispositions";
import { CALL_DISPOSITION_TYPES } from "../../../constants/Constants";
import RuvixxSelect from "../../../components/RuvixxSelect";

function CallDispositionForm(props) {
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    dnc: false,
    invalid: false,
    parent_id: null,
    is_successful: false,
    assignToSelf: false,
  });
  const [parentDispositions, setParentDispositions] = useState([]);

  const dispositionTypes = Object.values(CALL_DISPOSITION_TYPES).map(type => ({
    id: type,
    value: type,
    text: type,
    key: type,
  }));

  const formatParents = dispositions => [
    ...Object.values(dispositions)
      .filter(disposition => formData.id !== disposition.id)
      .map(disposition => ({
        id: disposition.id,
        value: disposition.id,
        text: disposition.name,
        key: disposition.id,
      })),
  ];

  const fetchDisposition = useCallback(async dispositionId => {
    const { id, name, type, dnc, invalid, parent_id, is_successful } =
      await CallDispositionService.getDisposition(dispositionId);
    setFormData({ id, name, type, dnc, invalid, parent_id, is_successful });
  }, []);

  useEffect(() => {
    if (props.dispositionId) {
      fetchDisposition(props.dispositionId);
    }
  }, [props.dispositionId, fetchDisposition]);

  const handleChange = (event, data) => {
    const formDataCopy = { ...formData };
    formDataCopy[data.name] =
      data.type === "checkbox" ? data.checked : data.value;
    setFormData(formDataCopy);
  };

  const handleSelect = (e, { name, value }) => {
    if (name === undefined) return;
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    if (name === "parent_id") {
      formDataCopy["type"] = value ? getParent(value).type : "";
    }
    setFormData(formDataCopy);
  };

  const getParent = parent_id => {
    return parentDispositions.find(d => d.id === parent_id);
  };

  const handleSubmit = async e => {
    const {
      id,
      name,
      type,
      dnc,
      invalid,
      parent_id,
      is_successful,
      assignToSelf,
    } = formData;
    const data = {
      name: name,
      type: type,
      dnc: dnc,
      invalid: invalid,
      parent_id: parent_id ? parent_id : null,
      is_successful: type == "connected" ? is_successful : false,
      assign_to_self: assignToSelf,
    };
    try {
      if (id) {
        await CallDispositionService.editDisposition(id, data);
      } else {
        await CallDispositionService.createDisposition(data);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  const getParentDispositions = async (filters = null) => {
    let response = await CallDispositionService.getDispositionsForFilters({
      has_parent: "false",
      ...filters,
    });
    setParentDispositions(response);
    return formatParents(response);
  };

  return (
    <RuvixxForm
      ready={!!formData.name && !!formData.type}
      onSubmit={handleSubmit}
      onSuccess={props.onSuccess}
    >
      <Form.Input
        inline
        required
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      {props.parentDispositions.length > 0 && (
        <RuvixxSelect
          label="Parent Disposition"
          name="parent_id"
          multiple={false}
          clearable={true}
          value={formData.parent_id}
          onChange={handleSelect}
          queryFn={getParentDispositions}
        />
      )}
      <Form.Select
        required
        label="Type"
        name="type"
        selection
        search
        onChange={handleSelect}
        options={dispositionTypes}
        value={formData.type}
        disabled={!!formData.parent_id}
      />
      <Form.Checkbox
        toggle
        name="dnc"
        checked={formData.dnc}
        onChange={handleChange}
        label="DNC"
        required
      />
      <Form.Checkbox
        toggle
        name="invalid"
        checked={formData.invalid}
        onChange={handleChange}
        label="Invalid"
        required
      />
      {formData.type == "connected" ? (
        <Form.Checkbox
          toggle
          name="is_successful"
          checked={formData.is_successful}
          onChange={handleChange}
          label="Is Successful?"
          required
        />
      ) : null}
    </RuvixxForm>
  );
}

CallDispositionForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default CallDispositionForm;

import React, { useState, useEffect } from "react";
import { Popup } from "semantic-ui-react";

const FadingPopup = ({ open, setOpen, className, ...rest }) => {
  const [popupState, setPopupState] = useState("closed");

  useEffect(() => {
    if (open) {
      setPopupState("open");
      setTimeout(() => setPopupState("fading"), 2000);
      setTimeout(() => {
        setPopupState("closed");
        setOpen(false);
      }, 2500);
    } else {
      setPopupState("closed");
    }
  }, [open, setOpen]);

  return (
    <Popup
      position="top center"
      open={popupState !== "closed"}
      className={`transition fade ${popupState === "open" ? "in" : "out"} ${
        className || ""
      }`}
      {...rest}
    />
  );
};

export default FadingPopup;

import { applyFilters, doRequest } from "./helpers";

export default class InvestigationStatusService {
  static async getInvestigationStatusesAsTable(filters = null) {
    let path = "/case/statuses/table";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createInvestigationStatus(name, description) {
    return doRequest("POST", `/case/statuses`, {
      data: { name, description },
    });
  }

  static async deleteInvestigationStatus(id) {
    return doRequest("DELETE", `/case/statuses/${id}`);
  }

  static async editInvestigationStatus(id, name, description) {
    return doRequest("PUT", `/case/statuses/${id}`, {
      data: { name, description },
    });
  }
}

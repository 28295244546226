import { applyFilters, doRequest } from "./helpers";

export default class EmailTemplateService {
  static async getEmailTemplatesForFilters() {
    return doRequest("GET", "/email/assets/templates/for_filters");
  }

  static async getEmailTemplatesTable(filters = null) {
    let path = `/email/assets/templates/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getEmailTemplate(id) {
    return doRequest("GET", `/email/assets/templates/${id}`);
  }

  static async getRenderedTemplate(id, filters) {
    let path = `/email/assets/templates/${id}/render`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createEmailTemplate(
    name,
    subject,
    text,
    html,
    info,
    customFields
  ) {
    return doRequest("POST", "/email/assets/templates/", {
      data: {
        name,
        subject,
        text,
        html,
        info,
        custom_fields: customFields,
      },
    });
  }

  static async editEmailTemplate({
    id,
    name,
    subject,
    text,
    html,
    info,
    cc_json,
    customFields,
  }) {
    return doRequest("PUT", `/email/assets/templates/${id}`, {
      data: {
        name,
        subject,
        text,
        html,
        info,
        cc_json,
        custom_fields: customFields,
      },
    });
  }

  static async deleteEmailTemplate(id) {
    return doRequest("DELETE", `/email/assets/templates/${id}`);
  }

  static async getTags() {
    return doRequest("GET", "/email/assets/templates/tags");
  }

  static async getUrls() {
    return doRequest("GET", "/email/assets/templates/urls");
  }

  static async checkIfTemplateBeingUsed(id) {
    return doRequest("GET", `/email/assets/templates/${id}/check`);
  }

  static async getCustomFields() {
    let path = `/email/assets/templates/custom_fields`;
    return doRequest("GET", path);
  }
}

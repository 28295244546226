import { doRequest, applyFilters } from "./helpers";

const CustomFieldGroupsService = {
  getCustomFieldGroups: async (filters = null) => {
    const path = applyFilters(filters, "/custom_field_group/");
    return doRequest("GET", path);
  },

  createCustomFieldGroup: async ({
    name,
    modelType,
    customFieldConfigs,
    description,
  }) => {
    const data = {
      name: name,
      model_type: modelType,
      custom_fields: customFieldConfigs,
      description: description,
    };
    return doRequest("POST", "/custom_field_group/", { data });
  },

  getCustomFieldGroup: async id => {
    return doRequest("GET", `/custom_field_group/${id}`);
  },

  editCustomFieldGroup: async ({
    id,
    name,
    modelType,
    description,
    customFieldConfigs,
  }) => {
    const data = {
      name: name,
      model_type: modelType,
      description: description,
      custom_fields: customFieldConfigs,
    };
    return doRequest("PUT", `/custom_field_group/${id}`, { data });
  },

  deleteCustomFieldGroup: async id => {
    return doRequest("DELETE", `/custom_field_group/${id}`);
  },

  assignCustomFieldToGroup: async (customFieldGroupId, customFieldConfigId) => {
    const data = {
      group_id: customFieldGroupId,
      custom_field_config_id: customFieldConfigId,
    };
    return doRequest("POST", "/custom_field_group/assign/", { data });
  },

  removeCustomFieldFromGroup: async (
    customFieldGroupId,
    customFieldConfigId
  ) => {
    const data = {
      group_id: customFieldGroupId,
      custom_field_config_id: customFieldConfigId,
    };
    return doRequest("DELETE", "/custom_field_group/assign/", { data });
  },

  getCustomFieldGroupsForModelType: async model_type => {
    return doRequest("GET", `/custom_field_group/model_type/${model_type}`);
  },
};
export default CustomFieldGroupsService;

const status = [
  { id: 1, name: "Active" },
  { id: 2, name: "Inactive" },
  { id: 3, name: "Archived" },
];

const campaignConstants = {
  status,
};

export default campaignConstants;

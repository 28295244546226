import React, { useState, cloneElement } from "react";
import {
  Button,
  Header,
  Icon,
  Modal,
  Popup,
  Dropdown,
  Message,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import TableButton from "../TableButton";

const ConfirmationModal = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setModalOpen(false);
  };

  const handleConfirm = async () => {
    try {
      let response = await props.onConfirm();
      if (response) {
        setError(response.message);
      } else {
        handleClose();
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const tableButtonTrigger = () => (
    <TableButton
      edit={props.tableButton === "edit"}
      delete={props.tableButton === "delete"}
      description={props.actionDescription}
      onClick={handleOpen}
    />
  );

  const popupTrigger = () =>
    props.simplePopup ? (
      <Popup
        position={`top ${props.popupPosition}`}
        content={props.actionDescription}
        trigger={
          <Button
            style={props.style}
            color={props.buttonColor}
            inverted
            onClick={handleOpen}
            size={props.circularIcon ? "mini" : "tiny"}
            disabled={props.disabled}
            icon={!props.buttonText}
          >
            {props.icon ? (
              props.circularIcon ? (
                <Icon.Group
                  style={{ verticalAlign: "2.3px", marginRight: "5px" }}
                >
                  <Icon size="large" name="circle outline" />
                  <Icon
                    size="small"
                    name={props.icon}
                    style={{ transform: "translate(-7px, -4px)" }}
                  />
                </Icon.Group>
              ) : (
                <Icon
                  name={props.icon}
                  style={{ ...(!props.buttonText && { margin: 0 }) }}
                />
              )
            ) : null}
            <span
              style={{
                ...(props.circularIcon && { fontSize: "1.1em" }),
              }}
            >
              {props.buttonText}
            </span>
          </Button>
        }
      />
    ) : (
      <Popup
        position={props.popupPosition}
        content={props.actionDescription}
        trigger={
          <Button onClick={handleOpen} size="tiny" disabled={props.disabled} />
        }
      />
    );

  const menuTrigger = () => (
    <Dropdown.Item
      onClick={handleOpen}
      icon={props.icon}
      content={props.actionDescription}
      disabled={props.disabled}
    />
  );

  const iconTrigger = () => (
    <Button basic icon onClick={handleOpen} disabled={props.disabled}>
      <Icon name={props.icon} />
    </Button>
  );

  const getTrigger = () => {
    if (props.trigger) {
      return cloneElement(props.trigger, {
        onClick: handleOpen,
      });
    }
    if (props.tableButton) {
      return tableButtonTrigger();
    }
    if (props.menuTrigger) {
      return menuTrigger();
    }
    if (props.iconTrigger) {
      return iconTrigger();
    }
    return popupTrigger();
  };

  let { actionDescription, content, children, warning } = props;
  const cancelClass = `cancel ${warning ? `warning` : ""}`;
  const confirmClass = `confirm ${warning ? `warning` : ""}`;

  return (
    <Modal
      trigger={getTrigger()}
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      closeIcon
    >
      <Header
        icon={warning ? "warning sign" : null}
        content={actionDescription}
      />
      <Modal.Content>
        {content || children}
        {error && <Message error header={`Action Failed`} content={error} />}
      </Modal.Content>
      <Modal.Actions>
        <Button className={cancelClass} onClick={handleClose}>
          <Icon name="remove" /> No
        </Button>
        <Button className={confirmClass} onClick={handleConfirm}>
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  actionDescription: PropTypes.string.isRequired,
  icon: PropTypes.string,
  circularIcon: PropTypes.bool,
  buttonColor: PropTypes.string,
  buttonText: PropTypes.string,
  style: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  dontAwait: PropTypes.bool,
  menuTrigger: PropTypes.bool,
  iconTrigger: PropTypes.bool,
  simplePopup: PropTypes.bool,
  popupPosition: PropTypes.string,
  tableButton: PropTypes.string,
  content: PropTypes.node,
  warning: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  simplePopup: true,
  popupPosition: "left",
  disabled: false,
  warning: false,
};

export default ConfirmationModal;

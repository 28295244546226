import React, { useState } from "react";
import { Button, Dropdown, Header, Modal } from "semantic-ui-react";
import SequenceCampaignForm from "./SequenceCampaignForm";

export default function SequenceCampaignModal(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const onSuccess = async => {
    handleClose();
    props.onSuccess();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        props.button ? (
          <Button
            content="Attach To"
            onClick={handleOpen}
            size="tiny"
            className="item-adder"
          />
        ) : (
          <Dropdown.Item
            className="primary"
            content="Attach To"
            onClick={handleOpen}
          />
        )
      }
    >
      <Header content="Attach to Campaign" />
      <Modal.Content>
        <SequenceCampaignForm
          sequenceId={Number(props.sequenceId)}
          onSuccess={onSuccess}
        />
      </Modal.Content>
    </Modal>
  );
}

import React, { useState, useCallback, useEffect, useRef } from "react";
import { Form } from "semantic-ui-react";

import RuvixxForm from "../../../components/RuvixxForm";
import TargetSelector from "./TargetSelector";
import taskConstants from "../../../constants/Task";
import UserService from "../../../services/User";
import VisibilitySelector from "./VisibilitySelector";
import DateTimePicker from "../../../components/DateTimePicker";
import { getUserTimeZone, stringToDateTime } from "../../../components/helpers";
import UploadManager from "../../../components/UploadManager";
import UploadProgressRing from "../../../components/UploadProgressRing";
import TaskService from "../../../services/Task";
import FilePicker from "../../../components/FilePicker";
import CampaignService from "../../../services/Campaign";
import RuvixxSelect from "../../../components/RuvixxSelect";

const TaskForm = ({ task, onSuccess, modelType, modelId }) => {
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [targetType, setTargetType] = useState({});
  const [targetId, setTargetId] = useState(null);
  const [type, setType] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [visibility, setVisibility] = useState({});
  const [visibilityIds, setVisibilityIds] = useState([]);
  const [status, setStatus] = useState(
    taskConstants.statuses.find(({ key }) => key === "open").id
  );
  const [dueDate, setDueDate] = useState();
  const [dueDateTimeZone, setDueDateTimeZone] = useState(getUserTimeZone());
  const [priority, setPriority] = useState(null);
  const [description, setDescription] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const [info, setInfo] = useState({});
  const [campaignIds, setCampaignIds] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const uploadManager = useRef();

  const isCreating = !task;
  const ready =
    !!name &&
    !!targetId &&
    !!type &&
    assignees.length > 0 &&
    (visibility.key === "public" || visibilityIds.length > 0) &&
    !!status;

  const loadTask = useCallback(() => {
    if (!task) {
      return;
    }

    let {
      id,
      name,
      target: { id: targetId, type: targetType },
      type,
      assigned_users: assignees,
      visibility,
      status,
      due_date: dueDate,
      time_zone: dueDateTimeZone,
      priority,
      description,
      roles,
      users,
      attachments,
      info,
      campaign_ids,
    } = task;

    targetType = taskConstants.targets.find(({ name }) => name === targetType);
    visibility = taskConstants.visibilities.find(({ id }) => id === visibility);
    let visibilityIds = [];
    if (visibility.key === "private_roles") {
      visibilityIds = roles;
    } else if (visibility.key === "private_users") {
      visibilityIds = users;
    }
    visibilityIds = visibilityIds.map(({ id }) => id);
    assignees = assignees.map(({ id }) => id);
    dueDate = dueDate && stringToDateTime(dueDate, dueDateTimeZone);
    attachments = attachments.map(({ file_name: name }) => ({ name }));

    setId(id);
    setName(name);
    setTargetType(targetType);
    setTargetId(targetId);
    setType(type);
    setAssignees(assignees);
    setVisibility(visibility);
    setVisibilityIds(visibilityIds);
    setStatus(status);
    !!dueDate && setDueDate(dueDate);
    !!dueDateTimeZone && setDueDateTimeZone(dueDateTimeZone);
    setPriority(priority);
    setDescription(description);
    setAttachments(attachments);
    !!info && setInfo(info);
    setCampaignIds(campaign_ids);
  }, [task]);

  useEffect(() => {
    if (modelId && modelType) {
      const targetType = taskConstants.targets.find(
        ({ name }) => name === modelType
      );
      setTargetId(modelId);
      setTargetType(targetType);
    }
    if (modelType == "Campaign") {
      setCampaignIds([parseInt(modelId)]);
    }
  }, [modelId, modelType]);

  const fetchUsers = useCallback(async () => {
    const users = await UserService.getUsers();
    const assigneeOptions = users.map(({ id, full_name }) => ({
      key: id,
      value: id,
      text: full_name,
    }));
    setAssigneeOptions(assigneeOptions);
  }, []);

  const initUploadManager = useCallback(() => {
    uploadManager.current = new UploadManager();
    uploadManager.current.addProgressListener(files => setFiles([...files]));
  }, []);

  useEffect(() => {
    loadTask();
    fetchUsers();
    initUploadManager();
  }, [loadTask, fetchUsers, initUploadManager]);

  const handleFileSelect = async files => {
    await uploadManager.current.uploadFiles(files);
  };

  const handleCampaignSelect = (e, { name, value }) => {
    setCampaignIds(value);
  };

  const handleSubmit = async () => {
    if (id) {
      await TaskService.updateTask(
        id,
        name,
        targetType,
        targetId,
        type,
        assignees,
        visibility,
        visibilityIds,
        status,
        dueDate,
        dueDateTimeZone,
        priority,
        description,
        files,
        info,
        campaignIds
      );
    } else {
      await TaskService.createTask(
        name,
        targetType,
        targetId,
        type,
        assignees,
        visibility,
        visibilityIds,
        status,
        dueDate,
        dueDateTimeZone,
        priority,
        description,
        files,
        info,
        campaignIds
      );
    }
  };

  return (
    <RuvixxForm
      ready={ready}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      error={error}
      errorMessage={errorMessage}
    >
      <Form.Input
        required
        inline
        label="Task Name"
        value={name}
        maxLength={255}
        onChange={(_, { value }) => setName(value)}
      />
      {modelType === "Campaign" ? null : (
        <RuvixxSelect
          name="taskCampaignIds"
          clearable={true}
          multiple={true}
          value={campaignIds}
          onChange={handleCampaignSelect}
          label={"Campaigns"}
          placeholder="Select Campaigns"
          queryFn={CampaignService.getCampaignsForFilters}
        />
      )}
      {!modelId && !modelType && (
        <TargetSelector
          targetType={targetType}
          setTargetType={setTargetType}
          targetId={targetId}
          setTargetId={setTargetId}
          campaignIds={campaignIds}
        />
      )}
      <Form.Select
        required
        inline
        label="Task Type"
        value={type}
        options={taskConstants.types.map(({ id, name }) => ({
          key: id,
          value: id,
          text: name,
        }))}
        onChange={(_, { value }) => setType(value)}
      />
      <Form.Select
        required
        inline
        label="Assignee"
        search
        multiple
        value={assignees}
        options={assigneeOptions}
        onChange={(_, { value }) => setAssignees(value)}
      />
      <VisibilitySelector
        visibility={visibility}
        setVisibility={setVisibility}
        visibilityIds={visibilityIds}
        setVisibilityIds={setVisibilityIds}
      />
      <Form.Select
        required
        inline
        label="Status"
        value={status}
        options={taskConstants.statuses
          .filter(({ key }) => !isCreating || key !== "closed")
          .map(({ id, name }) => ({
            key: id,
            value: id,
            text: name,
          }))}
        onChange={(_, { value }) => setStatus(value)}
      />
      <DateTimePicker
        inline
        label="Due Date"
        dateTime={dueDate}
        setDateTime={setDueDate}
        timeZone={dueDateTimeZone}
        className="fillSpace"
      />
      <Form.Select
        inline
        clearable
        label="Priority"
        value={priority}
        options={taskConstants.priorities.map(({ id, name }) => ({
          key: id,
          value: id,
          text: name,
        }))}
        onChange={(_, { value }) => setPriority(value || null)}
      />
      <Form.TextArea
        label="Task Description"
        value={description}
        onChange={(_, { value }) => setDescription(value)}
      />
      <Form.Field>
        <label>Attach Files</label>
        <FilePicker
          onSelect={handleFileSelect}
          multiple
          className="fileButton"
        />
        <UploadProgressRing
          uploads={files}
          onDelete={upload => uploadManager.current.removeUpload(upload)}
          xOnComplete
        />
        <dl>
          {[...attachments, ...files].map(({ name }, index) => (
            <dd key={index}>{name}</dd>
          ))}
        </dl>
      </Form.Field>
    </RuvixxForm>
  );
};

export default TaskForm;

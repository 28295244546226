import React, { createRef } from "react";
import BaseTable from "../../../components/BaseTable";
import AuthService from "../../../services/Auth";
import RoleService from "../../../services/Role";
import RoleModal from "./RoleModal";
import { Button, Container, Dropdown, Segment } from "semantic-ui-react";
import ConfirmModal from "./ConfirmModal";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import setPageTitle from "../../../helpers/title";
import CustomDropdown from "../../../components/CustomDropdown";

const AddNewRoleButton = withRoleCheck(Button, [
  ACL_RELATIONSHIPS.adminRole.create,
]);

class RolesTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = RoleService.getRolesTableAsAdmin;

    this.roleModal = createRef();
    this.confirmModal = createRef();

    this.state = {
      ...this.state,
      enableSearch: false,
      enableSettings: false,
      className: "role_table",
      tableName: "role_table",
      noDataText: "No Roles",
      createButton: (
        <AddNewRoleButton
          size="tiny"
          content="New Role"
          className="item-adder"
          onClick={() => this.roleModal.current.open()}
        />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Roles");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Name",
        accessor: "name",
        headerClassName: "padded",
        className: "padded",
      },
      {
        Header: "Actions",
        id: "actions",
        sortable: false,
        Cell: ({ original: role }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu>
              <Dropdown.Item
                icon="edit"
                content="Edit Role"
                onClick={() => this.roleModal.current.open(role)}
              />
              <Dropdown.Item
                icon="trash"
                content="Delete Role"
                onClick={() => this.confirmModal.current.open(role)}
              />
              <Dropdown.Item
                icon="clone outline"
                content="Copy Role"
                onClick={() => this.roleModal.current.open(role, true)}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  confirmDelete = async role => {
    await RoleService.deleteRole(role.id);
    this.fetchData();
  };

  render = () => {
    return (
      <Container fluid className="admin route" {...this.props}>
        <Segment>
          <div className="content">
            <RoleModal ref={this.roleModal} onSuccess={this.fetchData} />
            <ConfirmModal
              ref={this.confirmModal}
              onConfirm={this.confirmDelete}
              title="Delete Role"
              confirmButton={{ content: "Delete", color: "red" }}
              content={({ name }) =>
                `Are you sure you want to delete role '${name}'?`
              }
            />
            {super.render()}
          </div>
        </Segment>
      </Container>
    );
  };
}

export default RolesTable;

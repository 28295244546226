import RuvixxForm from "components/RuvixxForm";
import InvestigationStatusService from "services/InvestigationStatus";
import React, { useState } from "react";
import { Form } from "semantic-ui-react";

const InvestigationStatusForm = ({ investigationStatus, onSuccess }) => {
  const [description, setDescription] = useState(
    investigationStatus?.description
  );
  const [name, setName] = useState(investigationStatus?.name);

  const handleSubmit = async () => {
    if (investigationStatus?.id) {
      await InvestigationStatusService.editInvestigationStatus(
        investigationStatus.id,
        name,
        description
      );
      setName("");
      setDescription("");
    } else {
      await InvestigationStatusService.createInvestigationStatus(
        name,
        description
      );
      setName("");
      setDescription("");
    }
  };

  return (
    <RuvixxForm
      onSubmit={handleSubmit}
      submitButtonText={investigationStatus ? "Save" : "Add"}
      onSuccess={onSuccess}
    >
      <Form.Input
        inline
        required
        name="name"
        label="Name"
        onChange={(_, { value }) => setName(value)}
        placeholder="Investigation Status Name"
        value={name}
      />
      <Form.Input
        inline
        required
        name="description"
        label="Description"
        value={description}
        placeholder="Investigation Status Description"
        onChange={(_, { value }) => setDescription(value)}
      />
    </RuvixxForm>
  );
};

export default InvestigationStatusForm;

import React from "react";
import { Popup } from "semantic-ui-react";
import moment from "moment";

const RuvixxDate = ({ date, isAbsoluteDateEnabled }) => {
  const relativeDate = moment().to(moment.utc(date));
  const absoluteDate = moment
    .utc(date)
    .local()
    .format("MMMM Do YYYY, h:mm:ss a");

  return isAbsoluteDateEnabled ? (
    <>{absoluteDate}</>
  ) : (
    <Popup
      trigger={<div>{relativeDate}</div>}
      content={<div>{absoluteDate}</div>}
    ></Popup>
  );
};

export default RuvixxDate;

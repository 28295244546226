import React, { Component } from "react";
import { Container, Header, Grid, Icon } from "semantic-ui-react";
import UserSettingsForm from "./forms/UserSettings";

class UserSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Container fluid className="route">
        <Grid className="bg list-grid" divided>
          <Grid.Row>
            <Grid.Column className="sixteen wide">
              <Header>
                <Icon name="user" />
                My Account
              </Header>
              <UserSettingsForm />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default UserSettings;

import React, { useEffect, useState } from "react";
import saveAs from "file-saver";
import {
  Document,
  Font,
  Image,
  Link,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import ActivityService from "../../services/Activity";
import { EMAIL_URL_LANGS } from "../../constants/Constants";
import { createDateTimeFormatter } from "../helpers";

const styles = StyleSheet.create({
  body: {
    fontSize: 12,
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  header: {
    fontSize: 16,
    fontWeight: 700,
  },
  row: {
    marginTop: 10,
  },
  question: {
    fontWeight: 700,
  },
  answer: {
    paddingLeft: 20,
  },
  listItem: {
    display: "list-item",
  },
  tableAnswer: {
    marginTop: 5,
    paddingTop: 5,
    borderTopWidth: 0.75,
    borderTopColor: "rgb(25, 25, 25, 0.1)",
    borderTopStyle: "solid",
  },
  submittedInfo: {
    marginTop: 10,
  },
});

const BULLET = "\u2022";

const UnorderedListItem = ({ children }) => {
  return (
    <View style={styles.listItem}>
      <Text>
        {BULLET}&nbsp;<Text>{children}</Text>
      </Text>
    </View>
  );
};

const UnorderedList = ({ children }) => {
  return <View style={styles.list}>{children}</View>;
};

const formatDate = createDateTimeFormatter();

const FormResponsePDF = props => {
  const { formResponse, language, contact } = props;
  const agentName = formResponse.hidden.agent_name;
  let fontFamily, regularSrc, boldSrc;
  if (language === EMAIL_URL_LANGS.THAI) {
    fontFamily = "Sarabun";
    regularSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/Sarabun/Sarabun-Regular.ttf";
    boldSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/Sarabun/Sarabun-Bold.ttf";
  } else if (language === EMAIL_URL_LANGS.JAPANESE) {
    fontFamily = "Noto Sans JP";
    regularSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/MPLUSRounded1c/MPLUSRounded1c-Regular.ttf";
    boldSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/MPLUSRounded1c/MPLUSRounded1c-Bold.ttf";
  } else {
    fontFamily = "Open Sans";
    regularSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/OpenSans/OpenSans-Regular.ttf";
    boldSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/OpenSans/OpenSans-Bold.ttf";
  }
  Font.register({
    family: fontFamily,
    format: "truetype",
    fonts: [
      {
        src: regularSrc,
      },
      {
        src: boldSrc,
        fontWeight: 700,
      },
    ],
  });

  styles.body.fontFamily = fontFamily;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header}>{formResponse.title}</Text>
        {contact ? (
          <View>
            <Text>
              {contact.full_name} ({contact.email})
            </Text>
          </View>
        ) : null}
        {formResponse.data.map((question, index) => (
          <View key={index} style={styles.row}>
            <View style={styles.question}>
              <Text>{question.title}</Text>
            </View>
            <View style={styles.answer}>{answerByType(question)}</View>
          </View>
        ))}
        <View style={styles.submittedInfo}>
          <Text>Submitted at: {formatDate(formResponse.submitted_at)}</Text>
          {agentName && <Text>Submitted by: {agentName}</Text>}
        </View>
      </Page>
    </Document>
  );
};

const answerByType = answer => {
  switch (answer.type) {
    case "DatePicker":
    case "Dropdown":
    case "RadioButtonGroup":
    case "TextBox":
    case "TextInput":
      return <Text>{answer[answer.valKey]}</Text>;
    case "Upload":
      return (
        <View>
          {answer.uploadType === "Image" ? (
            <Image src={answer.url} wrapped ui={false} />
          ) : null}
          <Link src={answer.url} style={styles.link}>
            {answer.uploadFileName}
          </Link>
        </View>
      );
    case "TableV1": {
      let headers = answer.data.columns.filter(
        data => data.dataPath !== "delete"
      );
      let rows = answer.data.rows;
      let data = {
        headers: headers.map(h => h.label),
        answers: rows.map(row =>
          headers.map(h => {
            return row[h.dataPath] || "";
          })
        ),
      };

      return (
        <View>
          {data.answers.map((answerRow, index) => (
            <View key={index} style={index > 0 ? styles.tableAnswer : null}>
              {answerRow.map((answer, idx) => (
                <Text key={idx}>
                  {data.headers[idx]}: {answer}
                </Text>
              ))}
            </View>
          ))}
        </View>
      );
    }
    case "PleteoUpload":
      return <PleteoUpload activityLogId={answer.activityLogId} />;
    case "CheckboxButtonGroup":
      return answer.checked !== null ? (
        <UnorderedList>
          {answer.checked.map((val, i) => (
            <UnorderedListItem key={i}>{val}</UnorderedListItem>
          ))}
        </UnorderedList>
      ) : (
        <Text>---</Text>
      );
    default:
      return <Text>{answer.type} not yet supported in PDF generation</Text>;
  }
};

const PleteoUpload = props => {
  const [attachments, setAttachments] = useState([]);
  useEffect(() => {
    const fetchActivityLog = async () => {
      if (props.activityLogId !== null) {
        const activityLog = await ActivityService.getActivityLog(
          "Contact",
          props.activityLogId
        );
        setAttachments(activityLog.attachments);
      }
    };

    fetchActivityLog().catch(console.error);
  }, []);

  return (
    <UnorderedList>
      {attachments.map(attachment => (
        <UnorderedListItem key={attachment.id}>
          <Link src={attachment.url}>{attachment.file_name}</Link>
        </UnorderedListItem>
      ))}
    </UnorderedList>
  );
};

export const generatePdfDocument = async (formResponse, language, contact) => {
  const blob = await pdf(
    <FormResponsePDF
      formResponse={formResponse}
      language={language}
      contact={contact}
    />
  ).toBlob();
  const filename = [
    formResponse.title,
    contact && contact.email,
    formResponse.submitted_at,
  ]
    .filter(el => !!el)
    .join("-")
    .replace(/\s/g, "_");
  saveAs(blob, `${filename}.pdf`);
};

const taggableModels = {
  all_models: "All Models",
  campaign_target_email: "CampaignTargetEmail",
  campaign_target_call: "CampaignTargetCall",
  case: "Case",
  contact: "Contact",
  data_job: "DataJob",
  email_footer: "EmailFooter",
  email_signature: "EmailSignature",
  email_template: "EmailTemplate",
  email_url: "EmailUrl",
  entity: "Entity",
  lead_contact: "LeadContact",
  lead_entity: "LeadEntity",
  revenue_opportunity: "RevenueOpportunity",
  machine: "Machine",
  task: "Task",
};

const metadataTypes = {
  text: "text",
  number: "number",
  boolean: "boolean",
  categorical: "categorical",
  list: "list",
  date: "date",
};

const noTag = {
  color: "#ffffff",
  name: "No Tags",
  disableClick: true,
  enabled: true,
};

const tagConstants = {
  taggableModels,
  metadataTypes,
  noTag,
};

export default tagConstants;
export { noTag };

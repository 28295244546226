import React, { useEffect, useRef, useState } from "react";
import { Form, List } from "semantic-ui-react";
import Input from "./Input";

const ChangePasswordForm = ({
  password,
  newPassword,
  confirmPassword,
  setPassword,
  setNewPassword,
  setConfirmPassword,
  onSubmit,
}) => {
  const [passwordVisible] = useState(!password);
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  useEffect(() => {
    if (passwordVisible) {
      currentPasswordRef.current.focus();
    } else {
      newPasswordRef.current.focus();
    }
  }, [passwordVisible]);
  const [passwordValidation, setPasswordValidation] = useState({
    passwordLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasSpecialCharacters: false,
    hasNumber: false,
  });

  const handlePasswordValidation = (_, { value }) => {
    setNewPassword(value);
    const lengthRegex = /^.{8,}$/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharactersRegex = /[!@#$%^*]/;
    const numberRegex = /\d/;
    setPasswordValidation({
      passwordLength: lengthRegex.test(value),
      hasUppercase: uppercaseRegex.test(value),
      hasLowercase: lowercaseRegex.test(value),
      hasSpecialCharacters: specialCharactersRegex.test(value),
      hasNumber: numberRegex.test(value),
    });
  };

  return (
    <Form>
      <p style={{ marginTop: 0 }}>You need to change your password.</p>
      <Input
        label="Current Password"
        type="password"
        name="password"
        value={password}
        visible={passwordVisible}
        ref={currentPasswordRef}
        onChange={(_, { value }) => setPassword(value)}
        onSubmit={onSubmit}
      />
      <Input
        label="New Password"
        type="password"
        name="newPassword"
        value={newPassword}
        ref={newPasswordRef}
        onChange={handlePasswordValidation}
        onSubmit={onSubmit}
      />
      <Input
        label="Confirm New Password"
        type="password"
        name="confirmPassword"
        value={confirmPassword}
        onChange={(_, { value }) => setConfirmPassword(value)}
        onSubmit={onSubmit}
      />
      {newPassword.length > 0 && (
        <List>
          <List.Item
            className={
              passwordValidation.passwordLength
                ? " passwordItem--right"
                : " passwordItem--wrong"
            }
          >
            <List.Icon
              name={passwordValidation.passwordLength ? "check" : "times"}
            />
            <List.Content>8 characters or longer</List.Content>
          </List.Item>
          <List.Item
            className={
              passwordValidation.hasUppercase
                ? " passwordItem--right"
                : " passwordItem--wrong"
            }
          >
            <List.Icon
              name={passwordValidation.hasUppercase ? "check" : "times"}
            />
            <List.Content>1 uppercase</List.Content>
          </List.Item>
          <List.Item
            className={
              passwordValidation.hasLowercase
                ? " passwordItem--right"
                : " passwordItem--wrong"
            }
          >
            <List.Icon
              name={passwordValidation.hasLowercase ? "check" : "times"}
            />
            <List.Content>1 lowercase</List.Content>
          </List.Item>
          <List.Item
            className={
              passwordValidation.hasSpecialCharacters
                ? " passwordItem--right"
                : " passwordItem--wrong"
            }
          >
            <List.Icon
              name={passwordValidation.hasSpecialCharacters ? "check" : "times"}
            />
            <List.Content>1 special character (!@#$%^*)</List.Content>
          </List.Item>
          <List.Item
            className={
              passwordValidation.hasNumber
                ? " passwordItem--right"
                : " passwordItem--wrong"
            }
          >
            <List.Icon
              name={passwordValidation.hasNumber ? "check" : "times"}
            />
            <List.Content>1 number</List.Content>
          </List.Item>
          {confirmPassword.length > 0 && (
            <List.Item
              className={
                newPassword === confirmPassword
                  ? " passwordItem--right"
                  : " passwordItem--wrong"
              }
            >
              <List.Icon
                name={newPassword === confirmPassword ? "check" : "times"}
              />
              <List.Content>Passwords should match</List.Content>
            </List.Item>
          )}
          {password === newPassword && (
            <List.Item className={" passwordItem--wrong"}>
              <List.Icon name={"times"} />
              <List.Content>
                New password should be different from the previous one
              </List.Content>
            </List.Item>
          )}
        </List>
      )}
    </Form>
  );
};

export default ChangePasswordForm;

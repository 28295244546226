import React, { Component } from "react";
import { Modal, Dropdown } from "semantic-ui-react";
import EditRegionForm from "../forms/EditRegionForm";
import PropTypes from "prop-types";

export default class EditRegionModal extends Component {
  static propTypes = {
    fetchRegions: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSuccess = async () => {
    await this.props.fetchRegions(); // maybe-promise
    //this.handleClose();
  };

  render() {
    return (
      <Modal
        size="small"
        trigger={
          <Dropdown.Item onClick={this.handleOpen} content="Edit" icon="edit" />
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>Edit Region</Modal.Header>
        <Modal.Content>
          <EditRegionForm
            id={this.props.id}
            onSuccess={this.handleSuccess}
            onClose={this.handleClose}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

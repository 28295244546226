import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";

import PropTypes from "prop-types";
import EmailUrlService from "../../../services/EmailUrls";
import { EMAIL_URL_LANGS } from "../../../constants/Constants";
import { startCase } from "../../../helpers/string";

function EmailUrlForm(props) {
  const [formData, setFormData] = useState({
    name: "",
    url: "",
    is_form: false,
    clicky_id: "",
    clicky_key: "",
    entity_auto_tags: "",
    contact_auto_tags: "",
    notify_email: "",
    active: true,
    language: EMAIL_URL_LANGS.ENGLISH,
  });

  const fetchEmailUrl = useCallback(async emailUrlId => {
    const {
      id,
      name,
      url,
      is_form,
      entity_auto_tags,
      contact_auto_tags,
      notify_email,
      clicky_id,
      clicky_key,
      active,
      language,
    } = await EmailUrlService.getEmailUrl(emailUrlId);
    setFormData({
      id,
      name,
      url,
      is_form,
      entity_auto_tags,
      contact_auto_tags,
      notify_email,
      clicky_id,
      clicky_key,
      active,
      language,
    });
  }, []);

  useEffect(() => {
    if (props.emailUrlId) {
      fetchEmailUrl(props.emailUrlId);
    }
  }, [props.emailUrlId, fetchEmailUrl]);

  const handleChange = (event, data) => {
    const formDataCopy = { ...formData };
    formDataCopy[data.name] =
      data.type === "checkbox" ? data.checked : data.value;
    setFormData(formDataCopy);
  };

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const checkUrlProtocol = link => {
    if (link.search(/^http[s]?\:\/\//) == -1) {
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    if (!checkUrlProtocol(formData["url"])) {
      throw new Error(
        "Invalid URL format: Valid Example -> https://www.example.com"
      );
    }
    try {
      if (formData.id) {
        await EmailUrlService.editEmailUrl(formData.id, formData);
      } else {
        await EmailUrlService.createEmailUrl(formData);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  return (
    <RuvixxForm
      ready={!!formData.name && !!formData.url}
      onSubmit={handleSubmit}
      onSuccess={props.onSuccess}
    >
      <Form.Input
        inline
        required
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <Form.Input
        inline
        required
        label="URL"
        name="url"
        placeholder="https://www.example-url.com"
        value={formData.url}
        onChange={handleChange}
      />
      <Form.Checkbox
        toggle
        name="is_form"
        checked={formData.is_form}
        onChange={handleChange}
        label="Is Form?"
      />
      {formData.is_form
        ? [
            <Form.Input
              inline
              label="Entity Auto Tags"
              name="entity_auto_tags"
              placeholder="Ex. tag 1, tag 2"
              value={formData.entity_auto_tags}
              onChange={handleChange}
            />,
            <Form.Input
              inline
              label="Contact Auto Tags"
              name="contact_auto_tags"
              placeholder="Ex. tag 1, tag 2"
              value={formData.contact_auto_tags}
              onChange={handleChange}
            />,
            <Form.Input
              inline
              label="Notify Email"
              name="notify_email"
              value={formData.notify_email}
              onChange={handleChange}
            />,
            <Form.Select
              search
              clearable
              name="language"
              label="Language"
              value={formData.language}
              options={Object.keys(EMAIL_URL_LANGS).map(key => ({
                key: startCase(key),
                text: startCase(key),
                value: EMAIL_URL_LANGS[key],
              }))}
              onChange={handleSelect}
            />,
          ]
        : null}
      <Form.Input
        inline
        label="Clicky ID"
        name="clicky_id"
        value={formData.clicky_id}
        onChange={handleChange}
      />
      <Form.Input
        inline
        label="Clicky Key"
        name="clicky_key"
        value={formData.clicky_key}
        onChange={handleChange}
      />
      <Form.Checkbox
        toggle
        name="active"
        checked={formData.active}
        onChange={handleChange}
        label="Active"
      />
    </RuvixxForm>
  );
}

EmailUrlForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default EmailUrlForm;

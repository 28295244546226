import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { Checkbox, Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import store from "store";

import { NewCategoryModal } from "../../routes/campaign/components/Modals";
import ManageCategoriesModal from "../modals/ManageCategoriesModal";
import CompactForm from "./CompactForm";
import UploadManager from "../UploadManager";
import RichTextEditor from "../RichEditor/RichTextEditor";
import ActivityService from "../../services/Activity";
import FilePicker from "../FilePicker";
import UploadProgressRing from "../UploadProgressRing";

function NoteForm(props) {
  const {
    note,
    modelId,
    modelType,
    callSid,
    refersToModel,
    refersToID,
    onSuccess,
    categories,
    updateCategories,
  } = props;
  const [formData, _setFormData] = useState({
    categoryId: null,
    files: [],
    includeInEntity: false,
    mentionedUsers: [],
  });
  const [progressListenerId, setProgressListenerId] = useState();
  const uploadManager = useMemo(() => new UploadManager(), []);
  const [editorHasText, setEditorHasText] = useState(false);

  const editor = useRef();

  const setFormData = useCallback(newFormData => {
    _setFormData(formData => ({
      ...formData,
      ...newFormData,
    }));
  }, []);

  const fetchNewCategories = async () => {
    const rawCategories = await ActivityService.getCategories();
    const newCategories = rawCategories.map(category => ({
      key: category.id,
      text: category.name,
      value: category.id,
      hidden: category.is_hidden,
    }));
    updateCategories(newCategories);
  };

  useEffect(() => {
    if (note && note.id) {
      if (note.editor_state) {
        editor.current.importState(JSON.parse(note.editor_state));
      } else {
        editor.current.importStateFromHtml(note.info.comment);
      }
      setFormData({
        categoryId: note.category_id,
        includeInEntity: note.info.include_in_entity,
        mentionedUsers: note.info.mentioned_users || [],
        files: [], // Allow editing/removing existing attachments? We currently only allow adding more files to the same comment
      });
    }
  }, [note]);

  useEffect(() => {
    const listenerId = uploadManager.addProgressListener(handleFileProgress);
    setProgressListenerId(listenerId);

    return function cleanup() {
      uploadManager.removeProgressListener(progressListenerId || 0);
    };
  }, [uploadManager]);

  useEffect(() => {
    fetchNewCategories();
  }, []);

  const handleEditorStateChange = ({ hasText }) => {
    setEditorHasText(hasText);
  };

  const handleAddMention = mentionedUser => {
    setFormData({
      mentionedUsers: [...formData.mentionedUsers, mentionedUser],
    });
  };

  const handleFileProgress = files => {
    setFormData({
      files: [...files],
    });
  };

  const handleToggle = (_, { name, checked }) => {
    setFormData({
      [name]: checked,
    });
  };

  const handleSelect = (_, { name, value }) => {
    if (name === "categoryId" && value === "") {
      value = null;
    }
    setFormData({
      [name]: value,
    });
  };

  const handleFileChange = async files => {
    setFormData({
      files,
    });
    await uploadManager.uploadFiles(files);
  };

  const resetForm = () => {
    const emptyFormData = {
      categoryId: null,
      files: [],
      includeInEntity: false,
      mentionedUsers: [],
    };
    setFormData(emptyFormData);
    editor.current.resetState();
  };

  const handleSubmit = async () => {
    const editorState = JSON.stringify(editor.current.exportState());
    const plainText = editor.current.exportStateAsPlain();
    const richText = editor.current.exportStateAsHtml();
    const data = {
      model_id: modelId,
      model_type: modelType,
      editor_state: editorState,
      plain_text: plainText,
      rich_text: richText,
      category_id: formData.categoryId,
      info: {
        refers_to_model: refersToModel,
        refers_to_id: refersToID,
        include_in_entity: formData.includeInEntity,
        call_sid: callSid,
      },
      creator_user_id: store.get("userAuth").id,
      attachments: formData.files.map(f => {
        if (f.attachment) {
          return f.attachment;
        }
      }),
    };

    // try {
    if (note && note.id) {
      await ActivityService.updateActivityLog(note.id, data);
    } else {
      await ActivityService.createActivityLog(data);
    }

    resetForm();
  };

  return (
    <CompactForm
      ready={editorHasText}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      successHeader="Note Created"
      successMessage="Successfully created a new Note"
    >
      <Form.Field>
        <RichTextEditor
          ref={editor}
          onAddMention={handleAddMention}
          onStateChange={handleEditorStateChange}
        />
      </Form.Field>
      <Form.Group>
        <Form.Field inline>
          <label style={{ minWidth: "100px" }}>Category</label>
          <Form.Select
            clearable
            name={"categoryId"}
            value={formData.categoryId}
            options={categories}
            placeholder="Category"
            onChange={handleSelect}
            style={{ minWidth: "250px" }}
          />
        </Form.Field>
        <Form.Field inline>
          <NewCategoryModal margin={0} fetchCategories={fetchNewCategories} />
        </Form.Field>
        <ManageCategoriesModal
          categories={categories}
          fetchCategories={fetchNewCategories}
        />
      </Form.Group>
      {modelType === "Contact" && (
        <Form.Field>
          <Checkbox
            toggle
            name="includeInEntity"
            checked={formData.includeInEntity}
            onChange={handleToggle}
            className="right"
            label="Include in Entity"
          />
        </Form.Field>
      )}
      <FilePicker onSelect={handleFileChange} multiple className="fileButton" />
      <UploadProgressRing
        uploads={formData.files}
        onDelete={uploadManager.removeUpload}
        xOnComplete={true}
      />
      <dl>
        {formData.files.map(({ name }, index) => (
          <dd key={index}>{name}</dd>
        ))}
      </dl>
    </CompactForm>
  );
}

NoteForm.propTypes = {
  modelType: PropTypes.string.isRequired,
  modelId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  updateCategories: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.object),
  refersToModel: PropTypes.string,
  refersToID: PropTypes.number,
  callSid: PropTypes.string,
};

NoteForm.defaultProps = {
  categories: [],
};

export default NoteForm;

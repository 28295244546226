import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import PhoneNumbers from "../../../components/PhoneNumbers";
import RuvixxForm from "../../../components/RuvixxForm";
import GroupService from "../../../services/Group";
import RegionService from "../../../services/Regions";
import RoleService from "../../../services/Role";
import UserService from "../../../services/User";

const UserForm = ({ user = {}, onSuccess, onCancel, submitButtonText }) => {
  const [fullName, setFullName] = useState(user.full_name || "");
  const [email, setEmail] = useState(user.email || "");
  const [phoneNumbers, setPhoneNumbers] = useState(
    user.phone_numbers ? user.phone_numbers || [] : []
  );
  const [role, setRole] = useState(user.role && user.role.id);
  const [external, setExternal] = useState(user.external || false);
  const [groupId, setGroupId] = useState(user.group_id);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [title, setTitle] = useState(user.title || "");
  const [region, setRegion] = useState(user.region_id);
  const [errors, setErrors] = useState({});

  const fetchRoles = useCallback(async () => {
    const roles = await RoleService.getRoles();
    const options = roles.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
    setRolesOptions(options);
  }, []);

  const fetchRegions = useCallback(async () => {
    const regions = await RegionService.getRegions();
    const options = regions.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));
    setRegionsOptions(options);
  }, []);

  const fetchGroups = useCallback(async () => {
    const groups = await GroupService.getForFilters();
    const options = groups.map(({ id, name, internal }) => ({
      key: id,
      value: id,
      text: name,
      data: {
        internal,
      },
    }));
    setGroupOptions(options);
  }, []);

  useEffect(() => {
    fetchRoles();
    fetchRegions();
    fetchGroups();
  }, [fetchRoles, fetchRegions]);

  const addPhoneNumber = (pn, error) => {
    if (error) {
      let errorsCopy = errors;
      errorsCopy["number"] = error.message;
      setErrors(errorsCopy);
      return;
    }
    const isDuplicate =
      phoneNumbers.filter(p => {
        return p === pn;
      }).length > 0;
    if (!isDuplicate) {
      let phoneNumbersCopy = phoneNumbers;
      phoneNumbersCopy.push(pn);
      setPhoneNumbers(phoneNumbersCopy);
      setErrors({});
    }
  };

  const removePhoneNumber = index => {
    let phoneNumbersCopy = [...phoneNumbers];
    phoneNumbersCopy.splice(index, 1);
    setPhoneNumbers(phoneNumbersCopy);
  };

  const updatePhoneNumber = (phoneNumber, index) => {
    let phoneNumbersCopy = [...phoneNumbers];
    phoneNumbersCopy[index] = phoneNumber;
    setPhoneNumbers(phoneNumbersCopy);
  };

  const handleSubmit = async () => {
    const nonEmptyPhoneNumbers = phoneNumbers.filter((pn, i) =>
      pn.number?.trim()
    );
    const data = {
      email,
      fullName,
      title,
      phoneNumbers: nonEmptyPhoneNumbers,
      role,
      region,
      groupId,
      external,
    };
    if ("id" in user) {
      await UserService.updateUser(user.id, data);
    } else {
      await UserService.createUser(data);
    }
  };

  return (
    <RuvixxForm
      ready={!!fullName && !!email && !!role && (!external || !!groupId)}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      onCancel={onCancel}
      submitButtonText={submitButtonText}
      cancelButtonText="Cancel"
    >
      <Form.Input
        required
        inline
        label="Name"
        className="fillSpace"
        value={fullName}
        onChange={(_, { value }) => setFullName(value)}
      />
      <Form.Input
        required
        inline
        label="Email"
        className="fillSpace"
        type="email"
        value={email}
        onChange={(_, { value }) => setEmail(value)}
      />
      <Form.Input
        inline
        label="Title"
        className="fillSpace"
        value={title}
        onChange={(_, { value }) => setTitle(value)}
      />
      <PhoneNumbers
        key={phoneNumbers}
        type={"user"}
        phoneNumbers={phoneNumbers}
        addPhoneNumber={addPhoneNumber}
        removePhoneNumber={removePhoneNumber}
        updatePhoneNumber={updatePhoneNumber}
        onValidationSuccess={onSuccess}
      />
      <Form.Select
        required
        inline
        className="fillSpace"
        search
        clearable
        label="Role"
        options={rolesOptions}
        value={role}
        onChange={(_, { value }) => setRole(value)}
      />
      <Form.Select
        inline
        className="fillSpace"
        search
        clearable
        label="Region"
        options={regionsOptions}
        value={region}
        onChange={(_, { value }) => setRegion(value || null)}
      />
      <Form.Checkbox
        toggle
        onChange={(_, { checked }) => {
          setGroupId(null);
          setExternal(checked);
        }}
        checked={external}
        label="External User"
      />
      {external && (
        <Form.Select
          required={external}
          inline
          className="fillSpace"
          search
          clearable
          label="Group"
          options={groupOptions.filter(opt =>
            !external ? opt.data.internal : !opt.data.internal
          )}
          value={groupId}
          onChange={(_, { value }) => setGroupId(value)}
        />
      )}
      <p>
        <span className="requiredStar" /> Indicates a required field
      </p>
    </RuvixxForm>
  );
};

export default UserForm;

import React from "react";
import { Checkbox, Container, Dropdown, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import BaseTable from "../../components/BaseTable";
import CustomDropdown from "../../components/CustomDropdown";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import setPageTitle from "../../helpers/title";
import AuthService from "../../services/Auth";
import SmtpAccountService from "../../services/SmtpAccounts";
import _SmtpAccountModal, {
  SmtpAccountDeleteModal as _SmtpAccountDeleteModal,
} from "./SmtpAccountModal";

const SmtpAccountModal = withRoleCheck(_SmtpAccountModal, [
  ACL_RELATIONSHIPS.emailConfigSmtp.create,
]);
const SmtpAccountEditModal = withRoleCheck(_SmtpAccountModal, [
  ACL_RELATIONSHIPS.emailConfigSmtp.edit,
]);
const SmtpAccountDeleteModal = withRoleCheck(_SmtpAccountDeleteModal, [
  ACL_RELATIONSHIPS.emailConfigSmtp.delete,
]);

class SmtpAccountsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = SmtpAccountService.getSmtpAccountsTable;

    this.state = {
      ...this.state,
      enableSearch: false,
      header: "SMTP Accounts",
      headerIcon: "mail",
      className: "smtp_accounts",
      tableName: "smtp_accounts",
      noDataText: "No SMTP Accounts found. Create one.",
      createButton: <SmtpAccountModal onSuccess={this.fetchData} />,
    };
  }

  async componentDidMount() {
    setPageTitle("SMTP Accounts");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
    }
  }

  handleSetAsDefault = async (configId, isDefault) => {
    // Display checked update immediately before fetching
    this.setState({
      rows: this.state.rows.map(row => {
        row.is_default = row.id === configId && isDefault ? isDefault : false;
        return row;
      }),
    });
    await SmtpAccountService.updateSmtpAccount(configId, { isDefault });
    await this.fetchData();
  };

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        width: 40,
        accessor: "id",
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Server",
        accessor: "server",
      },
      {
        Header: "Port",
        accessor: "port",
      },
      {
        Header: "Encryption",
        accessor: "encryption",
        Cell: ({ value: encryption }) =>
          encryption === "starttls" ? "STARTTLS" : "SSL/TLS",
      },
      {
        Header: "Set as Default",
        width: 100,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ original: row }) => (
          <Checkbox
            toggle
            name={`is_default_${row.id}`}
            checked={row.is_default}
            onChange={(_, { checked }) =>
              this.handleSetAsDefault(row.id, checked)
            }
          />
        ),
      },
      {
        Header: "Actions",
        id: "actions",
        width: 140,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ original: { id } }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <SmtpAccountEditModal configId={id} onSuccess={this.fetchData} />
              <SmtpAccountDeleteModal
                configId={id}
                onSuccess={this.fetchData}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default SmtpAccountsTable;

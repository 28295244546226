import React, { createRef } from "react";
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Icon,
  Segment,
} from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";

import BaseTable from "../../components/BaseTable";
import ColorPicker from "../../components/ColorPicker";
import CustomDropdown from "../../components/CustomDropdown";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import FormModal from "../../components/modals/FormModal";
import RuvixxDate from "../../components/RuvixxDate";
import tagConstants from "../../constants/Tag";
import setPageTitle from "../../helpers/title";
import AuthService from "../../services/Auth";
import TagService from "../../services/Tag";
import TagCategoryService from "../../services/TagCategory";
import BulkUpdateCategoryModal from "./components/BulkUpdateCategoryModal";
import TagForm from "./components/TagForm";

const CreateTagModal = withRoleCheck(FormModal, [ACL_RELATIONSHIPS.tag.create]);
const EditTagModal = withRoleCheck(FormModal, [ACL_RELATIONSHIPS.tag.edit]);
const CloneTagModal = withRoleCheck(FormModal, [ACL_RELATIONSHIPS.tag.create]);
const DeleteTagModal = withRoleCheck(ConfirmationModal, [
  ACL_RELATIONSHIPS.tag.delete,
]);

const BulkUpdateCategoryButton = withRoleCheck(Button, [
  ACL_RELATIONSHIPS.tagBulkUpdateCategory.edit,
]);

class TagManagementTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = TagService.getTagsTable;

    this.updateModal = createRef();
    this.deleteModal = createRef();
    this.bulkUpdateCategoryModal = createRef();

    this.state = {
      ...this.state,
      header: "Tag Management",
      headerIcon: "tags",
      className: "TagManagement",
      tableName: "tag_management",
      noDataText: "No Tags found. Try adjusting your filters.",
      createButton: (
        <CreateTagModal
          size={"large"}
          onSuccess={this.fetchData}
          modelType={"Tag"}
          model={props.original}
          FormComponent={TagForm}
        />
      ),
      tagCategories: [],
    };
  }

  async componentDidMount() {
    setPageTitle("Tag Management");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      this.fetchTagCategories();
      this.updateFilterOptions();
    }
  }

  fetchTagCategories = async () => {
    const tagCategories = await TagCategoryService.getTagCategoryForFilters();
    this.setState({ tagCategories }, this.updateFilterOptions);
  };

  setColumns = () => {
    const columns = [
      {
        Header: props => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        accessor: this.state.primaryKey,
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: props => (
          <Checkbox
            onChange={this.handleChange}
            name={props.original.name}
            id={props.value}
            checked={this.state.checked[props.value]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Color",
        accessor: "color",
        width: 80,
        headerClassName: "non-sortable",
        sortable: false,
        Cell: ({ value }) => <ColorPicker color={value} size="small" />,
      },
      {
        Header: "Taggable Models",
        accessor: "taggable_models",
        Cell: ({ value }) =>
          value.map(key => tagConstants.taggableModels[key]).join(", "),
      },
      {
        Header: "Roles",
        sortable: false,
        accessor: "required_roles",
        Cell: ({ value }) =>
          value.length > 0
            ? value.map(role => role["name"]).join(", ")
            : "All Roles",
      },
      {
        Header: "Enabled",
        accessor: "enabled",
        headerClassName: "centered",
        className: "centered",
        Cell: ({ value }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Icon name={value ? "check" : "close"} />
            </div>
          );
        },
      },
      {
        Header: "Category",
        accessor: "tag_category.name",
      },
      {
        Header: "Created By",
        accessor: "created_by_user.full_name",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => <RuvixxDate date={value} />,
      },
      {
        Header: "Updated By",
        accessor: "updated_by_user.full_name",
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        Cell: ({ value }) => <RuvixxDate date={value} />,
      },
      {
        Header: "Actions",
        id: "actions",
        headerClassName: "centered non-sortable",
        className: "centered action-menu",
        sortable: false,
        minWidth: 60,
        Cell: ({ original }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditTagModal
                size="large"
                dropdown
                onSuccess={this.fetchData}
                modelType="Tag"
                model={original}
                FormComponent={TagForm}
              />
              <CloneTagModal
                size="large"
                dropdown
                onSuccess={this.fetchData}
                modelType="Tag"
                model={original}
                FormComponent={TagForm}
                actionText="Clone Tag"
                icon="clone outline"
                formProps={{ isClone: true }}
              />
              <DeleteTagModal
                actionDescription="Delete Tag"
                buttonColor="grey"
                onConfirm={() => this.deleteTag(original.id)}
                menuTrigger
                icon="trash"
                warning
              >
                <p>Are you sure you want to delete this Tag?</p>
              </DeleteTagModal>
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  updateFilterOptions() {
    const models = Object.entries(tagConstants.taggableModels).map(
      ([key, value]) => ({
        id: key,
        name: value,
      })
    );
    const filters = [
      {
        key: "taggable_models",
        title: "Taggable Models",
        type: "select",
        data: models,
      },
      {
        key: "category_ids",
        title: "Category",
        type: "select",
        data: this.state.tagCategories,
      },
    ];
    this.setState({ filters });
  }

  actionsDropdown() {
    return (
      <>
        <Dropdown
          text="Actions"
          className="button mini"
          disabled={this.state.checkedArr.length === 0}
        >
          <Dropdown.Menu direction="left">
            <DeleteTagModal
              actionDescription="Delete Tag(s)"
              buttonColor="grey"
              onConfirm={() => this.bulkDeleteTag(this.state.checkedArr)}
              menuTrigger
              warning
            >
              <p>
                Are you sure you want to bulk delete{" "}
                {this.state.checkedArr.length} tag(s)?
              </p>
            </DeleteTagModal>
            <Dropdown.Item
              onClick={() => {
                this.bulkUpdateCategoryModal.current.open(
                  this.state.checkedArr
                );
              }}
              content="Bulk Update Category"
            />
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  deleteTag = async id => {
    await TagService.deleteTag(id);
    this.fetchData();
  };

  bulkDeleteTag = async ids => {
    await TagService.bulkDeleteTags(ids);
    this.fetchData();
  };

  render() {
    return (
      <Container fluid className="route sub-route" {...this.props}>
        {/*<UpdateTagModal ref={this.updateModal} refreshTable={this.fetchData} />*/}
        {/*<DeleteTagModal ref={this.deleteModal} refreshTable={this.fetchData} />*/}
        <BulkUpdateCategoryModal
          ref={this.bulkUpdateCategoryModal}
          refreshTable={this.fetchData}
        />
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default TagManagementTable;

import React, { useEffect, useRef, useState } from "react";
import { Form, Segment } from "semantic-ui-react";
import countryOptions from "../../Countries";
import SearchLocationInput from "../SearchLocationInput";

function AddressForm({
  address = {},
  updateAddress,
  touched,
  setTouched,
  showLatLong = false,
}) {
  const [formData, setFormData] = useState({
    id: address.id,
    street1: address.street1 || null,
    street2: address.street2 || "",
    city: address.city || "",
    region: address.region || "",
    postal_code: address.postal_code || "",
    country: address.country || "",
    latitude: address.latitude || null,
    longitude: address.longitude || null,
    info: address.info,
  });
  const [timeoutId, setTimeoutId] = useState(0);

  const handleChange = (_, data) => {
    const formDataCopy = { ...formData };
    let value = data.type === "checkbox" ? data.checked : data.value;
    if (value === "") {
      value = null;
    }
    formDataCopy[data.name] = value;
    setFormData(formDataCopy);
    delayUpdateAddress(formDataCopy);
  };

  const handleSelect = (e, { name, value }) => {
    if (name === undefined) return;
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
    delayUpdateAddress(formDataCopy);
  };

  const handleAddressChange = (e, newFormData) => {
    if (!touched) {
      setTouched();
    }
    updateAddress(newFormData || formData); // maybe-promise
  };

  const delayUpdateAddress = newFormData => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(
      setTimeout(function () {
        handleAddressChange(null, newFormData);
      }, 500)
    );
  };

  const setAddressFields = ({
    street1,
    street2,
    city,
    region,
    country,
    postal_code,
    latitude,
    longitude,
    info,
  }) => {
    const newFormData = {
      ...formData,
      street1,
      street2,
      city,
      region,
      country,
      postal_code,
      latitude,
      longitude,
      info,
    };
    setFormData(newFormData);
    delayUpdateAddress(newFormData);
  };

  return (
    <Segment>
      <Form>
        <SearchLocationInput
          label={"Street 1"}
          inline
          required
          value={formData.street1}
          onChange={setAddressFields}
          address={address}
          country={formData.country}
        />
        <Form.Input
          inline
          name="street2"
          label="Street 2"
          style={{ paddingRight: "1.5em" }}
          width={8}
          value={formData.street2}
          onChange={handleChange}
          onBlur={handleAddressChange}
        />
        <Form.Group widths="equal">
          <Form.Input
            required
            inline
            name="city"
            label="City"
            value={formData.city}
            onChange={handleChange}
            onBlur={handleAddressChange}
          />
          <Form.Input
            required
            inline
            name="postal_code"
            label="Postal Code"
            value={formData.postal_code}
            onChange={handleChange}
            onBlur={handleAddressChange}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            required
            inline
            name="region"
            label="Region"
            value={formData.region}
            onChange={handleChange}
            onBlur={handleAddressChange}
          />
          <Form.Select
            inline
            required
            clearable
            label="Country"
            search
            name="country"
            options={countryOptions}
            value={formData.country}
            onChange={handleSelect}
            onBlur={handleAddressChange}
          />
        </Form.Group>
        {showLatLong && (
          <Form.Group widths="equal">
            <Form.Input
              inline
              name="latitude"
              label="Latitude"
              value={formData.latitude}
              onChange={handleChange}
              onBlur={handleAddressChange}
            />
            <Form.Input
              inline
              name="longitude"
              label="Longitude"
              value={formData.longitude}
              onChange={handleChange}
              onBlur={handleAddressChange}
            />
          </Form.Group>
        )}
      </Form>
    </Segment>
  );
}

export default AddressForm;

import React from "react";
import { List } from "semantic-ui-react";
import { Link } from "react-router-dom";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";
import "./../../../styles/campaign.scss";
import "./../../../styles/table.scss";

import CallQueueService from "../../../services/CallQueue";
import BaseTable from "../../../components/BaseTable";
import AuthService from "../../../services/Auth";
import {
  formatDate,
  capitalize,
  tableTimestamp,
} from "../../../components/helpers";
import TagList from "../../../components/TagList";
import { EPOCH_TIMESTAMP } from "../../../constants/Constants";

const LastEventCell = ({ action, date }) => {
  if (!action || !date) {
    return <p>No events</p>;
  }

  return (
    <p>
      {capitalize(action)} in {formatDate(date)}
    </p>
  );
};

class ContactsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CallQueueService.getContacts;

    this.state = {
      ...this.state,
      className: "Contact",
      tableName: "dial_session_contacts",
      noDataText: "No Contacts found.",
      filters: [],
      enableSearch: false,
      createButton: null,
      enableTags: false,
      useParamManager: false,
      defaultFilterParams: {
        dial_session_id: this.props.dialSessionId,
        total: this.props.total,
        urgent: this.props.isUrgent,
        callable: this.props.isCallable,
      },
      exportTableName: "DialSessionContactsTable",
    };
  }

  async componentDidMount() {
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        Cell: props => (
          <Link to={"/contacts/" + props.original.contact.id}>
            {props.original.contact.id}
          </Link>
        ),
      },
      {
        Header: "Name",
        accessor: "contact.full_name",
        Cell: props => (
          <Link to={"/contacts/" + props.original.contact.id}>
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Contact Alternate Name",
        accessor: "contact.alternate_names",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <List horizontal bulleted style={{ display: "flex" }}>
            {props.value.map(({ name }) => (
              <List.Item>{name}</List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Entity",
        accessor: "entity.name",
        Cell: props => (
          <Link to={"/entities/" + props.original.entity.id}>
            {props.value}
          </Link>
        ),
      },
      {
        Header: "Email",
        accessor: "contact.email",
      },
      {
        Header: "Alternate Emails",
        accessor: "contact.email_addresses",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <List horizontal bulleted style={{ display: "flex" }}>
            {props.value
              .filter(email => !email.is_primary)
              .map(email => (
                <List.Item key={email.id}>{email.email}</List.Item>
              ))}
          </List>
        ),
      },
      {
        Header: "Title",
        accessor: "contact.title",
      },
      {
        Header: "Phone Numbers",
        id: "phone_numbers",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <List horizontal bulleted>
            {props.original.contact.phone_numbers.map(phone => (
              <List.Item key={phone.id}>
                {phone.ext ? `${phone.number}x${phone.ext}` : phone.number}
              </List.Item>
            ))}
          </List>
        ),
      },
      {
        Header: "Timezone",
        accessor: "timezone",
      },
      {
        Header: "Last Called At",
        accessor: "last_called_at",
        Cell: ({ value }) => {
          if (value === EPOCH_TIMESTAMP) {
            return null;
          }
          return tableTimestamp(value);
        },
      },
      {
        Header: "Last Disposition",
        accessor: "last_call_disposition",
      },
      {
        Header: "Callback Time",
        accessor: "callback_time",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Contact Tags",
        accessor: "contact.tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Entity Tags",
        accessor: "entity.tags",
        sortable: false,
        headerClassName: "non-sortable",
        Cell: props => (
          <TagList
            tags={props.value}
            modelType={this.state.className}
            modelId={props.original.id}
            onUpdate={this.fetchData}
            tableCell
          />
        ),
      },
      {
        Header: "Last Email Event",
        id: "last_email_event",
        sortable: false,
        Cell: ({ original: { last_email_action, last_email_date } }) => (
          <LastEventCell action={last_email_action} date={last_email_date} />
        ),
      },
      {
        Header: "Last Form Event",
        id: "last_form_event",
        sortable: false,
        Cell: ({ original: { last_form_action, last_form_date } }) => (
          <LastEventCell action={last_form_action} date={last_form_date} />
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return <div>{this.renderTable()}</div>;
  }
}

export default ContactsTable;

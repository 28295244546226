import React, { Component } from "react";
import { Modal, Dropdown, Button } from "semantic-ui-react";
import CampaignScheduleForm from "../forms/CampaignScheduleForm";
import PropTypes from "prop-types";

class ScheduleModal extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    id: PropTypes.string,
    status: PropTypes.string,
    emailDeliveryTime: PropTypes.string,
    template_name: PropTypes.string,
    senderName: PropTypes.string,
    fromAddress: PropTypes.string,
    scheduleId: PropTypes.number,
    fetchData: PropTypes.func,
    isClone: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSuccess = async () => {
    this.props.fetchData();
  };

  render() {
    return (
      <Modal
        trigger={
          this.props.scheduleId ? (
            this.props.isClone ? (
              <Dropdown.Item
                onClick={this.handleOpen}
                content="Clone"
                icon={this.props.noIcon ? null : "clone"}
              />
            ) : (
              <Dropdown.Item
                onClick={this.handleOpen}
                content="Edit"
                icon={this.props.noIcon ? null : "edit"}
              />
            )
          ) : (
            <Button
              size="tiny"
              className="item-adder"
              content="New Schedule"
              onClick={this.handleOpen}
            />
          )
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="fullscreen"
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header
          icon="calendar alternate outline"
          content={`${
            this.props.scheduleId
              ? this.props.isClone
                ? "Clone"
                : "Edit"
              : "New"
          } Schedule`}
        />
        <Modal.Content>
          <CampaignScheduleForm
            onSuccess={this.handleSuccess}
            onClose={this.handleClose}
            campaignId={this.props.campaignId}
            newSchedule={this.props.newSchedule}
            scheduleId={this.props.scheduleId}
            isClone={this.props.isClone}
            closeParentModal={this.handleClose}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default ScheduleModal;

import CONSTANTS from "../constants/Constants";
import { applyFilters, doRequest } from "./helpers";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

export default class EntityService {
  static async getEntities(filters = null) {
    let path = "/entity/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getEntitiesForFilters(filters = null) {
    let path = "/entity/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getEntity(id) {
    return doRequest("GET", `/entity/${id}`);
  }

  static async deleteEntity(id) {
    return doRequest("DELETE", `/entity/${id}`);
  }

  static async createEntity(
    name,
    urls,
    external_cc_emails,
    addresses,
    contacts,
    custom,
    account_managers,
    alternateNames,
    region_id,
    country
  ) {
    const data = {
      name,
      urls,
      external_cc_emails,
      addresses,
      contacts,
      account_managers,
      custom_fields: custom,
      alternate_names: alternateNames,
      region_id: region_id || null,
      country: country || null,
    };
    return doRequest("POST", "/entity/", { data: omitBy(data, isNil) });
  }

  static async editEntity(
    id,
    name,
    urls,
    external_cc_emails,
    addresses,
    custom,
    account_managers,
    alternateNames,
    region_id,
    country
  ) {
    const data = {
      name,
      urls,
      external_cc_emails,
      addresses,
      account_managers,
      custom_fields: custom,
      alternate_names: alternateNames,
      region_id: region_id || null,
      country: country || null,
    };
    return doRequest("PUT", `/entity/${id}`, { data: omitBy(data, isNil) });
  }

  static async addAccountManager(entityId, userId) {
    return doRequest("POST", `/entity/${entityId}/account_manager/${userId}`);
  }

  static async deleteAccountManager(entityId, userId) {
    return doRequest("DELETE", `/entity/${entityId}/account_manager/${userId}`);
  }

  static async addContact(entityId, data) {
    return doRequest("POST", `/entity/${entityId}/contact/`, {
      data: omitBy(data, isNil),
    });
  }

  static async editContact(entityId, contactId, data) {
    return doRequest("PUT", `/entity/${entityId}/contact/${contactId}`, {
      data: omitBy(data, isNil),
    });
  }

  static async transferContacts(entityId, contact_ids) {
    return doRequest("PUT", `/entity/${entityId}/contact/transfer_contacts`, {
      data: { contact_ids },
    });
  }

  static async deleteContact(entityId, contactId) {
    return doRequest("DELETE", `/entity/${entityId}/contact/${contactId}`);
  }

  static async getContacts(id) {
    return doRequest("GET", `/entity/${id}/contact/`);
  }

  static async search(query, id) {
    return doRequest("GET", `/entity/${id}/contact/search/${query}`);
  }

  static async getCustomFields() {
    let path = `/entity/custom_fields`;
    return doRequest("GET", path);
  }

  static async getEmails(entity_id, filters = null) {
    let path = `/entity/${entity_id}/emails/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getCampaigns(entity_id) {
    return doRequest("GET", `/entity/${entity_id}/campaigns`);
  }

  static async getCampaignTable(entity_id) {
    return doRequest("GET", `/entity/${entity_id}/campaign_table`);
  }

  static async getDepartmentsTable(entityId) {
    return doRequest("GET", `/entity/${entityId}/departments`);
  }

  static async assignDepartment(entityId, departmentId) {
    return doRequest("POST", `/entity/${entityId}/department/${departmentId}`);
  }

  static async updateDepartment(entityId, departmentId, name, contacts) {
    return doRequest("PUT", `/entity/${entityId}/department/${departmentId}`, {
      data: {
        name,
        contacts,
      },
    });
  }

  static async deleteDepartment(entityId, departmentId) {
    return doRequest(
      "DELETE",
      `/entity/${entityId}/department/${departmentId}`
    );
  }

  static async sendEmail({
    entityId,
    template,
    attachments,
    senderName,
    cc,
    bcc,
    ccAccountManagers,
    ccExternalAccountManagers,
    fromUser,
    smtp_config_id,
    provider,
    footerId,
    signatureId,
    campaignId,
    follow_up,
  }) {
    return doRequest("POST", `/entity/${entityId}/email`, {
      data: {
        status: CONSTANTS.EMAIL_STATUS.PENDING,
        template_id: template,
        sender_name: senderName,
        cc,
        bcc,
        cc_account_managers: ccAccountManagers,
        cc_external_account_managers: ccExternalAccountManagers,
        attachments,
        from_user: fromUser,
        provider,
        footer_id: footerId || null,
        signature_id: signatureId,
        ...(smtp_config_id && { smtp_config_id }),
        campaign_id: campaignId,
        follow_up,
      },
    });
  }

  static async mergeEntitiesCheck(data) {
    return doRequest("POST", "/entity/merge/check", { data });
  }

  static async mergeEntities(data) {
    return doRequest("POST", "/entity/merge", { data });
  }
}

import React, { useState, useRef, createRef, useEffect } from "react";
import DownloadAttachments from "./DownloadAttachments";
import PleteoUpload from "./PleteoUpload";
import { formatDate } from "../helpers";
import {
  Button,
  Card,
  Header,
  Icon,
  Image,
  List,
  Table,
} from "semantic-ui-react";
import { generatePdfDocument } from "./v3FormResponsePDF";
import ConfirmationModal from "../modals/ConfirmationModal";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../hocs/withRoleCheck";
import _FollowupModal from "../../routes/campaign/components/FollowupModal";
import OptionsModal from "../../routes/email_urls/components/OptionsModal";
import FormResponseService from "../../services/FormResponse";
import QuestionService from "../../services/Question";
import { questionTypes } from "../../constants/Question";
import FollowupEmailModal from "../../routes/campaign/components/FollowupEmailModal";

const FollowupModal = withRoleCheck(_FollowupModal, [
  ACL_RELATIONSHIPS.emailUrl.editQuestions,
  ACL_RELATIONSHIPS.emailUrl.readQuestions,
]);

function DownloadResponsePDF({
  formResponse,
  questions,
  answers,
  language,
  contact,
}) {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      await generatePdfDocument(
        formResponse,
        questions,
        answers,
        language,
        contact
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };
  return (
    <Button
      primary
      loading={loading}
      size="tiny"
      style={{ marginLeft: "auto" }}
      onClick={handleDownload}
    >
      Download PDF
    </Button>
  );
}

const followupEmailModal = createRef();

const handleFollowupSubmit = async (form_id, followups, form_response) => {
  await QuestionService.addFollowups(form_id, followups);
  followupEmailModal.current.open(form_response);
};

const SendFollowups = ({ form_id, followups, form_response }) => {
  const followups_array = Object.values(followups);
  return (
    <ConfirmationModal
      actionDescription="Create Followups"
      onConfirm={async () =>
        await handleFollowupSubmit(form_id, followups_array, form_response)
      }
      trigger={
        <Button primary size="tiny">
          Create {followups_array.length}{" "}
          {followups_array.length > 1 ? " Followups" : " Followup"}
        </Button>
      }
    >
      <p>Are you sure you want to create these Followups?</p>
    </ConfirmationModal>
  );
};

const answerByType = (question, answer) => {
  switch (question.question_type) {
    case questionTypes.text:
    case questionTypes.textbox:
    case questionTypes.dropdown:
    case questionTypes.radioButtonGroup:
      return answer?.answer || "";
    case questionTypes.checkboxGroup:
      return (
        <List>
          {answer.answer !== null
            ? answer.answer.map(x => <List.Item key={x}>{x}</List.Item>)
            : "---"}
        </List>
      );
    case questionTypes.upload:
      return <PleteoUpload attachments={answer.answer} />;
    default:
      return "Unknown Answer Type";
  }
};

function WixFormResponseV3({ form_response, language, contact }) {
  const [followups, setFollowups] = useState({});
  const [oldFollowups, setOldFollowups] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const optionsModal = useRef();

  useEffect(() => {
    const fetchAnswers = async () => {
      const data = await FormResponseService.getFormResponseAnswers(
        form_response.id
      );
      setAnswers(data);
    };
    const fetchQuestions = async () => {
      const data = await QuestionService.getQuestionsForForm(
        form_response.form_id
      );
      setQuestions(data);
    };
    const fetchFollowups = async () => {
      const data = await FormResponseService.getFollowups(form_response.id);
      setOldFollowups(data);
    };

    fetchAnswers();
    fetchQuestions();
    fetchFollowups();
  }, []);

  const fetchFollowups = async () => {
    const data = await FormResponseService.getFollowups(form_response.id);
    setOldFollowups(data);
  };

  const agentName = form_response.agent_name;

  const addFollowup = (questionId, followup) => {
    if (!followup) {
      return;
    }
    const followups_copy = { ...followups };
    followups_copy[questionId] = followup;
    setFollowups(followups_copy);
  };

  const deleteFollowup = questionId => {
    const followups_copy = { ...followups };
    delete followups_copy[questionId];
    setFollowups(followups_copy);
  };

  const handleEditOptions = (question, index, onUpdate) => {
    optionsModal.current.open(question, index, onUpdate);
  };

  function addRows() {
    const rows = [];
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const oldFollowup = oldFollowups.find(
        obj => obj.parent_question_id === question.id
      );
      const answer = answers.find(
        answer => answer.form_question_id === question.id
      );
      rows.push(
        <Table.Row key={question.id}>
          {/* QUESTION */}
          <Table.Cell width={6}>
            <Header as="h4">
              <Header.Content>
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.title,
                  }}
                />
                <Header.Subheader>
                  {Object.keys(questionTypes).find(
                    key => questionTypes[key] === question.question_type
                  )}
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Table.Cell>

          {/* ANSWER */}
          <Table.Cell>
            {answer ? answerByType(question, answer) : ""}
          </Table.Cell>

          {/* ACTIONS */}
          <Table.Cell collapsing>
            {followups[question.id] || oldFollowup || !answer ? (
              ""
            ) : (
              <FollowupModal
                parent_question={question}
                form_response_id={form_response.id}
                callback={followup_question =>
                  addFollowup(question.id, followup_question)
                }
                onEditOptions={handleEditOptions}
              />
            )}
          </Table.Cell>
        </Table.Row>
      );
      if (followups[question.id]) {
        rows.push(
          <Table.Row positive key={question.id + "followup"}>
            <Table.Cell width={6}>
              <Header as="h4">
                <Header.Content>
                  <Header.Subheader>followup</Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              {followups[question.id].title}
              <Header.Subheader>
                {Object.keys(questionTypes).find(
                  key =>
                    questionTypes[key] === followups[question.id].question_type
                )}
              </Header.Subheader>
            </Table.Cell>
            <Table.Cell>
              {followups[question.id] ? (
                <ConfirmationModal
                  actionDescription="Delete Followup"
                  onConfirm={async () => await deleteFollowup(question.id)}
                  trigger={
                    <Button icon>
                      <Icon name="delete" />
                    </Button>
                  }
                  warning
                >
                  <p>Are you sure you want to delete this Followup?</p>
                </ConfirmationModal>
              ) : (
                ""
              )}
            </Table.Cell>
          </Table.Row>
        );
      }
      if (oldFollowup) {
        rows.push(
          <Table.Row positive key={question.id + "followup"}>
            <Table.Cell width={6}>
              <Header as="h4">
                <Header.Content>
                  {oldFollowup.title}
                  <Header.Subheader>
                    {"[followup] " +
                      Object.keys(questionTypes).find(
                        key => questionTypes[key] === oldFollowup.question_type
                      )}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              {answers.find(
                answer => answer.form_question_id === oldFollowup.id
              )
                ? answerByType(
                    oldFollowup,
                    answers.find(
                      answer => answer.form_question_id === oldFollowup.id
                    )
                  )
                : "---"}
            </Table.Cell>
          </Table.Row>
        );
      }
    }
    return rows;
  }

  function calculate_form_progress() {
    const computable_questions = questions.filter(
      q => q.question_type !== questionTypes.section
    );
    const answers_for_questions = answers.reduce((result, answer) => {
      const question = questions.find(q => q.id === answer.form_question_id);
      if (question) {
        result.push(answer);
      }
      return result;
    }, []);
    const followup_answers = answers.reduce((result, answer) => {
      const followup = oldFollowups.find(
        followup => followup.id === answer.form_question_id
      );
      if (followup) {
        result.push(followup);
      }
      return result;
    }, []);
    return (
      <React.Fragment>
        {"Form progress: "}
        <br></br>
        {`${answers_for_questions.length} out ${
          computable_questions.length
        } questions completed. (${
          (answers_for_questions.length / computable_questions.length) * 100
        }%)`}
        <br></br>
        {oldFollowups.length > 0
          ? `${followup_answers.length} out ${
              oldFollowups.length
            } followups completed. (${
              (followup_answers.length / oldFollowups.length) * 100
            }%)`
          : `no followups`}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <OptionsModal ref={optionsModal} />
      <FollowupEmailModal
        ref={followupEmailModal}
        onSuccess={async () => {
          setFollowups({});
          await fetchFollowups();
        }}
      />
      <Card fluid>
        <Card.Content header={form_response.title} />
        <Card.Content content={calculate_form_progress()} textAlign="center" />
        <Card.Content>
          <Table basic="very" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Questions</Table.HeaderCell>
                <Table.HeaderCell>Answers</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>{addRows()}</Table.Body>
          </Table>
        </Card.Content>
        <Card.Content extra>
          <p>Submitted at: {formatDate(form_response.submitted_at)}</p>
          {agentName && <p>Submitted by: {agentName}</p>}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <DownloadResponsePDF
              questions={questions}
              answers={answers}
              formResponse={form_response}
              language={language}
              contact={contact}
            />
            {Object.values(followups).length > 0 ? (
              <SendFollowups
                followups={followups}
                form_id={form_response.form_id}
                form_response={form_response}
              />
            ) : (
              ""
            )}
            {questions.some(
              q => q && q.question_type === questionTypes.upload
            ) && <DownloadAttachments ids={form_response.id} />}
          </div>
        </Card.Content>
      </Card>
    </React.Fragment>
  );
}

export default WixFormResponseV3;

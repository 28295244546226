import { applyFilters, doRequest } from "./helpers";

export default class DepartmentService {
  static async getForFilters(filters = null) {
    let path = "/department/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async deleteDepartment(id) {
    return doRequest("DELETE", `/department/${id}`);
  }

  static async createDepartment(name) {
    return doRequest("POST", "/department/", {
      data: {
        department_name: name,
      },
    });
  }
}

import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";

import RuvixxForm from "../../components/RuvixxForm";
import CaseService from "../../services/Case";
import UserService from "../../services/User";

function AssignInvestigatorsForm({ caseIds, onSuccess }) {
  const [userIds, setUserIds] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const fetchUsers = useCallback(async () => {
    const users = (await UserService.getUsers()).map(
      ({ id, full_name: name }) => ({
        key: id,
        value: id,
        text: name,
      })
    );

    setUserOptions(users);
  }, []);

  const handleSubmit = async () => {
    await CaseService.assignInvestigators(caseIds, userIds);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <RuvixxForm onSubmit={handleSubmit} onSuccess={onSuccess}>
      <Form.Select
        search
        multiple
        clearable
        onChange={(_, { value }) => setUserIds(value)}
        label="Investigators"
        name="investigators"
        options={userOptions}
        placeholder="Select investigators"
        value={userIds}
      />
    </RuvixxForm>
  );
}

AssignInvestigatorsForm.propTypes = {
  caseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AssignInvestigatorsForm;

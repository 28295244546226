import React, { Component, Fragment } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Modal,
  Select,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { DateRangePicker } from "../DateRangeFilter";
import _ from "lodash";
import isEqual from "lodash/isEqual";

export default class Filter extends Component {
  static propTypes = {
    /*
      this is the expected format of the options:

      [
        {
          title: 'string',
          type: 'string', // either 'dropdown' (default) or 'checkbox'
          data: [
            {id: number, name: 'string'},
            {id: number, name: 'string'},
            ...]
        },
        ...
      ]
    */
    options: PropTypes.arrayOf(PropTypes.object), // if options are provided, 'checked' and 'updateChecked' are needed
    checked: PropTypes.object,
    updateChecked: PropTypes.func, // callback function to update checks stored in parent as a state.

    dateRange: PropTypes.object, // If provided will display inputs to set start and end dates and updateRange is needed
    updateDateRange: PropTypes.func, // Callback function to update dates, needs type(from/to) and a moment object.

    applyFilter: PropTypes.func.isRequired,
    trigger: PropTypes.object,
    inverted: PropTypes.bool, // inverts functionality of filters (all selected by default)
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      options: [],
      checkOptions: _([]),
      checked: {},
      originalChecked: {},
      dateRange: {},
      maxTitleLength: 0,
    };
  }

  handleOpen = () => {
    this.setState({
      modalOpen: true,
      // these are only copyied to restore in case of cancel
      // actual data is stored in parent and handled by it
      checked: JSON.parse(
        JSON.stringify(!!this.props.checked ? this.props.checked : {})
      ),
      originalChecked: { ...this.props.checked },
      from: !!this.props.dateRange ? this.props.dateRange.from : null,
      to: !!this.props.dateRange ? this.props.dateRange.to : null,
    });
  };

  handleClose = () => {
    // restores the filters to how they where when the modal was first opened
    this.props.updateChecked(this.state.originalChecked);
    if (!!this.props.dateRange) {
      this.props.updateDateRange({ from: this.state.from, to: this.state.to });
    }
    this.setState({ modalOpen: false });
  };

  applyFilter = async () => {
    await this.props.applyFilter(); // maybe promise
    this.setState({ modalOpen: false });
  };

  /*

  {
    "State": {
      "Open": 1,
    }
  }


  */

  static updateCheckedOptions = ({
    data,
    group,
    name,
    prevChecked,
    checkOptions,
  }) => {
    const updateOption = (data, group, name, prevChecked) => {
      const checked = { ...prevChecked };
      if (!checked[group] || !name) {
        checked[group] = {};
      }
      if (name) {
        if (data.checked) {
          checked[group][name] = data.value;
        } else {
          delete checked[group][name];
        }
      } else {
        for (const itemId of data.value) {
          const itemName = data.options.find(opt => opt.value === itemId).text;
          checked[group][itemName] = itemId;
        }
      }
      return checked;
    };
    if (checkOptions) {
      return checkOptions
        .filter(({ title }) => !group || title === group)
        .reduce(
          (checked, { id, name, title }) =>
            updateOption(
              { checked: data.checked, value: id },
              title,
              name,
              checked
            ),
          prevChecked
        );
    }
    return updateOption(data, group, name, prevChecked);
  };

  handleCheckSingle = (data, group) => {
    const checked = { ...this.props.checked, [group]: data.checked };
    this.props.updateChecked(checked);
    this.setState({ checked });
  };

  handleSelect = (data, group, name) => {
    const checked = Filter.updateCheckedOptions({
      data,
      group,
      name,
      prevChecked: this.state.checked,
    });
    this.props.updateChecked(checked);
    this.setState({ checked });
  };

  handleSelectAll = (selected, group) => {
    const checked = Filter.updateCheckedOptions({
      data: { checked: selected },
      group,
      prevChecked: this.state.checked,
      checkOptions: this.state.checkOptions,
    });
    this.props.updateChecked(checked);
    this.setState({ checked });
  };

  getCurrentValue(group) {
    const { checked } = this.props;
    let result = null;
    if (group.data) {
      result = group.data
        .filter(item => checked[group.title] && checked[group.title][item.name])
        .map(item => item.id);
    } else {
      result = checked[group.title];
    }
    return result;
  }

  renderDropdownOption = (group, index) => {
    return (
      <Form.Field key={index} inline>
        <label style={{ width: `${this.state.maxTitleLength}ch` }}>
          {group.title}
        </label>
        <Select
          multiple
          search
          options={group.data.map(item => ({
            key: item.id,
            text: item.name,
            value: item.id,
          }))}
          defaultValue={this.getCurrentValue(group)}
          onChange={(_, data) => this.handleSelect(data, group.title)}
          className="or-filter"
        />
      </Form.Field>
    );
  };

  renderCheckboxOption = (group, index) => {
    return group.data
      ? this.renderCheckboxMultipleOption(group, index)
      : this.renderCheckboxSingleOption(group, index);
  };

  renderCheckboxSingleOption = (group, index) => {
    const value = !!this.getCurrentValue(group);
    return (
      <Form.Checkbox
        key={index}
        name={group.title}
        label={group.title}
        checked={value}
        onChange={(_, data) => this.handleCheckSingle(data, group.title)}
      />
    );
  };

  renderCheckboxMultipleOption = (group, index) => {
    const checkedGroupOptions = this.getCurrentValue(group);
    return (
      <Fragment key={index}>
        <Form.Checkbox
          name={group.title}
          label={<label className="groupTitle">{group.title}</label>}
          checked={checkedGroupOptions.length === group.data.length}
          indeterminate={
            checkedGroupOptions.length > 0 &&
            checkedGroupOptions.length < group.data.length
          }
          onClick={(_, { checked }) =>
            this.handleSelectAll(checked, group.title)
          }
        />
        <Form.Group className="filterGroup">
          {group.data.map((item, index) => (
            <Form.Checkbox
              key={index}
              name={item.name}
              label={
                <label
                  style={{
                    fontSize: item.name.length > 27 ? "0.8em" : "0.9em",
                  }}
                >
                  {item.name}
                </label>
              }
              value={item.id}
              checked={checkedGroupOptions.includes(item.id)}
              onChange={(_, data) =>
                this.handleSelect(data, group.title, item.name)
              }
            />
          ))}
        </Form.Group>
      </Fragment>
    );
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (isEqual(nextProps.options, prevState.options)) {
      return null;
    }
    const options = nextProps.options;
    const maxTitleLength = Math.max(
      0,
      ...options
        .filter(opt => !opt.type || opt.type === "dropdown")
        .map(({ title }) => title.length)
    );
    const checkOptions = _(options)
      .filter(({ type, data }) => type === "checkbox" && !!data)
      .flatMap(({ title, data }) =>
        data.map(({ id, name }) => ({ id, name, title }))
      );
    let checked = prevState.checked;
    if (nextProps.inverted) {
      checked = Filter.updateCheckedOptions({
        data: { checked: true },
        prevChecked: prevState.checked,
        checkOptions,
      });
      nextProps.updateChecked(checked);
    }
    return { maxTitleLength, options, checkOptions, checked };
  };

  render() {
    const numCheckedOptions = this.state.checkOptions
      .filter(
        ({ name, title }) =>
          this.props.checked[title] && this.props.checked[title][name]
      )
      .size();
    const numFilterCheckboxes = this.state.checkOptions.size();
    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        trigger={
          this.props.trigger ? (
            <div style={{ display: "inline-block" }} onClick={this.handleOpen}>
              {this.props.trigger}
            </div>
          ) : (
            <Button icon="filter" onClick={this.handleOpen} size="mini" />
          )
        }
        closeIcon
        size="small"
      >
        <Header content="Filters" />
        <Form>
          {this.props.dateRange && (
            <Container style={{ margin: "0.5em" }}>
              <DateRangePicker
                to={this.props.dateRange.to}
                from={this.props.dateRange.from}
                updateDateRange={this.props.updateDateRange}
              />
            </Container>
          )}
          {this.props.dateRange && this.props.options && (
            <Divider style={{ margin: "2em 0" }} />
          )}
          {this.props.options && (
            <Container style={{ margin: "0.5em" }}>
              {numFilterCheckboxes > 0 && (
                <Form.Checkbox
                  label={<label className="groupTitle">Select ALL/NONE</label>}
                  style={{ margin: "0.3em 0 0.5em" }}
                  checked={numCheckedOptions === numFilterCheckboxes}
                  indeterminate={
                    numCheckedOptions > 0 &&
                    numCheckedOptions < numFilterCheckboxes
                  }
                  onClick={(_, { checked }) => this.handleSelectAll(checked)}
                />
              )}
              {this.props.options.map((group, index) =>
                group.type === "checkbox"
                  ? this.renderCheckboxOption(group, index)
                  : this.renderDropdownOption(group, index)
              )}
            </Container>
          )}
          <Divider hidden style={{ margin: "2.5em 0" }} />
        </Form>
        <Modal.Actions>
          <Button
            basic
            size="tiny"
            onClick={this.handleClose}
            content="Cancel"
          />
          <Button
            size="tiny"
            primary
            onClick={this.applyFilter}
            content="APPLY"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

function FilterButtons({ filters, checked, clearFilter }) {
  const getLabel = filter => {
    const vals = Object.keys(checked[filter] || {}).join(", ");
    return filter + (vals ? `: ${vals}` : "");
  };
  const filterKeys = Object.keys(filters).filter(
    filter => filters[filter] === true || filters[filter].length > 0
  );
  if (filterKeys.length === 0) {
    return null;
  }
  filterKeys.unshift("Clear All");
  return (
    <>
      Filters:
      {filterKeys.map((filter, index) => (
        <Button
          key={index}
          basic
          size="mini"
          icon="close"
          content={getLabel(filter)}
          onClick={() => clearFilter(filter)}
        />
      ))}
    </>
  );
}

export { FilterButtons };

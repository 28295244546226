import React, { Component, cloneElement } from "react";
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Label,
  Modal,
  Message,
  Popup,
} from "semantic-ui-react";
import capitalize from "lodash/capitalize";
import isEqual from "lodash/isEqual";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import NewCategoryForm from "./../forms/ActivityLogCategory";
import CampaignForm from "./../forms/Campaign";
import EditCampaignForm from "./../forms/EditCampaign";
import EditCampaignTargetForm from "./../forms/EditCampaignTarget";
import NewCampaignTargetForm from "./../forms/NewTarget";
import NewEmailForm from "./../forms/NewEmail";
import NewCampaignEmailForm from "./../forms/NewCampaignEmail";
import AssignUserForm from "./../forms/AssignUserForm";
import CampaignService from "../../../services/Campaign";
import PropTypes from "prop-types";
import CONSTANTS from "constants/Constants";

class NewCategoryModal extends Component {
  static propTypes = {
    // ...prop type definitions here
    fetchCategories: PropTypes.func.isRequired,
    margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    margin: "0 0 1em 0",
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSuccess = async () => {
    await this.props.fetchCategories();
    this.handleClose();
  };

  render() {
    return (
      <div>
        <Button
          style={{ margin: this.props.margin }}
          basic
          onClick={this.handleOpen}
          size="tiny"
        >
          <Icon name="plus" />
          <strong>New Category</strong>
        </Button>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
          size="small"
          closeIcon
        >
          <Header content="New Category" />
          <Modal.Content>
            <NewCategoryForm onSuccess={this.handleSuccess} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class DeleteCampaignModal extends Component {
  static propTypes = {
    onConfirmDelete: PropTypes.func.isRequired,
    menuTrigger: PropTypes.bool,
    noIcon: PropTypes.bool,
  };

  render() {
    return (
      <ConfirmationModal
        actionDescription="Delete Campaign"
        buttonColor="grey"
        onConfirm={this.props.onConfirmDelete}
        menuTrigger={this.props.menuTrigger}
        icon={this.props.noIcon ? null : "trash"}
        warning
      >
        <p>Are you sure you want to delete this Campaign?</p>
      </ConfirmationModal>
    );
  }
}

class ArchiveCampaignModal extends Component {
  static propTypes = {
    onConfirmArchive: PropTypes.func.isRequired,
    archived: PropTypes.bool,
    menuTrigger: PropTypes.bool,
  };

  render() {
    const action = `${this.props.archived ? "un" : ""}archive`;
    return (
      <ConfirmationModal
        actionDescription={`${capitalize(action)} Campaign`}
        buttonColor="grey"
        onConfirm={this.props.onConfirmArchive}
        menuTrigger={this.props.menuTrigger}
        icon="archive"
        warning={!this.props.archived}
      >
        <p>Are you sure you want to {action} this Campaign?</p>
      </ConfirmationModal>
    );
  }
}

class ActiveInactiveCampaignModal extends Component {
  static propTypes = {
    onConfirmActivate: PropTypes.func.isRequired,
    onConfirmInactivate: PropTypes.func.isRequired,
    isInactive: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    menuTrigger: PropTypes.bool,
  };

  render() {
    const { isInactive, onConfirmActivate, onConfirmInactivate } = this.props;
    return (
      <ConfirmationModal
        actionDescription={
          isInactive ? "Activate Campaign" : "Inactivate Campaign"
        }
        buttonColor="grey"
        onConfirm={isInactive ? onConfirmActivate : onConfirmInactivate}
        disabled={this.props.disabled}
        menuTrigger={this.props.menuTrigger}
        icon={isInactive ? "lock" : "unlock"}
        warning={!isInactive}
      >
        <p>
          Are you sure you want to {isInactive ? "activate " : "inactivate "}{" "}
          this Campaign?
        </p>
      </ConfirmationModal>
    );
  }
}

class NewCampaignModal extends Component {
  static propTypes = {
    fetchCampaigns: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSuccess = async () => {
    await this.props.fetchCampaigns(); // maybe-promise
    this.setState({ modalOpen: false });
  };

  render() {
    return (
      <Modal
        size="small"
        trigger={
          <Button
            size="tiny"
            className="item-adder"
            content="New Campaign"
            onClick={this.handleOpen}
          />
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>New Campaign</Modal.Header>
        <Modal.Content>
          <CampaignForm onSuccess={this.handleSuccess} />
        </Modal.Content>
      </Modal>
    );
  }
}

class EditCampaignModal extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    fetchCampaigns: PropTypes.func.isRequired,
    noIcon: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSuccess = async () => {
    await this.props.fetchCampaigns(); // maybe-promise
    this.handleClose();
  };

  render() {
    return (
      <Modal
        size="small"
        trigger={
          <Dropdown.Item
            onClick={this.handleOpen}
            content="Edit"
            icon={this.props.noIcon ? null : "edit"}
          />
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header>Edit Campaign</Modal.Header>
        <Modal.Content>
          <EditCampaignForm
            onSuccess={this.handleSuccess}
            campaignId={this.props.campaignId}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class NewCampaignTargetModal extends Component {
  static propTypes = {
    fetchCampaignTargets: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      campaignStatus: null,
    };
  }

  handleOpen = () => {
    this.setState({
      modalOpen: true,
      campaignStatus: this.props.campaign.status,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
      campaignStatus: null,
    });
  };

  handleSuccess = async () => {
    await this.props.fetchCampaignTargets(); // maybe-promise
    this.handleClose();
  };

  render() {
    const { campaignStatus } = this.state;

    return (
      <Modal
        size="small"
        trigger={
          <Button
            size="tiny"
            className="item-adder"
            content="New Target"
            onClick={this.handleOpen}
          />
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>New Target</Modal.Header>
        <Modal.Content>
          {campaignStatus !== CONSTANTS.CAMPAIGN_STATUS_TYPES.ACTIVE ? (
            <Message color="red">
              <Message.Header>Action failed</Message.Header>
              <p>
                This campaign is{" "}
                {campaignStatus === CONSTANTS.CAMPAIGN_STATUS_TYPES.INACTIVE
                  ? "inactive"
                  : "archived"}
              </p>
            </Message>
          ) : (
            <NewCampaignTargetForm
              onSuccess={this.handleSuccess}
              campaignId={this.props.campaignId}
            />
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

class DeleteCampaignTargetModal extends Component {
  static propTypes = {
    onConfirmDelete: PropTypes.func.isRequired,
    menuTrigger: PropTypes.bool,
    noIcon: PropTypes.bool,
    iconTrigger: PropTypes.bool,
    modalMessage: PropTypes.string,
    buttonText: PropTypes.string,
  };

  render() {
    return (
      <ConfirmationModal
        actionDescription={this.props.buttonText}
        buttonColor="red"
        onConfirm={this.props.onConfirmDelete}
        menuTrigger={this.props.menuTrigger}
        iconTrigger={this.props.iconTrigger}
        icon={this.props.noIcon ? null : "trash"}
        warning
      >
        <p>{this.props.modalMessage}</p>
      </ConfirmationModal>
    );
  }
}

class EditCampaignTargetModal extends Component {
  static propTypes = {
    fetchCampaignTargets: PropTypes.func.isRequired,
    campaignId: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,
    noIcon: PropTypes.bool,
    iconTrigger: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSuccess = async () => {
    await this.props.fetchCampaignTargets(); // maybe-promise
    this.handleClose();
  };

  render() {
    return (
      <Modal
        size="large"
        trigger={
          this.props.iconTrigger ? (
            this.props.iconTrigger === "pencil" ? (
              <Icon
                name="pencil"
                className="primary"
                link
                onClick={this.handleOpen}
                style={{ verticalAlign: "-2px" }}
              />
            ) : (
              <Button basic icon onClick={this.handleOpen}>
                <Icon name="edit" />
              </Button>
            )
          ) : (
            <Dropdown.Item
              onClick={this.handleOpen}
              content="Edit"
              icon={this.props.noIcon ? null : "edit"}
            />
          )
        }
        closeIcon
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header>Edit Campaign Target</Modal.Header>
        <Modal.Content>
          <EditCampaignTargetForm
            onSuccess={this.handleSuccess}
            campaignId={this.props.campaignId}
            entityId={this.props.entityId}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class SendEmailModal extends Component {
  static propTypes = {
    campaignId: PropTypes.number,
    iconOnly: PropTypes.bool,
    onSuccess: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      sentModal: false,
      disabled: this.props.disabled,
    };
  }
  static defaultProps = {
    onSuccess: () => {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.disabled !== undefined &&
      !isEqual(nextProps.disabled, prevState.disabled)
    ) {
      return {
        disabled: nextProps.disabled,
      };
    }
    return null;
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ sentModal: false, modalOpen: false });
  };

  handleSuccess = async () => {
    this.setState({ sentModal: true });
    this.props.onSuccess();
  };

  defaultTrigger = () => (
    <Button
      onClick={this.handleOpen}
      disabled={this.state.disabled}
      as="div"
      labelPosition="right"
      size="tiny"
    >
      <Button icon>
        <Icon name="envelope outline" />
      </Button>
      <Label as="a" basic>
        Send Email
      </Label>
    </Button>
  );

  iconOnlyTrigger = () => (
    <Popup
      content="Send Email"
      trigger={
        <Button
          onClick={this.handleOpen}
          icon="envelope outline"
          size="tiny"
          className="iconOnly"
          disabled={this.state.disabled}
        />
      }
    />
  );

  render() {
    return (
      <Modal
        size="large"
        trigger={cloneElement(
          this.props.trigger ||
            (this.props.iconOnly
              ? this.iconOnlyTrigger()
              : this.defaultTrigger()),
          {
            onClick: this.handleOpen,
          }
        )}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>New Email</Modal.Header>
        <Modal.Content>
          <NewEmailForm
            campaignId={this.props.campaignId}
            entities={(this.props.entityId && [this.props.entityId]) || []}
            forEntity={!this.props.campaignId && !!this.props.entityId}
            contactId={this.props.contactId || 0}
            contactEmail={this.props.contactEmail || ""}
            onSuccess={this.handleSuccess}
            contactRows={this.props.contactRows}
            checkedArr={this.props.checkedArr}
            closeParentModal={this.handleClose}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class ComposeNoticeModal extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    campaignTargets: PropTypes.array,
    emailService: PropTypes.number,
    onSuccess: PropTypes.func,
  };

  static defaultProps = {
    onSuccess: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      sentModal: false,
      disabled: true,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ sentModal: false, modalOpen: false });
  };

  handleSuccess = async () => {
    this.setState({ sentModal: true });
    this.props.onSuccess();
  };

  componentDidMount = async () => {
    let disabled = false;
    if (!this.props.campaignTargets) {
      disabled = !(await CampaignService.hasEmailableContacts(
        this.props.campaignId
      ));
    }
    this.setState({ disabled });
  };

  render() {
    return (
      <Modal
        size="large"
        trigger={
          <Dropdown.Item
            content="Compose Notice"
            disabled={this.state.disabled}
            onClick={this.handleOpen}
          />
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header>New Email</Modal.Header>
        <Modal.Content>
          {this.props.campaignTargets ? (
            <NewEmailForm
              campaignId={this.props.campaignId}
              entities={this.props.campaignTargets}
              emailService={this.props.emailService}
              onSuccess={this.handleSuccess}
              closeParentModal={this.handleClose}
            />
          ) : (
            <NewCampaignEmailForm
              campaignId={this.props.campaignId}
              onSuccess={this.handleSuccess}
              emailService={this.props.emailService}
              closeParentModal={this.handleClose}
            />
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

class AssignUserModal extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    campaignTargets: PropTypes.array.isRequired,
    fetchCampaignTargets: PropTypes.func.isRequired,
    assignedUserId: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSuccess = async () => {
    await this.props.fetchCampaignTargets();
    this.handleClose();
  };

  render() {
    return (
      <Modal
        size="large"
        trigger={
          <Dropdown.Item onClick={this.handleOpen} content="Assign User" />
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        closeIcon
      >
        <Modal.Header>Select user to assign</Modal.Header>
        <Modal.Content>
          <AssignUserForm
            campaignId={this.props.campaignId}
            campaignTargets={this.props.campaignTargets}
            onSuccess={this.handleSuccess}
            assignedUserId={this.props.assignedUserId}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class DeleteScheduleModal extends Component {
  static propTypes = {
    onConfirmDelete: PropTypes.func.isRequired,
    scheduleId: PropTypes.number.isRequired,
    campaignId: PropTypes.number.isRequired,
  };
  render() {
    return (
      <ConfirmationModal
        actionDescription="Delete"
        buttonColor="grey"
        onConfirm={this.props.onConfirmDelete}
        menuTrigger={this.props.menuTrigger}
        icon={this.props.noIcon ? null : "trash"}
        warning
      >
        <p>Are you sure you want to delete this Schedule?</p>
      </ConfirmationModal>
    );
  }
}

export {
  NewCategoryModal,
  DeleteCampaignModal,
  ArchiveCampaignModal,
  ActiveInactiveCampaignModal,
  NewCampaignModal,
  EditCampaignModal,
  NewCampaignTargetModal,
  DeleteCampaignTargetModal,
  EditCampaignTargetModal,
  SendEmailModal,
  AssignUserModal,
  ComposeNoticeModal,
  DeleteScheduleModal,
};

import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import AssignContactForm from "./AssignContactForm";

const AssignContactModal = forwardRef(
  ({ updateContactId, phoneNumber }, ref) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState();
    const [contacts, setContacts] = useState([]);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
    }));

    const handleOpen = (title, contacts = []) => {
      setTitle(title);
      setContacts(contacts);
      setOpen(true);
    };

    const handleSuccess = () => {
      setOpen(false);
    };

    return (
      <Modal
        size="tiny"
        open={open}
        onClose={() => setOpen(false)}
        closeOnDimmerClick={false}
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        closeIcon
      >
        <Modal.Header>{title ?? "Assign Contact"}</Modal.Header>
        <Modal.Content>
          <AssignContactForm
            onSuccess={handleSuccess}
            updateContactId={updateContactId}
            contacts={contacts}
            phoneNumber={phoneNumber}
          />
        </Modal.Content>
      </Modal>
    );
  }
);

AssignContactModal.propTypes = {
  updateContactId: PropTypes.func.isRequired,
};

export default AssignContactModal;

import { doRequest, applyFilters } from "./helpers";
import { DEFAULT_PAGE_SIZE } from "../constants/Constants";

const generatePayload = (
  name,
  targetType,
  targetId,
  type,
  assignees,
  visibility,
  visibilityIds,
  status,
  dueDate,
  dueDateTimeZone,
  priority,
  description,
  files,
  info,
  campaignIds
) => ({
  name,
  target: {
    type: targetType.name,
    id: Number(targetId),
  },
  type,
  assigned_user_ids: assignees,
  visibility: visibility.id,
  ...(visibility.key !== "public" && {
    [`${visibility.model.toLowerCase()}_ids`]: visibilityIds,
  }),
  status,
  due_date: dueDate,
  time_zone: dueDateTimeZone,
  priority,
  description: description || null,
  attachments: files.map(({ attachment }) => attachment),
  info: info,
  campaign_ids: campaignIds,
});

const TaskService = {
  getTasks: async ({
    page = 1,
    perPage = DEFAULT_PAGE_SIZE,
    filters = {},
    cancelToken,
  }) => {
    filters = {
      page,
      per_page: perPage,
      ...filters,
    };
    const path = applyFilters(filters, "/task/");
    return doRequest("GET", path, { cancelToken });
  },
  getTask: async id => {
    const path = `/task/${id}`;
    return doRequest("GET", path);
  },
  createTask: async (...args) => {
    const data = generatePayload(...args);
    return doRequest("POST", "/task/", { data });
  },
  updateTask: async (id, ...args) => {
    const data = generatePayload(...args);
    return doRequest("PUT", `/task/${id}`, { data });
  },
  markAsCompleted: async id => {
    return doRequest("POST", `/task/${id}/mark_as_completed`);
  },
  attachFile: async (id, file) => {
    return doRequest("POST", `/task/${id}/attach_file`, { data: { ...file } });
  },
};

export default TaskService;

import React from "react";
import { List } from "semantic-ui-react";
import PropTypes from "prop-types";

function ListTableColumn({
  itemKey,
  itemField,
  itemValue,
  filterFn,
  data = [],
}) {
  if (data && !Array.isArray(data)) {
    console.error("ListTableColumn expects data to be an array.");
    return null;
  }

  const items = filterFn ? data.filter(filterFn) : data;

  return (
    <List horizontal bulleted style={{ display: "flex" }}>
      {items.map((value, index) => (
        <List.Item key={itemKey ? value[itemKey] : index}>
          {itemValue ? itemValue(value) : itemField ? value[itemField] : value}
        </List.Item>
      ))}
    </List>
  );
}

ListTableColumn.propTypes = {
  itemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemField: PropTypes.string,
  itemValue: PropTypes.func,
  filterFn: PropTypes.func,
  data: PropTypes.array,
};

export default ListTableColumn;

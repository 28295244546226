export default class Validate {
  static email(email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static emailUsername(fromUser) {
    //Validate the username part of an email address: THIS_PART@example.com
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;
    return re.test(String(fromUser).toLowerCase());
  }

  static tel(tel) {
    var re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    return re.test(String(tel));
  }

  static imageFileType(fileType) {
    var re = /^image\//;
    return re.test(String(fileType));
  }
}

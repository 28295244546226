import React, { useState } from "react";
import { Button, Header, Modal, Dropdown } from "semantic-ui-react";
import ContractForm from "../forms/Contract";

export default function ContractModal(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const onSuccess = async => {
    props.onSuccess();
    handleClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
      trigger={
        props.contract ? (
          <Dropdown.Item
            onClick={handleOpen}
            content="Edit"
            icon={props.noIcon ? null : "edit"}
          />
        ) : (
          <Button
            content="New Contract"
            onClick={handleOpen}
            size="tiny"
            className="item-adder"
          />
        )
      }
    >
      <Header content={props.contract ? "Edit Contract" : "New Contract"} />
      <Modal.Content>
        <ContractForm
          onSuccess={onSuccess}
          entityId={props.entityId}
          contractId={props.contract ? props.contract.id : null}
          contract={props.contract || null}
        />
      </Modal.Content>
    </Modal>
  );
}

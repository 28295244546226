import { doRequest, applyFilters } from "./helpers";

const TwilioPhoneNumberService = {
  getTable: filters => {
    let path = "/twilio_phone_number/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  },
  assignAgents: (twilioPhoneNumberIds, userIds) => {
    return doRequest("PUT", "/twilio_phone_number/assign_agents", {
      data: {
        twilio_phone_number_ids: twilioPhoneNumberIds,
        user_ids: userIds,
      },
    });
  },
  assignVoicemail: (twilioPhoneNumberId, voicemailId) => {
    return doRequest("PUT", "/twilio_phone_number/assign_voicemail", {
      data: {
        twilio_phone_number_id: twilioPhoneNumberId,
        voicemail_id: voicemailId,
      },
    });
  },
};

export default TwilioPhoneNumberService;

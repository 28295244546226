import { applyFilters, doRequest } from "./helpers";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

export default class CustomFieldService {
  static async getCustomFieldConfigsTable(filters = null) {
    let path = "/custom_field/configs/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async createCustomFieldConfig(data) {
    return doRequest("POST", `/custom_field/configs/`, {
      data: omitBy(data, isNil),
    });
  }

  static async updateCustomFieldConfig(customFieldConfigId, data) {
    return doRequest("PUT", `/custom_field/configs/${customFieldConfigId}`, {
      data: omitBy(data, isNil),
    });
  }

  static async deleteCustomFieldConfig(customFieldConfigId) {
    return doRequest("DELETE", `/custom_field/configs/${customFieldConfigId}`);
  }

  static async getCustomFieldConfigsForModel(modelType) {
    return doRequest("GET", `/custom_field/configs/${modelType}/`);
  }
  static async getCustomFieldConfigModelTypes() {
    return doRequest("GET", `/custom_field/configs/model_types/`);
  }
}

import React, { useState, useCallback, useEffect, useRef } from "react";
import { Tab, Button, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../../acl-relationships";

import FileList from "../../../components/FileList";
import FilePicker from "../../../components/FilePicker";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import UploadManager from "../../../components/UploadManager";
import TaskService from "../../../services/Task";

const FilesTab = ({ id, fetchTask }) => {
  const [loading, setLoading] = useState(true);
  const [attachNewFileLoading, setAttachNewFileLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const uploadManager = useRef();

  const fetchFiles = useCallback(async () => {
    setLoading(true);
    const { attachments: files } = await TaskService.getTask(id);
    setFiles(files);
    setLoading(false);
  }, [id]);

  const submitAttachment = useCallback(
    async file => {
      await TaskService.attachFile(id, file.attachment);
      fetchFiles();
      fetchTask();
    },
    [fetchFiles, fetchTask]
  );

  const initUploadManager = useCallback(() => {
    uploadManager.current = new UploadManager();
    uploadManager.current.addSuccessListener(submitAttachment);
  }, [submitAttachment]);

  useEffect(() => {
    fetchFiles();
    initUploadManager();
  }, [fetchFiles, initUploadManager]);

  const handleFileSelect = async files => {
    setAttachNewFileLoading(true);
    await uploadManager.current.uploadFiles(files);
    setAttachNewFileLoading(false);
    fetchFiles();
  };

  const FilePickerWithRoleCheck = withRoleCheck(
    () => (
      <FilePicker
        size="tiny"
        secondary
        icon={false}
        content="Attach New File"
        disabled={loading || attachNewFileLoading}
        loading={attachNewFileLoading}
        onSelect={handleFileSelect}
        style={{ marginBottom: "1em" }}
      />
    ),
    [ACL_RELATIONSHIPS.task.attachFile]
  );

  return (
    <Tab.Pane>
      <FilePickerWithRoleCheck />
      <FileList
        files={files}
        fetchFiles={fetchFiles}
        onDelete={fetchTask}
        isLoading={loading}
      />
    </Tab.Pane>
  );
};

export default FilesTab;

import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "semantic-ui-react";
import ApplyTagForm from "../forms/ApplyTagForm";

const ApplyTagModal = forwardRef(({ onApplyTag }, ref) => {
  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState({});

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = tag => {
    setTag(tag);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = metadata => {
    onApplyTag(tag, metadata);
    handleClose();
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={handleClose}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Apply Tag</Modal.Header>
      <Modal.Content>
        <ApplyTagForm
          tag={tag}
          onSubmit={handleSubmit}
          onCancel={handleClose}
        />
      </Modal.Content>
    </Modal>
  );
});

export default ApplyTagModal;

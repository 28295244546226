import lodashStartCase from "lodash/startCase";
import React from "react";
import ConfirmationModal from "./ConfirmationModal";
import CampaignTargetCallService from "../../services/CampaignTargetCall";
import CampaignService from "../../services/Campaign";
import TextMessageService from "../../services/TextMessage";
import { useState, useEffect } from "react";

const DeleteManualActivityModal = ({ item, fetchData }) => {
  const [isDeleted, setIsDeleted] = useState(false);
  useEffect(() => {
    const checkDeletion = async () => {
      const deleted = await checkIfDeleted();
      setIsDeleted(deleted);
    };
    checkDeletion();
  }, []);

  let deleteFunction;
  switch (item.auditable_type) {
    case "CampaignTargetEmail":
      deleteFunction = CampaignService.deleteCampaignTargetEmails;
      break;
    case "CampaignTargetCall":
      deleteFunction = CampaignTargetCallService.deleteCall;
      break;
    case "TextMessage":
      deleteFunction = TextMessageService.deleteTextMessage;
      break;
    default:
      deleteFunction = null;
  }

  const handleDelete = async () => {
    try {
      await deleteFunction([item.auditable_id]);
    } catch (e) {
      return e;
    }
    fetchData();
  };

  const checkIfDeleted = async () => {
    try {
      switch (item.auditable_type) {
        case "CampaignTargetEmail":
          await CampaignService.getEmailData(item.auditable_id);
          break;
        case "CampaignTargetCall":
          await CampaignTargetCallService.getCall(item.auditable_id);
          break;
        case "TextMessage":
          await TextMessageService.getTextMessage(item.auditable_id);
          break;
      }
      return false;
    } catch (error) {
      if (error.response.status === 404) {
        return true;
      }
      return false;
    }
  };

  return (
    <ConfirmationModal
      buttonColor="grey"
      onConfirm={handleDelete}
      iconTrigger
      disabled={isDeleted}
      icon="trash"
      actionDescription={`Delete ${lodashStartCase(item.auditable_type)}`}
      warning
    >
      <p>
        Are you sure you want to delete this{" "}
        {lodashStartCase(item.auditable_type).toLowerCase()}?
      </p>
    </ConfirmationModal>
  );
};

export default DeleteManualActivityModal;

const actionTypes = {
  task: 1,
  automation: 2,
};

const automationActions = [
  {
    id: 1,
    key: "add_tags",
    name: "Add Tags",
  },
  {
    id: 2,
    key: "change_campaign_status",
    name: "Change Campaign Status",
  },
];

const automationLevels = [
  {
    id: 1,
    key: "contact",
    name: "Contact Level",
  },
  {
    id: 2,
    key: "entity",
    name: "Entity Level",
  },
];

const sequenceConstants = {
  actionTypes,
  automationActions,
  automationLevels,
};

export default sequenceConstants;

import React, { Component } from "react";
import PropTypes from "prop-types";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";

export default class DeleteVoicemailModal extends Component {
  static propTypes = {
    onConfirmDelete: PropTypes.func.isRequired,
    menuTrigger: PropTypes.bool,
    noIcon: PropTypes.bool,
  };

  render() {
    return (
      <ConfirmationModal
        actionDescription="Delete Voicemail"
        buttonColor="grey"
        onConfirm={this.props.onConfirmDelete}
        menuTrigger={this.props.menuTrigger}
        icon={this.props.noIcon ? null : "trash"}
        warning
      >
        <p>Are you sure you want to delete this Voicemail?</p>
      </ConfirmationModal>
    );
  }
}

import React, { useState, useRef, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { trimText } from "helpers/string";

import "./FileInput.scoped.scss";

const FileInput = ({
  onChange,
  value,
  clearable = false,
  multiple = false,
  ...inputProps
}) => {
  const [fileName, setFileName] = useState(value);
  const inputRef = useRef();

  useEffect(() => {
    setFileName(value);
  }, [value]);

  const handleBrowse = () => {
    inputRef.current.click();
  };

  const handleChange = () => {
    const files = Array.from(inputRef.current.files);
    if (files.length >= 1) {
      setFileName(files[0].name);
      if (onChange) {
        onChange(multiple ? files : files[0]);
      }
    } else {
      handleReset();
    }
  };

  const handleReset = event => {
    if (event !== undefined) {
      event.stopPropagation();
    }
    setFileName(null);
    if (onChange) {
      onChange(multiple ? [] : null);
    }
  };

  return (
    <>
      <div className="file-input" onClick={handleBrowse}>
        <div>
          <button>Browse…</button>
          <span>{fileName ? trimText(fileName, 30) : "No file selected."}</span>
        </div>
        {fileName && clearable && (
          <div>
            <Icon link name="close" color="grey" onClick={handleReset} />
          </div>
        )}
      </div>
      <input
        ref={inputRef}
        type="file"
        title=""
        onChange={handleChange}
        multiple={multiple ? "multiple" : undefined}
        {...inputProps}
      />
    </>
  );
};

export default FileInput;

import React, { useState } from "react";
import { Header, Modal, Button, Dropdown } from "semantic-ui-react";
import RevenueOpportunityStatusForm from "components/forms/RevenueOpportunityStatusForm";

export default function RevenueOpportunityStatusModal({
  fetchStatus,
  revenueOpportunityStatusId,
  noIcon,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const onSuccess = async () => {
    fetchStatus();
    handleClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={onSuccess}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        revenueOpportunityStatusId ? (
          <Dropdown.Item
            onClick={handleOpen}
            content="Edit Revenue Opportunity Status"
            icon={noIcon ? null : "plus"}
          />
        ) : (
          <Button
            size="tiny"
            className="item-adder"
            content="New Revenue Opportunity Status"
            onClick={handleOpen}
          />
        )
      }
    >
      <Header
        content={`${
          revenueOpportunityStatusId ? "Edit" : "Create"
        } Revenue Opportunity Status`}
      />
      <Modal.Content>
        <RevenueOpportunityStatusForm
          statusId={revenueOpportunityStatusId}
          onSuccess={onSuccess}
        />
      </Modal.Content>
    </Modal>
  );
}

import React, { Component } from "react";
import UserService from "../../services/User";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { NavLink } from "react-router-dom";
import { Form } from "semantic-ui-react";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import ACL_RELATIONSHIPS from "../../acl-relationships";

const SMTPAccountsNavLink = withRoleCheck(NavLink, [
  ACL_RELATIONSHIPS.emailConfigSmtp.read,
]);
const ResetSettingsModal = withRoleCheck(ConfirmationModal, [
  ACL_RELATIONSHIPS.userSettings.edit,
]);

class Preferences extends Component {
  resetTableSettings = async () => {
    await UserService.resetPreferences();
  };

  render() {
    return (
      <Form.Group inline>
        <Form.Field>
          <ResetSettingsModal
            actionDescription="This will set all table settings to their original state"
            icon="warning circle"
            buttonColor="orange"
            buttonText="Reset Table Settings"
            onConfirm={this.resetTableSettings}
            warning
          >
            <p>Are you sure you want to reset all table Settings?</p>
          </ResetSettingsModal>
        </Form.Field>
        <Form.Field>
          <SMTPAccountsNavLink
            to="/smtp_accounts"
            className="ui grey mini inverted button"
            style={{ padding: "1em 1.2em" }}
          >
            SMTP Configuration
          </SMTPAccountsNavLink>
        </Form.Field>
      </Form.Group>
    );
  }
}

export default Preferences;

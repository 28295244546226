const countryOptions = [
  { text: "Afghanistan", key: 0, value: "AFG", flag: "af" },
  { text: "Albania", key: 1, value: "ALB", flag: "al" },
  { text: "Algeria", key: 2, value: "DZA", flag: "dz" },
  { text: "American Samoa", key: 3, value: "ASM", flag: "as" },
  { text: "Andorra", key: 4, value: "AND", flag: "ad" },
  { text: "Angola", key: 5, value: "AGO", flag: "ao" },
  { text: "Anguilla", key: 6, value: "AIA", flag: "ai" },
  // {text:"Antarctica",key:7,value:"ATA",flag:"aq"},
  { text: "Antigua and Barbuda", key: 8, value: "ATG", flag: "ag" },
  { text: "Argentina", key: 9, value: "ARG", flag: "ar" },
  { text: "Armenia", key: 10, value: "ARM", flag: "am" },
  { text: "Aruba", key: 11, value: "ABW", flag: "aw" },
  { text: "Australia", key: 12, value: "AUS", flag: "au" },
  { text: "Austria", key: 13, value: "AUT", flag: "at" },
  { text: "Azerbaijan", key: 14, value: "AZE", flag: "az" },
  { text: "Bahamas", key: 15, value: "BHS", flag: "bs" },
  { text: "Bahrain", key: 16, value: "BHR", flag: "bh" },
  { text: "Bangladesh", key: 17, value: "BGD", flag: "bd" },
  { text: "Barbados", key: 18, value: "BRB", flag: "bb" },
  { text: "Belarus", key: 19, value: "BLR", flag: "by" },
  { text: "Belgium", key: 20, value: "BEL", flag: "be" },
  { text: "Belize", key: 21, value: "BLZ", flag: "bz" },
  { text: "Benin", key: 22, value: "BEN", flag: "bj" },
  { text: "Bermuda", key: 23, value: "BMU", flag: "bm" },
  { text: "Bhutan", key: 24, value: "BTN", flag: "bt" },
  {
    text: "Bolivia, Plurinational State of",
    key: 25,
    value: "BOL",
    flag: "bo",
  },
  // {text:"Bonaire, Sint Eustatius and Saba",key:26,value:"BES",flag:"bq"},
  { text: "Bosnia and Herzegovina", key: 27, value: "BIH", flag: "ba" },
  { text: "Botswana", key: 28, value: "BWA", flag: "bw" },
  { text: "Bouvet Island", key: 29, value: "BVT", flag: "bv" },
  { text: "Brazil", key: 30, value: "BRA", flag: "br" },
  // {text:"British Indian Ocean Territory",key:31,value:"IOT",flag:"io"},
  { text: "Brunei Darussalam", key: 32, value: "BRN", flag: "bn" },
  { text: "Bulgaria", key: 33, value: "BGR", flag: "bg" },
  { text: "Burkina Faso", key: 34, value: "BFA", flag: "bf" },
  { text: "Burundi", key: 35, value: "BDI", flag: "bi" },
  { text: "Cambodia", key: 36, value: "KHM", flag: "kh" },
  { text: "Cameroon", key: 37, value: "CMR", flag: "cm" },
  { text: "Canada", key: 38, value: "CAN", flag: "ca" },
  { text: "Cape Verde", key: 39, value: "CPV", flag: "cv" },
  { text: "Cayman Islands", key: 40, value: "CYM", flag: "ky" },
  { text: "Central African Republic", key: 41, value: "CAF", flag: "cf" },
  { text: "Chad", key: 42, value: "TCD", flag: "td" },
  { text: "Chile", key: 43, value: "CHL", flag: "cl" },
  { text: "China", key: 44, value: "CHN", flag: "cn" },
  { text: "Christmas Island", key: 45, value: "CXR", flag: "cx" },
  { text: "Cocos (Keeling) Islands", key: 46, value: "CCK", flag: "cc" },
  { text: "Colombia", key: 47, value: "COL", flag: "co" },
  { text: "Comoros", key: 48, value: "COM", flag: "km" },
  { text: "Congo", key: 49, value: "COG", flag: "cg" },
  {
    text: "Congo, the Democratic Republic of the",
    key: 50,
    value: "COD",
    flag: "cd",
  },
  { text: "Cook Islands", key: 51, value: "COK", flag: "ck" },
  { text: "Costa Rica", key: 52, value: "CRI", flag: "cr" },
  { text: "Croatia", key: 53, value: "HRV", flag: "hr" },
  { text: "Cuba", key: 54, value: "CUB", flag: "cu" },
  // {text:"Curaçao",key:55,value:"CUW",flag:"cw"},
  { text: "Cyprus", key: 56, value: "CYP", flag: "cy" },
  { text: "Czech Republic", key: 57, value: "CZE", flag: "cz" },
  { text: "Côte d'Ivoire", key: 58, value: "CIV", flag: "ci" },
  { text: "Denmark", key: 59, value: "DNK", flag: "dk" },
  { text: "Djibouti", key: 60, value: "DJI", flag: "dj" },
  { text: "Dominica", key: 61, value: "DMA", flag: "dm" },
  { text: "Dominican Republic", key: 62, value: "DOM", flag: "do" },
  { text: "Ecuador", key: 63, value: "ECU", flag: "ec" },
  { text: "Egypt", key: 64, value: "EGY", flag: "eg" },
  { text: "El Salvador", key: 65, value: "SLV", flag: "sv" },
  { text: "Equatorial Guinea", key: 66, value: "GNQ", flag: "gq" },
  { text: "Eritrea", key: 67, value: "ERI", flag: "er" },
  { text: "Estonia", key: 68, value: "EST", flag: "ee" },
  { text: "Ethiopia", key: 69, value: "ETH", flag: "et" },
  { text: "Falkland Islands (Malvinas)", key: 70, value: "FLK", flag: "fk" },
  { text: "Faroe Islands", key: 71, value: "FRO", flag: "fo" },
  { text: "Fiji", key: 72, value: "FJI", flag: "fj" },
  { text: "Finland", key: 73, value: "FIN", flag: "fi" },
  { text: "France", key: 74, value: "FRA", flag: "fr" },
  { text: "French Guiana", key: 75, value: "GUF", flag: "gf" },
  { text: "French Polynesia", key: 76, value: "PYF", flag: "pf" },
  { text: "French Southern Territories", key: 77, value: "ATF", flag: "tf" },
  { text: "Gabon", key: 78, value: "GAB", flag: "ga" },
  { text: "Gambia", key: 79, value: "GMB", flag: "gm" },
  { text: "Georgia", key: 80, value: "GEO", flag: "ge" },
  { text: "Germany", key: 81, value: "DEU", flag: "de" },
  { text: "Ghana", key: 82, value: "GHA", flag: "gh" },
  { text: "Gibraltar", key: 83, value: "GIB", flag: "gi" },
  { text: "Greece", key: 84, value: "GRC", flag: "gr" },
  { text: "Greenland", key: 85, value: "GRL", flag: "gl" },
  { text: "Grenada", key: 86, value: "GRD", flag: "gd" },
  { text: "Guadeloupe", key: 87, value: "GLP", flag: "gp" },
  { text: "Guam", key: 88, value: "GUM", flag: "gu" },
  { text: "Guatemala", key: 89, value: "GTM", flag: "gt" },
  // {text:"Guernsey",key:90,value:"GGY",flag:"gg"},
  { text: "Guinea", key: 91, value: "GIN", flag: "gn" },
  { text: "Guinea-Bissau", key: 92, value: "GNB", flag: "gw" },
  { text: "Guyana", key: 93, value: "GUY", flag: "gy" },
  { text: "Haiti", key: 94, value: "HTI", flag: "ht" },
  {
    text: "Heard Island and McDonald Islands",
    key: 95,
    value: "HMD",
    flag: "hm",
  },
  { text: "Holy See (Vatican City State)", key: 96, value: "VAT", flag: "va" },
  { text: "Honduras", key: 97, value: "HND", flag: "hn" },
  { text: "Hong Kong", key: 98, value: "HKG", flag: "hk" },
  { text: "Hungary", key: 99, value: "HUN", flag: "hu" },
  { text: "Iceland", key: 100, value: "ISL", flag: "is" },
  { text: "India", key: 101, value: "IND", flag: "in" },
  { text: "Indonesia", key: 102, value: "IDN", flag: "id" },
  { text: "Iran, Islamic Republic of", key: 103, value: "IRN", flag: "ir" },
  { text: "Iraq", key: 104, value: "IRQ", flag: "iq" },
  { text: "Ireland", key: 105, value: "IRL", flag: "ie" },
  // {text:"Isle of Man",key:106,value:"IMN",flag:"im"},
  { text: "Israel", key: 107, value: "ISR", flag: "il" },
  { text: "Italy", key: 108, value: "ITA", flag: "it" },
  { text: "Jamaica", key: 109, value: "JAM", flag: "jm" },
  { text: "Japan", key: 110, value: "JPN", flag: "jp" },
  // {text:"Jersey",key:111,value:"JEY",flag:"je"},
  { text: "Jordan", key: 112, value: "JOR", flag: "jo" },
  { text: "Kazakhstan", key: 113, value: "KAZ", flag: "kz" },
  { text: "Kenya", key: 114, value: "KEN", flag: "ke" },
  { text: "Kiribati", key: 115, value: "KIR", flag: "ki" },
  {
    text: "Korea, Democratic People's Republic of",
    key: 116,
    value: "PRK",
    flag: "kp",
  },
  { text: "Korea, Republic of", key: 117, value: "KOR", flag: "kr" },
  { text: "Kuwait", key: 118, value: "KWT", flag: "kw" },
  { text: "Kyrgyzstan", key: 119, value: "KGZ", flag: "kg" },
  {
    text: "Lao People's Democratic Republic",
    key: 120,
    value: "LAO",
    flag: "la",
  },
  { text: "Latvia", key: 121, value: "LVA", flag: "lv" },
  { text: "Lebanon", key: 122, value: "LBN", flag: "lb" },
  { text: "Lesotho", key: 123, value: "LSO", flag: "ls" },
  { text: "Liberia", key: 124, value: "LBR", flag: "lr" },
  { text: "Libya", key: 125, value: "LBY", flag: "ly" },
  { text: "Liechtenstein", key: 126, value: "LIE", flag: "li" },
  { text: "Lithuania", key: 127, value: "LTU", flag: "lt" },
  { text: "Luxembourg", key: 128, value: "LUX", flag: "lu" },
  { text: "Macao", key: 129, value: "MAC", flag: "mo" },
  {
    text: "Macedonia, the Former Yugoslav Republic of",
    key: 130,
    value: "MKD",
    flag: "mk",
  },
  { text: "Madagascar", key: 131, value: "MDG", flag: "mg" },
  { text: "Malawi", key: 132, value: "MWI", flag: "mw" },
  { text: "Malaysia", key: 133, value: "MYS", flag: "my" },
  { text: "Maldives", key: 134, value: "MDV", flag: "mv" },
  { text: "Mali", key: 135, value: "MLI", flag: "ml" },
  { text: "Malta", key: 136, value: "MLT", flag: "mt" },
  { text: "Marshall Islands", key: 137, value: "MHL", flag: "mh" },
  { text: "Martinique", key: 138, value: "MTQ", flag: "mq" },
  { text: "Mauritania", key: 139, value: "MRT", flag: "mr" },
  { text: "Mauritius", key: 140, value: "MUS", flag: "mu" },
  { text: "Mayotte", key: 141, value: "MYT", flag: "yt" },
  { text: "Mexico", key: 142, value: "MEX", flag: "mx" },
  {
    text: "Micronesia, Federated States of",
    key: 143,
    value: "FSM",
    flag: "fm",
  },
  { text: "Moldova, Republic of", key: 144, value: "MDA", flag: "md" },
  { text: "Monaco", key: 145, value: "MCO", flag: "mc" },
  { text: "Mongolia", key: 146, value: "MNG", flag: "mn" },
  { text: "Montenegro", key: 147, value: "MNE", flag: "me" },
  { text: "Montserrat", key: 148, value: "MSR", flag: "ms" },
  { text: "Morocco", key: 149, value: "MAR", flag: "ma" },
  { text: "Mozambique", key: 150, value: "MOZ", flag: "mz" },
  // {text:"Myanmar",key:151,value:"MMR",flag:"mm"},
  { text: "Namibia", key: 152, value: "NAM", flag: "na" },
  { text: "Nauru", key: 153, value: "NRU", flag: "nr" },
  { text: "Nepal", key: 154, value: "NPL", flag: "np" },
  { text: "Netherlands", key: 155, value: "NLD", flag: "nl" },
  { text: "New Caledonia", key: 156, value: "NCL", flag: "nc" },
  { text: "New Zealand", key: 157, value: "NZL", flag: "nz" },
  { text: "Nicaragua", key: 158, value: "NIC", flag: "ni" },
  { text: "Niger", key: 159, value: "NER", flag: "ne" },
  { text: "Nigeria", key: 160, value: "NGA", flag: "ng" },
  { text: "Niue", key: 161, value: "NIU", flag: "nu" },
  { text: "Norfolk Island", key: 162, value: "NFK", flag: "nf" },
  { text: "Northern Mariana Islands", key: 163, value: "MNP", flag: "mp" },
  { text: "Norway", key: 164, value: "NOR", flag: "no" },
  { text: "Oman", key: 165, value: "OMN", flag: "om" },
  { text: "Pakistan", key: 166, value: "PAK", flag: "pk" },
  { text: "Palau", key: 167, value: "PLW", flag: "pw" },
  { text: "Palestine, State of", key: 168, value: "PSE", flag: "ps" },
  { text: "Panama", key: 169, value: "PAN", flag: "pa" },
  { text: "Papua New Guinea", key: 170, value: "PNG", flag: "pg" },
  { text: "Paraguay", key: 171, value: "PRY", flag: "py" },
  { text: "Peru", key: 172, value: "PER", flag: "pe" },
  { text: "Philippines", key: 173, value: "PHL", flag: "ph" },
  { text: "Pitcairn", key: 174, value: "PCN", flag: "pn" },
  { text: "Poland", key: 175, value: "POL", flag: "pl" },
  { text: "Portugal", key: 176, value: "PRT", flag: "pt" },
  { text: "Puerto Rico", key: 177, value: "PRI", flag: "pr" },
  { text: "Qatar", key: 178, value: "QAT", flag: "qa" },
  { text: "Romania", key: 179, value: "ROU", flag: "ro" },
  { text: "Russian Federation", key: 180, value: "RUS", flag: "ru" },
  { text: "Rwanda", key: 181, value: "RWA", flag: "rw" },
  { text: "Réunion", key: 182, value: "REU", flag: "re" },
  // {text:"Saint Barthélemy",key:183,value:"BLM",flag:"bl"},
  {
    text: "Saint Helena, Ascension and Tristan da Cunha",
    key: 184,
    value: "SHN",
    flag: "sh",
  },
  { text: "Saint Kitts and Nevis", key: 185, value: "KNA", flag: "kn" },
  { text: "Saint Lucia", key: 186, value: "LCA", flag: "lc" },
  // {text:"Saint Martin (French part)",key:187,value:"MAF",flag:"mf"},
  { text: "Saint Pierre and Miquelon", key: 188, value: "SPM", flag: "pm" },
  {
    text: "Saint Vincent and the Grenadines",
    key: 189,
    value: "VCT",
    flag: "vc",
  },
  { text: "Samoa", key: 190, value: "WSM", flag: "ws" },
  { text: "San Marino", key: 191, value: "SMR", flag: "sm" },
  { text: "Sao Tome and Principe", key: 192, value: "STP", flag: "st" },
  { text: "Saudi Arabia", key: 193, value: "SAU", flag: "sa" },
  { text: "Senegal", key: 194, value: "SEN", flag: "sn" },
  { text: "Serbia", key: 195, value: "SRB", flag: "rs" },
  { text: "Seychelles", key: 196, value: "SYC", flag: "sc" },
  { text: "Sierra Leone", key: 197, value: "SLE", flag: "sl" },
  { text: "Singapore", key: 198, value: "SGP", flag: "sg" },
  // {text:"Sint Maarten (Dutch part)",key:199,value:"SXM",flag:"sx"},
  { text: "Slovakia", key: 200, value: "SVK", flag: "sk" },
  { text: "Slovenia", key: 201, value: "SVN", flag: "si" },
  { text: "Solomon Islands", key: 202, value: "SLB", flag: "sb" },
  { text: "Somalia", key: 203, value: "SOM", flag: "so" },
  { text: "South Africa", key: 204, value: "ZAF", flag: "za" },
  {
    text: "South Georgia and the South Sandwich Islands",
    key: 205,
    value: "SGS",
    flag: "gs",
  },
  // {text:"South Sudan",key:206,value:"SSD",flag:"ss"},
  { text: "Spain", key: 207, value: "ESP", flag: "es" },
  { text: "Sri Lanka", key: 208, value: "LKA", flag: "lk" },
  { text: "Sudan", key: 209, value: "SDN", flag: "sd" },
  { text: "Suriname", key: 210, value: "SUR", flag: "sr" },
  { text: "Svalbard and Jan Mayen", key: 211, value: "SJM", flag: "sj" },
  { text: "Swaziland", key: 212, value: "SWZ", flag: "sz" },
  { text: "Sweden", key: 213, value: "SWE", flag: "se" },
  { text: "Switzerland", key: 214, value: "CHE", flag: "ch" },
  { text: "Syrian Arab Republic", key: 215, value: "SYR", flag: "sy" },
  { text: "Taiwan, Province of China", key: 216, value: "TWN", flag: "tw" },
  { text: "Tajikistan", key: 217, value: "TJK", flag: "tj" },
  { text: "Tanzania, United Republic of", key: 218, value: "TZA", flag: "tz" },
  { text: "Thailand", key: 219, value: "THA", flag: "th" },
  { text: "Timor-Leste", key: 220, value: "TLS", flag: "tl" },
  { text: "Togo", key: 221, value: "TGO", flag: "tg" },
  { text: "Tokelau", key: 222, value: "TKL", flag: "tk" },
  { text: "Tonga", key: 223, value: "TON", flag: "to" },
  { text: "Trinidad and Tobago", key: 224, value: "TTO", flag: "tt" },
  { text: "Tunisia", key: 225, value: "TUN", flag: "tn" },
  { text: "Turkey", key: 226, value: "TUR", flag: "tr" },
  { text: "Turkmenistan", key: 227, value: "TKM", flag: "tm" },
  { text: "Turks and Caicos Islands", key: 228, value: "TCA", flag: "tc" },
  { text: "Tuvalu", key: 229, value: "TUV", flag: "tv" },
  { text: "Uganda", key: 230, value: "UGA", flag: "ug" },
  { text: "Ukraine", key: 231, value: "UKR", flag: "ua" },
  { text: "United Arab Emirates", key: 232, value: "ARE", flag: "ae" },
  { text: "United Kingdom", key: 233, value: "GBR", flag: "gb" },
  { text: "United States", key: 234, value: "USA", flag: "us" },
  {
    text: "United States Minor Outlying Islands",
    key: 235,
    value: "UMI",
    flag: "um",
  },
  { text: "Uruguay", key: 236, value: "URY", flag: "uy" },
  { text: "Uzbekistan", key: 237, value: "UZB", flag: "uz" },
  { text: "Vanuatu", key: 238, value: "VUT", flag: "vu" },
  {
    text: "Venezuela, Bolivarian Republic of",
    key: 239,
    value: "VEN",
    flag: "ve",
  },
  { text: "Viet Nam", key: 240, value: "VNM", flag: "vn" },
  { text: "Virgin Islands, British", key: 241, value: "VGB", flag: "vg" },
  { text: "Virgin Islands, U.S.", key: 242, value: "VIR", flag: "vi" },
  { text: "Wallis and Futuna", key: 243, value: "WLF", flag: "wf" },
  { text: "Western Sahara", key: 244, value: "ESH", flag: "eh" },
  { text: "Yemen", key: 245, value: "YEM", flag: "ye" },
  { text: "Zambia", key: 246, value: "ZMB", flag: "zm" },
  { text: "Zimbabwe", key: 247, value: "ZWE", flag: "zw" },
  { text: "Åland Islands", key: 248, value: "ALA", flag: "ax" },
];

export default countryOptions;

import React from "react";
import { Container } from "semantic-ui-react";

import { SequenceList } from "./components/SequenceList";

export default function Sequences() {
  return (
    <Container fluid className="route">
      <SequenceList />
    </Container>
  );
}

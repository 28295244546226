import React from "react";
import { Button, Form } from "semantic-ui-react";

const ExportTags = ({
  tagsData,
  tagsFilters,
  onUpdate,
  onDelete,
  onAdd,
  type,
}) => {
  const tagOptions = tagsData.length ? tagsData : [];
  const tagType = type.toLowerCase();

  const onChangeOperationType = (event, data, fieldIndex) => {
    const newTagFilters = tagsFilters;
    newTagFilters[fieldIndex].operationType = data.value;
    newTagFilters[fieldIndex].label =
      data.value === "contains" ? "Contain tags: " : "Exclude tags: ";
    onUpdate(newTagFilters, tagType);
  };

  const onChangeTagsSelected = (event, data, fieldIndex) => {
    const newTagFilters = tagsFilters;
    newTagFilters[fieldIndex].tags = data.value;
    onUpdate(newTagFilters, tagType);
  };

  const tagSelectableOption = tagsFilters
    ? tagsFilters.map((field, fieldIndex) => (
        <Form.Group key={`tag_filter_${field.uuid}`} className="bottom-align">
          <Form.Select
            key={`tag_filter_operation_${fieldIndex}`}
            options={[
              { key: "contains", text: "Contains", value: "contains" },
              {
                key: "excludes",
                text: "Excludes",
                value: "excludes",
              },
            ]}
            onChange={(event, data) =>
              onChangeOperationType(event, data, fieldIndex)
            }
            defaultValue={tagsFilters[fieldIndex].operationType}
          />
          <Form.Select
            key={`tag_filter_tags_${fieldIndex}`}
            multiple
            search
            className="fluid-field"
            options={tagOptions.map(item => ({
              key: item.id,
              text: item.name,
              value: item.id,
              ...(item.color && {
                className: "hideText",
                label: {
                  content: item.name,
                  className: "ctag",
                  style: { backgroundColor: item.color },
                },
              }),
            }))}
            onChange={(event, data) =>
              onChangeTagsSelected(event, data, fieldIndex)
            }
            renderLabel={item => item.label || item.text}
            defaultValue={tagsFilters[fieldIndex].tags}
          />
          <i
            className="delete link icon red"
            onClick={() => onDelete(field.uuid, tagType)}
          />
        </Form.Group>
      ))
    : [];

  return tagOptions.length !== 0 ? (
    <div style={{ marginBottom: 20 }}>
      <h5 style={{ color: "#666" }}>{type} Tags</h5>
      {tagSelectableOption}
      <Button
        basic
        color="grey"
        size="mini"
        content="Add tag"
        onClick={() => onAdd(tagType)}
      />
    </div>
  ) : null;
};

export default ExportTags;

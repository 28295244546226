import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import AdaptableInput from "../AdaptableInput";
import RuvixxForm from "../RuvixxForm";
import tagConstants from "../../constants/Tag";

const ApplyTagForm = ({ tag, onSubmit, onCancel }) => {
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const fields = tag.tag_metadata_fields
      .map(field => {
        let value;
        if (tag.tag_metadata_values) {
          const valueIndex = tag.tag_metadata_values.findIndex(
            metadataValue => metadataValue.field_id === field.id
          );
          value = tag.tag_metadata_values[valueIndex]?.value;
        }
        return {
          ...field,
          value: value || field.default_value,
        };
      })
      .sort((a, b) => (a.order > b.order ? 1 : -1));
    setFields(fields);
  }, [tag]);

  const handleSubmit = () => {
    onSubmit(fields);
  };

  const handleChange = data => {
    const { name, value } = data;
    const index = fields.findIndex(({ field_name }) => field_name === name);
    const updatedField = {
      ...fields[index],
      value,
    };
    const newFields = [
      ...fields.slice(0, index),
      updatedField,
      ...fields.slice(index + 1),
    ];
    setFields(newFields);
  };

  const overrideableFields = fields.filter(field => field.allow_override);

  const renderFieldInput = field => {
    const fieldType = Object.keys(tagConstants.metadataTypes).find(
      e => tagConstants.metadataTypes[e] == field.field_type
    );
    return (
      <AdaptableInput
        type={fieldType}
        options={field.field_options}
        name={field.field_name}
        value={field.value}
        onChange={(_, data) => handleChange(data)}
        disabled={!field.allow_override}
        maxLength={2048}
      />
    );
  };

  return (
    <RuvixxForm
      ready={fields.every(field => !field.required || !!field.value)}
      onSubmit={overrideableFields.length > 0 ? handleSubmit : undefined}
      onCancel={onCancel}
      cancelButtonText={fields.length == 0 ? "OK" : undefined}
    >
      {fields.length > 0 ? (
        fields.map(field => (
          <Form.Field required={field.required} inline key={field.id}>
            <label>{field.field_name}</label>
            {renderFieldInput(field)}
          </Form.Field>
        ))
      ) : (
        <p>
          There are no metadata fields for this tag or you do not have required
          permissions to edit them.
        </p>
      )}
    </RuvixxForm>
  );
};

export default ApplyTagForm;

import { doRequest } from "./helpers";

const SettingsService = {
  getSettings: async (asObject = false) => {
    const path = "/settings/";
    let settings = await doRequest("GET", path);
    if (asObject) {
      settings = settings.reduce((obj, cur) => {
        const { id, category, name, value } = cur;
        return {
          ...obj,
          [category]: {
            ...obj[category],
            [name]: { id, value },
          },
        };
      }, {});
    }
    return settings;
  },
  updateSettings: newSettings => {
    const path = "/settings/";
    return doRequest("PUT", path, { data: { new_settings: newSettings } });
  },
};

export default SettingsService;

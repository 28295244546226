import { useCallback, useEffect, useState } from "react";
import store from "store";
import observe from "store/plugins/observe";
import { isEqual } from "lodash";

const useStore = key => {
  const [value, setValue] = useState(store.get(key));

  const getValue = useCallback(async () => {
    const newValue = store.get(key);
    if (!isEqual(value, newValue)) {
      setValue(newValue);
    }
  }, [key]);

  useEffect(() => {
    store.addPlugin(observe);
    store.observe(key, getValue);
  }, []);

  return value;
};

export default useStore;

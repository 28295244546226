import React, { useState, useRef, useEffect } from "react";
import { Portal } from "semantic-ui-react";
import { SketchPicker } from "react-color";

import "./ColorPicker.scoped.scss";
import useOuterClick from "../hooks/useOuterClick";

const SWATCH_RATIO = 1.618;
const SWATCH_SIZES = {
  small: {
    padding: 1.5,
    height: 12,
  },
  medium: {
    padding: 2,
    height: 16,
  },
  large: {
    padding: 3,
    height: 20,
  },
};

const ColorPicker = ({
  color = "#ccc",
  size = "medium" /* small | medium | large */,
  setColor,
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [pickerPosition, setPickerPosition] = useState([0, 0]);
  const [innerColor, setInnerColor] = useState(color);
  const swatchRef = useRef();
  const pickerRef = useRef();

  useOuterClick(pickerRef, () => {
    if (pickerOpen) {
      closePicker();
    }
  });

  const { height, padding } = SWATCH_SIZES[size];
  const width = height * SWATCH_RATIO;

  const clickHandler = () => {
    if (setColor === undefined) {
      return;
    }
    if (pickerOpen) {
      closePicker();
      return;
    }
    setPickerOpen(true);
    let [xPos, yPos] = pickerPosition;
    const bounds = swatchRef.current.getBoundingClientRect();
    xPos = bounds.left + window.scrollX;
    yPos = bounds.top + window.scrollY + bounds.height + 1;
    const newPickerPosition = [xPos, yPos];
    setPickerPosition(newPickerPosition);
    setTimeout(() => handleReposition(bounds, newPickerPosition), 10);
  };

  const closePicker = () => {
    setPickerVisible(false);
    setPickerOpen(false);
  };

  const handleReposition = (bounds, pickerPosition) => {
    let [xPos, yPos] = pickerPosition;
    const height = pickerRef.current.offsetHeight;
    if (
      bounds.top + height >
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      yPos -= bounds.height + height + 2;
    }
    setPickerPosition([xPos, yPos]);
    setPickerVisible(true);
  };

  const handleColorChange = color => {
    setInnerColor(color.hex);
  };

  const handleColorComplete = color => {
    setColor(color.hex);
    // closePicker();
  };

  return (
    <div className="color-picker" ref={swatchRef}>
      <Portal open={pickerOpen}>
        <div
          style={{
            position: "absolute",
            zIndex: 1000,
            top: pickerPosition[1],
            left: pickerPosition[0],
            opacity: pickerVisible ? 1 : 0,
          }}
          ref={pickerRef}
        >
          <SketchPicker
            color={innerColor}
            onChange={handleColorChange}
            onChangeComplete={handleColorComplete}
          />
        </div>
      </Portal>
      <div
        className={setColor === undefined ? "" : "clickable"}
        style={{ padding }}
        onClick={clickHandler}
      >
        <div
          className="inner-color"
          style={{ backgroundColor: color, width, height }}
        ></div>
      </div>
    </div>
  );
};

export default ColorPicker;

import React, { useState } from "react";
import { Button, Dropdown, Header, Modal } from "semantic-ui-react";
import VoicemailForm from "./VoicemailForm";

export default function VoicemailModal(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const onSuccess = async => {
    handleClose();
    props.onSuccess();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      trigger={
        props.button ? (
          <Button
            content="New Voicemail"
            onClick={handleOpen}
            size="tiny"
            className="item-adder"
          />
        ) : (
          <Dropdown.Item
            className="primary"
            content={props.voicemailId ? "Edit Voicemail" : "New Voicemail"}
            icon={props.voicemailId ? "edit" : null}
            onClick={handleOpen}
          />
        )
      }
    >
      <Header
        content={props.voicemailId ? "Edit Voicemail" : "New Voicemail"}
      />
      <Modal.Content>
        <VoicemailForm onSuccess={onSuccess} voicemailId={props.voicemailId} />
      </Modal.Content>
    </Modal>
  );
}

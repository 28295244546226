import React from "react";
import { Icon, Progress } from "semantic-ui-react";
import SummaryItem from "./SummaryItem";

const defaultSummary = {
  current_row: 0,
  max_rows: 0,
  updated_contacts: 0,
  updated_entities: 0,
  new_contacts: 0,
  new_entities: 0,
  custom_fields: 0,
};

const ImportSummary = ({
  stage,
  summary = defaultSummary,
  validationSummary,
  campaignName,
  files,
}) => {
  const percent = (summary.current_row / summary.max_rows) * 100;

  const renderFilenames = () => (
    <div className="filenames-label">
      {files.map(upload => (
        <p>{upload.file.name}</p>
      ))}
    </div>
  );

  return (
    <div className="validation-grid">
      <div className="validation-grid">
        {stage === "imported" ? (
          <>
            <Icon name="check circle" color="green" />
            <p className="validation-status">
              Import complete on {campaignName ? "" : "the"}{" "}
              <strong>{campaignName || "Database"}</strong>
            </p>
            {renderFilenames()}
          </>
        ) : (
          <>
            <p className="validation-status">
              Importing data to <strong>{campaignName || "Database"}…</strong>
            </p>
            {renderFilenames()}
            <Progress color="green" percent={percent} size="small" />
          </>
        )}
      </div>
      <div className="SummaryItems">
        <SummaryItem
          title="Rows"
          item={`${summary.current_row} / ${summary.max_rows}`}
        />
        <SummaryItem title="Updated Contacts" item={summary.updated_contacts} />
        <SummaryItem title="Updated Entities" item={summary.updated_entities} />
        <SummaryItem title="New Contacts" item={summary.new_contacts} />
        <SummaryItem title="New Entities" item={summary.new_entities} />
        <SummaryItem title="Custom Fields" item={summary.custom_fields} />
        <SummaryItem
          title="Errors"
          item={validationSummary.error_count}
          color="error"
        />
        <SummaryItem
          title="Warnings"
          item={validationSummary.warning_count}
          color="warning"
        />
      </div>
    </div>
  );
};

export default ImportSummary;

import React, { useCallback, useState } from "react";
import DashboardService from "../../services/Dashboard";
import "./../../styles/campaign.scss";
import "./../../styles/table.scss";
import BaseTableV2 from "../../components/BaseTableV2/BaseTableV2";
import { formatDate } from "../../components/helpers";

const ROUNDING_PRECISION = 2;

const CallAgentMetricsTable = ({
  queryFilters,
  setQueryFilters,
  page,
  setPage,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const formatPercentage = ({ value }) =>
    (value * 100).toFixed(ROUNDING_PRECISION) + "%";
  const formatDecimal = ({ value }) => value.toFixed(ROUNDING_PRECISION);

  const columns = [
    {
      Header: "Agent",
      accessor: "agent",
    },
    {
      Header: "Last Call",
      accessor: "last_call",
      width: 180,
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "# Dials",
      accessor: "num_dials",
    },
    {
      Header: "Avg Dials/Day",
      accessor: "avg_daily_dials",
      Cell: formatDecimal,
    },
    {
      Header: "# Pickups",
      accessor: "num_pickups",
    },
    {
      Header: "Pickup Rate",
      accessor: "pickup_rate",
      Cell: formatPercentage,
    },
    {
      Header: "# Leads",
      accessor: "num_leads",
    },
    {
      Header: "Conversion Rate",
      accessor: "conversion_rate",
      Cell: formatPercentage,
    },
    {
      Header: "Hours Calling",
      accessor: "hours_calling",
      Cell: formatDecimal,
    },
    {
      Header: "Handle Time",
      accessor: "handle_time",
      Cell: formatDecimal,
    },
    {
      Header: "Working Days",
      accessor: "working_days",
    },
  ];

  const queryMethod = DashboardService.getAgentTable;

  const handleUpdateQueryFilters = updatedFilters => {
    setQueryFilters({ ...queryFilters, ...updatedFilters });
  };

  const handleSelectedRows = useCallback(
    rows => {
      setSelectedRows(rows);
    },
    [setSelectedRows]
  );

  const handleFetched = () => {
    setShouldRefetch(false);
  };

  const handleRefetch = () => {
    setShouldRefetch(true);
  };

  return (
    <div className="agent-table">
      <BaseTableV2
        columns={columns}
        queryMethod={queryMethod}
        queryFilters={queryFilters}
        queryKey={"agent-breakdown-table"}
        shouldRefetch={shouldRefetch}
        onFetched={handleFetched}
        config={{
          enableHeader: false,
          enableFooter: true,
          enablePagination: false,
        }}
        onUpdateQueryFilters={handleUpdateQueryFilters}
        exportTableName="CallAgentMetricsTable"
      />
    </div>
  );
};

export default CallAgentMetricsTable;

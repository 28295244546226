import React, { useEffect, useRef } from "react";

function useInterval(callback, delay, runInstantly = false) {
  const savedCallback = useRef();
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });
  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (typeof savedCallback.current !== "undefined") {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      if (runInstantly) {
        tick();
      }
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;

import React, { useState } from "react";
import RuvixxForm from "../RuvixxForm";
import EntityService from "../../services/Entity";
import RuvixxSelect from "../RuvixxSelect";

const TranferContactsForm = ({ contactIds, onSuccess }) => {
  const [entityId, setEntityId] = useState(null);

  const handleSelect = (e, { value }) => {
    setEntityId(value);
  };

  const handleSubmit = async e => {
    await EntityService.transferContacts(entityId, contactIds);
  };

  return (
    <RuvixxForm
      ready={!!entityId}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      submitButtonText="Transfer"
    >
      <RuvixxSelect
        required={true}
        label="Entity"
        name="entity"
        value={entityId}
        onChange={handleSelect}
        queryFn={EntityService.getEntitiesForFilters}
      />
    </RuvixxForm>
  );
};

export default TranferContactsForm;

import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import ConstantContactAccountsService from "../../../services/ConstantContactAccounts";

const AccountForm = ({ model, onSuccess, onCancel, submitButtonText }) => {
  const [emailAddress, setEmailAddress] = useState(model?.email_address);
  const [active, setActive] = useState(!!model?.active);
  const [apiKey, setApiKey] = useState(model?.api_key);
  const [secret, setSecret] = useState("");
  const originalKey = model?.api_key;

  const handleSubmit = async () => {
    const data = {
      email_address: emailAddress,
      active: active,
      api_key: apiKey,
      secret: secret,
    };
    if (model?.id) {
      await ConstantContactAccountsService.update(model.id, data);
    } else {
      await ConstantContactAccountsService.create(data);
    }
  };

  return (
    <RuvixxForm
      ready={
        !!emailAddress &&
        // !!accountId &&
        !!apiKey &&
        (originalKey !== apiKey ? !!secret : true)
      }
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      onCancel={onCancel}
      submitButtonText={submitButtonText}
      cancelButtonText="Cancel"
    >
      <Form.Input
        required
        inline
        label="Email Address"
        className="fillSpace"
        value={emailAddress}
        onChange={(_, { value }) => setEmailAddress(value)}
      />
      {/*<Form.Input*/}
      {/*  required*/}
      {/*  inline*/}
      {/*  label="Account ID"*/}
      {/*  className="fillSpace"*/}
      {/*  value={accountId}*/}
      {/*  onChange={(_, { value }) => setAccountId(value)}*/}
      {/*/>*/}
      <Form.Checkbox
        label="Active"
        checked={active}
        onChange={(_, { checked }) => setActive(checked)}
      />
      <Form.Input
        required
        inline
        label="API Key"
        className="fillSpace"
        value={apiKey}
        onChange={(_, { value }) => {
          setApiKey(value);
        }}
      />
      <Form.Input
        required={!originalKey || apiKey !== originalKey}
        inline
        label="Secret"
        className="fillSpace"
        value={secret}
        placeholder={!!originalKey ? "Leave blank if API Key is unchanged" : ""}
        onChange={(_, { value }) => setSecret(value)}
      />
      <p>
        <span className="requiredStar" /> Indicates a required field
      </p>
    </RuvixxForm>
  );
};

export default AccountForm;

import React, { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import { Icon, Dropdown } from "semantic-ui-react";
import CustomDropdown from "./CustomDropdown";
import "./AudioElement.scoped.scss";

// helpers
const formatTime = time =>
  moment
    .utc(moment.duration(Math.floor(time), "seconds").asMilliseconds())
    .format("mm:ss");
const getPosition = el => el.getBoundingClientRect().left;

const AudioElement = ({ src, id, wide = false }) => {
  const audio = useRef();
  const playhead = useRef();
  const timeline = useRef();
  const [state, setState] = useState("stopped");
  const [duration, setDuration] = useState("00:01");
  const [currentTime, setCurrentTime] = useState("00:00");
  const [muted, setMuted] = useState(false);

  const movePlayhead = useCallback(event => {
    const newMargLeft = event.clientX - getPosition(timeline.current);
    const timelineWidth =
      timeline.current.offsetWidth - playhead.current.offsetWidth;

    playhead.current.style.marginLeft = newMargLeft + "px";
    audio.current.currentTime =
      audio.current.duration * clickPercent(event, timelineWidth);
  }, []);

  useEffect(() => {
    const audioElem = audio.current;
    const timelineElem = timeline.current;
    audioElem.addEventListener("canplaythrough", initializePlayer, false);
    audioElem.addEventListener("timeupdate", timeUpdate, false);
    timelineElem.addEventListener("click", movePlayhead, false);
    return () => {
      timelineElem.removeEventListener("click", movePlayhead);
      audioElem.removeEventListener("canplaythrough", initializePlayer);
      audioElem.removeEventListener("timeupdate", timeUpdate);
    };
  }, [movePlayhead]);

  useEffect(() => {
    audio.current.muted = muted;
  }, [muted]);

  const initializePlayer = () => {
    setDuration(formatTime(audio.current.duration));
  };

  const playAudio = () => {
    if (audio.current.paused) {
      audio.current.play();
      setState("playing");
    } else {
      audio.current.pause();
      setState("paused");
    }
  };

  const timeUpdate = () => {
    const playPercent =
      99 * (audio.current.currentTime / audio.current.duration);
    setCurrentTime(formatTime(audio.current.currentTime));
    playhead.current.style.marginLeft = playPercent + "%";
  };

  const setPlaybackSpeed = speed => (audio.current.playbackRate = speed);

  const clickPercent = (event, timelineWidth) =>
    (event.clientX - getPosition(timeline.current)) / timelineWidth;

  return (
    <div className="audioplayer" style={{ width: wide ? "460px" : "300px" }}>
      <audio id={id} ref={audio}>
        <source src={src} type="audio/ogg" />
        <source src={src} type="audio/mpeg" />
        <source src={src} type="audio/x-m4a" />
      </audio>
      <Icon name={state === "playing" ? "pause" : "play"} onClick={playAudio} />
      <span>
        {currentTime} / {duration}{" "}
      </span>
      <div className="timeline" ref={timeline}>
        <div className="playhead" ref={playhead}></div>
      </div>
      <Icon
        onClick={() => setMuted(!muted)}
        name={muted ? "volume off" : "volume up"}
      />
      <CustomDropdown icon="forward">
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setPlaybackSpeed(1)} text="1x" />
          <Dropdown.Item onClick={() => setPlaybackSpeed(1.5)} text="1.5x" />
          <Dropdown.Item onClick={() => setPlaybackSpeed(2)} text="2x" />
        </Dropdown.Menu>
      </CustomDropdown>
      <a
        style={{ color: "black" }}
        href={src}
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        <Icon name="download" />
      </a>
    </div>
  );
};

export default AudioElement;

import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import store from "store";
import { ApiSetting } from "services/ApiSettings";

const SocketContext = createContext(null);

export function useSocket() {
  return useContext(SocketContext);
}

export function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);

  function createSocket() {
    const userId = store.get("userAuth")?.id;
    if (!userId) return;

    if (socket) return;

    const data = {
      withCredentials: true,
      query: { user_id: userId },
    };
    const newSocket = io(ApiSetting.DEFAULT_HOST, data);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }

  useEffect(() => {
    const cleanup = createSocket();
    return () => {
      if (cleanup) cleanup();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, createSocket }}>
      {children}
    </SocketContext.Provider>
  );
}

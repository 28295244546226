const BOOL = "boolean";
const DEFAULT_BOOL = "false";
const LIST = "list";
const DEFAULT_LIST = [];

const mapCustomFieldValues = (custom, customFieldConfigs) => {
  customFieldConfigs.forEach(cfc => {
    const cf = custom.find(
      cf => cf.key.toLowerCase() === cfc.label.toLowerCase()
    );
    const value = cf?.value;
    const defaultValue =
      cfc.field_type === LIST
        ? DEFAULT_LIST
        : cfc.field_type === BOOL
        ? DEFAULT_BOOL
        : null;
    if (value || cfc.required) {
      cfc["value"] = value || defaultValue;
      cfc["selected"] = true;
    }
    if (!value) {
      cfc["value"] = defaultValue;
    }
  });
  return customFieldConfigs;
};

export { mapCustomFieldValues };

import React, { useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./DraggableContainer.scoped.scss";

function DraggableContainer(props) {
  const children = props.children;
  const [isDragging, setIsDragging] = useState(false);
  const handleDragStart = (dragEvent, b) => {
    setIsDragging(true);
    if (props.onDragStart) {
      props.onDragStart(dragEvent, b);
    }
  };

  const handleDragEnd = (dragEvent, b) => {
    setIsDragging(false);
    if (!dragEvent.source || !dragEvent.destination) {
      return;
    }

    const key = dragEvent.draggableId;
    const src = dragEvent.source.index;
    const dst = dragEvent.destination.index;
    props.onUpdateChildrenOrder(key, src, dst);
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    let style = {
      ...draggableStyle,
    };
    return style;
  };

  return (
    <DragDropContext
      onDragStart={(dragEvent, b) => handleDragStart(dragEvent, b)}
      onDragEnd={(dragEvent, b) => handleDragEnd(dragEvent, b)}
    >
      <Droppable
        droppableId="droppable"
        direction={props.direction || "vertical"}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`draggable-container ${props.className} ${
              isDragging ? "dragging" : ""
            }`}
          >
            {children.map((child, index) => (
              <Draggable
                key={`draggable-${index}`}
                draggableId={`draggable-${index}`}
                index={index}
              >
                {(provided, snapshot) => {
                  provided.draggableProps.style.display =
                    props.direction === "horizontal" ? "inline-block" : "block";
                  const draggable = (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {child}
                    </div>
                  );
                  if (snapshot.isDragging) {
                    return ReactDOM.createPortal(draggable, document.body);
                  }
                  return draggable;
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableContainer;

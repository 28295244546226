import React, { useState } from "react";
import { Button, Form, Modal, Dropdown } from "semantic-ui-react";
import CustomEmailDomainsService from "services/CustomEmailDomains";
import { useEffect } from "react";
import RuvixxForm from "components/RuvixxForm";
import { Popup } from "semantic-ui-react";

const VERIFIED_COLOR_MAP = {
  true: "green",
  false: "orange",
  null: "grey",
};

const DomainModal = ({ domain, fetchDomains, button }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [domainName, setDomainName] = useState(domain ? domain.name : "");
  const [verificationStatus, setVerificationStatus] = useState(
    domain ? domain.verified : null
  );
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    fetchDomains();
    setModalOpen(false);
    setDomainName("");
    setVerificationStatus(null);
  };

  const handleSubmit = async () => {
    if (domain) {
      let data = {
        domainName,
        isDefault: domain.is_default,
      };
      // This allows to set verificationStatus to null
      if (verificationStatus !== null) {
        data.verificationStatus = verificationStatus;
      }
      await CustomEmailDomainsService.updateDomain(domain.id, data);
    } else {
      let data = {
        domainName,
        isDefault: false,
      };
      if (verificationStatus !== null) {
        data.verificationStatus = verificationStatus;
      }
      await CustomEmailDomainsService.addDomain(data);
    }
    handleClose();
  };

  useEffect(() => {
    if (domain && domainName !== domain.name) {
      setVerificationStatus(false);
    }
  }, [domainName]);

  const handleVerify = async () => {
    const response = await CustomEmailDomainsService.verifyDomain(domainName);
    setVerificationStatus(response.verified);
    if (response.error) {
      setErrorMessage(response.error);
      setError(true);
    }
  };

  return (
    <Modal
      size="small"
      trigger={
        button ? (
          <Button
            content="New Domain"
            onClick={handleOpen}
            size="tiny"
            className="item-adder"
          />
        ) : (
          <Dropdown.Item
            className="primary"
            content="Edit Domain"
            icon="edit"
            onClick={handleOpen}
          />
        )
      }
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>{domain ? "Edit" : "New"} Custom Email Domain</Modal.Header>
      <Modal.Content>
        <RuvixxForm
          ready={!!domainName}
          onSubmit={handleSubmit}
          onSuccess={handleClose}
          onCancel={handleClose}
          error={true}
          errorTitle={"errorTitle"}
          errorMessage={"errorMessage"}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Form.Input
              inline
              required
              label="Domain Name"
              placeholder="ex. example.com"
              value={domainName}
              onChange={e => setDomainName(e.target.value)}
              style={{ flexGrow: 1, marginRight: "1em" }}
            />
            <Popup
              content="Domain DNS may take 24-48 hours to propagate"
              trigger={
                <Form.Button
                  inline
                  disabled={!domainName}
                  color={VERIFIED_COLOR_MAP[verificationStatus]}
                  content={verificationStatus ? "Verified" : "Check Verify"}
                  onClick={handleVerify}
                />
              }
            />
          </div>
        </RuvixxForm>
      </Modal.Content>
    </Modal>
  );
};

export default DomainModal;

import { applyFilters, doRequest } from "./helpers";

export default class MachineService {
  static async getTable(filters = null) {
    let path = "/machine/";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async get(id) {
    return doRequest("GET", `/machine/${id}`);
  }

  static async getForFilters(filters = null) {
    let path = "/machine/for_filters";
    path = applyFilters(filters, path);
    return doRequest("get", path);
  }

  static async updateStatus(data) {
    return doRequest("PUT", "/machine/status", {
      data: data,
    });
  }
}

import AttachmentService from "../services/Attachment";
import axios from "axios";

export default class UploadManager {
  constructor() {
    this.progressListeners = [];
    this.successListeners = [];
    this.uploads = [];
  }

  addProgressListener = listener => {
    return this.progressListeners.push(listener) - 1;
  };

  addSuccessListener = listener => {
    return this.successListeners.push(listener) - 1;
  };

  removeProgressListener = id => {
    this.progressListeners.splice(id, 1);
  };

  removeSuccessListener = id => {
    this.successListeners.splice(id, 1);
  };

  dispatchProgressEvent = () => {
    this.progressListeners.forEach(listener => listener(this.uploads));
  };

  dispatchSuccessEvent = upload => {
    this.successListeners.forEach(listener => listener(upload));
  };

  updateUploadData = upload => {
    let index = this.getUploadIndex(upload.id);
    if (index === -1) {
      index = this.uploads.push({}) - 1;
    }
    this.uploads[index] = {
      ...this.uploads[index],
      ...upload,
    };
    return { ...this.uploads[index] };
  };

  getUploadId = file => {
    return `${file.name}-${file.lastModified}-${file.size}-${file.type}`;
  };

  getUploadIndex = id => {
    return this.uploads.findIndex(upload => upload.id === id);
  };

  uploadFiles = async files => {
    const uploads = files
      .map(file => ({
        file,
        id: this.getUploadId(file),
        status: "queued",
        loaded: 0,
        percent: 0,
        attachment: null,
        source: axios.CancelToken.source(),
      }))
      .filter(upload => this.getUploadIndex(upload.id) === -1);
    this.uploads = this.uploads.concat(uploads);
    this.dispatchProgressEvent();
    for (const upload of uploads) {
      const attachment = await AttachmentService.uploadFile(
        upload.file,
        progress => {
          this.updateUploadData({
            id: upload.id,
            status: "uploading",
            loaded: progress.loaded,
            percent: Math.round((progress.loaded / progress.total) * 100),
          });
          this.dispatchProgressEvent();
        },
        upload.source.token
      );
      const uploadData = this.updateUploadData({
        id: upload.id,
        status: "finished",
        attachment,
      });
      this.dispatchProgressEvent();
      this.dispatchSuccessEvent(uploadData);
    }
  };

  removeUpload = upload => {
    const index = this.getUploadIndex(upload.id);
    if (index === -1) {
      return;
    }
    this.uploads.splice(index, 1);
    this.dispatchProgressEvent();
    upload.source.cancel();
  };

  removeUploads = () => {
    for (const upload of this.uploads) {
      this.removeUpload(upload);
    }
  };
}

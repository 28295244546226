import PropTypes from "prop-types";
import React, { Component } from "react";
import "./ProgressRing.scoped.scss";

export default class ProgressRing extends Component {
  static propTypes = {
    error: PropTypes.bool,
    percent: PropTypes.number,
    isRing: PropTypes.bool,
    label: PropTypes.string,
    radius: PropTypes.number,
    color: PropTypes.string,
    completeColor: PropTypes.string,
    ringColor: PropTypes.string,
    ringWidth: PropTypes.number,
    xOnHover: PropTypes.bool,
    xSize: PropTypes.number,
    xColor: PropTypes.string,
    onClick: PropTypes.func,
    alwaysShowX: PropTypes.bool,
  };

  static defaultProps = {
    error: false,
    percent: 0,
    isRing: true,
    label: "",
    radius: 9,
    color: "#8080FF",
    completeColor: "#21A712",
    ringColor: "#CCCCCC",
    ringWidth: 3,
    xOnHover: true,
    xSize: 3,
    xColor: "#999999",
    alwaysShowX: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      showX: false,
      angle: this.calculateAngle(this.props.percent),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.percent !== this.props.percent) {
      this.setState({
        angle: this.calculateAngle(this.props.percent),
      });

      if (this.props.percent === 100) {
        this.setState({ showX: false });
      }
    }
  }

  calculateAngle(percent) {
    return (2 * Math.PI * percent) / 100;
  }

  setShowX(show) {
    if (!this.props.xOnHover) {
      return;
    }
    this.setState({ showX: show });
  }

  render() {
    const {
      label,
      radius,
      cancelUpload,
      ringColor,
      ringWidth,
      percent,
      isRing,
      xSize,
      showX,
      alwaysShowX,
      angle,
      onClick,
      xColor,
    } = { ...this.props, ...this.state };

    const r = radius - (isRing ? ringWidth / 2 : 0);
    let color =
      this.props.percent < 100 ? this.props.color : this.props.completeColor;

    return (
      <div className="progress-ring">
        <div className="progress-ring-container" onClick={onClick}>
          <svg
            width={radius * 2}
            height={radius * 2}
            xmlns="http://www.w3.org/2000/svg"
            onClick={cancelUpload}
            onMouseEnter={() => this.setShowX(true)}
            onMouseLeave={() => this.setShowX(false)}
          >
            {percent < 100 ? (
              <>
                {isRing && (
                  <circle
                    cx={radius}
                    cy={radius}
                    r={r}
                    fill="transparent"
                    stroke={ringColor}
                    strokeWidth={ringWidth}
                  />
                )}
                <path
                  d={`M ${radius} ${radius - r}
                      A ${r}, ${r}, 0, ${percent > 50 ? 1 : 0}, 1, ${
                    radius + Math.sin(angle) * r
                  } ${radius - Math.cos(angle) * r}
                      ${isRing ? " " : `L ${r} ${r} Z`}`}
                  fill={isRing ? "transparent" : color}
                  stroke={isRing ? color : "none"}
                  strokeWidth={ringWidth}
                />
              </>
            ) : (
              <circle
                cx={radius}
                cy={radius}
                r={r}
                fill={isRing ? "transparent" : color}
                stroke={isRing ? color : "none"}
                strokeWidth={ringWidth}
              />
            )}
            {(showX || alwaysShowX) && (
              <path
                d={`M ${radius - xSize} ${radius - xSize}
                  L ${radius + xSize} ${radius + xSize}
                  M ${radius - xSize} ${radius + xSize}
                  L ${radius + xSize} ${radius - xSize}`}
                stroke={xColor}
                strokeWidth={2}
                className="the-x"
              />
            )}
          </svg>
        </div>
        <div className="label">{label}</div>
      </div>
    );
  }
}

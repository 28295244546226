import React, { memo, useEffect, useState } from "react";
import { Button, Checkbox, Form, Transition } from "semantic-ui-react";
import EmailFooterService from "../../../services/EmailFooters";
import RenderEmailTemplatePreviewModal from "../components/RenderEmailTemplatePreviewModal";

function RenderTemplateForm({
  templateFormId,
  providerId,
  updateProviderList,
  templateName,
  templateSubject,
  isVisible,
}) {
  const [footers, setFooters] = useState([]);
  const [footerId, setFooterId] = useState(null);
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (visible) {
      setVisible(false);
      updateProviderList(providerId);
    } else {
      setVisible(true);
      updateProviderList(providerId);
    }
  };

  useEffect(() => {
    (async () => {
      const footers = await EmailFooterService.getEmailFootersForFilters();
      setFooters(mapForSelect(footers));
    })();
  }, []);

  useEffect(() => {
    if (isVisible) {
      setVisible(isVisible);
    }
  }, [isVisible]);

  const mapForSelect = array =>
    array.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));

  const handleFooterChange = (_, { value }) => {
    setFooterId(value);
  };

  return (
    <>
      <Checkbox
        size="tiny"
        toggle
        name="enable-preview"
        checked={visible}
        onChange={toggleVisibility}
        style={{ display: "inline-block", float: "right" }}
      />
      <Transition visible={visible}>
        <Form>
          <Form.Select
            inline
            search
            label="Footer"
            name="footerId"
            options={footers}
            onChange={handleFooterChange}
          />
          <div style={{ marginTop: "10px", display: "flex" }}>
            <RenderEmailTemplatePreviewModal
              footerId={footerId}
              templateId={templateFormId}
              providerId={providerId}
              templateSubject={templateSubject}
              templateName={templateName}
            />
          </div>
        </Form>
      </Transition>
    </>
  );
}

export default memo(RenderTemplateForm);

import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import { checkIsAuthorized } from "../../components/helpers";
import NotificationService from "../../services/Notification";

import "./Actions.scoped.scss";

const Actions = ({ selectedRows, handleRefetch, queryFilters, isArchived }) => {
  const selectionMade = selectedRows.length > 0;
  const [markAsReadLoading, setMarkAsReadLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);

  const canMarkAsRead = checkIsAuthorized([
    ACL_RELATIONSHIPS.notifications.markAsRead,
  ]);
  const canArchive = checkIsAuthorized([
    ACL_RELATIONSHIPS.notifications.archive,
  ]);

  const handleArchive = async () => {
    setArchiveLoading(true);
    const notificationIds = selectedRows.map(row => row.id);
    await NotificationService.archive(notificationIds, queryFilters);
    await handleRefetch();
    setArchiveLoading(false);
  };

  const handleMarkAsRead = async () => {
    setMarkAsReadLoading(true);
    const notificationIds = selectedRows.map(row => row.id);
    await NotificationService.markAsRead(notificationIds, queryFilters);
    await handleRefetch();
    setMarkAsReadLoading(false);
  };

  return (
    <div className="inbox-actions">
      {canArchive && !isArchived && (
        <Button
          content="Archive"
          size="mini"
          primary
          disabled={!selectionMade}
          onClick={handleArchive}
          loading={archiveLoading}
          disabled={archiveLoading}
        />
      )}
      {canMarkAsRead && (
        <Button
          content={selectionMade ? "Mark As Read" : "Mark All Read"}
          size="mini"
          primary
          onClick={handleMarkAsRead}
          loading={markAsReadLoading}
          disabled={markAsReadLoading}
        />
      )}
    </div>
  );
};

export default Actions;

import React, { Component } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import RuvixxForm from "../RuvixxForm";
import ActivityService from "../../services/Activity";

class EditCategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.category.value,
      name: this.props.category.text,
    };
  }

  handleSubmit = async e => {
    const { id, name } = this.state;
    await ActivityService.editCategory(id, { name });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { id, name } = this.state;
    return (
      <RuvixxForm
        ready={!!id && !!name}
        onSubmit={this.handleSubmit}
        onSuccess={this.props.onSuccess}
      >
        <Form.Input
          inline
          name="name"
          label="Name"
          required
          value={name}
          onChange={this.handleChange}
        />
      </RuvixxForm>
    );
  }
}

export class EditCategoryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSuccess = async () => {
    await this.props.onSuccess(); // maybe-promise
    this.handleClose();
  };

  render() {
    return (
      <Modal
        size="small"
        trigger={
          <Button size="tiny" onClick={this.handleOpen}>
            Edit Category
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>Edit Category</Modal.Header>
        <Modal.Content>
          <EditCategoryForm
            category={this.props.category}
            onSuccess={this.handleSuccess}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default EditCategoryModal;

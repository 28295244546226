import { applyFilters, doRequest, downloadFile } from "./helpers";

export default class FormResponseService {
  static async getFormResponses(filters) {
    let path = `/form_response/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getFormResponse(id) {
    let path = `/form_response/${id}`;
    return doRequest("GET", path);
  }

  static async downloadAttachments(ids) {
    ids = Array.isArray(ids) ? ids : [ids];
    await downloadFile(`/form_response/attachments?response_ids=${ids}`);
  }

  static async getFormResponseAnswers(id) {
    let path = `/form_response/${id}/answers`;
    return doRequest("GET", path);
  }

  static async getFollowups(id) {
    let path = `/form_response/${id}/followups`;
    return doRequest("GET", path);
  }
}

import React from "react";
import { Icon, Progress } from "semantic-ui-react";
import SummaryItem from "./SummaryItem";

const ValidationSummary = ({ stage, summary, isExpired, files }) => {
  const percent = (summary.current_row / summary.max_rows) * 100;
  const isError = isExpired || ["failed", "aborted"].includes(stage);

  const getErrorString = () => {
    if (isExpired) {
      return "Expired";
    }
    return stage === "aborted" ? "Aborted" : "Failed";
  };

  const renderFilenames = () => (
    <div className="filenames-label">
      {files.map((upload, i) => (
        <p key={i}>{upload.file.name}</p>
      ))}
    </div>
  );

  return (
    <div className="validation-grid">
      {stage === "validated" && !isError ? (
        <div className="validation-grid">
          <Icon name="check circle" color="green" />
          <p className="validation-status">Analysis complete</p>
          {renderFilenames()}
        </div>
      ) : (
        <div className="validation-grid">
          {isError && <Icon name="times circle" color="red" />}
          <p className="validation-status">
            {isError ? getErrorString() : "Analizing…"}
          </p>
          {renderFilenames()}
          {!isExpired && (
            <Progress
              color={isError ? "red" : "green"}
              percent={isError ? 100 : percent}
              size="small"
              className="analisis-progress"
            />
          )}
        </div>
      )}
      <div className="SummaryItems">
        <SummaryItem
          title="Rows"
          item={`${summary.current_row} / ${summary.max_rows}`}
          color="item"
        />
        <SummaryItem
          title="Existing Contacts"
          item={summary.existing_contact_count}
          color="item"
        />
        <SummaryItem
          title="Existing Entities"
          item={summary.existing_entity_count}
          color="item"
        />
        <SummaryItem
          title="New Contacts"
          item={summary.new_contact_count}
          color="item"
        />
        <SummaryItem
          title="New Entities"
          item={summary.new_entity_count}
          color="item"
        />
        <SummaryItem title="Errors" item={summary.error_count} color="error" />
        <SummaryItem
          title="Warnings"
          item={summary.warning_count}
          color="warning"
        />
      </div>
    </div>
  );
};

export default ValidationSummary;

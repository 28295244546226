import _ from "lodash";
import React from "react";
import { Tab, Menu } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function getElementType(Component, props, getDefault) {
  const { defaultProps = {} } = Component;
  if (props.as && props.as !== defaultProps.as) return props.as;

  if (getDefault) {
    const computedDefault = getDefault();
    if (computedDefault) return computedDefault;
  }

  if (props.href) return "a";

  return defaultProps.as || "div";
}

const getUnhandledProps = (Component, props) => {
  // Note that `handledProps` are generated automatically during build with `babel-plugin-transform-react-handled-props`
  const { handledProps = [] } = Component;

  return Object.keys(props).reduce((acc, prop) => {
    if (prop === "childKey") return acc;
    if (handledProps.indexOf(prop) === -1) acc[prop] = props[prop];
    return acc;
  }, {});
};

class DraggableTab extends Tab {
  render() {
    const menu = this.renderMenu();
    const rest = getUnhandledProps(Tab, this.props);
    const ElementType = getElementType(Tab, this.props);

    if (menu.props.vertical) {
      return <ElementType {...rest}>{this.renderVertical(menu)}</ElementType>;
    }

    return (
      <ElementType {...rest}>
        {menu.props.attached !== "bottom" && menu}
        {this.renderItems()}
        {menu.props.attached === "bottom" && menu}
      </ElementType>
    );
  }

  handleDragStart(self, dragEvent, b) {
    if (self.props.onDragStart) {
      self.props.onDragStart(dragEvent, b);
    }
    // TODO: handle internally??
  }

  handleDragEnd(self, dragEvent, b) {
    if (!dragEvent.source || !dragEvent.destination) {
      return;
    }
    if (self.props.onUpdateTabOrder) {
      const key = dragEvent.draggableId;
      const src = dragEvent.source.index;
      const dst = dragEvent.destination.index;
      self.props.onUpdateTabOrder(key, src, dst);
    }
    // TODO: handle internally??
  }

  renderMenu() {
    const { menu, panes, menuPosition } = this.props;
    const { activeIndex } = this.state;

    if (menu.tabular === true && menuPosition === "right") {
      menu.tabular = "right";
    }

    const panesLength = panes.filter(p => !!p.pane).length;
    if (activeIndex > panesLength - 1) {
      this.setState({ activeIndex: panesLength - 1 });
    }

    return (
      <DragDropContext
        onDragStart={(dragEvent, b) => this.handleDragStart(this, dragEvent, b)}
        onDragEnd={(dragEvent, b) => this.handleDragEnd(this, dragEvent, b)}
      >
        <Droppable droppableId="droppable" direction="horizontal">
          {droppableProvided => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              <Menu {...menu}>
                {panes.map(({ menuItem }, index) => (
                  <Draggable
                    key={menuItem.key || `draggable-${index}`}
                    draggableId={menuItem.key || `draggable-${index}`}
                    index={index}
                    isDragDisabled={menuItem.props.isDragDisabled}
                  >
                    {draggableProvided => (
                      <div
                        onClick={e => {
                          _.invoke(this.props, "onTabChange", e, {
                            ...this.props,
                            activeIndex: index,
                          });
                          this.setState({ activeIndex: index });
                        }}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        {menuItem.type === Menu.Item ? (
                          <Menu.Item
                            {...menuItem.props}
                            active={this.state.activeIndex === index}
                          />
                        ) : (
                          <>{menuItem}</>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </Menu>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DraggableTab;

import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import GroupService from "../../../services/Group";

const GroupForm = ({ model, onSuccess, onCancel, submitButtonText }) => {
  const [name, setName] = useState(model?.name || "");
  const [internal, setInternal] = useState(model?.internal || false);

  const handleSubmit = async () => {
    const data = {
      name,
      internal,
    };
    if (model?.id) {
      await GroupService.update(model.id, data);
    } else {
      await GroupService.create(data);
    }
  };

  return (
    <RuvixxForm
      ready={!!name}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      onCancel={onCancel}
      submitButtonText={submitButtonText}
      cancelButtonText="Cancel"
    >
      <Form.Input
        required
        inline
        label="Name"
        className="fillSpace"
        value={name}
        onChange={(_, { value }) => setName(value)}
      />
      {/*<Form.Checkbox*/}
      {/*  toggle*/}
      {/*  inline*/}
      {/*  label="Internal"*/}
      {/*  className="fillSpace"*/}
      {/*  checked={internal}*/}
      {/*  onChange={(_, { checked }) => setInternal(checked)}*/}
      {/*/>*/}
      <p>
        <span className="requiredStar" /> Indicates a required field
      </p>
    </RuvixxForm>
  );
};

export default GroupForm;

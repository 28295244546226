import { useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";
import useRouteParams from "./useRouteParams";

const RouteParamsManager = ({
  defaultParams,
  routeParams: parentRouteParams,
  setRouteParams: parentSetRouteParams,
}) => {
  const [routeParams, setRouteParams] = useRouteParams(defaultParams);
  const changed = useRef();

  useEffect(() => {
    if (parentRouteParams && !isEqual(routeParams, parentRouteParams)) {
      setRouteParams(parentRouteParams);
      changed.current = true;
    }
  }, [parentRouteParams]);

  useEffect(() => {
    if (
      parentSetRouteParams &&
      (!isEqual(routeParams, parentRouteParams) || changed.current)
    ) {
      parentSetRouteParams(routeParams);
      changed.current = false;
    }
  }, [routeParams]);

  return null;
};

export default RouteParamsManager;

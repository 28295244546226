import React, { useState } from "react";
import {
  Button,
  Header,
  Modal,
  Confirm,
  Popup,
  Dropdown,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import EmailFooterService from "../../../services/EmailFooters";
import EmailFooterForm from "../forms/EmailFooterForm";

function EmailFooterModal({ fetchFooters, isClone, emailFooterId }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [name, setName] = useState("");
  const [html, setHtml] = useState("");

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(true);
  };

  const handleCancelClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setModalOpen(false);
  };

  const handleSuccess = async () => {
    await fetchFooters();
    handleConfirmClose();
  };

  return (
    <>
      {emailFooterId ? (
        <Dropdown.Item
          className="primary"
          content={isClone ? "Clone Email Footer" : "Edit Email Footer"}
          icon={isClone ? "clone" : "pencil"}
          onClick={handleOpen}
        />
      ) : (
        <Button
          content="New Footer Template"
          onClick={handleOpen}
          size="tiny"
          className="item-adder"
        />
      )}
      <Confirm
        className="confirm"
        open={confirmOpen}
        onConfirm={handleConfirmClose}
        onCancel={handleCancelClose}
        header={isClone ? "Clone Email Footer" : "Edit Email Footer"}
        content="Are you sure? Closing this window will undo unsaved changes"
      />
      <Modal
        open={modalOpen}
        onClose={handleClose}
        size="large"
        closeIcon
        closeOnDimmerClick={false}
      >
        <Header
          content={
            isClone
              ? "Clone Email Footer"
              : emailFooterId
              ? "Edit Email Footer"
              : "New Email Footer"
          }
        />
        <Modal.Content>
          {
            <EmailFooterForm
              onSuccess={handleSuccess}
              isClone={isClone}
              emailFooterId={emailFooterId}
            />
          }
        </Modal.Content>
      </Modal>
    </>
  );
}

export default EmailFooterModal;

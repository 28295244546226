import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty, startCase } from "lodash";
import { Container, Divider, Icon, Menu, Segment } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import CallsTable from "../../components/CallsTable";
import CopyCCEmail from "../../components/CopyCCEmail";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import PhoneDetails from "../../components/PhoneDetails";
import Sidebar from "../../components/Sidebar";

import AuditLog from "../../components/views/AuditLog";
import CampaignTable from "../../components/views/CampaignTable";
import NotesView from "../../components/views/NotesView";
import ResponsesView from "../../components/views/ResponsesView";
import CONFIG from "../../Config";
import { generateTabs } from "../../constants/Constants";
import { noTag } from "../../constants/Tag";
import { isContactEmailable, isContactCallable } from "../../helpers/contacts";
import setPageTitle from "../../helpers/title";
import useRouteParam from "../../hooks/params/useRouteParam";
import useTags from "../../hooks/useTags";
import AuthService from "../../services/Auth";
import { SendEmailModal as _SendEmailModal } from "../campaign/components/Modals";

import { EditContactModal as _EditContactModal } from "../entity/components/Modals";
import TaskList from "../tasks/TaskList";

import ContactService from "./../../services/Contact";
import EntityService from "./../../services/Entity";

import "./../../styles/navbar.scss";
import ActivityService from "../../services/Activity";

const EditContactModal = withRoleCheck(_EditContactModal, [
  ACL_RELATIONSHIPS.entityContact.edit,
  ACL_RELATIONSHIPS.entityForFilters.read,
]);
const SendEmailModal = withRoleCheck(_SendEmailModal, [
  ACL_RELATIONSHIPS.emailTemplates.read,
  ACL_RELATIONSHIPS.contactEmail.create,
]);

const tabs = generateTabs(
  "activityLog",
  "notes",
  "responses",
  "calls",
  "campaigns",
  "tasks"
);

const Contact = () => {
  const [contact, setContact] = useState({});
  const [contactName, setContactName] = useState("");
  const [alternateNames, setAlternateNames] = useState([]);
  const [entity, setEntity] = useState({});
  const [entityId, setEntityId] = useState(0);
  const [entityName, setEntityName] = useState("");
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [title, setTitle] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const contactTags = useTags("contact");
  const entityTags = useTags("entity");
  const [customFields, setCustomFields] = useState([]);
  const [entityCustomFields, setEntityCustomFields] = useState([]);
  const [url, setUrl] = useState("");
  const [accountManagers, setAccountManagers] = useState([]);
  const [enableCall, setEnableCall] = useState(false);
  const [isCallable, setIsCallable] = useState(false);
  const [focusOnNewNote, setFocusOnNewNote] = useState(false);
  const [auditLogId, setAuditLogId] = useState(null);
  const [updateAuditLogFlag, setUpdateAuditLogFlag] = useState(0);
  const [activeTab, setActiveTab] = useRouteParam("tab", tabs[0].name);
  const [contactId] = useRouteParam("id");
  const [hasNotes, setHasNotes] = useState(false);

  const fetchNotes = useCallback(async () => {
    if (!contactId) return;
    const result = await ActivityService.hasNotes(contactId, "Contact");
    setHasNotes(result);
  }, [contactId]);

  useEffect(() => {
    if (AuthService.isLoggedIn() && contactId) {
      fetchContact();
      fetchNotes();
    }
  }, [contactId, fetchNotes]);

  const getOutreachStatus = () => {
    const { emailable, callable } = contact;
    const GreenCheck = <Icon name="check" color="green" />;
    const RedCross = <Icon name="close" color="red" />;
    return (
      <p>
        Emailable: {emailable ? GreenCheck : RedCross}
        <br />
        Callable: {callable ? GreenCheck : RedCross}
      </p>
    );
  };

  const fetchContact = async () => {
    const contact = await ContactService.getContact(contactId);

    if (contact.tags.length === 0) {
      contact.tags = [noTag];
    }
    const {
      entity_id: entityId,
      full_name: contactName,
      alternate_names: alternateNames,
      email_addresses: emailAddresses = [],
      title,
      department_name: departmentName,
      phone_numbers: phoneNumbers,
      custom_fields: customFields,
    } = contact;
    const entity = await EntityService.getEntity(entityId);
    if (entity.tags.length === 0) {
      entity.tags = [noTag];
    }
    const { name: entityName, url, account_managers: accountManagers } = entity;
    setContact(contact);
    setPageTitle(contactName);
    setContactName(contactName);
    setAlternateNames(alternateNames);
    setEntity(entity);
    setEntityId(entityId);
    setEntityName(entityName);
    setEmailAddresses(emailAddresses);
    setDepartmentName(departmentName);
    setTitle(title);
    setPhoneNumbers(areNumbersDisabled(phoneNumbers) ? [] : phoneNumbers);
    setCustomFields(customFields);
    setEntityCustomFields(entity.custom_fields);
    setUrl(url);
    setAccountManagers(accountManagers);
    setEnableCall(phoneNumbers.some(({ enabled }) => enabled));
    setIsCallable(phoneNumbers.some(({ is_callable }) => is_callable));
    setUpdateAuditLogFlag(updateAuditLogFlag + 1);
  };

  const areNumbersDisabled = phoneNumbers => {
    for (const pn of phoneNumbers) {
      if (pn["enabled"]) {
        return false;
      }
    }

    return true;
  };

  const fetchEmailedForms = async filters => {
    filters = filters || {};
    return await ContactService.getEmails(contactId, filters);
  };

  const handleTabChange = (
    e,
    { name, focusOnNewNote = false, auditLogId = null }
  ) => {
    setActiveTab(name);
    setFocusOnNewNote(focusOnNewNote);
    setAuditLogId(auditLogId);
  };

  const renderTabView = useMemo(() => {
    switch (activeTab) {
      case "activityLog":
        return (
          <AuditLog
            modelId={contactId}
            modelType="Contact"
            auditLogId={auditLogId}
            updateFlag={updateAuditLogFlag}
          />
        );
      case "notes":
        return (
          <NotesView
            modelType="Contact"
            modelId={+contactId}
            focusOnNewNote={focusOnNewNote}
            handleTabChange={handleTabChange}
          />
        );
      case "responses":
        return (
          <ResponsesView
            modelId={contactId}
            modelType="Contact"
            fetchEmailedForms={fetchEmailedForms}
          />
        );
      case "calls":
        return (
          <Segment
            style={{
              boxShadow: "0px 0px 5px 1px #ddd",
            }}
          >
            <CallsTable entityId={entityId} contactId={+contactId} />
          </Segment>
        );
      case "campaigns":
        return (
          <CampaignTable
            contactId={contactId}
            sendEmail={isContactEmailable(contact)}
          />
        );
      case "tasks":
        return <TaskList modelType="Contact" modelId={contactId} />;
      default:
        return null;
    }
  }, [activeTab, contactId, auditLogId, focusOnNewNote, updateAuditLogFlag]);

  const renderSidebar = () => (
    <Sidebar
      modelName="contacts"
      modelId={contactId}
      fetchModel={fetchContact}
      icon="user"
      name={contactName}
      hasNotes={hasNotes}
      editButton={
        !isEmpty(contact) && (
          <EditContactModal
            entityId={entityId}
            contact={contact}
            onSuccess={fetchContact}
            iconTrigger={true}
          />
        )
      }
      info={[
        {
          title: "Contact Name",
          value: contactName,
        },
        {
          title: "Contact ID",
          value: contact.id,
        },
        {
          title: "Contact Email",
          value: emailAddresses
            .filter(e => e.enabled)
            .sort((a, b) => (a.is_primary ? -1 : 0))
            .map(e => (e.is_primary ? e.email + "*" : e.email)),
        },
        {
          title: "Alternate Names",
          value: alternateNames.map(an => an.name),
        },
        {
          title: "Contact Department",
          value: departmentName,
        },
        {
          title: "Contact Title",
          value: title,
        },
        {
          title: "Contact Phone Number",
          value: phoneNumbers.map(pn => {
            return (
              <PhoneDetails number={pn.number} type={pn.type} ext={pn.ext} />
            );
          }),
        },
        {
          title: "Email BCC",
          value: CONFIG.CC_EMAIL ? <CopyCCEmail /> : "Not Configured",
        },
        {
          title: "Contact Tags",
          value: contact.tags,
          type: "tags",
          model: contact,
          modelType: "Contact",
          tags: contactTags,
        },
        {
          title: "Outreach Status",
          value: getOutreachStatus(),
        },
      ]}
      extraInfo={[
        {
          title: entityName,
          href: `/entities/${entityId}`,
          fields: [
            {
              title: "Entity Tags",
              value: entity.tags,
              type: "tags",
              model: entity,
              modelType: "Entity",
              tags: entityTags,
            },
            {
              title: "Website",
              value: url,
            },
            {
              title: "Account Manager",
              value: accountManagers.map(ams => ams.user_full_name),
            },
          ],
        },
      ]}
      custom={customFields}
      entityCustom={entityCustomFields}
      handleTabChange={handleTabChange}
      entity_id={entityId}
      contact_id={contact.id}
      contact_phone_numbers={contact.phone_numbers}
      composeEmailModal={({ trigger }) => (
        <SendEmailModal
          trigger={trigger}
          contactId={contact.id || 0}
          contactEmail={contact.email || ""}
        />
      )}
      disableEmail={!isContactEmailable(contact)}
      disableCall={!isContactCallable(contact)}
    />
  );

  const renderTabMenu = () => (
    <Menu className="navbar icon-only" pointing secondary>
      {tabs.map(({ name, icon }) => {
        const isActive = activeTab === name;
        return (
          <Menu.Item
            key={name}
            name={name}
            active={isActive}
            onClick={handleTabChange}
          >
            <Icon name={icon} size="large" /> {isActive && startCase(name)}
            {name === "notes" && hasNotes && !isActive && (
              <div className="new-notif-indicator-contact" />
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Container fluid className="route divided">
      {renderSidebar()}
      <div className="main">
        {renderTabMenu()}
        {renderTabView}
        <Divider hidden />
      </div>
    </Container>
  );
};

export default Contact;

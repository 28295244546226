import { doRequest } from "./helpers";

export default class TwilioService {
  static async generateToken() {
    return doRequest("GET", "/twilio/token");
  }

  static async addToCall(callSid, data) {
    return doRequest("POST", `/twilio/add_to_call/${callSid}`, {
      data,
    });
  }

  static async removeFromCall(callSid, data) {
    return doRequest("POST", `/twilio/remove_from_call/${callSid}`, {
      data,
    });
  }

  static async updateParticipant(callSid, participantCallSid, updateSettings) {
    return doRequest("POST", `/twilio/update_participant/${callSid}`, {
      data: {
        participantCallSid,
        updateSettings,
      },
    });
  }

  static async addCallDisposition(
    call_sid,
    dispositionId,
    otherComment,
    callbackDate,
    redial,
    number,
    grantedConsent,
    assignToSelf
  ) {
    let payload = {
      data: {
        call_sid,
        disposition_id: dispositionId,
        redial: !!redial,
        number,
        assign_to_self: assignToSelf,
      },
    };

    if (otherComment) {
      payload.data.other_comment = otherComment;
    }
    if (callbackDate) {
      payload.data.callback_time = callbackDate;
    }
    if (grantedConsent) {
      payload.data.enable_recording = grantedConsent;
    }
    return doRequest("POST", "/twilio/disposition", payload);
  }

  static async leaveVoicemail(callSid, data) {
    return doRequest("POST", `/callback/twilio/leave_voicemail/${callSid}`, {
      data,
    });
  }

  static async setRecordingStatus(callSid, status) {
    return doRequest("POST", `/twilio/recording/${callSid}`, {
      data: {
        status,
      },
    });
  }

  static async getRecordingStatus(callSid) {
    return doRequest("GET", `/twilio/recording/${callSid}`);
  }

  static async validateNumber(numberId) {
    return doRequest("POST", `/twilio/validate/${numberId}`);
  }

  static async checkValidationStatus(numberId) {
    return doRequest("GET", `/twilio/validate/${numberId}`);
  }

  static async getVerifiedNumbers() {
    return doRequest("GET", `/twilio/verified_numbers`);
  }

  static async getCallerIdOptions() {
    return doRequest("GET", `/twilio/caller_id_options`);
  }
}

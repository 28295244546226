import React from "react";
import ConfirmationModal from "./ConfirmationModal";
import CampaignTargetCallService from "../../services/CampaignTargetCall";

const DeleteCampaignTargetCallModal = ({ callId, fetchData }) => {
  const handleDelete = async () => {
    try {
      await CampaignTargetCallService.deleteCall(callId);
    } catch (e) {
      return e;
    }
    fetchData();
  };

  return (
    <ConfirmationModal
      actionDescription="Delete Call"
      buttonColor="grey"
      onConfirm={handleDelete}
      menuTrigger
      icon="trash"
      warning
    >
      <p>Are you sure you want to delete this Call?</p>
    </ConfirmationModal>
  );
};

export default DeleteCampaignTargetCallModal;

import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import ActivityService from "../../../services/Activity";
import PropTypes from "prop-types";

class NewCategoryForm extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: "",
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    const { name } = this.state;
    await ActivityService.createCategory(name);
  };

  render() {
    const { name } = this.state;

    return (
      <div>
        <RuvixxForm
          ready={!!name}
          onSubmit={this.handleSubmit}
          onSuccess={this.props.onSuccess}
          submitButtonText="ADD"
        >
          <Form.Input
            label="Name"
            placeholder="Name"
            name="name"
            value={name}
            onChange={this.handleChange}
          />
        </RuvixxForm>
      </div>
    );
  }
}

export default NewCategoryForm;

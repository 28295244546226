import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Breadcrumb,
  Header,
  Icon,
  Segment,
  Button,
  Tab,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import "./TaskDetails.scoped.scss";
import StepInfoCard from "./components/StepInfoCard";
import TaskModal from "./components/TaskModal";
import TaskService from "../../services/Task";
import taskConstants from "../../constants/Task";
import ActivityTab from "./components/ActivityTab";
import FilesTab from "./components/FilesTab";
import NotesTab from "./components/NotesTab";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import useRouteParam from "../../hooks/params/useRouteParam";
import setPageTitle from "../../helpers/title";

const tabs = ["taskActivity", "notes", "files"];

const TaskDetails = () => {
  const [id] = useRouteParam("id");
  const [activeTab, setActiveTab] = useRouteParam("tab", tabs[0]);
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [markCompleteLoading, setMarkCompleteLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const taskModal = useRef();

  useEffect(() => {
    setActiveIndex(tabs.findIndex(tab => tab === activeTab));
  }, [activeTab]);

  const fetchTask = useCallback(async () => {
    if (!id) {
      return;
    }
    setLoading(true);

    let task = await TaskService.getTask(id);

    setTask(task);
    setPageTitle(task.name);
    setLoading(false);
    refreshActivePane();
  }, [id]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  const refreshActivePane = () => {
    const activePane = activeIndex in panes ? panes[activeIndex].ref : null;
    if (activePane && activePane.current && activePane.current.refresh) {
      activePane.current.refresh();
    }
  };

  const handleEdit = task => {
    taskModal.current.open(task);
  };

  const markAsCompleted = async () => {
    setMarkCompleteLoading(true);
    await TaskService.markAsCompleted(id);
    setMarkCompleteLoading(false);
    fetchTask();
    refreshActivePane();
  };

  const handleEditSuccess = () => {
    fetchTask();
    refreshActivePane();
  };

  const isNotClosed =
    task &&
    taskConstants.statuses.find(({ id }) => id === task.status).key !==
      "closed";

  const panes = [
    { name: "Task Activity", Component: ActivityTab, ref: useRef() },
    { name: "Notes", Component: NotesTab },
    { name: "Files", Component: FilesTab },
  ];

  const MarkAsCompletedButton = withRoleCheck(
    () => (
      <Button
        size="tiny"
        secondary
        onClick={markAsCompleted}
        loading={markCompleteLoading}
        disabled={loading}
        content="Mark As Completed"
      />
    ),
    [ACL_RELATIONSHIPS.task.markAsCompleted]
  );

  return (
    <div className="main route taskDetails">
      <TaskModal ref={taskModal} onSuccess={handleEditSuccess} />
      <Segment>
        <Breadcrumb>
          <Breadcrumb.Section link>
            <Link to="/tasks">All Tasks</Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
        </Breadcrumb>
        <div className="headerContainer">
          <Header size="tiny">
            <Icon name="linkify" className="brand" />
            Task Details
          </Header>
          {isNotClosed && <MarkAsCompletedButton />}
        </div>
        <div className="taskInfoContainer">
          <StepInfoCard step={task} loading={loading} onEdit={handleEdit} />
        </div>
        <Tab
          panes={panes.map(({ name, Component, ref }) => ({
            menuItem: name,
            render: () => <Component id={id} fetchTask={fetchTask} ref={ref} />,
          }))}
          activeIndex={activeIndex}
          onTabChange={(_, { activeIndex }) => setActiveTab(tabs[activeIndex])}
        />
      </Segment>
    </div>
  );
};

export default TaskDetails;

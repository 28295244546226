import React, { useCallback, useEffect, useState } from "react";
import { capitalize, isUndefined } from "lodash";
import Datetime from "react-datetime";
import { Form, FormField, Icon, Input, List, Segment } from "semantic-ui-react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

import RuvixxSelect from "components/RuvixxSelect";
import Dropzone from "react-dropzone";
import AttachmentService from "services/Attachment";
import CallDispositionService from "services/CallDispositions";
import CampaignService from "services/Campaign";
import CampaignTargetCallService from "services/CampaignTargetCall";
import CaseService from "services/Case";
import ContactService from "services/Contact";
import EntityService from "services/Entity";
import RevenueOpportunityService from "services/RevenueOpportunities";
import TextMessageService from "services/TextMessage";
import RuvixxForm from "../RuvixxForm";
import UploadProgressRing from "../UploadProgressRing";

import "./../../styles/campaign.scss";
import "./../../styles/table.scss";
import "./ManualActivityForm.scss";
import FileInput from "components/FileInput";
import callConstants from "constants/Call";
import { storeError } from "services/helpers";
const callDirectionOptions = callConstants.callDirections.map(direction => ({
  key: direction,
  value: direction,
  text: capitalize(direction),
}));

const ManualActivityForm = ({ modelId, modelType, onCancel, onSuccess }) => {
  const [formData, setFormData] = useState({ attachments: [], eml_file: [] });
  const [callDispositions, setCallDispositions] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [entityId, setEntityId] = useState(null);
  const [caseId, setCaseId] = useState(null);
  const [revenueOpportunityId, setRevenueOpportunityId] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneNumberOptions, setPhoneNumberOptions] = useState([]);
  const [recipientOptions, setRecipientOptions] = useState([]);

  const [attFiles, setAttFiles] = useState([]);
  const [emlFile, setEmlFile] = useState([]);
  const [recording, setRecording] = useState(null);

  useEffect(() => {
    getModel();
    setIsSubmitting(false);
    fetchCallDispositions();
  }, []);

  const getModel = () => {
    switch (modelType) {
      case "Campaign":
        setCampaignId(modelId);
        break;
      case "Entity":
        setEntityId(modelId);
        break;
      case "Contact":
        setContactId(modelId);
        break;
      case "Case":
        setCaseId(modelId);
        break;
      case "revenue_opportunity":
        setRevenueOpportunityId(modelId);
        break;
    }
  };

  useEffect(() => {
    fetchContact(contactId);
  }, [contactId]);

  useEffect(() => {
    fetchRevenueOpportunity(revenueOpportunityId);
  }, [revenueOpportunityId]);

  const fetchRevenueOpportunity = async revenueOpportunityId => {
    if (!revenueOpportunityId) {
      return;
    }
    if (modelType === "revenue_opportunity") {
      const revenueOpportunity =
        await RevenueOpportunityService.getRevenueOpportunityById(
          revenueOpportunityId
        );
      setEntityId(revenueOpportunity.entity_id);
    }
  };

  const fetchContact = async contactId => {
    if (!contactId) {
      return;
    }
    const contact = await ContactService.getContact(contactId);
    if (modelType === "Contact") {
      setEntityId(contact.entity_id);
    }
    const phoneNumbers = contact.phone_numbers;
    const primaryPhoneNumber = phoneNumbers.filter(
      phoneNumber => phoneNumber.is_primary
    );
    if (primaryPhoneNumber.length) {
      setFormData({ ...formData, phoneNumber: primaryPhoneNumber.number });
    }
    setPhoneNumberOptions(
      phoneNumbers.map(phoneNumber => {
        const number = phoneNumber.ext
          ? `${phoneNumber.number}E${phoneNumber.ext}`
          : phoneNumber.number;
        return {
          id: phoneNumber.id,
          value: phoneNumber.id,
          text: number,
        };
      })
    );
    const emailAddresses = contact.email_addresses;
    const primaryEmailAddress = emailAddresses.filter(emailAddress => {
      return emailAddress.is_primary;
    });
    if (primaryEmailAddress.length) {
      setFormData({ ...formData, recipient: primaryEmailAddress[0].email });
    }
    setRecipientOptions(
      emailAddresses.map(emailAddress => {
        return {
          id: emailAddress.id,
          value: emailAddress.id,
          text: emailAddress.email,
        };
      })
    );
  };

  const postCall = async () => {
    const attachments = await Promise.all(
      attFiles.map(f => AttachmentService.uploadFile(f))
    );

    let attachment = null;
    if (recording) {
      attachment = await AttachmentService.uploadFile(recording);
    }
    const callStatus = callDispositions.find(
      disposition => disposition.value === formData.disposition
    ).text;
    const phoneNumber = phoneNumberOptions.find(
      phoneNumber => phoneNumber.value === formData.phoneNumber
    ).text;
    const data = {
      attachments,
      campaignId,
      contactId,
      entityId,
      caseId,
      callDirection: formData.callDirection,
      callStatus,
      dispositionId: formData.disposition,
      phoneNumber,
      recording: attachment,
      revenue_opportunity_id: revenueOpportunityId,
      transcript: formData.transcript,
      comments: formData.comment,
      date: formData.date,
    };
    await CampaignTargetCallService.postCall(data);
  };

  const postTextMessage = async () => {
    const attachments = await Promise.all(
      attFiles.map(f => AttachmentService.uploadFile(f))
    );
    const textMessageData = {
      attachments: attachments,
      datetime_sent: formData.date,
      transcript: formData.transcript || null,
      message_type: formData.model,
      phone_number_id: formData.phoneNumber,
      url: formData.url,
      user_id: null,
      campaign_id: campaignId,
      entity_id: entityId,
      contact_id: contactId,
      case_id: caseId,
      revenue_opportunity_id: revenueOpportunityId,
      comment: formData.comment,
    };
    await TextMessageService.postTextMessage(textMessageData);
  };

  const postEmail = async () => {
    const attachments = await Promise.all(
      attFiles.map(f => AttachmentService.uploadFile(f))
    );
    const eml_file = await Promise.all(
      emlFile.map(f => AttachmentService.uploadFile(f))
    );
    const emailData = {
      campaignId,
      template: -1,
      targetId: entityId,
      revenueOpportunityId,
      caseId: caseId,
      contacts: [contactId],
      attachments: attachments,
      eml_file: eml_file,
      fromUser: formData.fromUser,
      is_manual: true,
      subject: formData.subject,
      emailAddressIds: [formData.recipient],
      responses: [],
      date: formData.date,
      comments: formData.comment,
    };
    await CampaignService.sendEmail(emailData);
  };

  const handleFileDrop = async newFiles => {
    setAttFiles(prevFiles => [...prevFiles, ...newFiles]);
  };
  const handleEMLFileDrop = async newFiles => {
    setEmlFile(prevFiles => [...prevFiles, ...newFiles]);
  };

  const deleteAttFile = async file => {
    let copy = [...attFiles];
    copy.splice(copy.indexOf(file), 1);
    setAttFiles(copy);
  };

  const deleteEMLFile = async file => {
    let copy = [...emlFile];
    copy.splice(copy.indexOf(file), 1);
    setEmlFile(copy);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (formData.model === "call") {
        await postCall();
      } else if (
        formData.model === "sms" ||
        formData.model === "whatsapp" ||
        formData.model === "linkedin"
      ) {
        await postTextMessage();
      } else if (formData.model === "email") {
        await postEmail();
      }
    } catch (error) {
      storeError(error.message);
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchCampaignOptions = useCallback(
    async filters => {
      if (entityId) {
        filters["entity_id"] = entityId;
      }
      if (contactId) {
        filters["contact_id"] = contactId;
      }
      if (caseId) {
        filters["case_id"] = caseId;
      }
      if (revenueOpportunityId) {
        filters["revenue_opportunity_id"] = revenueOpportunityId;
      }
      return await CampaignService.getCampaignsForFilters(filters);
    },
    [entityId, contactId, caseId, revenueOpportunityId]
  );

  const fetchEntityOptions = useCallback(
    async filters => {
      if (campaignId) {
        filters["campaign_id"] = campaignId;
      }
      if (contactId) {
        filters["contact_id"] = contactId;
      }
      if (caseId) {
        filters["case_id"] = caseId;
      }
      if (revenueOpportunityId) {
        filters["revenue_opportunity_id"] = revenueOpportunityId;
      }
      if (modelType === "Entity") {
        filters["entity_id"] = modelId;
      }
      const results = await EntityService.getEntitiesForFilters(filters);
      return results;
    },
    [campaignId, contactId, caseId, revenueOpportunityId]
  );

  const fetchContactOptions = useCallback(
    async filters => {
      if (campaignId) {
        filters["campaign_id"] = campaignId;
      }
      if (entityId) {
        filters["entity_id"] = entityId;
      }
      if (caseId) {
        filters["case_id"] = caseId;
      }
      if (modelType === "Contact") {
        filters["contact_id"] = modelId;
      }
      return await ContactService.getContactsForFilters(filters);
    },
    [campaignId, entityId, caseId]
  );

  const fetchCaseOptions = useCallback(
    async filters => {
      if (entityId) {
        filters["entity_id"] = entityId;
      }
      if (campaignId) {
        filters["campaign_id"] = campaignId;
      }
      if (contactId) {
        filters["contact_id"] = contactId;
      }
      if (revenueOpportunityId) {
        filters["revenue_opportunity_id"] = revenueOpportunityId;
      }
      if (modelType === "Case") {
        filters["case_id"] = modelId;
      }
      const results = await CaseService.getForFilters(filters);
      return results;
    },
    [entityId, campaignId, contactId, revenueOpportunityId]
  );

  const fetchRevenueOpportunityOptions = useCallback(
    async filters => {
      if (modelType === "revenue_opportunity") {
        filters["revenue_opportunity_id"] = modelId;
      } else {
        if (campaignId) {
          filters["campaign_id"] = campaignId;
        }
        if (entityId) {
          filters["entity_id"] = entityId;
        }
        if (caseId) {
          filters["case_id"] = caseId;
        }
      }

      const results = await RevenueOpportunityService.getForFilters(filters);
      return results;
    },
    [campaignId, entityId, caseId]
  );

  const fetchCallDispositions = async () => {
    const fetchedCallDispositions =
      await CallDispositionService.getDispositionsForFilters();
    const callDispositionOptions = fetchedCallDispositions.map(disposition => {
      return {
        key: disposition.id,
        text: disposition.name,
        value: disposition.id,
      };
    });
    setCallDispositions(callDispositionOptions);
  };

  const handleChange = (_, data) => {
    const { name, value } = data;
    let prevFormData = { ...formData };
    if (name) {
      prevFormData[name] = value;
      setFormData(prevFormData);
    }
  };

  useEffect(() => {
    clearFormData();
  }, [formData.model]);

  const clearFormData = () => {
    const prevFormData = { ...formData };
    if (formData.model !== "email") {
      prevFormData.recipient = null;
      prevFormData.fromUser = "";
      prevFormData.subject = "";
      prevFormData.attachments = [];
      setEmlFile([]);
    } else {
      prevFormData.phoneNumber = null;
      prevFormData.transcript = "";
      prevFormData.url = "";
      setAttFiles([]);
    }

    if (formData.model !== "call") {
      prevFormData.callDirection = "";
      prevFormData.disposition = null;
      setRecording(null);
    }
    setFormData(prevFormData);
  };

  const activityModels = [
    {
      key: "whatsapp",
      text: "WhatsApp",
      value: "whatsapp",
    },
    {
      key: "sms",
      text: "Text",
      value: "sms",
    },
    {
      key: "linkedin",
      text: "LinkedIn",
      value: "linkedin",
    },
    {
      key: "email",
      text: "Email",
      value: "email",
    },
    {
      key: "call",
      text: "Call",
      value: "call",
    },
  ];

  const deleteAttachment = async id => {
    await AttachmentService.delete(id);
    formData["attachments"] = formData["attachments"].filter(
      attachment => attachment.id !== id
    );
    setFormData({ ...formData });
  };

  const isReady = () => {
    if (isSubmitting) {
      return false;
    }

    if (
      !campaignId ||
      !entityId ||
      !contactId ||
      !formData.model ||
      !formData.date
    ) {
      return false;
    }
    if (formData.model === "whatsapp" || formData.model === "sms") {
      if (!formData.transcript) return false;
      if (!formData.phoneNumber) return false;
    } else if (formData.model === "linkedin") {
      if (!formData.transcript) return false;
      if (!formData.url) return false;
    } else if (formData.model === "call") {
      if (contactId <= 0) return false;
      if (!formData.callDirection) return false;
      if (!formData.disposition) return false;
      if (!formData.phoneNumber) return false;
    } else if (formData.model === "email") {
      if (emlFile.length !== 1) return false;
      if (!formData.fromUser) return false;
      if (!formData.subject) return false;
      if (!formData.recipient) return false;
    } else {
      return true;
    }
  };

  const isDropzoneEnabled = emlFile.length === 0;

  const renderEMLuploader = () => {
    const { eml_file } = formData;
    return (
      <>
        {eml_file.length > 0 ? <Form.Field label="Previous EML File" /> : null}
        <List relaxed>
          {eml_file.length > 0
            ? eml_file.map((file, i) => {
                return (
                  <List.Item key={i}>
                    <List.Content floated="right">
                      <Icon
                        name="delete"
                        onClick={() => deleteAttachment(file.id)}
                      />
                    </List.Content>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.file_name}
                    </a>
                  </List.Item>
                );
              })
            : null}
        </List>
        {eml_file.length > 0 ? (
          <Form.Field label="Upload EML File" required />
        ) : (
          <Form.Field label="EML File" required />
        )}
        {isSubmitting && emlFile && emlFile.every(f => f.file) ? (
          <UploadProgressRing uploads={emlFile} />
        ) : (
          <List relaxed>
            {!!emlFile
              ? emlFile.map((file, i) =>
                  file !== undefined ? (
                    <List.Item key={i}>
                      <List.Content floated="right">
                        <Icon name="delete" onClick={() => deleteEMLFile(i)} />
                      </List.Content>
                      {file.name}
                    </List.Item>
                  ) : null
                )
              : null}
          </List>
        )}
        <Dropzone
          required
          onDrop={emlFile => handleEMLFileDrop(emlFile)}
          multiple={false}
          accept="message/rfc822"
          disabled={emlFile.length > 0}
        >
          {({ getRootProps, getInputProps }) => (
            <Segment
              className={`centered ${
                isDropzoneEnabled ? "dropzone-enabled" : ""
              }`}
            >
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDropzoneEnabled ? (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                ) : (
                  <p>Only one EML file is allowed.</p>
                )}
              </div>
            </Segment>
          )}
        </Dropzone>
      </>
    );
  };

  const renderAttachments = () => {
    const { attachments } = formData;
    return (
      <>
        {attachments.length > 0 ? (
          <Form.Field label="Previous Attachments" />
        ) : null}
        <List relaxed>
          {attachments.length > 0
            ? attachments.map((file, i) => {
                return (
                  <List.Item key={i}>
                    <List.Content floated="right">
                      <Icon
                        name="delete"
                        onClick={() => deleteAttachment(file.id)}
                      />
                    </List.Content>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.file_name}
                    </a>
                  </List.Item>
                );
              })
            : null}
        </List>
        {attachments.length > 0 ? (
          <Form.Field label="New Attachments" />
        ) : (
          <Form.Field label="Attachments" />
        )}
        {isSubmitting && attFiles && attFiles.every(f => f.file) ? (
          <UploadProgressRing uploads={attFiles} />
        ) : (
          <List relaxed>
            {!!attFiles
              ? attFiles.map((file, i) =>
                  file !== undefined ? (
                    <List.Item key={i}>
                      <List.Content floated="right">
                        <Icon name="delete" onClick={() => deleteAttFile(i)} />
                      </List.Content>
                      {file.name}
                    </List.Item>
                  ) : null
                )
              : null}
          </List>
        )}
        <Dropzone onDrop={attFiles => handleFileDrop(attFiles)}>
          {({ getRootProps, getInputProps }) => (
            <Segment style={{ border: "dashed 2px grey", textAlign: "center" }}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </Segment>
          )}
        </Dropzone>
      </>
    );
  };

  return (
    <>
      <RuvixxForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        ready={isReady()}
        onSuccess={onSuccess}
        buttonsLeft={
          <small>
            The information entered in this field will be permanently saved and
            cannot be modified.
          </small>
        }
      >
        <RuvixxSelect
          search
          inline
          required
          clearable={modelType !== "Campaign"}
          label="Campaign"
          name="campaignId"
          value={campaignId}
          onChange={(_, { value }) => {
            setCampaignId(value);
          }}
          queryFn={fetchCampaignOptions}
          placeholder="Type to search..."
        />
        <RuvixxSelect
          readOnly={
            modelType === "Entity" ||
            modelType === "Contact" ||
            modelType === "revenue_opportunity"
          }
          search
          inline
          required
          clearable={modelType !== "Entity"}
          label="Entity"
          name="entityId"
          value={entityId}
          onChange={(_, { value }) => {
            if (modelType !== "revenue_opportunity") {
              setEntityId(value);
            }
          }}
          queryFn={fetchEntityOptions}
          campaignIds={campaignId ? [campaignId] : null}
          placeholder="Type to search..."
        />
        <RuvixxSelect
          readOnly={modelType === "Contact"}
          search
          inline
          required
          clearable={modelType !== "Contact"}
          label="Contact"
          name="contactId"
          value={contactId}
          onChange={(_, { value }) => {
            setContactId(value);
          }}
          queryFn={fetchContactOptions}
          campaignIds={campaignId ? [campaignId] : null}
          entityIds={entityId ? [entityId] : null}
          placeholder="Type to search..."
        />
        <RuvixxSelect
          readOnly={modelType === "Case"}
          search
          inline
          clearable={modelType !== "Case"}
          label="Case"
          name="caseId"
          value={caseId}
          onChange={(_, { value }) => {
            setCaseId(value);
          }}
          queryFn={fetchCaseOptions}
          campaignIds={campaignId ? [campaignId] : null}
          entityIds={entityId ? [entityId] : null}
          placeholder="Type to search..."
        />
        <RuvixxSelect
          readOnly={modelType === "revenue_opportunity"}
          search
          inline
          clearable={modelType !== "revenue_opportunity"}
          label="Revenue Opportunity"
          name="revenueOpportunityId"
          value={revenueOpportunityId}
          onChange={(_, { value }) => {
            setRevenueOpportunityId(value);
          }}
          queryFn={fetchRevenueOpportunityOptions}
          campaignIds={campaignId ? [campaignId] : null}
          entityIds={entityId ? [entityId] : null}
          caseIds={caseId ? [caseId] : null}
          placeholder="Type to search..."
        />
        <Form.Dropdown
          selection
          required
          label="Model"
          name="model"
          options={activityModels}
          value={formData.model}
          onChange={(_, { value }) => {
            handleChange(null, { name: "model", value });
          }}
        />
        <FormField inline required>
          <label>Date</label>
          <Datetime
            closeOnSelect={true}
            timeFormat={false}
            label="Date"
            name="date"
            value={formData.effective_date}
            onChange={value => {
              handleChange(null, { name: "date", value });
            }}
            renderInput={props => <Input icon="calendar outline" {...props} />}
            dateFormat={"YYYY-MM-DD"}
          />
        </FormField>
        <Form.TextArea
          inline
          name="comment"
          label="Comments"
          onChange={(e, { value }) => {
            handleChange(null, { name: "comment", value });
          }}
        />

        {formData.model === "email" && (
          <>
            <Form.Dropdown
              selection
              required
              label="Recipient"
              options={recipientOptions}
              value={formData.recipient}
              onChange={(e, { value }) => {
                handleChange(null, { name: "recipient", value });
              }}
            />
            <Form.Input
              required
              inline
              name="fromUser"
              label="From Email Address"
              onChange={(e, { value }) => {
                handleChange(null, { name: "fromUser", value });
              }}
            />
            <Form.Input
              required
              inline
              name="subject"
              label="Subject Line"
              onChange={(e, { value }) => {
                handleChange(null, { name: "subject", value });
              }}
            />
            {renderEMLuploader()}
            {renderAttachments()}
          </>
        )}
        {(formData.model === "sms" ||
          formData.model === "whatsapp" ||
          formData.model === "call") && (
          <>
            <h3>Phone Number</h3>
            <Form.Dropdown
              selection
              required
              label="Phone Number"
              options={phoneNumberOptions}
              value={formData.phoneNumber}
              onChange={(e, { value }) => {
                handleChange(null, { name: "phoneNumber", value });
              }}
            />
          </>
        )}

        {(formData.model === "sms" ||
          formData.model === "whatsapp" ||
          formData.model === "linkedin" ||
          formData.model === "call") && (
          <>
            <h3>Conversation</h3>
            <Form.TextArea
              required={formData.model !== "call"}
              inline
              name="transcript"
              label="Transcript"
              onChange={(e, { value }) => {
                handleChange(null, { name: "transcript", value });
              }}
            />
          </>
        )}
        {formData.model === "linkedin" && (
          <Form.Input
            required
            inline
            name="url"
            label="URL"
            onChange={handleChange}
          />
        )}
        {(formData.model === "sms" || formData.model === "whatsapp") && (
          <>{renderAttachments()}</>
        )}

        {formData.model == "call" && (
          <>
            <h3>Call Direction</h3>
            <Form.Select
              required
              inline
              label="Call Direction"
              name="callDirection"
              value={formData.callDirection}
              onChange={handleChange}
              options={callDirectionOptions}
            />
            <h3>Disposition</h3>
            <Form.Select
              required
              search
              inline
              label="Call Disposition"
              name="disposition"
              value={formData.disposition}
              onChange={handleChange}
              options={callDispositions}
              placeholder="Type to search..."
            />
            <h3>Recording</h3>
            <Form.Field inline>
              <label>Recording File</label>
              <FileInput
                accept=".mp3, .wav"
                clearable
                onChange={setRecording}
              />
            </Form.Field>
            {renderAttachments()}
          </>
        )}
      </RuvixxForm>
    </>
  );
};

export default ManualActivityForm;

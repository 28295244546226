import React, { useState, useEffect } from "react";
import { Grid, Tab, Container, Menu } from "semantic-ui-react";
import useRouteParam from "../../hooks/params/useRouteParam";
import { checkIsAuthorized } from "../../components/helpers";
import _Filters from "./Filters";
import NotificationsTable from "./NotificationsTable";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import store from "store";
import useInterval from "../../hooks/useInterval";
import { USER_ALERTS_FETCH_INTERVAL } from "../../constants/Constants";
import setPageTitle from "../../helpers/title";

import "./Inbox.scoped.scss";

const tabs = ["tasks", "notifications", "archive"];

const Filters = withRoleCheck(_Filters, [
  ACL_RELATIONSHIPS.campaignForFilters.read,
  ACL_RELATIONSHIPS.entityForFilters.read,
]);

const Inbox = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [hasNewNotifs, setHasNewNotifs] = useState([false, false]);
  const [hasTaskNotifs, setHasTaskNotifs] = useState(false);
  const [hasOtherNotifs, setHasOtherNotifs] = useState(false);
  const [activeTab, setActiveTab] = useRouteParam("tab", tabs[0]);
  const [page, setPage] = useState(1);

  const handleTabChange = activeIndex => {
    setPage(1);
    setActiveTab(tabs[activeIndex]);
  };

  useEffect(() => {
    setPageTitle("Inbox");
    setActiveIndex(tabs.findIndex(tab => tab === activeTab));
  }, [activeTab]);

  useInterval(
    () => {
      const alerts = store.get("userAlerts") || {};
      const taskNotifs = alerts.has_task_notifications || false;
      const otherNotifs = alerts.has_other_notifications || false;
      setHasNewNotifs([taskNotifs, otherNotifs]);
      setHasTaskNotifs(taskNotifs);
      setHasOtherNotifs(otherNotifs);
    },
    USER_ALERTS_FETCH_INTERVAL,
    true
  );

  const MenuItem = ({ name, index }) => (
    <Menu.Item
      active={activeIndex === index}
      onClick={() => handleTabChange(index)}
    >
      {name}
    </Menu.Item>
  );

  const panes = [
    {
      menuItem: () => (
        <>
          <MenuItem name="Task" index={0} />
          <div className={hasTaskNotifs ? "new-notif-indicator" : ""} />
        </>
      ),
      authorized: checkIsAuthorized([
        ACL_RELATIONSHIPS.notifications.read,
        ACL_RELATIONSHIPS.notifications.markAsRead,
      ]),
      render: () => (
        <Tab.Pane>
          <NotificationsTable
            paneName="Task"
            modelType="Task"
            isArchived={false}
            typeOptions={["completed", "near_due", "past_due"]}
            filters={filters}
            setFilters={setFilters}
            page={page}
            setPage={setPage}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <>
          <MenuItem name="Notifications" index={1} />
          <div className={hasOtherNotifs ? "new-notif-indicator" : ""} />
        </>
      ),
      authorized: checkIsAuthorized([
        ACL_RELATIONSHIPS.notifications.read,
        ACL_RELATIONSHIPS.notifications.markAsRead,
      ]),
      render: () => (
        <Tab.Pane>
          <NotificationsTable
            paneName="Notifications"
            modelType="-Task"
            isArchived={false}
            typeOptions={["delivered", "response", "mention"]}
            filters={filters}
            setFilters={setFilters}
            page={page}
            setPage={setPage}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="archive" className="right-aligned">
          <span>Archive</span>
        </Menu.Item>
      ),
      authorized: checkIsAuthorized([ACL_RELATIONSHIPS.notifications.read]),
      render: () => (
        <Tab.Pane>
          <NotificationsTable
            paneName="Archive"
            isArchived
            typeOptions={[
              "completed",
              "near_due",
              "past_due",
              "delivered",
              "response",
              "mention",
            ]}
            filters={filters}
            setFilters={setFilters}
            page={page}
            setPage={setPage}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Container fluid className="inbox route">
      <Grid className="bg" divided style={{ height: "100%" }}>
        <Grid.Row columns="1">
          <Grid.Column width={16}>
            <div className="campaign-segment">
              <Filters filters={filters} setFilters={setFilters} />
              <Tab
                panes={panes.filter(pane => pane.authorized)}
                activeIndex={activeIndex}
                onTabChange={(_, { activeIndex }) =>
                  handleTabChange(activeIndex)
                }
                menuPosition="right"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Inbox;

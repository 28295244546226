import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal, Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import CustomFieldService from "../../../services/CustomField";
import CustomFieldConfigForm from "../forms/CustomFieldConfig";

const UpdateFieldModal = forwardRef(({ refreshTable }, ref) => {
  const [open, setOpen] = useState(false);
  const [customFieldConfig, setCustomFieldConfig] = useState({});
  const [confirmMode, setConfirmMode] = useState(false);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = custom_field_config => {
    setCustomFieldConfig(custom_field_config);
    setConfirmMode(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    handleClose();
    refreshTable();
  };

  const handleCancel = () => {
    setConfirmMode(false);
  };

  return (
    <>
      <Modal
        size="small"
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        closeIcon
      >
        <Modal.Header content="Edit Custom Field Config" />
        <Modal.Content>
          <CustomFieldConfigForm
            customFieldConfig={customFieldConfig}
            onSuccess={handleSuccess}
          />
        </Modal.Content>
      </Modal>
    </>
  );
});

export default UpdateFieldModal;

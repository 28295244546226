import React, { useState } from "react";
import TransferCallForm from "./TransferCallForm";
import { Modal, Button } from "semantic-ui-react";

import "./TransferCallModal.scoped.scss";

const TransferCallModal = ({
  onDirectTransfer,
  startWarmTransfer,
  cancelWarmTransfer,
  completeWarmTransfer,
  transferCallData,
  disabled,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSuccess = async () => {
    handleClose();
  };

  return (
    <Modal
      trigger={
        <Button
          className="lightblue"
          size="tiny"
          content={
            !!transferCallData.callSid
              ? "Continue Warm Transfer"
              : "Transfer Call"
          }
          disabled={disabled}
        />
      }
      open={modalOpen && !disabled}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
      size="tiny"
      className="transferCallModal"
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <Modal.Header>Transfer Call</Modal.Header>
      <Modal.Content>
        <TransferCallForm
          onSuccess={handleSuccess}
          onDirectTransfer={onDirectTransfer}
          startWarmTransfer={startWarmTransfer}
          cancelWarmTransfer={cancelWarmTransfer}
          completeWarmTransfer={completeWarmTransfer}
          transferCallData={transferCallData}
        />
      </Modal.Content>
    </Modal>
  );
};

export default TransferCallModal;

import { applyFilters, doRequest } from "./helpers";

export default class CallDispositionService {
  static async getDispositions(filters) {
    let path = `/disposition/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getDispositionsForFilters(filters = null) {
    let path = "/disposition/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getDisposition(id) {
    let path = `/disposition/${id}`;
    return doRequest("GET", path);
  }

  static async getDispositionWithDialSession(id) {
    let path = `/disposition/${id}/dial_sessions`;
    return doRequest("GET", path);
  }

  static async createDisposition(data) {
    return doRequest("POST", "/disposition/", { data });
  }

  static editDisposition(id, data) {
    return doRequest("PUT", `/disposition/${id}`, { data });
  }

  static deleteDisposition(id) {
    return doRequest("DELETE", `/disposition/${id}`);
  }
}

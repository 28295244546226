import React from "react";
import { Navigate } from "react-router-dom";
import useAuthenticated from "../../hooks/useAuthenticated";

const RequireAuth = ({ children }) => {
  const isAuthenticated = useAuthenticated();
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default RequireAuth;

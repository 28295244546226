const upperFirst = string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

const titleCase = s =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase()); // First char after each -/_

const startCase = (phrase, separator = " ") => {
  if (!phrase.split) return phrase;
  let result = [];
  phrase.split(separator).forEach(w => {
    result.push(upperFirst(w));
  });
  return result.join(" ");
};

const cleanString = string => {
  if (string === undefined || string === null) {
    return string;
  }
  return string.toLowerCase().replace(/\s+/, " ").trim();
};

const trimText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substr(0, maxLength) + "...";
  }
};

export { upperFirst, startCase, titleCase, cleanString, trimText };

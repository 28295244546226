import React, { useEffect, useState } from "react";
import { Form, Dropdown, Input, Segment, Button } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";
import EmailTemplateService from "../../../services/EmailTemplate";

export function WarningEmailTemplateForm({ changeTab }) {
  return (
    <Segment style={{ textAlign: "center" }}>
      <h3>Warning</h3>
      <p>
        Any changes made in the HTML tab will be undone by changes in the
        Graphical tab
      </p>
      <p>Are you sure you want to continue?</p>
      <Form>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form.Button
            className="warning confirm"
            type="button"
            onClick={() => {
              changeTab("graphical");
            }}
          >
            Yes
          </Form.Button>
          <Form.Button
            type="warning cancel"
            type="button"
            onClick={() => {
              changeTab("HTML");
            }}
          >
            No
          </Form.Button>
        </div>
      </Form>
    </Segment>
  );
}

export function NoTemplateWarningForm({ changeTab }) {
  return (
    <Segment style={{ textAlign: "center" }}>
      <h3>Warning</h3>
      <p>Please fill out the general tab first</p>
      <Form>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form.Button
            type="button"
            onClick={() => {
              changeTab("general");
            }}
          >
            Ok
          </Form.Button>
        </div>
      </Form>
    </Segment>
  );
}

export function NoHTMLWarningForm({ changeTab }) {
  return (
    <Segment style={{ textAlign: "center" }}>
      <h3>Warning</h3>
      <p>Please save a HTML template first</p>
      <Form>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form.Button
            type="button"
            onClick={() => {
              changeTab("graphical");
            }}
          >
            Ok
          </Form.Button>
        </div>
      </Form>
    </Segment>
  );
}

import React from "react";
import { Header } from "semantic-ui-react";
import taskConstants from "../../../constants/Task";
import sequenceConstants from "../../../constants/Sequence";
import StepInfoCard from "../../tasks/components/StepInfoCard";
import { startCase } from "../../../helpers/string";

import "./StepInfo.scoped.scss";

const StepInfo = ({
  step,
  stepIndex,
  users,
  auditLogEvents,
  onEdit,
  onDelete,
  canEdit,
}) => {
  if (!step) {
    return;
  }

  const getAssigneeName = assignee => {
    const user = users.find(({ id }) => id === assignee);
    return user ? user.full_name : undefined;
  };

  const getDefaultTaskName = (taskType, assignee) => {
    const assigneeName = getAssigneeName(assignee);
    const taskTypeName = taskConstants.types.find(
      ({ id }) => id === taskType
    ).name;
    return `${taskTypeName} with ${assigneeName}`;
  };

  const getDefaultAutomationName = automationAction => {
    return `Perform Automation: ${automationAction.name}`;
  };

  const getDueDate = ({ value, unit }) => {
    if (+value === 1) {
      unit = unit.replace(/s$/, "");
    }
    return `${value} ${unit} after task creation`;
  };

  const handleEdit = () => {
    onEdit(stepIndex);
  };

  const handleDelete = () => {
    onDelete(stepIndex);
  };

  const {
    trigger,
    task_name,
    task_type,
    assignee,
    priority,
    description,
    due_date,
    action_type,
    automation_action,
    automation_name,
    automation_description,
  } = step;

  const automationAction = sequenceConstants.automationActions.find(
    ({ id }) => id === automation_action
  );

  const getStepInfo = () => {
    if (action_type === sequenceConstants.actionTypes.task) {
      return {
        name: task_name || getDefaultTaskName(task_type, assignee),
        type: task_type,
        assigned_users: [
          {
            id: assignee,
            full_name: getAssigneeName(assignee),
          },
        ],
        due_date: getDueDate(due_date),
        priority,
        description,
      };
    }
    if (action_type === sequenceConstants.actionTypes.automation) {
      return {
        name: automation_name || getDefaultAutomationName(automationAction),
        description: automation_description,
        type: 1,
      };
    }
  };

  const triggerEvent = startCase(
    auditLogEvents.find(({ value }) => value === trigger.event).text,
    "_"
  );

  const actionDescription =
    action_type === sequenceConstants.actionTypes.task
      ? "Create Task"
      : automationAction.name;

  return (
    <div className="stepInfo">
      <Header className="stepName">Step {stepIndex + 1}</Header>
      <Header className="trigger">
        When: {triggerEvent} - {actionDescription}:
      </Header>
      <StepInfoCard
        step={getStepInfo()}
        onEdit={handleEdit}
        onDelete={handleDelete}
        canEdit={canEdit}
      />
    </div>
  );
};

export default StepInfo;

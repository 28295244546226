import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import "./../styles/table.scss";
import { NewCategoryModal } from "../routes/campaign/components/Modals";
import EditCategoryModal from "./modals/EditCategoryModal";
import DeleteCategoryModal from "./modals/DeleteCategoryModal";
import ActivityService from "../services/Activity";

class CategoryRow extends Component {
  handleDelete = async () => {
    const {
      category: { value: id },
    } = this.props;
    await ActivityService.deleteCategory(id);
    await this.props.onSuccess();
  };

  render() {
    const { category } = this.props;
    return (
      <Table.Row>
        <Table.Cell>{category.text}</Table.Cell>
        <Table.Cell style={{ textAlign: "right" }}>
          <EditCategoryModal
            category={category}
            onSuccess={this.props.onSuccess}
          />
          <DeleteCategoryModal onConfirmDelete={this.handleDelete} />
        </Table.Cell>
      </Table.Row>
    );
  }
}

class EmptyBody extends Component {
  render() {
    return (
      <Table.Body>
        <Table.Cell colSpan={this.props.colSpan} textAlign={"center"}>
          No {this.props.name}
        </Table.Cell>
      </Table.Body>
    );
  }
}

class CategoriesTable extends Component {
  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchCategories: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      categories: this.props.categories,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.categories &&
      !isEqual(nextProps.categories, prevState.categories)
    ) {
      return {
        categories: nextProps.categories,
      };
    }

    return null;
  }

  render() {
    const { categories } = this.state;
    return (
      <div>
        <NewCategoryModal fetchCategories={this.props.fetchCategories} />
        <Table basic="very">
          {!isEmpty(categories) ? (
            <Table.Body>
              {categories
                .filter(({ hidden }) => !hidden)
                .map(category => (
                  <CategoryRow
                    key={category.value}
                    category={category}
                    onSuccess={this.props.fetchCategories}
                  />
                ))}
            </Table.Body>
          ) : (
            <EmptyBody colSpan={3} name="Categories" />
          )}
        </Table>
      </div>
    );
  }
}

export default CategoriesTable;

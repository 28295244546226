import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Grid,
  Header,
  Input,
  Segment,
  Tab,
} from "semantic-ui-react";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import CampaignService from "../../services/Campaign";
import RuvixxSelect from "../../components/RuvixxSelect";
import DialSessionService from "../../services/DialSessions";
import UserService from "../../services/User";
import CallDispositionService from "../../services/CallDispositions";
import "./../../styles/call_dashboard.scss";
import TotalCallsChart from "./TotalCallsChart";
import CallDashboardSummaryStats from "./CallDashboardSummaryStats";
import CallsBreakDownByDispositionChart from "./CallBreakdownByDisposition";
import CallsBreakDownByDialSessionChart from "./CallsBreakDownByDialSessionChart";
import omitBy from "lodash/omitBy";
import CallAgentMetricsTable from "./CallAgentMetricsTable";
import useQueryParams from "../../hooks/params/useQueryParams";
import setPageTitle from "../../helpers/title";

const WhiteBackgroundWrapper = ({ children }) => {
  return <div className="whiteBackgroundWrapper">{children}</div>;
};

const tabs = [
  "totalCalls",
  "agentMetrics",
  "dispositionBreakdown",
  "dialSessionBreakdown",
];

const dateRangePresets = [
  {
    key: "today",
    text: "Today",
    value: "today",
  },
  {
    key: "yesterday",
    text: "Yesterday",
    value: "yesterday",
  },
  {
    key: "previous7days",
    text: "Previous 7 Days",
    value: "previous7days",
  },
  {
    key: "previous30days",
    text: "Previous 30 Days",
    value: "previous30days",
  },
  {
    key: "currentMonth",
    text: "Current Month",
    value: "currentMonth",
  },
  {
    key: "previousMonth",
    text: "Previous Month",
    value: "previousMonth",
  },
  {
    key: "currentQuarter",
    text: "Current Quarter",
    value: "currentQuarter",
  },
  {
    key: "previousQuarter",
    text: "Last Quarter",
    value: "previousQuarter",
  },
  {
    key: "yearToDate",
    text: "Year To Date",
    value: "yearToDate",
  },
  {
    key: "all",
    text: "All Time",
    value: "all",
  },
];

const callTypeOptions = [
  { key: "connected", value: "connected", text: "Connected" },
  { key: "not_connected", value: "not_connected", text: "Not Connected" },
  { key: "voicemail", value: "voicemail", text: "Voicemail" },
  { key: "callback", value: "callback", text: "Callback" },
];

const callsPerDayOperatorOptions = [
  {
    key: "gt",
    text: "Greater Than",
    value: "gt",
  },
  {
    key: "lt",
    text: "Less Than",
    value: "lt",
  },
];

const durationOperatorOptions = [
  {
    key: "gt",
    text: "Greater Than",
    value: "gt",
  },
  {
    key: "lt",
    text: "Less Than",
    value: "lt",
  },
];

const callDispositionOptions = [
  { key: "contains", value: "contains", text: "Contains" },
  { key: "excludes", value: "excludes", text: "Excludes" },
];

const DATE_FORMAT = "YYYY-MM-DD";
const beginningOfTime = moment("2000-01-01").format(DATE_FORMAT);
const today = moment().format(DATE_FORMAT);
const yesterday = moment().subtract(1, "days").format(DATE_FORMAT);
const _7daysAgo = moment().subtract(7, "days").format(DATE_FORMAT);
const _30daysAgo = moment().subtract(30, "days").format(DATE_FORMAT);
const startOfMonth = moment().startOf("month").format(DATE_FORMAT);
const endOfMonth = moment().endOf("month").format(DATE_FORMAT);
const startOfYear = moment().startOf("year").format(DATE_FORMAT);
const startOfPreviousMonth = moment()
  .subtract(1, "months")
  .startOf("month")
  .format(DATE_FORMAT);
const endOfPreviousMonth = moment()
  .subtract(1, "months")
  .endOf("month")
  .format(DATE_FORMAT);
const startOfQuarter = moment().startOf("quarter").format(DATE_FORMAT);
const endOfQuarter = moment().endOf("quarter").format(DATE_FORMAT);
const startOfPreviousQuarter = moment()
  .subtract(1, "Q")
  .startOf("quarter")
  .format(DATE_FORMAT);
const endOfPreviousQuarter = moment()
  .subtract(1, "Q")
  .endOf("quarter")
  .format(DATE_FORMAT);

const datePresets = {
  yearToDate: {
    start: startOfYear,
    end: today,
  },
  today: {
    start: today,
    end: today,
  },
  yesterday: {
    start: yesterday,
    end: yesterday,
  },
  previous7days: {
    start: _7daysAgo,
    end: today,
  },
  previous30days: {
    start: _30daysAgo,
    end: today,
  },
  previousMonth: {
    start: startOfPreviousMonth,
    end: endOfPreviousMonth,
  },
  currentMonth: {
    start: startOfMonth,
    end: endOfMonth,
  },
  currentQuarter: {
    start: startOfQuarter,
    end: endOfQuarter,
  },
  previousQuarter: {
    start: startOfPreviousQuarter,
    end: endOfPreviousQuarter,
  },
  all: {
    start: beginningOfTime,
    end: today,
  },
};

const CallDashboard = props => {
  // filters
  const [dateRangePreset, setDateRangePreset] = useState("previous7days");
  const [showDispositionDropdown, setShowDispositionDropdown] = useState(false);
  const [startDate, setStartDate] = useState(datePresets.previous7days.start);
  const [endDate, setEndDate] = useState(datePresets.previous7days.end);
  const [campaignIds, setCampaignIds] = useState(null);
  const [dialSessionIds, setDialSessionIds] = useState(null);
  const [dispositionIds, setDispositionIds] = useState(null);
  const [agentIds, setAgentIds] = useState(null);
  const [callType, setCallType] = useState("");
  const [callVerified, setCallVerified] = useState(false);
  const [callDurationOperator, setCallDurationOperator] = useState("");
  const [callDispositionOperator, setCallDispositionOperator] = useState("");
  const [callsPerDayOperator, setCallsPerDayOperator] = useState("");
  const [callDuration, setCallDuration] = useState("");
  const [callsPerDay, setCallsPerDay] = useState("");
  const [queryFilters, setQueryFilters] = useQueryParams({
    tab: tabs[0],
    start_date: startDate,
    end_date: endDate,
  });
  const [activeTab, setActiveTab] = useState(tabs[0]);

  setPageTitle("Call Dashboard");

  // filter handlers
  const handleSelectCampaign = (_, { value }) => {
    setCampaignIds(value || []);
  };
  const handleSelectDialSession = (_, { value }) => {
    setDialSessionIds(value || []);
  };
  const handleSelectDisposition = (_, { value }) => {
    setDispositionIds(value || []);
  };
  const handleSelectAgent = (_, { value }) => {
    setAgentIds(value || []);
  };

  const handleDatePreset = (e, { value }) => {
    setDateRangePreset(value);
    setStartDate(datePresets[value].start);
    setEndDate(datePresets[value].end);
  };

  const applyFilters = () => {
    let filterParams = {
      ...queryFilters,
      campaign_ids: campaignIds,
      dial_session_ids: dialSessionIds,
      disposition_ids: dispositionIds,
      start_date: startDate,
      end_date: endDate,
      agent_ids: agentIds,
      call_type: callType,
      call_duration: callDuration,
      calls_per_day: callsPerDay,
      disposition_operator: callDispositionOperator,
      duration_operator: callDurationOperator,
      calls_per_day_operator: callsPerDayOperator,
    };
    filterParams = omitBy(filterParams, item => item === undefined);
    setQueryFilters(filterParams);
  };

  const handleTabChange = (_, { activeIndex }) => {
    const activeTab = tabs[activeIndex];
    setActiveTab(activeTab);
    setQueryFilters({ ...queryFilters, tab: activeTab });
  };

  const csvStringToArray = str => {
    return str.split(",").map(elem => {
      return elem !== null && !isNaN(+elem) ? +elem : elem;
    });
  };

  useEffect(() => {
    const {
      tab,
      start_date,
      end_date,
      campaign_ids,
      dial_session_ids,
      disposition_ids,
      agent_ids,
      call_type,
      duration_operator,
      calls_per_day_operator,
      calls_per_day,
      call_duration,
      disposition_operator,
    } = queryFilters;
    if (tab) {
      setActiveTab(tab);
    }
    if (start_date) {
      setStartDate(start_date);
    }
    if (end_date) {
      setEndDate(end_date);
    }
    if (campaign_ids) {
      setCampaignIds(csvStringToArray(campaign_ids));
    }
    if (dial_session_ids) {
      setDialSessionIds(csvStringToArray(dial_session_ids));
    }
    if (disposition_ids) {
      setShowDispositionDropdown(true);
      setDispositionIds(csvStringToArray(disposition_ids));
    }
    if (agent_ids) {
      setAgentIds(csvStringToArray(agent_ids));
    }
    if (call_type) {
      setCallType(csvStringToArray(call_type));
    }
    if (disposition_operator) {
      setCallDispositionOperator(disposition_operator);
    }
    if (duration_operator) {
      setCallDurationOperator(duration_operator);
    }
    if (call_duration) {
      setCallDuration(call_duration);
    }
    if (calls_per_day) {
      setCallsPerDay(calls_per_day);
    }
    if (calls_per_day_operator) {
      setCallsPerDayOperator(calls_per_day_operator);
    }
  }, [queryFilters]);

  // Panes
  const panes = [
    {
      menuItem: "Total Calls",
      render: () => (
        <Tab.Pane>
          <Segment basic>
            <TotalCallsChart queryFilters={queryFilters} />
          </Segment>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Agent Metrics",
      render: () => (
        <WhiteBackgroundWrapper>
          <CallAgentMetricsTable
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
          />
        </WhiteBackgroundWrapper>
      ),
    },
    {
      menuItem: "Disposition Breakdown",
      render: () => (
        <Tab.Pane>
          <Segment basic>
            <CallsBreakDownByDispositionChart
              applyFilters={applyFilters}
              queryFilters={queryFilters}
              setDispositionIds={setDispositionIds}
            />
          </Segment>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Dial Session Breakdown",
      render: () => (
        <Tab.Pane>
          <Segment basic>
            <CallsBreakDownByDialSessionChart
              applyFilters={applyFilters}
              queryFilters={queryFilters}
              setDialSessionIds={setDialSessionIds}
            />
          </Segment>
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: "Call QA Table",
    //   render: () => (
    //     <Tab.Pane>
    //       <Segment basic>Call QA Table</Segment>
    //     </Tab.Pane>
    //   ),
    // },
  ];

  return (
    <Container fluid>
      <Grid
        className={"call-dashboard"}
        relaxed
        stackable
        verticalAlign="top"
        columns={3}
      >
        <Grid.Row>
          <Grid.Column width={3}>
            <Segment>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Header as={"h4"} content="Filters" />
                <Button
                  secondary
                  size="tiny"
                  content={"Apply Filters"}
                  onClick={applyFilters}
                />
              </div>
              <Dropdown
                className={"filterOption"}
                selection
                placeholder="Select Date Range Preset"
                fluid
                value={dateRangePreset}
                options={dateRangePresets}
                onChange={handleDatePreset}
              />
              <Datetime
                className={"filterOption"}
                closeOnSelect={true}
                dateFormat={"YYYY-MM-DD"}
                timeFormat={false}
                onChange={moment => {
                  setDateRangePreset("");
                  if (moment.format) {
                    setStartDate(moment.format(DATE_FORMAT));
                  } else {
                    setStartDate("");
                  }
                }}
                value={startDate}
                renderInput={props => (
                  <Input
                    placeholder="Start Date"
                    icon="calendar outline"
                    fluid
                    {...props}
                  />
                )}
              />
              <Datetime
                className={"filterOption"}
                closeOnSelect={true}
                dateFormat={"YYYY-MM-DD"}
                timeFormat={false}
                onChange={moment => {
                  setDateRangePreset("");
                  if (moment.format) {
                    setEndDate(moment.format(DATE_FORMAT));
                  } else {
                    setEndDate("");
                  }
                }}
                value={endDate}
                renderInput={props => (
                  <Input
                    placeholder="End Date"
                    icon="calendar outline"
                    fluid
                    {...props}
                  />
                )}
              />
              <RuvixxSelect
                className={"filterOption"}
                placeholder="Campaign"
                multiple
                clearable
                fluid
                value={campaignIds || []}
                queryFn={CampaignService.getCampaignsForFilters}
                onChange={handleSelectCampaign}
              />
              <RuvixxSelect
                className={"filterOption"}
                placeholder="Agent"
                multiple
                clearable
                fluid
                value={agentIds || []}
                campaignIds={campaignIds}
                dialSessionIds={dialSessionIds}
                queryFn={UserService.getCallers}
                onChange={handleSelectAgent}
              />
              <RuvixxSelect
                className={"filterOption"}
                placeholder="Dial Session"
                multiple
                clearable
                fluid
                value={dialSessionIds || []}
                campaignIds={campaignIds}
                mapForCampaignId={true}
                queryFn={DialSessionService.getDialSessionsForFilters}
                onChange={handleSelectDialSession}
              />
              <Dropdown
                className={"filterOption"}
                value={callType || ""}
                clearable
                fluid
                selection
                multiple
                options={callTypeOptions}
                onChange={(e, { value }) => {
                  setCallType(value);
                }}
                placeholder="Call Type"
              />
              <div className={"filterOption"}>
                <Dropdown
                  value={callDispositionOperator}
                  placeholder={"Call Disposition"}
                  clearable
                  fluid
                  selection
                  options={callDispositionOptions}
                  onChange={(e, { value }) => {
                    setCallDispositionOperator(value);
                    if (!value) {
                      setDispositionIds([]);
                    }
                    setShowDispositionDropdown(!!value);
                  }}
                />
              </div>
              {showDispositionDropdown && (
                <RuvixxSelect
                  className={"filterOption"}
                  placeholder="Select Disposition"
                  search
                  multiple
                  fluid
                  clearable
                  value={dispositionIds || []}
                  queryFn={CallDispositionService.getDispositionsForFilters}
                  onChange={handleSelectDisposition}
                />
              )}
              <div className={"filterOption"}>
                <Dropdown
                  value={callDurationOperator}
                  placeholder={"Call Duration"}
                  clearable
                  fluid
                  selection
                  options={durationOperatorOptions}
                  onChange={(e, { value }) => {
                    setCallDurationOperator(value);
                  }}
                />
              </div>
              {callDurationOperator && (
                <Input
                  fluid
                  name="callDuration"
                  style={{ marginTop: "1rem" }}
                  placeholder="Time in Seconds..."
                  value={callDuration}
                  onChange={(e, { value }) => {
                    setCallDuration(value.replace(/\D/g, ""));
                  }}
                />
              )}
              <div className={"filterOption"}>
                <Dropdown
                  value={callsPerDayOperator}
                  placeholder="Calls Per Day"
                  clearable
                  fluid
                  selection
                  options={callsPerDayOperatorOptions}
                  onChange={(e, { value }) => {
                    setCallsPerDayOperator(value);
                  }}
                />
                {callsPerDayOperator && (
                  <Input
                    fluid
                    name="callsPerDay"
                    style={{ marginTop: "1rem" }}
                    placeholder="Number of Calls..."
                    value={callsPerDay}
                    onChange={(e, { value }) => {
                      setCallsPerDay(value.replace(/\D/g, ""));
                    }}
                  />
                )}
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column width={13}>
            <CallDashboardSummaryStats queryFilters={queryFilters} />
            <Tab
              panes={panes}
              activeIndex={tabs.indexOf(activeTab)}
              onTabChange={handleTabChange}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
export default CallDashboard;

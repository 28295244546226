import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Header, Modal } from "semantic-ui-react";
import CategoriesTable from "../CategoriesTable";

class ManageCategoriesModal extends Component {
  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchCategories: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    return (
      <div>
        <Button
          icon="setting"
          onClick={this.handleOpen}
          size="mini"
          basic
          style={{ padding: "10px" }}
        />
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
          size="small"
          closeIcon
          style={{ maxHeight: "50%", overflow: "auto" }}
        >
          <Header content="Manage Categories" />
          <Modal.Content>
            <CategoriesTable
              categories={this.props.categories}
              fetchCategories={this.props.fetchCategories}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
export default ManageCategoriesModal;

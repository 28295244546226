import React, { useState, useCallback, cloneElement } from "react";
import { Modal, Button, Label, Icon, Form, Popup } from "semantic-ui-react";
import DialSessionService from "../../services/DialSessions";
import CallQueueService from "../../services/CallQueue";
import VoicemailService from "../../services/Voicemails";
import EmailUrlService from "../../services/EmailUrls";
import ContactService from "../../services/Contact";
import CampaignService from "../../services/Campaign";
import RuvixxForm from "../../components/RuvixxForm";
import { DEFAULT_CAMPAIGN_ID } from "../../constants/Constants";

function CallModal({
  contactId,
  campaignId = DEFAULT_CAMPAIGN_ID,
  disabled = false,
  voicemails,
  forms,
  trigger,
  iconOnly = false,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [voicemailOptions, setVoicemailOptions] = useState([]);
  const [formOptions, setFormOptions] = useState([]);
  const [formData, setFormData] = useState({});
  const [campaignOptions, setCampaignOptions] = useState([]);

  const fetchVoicemails = useCallback(async () => {
    let voicemailOptions =
      voicemails || (await VoicemailService.getVoicemailsForFilter());
    voicemailOptions = voicemailOptions.map(voicemail => ({
      text: voicemail.name,
      key: voicemail.id,
      value: voicemail.id,
    }));
    setVoicemailOptions(voicemailOptions);
  }, [VoicemailService.getVoicemailsForFilter]);

  const fetchForms = useCallback(async () => {
    let formOptions = forms || (await EmailUrlService.getForms());
    formOptions = formOptions.map(form => ({
      text: form.name,
      key: form.id,
      value: form.id,
    }));
    setFormOptions(formOptions);
  }, []);

  const fetchCampaigns = useCallback(async () => {
    const campaigns = await CampaignService.getCampaignsForFilters({
      contact_id: contactId,
    });
    const campaignOptions = campaigns.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
    setCampaignOptions(campaignOptions);
  }, []);

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const makeCall = async (contactId, voicemailId, formId) => {
    let isCallableInfo = await ContactService.checkContactCallableHours(
      contactId
    );
    const nonCallableMsg = `Contact is outside calling hours ( ${isCallableInfo["window_start"]}:00 - ${isCallableInfo["window_end"]}:00 ${isCallableInfo["time_zone"]} )`;
    if (!isCallableInfo["callable"]) {
      throw new Error(nonCallableMsg);
    }

    const sessionData = {
      name: `Contact ${contactId} - ${new Date().toISOString()}`,
      campaign_id: formData.campaign || campaignId,
      status: true,
      voicemail_id: voicemailId,
      form_id: formId,
      info: {
        enable_recording: true,
      },
    };
    const dialSession = await DialSessionService.createPrivateSession(
      sessionData
    );
    await CallQueueService.addContact(dialSession.id, contactId, true);
    const contactExists = await CallQueueService.nextContactExists(
      dialSession.id
    );
    if (!contactExists) {
      throw new Error(nonCallableMsg);
    }
    window.open(
      `/dialer/${dialSession.id}?private=1`,
      "_blank",
      "location=yes,height=750,width=1800,scrollbars=yes,status=yes"
    );
  };

  const handleOpen = () => {
    fetchVoicemails();
    if (campaignId !== DEFAULT_CAMPAIGN_ID) {
      fetchForms();
    } else {
      fetchCampaigns();
    }
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = async e => {
    const { voicemailId, formId } = formData;
    try {
      await makeCall(contactId, voicemailId, formId);
    } catch (error) {
      let message = error.message;
      if ("response" in error) {
        message = error.response.data.message;
      }
      throw new Error(message);
    }
  };
  const handleSuccess = async () => {
    handleClose();
  };

  const defaultTrigger = () => (
    <Button
      onClick={handleOpen}
      disabled={disabled}
      as="div"
      labelPosition="right"
      size="tiny"
    >
      <Button icon>
        <Icon name="call" />
      </Button>
      <Label as="a" basic>
        Call
      </Label>
    </Button>
  );

  const iconOnlyTrigger = () => (
    <Popup
      content="Call"
      trigger={
        <Button
          onClick={handleOpen}
          disabled={disabled}
          icon="call"
          size="tiny"
          className="iconOnly"
        />
      }
    />
  );

  return (
    <Modal
      size="tiny"
      trigger={cloneElement(
        trigger || (iconOnly ? iconOnlyTrigger() : defaultTrigger()),
        {
          onClick: handleOpen,
          disabled: disabled,
        }
      )}
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>Call Contact</Modal.Header>
      <Modal.Content>
        <RuvixxForm
          ready={
            (!!formData.campaign || !!campaignId) && !!formData.voicemailId
          }
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          submitButtonText="Start Call"
        >
          {campaignId == DEFAULT_CAMPAIGN_ID && (
            <Form.Select
              inline
              fluid
              required
              search
              onChange={handleSelect}
              value={formData.campaign}
              label="Campaign"
              name="campaign"
              options={campaignOptions}
              placeholder="Select Campaign"
            />
          )}
          <Form.Select
            inline
            fluid
            required
            search
            onChange={handleSelect}
            value={formData.voicemailId}
            label="Voicemail"
            name="voicemailId"
            options={voicemailOptions}
            placeholder="Select Voicemail"
          />
          {campaignId != DEFAULT_CAMPAIGN_ID && (
            <Form.Select
              inline
              fluid
              search
              clearable
              onChange={handleSelect}
              value={formData.formId}
              label="Questionnaire"
              name="formId"
              options={formOptions}
              placeholder="Select Questionnaire"
            />
          )}
        </RuvixxForm>
      </Modal.Content>
    </Modal>
  );
}

export default CallModal;

import CONSTANTS from "../constants/Constants";
import { upperFirst } from "./string";

const getPhoneType = value => {
  let phone_type = Object.keys(CONSTANTS.CONTACT_PHONE_TYPES).find(
    key => CONSTANTS.CONTACT_PHONE_TYPES[key] === value
  );
  phone_type = upperFirst(phone_type.toLowerCase());
  return phone_type;
};

export default getPhoneType;

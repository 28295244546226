import React, { useState, useEffect } from "react";
import ActivityService from "../../services/Activity";
import { Card, List } from "semantic-ui-react";
import { iconByType } from "./helpers";

const PleteoUpload = props => {
  const [attachments, setAttachments] = useState([]);
  useEffect(() => {
    if (props.activityLogId) {
      const fetchActivityLog = async () => {
        if (props.activityLogId !== null) {
          const activityLog = await ActivityService.getActivityLog(
            "Contact",
            props.activityLogId
          );
          setAttachments(activityLog.attachments);
        }
      };

      fetchActivityLog().catch(console.error);
    }
    if (props.attachments) {
      setAttachments(props.attachments);
    }
  }, [props.activityLogId, props.attachments]);

  return (
    <Card fluid>
      <Card.Content>
        {attachments.map(attachment => (
          <List divided relaxed key={attachment.id}>
            <List.Item>
              <List.Icon
                name={iconByType(attachment.file_content_type)}
                verticalAlign="middle"
              />
              <List.Content>
                <List.Header>
                  <a
                    target="_blank"
                    href={attachment.url}
                    style={{ wordBreak: "break-word" }}
                  >
                    {attachment.file_name}
                  </a>
                </List.Header>
              </List.Content>
            </List.Item>
          </List>
        ))}
      </Card.Content>
    </Card>
  );
};

export default PleteoUpload;

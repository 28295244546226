import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Segment, Checkbox, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import BaseTable from "../BaseTable";
import AuthService from "../../services/Auth";
import EntityService from "../../services/Entity";
import ContactService from "../../services/Contact";
import CampaignService from "../../services/Campaign";
import { SendEmailModal } from "../../routes/campaign/components/Modals";
import CustomDropdown from "../CustomDropdown";
import {
  AddToCampaignModal as _AddToCampaignModal,
  IncludeContactsModal as _IncludeContactsModal,
  RemoveCampaignModal as _RemoveCampaignModal,
} from "../modals/CampaignTableModals";
import CampaignStatusModal from "../../routes/campaign/components/CampaignStatusModal";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import ACL_RELATIONSHIPS from "../../acl-relationships";
import { checkIsAuthorized } from "../../components/helpers";
import tablesSettings from "constants/Table";

const AddToCampaignModal = withRoleCheck(_AddToCampaignModal, [
  ACL_RELATIONSHIPS.campaignTarget.create,
]);
const IncludeContactsModal = withRoleCheck(_IncludeContactsModal, [
  ACL_RELATIONSHIPS.campaignTargetContacts.create,
]);
const RemoveCampaignModal = withRoleCheck(_RemoveCampaignModal, [
  ACL_RELATIONSHIPS.campaignTarget.delete,
]);

class CampaignTable extends BaseTable {
  static propTypes = {
    entityId: PropTypes.number,
    contactId: PropTypes.number,
    sendEmail: PropTypes.bool,
  };

  static defaultProps = {
    entityId: null,
    contactId: null,
    sendEmail: false,
  };

  constructor(props) {
    super(props);

    let canEmail = false;

    if (props.entityId) {
      this.queryMethod = EntityService.getCampaignTable;
      this.queryArgs = [props.entityId];
      canEmail = checkIsAuthorized([ACL_RELATIONSHIPS.entityEmail.create]);
    } else if (props.contactId) {
      this.queryMethod = ContactService.getCampaignTable;
      this.queryArgs = [props.contactId];
      canEmail = checkIsAuthorized([ACL_RELATIONSHIPS.contactEmail.create]);
    } else {
      throw new Error(
        "One of props 'entityId' or 'contactId' was not specified in 'CampaignTable'."
      );
    }

    this.state = {
      ...this.state,
      className: "Campaign",
      tableName: "campaign",
      noDataText: "No Campaigns found. Try adjusting your filters.",
      enableSearch: false,
      createButton: (
        <AddToCampaignModal
          entityId={props.entityId}
          contactId={props.contactId}
          campaignsAdded={this.getRows}
          onSuccess={this.fetchData}
        />
      ),
      canEmail,
    };
  }

  async componentDidMount() {
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  handleRemoveCampaign = async (campaign_id, entity_id, contact_id) => {
    if (contact_id) {
      await CampaignService.removeContact(campaign_id, entity_id, contact_id);
    } else {
      await CampaignService.deleteCampaignTarget(campaign_id, entity_id);
    }
    await this.fetchData();
  };

  enableEmails = (contact_id, has_contacts) =>
    !!this.props.sendEmail
      ? this.props.sendEmail
      : contact_id
      ? false
      : has_contacts;

  setColumns() {
    const columns = [
      {
        Header: "ID",
        accessor: "campaign_id",
        width: 50,
        Cell: ({ value, original: { campaign_id, entity_id } }) => (
          <Link to={`/campaigns/${campaign_id}/target/${entity_id}`}>
            {value}
          </Link>
        ),
      },
      {
        Header: "Campaign Name",
        accessor: "campaign_name",
        Cell: ({ value, original: { campaign_id, entity_id } }) => (
          <Link to={`/campaigns/${campaign_id}/target/${entity_id}`}>
            {value}
          </Link>
        ),
      },
      {
        Header: "Assignee",
        accessor: "assignee_full_name",
      },
      {
        Header: "Send Email",
        headerClassName: "centered non-sortable",
        className: "centered",
        sortable: false,
        Cell: ({
          original: { campaign_id, entity_id, contact_id, has_contacts },
        }) =>
          this.state.canEmail ? (
            <SendEmailModal
              campaignId={campaign_id}
              entityId={entity_id}
              contactId={contact_id}
              disabled={
                !this.enableEmails(contact_id, has_contacts) &&
                !this.state.canEmail
              }
            />
          ) : null,
      },
      {
        Header: "Campaign Status",
        accessor: "campaign_status",
        headerClassName: "centered",
        minWidth: tablesSettings.statusColumnMinWidth,
        className: "centered",
        Cell: ({
          value,
          original: { campaign_id, entity_id, contact_id, entity_name },
        }) => (
          <CampaignStatusModal
            campaignStatus={value}
            campaignId={campaign_id}
            entityId={entity_id}
            contactId={contact_id}
            updateData={this.fetchData}
            entityName={entity_name}
          />
        ),
      },
      {
        Header: "Last Status Updated",
        accessor: "status_last_updated_at",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return "N/A";
        },
      },
      {
        Header: "Actions",
        id: "actions",
        headerClassName: "centered non-sortable",
        className: "centered action-menu",
        sortable: false,
        minWidth: 60,
        Cell: ({
          original: { campaign_id, entity_id, contact_id, campaign_name },
        }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              {!!this.props.entityId && (
                <IncludeContactsModal
                  entityId={entity_id}
                  campaignId={campaign_id}
                  onSuccess={this.fetchData}
                />
              )}
              <RemoveCampaignModal
                menuTrigger
                campaignName={campaign_name}
                modelType={contact_id ? "contact" : "entity"}
                onConfirmDelete={async () =>
                  await this.handleRemoveCampaign(
                    campaign_id,
                    entity_id,
                    contact_id
                  )
                }
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];

    const { freezedColumns, visibleColumns } = this.getTableSettings(columns);
    columns.forEach(c => {
      c.show =
        typeof c.Header !== "string" || visibleColumns.includes(c.Header);
    });
    this.setState({ columns, freezedColumns, visibleColumns });
  }

  render() {
    return (
      (!!this.props.entityId || !!this.props.contactId) && (
        <Segment
          style={{
            boxShadow: "0px 0px 5px 1px #ddd",
          }}
        >
          {this.renderTable()}
        </Segment>
      )
    );
  }
}

export default CampaignTable;

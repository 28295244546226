import React from "react";
import { Container, Segment, Dropdown } from "semantic-ui-react";

import ACL_RELATIONSHIPS from "../../acl-relationships";
import withRoleCheck from "../../components/hocs/withRoleCheck";
import AuthService from "../../services/Auth";

import CustomDropdown from "../../components/CustomDropdown";
import setPageTitle from "../../helpers/title";
import CampaignService from "../../services/Campaign";
import EditCampaignStatusModal from "./components/EditCampaignStatusModal";
import DeleteCampaignStatusModal from "./components/DeleteCampaignStatusModal";
import NewCampaignStatusModal from "./components/NewCampaignStatusModal";
import BaseTable from "../../components/BaseTable";

const NewCampaignStatusModalWithCheck = withRoleCheck(NewCampaignStatusModal, [
  ACL_RELATIONSHIPS.campaignStatuses.create,
]);

const EditCampaignStatusModalWithCheck = withRoleCheck(
  EditCampaignStatusModal,
  [ACL_RELATIONSHIPS.campaignStatus.edit]
);

const DeleteCampaignStatusModalWithCheck = withRoleCheck(
  DeleteCampaignStatusModal,
  [ACL_RELATIONSHIPS.campaignStatus.delete]
);

class CampaignsStatusesTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = CampaignService.getCampaignStatusesAsTable;

    this.state = {
      ...this.state,
      enableSearch: true,
      header: "Campaign Status",
      headerIcon: "bullseye",
      tableName: "campaign_statuses",
      noDataText: "No campaign statuses found. Try adjusting your filters.",
      createButton: (
        <NewCampaignStatusModalWithCheck fetchStatuses={this.fetchData} />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Campaign Statuses");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        className: "centered",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Actions",
        id: "actions",
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: ({ original: campaignStatus }) => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <EditCampaignStatusModalWithCheck
                campaignStatus={campaignStatus}
                fetchStatuses={this.fetchData}
              />
              <DeleteCampaignStatusModalWithCheck
                campaignStatus={campaignStatus}
                fetchStatuses={this.fetchData}
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default CampaignsStatusesTable;

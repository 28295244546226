import React, { useState } from "react";
import { Table, Button, Icon, Segment, Form } from "semantic-ui-react";
import { formatAddress } from "../../../helpers/address";

import "./../../../styles/forms.scss";
import "./addresses.scss";

import AddressForm from "./AddressForm";

const Addresses = ({
  required,
  addresses,
  updateAddresses,
  updatePrimaryAddress,
  initialActiveIndex = -1,
  primaryAddressId = null,
  showPrimaryColumn = false,
  showLatLong = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);
  const [touched, setTouched] = useState(false);

  const handleClick = (e, index) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const addEmptyAddress = () => {
    addresses.push({});
    updateAddresses(addresses);
    setActiveIndex(addresses.length - 1);
  };

  const updateAddress = (index, address) => {
    addresses.splice(index, 1, address);
    updateAddresses(addresses);
  };

  const deleteAddress = index => {
    addresses.splice(index, 1);
    updateAddresses(addresses);
  };

  const handleDelete = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const newIndex = activeIndex === index ? -1 : activeIndex;
    setActiveIndex(newIndex);
    deleteAddress(index);
  };

  const missingRequiredFields = address =>
    !address.street1 ||
    !address.city ||
    !address.region ||
    !address.postal_code ||
    !address.country;

  const isPrimaryCheckboxChecked = address => {
    if (address.id) {
      return primaryAddressId == address.id;
    } else {
      return address.primary;
    }
  };

  return (
    <>
      <Table basic="very" style={{ tableLayout: "fixed" }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Addresses</Table.HeaderCell>
            {showPrimaryColumn && (
              <Table.HeaderCell width={3}>Primary?</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {addresses.length === 0 && <p>No Addresses</p>}
          {addresses.map((address, index) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Segment.Group className="addressItem">
                    <Segment
                      className="addressHeader"
                      key={`addressTitle-${index}`}
                      index={index}
                      onClick={e => handleClick(e, index)}
                    >
                      <Icon
                        name={
                          activeIndex === index
                            ? "triangle down"
                            : "triangle right"
                        }
                      />
                      {activeIndex === index ? (
                        <strong>{formatAddress(address)}</strong>
                      ) : (
                        formatAddress(address)
                      )}
                      {missingRequiredFields(address) && (
                        <span style={{ marginLeft: "1rem" }}>
                          <strong className="warning">
                            (Missing Required Fields)
                          </strong>
                        </span>
                      )}
                      {(!required || addresses.length > 1) && (
                        <Icon
                          link
                          color="red"
                          name="cancel"
                          onClick={e => handleDelete(e, index)}
                        />
                      )}
                    </Segment>
                    {activeIndex === index && (
                      <AddressForm
                        active={activeIndex === index}
                        address={address}
                        updateAddress={address => updateAddress(index, address)}
                        touched={touched}
                        setTouched={() => setTouched(true)}
                        showLatLong={showLatLong}
                      />
                    )}
                  </Segment.Group>
                </Table.Cell>
                {showPrimaryColumn && (
                  <Table.Cell>
                    {!missingRequiredFields(address) && (
                      <Form.Checkbox
                        toggle
                        inline
                        name="primaryAddress"
                        checked={isPrimaryCheckboxChecked(address)}
                        onChange={(_, data) =>
                          updatePrimaryAddress(address, index, data.checked)
                        }
                      />
                    )}
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Button
        basic
        size="tiny"
        icon={"plus"}
        content={"Add Address"}
        onClick={addEmptyAddress}
      />
    </>
  );
};

export default Addresses;

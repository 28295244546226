import React from "react";
import WixFormResponseV1 from "./WixFormResponseV1";
import WixFormResponseV2 from "./WixFormResponseV2";
import WixFormResponseV3 from "./WixFormResponseV3";

function iconByType(type) {
  switch (type) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "file word outline";
    case "application/pdf":
      return "file pdf outline";
    case "application/vnd.ms-powerpoint":
      return "file powerpoint outline";
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "file excel outline";
    case "image/gif":
    case "image/jpeg":
    case "image/png":
    case "image/svg+xml":
    case "image/tiff":
    case "image/webp":
      return "file image outline";
    case "video/x-msvideo":
    case "video/mpeg":
    case "video/ogg":
    case "video/mp2t":
    case "video/webm":
    case "video/mp4":
      return "file video outline";
    case "application/zip":
    case "application/x-rar-compressed":
    case "application/x-7z-compressed":
      return "file archive outline";
    default:
      return "file outline";
  }
}

// TODO: why are we passing ids here, why doesn't response always have an id already?
function parseWixForm(response, ids, language, contact = undefined) {
  switch (response.form_response.form_version) {
    case "3.0":
      response.form_response.id = response.id || ids;
      return (
        <WixFormResponseV3
          form_response={response.form_response}
          language={response.language || language}
          contact={contact}
        />
      );
    case "2.0":
      return (
        <WixFormResponseV2
          form_response={response.form_response}
          language={response.language || language}
          contact={contact}
          ids={ids}
        />
      );
    default:
      return WixFormResponseV1(
        response.form_response,
        response.language || language,
        contact,
        ids
      );
  }
}

export { parseWixForm, iconByType };

import React, { useCallback, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Breadcrumb,
  Container,
  Grid,
  Header,
  Icon,
  Menu,
  Segment,
  Message,
} from "semantic-ui-react";
import setPageTitle from "../../helpers/title";
import EmailTemplateService from "../../services/EmailTemplate";
import { LoadingMessage } from "./../../components/helpers";
import EmailTemplateForm from "./forms/EmailTemplateForm";
import GeneralEmailTemplateForm from "./forms/GeneralEmailTemplate";
import HtmlEmailTemplateForm from "./forms/HtmlEmailTemplate";
import RenderEmailTemplateList from "./forms/RenderEmailTemplateList";
import {
  NoTemplateWarningForm,
  WarningEmailTemplateForm,
} from "./forms/WarningEmailTemplate";
import useEphemeral from "../../hooks/useEphemeral";

const EmailTemplate = ({ id, isClone, isNew, onSuccess }) => {
  const isModal = !!id;
  const tabs = ["general", "graphical", "HTML", "render"];
  const [template, setTemplate] = useState({});
  const [options, setOptions] = useState({});
  const [activeTab, setActiveTab] = useState("general");
  const [successMessageVisible, showSuccessMessage] = useEphemeral();
  const params = useParams();

  const fetchTemplate = async id => {
    const template = await EmailTemplateService.getEmailTemplate(id);

    setPageTitle(template.name);

    if (isClone) {
      template.id = undefined;
      template.name = "";
    }
    setTemplate(template);
  };

  const setup = useCallback(async () => {
    if (id || params.id) {
      await fetchTemplate(id || params.id);
    }
    const tags = await EmailTemplateService.getTags();
    const urls = await EmailTemplateService.getUrls();
    setOptions({ mergeTags: tags, specialLinks: urls });
  }, []);

  useEffect(() => {
    setup();
  }, [setup]);

  const renderTabMenu = () => {
    return (
      <Menu className="navbar" pointing secondary style={{ marginLeft: "2em" }}>
        {tabs.map(tab => (
          <Menu.Item
            key={tab}
            name={tab}
            active={activeTab === tab}
            onClick={() => {
              if (activeTab === "HTML") {
                if (tab === "graphical") {
                  setActiveTab("warning");
                } else {
                  setActiveTab(tab);
                }
              } else if (!template.id) {
                setActiveTab("no_id");
              } else {
                setActiveTab(tab);
              }
            }}
          />
        ))}
      </Menu>
    );
  };

  const changeTab = async (tab, updatedTemplate) => {
    if (updatedTemplate) {
      setTemplate(updatedTemplate);
    }
    setActiveTab(tab);
    renderTabView();
  };

  const renderTabView = () => {
    let isReady = true;
    let templateForm = null;
    switch (activeTab) {
      default:
      case "general":
        templateForm = (
          <GeneralEmailTemplateForm
            template={template}
            options={options}
            changeTab={changeTab}
            isClone={isClone}
            showSuccessMessage={showSuccessMessage}
          />
        );
        break;
      case "graphical":
        templateForm = (
          <EmailTemplateForm
            id={template.id}
            name={template.name}
            subject={template.subject}
            text={template.text}
            html={unescape(template.html)}
            info={template.info}
            options={options}
            changeTab={changeTab}
            fetchTemplate={fetchTemplate}
            showSuccessMessage={showSuccessMessage}
          />
        );
        break;
      case "HTML":
        templateForm = (
          <HtmlEmailTemplateForm
            id={template.id}
            name={template.name}
            subject={template.subject}
            text={template.text}
            html={unescape(template.html)}
            info={template.info}
            options={options}
            changeTab={changeTab}
            fetchTemplate={fetchTemplate}
            showSuccessMessage={showSuccessMessage}
          />
        );
        break;
      case "render":
        templateForm = (
          <RenderEmailTemplateList
            templateFormId={template.id}
            onSuccess={onSuccess}
            changeTab={changeTab}
            name={template.name}
          />
        );
        break;
      case "warning":
        templateForm = <WarningEmailTemplateForm changeTab={changeTab} />;
        break;
      case "no_id":
        templateForm = <NoTemplateWarningForm changeTab={changeTab} />;
        break;
    }
    return isReady ? (
      <Segment basic>{templateForm}</Segment>
    ) : (
      <LoadingMessage />
    );
  };

  const renderSuccessMessage = () => {
    return (
      successMessageVisible && (
        <Message positive>
          <p>Saved successfully</p>
        </Message>
      )
    );
  };

  return (
    <>
      {isNew || isClone || isModal ? (
        <>
          {renderTabMenu()}
          {renderTabView()}
          {renderSuccessMessage()}
        </>
      ) : (
        <Container fluid className="route">
          <Grid className="bg list-grid" divided>
            <Grid.Row>
              <Grid.Column className="sixteen wide">
                <Breadcrumb style={{ margin: "1em 0 0 1em" }}>
                  <Breadcrumb.Section>
                    <Link to="/settings/email_templates">Email Templates</Link>
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron" />
                  <Breadcrumb.Section active>
                    {template.name}
                  </Breadcrumb.Section>
                </Breadcrumb>
                <Header>
                  <Icon name="envelope" />
                  Edit Email Template
                </Header>
                {renderTabMenu()}
                {renderTabView()}
                {renderSuccessMessage()}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default EmailTemplate;

import { applyFilters, doRequest } from "./helpers";

const basePath = `/constant_contact/campaign/`;

export default class ConstantContactCampaignsService {
  static async getCampaignActivities(filters) {
    let path = applyFilters(filters, basePath);
    return doRequest("GET", path);
  }

  static async getCampaignActivity(id) {
    let path = `${basePath}${id}`;
    return doRequest("GET", path);
  }

  static async createCampaignActivity(data) {
    return doRequest("POST", basePath, { data });
  }

  static async createPreviewCampaignActivity(data) {
    let path = `${basePath}preview`;
    return doRequest("POST", path, { data });
  }

  static editCampaignActivity(id, data) {
    return doRequest("PUT", `${basePath}${id}`, { data });
  }

  static deleteCampaignActivity(id) {
    return doRequest("DELETE", `${basePath}${id}`);
  }

  static syncSchedule(schedule_id, template_only, contacts_only) {
    return doRequest("POST", `/constant_contact/sync_schedule/${schedule_id}`, {
      data: {
        template_only: template_only,
        contacts_only: contacts_only,
      },
    });
  }
}

import React, {
  Component,
  cloneElement,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import store from "store";

import { Modal, Button } from "semantic-ui-react";
import NoteForm from "../forms/NoteForm";
import ConfirmationModal from "./ConfirmationModal";
import AuthService from "../../services/Auth";
import UserService from "../../services/User";

const NewNoteModal = forwardRef(
  (
    {
      modelType,
      modelId,
      onSuccess = () => {},
      refersToModel,
      refersToID,
      trigger = (
        <Button size="tiny" content="Add Note" className="item-adder" />
      ),
      noTrigger = false,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
    }));

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleSuccess = async () => {
      await onSuccess();
      handleClose();
    };

    return (
      <Modal
        size="small"
        trigger={
          noTrigger ? undefined : cloneElement(trigger, { onClick: handleOpen })
        }
        open={open}
        onClose={handleClose}
        closeOnDimmerClick={false}
        closeIcon
        onFocus={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Modal.Header>New Note</Modal.Header>
        <Modal.Content>
          <NoteForm
            modelType={modelType}
            modelId={modelId}
            categories={categoryOptions}
            updateCategories={newCategories =>
              setCategoryOptions(newCategories)
            }
            onSuccess={handleSuccess}
            refersToModel={refersToModel}
            refersToID={refersToID}
          />
        </Modal.Content>
      </Modal>
    );
  }
);

class EditNoteModal extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    note: PropTypes.object.isRequired,
    categories: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      categoryOptions: [],
      authorized: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSuccess = async () => {
    await this.props.onSuccess();
    this.handleClose();
  };

  componentDidMount = async () => {
    const { note } = this.props;
    if (AuthService.isLoggedIn() && note) {
      const userId = store.get("userAuth").id;
      const isCreator = note.creator_user_id === userId;
      let isAdmin = false;
      if (!isCreator) {
        isAdmin = await UserService.isAdmin();
      }
      this.setState({ authorized: isCreator || isAdmin });
    }
  };

  render() {
    return (
      this.state.authorized && (
        <Modal
          size="small"
          trigger={cloneElement(this.props.trigger, {
            onClick: this.handleOpen,
          })}
          open={this.state.open}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
          closeIcon
        >
          <Modal.Header>Edit Note</Modal.Header>
          <Modal.Content>
            <NoteForm
              note={this.props.note}
              modelId={this.props.note.model_id}
              modelType={this.props.note.model_type}
              updateCategories={newCategories =>
                this.setState({ categoryOptions: newCategories })
              }
              categories={this.props.categories}
              onSuccess={this.handleSuccess}
            />
          </Modal.Content>
        </Modal>
      )
    );
  }
}

class DeleteNoteModal extends Component {
  static propTypes = {
    onConfirmDelete: PropTypes.func.isRequired,
  };

  render() {
    return (
      <ConfirmationModal
        actionDescription="Delete"
        icon="trash"
        buttonColor="grey"
        onConfirm={this.props.onConfirmDelete}
        menuTrigger={this.props.menuTrigger}
        warning
      >
        <p>Are you sure you want to delete this Note?</p>
      </ConfirmationModal>
    );
  }
}

export { NewNoteModal, EditNoteModal, DeleteNoteModal };

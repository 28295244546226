import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { Form, Input } from "semantic-ui-react";
import store from "store";

import CustomFieldForm from "../../../components/forms/CustomFieldForm";
import {
  checkIsCustomFieldsReady,
  extractCustom,
  getCustomFieldsForSubmit,
} from "../../../components/helpers";
import RuvixxForm from "../../../components/RuvixxForm";
import RuvixxSelect from "../../../components/RuvixxSelect";
import ContractService from "../../../services/Contract";
import CustomFieldService from "../../../services/CustomField";
import DepartmentService from "../../../services/Department";

export default function ContractForm(props) {
  const DEFAULT_VALUES = {
    name: "",
    description: "",
    external_contract_num: "",
    entity_id: props.entityId,
    start_date: null,
    end_date: null,
    num_licenses: 0,
    parent_contract_id: null,
    department_id: null,
  };
  let contract = props.contract;
  let entityId = props.entityId;
  const { timeZone } = store.get("userAuth");
  const [formData, setFormData] = useState(DEFAULT_VALUES);
  const [custom, setCustom] = useState([]);
  const [customFieldConfigs, setCustomFieldConfigs] = useState([]);
  const [contractOptions, setContractOptions] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const handleSubmit = async e => {
    const cust = getCustomFieldsForSubmit(customFieldConfigs, custom);
    formData["custom_fields"] = cust;
    if (props.contract) {
      await ContractService.editContract(
        props.contractId,
        props.entityId || entityId,
        formData
      );
    } else {
      await ContractService.createContract(
        props.entityId || entityId,
        formData
      );
    }
  };

  const handleChange = (_, { name, value }) => {
    if (!name) {
      return;
    }
    let newValue;
    if (!value) {
      newValue = DEFAULT_VALUES[name];
    } else {
      newValue = name === "num_licenses" ? parseInt(value) : value;
    }
    const newFormData = { ...formData, [name]: newValue };
    setFormData(newFormData);
  };

  const handleSelect = (e, { name, value }) => {
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const handleStart = moment => {
    const formDataCopy = { ...formData };
    formDataCopy["start_date"] = moment;
    setFormData(formDataCopy);
  };

  const handleEnd = moment => {
    const formDataCopy = { ...formData };
    formDataCopy["end_date"] = moment;
    setFormData(formDataCopy);
  };

  const fetchContracts = async () => {
    let filters = { entity_id: props.entityId };
    let contracts = await ContractService.getContractForFilters(filters);
    let noneOption = {
      key: 0,
      value: null,
      text: "None",
    };
    let contractOptions = contracts.map(contract => ({
      key: contract.id,
      value: contract.id,
      text: contract.name,
    }));
    contractOptions.unshift(noneOption);
    if (props.contract) {
      contractOptions = contractOptions.filter(
        contract => contract.key != props.contract.id
      );
    }
    setContractOptions(contractOptions);
  };

  const fetchCustomFieldConfigs = async () => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel("Contract");
    setCustomFieldConfigs(customFieldConfigs);
  };

  const handleUpdateCustomFieldConfigs = customFieldConfigs => {
    setCustomFieldConfigs(customFieldConfigs);
    setCustom(
      customFieldConfigs
        .filter(cfc => cfc.required || cfc.selected)
        .map(cfc => ({ key: cfc.label, value: cfc.value }))
    );
  };

  useEffect(() => {
    fetchContracts(props.entityId);
    fetchCustomFieldConfigs();
  }, [props.entityId]);

  useEffect(() => {
    if (props.contract) {
      const formDataCopy = {
        name: contract.name || "",
        description: contract.description || "",
        external_contract_num: contract.external_contract_num || "",
        entity_id: props.entityId,
        start_date: contract.start_date || null,
        end_date: contract.end_date || null,
        num_licenses: contract.num_licenses,
        parent_contract_id: contract.parent_contract_id || null,
        department_id: contract.department_id || null,
      };
      const customFields = extractCustom(contract.custom_fields);
      setFormData(formDataCopy);
      setCustom(customFields);
    }
  }, [props.contract]);

  return (
    <RuvixxForm
      ready={
        formData.name &&
        formData.external_contract_num &&
        checkIsCustomFieldsReady(customFieldConfigs)
      }
      onSubmit={handleSubmit}
      onSuccess={props.onSuccess}
      error={error}
      errorMessage={errorMessage}
      errorTitle={errorTitle}
    >
      <Form.Input
        inline
        name="external_contract_num"
        label="External Contract Number"
        required
        value={formData.external_contract_num}
        onChange={handleChange}
      />
      <Form.Input
        inline
        name="name"
        label="Name"
        required
        value={formData.name}
        onChange={handleChange}
      />
      <Form.Input
        inline
        name="description"
        label="Description"
        value={formData.description}
        onChange={handleChange}
      />
      <Form.Input
        inline
        type="number"
        min="0"
        name="num_licenses"
        label="Number of Licenses"
        value={formData.num_licenses}
        onChange={handleChange}
      />
      <Form.Select
        label="Parent Contract ID"
        name="parent_contract_id"
        inline
        search
        value={formData.parent_contract_id}
        onChange={handleSelect}
        options={contractOptions}
      />
      <RuvixxSelect
        placeholder="Pick a Department"
        label="Department"
        name="department_id"
        value={formData.department_id}
        onChange={handleChange}
        queryFn={DepartmentService.getForFilters}
        createFn={DepartmentService.createDepartment}
        allowAdditions
        clearable
        setValue={() => {}}
      />
      <Form.Group>
        <Form.Field>
          <label>Start Time</label>
          <Datetime
            closeOnSelect={true}
            onChange={handleStart}
            displayTimeZone={timeZone}
            renderInput={props => (
              <Input icon="large calendar outline" {...props} />
            )}
            dateFormat={"YYYY-MM-DD"}
            value={formData.start_date && new Date(formData.start_date)}
          />
        </Form.Field>
        <Form.Field>
          <label>End Time</label>
          <Datetime
            closeOnSelect={true}
            onChange={handleEnd}
            displayTimeZone={timeZone}
            renderInput={props => (
              <Input icon="large calendar outline" {...props} />
            )}
            dateFormat={"YYYY-MM-DD"}
            value={formData.end_date && new Date(formData.end_date)}
          />
        </Form.Field>
      </Form.Group>
      {customFieldConfigs && (
        <CustomFieldForm
          modelType="Contract"
          customFields={custom}
          customFieldConfigs={customFieldConfigs}
          onUpdateCustomFieldConfigs={handleUpdateCustomFieldConfigs}
        />
      )}
    </RuvixxForm>
  );
}

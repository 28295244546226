import React, { useEffect, useState } from "react";
import { Form, Segment, Divider } from "semantic-ui-react";
import EmailTemplateService from "../../../services/EmailTemplate";

function HtmlEmailTemplateForm({
  id,
  name,
  subject,
  html,
  text,
  info,
  changeTab,
  fetchTemplate,
  showSuccessMessage,
}) {
  const [formHtml, setFormHtml] = useState("");

  const decodeHTML = html => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  useEffect(() => {
    setFormHtml(decodeHTML(html));
  }, [html]);

  const handleChange = (event, data) => {
    setFormHtml(data.value);
  };

  const handleSubmit = async () => {
    if (id) {
      const updatedTemplate = await EmailTemplateService.editEmailTemplate({
        id,
        info,
        html: formHtml,
      });
      changeTab("render", updatedTemplate);
    } else {
      let result = await EmailTemplateService.createEmailTemplate(html);
      changeTab("render", result.id);
    }
    showSuccessMessage();
  };

  const handlePreview = async () => {
    if (id) {
      await EmailTemplateService.editEmailTemplate({ id, html: formHtml });
      fetchTemplate(id);
    } else {
      await EmailTemplateService.createEmailTemplate(
        name,
        subject,
        text,
        html,
        info
      );
    }
    showSuccessMessage();
  };

  const createPreviewHTMLIframe = htmlContent => {
    const adjustIframeHeight = event => {
      const iframe = event.target;
      iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
    };

    return (
      <iframe
        title="Preview HTML"
        srcDoc={htmlContent}
        onLoad={adjustIframeHeight}
        style={{ width: "100%", border: "none" }}
      />
    );
  };

  return (
    <>
      <h2>{name}</h2>
      <Divider />
      <section>
        <Form>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Form.Button
              size="tiny"
              primary
              type="submit"
              onClick={handleSubmit}
            >
              SUBMIT
            </Form.Button>
          </div>
          <h2>HTML</h2>
          <Form.TextArea
            name="html"
            value={decodeHTML(formHtml)}
            style={{ minWidth: "100%" }}
            rows={30}
            onChange={handleChange}
          />
        </Form>
      </section>
      <section>
        <h2>HTML Preview</h2>
        {createPreviewHTMLIframe(formHtml)}
      </section>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Form.Button size="tiny" primary type="submit" onClick={handlePreview}>
          Save HTML
        </Form.Button>
      </div>
    </>
  );
}

export default HtmlEmailTemplateForm;

import React, { useState } from "react";
import { Form, Segment, Icon, Input, Checkbox } from "semantic-ui-react";
import startCase from "lodash/startCase";
import RuvixxSelect from "../../../components/RuvixxSelect";
import ContactService from "../../../services/Contact";
import { DELETE_QUESTION } from "./ConfirmDeleteModal";
import { questionTypes } from "../../../constants/Question";

import "./Question.scoped.scss";

const Question = ({
  question,
  questions = [],
  onUpdate,
  totalPages = 1,
  onReorder = () => {},
  onRemovePrompt = () => {},
  onEditOptions = () => {},
}) => {
  const getIndex = (order, page) => {
    const index = questions.findIndex(
      q => q.order === order && q.page === page
    );
    return index;
  };

  const {
    title,
    question_type,
    options,
    order,
    page,
    collapsed,
    required,
    roles,
  } = question;
  const index = getIndex(order, page);

  const isOptionType = type => {
    return [
      questionTypes.dropdown,
      questionTypes.checkboxGroup,
      questionTypes.radioButtonGroup,
    ].includes(type);
  };

  const isMulti = questions.length > 0;

  const questionTypesOptions = [];
  const question_types_entries = Object.entries(questionTypes);
  for (let i = 0; i < question_types_entries.length; i++) {
    const [key, value] = question_types_entries[i];
    if (isMulti || key !== "section") {
      // if followup don't show uploads
      if (!(question.parent_question_id && key === "upload")) {
        questionTypesOptions.push({
          key: value,
          text: startCase(key),
          value,
        });
      }
    }
  }

  const handleChange = (data, index) => {
    const { name, type } = data;
    const value = data[type === "checkbox" ? "checked" : "value"];
    let newQuestion = isMulti ? questions[index] : question;
    newQuestion = {
      ...newQuestion,
      [name]: value,
    };
    if (isMulti) {
      onUpdate(index, newQuestion);
    } else {
      onUpdate(newQuestion);
    }
  };

  return (
    <Segment className={`question${isMulti ? "" : " no-border"}`} key={order}>
      <Form.Field required>
        <label>Question{isMulti ? ` ${index + 1}` : ""}</label>
        {isMulti && (
          <div className="actions">
            <Icon
              name="arrow up"
              link
              onClick={() => onReorder(index, -1)}
              disabled={index === 0 && page === 1}
            />
            <Icon
              name="arrow down"
              link
              onClick={() => onReorder(index, 1)}
              disabled={index === questions.length - 1 && page === totalPages}
            />
            <Icon
              name="close"
              link
              onClick={() => onRemovePrompt(index, DELETE_QUESTION)}
            />
          </div>
        )}
        <Input
          name="title"
          value={title || ""}
          onChange={(_, data) => handleChange(data, index)}
        />
      </Form.Field>
      {question_type !== questionTypes.section && (
        <Form.Field inline>
          <label>
            Required <span className="requiredStar" />
          </label>
          <Checkbox
            toggle
            name="required"
            checked={required}
            onChange={(_, data) => handleChange(data, index)}
          />
        </Form.Field>
      )}
      {isMulti && (
        <Form.Field inline>
          <label>
            Collapsed <span className="requiredStar" />
          </label>
          <Checkbox
            toggle
            name="collapsed"
            checked={collapsed}
            onChange={(_, data) => handleChange(data, index)}
          />
        </Form.Field>
      )}
      <Form.Select
        inline
        label="Type"
        name="question_type"
        required
        options={questionTypesOptions}
        value={question_type}
        onChange={(_, data) => handleChange(data, index)}
      />
      {!question.parent_question_id &&
        question_type !== questionTypes.section && (
          <RuvixxSelect
            name="roles"
            label="Roles"
            value={roles}
            queryFn={ContactService.getAvailableRoles}
            clearable
            onChange={(_, data) => handleChange(data, index)}
            multiple
            lazy
          />
        )}
      {isOptionType(question_type) && (
        <Form.Field required inline className="options">
          <label>Options</label>
          <div onClick={() => onEditOptions(question, index, onUpdate)}>
            {options.length > 0
              ? options.map(({ label }) => label).join(", ")
              : "(No options. Click to add.)"}
          </div>
        </Form.Field>
      )}
    </Segment>
  );
};

export default Question;

import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "components/RuvixxForm";

import "styles/forms.scss";

import MachineApprovalStatusService from "services/MachineApprovalStatus";

const formReducer = (s, a) => ({ ...s, ...a });
const initializer = model => {
  return {
    id: model?.id,
    name: "",
  };
};

function MachineApprovalStatusForm(props) {
  const [formData, setFormData] = useReducer(
    formReducer,
    props.model,
    initializer
  );
  const [error, setError] = useState(null);

  const fetchModel = useCallback(async () => {
    if (!formData.id) {
      return;
    }
    const model = await MachineApprovalStatusService.get(formData.id);
    setFormData({
      ...formData,
      id: model.id,
      name: model.name,
    });
  }, []);

  useEffect(() => {
    if (formData.id) {
      fetchModel();
    }
  }, [formData.id]);

  const handleChange = async (_, { name, value }) => {
    if (!name) {
      return;
    }
    const formDataCopy = { ...formData };
    formDataCopy[name] = value;
    setFormData(formDataCopy);
  };

  const handleSubmit = async () => {
    const { id, name } = formData;
    const data = {
      name,
    };

    try {
      if (id) {
        await MachineApprovalStatusService.update(id, data);
      } else {
        await MachineApprovalStatusService.create(data);
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      setError(message);
      throw new Error(message);
    }
  };

  return (
    <RuvixxForm
      ready={!!formData.name}
      onSubmit={handleSubmit}
      onSuccess={props.onSuccess}
      error={error?.error}
      errorMessage={error?.errorMessage}
      errorTitle={error?.errorTitle}
    >
      <Form.Input
        required
        autoFocus
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
    </RuvixxForm>
  );
}

export default MachineApprovalStatusForm;

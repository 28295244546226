import React, { useState } from "react";
import {
  Button,
  Header,
  Modal,
  Confirm,
  Popup,
  Dropdown,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import EmailSignatureService from "../../../services/EmailSignatures";
import EmailSignatureForm from "../forms/EmailSignatureForm";

function EmailSignatureModal({ fetchSignatures, isClone, emailSignatureId }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [name, setName] = useState("");
  const [html, setHtml] = useState("");

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(true);
  };

  const handleCancelClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    setModalOpen(false);
  };

  const handleSuccess = async () => {
    await fetchSignatures();
    handleConfirmClose();
  };

  return (
    <>
      {emailSignatureId ? (
        <Dropdown.Item
          className="primary"
          content={isClone ? "Clone Email Signature" : "Edit Email Signature"}
          icon={isClone ? "clone" : "pencil"}
          onClick={handleOpen}
        />
      ) : (
        <Button
          content="New Signature Template"
          onClick={handleOpen}
          size="tiny"
          className="item-adder"
        />
      )}
      <Confirm
        className="confirm"
        open={confirmOpen}
        onConfirm={handleConfirmClose}
        onCancel={handleCancelClose}
        header={isClone ? "Clone Email Signature" : "Edit Email Signature"}
        content="Are you sure? Closing this window will undo unsaved changes"
      />
      <Modal
        open={modalOpen}
        onClose={handleClose}
        size="large"
        closeIcon
        closeOnDimmerClick={false}
      >
        <Header
          content={
            isClone
              ? "Clone Email Signature"
              : emailSignatureId
              ? "Edit Email Signature"
              : "New Email Signature"
          }
        />
        <Modal.Content>
          {
            <EmailSignatureForm
              onSuccess={handleSuccess}
              isClone={isClone}
              emailSignatureId={emailSignatureId}
            />
          }
        </Modal.Content>
      </Modal>
    </>
  );
}

export default EmailSignatureModal;

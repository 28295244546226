import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Dropdown, Icon, Portal, Button } from "semantic-ui-react";
import "./CustomDropdown.scoped.scss";

class ModifiedDropdown extends Dropdown {
  openOnSpace = e => {
    return;
  };

  handleClick = e => {
    this.props.onClick(e);
  };

  closeOnDocumentClick = e => {
    this.close(e);
  };
}

export default class CustomDropdown extends Component {
  constructor(props) {
    super(props);
    this.trigger = React.createRef();
    this.dropdown = React.createRef();
    this.state = {
      xPos: 0,
      yPos: 0,
      portalOpen: false,
      dropdownOpen: false,
      hidden: true,
      lastClosed: null,
    };
  }

  handleOpen = _ => {
    this.props.handleOpen();
    const button = this.getElementFromRef(this.trigger);
    const bounds = button.getBoundingClientRect();
    const [xOffset, yOffset] = this.props.button ? [0, 30] : [6, 5];
    const xPos = bounds.left + window.scrollX + xOffset;
    const yPos = bounds.top + window.scrollY + yOffset;
    this.setState({
      xPos,
      yPos,
      portalOpen: true,
      dropdownOpen: true,
      hidden: true,
    });
    setTimeout(() => this.handleReposition(button), 10);
  };

  handleReposition = button => {
    let { xPos, yPos, hidden } = this.state;
    const dropdown = this.getElementFromRef(this.dropdown);
    if (!dropdown || !hidden) {
      return;
    }
    const bounds = dropdown.getBoundingClientRect();
    const menu = dropdown.querySelector(".menu");
    const isLeftDropdown = menu.classList.contains("left");
    const height = menu.offsetHeight + 30;
    if (isLeftDropdown) {
      xPos += button.offsetWidth;
    }
    if (
      bounds.top + height >
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      yPos -= height;
    }
    this.setState({
      xPos,
      yPos,
      hidden: false,
    });
  };

  _handleClose = e => {
    const element = this.getElementFromRef(this.dropdown);
    let shouldClosePortal = false;
    if (e && e.target && element) {
      const containsTarget = element.contains(e.target);
      shouldClosePortal = !containsTarget;
    } else if (e && e.path) {
      shouldClosePortal = !e.path.find(
        c => c.classList && c.classList.contains("modal")
      );
    }
    this.setState({
      portalOpen: !shouldClosePortal,
      dropdownOpen: false,
      hidden: false,
      lastClosed: new Date(),
    });
    this.props.handleClose();
  };

  handleClose = e => {
    setTimeout(() => {
      this._handleClose(e);
    }, 100);
  };

  handleDropdownClick = e => {
    const target = e ? e.target : null;
    if (!target) {
      return;
    }
    const element = this.getElementFromRef(this.dropdown);
    const containsTarget = element.contains(target);
    if (containsTarget) {
      this.setState({
        hidden: true,
      });
    }
  };

  timeSinceLastClosed = () => {
    const { lastClosed } = this.state;
    if (!lastClosed) {
      return Infinity;
    }
    return new Date().getTime() - lastClosed.getTime();
  };

  getElementFromRef = ref => {
    const component = ref ? ref.current : null;
    const element = component ? ReactDOM.findDOMNode(component) : null;
    return element;
  };

  handleTriggerClick = event => {
    if (this.state.dropdownOpen || this.timeSinceLastClosed() <= 10) {
      this.handleClose(event);
    } else {
      this.handleOpen(event);
    }
  };

  render = () => {
    const {
      button,
      icon,
      className,
      children,
      disabled,
      triggerProps,
      ...rest
    } = this.props;
    return (
      <div className="custom-dropdown">
        {button ? (
          <Button
            ref={this.trigger}
            className={className}
            icon={icon}
            disabled={disabled}
            onClick={this.handleTriggerClick}
            {...triggerProps}
          />
        ) : (
          <Icon
            link
            ref={this.trigger}
            name={icon}
            disabled={disabled}
            onClick={this.handleTriggerClick}
            {...triggerProps}
          />
        )}
        <Portal open={this.state.portalOpen}>
          <ModifiedDropdown
            open={this.state.dropdownOpen}
            ref={this.dropdown}
            icon=""
            className="portal modified-dropdown"
            style={{
              ...(this.props.isWithinModal && { zIndex: 1000 }),
              top: this.state.yPos,
              left: this.state.xPos,
              opacity: this.state.hidden ? 0 : 1,
            }}
            onClose={this.handleClose}
            onClick={this.handleDropdownClick}
            disabled={disabled}
            {...rest}
          >
            {children}
          </ModifiedDropdown>
        </Portal>
      </div>
    );
  };
}

CustomDropdown.defaultProps = {
  handleClose: () => {},
  handleOpen: () => {},
};

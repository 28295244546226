import { useState, useEffect } from "react";

const useEphemeral = (fadeOutTime = 5) => {
  const [isVisible, _setIsVisible] = useState(0);

  const setIsVisible = (shouldAppear = true) => {
    _setIsVisible(isVisible => (shouldAppear ? isVisible + 1 : 0));
  };

  useEffect(() => {
    let timer;

    if (isVisible) {
      timer = setTimeout(() => {
        _setIsVisible(0);
      }, fadeOutTime * 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isVisible, fadeOutTime]);

  return [!!isVisible, setIsVisible];
};

export default useEphemeral;

import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Modal, Header, Button, Icon } from "semantic-ui-react";

const DELETE_QUESTION = 0;
const DELETE_PAGE = 1;

const DeleteQuestionConfirm = forwardRef(({ onConfirm }, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [index, setIndex] = useState();
  const [type, setType] = useState(DELETE_QUESTION);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleOpen = (index, type) => {
    setIndex(index);
    setType(type);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleConfirm = () => {
    onConfirm(index, type);
    handleClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      size="tiny"
      onFocus={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      closeIcon
    >
      <Header
        icon="warning sign"
        content={`Delete ${type === DELETE_QUESTION ? "Question" : "Page"}`}
      />
      <Modal.Content>
        {type === DELETE_QUESTION
          ? "Are you sure you wish to delete this question?"
          : "Are you sure you wish to delete this page and all questions within it?"}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button onClick={handleConfirm} color="red">
          <Icon name="checkmark" /> Delete
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export { DELETE_QUESTION, DELETE_PAGE };
export default DeleteQuestionConfirm;

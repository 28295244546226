import React, { useRef, useState } from "react";
import { Button, Icon } from "semantic-ui-react";

const FilePicker = ({
  onSelect,
  onCancel,
  multiple = false,
  accept,
  icon = true,
  content,
  primary = false,
  ...buttonProps
}) => {
  const [draggingFile, setDraggingFile] = useState(false);
  const fileInputRef = useRef();

  const handleChange = event => {
    const files = Array.from(event.target.files);
    if (files.length === 0) {
      onCancel && onCancel(event);
      return;
    }
    handleUpload(files, event);
  };

  const handleUpload = async (files, event, dropped = false) => {
    if (!multiple) {
      files = files.slice(0, 1);
    }
    onSelect(files, event, dropped);
  };

  const handleDragEnter = event => {
    const items = [...event.dataTransfer.items];
    const draggingFile =
      items.length > 0 && items.every(item => item.kind === "file");
    setDraggingFile(draggingFile);
  };

  const handleDragLeave = () => {
    setDraggingFile(false);
  };

  const handleDrop = event => {
    handleDragLeave();
    event.preventDefault();
    const items = [...event.dataTransfer.items];
    if (items.length > 0 && items.every(item => item.kind === "file")) {
      const files = items.map(item => item.getAsFile());
      handleUpload(files, event, true);
    }
  };

  const getIcon = () => {
    if (icon === true) {
      return draggingFile ? "upload" : "file";
    }
    return icon;
  };

  return (
    <Button
      labelPosition={icon ? "left" : undefined}
      icon={!!icon}
      className={primary ? "item-adder validation-file" : ""}
      size={primary ? "small" : ""}
      onClick={() => fileInputRef.current.click()}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={event => event.preventDefault()}
      onDrop={handleDrop}
      {...buttonProps}
    >
      {icon && <Icon name={getIcon()} />}
      {draggingFile
        ? "Drop to Upload"
        : content || `Choose File${multiple ? "s" : ""}`}
      <input
        ref={fileInputRef}
        type="file"
        hidden
        multiple={multiple}
        onChange={handleChange}
        accept={accept}
      />
    </Button>
  );
};

export default FilePicker;

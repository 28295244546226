import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";

const selectTypes = [
  {
    id: "single",
    value: "single",
    text: "Single",
  },
  {
    id: "multi",
    value: "multi",
    text: "Multiple",
  },
];

const CategoricalTypeOptionsForm = ({
  fieldOptions,
  setFieldOptions,
  onSuccess,
}) => {
  const handleSubmit = () => {
    if (typeof fieldOptions["options"] === "string") {
      fieldOptions["options"] = fieldOptions["options"]
        .split(",")
        .map(e => e.trim())
        .filter(e => !!e);
    }
    const data = {
      name: "field_options",
      value: fieldOptions,
    };
    onSuccess(data);
  };

  const handleChange = (name, value) => {
    const newFieldOptions = {
      ...fieldOptions,
      [name]: value,
    };
    setFieldOptions(newFieldOptions);
  };

  return (
    <RuvixxForm
      ready={!!fieldOptions["type"] && !!fieldOptions["options"]}
      onSubmit={handleSubmit}
    >
      <Form.Select
        required
        inline
        name="type"
        label="Select Type"
        options={selectTypes}
        value={fieldOptions["type"]}
        onChange={(_, { name, value }) => handleChange(name, value)}
      />
      <Form.TextArea
        required
        inline
        name="options"
        label="Field Options"
        value={fieldOptions["options"]}
        placeholder="add your options as a comma separated value ex:(1,apple,blue)"
        onChange={(_, { name, value }) => handleChange(name, value)}
      />
    </RuvixxForm>
  );
};

export default CategoricalTypeOptionsForm;

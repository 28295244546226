const isContactEmailable = contact => {
  return contact.email_addresses?.some(
    ({ enabled, subscribed }) => enabled && subscribed
  );
};

const isContactCallable = contact => {
  return contact.phone_numbers?.some(
    ({ enabled, is_callable }) => enabled && is_callable
  );
};

export { isContactEmailable, isContactCallable };

import React from "react";
import saveAs from "file-saver";
import {
  Document,
  Font,
  Image,
  Link,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import ActivityService from "../../services/Activity";
import { EMAIL_URL_LANGS } from "../../constants/Constants";
import { createDateTimeFormatter } from "../helpers";

const styles = StyleSheet.create({
  body: {
    fontSize: 12,
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  header: {
    fontSize: 16,
    fontWeight: 700,
  },
  row: {
    marginTop: 10,
  },
  question: {
    fontWeight: 700,
  },
  answer: {
    paddingLeft: 20,
  },
  listItem: {
    display: "list-item",
  },
  tableAnswer: {
    marginTop: 5,
    paddingTop: 5,
    borderTopWidth: 0.75,
    borderTopColor: "rgb(25, 25, 25, 0.1)",
    borderTopStyle: "solid",
  },
  submittedInfo: {
    marginTop: 10,
  },
});

const BULLET = "\u2022";

const UnorderedListItem = ({ children }) => {
  return (
    <View style={styles.listItem}>
      <Text>
        {BULLET}&nbsp;<Text>{children}</Text>
      </Text>
    </View>
  );
};

const UnorderedList = ({ children }) => {
  return <View style={styles.list}>{children}</View>;
};

const formatDate = createDateTimeFormatter();

const FormResponsePDF = props => {
  const { definition, answerMap, hidden, language, contact, submittedAt } =
    props;
  const agentName = hidden.agent_name;
  let fontFamily, regularSrc, boldSrc;
  if (language === EMAIL_URL_LANGS.THAI) {
    fontFamily = "Sarabun";
    regularSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/Sarabun/Sarabun-Regular.ttf";
    boldSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/Sarabun/Sarabun-Bold.ttf";
  } else if (language === EMAIL_URL_LANGS.JAPANESE) {
    fontFamily = "Noto Sans JP";
    regularSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/MPLUSRounded1c/MPLUSRounded1c-Regular.ttf";
    boldSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/MPLUSRounded1c/MPLUSRounded1c-Bold.ttf";
  } else {
    fontFamily = "Open Sans";
    regularSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/OpenSans/OpenSans-Regular.ttf";
    boldSrc =
      "https://pleteo-shared-fonts.s3-us-west-2.amazonaws.com/fonts/OpenSans/OpenSans-Bold.ttf";
  }
  Font.register({
    family: fontFamily,
    format: "truetype",
    fonts: [
      {
        src: regularSrc,
      },
      {
        src: boldSrc,
        fontWeight: 700,
      },
    ],
  });

  styles.body.fontFamily = fontFamily;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header}>{definition.title}</Text>
        {contact ? (
          <View>
            <Text>
              {contact.full_name} ({contact.email})
            </Text>
          </View>
        ) : null}
        {answerMap.map((e, index) => (
          <View key={index} style={styles.row}>
            <View style={styles.question}>
              <Text>{e.question}</Text>
            </View>
            <View style={styles.answer}>{e.answer}</View>
          </View>
        ))}
        <View style={styles.submittedInfo}>
          <Text>Submitted at: {formatDate(submittedAt)}</Text>
          {agentName && <Text>Submitted by: {agentName}</Text>}
        </View>
      </Page>
    </Document>
  );
};

const getAnswerForTypeform = async answer => {
  switch (answer.type) {
    case "text":
    case "TextInput":
    case "TextBox":
      return <Text>{answer.text}</Text>;
    case "choice":
    case "RadioButtonGroup":
      return <Text>{answer.choice.label}</Text>;
    case "choices":
      return <Text>{answer.choices.labels}</Text>;
    case "email":
      return <Text>{answer.email}</Text>;
    case "url":
      return (
        <Text>
          <Link src={answer.url} style={styles.link}>
            {answer.url}
          </Link>
        </Text>
      );
    case "file_url":
      return (
        <Text>
          <Link src={answer.file_url} style={styles.link}>
            {answer.file_url}
          </Link>
        </Text>
      );
    case "Upload":
      return (
        <View>
          {answer.uploadType === "Image" ? (
            <Image src={answer.url} wrapped ui={false} />
          ) : null}
          <Link src={answer.url} style={styles.link}>
            {answer.uploadFileName}
          </Link>
        </View>
      );
    case "boolean":
      return <Text>{answer.boolean ? "True" : "False"}</Text>;
    case "number":
      return <Text>{answer.number}</Text>;
    case "date":
      return <Text>{answer.date}</Text>;
    case "DatePicker":
      return <Text>{answer.choice.label}</Text>;
    case "payment":
      return <Text>{answer.payment}</Text>;
    case "TableV1": {
      let headers = answer.data.columns.filter(
        data => data.dataPath !== "delete"
      );
      let rows = answer.data.rows;
      let data = {
        headers: headers.map(h => h.label),
        answers: rows.map(row =>
          headers.map(h => {
            return row[h.dataPath] || "";
          })
        ),
      };

      return (
        <View>
          {data.answers.map((answerRow, index) => (
            <View key={index} style={index > 0 ? styles.tableAnswer : null}>
              {answerRow.map((answer, idx) => (
                <Text key={idx}>
                  {data.headers[idx]}: {answer}
                </Text>
              ))}
            </View>
          ))}
        </View>
      );
    }
    case "PleteoUpload":
      if (answer.activityLogId) {
        const activityLog = await ActivityService.getActivityLog(
          "Contact",
          answer.activityLogId
        );
        const attachments = activityLog.attachments || [];
        return (
          <UnorderedList>
            {attachments.map((attachment, idx) => (
              <UnorderedListItem>
                <Link src={attachment.url}>{attachment.file_name}</Link>
              </UnorderedListItem>
            ))}
          </UnorderedList>
        );
      } else {
        return null;
      }
    case "CheckboxButtonGroup":
      return answer.checked !== null ? (
        <UnorderedList>
          {answer.checked.map(val => (
            <UnorderedListItem>{val}</UnorderedListItem>
          ))}
        </UnorderedList>
      ) : (
        <Text>---</Text>
      );
    case "MultiRadioButtonGroup":
      return (
        <UnorderedList>
          {answer.choices.map(radio => (
            <UnorderedListItem>{`${radio.title}: ${radio.value}`}</UnorderedListItem>
          ))}
        </UnorderedList>
      );
    case "CheckboxMultiInput":
      return (
        <UnorderedList>
          {answer.multiChecked.checkedValues.map(checkedValue => (
            <UnorderedListItem>
              {checkedValue.title +
                (checkedValue.value ? " : " : " ") +
                (checkedValue.value || " ")}
            </UnorderedListItem>
          ))}
        </UnorderedList>
      );
    case "Dropdown":
      return <Text>{answer.value}</Text>;
    default:
      return <Text>{answer.type} not yet supported in PDF generation</Text>;
  }
};

async function generateAnswer(question, answers) {
  const answer = answers.filter(a => {
    return a.field.id === question.id;
  });
  return {
    type: answer[0].type,
    question: question.title,
    answer: await getAnswerForTypeform(answer[0], question),
    id: question.id,
  };
}

const generateAnswerMapForPdf = async (definition, answers) => {
  const promises = [];
  definition.fields
    .filter(q => {
      const answer = answers.filter(a => {
        return a.field.id === q.id;
      });
      return answer.length > 0;
    })
    .forEach(question => {
      promises.push(generateAnswer(question, answers));
    });

  return await Promise.all(promises);
};

export const generatePdfDocument = async (formResponse, language, contact) => {
  // TODO: generate qAndA here
  const answerMap = await generateAnswerMapForPdf(
    formResponse.definition,
    formResponse.answers
  );
  const blob = await pdf(
    <FormResponsePDF
      definition={formResponse.definition}
      answerMap={answerMap}
      hidden={formResponse.hidden}
      language={language}
      contact={contact}
      submittedAt={formResponse.submitted_at}
    />
  ).toBlob();
  const filename = [
    formResponse.definition.title,
    contact && contact.email,
    formResponse.submitted_at,
  ]
    .filter(el => !!el)
    .join("-")
    .replace(/\s/g, "_");
  saveAs(blob, `${filename}.pdf`);
};

import { useState } from "react";
import store from "store";
import UserService from "../services/User";

const useAdminStatus = () => {
  const [isAdmin, setIsAdmin] = useState();
  const [prevUser, setPrevUser] = useState();
  const checkUserAdmin = async () => {
    const isAdmin = await UserService.isAdmin();
    setIsAdmin(isAdmin);
  };
  const userAuth = store.get("userAuth");
  if (!userAuth) {
    return false;
  }
  if (!prevUser || userAuth.email !== prevUser) {
    checkUserAdmin();
    setPrevUser(userAuth.email);
  }
  return isAdmin;
};

export { useAdminStatus };

import React from "react";
import { Navigate } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import LoginForm from "./Form";
import AuthService from "../../services/Auth";

const Login = props => {
  return AuthService.isLoggedIn() ? (
    <Navigate to="/" replace />
  ) : (
    <Grid centered verticalAlign="middle">
      <Grid.Column width={4}>
        <LoginForm {...props} />
      </Grid.Column>
    </Grid>
  );
};

export default Login;

import { useRef, useEffect } from "react";

function useOuterClick(ref, callback) {
  const callbackRef = useRef(); // initialize mutable ref, which stores callback

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e) {
      if (ref.current && callbackRef.current && !ref.current.contains(e.target))
        callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener
}

export default useOuterClick;

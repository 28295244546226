import PropTypes from "prop-types";
import React, { Component } from "react";
import Datetime from "react-datetime";
import { Form, Input } from "semantic-ui-react";
import CustomFieldForm from "../../../components/forms/CustomFieldForm";
import {
  checkIsCustomFieldsReady,
  compareCreatedAt,
  extractCustom,
  getCustomFieldsForSubmit,
  keyIsUnique,
} from "../../../components/helpers";
import RuvixxForm from "../../../components/RuvixxForm";
import CONSTANTS from "../../../constants/Constants";
import { startCase } from "../../../helpers/string";
import CampaignService from "../../../services/Campaign";
import CampaignStatusPresetService from "../../../services/CampaignStatusPreset";
import CustomFieldService from "../../../services/CustomField";
import EmailProviderService from "../../../services/EmailProviders";
import EmailUrlService from "../../../services/EmailUrls";
import RegionService from "../../../services/Regions";
import UserService from "../../../services/User";
import "../../../styles/datetime.scss";
import EmailTemplateService from "../../../services/EmailTemplate";
import campaignConstants from "constants/Campaign";
import RevenueOpportunityStatusPresetService from "../../../services/RevenueOpportunityStatusPreset";

class EditCampaignForm extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    campaignId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      status: "",
      prevName: "",
      assigned: "",
      start: "",
      end: "",
      description: "",
      emailService: null, // TODO: Update to Sendgrid in future. Default to mailgun for now.
      emailType: null,
      emailServices: [],
      emailTypes: [],
      selectedTemplates: [],
      templates: [],
      form: "",
      forms: [],
      region: "",
      regions: [],
      custom: [],
      customFieldConfigs: [],
      searchQuery: null,
      users: [],
      campaignStatusPresets: [],
      campaignStatusPreset: null,
      caseCampaignStatusPreset: null,
      revenueStatusPresets: [],
      revenueStatusPreset: null,
      limitEmailTemplateToggle: false,
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSelect = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSearchChange = async (e, { searchQuery }) => {
    this.setState({ searchQuery });
    await this.fetchUsers(searchQuery);
  };

  handleSubmit = async e => {
    const {
      name,
      status,
      assigned,
      start,
      end,
      description,
      emailService,
      emailType,
      form,
      region,
      custom,
      campaignStatusPreset,
      caseCampaignStatusPreset,
      revenueStatusPreset,
      customFieldConfigs,
      selectedTemplates,
    } = this.state;
    const cust = getCustomFieldsForSubmit(customFieldConfigs, custom);
    try {
      await CampaignService.editCampaign({
        campaignId: this.props.campaignId,
        name: name,
        status,
        assigned: assigned,
        start: start,
        end: end || null,
        form: form || undefined,
        emailService: emailService,
        emailType: emailType,
        region: region || undefined,
        description: description,
        emailTemplates: selectedTemplates || [],
        custom: cust,
        campaignStatusPreset: campaignStatusPreset || null,
        caseCampaignStatusPreset: caseCampaignStatusPreset || null,
        revenueStatusPreset: revenueStatusPreset || null,
      });
    } catch ({
      response: {
        data: { message },
      },
    }) {
      throw new Error(message);
    }
  };

  isUnique = key => {
    return keyIsUnique({
      key: key,
      array: this.state.custom,
    });
  };

  handleStart = moment => {
    this.setState({ start: moment });
  };

  handleEnd = moment => {
    this.setState({ end: moment });
  };

  fetchUsers = async query => {
    let users;
    if (query) {
      users = await UserService.search(query);
    } else {
      users = await UserService.getUsers();
    }
    this.setState({ users });
  };

  fetchCampaign = async () => {
    const campaign = await CampaignService.getCampaign(this.props.campaignId);
    const custom = extractCustom(campaign.custom_fields);
    this.setState({
      name: campaign.name,
      status: campaign.status,
      prevName: campaign.name,
      assigned: campaign.assigned_user_id,
      start: campaign.start_time,
      end: campaign.end_time || "",
      form: campaign.form_id,
      emailService: campaign.email_service,
      emailType: campaign.email_type,
      region: campaign.region_id,
      description: campaign.info.description,
      selectedTemplates: campaign.info.selected_templates || [],
      limitEmailTemplateToggle:
        (campaign.info.selected_templates &&
          campaign.info.selected_templates.length > 0) ||
        false,
      custom: custom,
      campaignStatusPreset: campaign.info.campaign_status_preset,
      caseCampaignStatusPreset: campaign.info.case_campaign_status_preset,
      revenueStatusPreset: campaign.info.revenue_status_preset,
    });
  };

  fetchRegions = async () => {
    const rawRegions = await RegionService.getRegions();
    const regions = rawRegions.map(region => ({
      text: region.name,
      key: region.id,
      value: region.id,
    }));
    this.setState({ regions });
  };

  fetchTemplates = async () => {
    let templates = await EmailTemplateService.getEmailTemplatesForFilters();
    templates = templates.map(template => ({
      id: template.id,
      key: template.id,
      value: template.id,
      text: template.name,
      info: template.info,
      enabled: template.enabled,
    }));

    this.setState({ templates }, this.setTemplateOptions);
  };

  fetchForms = async () => {
    let forms = await EmailUrlService.getForms();
    forms.sort(compareCreatedAt);
    forms = forms.map(forms => ({
      text: forms.name,
      key: forms.id,
      value: forms.id,
    }));
    this.setState({ forms });
  };

  fetchEmailServices = async () => {
    const emailServices = await EmailProviderService.getEmailProviders(
      true,
      true
    );
    this.setState({ emailServices });
  };

  fetchEmailTypes = async () => {
    const emailTypes = Object.keys(CONSTANTS.EMAIL_TYPES).map(key => ({
      key: CONSTANTS.EMAIL_TYPES[key],
      value: CONSTANTS.EMAIL_TYPES[key],
      text: startCase(key.toLowerCase()),
    }));
    this.setState({ emailTypes });
  };

  fetchCampaignStatusPresets = async () => {
    const rawPresets =
      await CampaignStatusPresetService.getCampaignStatusPresets();
    const campaignStatusPresets = rawPresets.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
    this.setState({ campaignStatusPresets });
  };

  fetchRevenueStatusPresets = async () => {
    const response =
      await RevenueOpportunityStatusPresetService.getCampaignStatusPresets();
    const revenueStatusPresets = response.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
    this.setState({ revenueStatusPresets });
  };

  fetchCustomFieldConfigs = async () => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel("Campaign");
    this.setState({ customFieldConfigs });
  };

  handleUpdateCustomFieldConfigs = customFieldConfigs => {
    this.setState({ customFieldConfigs });
    this.handleUpdateCustomFields(
      customFieldConfigs
        .filter(cfc => cfc.required || cfc.selected)
        .map(cfc => ({ key: cfc.label, value: cfc.value }))
    );
  };

  handleUpdateCustomFields = customFields => {
    this.setState({ custom: customFields });
  };

  handleTemplatesSelect = (e, { value }) => {
    this.setState({ selectedTemplates: value });
  };

  componentDidMount() {
    this.fetchEmailServices(); // don't await
    this.fetchEmailTypes(); // don't await
    this.fetchTemplates(); // don't await
    this.fetchForms(); // don't await
    this.fetchRegions(); // don't await
    this.fetchCampaign(); // don't await
    this.fetchUsers(); // don't await
    this.fetchCampaignStatusPresets(); // don't await
    this.fetchRevenueStatusPresets();
    this.fetchCustomFieldConfigs();
  }

  render() {
    const {
      name,
      status,
      assigned,
      start,
      end,
      description,
      emailService,
      emailServices,
      emailType,
      emailTypes,
      form,
      forms,
      regions,
      region,
      custom,
      customFieldConfigs,
      users,
      campaignStatusPresets,
      campaignStatusPreset,
      caseCampaignStatusPreset,
      revenueStatusPreset,
      revenueStatusPresets,
      templates,
      selectedTemplates,
      limitEmailTemplateToggle,
    } = this.state;
    const userOptions = users.map(user => {
      return { key: user.id, text: user.full_name, value: user.id };
    });
    const statusOptions = campaignConstants.status.map(status => {
      return { key: status.id, text: status.name, value: status.id };
    });

    return (
      <RuvixxForm
        ready={
          !!name &&
          !!assigned &&
          !!start &&
          !!emailService &&
          !!emailType &&
          checkIsCustomFieldsReady(customFieldConfigs)
        }
        onSubmit={this.handleSubmit}
        onSuccess={this.props.onSuccess}
      >
        <Form.Input
          inline
          name="name"
          label="Name"
          required
          value={name}
          onChange={this.handleChange}
        />
        <Form.Select
          inline
          required
          label="Status"
          name="status"
          onChange={this.handleSelect}
          search
          value={status}
          options={statusOptions}
        />
        <Form.Select
          inline
          required
          label="Assignee"
          name="assigned"
          onChange={this.handleSelect}
          onSearchChange={this.handleSearchChange}
          search
          options={userOptions}
          value={assigned}
        />
        <Form.Group>
          <Form.Field required>
            <label>Start Time</label>
            <Datetime
              closeOnSelect={true}
              value={start && new Date(start)}
              onChange={this.handleStart}
              renderInput={props => (
                <Input icon="large calendar outline" {...props} />
              )}
              dateFormat={"YYYY-MM-DD"}
            />
          </Form.Field>
          <Form.Field>
            <label>End Time</label>
            <Datetime
              closeOnSelect={true}
              value={end && new Date(end)}
              onChange={this.handleEnd}
              renderInput={props => (
                <Input icon="large calendar outline" {...props} />
              )}
              dateFormat={"YYYY-MM-DD"}
            />
          </Form.Field>
        </Form.Group>
        <Form.Select
          inline
          label="Form"
          clearable
          name="form"
          value={form}
          search
          options={forms}
          onChange={this.handleSelect}
        />
        <Form.Checkbox
          className="limit-email-templates-toggle"
          toggle
          checked={limitEmailTemplateToggle}
          label="Limit Email Templates"
          onChange={(_, { checked }) =>
            this.setState({
              limitEmailTemplateToggle: checked,
              selectedTemplates: checked ? this.state.selectedTemplates : [],
            })
          }
          required
        />
        {limitEmailTemplateToggle && (
          <Form.Dropdown
            fluid
            multiple
            search
            selection
            label="Templates"
            name="selectedTemplates"
            options={templates}
            onChange={this.handleTemplatesSelect}
            value={selectedTemplates}
          />
        )}
        <Form.Select
          inline
          required
          label="Email Service"
          value={emailService}
          name="emailService"
          options={emailServices}
          onChange={this.handleSelect}
        />
        <Form.Select
          inline
          required
          label="Email Type"
          value={emailType}
          name="emailType"
          options={emailTypes}
          onChange={this.handleSelect}
        />
        <Form.Select
          inline
          label="Region"
          clearable
          name="region"
          value={region}
          search
          options={regions}
          onChange={this.handleSelect}
        />
        <Form.Select
          inline
          label="Case Campaign Status Preset"
          clearable
          name="caseCampaignStatusPreset"
          search
          options={campaignStatusPresets}
          value={caseCampaignStatusPreset}
          onChange={this.handleSelect}
        />
        <Form.Select
          inline
          label="Campaign Status Preset"
          clearable
          name="campaignStatusPreset"
          search
          options={campaignStatusPresets}
          value={campaignStatusPreset}
          onChange={this.handleSelect}
        />
        <Form.Select
          clearable
          inline
          search
          name="revenueStatusPreset"
          label="Revenue Opportunity Status Preset"
          options={revenueStatusPresets}
          value={revenueStatusPreset}
          onChange={this.handleSelect}
        />
        <Form.Input
          fluid
          name="description"
          label="Description"
          onChange={this.handleChange}
          value={description}
        />
        {customFieldConfigs && (
          <CustomFieldForm
            modelType="Campaign"
            customFields={custom}
            customFieldConfigs={customFieldConfigs}
            onUpdateCustomFieldConfigs={this.handleUpdateCustomFieldConfigs}
          />
        )}
      </RuvixxForm>
    );
  }
}

export default EditCampaignForm;

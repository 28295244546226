import React, { useEffect, useState } from "react";
import { Form, Segment, Button, Table, Message } from "semantic-ui-react";
import "../../../styles/datetime.scss";

import CampaignScheduleService from "../../../services/CampaignScheduleService";

function CustomAttachmentForm({
  attachmentIdx,
  attachment,
  removeAttachment,
  customAttachmentList,
  scheduleConditions,
  emailTemplateId,
  campaignId,
  scheduleId,
  validateDisabled,
  checkIfCanValidate,
  sendToAllEmailAddresses,
}) {
  const [customAttachment, setCustomAttachment] = useState({
    custom_attachment_uri: attachment.custom_attachment_uri || "",
    custom_attachment_output: attachment.custom_attachment_output || "",
  });
  const [isValidating, setIsValidating] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validateSuccess, setValidateSuccess] = useState(false);

  const handleCustomAttachment = (event, data) => {
    setCustomAttachment(prevState => ({
      ...prevState,
      [data.name]: data.value,
    }));
  };

  const cleanTemplateConditions = scheduleConditions => {
    let cleanedCond = scheduleConditions.filter(t => {
      return t != null;
    });
    let conditions = cleanedCond.map(cond => ({
      filter_context: cond.filter_context || undefined,
      filter_operand1: cond.filter_operand1 || undefined,
      filter_operator: cond.filter_operator || undefined,
      filter_operand2: cond.filter_operand2 || undefined,
    }));
    return conditions;
  };

  const handleValidate = async e => {
    if (customAttachment) {
      let conditions = cleanTemplateConditions(scheduleConditions);
      let data = {
        uri: customAttachment.custom_attachment_uri,
        email_template_id: emailTemplateId,
        conditions: conditions,
        custom_output: customAttachment.custom_attachment_output,
        info: { send_to_all_email_addresses: sendToAllEmailAddresses },
      };
      setIsValidating(true);
      setValidationErrors([]);
      setError(false);
      setErrorMessage(undefined);
      try {
        const result = await CampaignScheduleService.validateUrl(
          campaignId,
          scheduleId,
          data
        );
        if (result.errors.length) {
          setValidationErrors(result.errors);
          setIsValidating(false);
        } else {
          setIsValidating(false);
          if (result.valid_objects) {
            setValidateSuccess(true);
          }
        }
      } catch (error) {
        setError(true);
        setErrorMessage(error.message);
        setIsValidating(false);
      }
    }
  };

  useEffect(() => {
    customAttachmentList[attachmentIdx] = customAttachment;
    checkIfCanValidate();
  }, [customAttachment]);

  return (
    <Segment>
      <i
        className="delete link icon red"
        style={{ float: "right" }}
        onClick={removeAttachment}
      />
      <Form.Input
        label="Custom Attachment URI Template"
        name="custom_attachment_uri"
        placeholder="s3://bucket-name/prefixes/{{ any reasonable allowable tags (ex:contact.name) }}.{file_extension}"
        onChange={handleCustomAttachment}
        value={customAttachment["custom_attachment_uri"] || ""}
        style={{ marginTop: "10px" }}
      />
      <Form.Input
        label="Custom Attachment Output"
        name="custom_attachment_output"
        onChange={handleCustomAttachment}
        value={customAttachment["custom_attachment_output"] || ""}
        placeholder="default format is {{contact.name}}.{file_extension}"
        style={{ marginTop: "10px" }}
      />
      {validateDisabled ? null : (
        <Button
          basic
          color="grey"
          size="mini"
          type="button"
          content="Validate Attachment"
          loading={isValidating}
          onClick={handleValidate}
          disabled={validateDisabled}
        />
      )}
      {validationErrors.length ? (
        <Table celled fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Contact Email</Table.HeaderCell>
              <Table.HeaderCell>Error</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {validationErrors.map(error => {
              return (
                <Table.Row>
                  <Table.Cell>{error.email}</Table.Cell>
                  <Table.Cell error>{error.info}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : validateSuccess ? (
        <Message positive>
          <Message.Header>Success!</Message.Header>
          <p>All custom attachments were validated successfully</p>
        </Message>
      ) : null}
    </Segment>
  );
}

export default CustomAttachmentForm;

import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import PropTypes from "prop-types";

import RuvixxForm from "components/RuvixxForm";
import TwilioPhoneNumberService from "services/TwilioPhoneNumber";
import UserService from "services/User";

const AssignAgentsForm = ({
  twilioPhoneNumberIds,
  userIds: defaultUserIds,
  onSuccess,
}) => {
  const [userIds, setUserIds] = useState(defaultUserIds || []);
  const [userOptions, setUserOptions] = useState([]);

  const fetchUsers = useCallback(async () => {
    const users = (await UserService.getUsers()).map(
      ({ id, full_name: name }) => ({
        key: id,
        value: id,
        text: name,
      })
    );

    setUserOptions(users);
  }, []);

  const handleSubmit = async () => {
    await TwilioPhoneNumberService.assignAgents(twilioPhoneNumberIds, userIds);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <RuvixxForm onSubmit={handleSubmit} onSuccess={onSuccess}>
      <Form.Select
        search
        multiple
        clearable
        onChange={(_, { value }) => setUserIds(value || [])}
        label="Agents"
        name="agents"
        options={userOptions}
        placeholder="Select agents"
        value={userIds}
      />
    </RuvixxForm>
  );
};

AssignAgentsForm.propTypes = {
  twilioPhoneNumberIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  userIds: PropTypes.arrayOf(PropTypes.number),
  onSuccess: PropTypes.func.isRequired,
};

export default AssignAgentsForm;

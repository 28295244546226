import React from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

import "../../../styles/table.scss";
import "./../../../styles/tab_view.scss";

import ContactService from "../../../services/Contact";
import _AddContactModal from "./AddContactModal";
import ContactsTable from "../../contact/ContactsTable";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import { checkIsAuthorized } from "../../../components/helpers";
import withRoleCheck from "../../../components/hocs/withRoleCheck";

const AddContactModal = withRoleCheck(_AddContactModal, [
  ACL_RELATIONSHIPS.entityContact.create,
]);

const canCall = checkIsAuthorized([
  ACL_RELATIONSHIPS.dialSession.read,
  ACL_RELATIONSHIPS.dialSessionPrivate.create,
  ACL_RELATIONSHIPS.callQueueNextContact.read,
  ACL_RELATIONSHIPS.callQueueAddContact.create,
  ACL_RELATIONSHIPS.disposition.read,
  ACL_RELATIONSHIPS.emailUrlForms.read,
  ACL_RELATIONSHIPS.voicemailForFilters.read,
  ACL_RELATIONSHIPS.twilioToken.read,
  ACL_RELATIONSHIPS.tag.read,
  ACL_RELATIONSHIPS.user.read,
  ACL_RELATIONSHIPS.campaignStatuses.read,
  ACL_RELATIONSHIPS.twilioDisposition.create,
  ACL_RELATIONSHIPS.twilioAddToCall.create,
  ACL_RELATIONSHIPS.twilioRemoveFromCall.create,
  ACL_RELATIONSHIPS.twilioUpdateParticipant.create,
]);

class EntityContactsTable extends ContactsTable {
  static propTypes = {
    entityId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.queryMethod = ContactService.getContacts;
    this.enableCalling = canCall;

    this.state = {
      ...this.state,
      defaultFilterParams: { entity_id: this.props.entityId },
      createButton: (
        <AddContactModal
          onSuccess={this.fetchData}
          entityId={this.props.entityId}
          model="Contact"
        />
      ),
    };
  }

  render() {
    return (
      !!this.props.entityId && (
        <Segment
          style={{
            boxShadow: "0px 0px 5px 1px #ddd",
          }}
        >
          {this.renderTable()}
        </Segment>
      )
    );
  }
}

export default EntityContactsTable;

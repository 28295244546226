import React, { useEffect, useState } from "react";
import { Button, Grid, Icon, Input, Modal, Popup } from "semantic-ui-react";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import { checkIsAuthorized } from "../../../components/helpers";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import { CALL_DISPOSITION_TYPES } from "../../../constants/Constants";
import CallQueueService from "../../../services/CallQueue";
import _AddReferralModal from "./AddReferralModal";
import CallbackModal from "./CallbackModal";
import "./DialerFooter.scoped.scss";
import TransferCallModal from "./TransferCallModal";

const AddReferralModal = withRoleCheck(_AddReferralModal, [
  ACL_RELATIONSHIPS.entityContact.create,
  ACL_RELATIONSHIPS.contactReferral.create,
  ACL_RELATIONSHIPS.campaignTargetContacts.create,
]);

function DialPadButton({ onClick, disabled }) {
  function handleClick() {
    !disabled && onClick && onClick();
  }

  return (
    <Button disabled={disabled} onClick={handleClick} icon className="textLink">
      <Icon.Group className="numpad">
        <Icon name="square" className="bg" />
        <Icon name="th" />
        <Icon name="square" />
      </Icon.Group>
    </Button>
  );
}

function DialPadModal({ connection, disabled }) {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!connection) {
      handleClose();
    }
  });

  const sendDigit = async digit => {
    connection.sendDigits(digit);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const phoneButton = (digit, secondary = undefined) => {
    return (
      <Button
        circular
        style={{ height: "55px", width: "55px" }}
        onClick={() => sendDigit(digit)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>{digit}</div>
          {secondary ? <span>{secondary}</span> : null}
        </div>
      </Button>
    );
  };

  return (
    <Modal
      trigger={<DialPadButton onClick={handleOpen} disabled={disabled} />}
      open={modalOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeIcon
      className="dialpad"
      style={{ height: "262px", width: "295px" }}
    >
      <Modal.Content>
        <Grid relaxed centered className="keys">
          <Grid.Row>
            {phoneButton("1")}
            {phoneButton("2", "abc")}
            {phoneButton("3", "def")}
          </Grid.Row>
          <Grid.Row>
            {phoneButton("4", "ghi")}
            {phoneButton("5", "jkl")}
            {phoneButton("6", "mno")}
          </Grid.Row>
          <Grid.Row>
            {phoneButton("7", "pqrs")}
            {phoneButton("8", "tuv")}
            {phoneButton("9", "wxyz")}
          </Grid.Row>
          <Grid.Row>
            {phoneButton("*")}
            {phoneButton("0", "+")}
            {phoneButton("#")}
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

function CallDispositionPopup({
  disposition,
  onSetDispositionId,
  dispositionId,
  setDispositionComment,
  dispositionComment,
  disabled = false,
}) {
  const [, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleClick = (_, { value }) => {
    onSetDispositionId(value.id);
  };

  const handleCommentChange = (parentDisposition, comment) => {
    onSetDispositionId(parentDisposition.id);
    setDispositionComment(comment);
  };

  return (
    <>
      {disposition.child_dispositions.length > 0 ? (
        <Popup
          style={{ width: "100px" }}
          trigger={
            <Button
              size="tiny"
              onClick={handleOpen}
              disabled={disabled}
              content={disposition.displayName}
              className={
                !!disposition.child_dispositions.find(
                  d => d.id === dispositionId
                ) || disposition.id === dispositionId
                  ? "gray"
                  : "white"
              }
            />
          }
          on="click"
          onOpen={handleOpen}
          onClose={handleClose}
          className="disposition-modal"
          content={
            <Grid relaxed centered className="keys">
              {disposition.child_dispositions.map(d => (
                <Grid.Row key={d.id}>
                  <Button
                    size="tiny"
                    content={d.displayName}
                    className={`${
                      dispositionId === d.id ? "gray" : "white"
                    } disposition`}
                    value={d}
                    onClick={handleClick}
                  />
                </Grid.Row>
              ))}
              <Grid.Row key={`${disposition.id}-other`}>
                <Button
                  size="tiny"
                  value={disposition}
                  onClick={(_, data) => {
                    handleClick(_, data);
                    if (dispositionId !== disposition.id) {
                      setDispositionComment("");
                    }
                  }}
                  content="Other"
                  className={`${
                    dispositionId === disposition.id ? "gray" : "white"
                  } disposition`}
                />
              </Grid.Row>
              <Grid.Row>
                <Input
                  className="disposition"
                  placeholder="comment..."
                  name="other_comment"
                  value={
                    dispositionId === disposition.id ? dispositionComment : ""
                  }
                  onChange={e =>
                    handleCommentChange(disposition, e.target.value)
                  }
                />
              </Grid.Row>
            </Grid>
          }
        />
      ) : (
        <Button
          size="tiny"
          disabled={disabled}
          content={disposition.displayName}
          className={dispositionId === disposition.id ? "gray" : "white"}
          value={disposition}
          onClick={handleClick}
        />
      )}
    </>
  );
}

export const WarningModal = ({
  numCalls,
  modalOpenWarning,
  handleModalClose,
  handleClickNext,
}) => {
  const handleOnConfirm = () => {
    handleModalClose();
    handleClickNext();
  };

  return (
    <Modal
      size="tiny"
      open={modalOpenWarning}
      onClose={handleModalClose}
      closeIcon
    >
      <Modal.Header
        style={{
          borderBottom: "2px solid orange",
        }}
      >
        Warning
      </Modal.Header>
      <Modal.Content>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Icon name="exclamation circle" color="orange" size="big" />
          <p
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            The contact has been called {numCalls} times for this campaign
          </p>
          <p style={{ textAlign: "center" }}>
            Are you sure you want to call this contact again?
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button color="orange" onClick={handleOnConfirm}>
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

function FooterButtons({
  autoDial,
  contact_id,
  entity_id,
  campaign_id,
  addContactToQueue,
  dispositions,
  dispositionId,
  onSetDispositionId,
  dispositionComment,
  setDispositionComment,
  disabled,
  setCallbackDate,
  timezone,
  dialSessionId,
  privateSession,
  assignToSelf,
  setAssignToSelf,
  isIncomingCall,
}) {
  const [liveAnswer, setLiveAnswer] = useState(false);

  useEffect(() => {
    setLiveAnswer(false);
  }, [contact_id]);

  if (liveAnswer || isIncomingCall) {
    return (
      <div className="dialer-footer-btns dialer-footer-center">
        <AddReferralModal
          contact_id={contact_id}
          entity_id={entity_id}
          campaign_id={campaign_id}
          addContactToQueue={addContactToQueue}
          dialSessionId={dialSessionId}
          privateSession={privateSession}
          disabled={!(contact_id && campaign_id)}
        />
        <CallbackModal
          dispositionId={dispositionId}
          onSetDispositionId={onSetDispositionId}
          setAssignToSelf={setAssignToSelf}
          checked={assignToSelf}
          setCallbackDate={setCallbackDate}
          timezone={timezone}
        />
        {!isIncomingCall && (
          <Button
            size="tiny"
            color="green"
            icon="phone volume"
            content="No Answer"
            onClick={() => setLiveAnswer(false)}
            disabled={disabled}
          />
        )}
        {dispositions
          .filter(disposition => !disposition.parent_id)
          .filter(disposition => {
            return disposition.type === CALL_DISPOSITION_TYPES.CONNECTED;
          })
          .map(disposition => (
            <CallDispositionPopup
              disposition={disposition}
              onSetDispositionId={onSetDispositionId}
              setDispositionComment={setDispositionComment}
              dispositionId={dispositionId}
              dispositionComment={dispositionComment}
              disabled={disabled}
              key={disposition.id}
            />
          ))}
      </div>
    );
  } else {
    return (
      <div className="dialer-footer-btns dialer-footer-center">
        <Button
          size="tiny"
          color="green"
          icon="phone volume"
          content="Live Answer"
          onClick={() => setLiveAnswer(true)}
          disabled={disabled}
        />
        {dispositions
          .filter(disposition => !disposition.parent_id)
          .filter(disposition => {
            return disposition.type === CALL_DISPOSITION_TYPES.VOICEMAIL;
          })
          .map(disposition => (
            <CallDispositionPopup
              disposition={disposition}
              onSetDispositionId={onSetDispositionId}
              setDispositionComment={setDispositionComment}
              dispositionId={dispositionId}
              dispositionComment={dispositionComment}
              disabled={disabled}
              key={disposition.id}
            />
          ))}
        {dispositions
          .filter(disposition => !disposition.parent_id)
          .filter(disposition => {
            return disposition.type === CALL_DISPOSITION_TYPES.NOT_CONNECTED;
          })
          .map(disposition => (
            <CallDispositionPopup
              disposition={disposition}
              onSetDispositionId={onSetDispositionId}
              setDispositionComment={setDispositionComment}
              dispositionId={dispositionId}
              dispositionComment={dispositionComment}
              disabled={disabled}
              key={disposition.id}
            />
          ))}
        {dispositions
          .filter(disposition => !disposition.parent_id)
          .filter(disposition => {
            return disposition.type === CALL_DISPOSITION_TYPES.UNKNOWN;
          })
          .map(disposition => (
            <CallDispositionPopup
              disposition={disposition}
              onSetDispositionId={onSetDispositionId}
              setDispositionComment={setDispositionComment}
              dispositionId={dispositionId}
              dispositionComment={dispositionComment}
              disabled={disabled}
              key={disposition.id}
            />
          ))}
      </div>
    );
  }
}

export default function DialerFooter({
  autoDial,
  connection,
  deviceStatus,
  paused,
  dispositions,
  dispositionId,
  onSetDispositionId,
  dispositionComment,
  setDispositionComment,
  handleNext,
  handleCloseSession,
  handleSkip,
  submitting,
  contact_id,
  entity_id,
  campaign_id,
  addContactToQueue,
  callAttempted,
  startedSession,
  onDirectTransfer,
  startWarmTransfer,
  cancelWarmTransfer,
  completeWarmTransfer,
  transferCallData,
  setCallbackDate,
  timezone,
  dialSessionId,
  nextContactExists,
  privateSession,
  grantedConsent,
  isTwoPartyConsent,
  toggleConsentModal,
  isRecordingEnabled,
  setAssignToSelf,
  assignToSelf,
  isIncomingCall,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [skipContact, setSkipContact] = useState(false);
  const [clickedNext, setClickedNext] = useState(false);

  const startSession = async () => {
    handleClickNext();
  };

  const canSkipContact =
    !autoDial &&
    checkIsAuthorized([ACL_RELATIONSHIPS.callQueueSkipContact.create]);

  const handleOpen = () => {
    if (grantedConsent == null && isTwoPartyConsent && isRecordingEnabled) {
      return toggleConsentModal();
    }
    setModalOpen(true);
  };

  const handleClose = () => {
    setSkipContact(false);
    setModalOpen(false);
  };

  const handleClickNext = async () => {
    setClickedNext(true);
    const nextContactExists = await CallQueueService.nextContactExists(
      dialSessionId
    );
    if (contact_id && !nextContactExists) {
      handleOpen();
    } else {
      await handleNext();
    }
    setClickedNext(false);
  };

  return (
    <div className="dialer-footer">
      {!isIncomingCall && (
        <div className="dialer-footer-btns dialer-footer-left">
          <DialPadModal
            connection={connection}
            disabled={deviceStatus !== "connected"}
          />
          <TransferCallModal
            onDirectTransfer={onDirectTransfer}
            startWarmTransfer={startWarmTransfer}
            cancelWarmTransfer={cancelWarmTransfer}
            completeWarmTransfer={completeWarmTransfer}
            transferCallData={transferCallData}
            disabled={
              !(transferCallData.callSid || paused) ||
              deviceStatus !== "connected"
            }
          />
        </div>
      )}
      <FooterButtons
        autoDial={autoDial}
        contact_id={contact_id}
        entity_id={entity_id}
        campaign_id={campaign_id}
        deviceStatus={deviceStatus}
        addContactToQueue={addContactToQueue}
        dispositions={dispositions}
        dispositionId={dispositionId}
        onSetDispositionId={onSetDispositionId}
        dispositionComment={dispositionComment}
        setDispositionComment={setDispositionComment}
        disabled={!callAttempted}
        setCallbackDate={setCallbackDate}
        timezone={timezone}
        dialSessionId={dialSessionId}
        privateSession={privateSession}
        assignToSelf={assignToSelf}
        setAssignToSelf={setAssignToSelf}
        isIncomingCall={isIncomingCall}
      />
      {!isIncomingCall && (
        <div className="dialer-footer-btns dialer-footer-right">
          {canSkipContact && !privateSession && (
            <Button
              size="tiny"
              className="white right"
              icon
              onClick={
                contact_id && !nextContactExists
                  ? () => {
                      setSkipContact(true);
                      handleOpen();
                    }
                  : handleSkip
              }
              disabled={!startedSession || callAttempted}
              content="Skip Contact"
            />
          )}
          {autoDial ? null : (
            <Button
              size="tiny"
              className="white right"
              icon
              onClick={startSession}
              disabled={
                submitting ||
                clickedNext ||
                !(!startedSession || (!!dispositionId && callAttempted))
              }
            >
              Next
              <Icon name="arrow right" />
            </Button>
          )}
          <Modal size="tiny" open={modalOpen} onClose={handleClose} closeIcon>
            <Modal.Header>End dial session</Modal.Header>
            <Modal.Content>
              <p>There are no more callable contacts in this dial session.</p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic onClick={handleClose}>
                <Icon name="cancel" /> Cancel
              </Button>
              <Button
                color="blue"
                onClick={() => {
                  handleClose();
                  skipContact ? handleSkip() : handleCloseSession();
                }}
              >
                <Icon name="checkmark" /> End call
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useCallback, useState, useRef } from "react";
import { Button, Segment, Pagination } from "semantic-ui-react";
import RuvixxForm from "../../../components/RuvixxForm";
import OptionsModal from "./OptionsModal";
import ConfirmDeleteModal, {
  DELETE_QUESTION,
  DELETE_PAGE,
} from "./ConfirmDeleteModal";
import { v4 as uuid } from "uuid";

import "./QuestionsForm.scoped.scss";
import { questionTypes } from "../../../constants/Question";
import QuestionService from "../../../services/Question";
import Question from "./Question";

const QuestionsForm = ({ model, onSuccess }) => {
  const [questions, setQuestions] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const optionsModal = useRef();
  const deleteConfirm = useRef();

  const fetchQuestions = useCallback(async () => {
    let questions = await QuestionService.getQuestionsForForm(model.id);
    questions = sortQuestionsByPageAndOrder(questions);
    const totalPages = questions.at(-1)?.page || 1;
    setQuestions(questions);
    setTotalPages(totalPages);
  }, [model.id]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  const handleSubmit = async () => {
    await QuestionService.updateQuestionsForForm(model.id, questions);
  };

  const getQuestionsForPage = page => {
    if (page === undefined) {
      page = activePage;
    }
    const questionsForPage = questions.filter(q => q.page === page);
    return questionsForPage;
  };

  const handleAddQuestion = () => {
    const questionsForPage = getQuestionsForPage();
    const emptyQuestion = {
      title: "",
      question_type: 1,
      order: questionsForPage.length + 1,
      page: activePage,
      options: [],
      collapsed: false,
      ref: uuid(), // Generate random ref id for referencing questions from actions
    };
    let newQuestions = [...questions, emptyQuestion];
    newQuestions = sortQuestionsByPageAndOrder(newQuestions);
    setQuestions(newQuestions);
  };

  const handleAddPage = () => {
    const newTotalPages = totalPages + 1;
    setTotalPages(newTotalPages);
    setActivePage(newTotalPages);
  };

  const handleRemovePrompt = (index, type) => {
    deleteConfirm.current.open(index, type);
  };

  const handleRemove = (index, type) => {
    if (type === DELETE_QUESTION) {
      updateQuestionAtIndex(index);
    } else if (type === DELETE_PAGE) {
      deletePage(index);
    }
  };

  const deletePage = page => {
    let newQuestions = questions.filter(q => q.page !== page);
    const nextPage = page !== totalPages ? page + 1 : null;
    if (nextPage) {
      newQuestions = newQuestions.map(q => ({
        ...q,
        page: q.page >= nextPage ? q.page - 1 : q.page,
      }));
    }
    const newTotalPages = newQuestions.at(-1)?.page || 1;
    const newActivePage = nextPage ? page : page - 1;
    setQuestions(newQuestions);
    setTotalPages(newTotalPages);
    setActivePage(newActivePage);
  };

  const updateQuestionAtIndex = (
    index,
    question = null,
    questionsObj = null
  ) => {
    let objPassed = true;
    if (!questionsObj) {
      questionsObj = questions;
      objPassed = false;
    }
    let newQuestions;
    if (question) {
      newQuestions = [
        ...questionsObj.slice(0, index),
        question,
        ...questionsObj.slice(index + 1),
      ];
    } else {
      newQuestions = [
        ...questionsObj.slice(0, index),
        ...questionsObj.slice(index + 1),
      ];
    }
    if (objPassed) {
      return newQuestions;
    }
    setQuestions(newQuestions);
  };

  const handleEditOptions = (question, index) => {
    optionsModal.current.open(question, index);
  };

  const handleReorder = (index, direction) => {
    const newIndex = index + direction;
    const curQuestion = questions[index];
    let opQuestion = questions[newIndex];
    if (Math.abs(curQuestion.page - opQuestion?.page) > 1) {
      opQuestion = null;
    }
    const shouldSwap = curQuestion.page === opQuestion?.page;
    const shiftUp = opQuestion
      ? curQuestion.page < opQuestion.page
      : direction === 1;
    const newCurQuestion = {
      ...curQuestion,
      order: !opQuestion
        ? 1
        : shouldSwap
        ? opQuestion.order
        : shiftUp
        ? 1
        : opQuestion.order + 1,
      page: !opQuestion
        ? shiftUp
          ? activePage + 1
          : activePage - 1
        : opQuestion.page,
    };
    let newOpQuestion;
    if (opQuestion) {
      newOpQuestion = {
        ...opQuestion,
        order: shouldSwap
          ? curQuestion.order
          : shiftUp
          ? opQuestion?.order + 1
          : opQuestion?.order,
        page: shouldSwap ? curQuestion.page : opQuestion?.page,
      };
    }
    let newQuestions = questions;
    if (shiftUp) {
      newQuestions = questions.map(q => {
        if (q.page !== opQuestion?.page) {
          return q;
        }
        return {
          ...q,
          order: q.order + 1,
        };
      });
    } else if (!shouldSwap) {
      newQuestions = questions.map(q => {
        if (q.page !== curQuestion.page) {
          return q;
        }
        return {
          ...q,
          order: q.order - 1,
        };
      });
    }
    newQuestions = updateQuestionAtIndex(index, newCurQuestion, newQuestions);
    if (newOpQuestion) {
      newQuestions = updateQuestionAtIndex(
        newIndex,
        newOpQuestion,
        newQuestions
      );
    }
    newQuestions = sortQuestionsByPageAndOrder(newQuestions);
    const newActivePage = !opQuestion
      ? shiftUp
        ? activePage + 1
        : activePage - 1
      : opQuestion.page !== curQuestion.page
      ? opQuestion.page
      : activePage;
    setQuestions(newQuestions);
    setActivePage(newActivePage);
  };

  const handlePageChange = (_, { activePage }) => {
    setActivePage(activePage);
  };

  const sortQuestionsByPageAndOrder = questions => {
    const questionsSorted = [...questions].sort(
      (q1, q2) => q1.page - q2.page || q1.order - q2.order
    );
    return questionsSorted;
  };

  const isOptionType = type => {
    return [
      questionTypes.dropdown,
      questionTypes.checkboxGroup,
      questionTypes.radioButtonGroup,
    ].includes(type);
  };

  const isReady = () => {
    return questions.every(({ title, question_type, options }) => {
      let ready = !!title;
      if (isOptionType(question_type) && options.length === 0) {
        ready = false;
      }
      return ready;
    });
  };

  const questionsForPage = getQuestionsForPage();

  return (
    <>
      <OptionsModal
        ref={optionsModal}
        questions={questions}
        updateQuestionAtIndex={updateQuestionAtIndex}
      />
      <ConfirmDeleteModal ref={deleteConfirm} onConfirm={handleRemove} />
      <RuvixxForm
        ready={isReady()}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        submitButtonText="SAVE"
        className="questions-form"
      >
        <div className="buttons">
          <Button
            basic
            size="tiny"
            icon="plus"
            content="Add Question"
            onClick={handleAddQuestion}
          />
          <Button
            basic
            size="tiny"
            icon="plus square"
            content="Add Page"
            onClick={handleAddPage}
            disabled={false}
          />
          <Button
            basic
            size="tiny"
            icon="minus square"
            content="Delete Page"
            onClick={() => handleRemovePrompt(activePage, DELETE_PAGE)}
            disabled={totalPages === 1}
          />
        </div>
        <div className="pages">
          Pages:
          <Pagination
            activePage={activePage}
            setActivePage={setActivePage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
        {questionsForPage.length > 0 && (
          <Segment.Group>
            {questionsForPage.map(question => (
              <Question
                question={question}
                questions={questions}
                totalPages={totalPages}
                onUpdate={updateQuestionAtIndex}
                onReorder={handleReorder}
                onRemovePrompt={handleRemovePrompt}
                onEditOptions={handleEditOptions}
              />
            ))}
          </Segment.Group>
        )}
      </RuvixxForm>
    </>
  );
};

export default QuestionsForm;

import moment from "moment";
import React, { createRef } from "react";

import { Checkbox, Container, List, Segment } from "semantic-ui-react";
import BaseTable from "../../components/BaseTable";
import MapModal from "../../components/MapModal";
import AuthService from "../../services/Auth";
import CaseEventService from "../../services/CaseEvent";
import MachineService from "../../services/Machine";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

import "./../../styles/campaign.scss";
import "./../../styles/table.scss";
import ListTableColumn from "components/ListTableColumn";

class CaseEventsTable extends BaseTable {
  constructor(props) {
    super(props);
    this.mapModal = createRef();
    this.queryMethod = CaseEventService.getTable;

    this.state = {
      ...this.state,
      defaultFilterParams: { case_id: encodeURIComponent(this.props.caseId) },
      header: "Events",
      headerIcon: "bolt",
      className: "CaseEvent",
      tableName: "case_events",
      noDataText: "No Case Events found. Try adjusting your filters.",
      users: [],
      enableSearch: true,
      enableCustomFieldsSettings: true,
      exportTableName: "CaseEventsTable",
      downloadConfig: {
        prompt: {
          text: "Include raw event data in export?",
          confirm: {
            text: "Yes",
            params: {
              include_raw_data: true,
            },
          },
          cancel: "No",
        },
      },
    };
  }

  async componentDidMount() {
    if (AuthService.isLoggedIn()) {
      this.fetchData();
      // this.fetchCustomFields();
      this.updateFilterOptions();
    }
  }

  openMapModal = data => {
    this.mapModal.current.open(data);
  };

  renderCoordCell = ({ original, value }) => {
    if (!value) {
      return null;
    }
    const [lat, lng] = value.split(",");
    return (
      <List>
        <List.Item>
          <a
            onClick={() =>
              this.openMapModal({
                modelType: "Case Event",
                modelId: original.id,
                locations: [
                  {
                    data: original,
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                  },
                ],
              })
            }
          >
            {value}
          </a>
        </List.Item>
      </List>
    );
  };

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        headerClassName: "centered non-sortable",
        width: 40,
        className: "centered",
        Cell: ({ original: { id } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={`case-event-${id}`}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "Case ID",
        accessor: "case_id",
        width: 50,
      },
      {
        Header: "Machine ID",
        accessor: "machine_id",
        width: 50,
      },
      {
        Header: "Product",
        accessor: "product",
      },
      {
        Header: "Event Type",
        accessor: "event_type",
      },
      {
        Header: "Server Timestamp",
        accessor: "server_timestamp",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return null;
        },
      },
      {
        Header: "Machine Local Timestamp",
        accessor: "machine_local_timestamp",
        Cell: props => {
          const value = props.value;
          if (value !== undefined && value !== null) {
            return moment(value).format("YYYY-MM-DD");
          }
          return null;
        },
      },
      {
        Header: "Machine Local Timezone",
        accessor: "machine_local_timezone",
      },
      {
        Header: "Computer Domain",
        accessor: "computer_domain",
      },
      {
        Header: "Email Domains",
        accessor: "email_domains",
        Cell: ({ value }) => value?.join(", "),
      },
      {
        Header: "Machine Locale",
        accessor: "machine_locale",
      },
      {
        Header: "Active Mac",
        accessor: "active_mac",
      },
      {
        Header: "WiFi Lat/Long",
        accessor: "wifi_lat_long",
        Cell: this.renderCoordCell,
      },
      {
        Header: "IP Lat/Long",
        accessor: "ip_lat_long",
        Cell: this.renderCoordCell,
      },
      {
        Header: "Hostname",
        accessor: "hostname",
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Active WiFi Access Point",
        accessor: "active_wifi_access_point",
        sortable: false,
        Cell: ({ value }) => <ListTableColumn data={value || []} />,
      },
      {
        Header: "Additional Email Addresses",
        accessor: "additional_email_addresses",
        sortable: false,
        Cell: ({ value }) => <ListTableColumn data={value || []} />,
      },
    ];
    this.initTableSettings(columns);
  };

  updateFilterOptions = async () => {
    // const { customFields } = this.state;
    const nullNotNullOptions = [
      {
        id: "null",
        name: "Is null",
      },
      {
        id: "not_null",
        name: "Is not null",
      },
    ];

    let filters = [
      {
        key: "machine_id",
        title: "Machine ID",
        type: "ruvixxSelect",
        queryFn: filters =>
          MachineService.getForFilters({
            case_id: encodeURIComponent(this.props.caseId),
            ...filters,
          }),
        props: {
          includeNoneOption: false,
        },
      },
      {
        key: "server_timestamp",
        title: "Server Timestamp",
        type: "dateRange",
      },
      {
        key: "email_domains",
        title: "Email Domains",
        type: "select",
        data: nullNotNullOptions,
        multiple: false,
        clearable: true,
      },
      {
        key: "computer_domain",
        title: "Computer Domain",
        type: "select",
        data: nullNotNullOptions,
        multiple: false,
        clearable: true,
      },
      {
        key: "wifi_lat_long",
        title: "WiFi Lat/Long",
        type: "select",
        data: nullNotNullOptions,
        multiple: false,
        clearable: true,
      },
      {
        key: "ip_lat_long",
        title: "IP Lat/Long",
        type: "select",
        data: nullNotNullOptions,
        multiple: false,
        clearable: true,
      },
    ];
    // customFields.forEach(cf =>
    //   cf.fields.forEach(field => {
    //     filters.push({
    //       key: `search:custom:${cf.model}:${field}`,
    //       title: "Custom Field: " + field,
    //       type: "input",
    //       model: cf.model,
    //     });
    //   })
    // );
    this.setState({ filters });
  };

  render() {
    return (
      <Container fluid className="route">
        <MapModal hasAddresses={false} ref={this.mapModal} />
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default CaseEventsTable;

import React, { useEffect, useRef, useState } from "react";
import chunk from "lodash/chunk";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import isEqual from "lodash/isEqual";
import omitBy from "lodash/omitBy";
import moment from "moment";
import Datetime from "react-datetime";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";
import store from "store";
import { RANGE_FILTER } from "../constants/Constants";
import { mapSelectTagOption } from "../helpers/tags";
import CustomFieldService from "../services/CustomField";
import UserService from "../services/User";

import "./AdvancedSearch.scoped.scss";
import SaveSearchParamsModal from "./modals/SaveSearchParamsModal";
import RuvixxSelect from "./RuvixxSelect";
import InputComponent from "./advancedSearch/InputComponent";
import { getCountryByCode } from "../helpers/countries";
import PropTypes from "prop-types";
import { trimText } from "helpers/string";
import _ from "lodash";

const AdvancedSearchCustomFields = ({
  filters,
  customFilters,
  customFieldConfigs,
  onAdd,
  onUpdate,
  onDelete,
  orFilters,
  setOrFilters,
}) => {
  const [customFieldOptions, setCustomFieldOptions] = useState([]);
  const [customFieldModelOptions, setCustomFieldModelOptions] = useState([]);
  const filterFormFilters = (filter, onlyCustom = false) =>
    onlyCustom === true
      ? filter.key.startsWith("search:custom")
      : !filter.key.startsWith("search:custom");

  useEffect(() => {
    const loadOptions = async () => {
      const options = filters
        .filter(filter => filterFormFilters(filter, true))
        .map(filter => ({
          key: filter.key,
          text: filter.title,
          value: filter.key,
        }));

      const customFieldModelTypes = await getCustomFieldModelTypes();
      const modelOptions = customFieldModelTypes.filter(model =>
        filters.some(f => f.model === model.id && filterFormFilters(f, true))
      );

      setCustomFieldOptions(options);
      setCustomFieldModelOptions(modelOptions);
      // Add options to existing custom filters
      if (customFilters?.length) {
        customFilters.forEach((filter, index) => {
          if (filter.model) {
            customFilters[index].options = filters
              .filter(cf => cf.model === filter.model)
              .map(cf => ({
                key: cf.key,
                text: cf.title,
                value: cf.key,
              }));
          }
        });
        onUpdate(customFilters);
      }
    };

    loadOptions();
  }, []);

  const getCustomFieldModelTypes = async () => {
    const customFieldModelTypes =
      await CustomFieldService.getCustomFieldConfigModelTypes();
    return customFieldModelTypes.map(modelType => ({
      id: modelType,
      value: modelType,
      text: _.startCase(modelType),
    }));
  };

  const findLabelForButton = selectedKey =>
    filters.find(filter => filter.key === selectedKey);

  const onChangeCustomFieldType = (data, fieldIndex) => {
    const label = data.options.find(e => e.value === data.value).text;
    const cf = getCustomFieldConfig(label);
    const newCustomFilters = customFilters;
    const filterForLabel = findLabelForButton(data.value);
    newCustomFilters[fieldIndex].selectValue = data.value;
    newCustomFilters[fieldIndex].label = filterForLabel
      ? filterForLabel["title"]
      : "Custom: ";
    let initialValue = null;
    if (cf && cf.field_type === "boolean") {
      initialValue = false;
    }
    newCustomFilters[fieldIndex].inputValue = initialValue;
    onUpdate(newCustomFilters);
  };

  const handleChange = (fieldIndex, data) => {
    let newValue = data.value;
    if (data.type === "checkbox") {
      newValue = data.checked;
    }
    const newCustomFilters = customFilters;
    newCustomFilters[fieldIndex].inputValue = newValue;
    onUpdate(newCustomFilters);
  };

  const renderInput = (field, fieldIndex, cf) => {
    if (!field.label) {
      return null;
    }
    switch (cf?.field_type) {
      case "text":
        return (
          <Form.Input
            inline
            name={cf.label}
            required={cf.required}
            onChange={(_, data) => handleChange(fieldIndex, data)}
            value={field.inputValue || ""}
          />
        );
      case "numeric":
        return (
          <Form.Input
            inline
            type="number"
            min="0"
            name={cf.label}
            required={cf.required}
            value={field.inputValue}
            onChange={(_, data) => handleChange(fieldIndex, data)}
          />
        );
      case "boolean":
        return (
          <Form.Checkbox
            toggle
            inline
            name={cf.label}
            required={cf.required}
            checked={field.inputValue === "false" ? false : field.inputValue}
            onChange={(_, data) => handleChange(fieldIndex, data)}
          />
        );
      case "select":
        const multiSelect = cf.field_options.type === "multi";
        let value = field.inputValue;
        if (multiSelect) {
          if (typeof value === "string") {
            value = value.split(",");
          }
          if (!value) {
            value = [];
          }
        } else if (Array.isArray(value)) {
          value = value[0];
        }
        return (
          <Form.Select
            inline
            clearable
            search
            multiple={multiSelect}
            name={cf.label}
            required={cf.required}
            options={cf.field_options["options"]}
            value={value}
            onChange={(_, data) => handleChange(fieldIndex, data)}
          />
        );
      case "date":
        return (
          <Form.Field inline required={cf.required} className="dateTime">
            <Datetime
              closeOnSelect={true}
              name={cf.label}
              value={field.inputValue}
              onChange={value => {
                handleChange(fieldIndex, { name: cf.label, value });
              }}
              renderInput={props => (
                <Input icon="large calendar outline" {...props} />
              )}
              dateFormat={"YYYY-MM-DD"}
              timeFormat={false}
              className="above"
            />
          </Form.Field>
        );
      case "list":
        const getOptions = inputValue => {
          if (!inputValue) return [];

          if (!Array.isArray(inputValue)) {
            inputValue = String(inputValue);
            inputValue = inputValue.split(",");
          }
          return inputValue.map(val => {
            if (typeof val === "object") return val;

            if (val) {
              return {
                key: val,
                text: val,
                value: val,
              };
            }
          });
        };

        const getValue = inputValue => {
          if (inputValue) {
            if (!Array.isArray(inputValue)) {
              inputValue = String(inputValue);
              inputValue = inputValue.split(",");
            }

            return inputValue.map(val => {
              if (typeof val === "object" && val.value) {
                return val.value;
              }
              if (val) {
                return val;
              }
            });
          }
        };

        return (
          <Form.Select
            inline
            clearable
            search
            multiple
            name={cf.label}
            required={cf.required}
            options={getOptions(field.inputValue)}
            value={getValue(field.inputValue)}
            onChange={(_, data) => handleChange(fieldIndex, data)}
            allowAdditions
          />
        );
      default:
        return null;
    }
  };

  const getCustomFieldConfig = label => {
    return customFieldConfigs.find(c => c.label === label);
  };

  const renderAndOrSelector = field => {
    const handleChange = orFilter => {
      let newOrFilters;
      if (orFilter) {
        newOrFilters = [...orFilters, field.label];
      } else {
        newOrFilters = orFilters.filter(label => label !== field.label);
      }
      setOrFilters(newOrFilters);
    };
    const isOrFilter = !!orFilters.find(label => label === field.label);
    return (
      <div className="and-or-selector">
        <p>
          Match:{" "}
          <span
            className={`text-link${!isOrFilter ? " selected" : ""}`}
            onClick={() => handleChange(false)}
          >
            all
          </span>{" "}
          &bull;{" "}
          <span
            className={`text-link${isOrFilter ? " selected" : ""}`}
            onClick={() => handleChange(true)}
          >
            any
          </span>
        </p>
      </div>
    );
  };
  const onChangeCustomFieldModel = (data, fieldIndex) => {
    customFilters[fieldIndex].model = data.value;
    customFilters[fieldIndex].options = filters
      .filter(cf => cf.model === data.value)
      .map(cf => ({
        key: cf.key,
        text: cf.title,
        value: cf.key,
      }));
    onUpdate(customFilters);
  };

  const customFieldFormGroup = customFilters?.map((field, fieldIndex) => {
    const customFieldConfig = getCustomFieldConfig(field.label);
    return (
      <React.Fragment key={`custom_field_${field.uuid}`}>
        <Form.Group>
          <Form.Select
            key={`custom_field_model_${fieldIndex}`}
            options={customFieldModelOptions}
            onChange={(_, data) => {
              onChangeCustomFieldModel(data, fieldIndex);
            }}
            placeholder={field.model}
            defaultValue={customFilters[fieldIndex].model}
          />
          {field.model && (
            <Form.Select
              key={`custom_field_form_${fieldIndex}`}
              search
              options={field.options || []}
              onChange={(_, data) => {
                onChangeCustomFieldType(data, fieldIndex);
              }}
              value={customFilters[fieldIndex].selectValue}
            />
          )}
          {renderInput(field, fieldIndex, customFieldConfig)}
          <i
            className="delete link icon red"
            onClick={() => onDelete(field.uuid)}
          />
        </Form.Group>
        {["list", "select"].includes(customFieldConfig?.field_type) &&
          renderAndOrSelector(field)}
      </React.Fragment>
    );
  });

  return customFieldOptions.length !== 0 ? (
    <div style={{ marginBottom: 20 }}>
      <h5 style={{ color: "#666" }}>Custom Fields</h5>
      <div className="and-filter">{customFieldFormGroup}</div>
      <Button
        basic
        color="grey"
        size="mini"
        content="Add custom field"
        onClick={onAdd}
      />
    </div>
  ) : null;
};

const AdvancedSearchTags = props => {
  const tagOptions = props.filters.filter(filter =>
    filter.key.startsWith("tag_id")
  );
  const tagsData = tagOptions.length ? tagOptions[0]["data"] : [];

  const onChangeOperationType = (event, data, fieldIndex) => {
    const newTagFilters = props.tagsFilters;
    newTagFilters[fieldIndex].operationType = data.value;
    newTagFilters[fieldIndex].label =
      data.value === "contains" ? "Contain tags: " : "Exclude tags: ";
    props.onUpdate(newTagFilters);
  };

  const onChangeTagsSelected = (event, data, fieldIndex) => {
    const newTagFilters = props.tagsFilters;
    newTagFilters[fieldIndex].tags = data.value;
    props.onUpdate(newTagFilters);
  };

  const tagSelectableOption = props.tagsFilters
    ? props.tagsFilters.map((field, fieldIndex) => (
        <Form.Group key={`tag_filter_${field.uuid}`} className="bottom-align">
          <Form.Select
            key={`tag_filter_operation_${fieldIndex}`}
            options={[
              { key: "contains", text: "Contains", value: "contains" },
              {
                key: "excludes",
                text: "Excludes",
                value: "excludes",
              },
            ]}
            onChange={(event, data) =>
              onChangeOperationType(event, data, fieldIndex)
            }
            value={props.tagsFilters[fieldIndex].operationType}
          />
          <Form.Select
            key={`tag_filter_tags_${fieldIndex}`}
            multiple
            search
            className="fluid-field or-filter"
            options={tagsData.map(mapSelectTagOption)}
            onChange={(event, data) =>
              onChangeTagsSelected(event, data, fieldIndex)
            }
            renderLabel={item => item.label || item.text}
            value={props.tagsFilters[fieldIndex].tags}
          />
          <i
            className="delete link icon red"
            onClick={() => props.onDelete(field.uuid)}
          />
        </Form.Group>
      ))
    : [];

  return tagOptions.length !== 0 ? (
    <div style={{ marginBottom: 20 }}>
      <h5 style={{ color: "#666" }}>Tags</h5>
      <div className="and-filter">{tagSelectableOption}</div>
      <Button
        basic
        color="grey"
        size="mini"
        content="Add tag"
        onClick={props.onAdd}
      />
    </div>
  ) : null;
};

const rangeTypes = [
  {
    key: 1,
    text: "Greater than or equal to",
    value: RANGE_FILTER.GREATER_THAN,
  },
  {
    key: 2,
    text: "Less than or equal to",
    value: RANGE_FILTER.LESS_THAN,
  },
  {
    key: 3,
    text: "Between",
    value: RANGE_FILTER.BETWEEN,
  },
];

const AdvancedSearch = props => {
  /*
    this is the expected format of the filters:

    [
      {
        title: 'string',
        type: input,
        exact: bool
      },
      {
        title: 'string',
        type: dateRange,
      },
      {
        title: 'string',
        type: select
        data: [
          {id: number, name: 'string'},
          {id: number, name: 'string'},
          ...]
      },
      ...
    ]
  */
  const saveSearchParamsModal = useRef(null);
  const [state, setState] = useState({
    modalOpen: false,
    filterParams: {},
    tagsFilters: [],
    customFilters: [],
    searchParams: [],
    countryOptions: [],
    customFieldConfigs: [],
    savedSearchName: null,
    savedSearchState: {},
    orFilters: [],
  });

  useEffect(() => {
    fetchCustomFieldConfigs();
  }, [props.modelType, props.secondaryModelType]);

  const handleOpen = () => {
    loadSearchParams();
    setState(prevState => ({
      ...prevState,
      modalOpen: true,
      filterParams: cloneDeep(props.filterParams) || {},
      tagsFilters: props.tagsFilters || [],
      customFilters: props.customFilters || [],
      savedSearchName: props.savedSearchName,
      orFilters: props.orFilters || [],
    }));
    fetchCustomFieldConfigs();
  };

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      modalOpen: false,
      tagsFilters: [],
      customFilters: [],
    }));
  };

  const fetchCustomFieldConfigs = async () => {
    let customFieldConfigs =
      await CustomFieldService.getCustomFieldConfigsForModel(props.modelType);
    if (props.secondaryModelType) {
      const secondaryCustomFieldConfigs =
        await CustomFieldService.getCustomFieldConfigsForModel(
          props.secondaryModelType
        );
      customFieldConfigs.concat(secondaryCustomFieldConfigs);
    }
    setState(prevState => ({
      ...prevState,
      customFieldConfigs,
    }));
  };

  const applyFilter = async () => {
    let {
      filterParams,
      tagsFilters,
      customFilters,
      savedSearchName,
      orFilters,
    } = state;
    filterParams = omitBy(filterParams, e => e === null || e === undefined);
    if (!isStillUsingSavedSearch()) {
      savedSearchName = null;
    }
    await props.updateFilterParams(
      filterParams,
      tagsFilters,
      customFilters,
      savedSearchName,
      orFilters
    ); // maybe promise
    setState(prevState => ({ ...prevState, modalOpen: false }));
  };

  const handleInput = data => {
    const { filterParams } = state;
    const updatedFilterParams = {
      ...filterParams,
      [data.target.name]: data.target.value.trim(),
    };
    setState(prevState => ({
      ...prevState,
      filterParams: updatedFilterParams,
    }));
  };

  const handleCheckbox = (e, data) => {
    const { filterParams } = state;
    filterParams[data.name] = data.checked;
    setState(prevState => ({
      ...prevState,
      filterParams,
    }));
  };

  const handleSelect = (e, data) => {
    const { filterParams, countryOptions } = state;
    const key = data.name;
    let newCountryOptions = key === "Region" ? [] : countryOptions;
    if (Array.isArray(data.value)) {
      const value = data.value.map(itemId => {
        return data.options.find(opt => opt.value === itemId);
      });
      if (value.length > 0) {
        filterParams[key] = value;
        if (key === "Region") {
          const countries = value.reduce((countries, region) => {
            countries.push(...region.extraData.countries);
            return countries;
          }, []);
          countries.sort();
          newCountryOptions = Array.from(new Set(countries)).map(country => ({
            key: country,
            value: country,
            text: getCountryByCode(country).text,
          }));
          // check if the selected country is still available
          const selectedCountries = filterParams["Country"];
          if (selectedCountries) {
            filterParams["Country"] = selectedCountries.filter(country =>
              countries.includes(country.value)
            );
          }
        }
      } else {
        delete filterParams[key];
      }
    } else {
      filterParams[key] = data.value;
    }
    setState(prevState => ({
      ...prevState,
      filterParams,
      countryOptions: newCountryOptions,
    }));
  };

  const handleRangeTypeChange = ({ value, title }) => {
    const { filterParams } = state;
    filterParams[title] = {
      ...filterParams[title],
      type: value,
    };
    setState(prevState => ({
      ...prevState,
      filterParams,
    }));
  };

  const handleRangeNumberChange = (event, title, key) => {
    const { value } = event.target;
    const { filterParams } = state;
    filterParams[title] = {
      ...filterParams[title],
      [key]: +value,
    };
    setState(prevState => ({
      ...prevState,
      filterParams,
    }));
  };

  const removeNonMoments = obj => {
    obj = Object.keys(obj).reduce((newObj, key) => {
      let value = obj[key];
      return moment.isMoment(value) ? { ...newObj, [key]: value } : newObj;
    }, {});
    return isEmpty(obj) ? undefined : obj;
  };

  const updateDateRange = (dateRange, filterTitle) => {
    if (dateRange.hasOwnProperty("to") && !dateRange.hasOwnProperty("from")) {
      setState(prevState => ({
        ...prevState,
        filterParams: omitBy(
          {
            ...prevState.filterParams,
            [filterTitle]: removeNonMoments({
              ...prevState.filterParams[filterTitle],
              to: dateRange.to,
            }),
          },
          isUndefined
        ),
      }));
    } else if (
      dateRange.hasOwnProperty("from") &&
      !dateRange.hasOwnProperty("to")
    ) {
      setState(prevState => ({
        ...prevState,
        filterParams: omitBy(
          {
            ...prevState.filterParams,
            [filterTitle]: removeNonMoments({
              ...prevState.filterParams[filterTitle],
              from: dateRange.from,
            }),
          },
          isUndefined
        ),
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        filterParams: omitBy(
          {
            ...prevState.filterParams,
            [filterTitle]: removeNonMoments({
              ...prevState.filterParams[filterTitle],
              ...dateRange,
            }),
          },
          isUndefined
        ),
      }));
    }
  };

  const filterFormFilters = filter =>
    !filter.key.startsWith("search:custom") && filter.key !== "tag_id";

  const onAddTagFilters = () => {
    let tagsFilters = state.tagsFilters;
    tagsFilters.forEach((tagFilter, idx) => {
      tagsFilters[idx].uuid = idx;
    });
    tagsFilters.push({
      operationType: "contains",
      label: "Tags: ",
      tags: [],
      uuid: tagsFilters.length,
    });
    setState(prevState => ({
      ...prevState,
      tagsFilters,
    }));
  };

  const onUpdateTagFilters = newTagFilters => {
    newTagFilters.forEach((tagFilter, idx) => {
      newTagFilters[idx].uuid = idx;
    });
    setState(prevState => ({
      ...prevState,
      tagsFilters: newTagFilters,
    }));
  };

  const onDeleteTagFilter = uuid => {
    const tagsFilters = state.tagsFilters.filter(
      filter => filter.uuid !== uuid
    );
    setState(prevState => ({
      ...prevState,
      tagsFilters,
    }));
  };

  const onAddCustomFilters = () => {
    let customFilters = state.customFilters;
    customFilters.forEach((customFilter, idx) => {
      customFilters[idx].uuid = idx;
    });
    customFilters.push({
      selectValue: null,
      inputValue: null,
      label: null,
      uuid: customFilters.length,
    });
    setState(prevState => ({
      ...prevState,
      customFilters,
    }));
  };

  const onUpdateCustomFilters = newCustomFilters => {
    newCustomFilters.forEach((customFilter, idx) => {
      newCustomFilters[idx].uuid = idx;
    });
    setState(prevState => ({
      ...prevState,
      customFilters: newCustomFilters,
    }));
  };

  const onDeleteCustomFilter = uuid => {
    const customFilters = state.customFilters.filter(
      filter => filter.uuid !== uuid
    );
    setState(prevState => ({
      ...prevState,
      customFilters,
    }));
  };

  const loadSearchParams = () => {
    let searchParams = [];
    const { tableName } = props;
    if (tableName) {
      const settings = store.get("userAuth").table_settings || {};
      const tableSettings = settings[tableName] || {};
      searchParams = tableSettings.search_params || [];
    }
    setState(prevState => ({
      ...prevState,
      searchParams,
    }));
  };

  const removeSearchParam = async (event, index) => {
    event.stopPropagation(); // prevent dropdown menu from closing
    const { searchParams } = state;
    const newSearchParams = [
      ...searchParams.slice(0, index),
      ...searchParams.slice(index + 1),
    ];
    setState(prevState => ({
      ...prevState,
      searchParams: newSearchParams,
    }));
    await UserService.updateUserTableSettings(
      props.tableName,
      "search_params",
      newSearchParams
    );
  };

  const setSavedSearch = (name, savedSearchState) => {
    setState(prevState => ({
      ...prevState,
      savedSearchName: name,
      savedSearchState: savedSearchState,
      filterParams: savedSearchState.filterParams,
      tagsFilters: savedSearchState.tagsFilters,
      customFilters: savedSearchState.customFilters,
    }));
  };

  const isStillUsingSavedSearch = () => {
    const {
      customFilters,
      filterParams,
      tagsFilters,
      savedSearchState,
      savedSearchName,
    } = state;
    if (!savedSearchName || Object.keys(savedSearchState).length === 0) {
      return true;
    }
    return (
      isEqual(customFilters, savedSearchState.customFilters) &&
      isEqual(filterParams, savedSearchState.filterParams) &&
      isEqual(tagsFilters, savedSearchState.tagsFilters)
    );
  };

  const getSearchParamsOptions = () => {
    const noParamsMessage = <Dropdown.Header content="No saved searches" />;
    const paramsItems = state.searchParams.map(
      ({ name, description, state }, index) => {
        const trimmedName = trimText(name, 35);
        const trimmedDescription = trimText(description, 40);
        return (
          <Dropdown.Item key={index}>
            <div className="first" onClick={() => setSavedSearch(name, state)}>
              <div
                className="text"
                title={name !== trimmedName ? name : undefined}
              >
                {trimmedName}
              </div>
              <div
                className="description"
                title={
                  description !== trimmedDescription ? description : undefined
                }
              >
                {trimmedDescription}
              </div>
            </div>
            <div className="second">
              <Icon
                name="close"
                onClick={event => removeSearchParam(event, index)}
              />
            </div>
          </Dropdown.Item>
        );
      }
    );
    return paramsItems.length > 0 ? paramsItems : noParamsMessage;
  };

  const renderFilterElement = (filter, index) => {
    let el;
    if (filter.hidden) {
      return null;
    }

    switch (filter.type) {
      case "select":
        el = renderSelect(index, filter);
        break;
      case "ruvixxSelect":
        el = renderRuvixxSelect(index, filter);
        break;
      case "input":
        el = renderInput(index, filter);
        break;
      case "dateRange":
        el = renderDateRange(index, filter);
        break;
      case "checkbox":
        el = renderCheckbox(index, filter);
        break;
      case "grid":
        el = renderGrid(index, filter);
        break;
      case "range":
        el = renderRange(index, filter);
        break;
      case "ruvixxToggle":
        el = renderRuvixxToggle(index, filter);
        break;
      default:
        el = null;
    }
    return el;
  };

  const renderRuvixxToggle = (index, filter) => {
    const { filterParams } = state;
    return (
      <Form.Group key={index}>
        <Form.Field>
          <label>{filter.title}</label>
          <Checkbox
            toggle
            name={filter.title}
            checked={String(filterParams[filter.title]) === "true"}
            onChange={handleCheckbox}
          />
        </Form.Field>
      </Form.Group>
    );
  };

  const renderDateRange = (index, filter) => {
    const { filterParams } = state;
    const dateRange = filterParams[filter.title] || {
      to: null,
      from: null,
    };
    for (const key of ["to", "from"]) {
      const value = dateRange[key];
      if (value && !moment.isMoment(value)) {
        dateRange[key] = moment(value);
      }
    }
    return (
      <Form.Group key={index}>
        <Form.Field>
          <label>{filter.title} From</label>
          <Datetime
            closeOnSelect={true}
            dateFormat={"YYYY-MM-DD"}
            timeFormat={filter.includeTime || false}
            onChange={moment => updateDateRange({ from: moment }, filter.title)}
            value={dateRange.from}
            renderInput={props => (
              <Input icon="large calendar outline" {...props} />
            )}
          />
        </Form.Field>
        <Form.Field>
          <label>{filter.title} To</label>
          <Datetime
            closeOnSelect={true}
            timeFormat={filter.includeTime || false}
            dateFormat={"YYYY-MM-DD"}
            onChange={moment => updateDateRange({ to: moment }, filter.title)}
            value={dateRange.to}
            renderInput={props => (
              <Input icon="large calendar outline" {...props} />
            )}
          />
        </Form.Field>
      </Form.Group>
    );
  };

  const renderInput = (index, filter) => {
    const { filterParams } = state;
    return (
      <InputComponent
        key={index}
        name={filter.title}
        label={filter.title}
        type={filter.inputType || "text"}
        initialValue={filterParams[filter.title]}
        onValueChange={eventValue => handleInput(eventValue)}
      />
    );
  };

  const renderCheckbox = (index, filter) => {
    const { filterParams } = state;
    return (
      <Form.Checkbox
        key={index}
        name={filter.title}
        label={filter.title}
        checked={filterParams[filter.title] || false}
        onChange={handleCheckbox}
      />
    );
  };

  const renderGrid = (index, filter) => {
    const components = chunk(filter.components, filter.props.columns);
    return (
      <>
        {filter.title ? (
          <Form.Field>
            <label>{filter.title}</label>
          </Form.Field>
        ) : null}
        <Form.Field>
          <Grid key={index} {...filter.props}>
            {components.map((row, rowIndex) => (
              <Grid.Row key={index}>
                {row.map((comp, colIndex) => (
                  <Grid.Column key={index}>
                    {renderFilterElement(
                      { ...comp, ...filter.componentProps },
                      `${rowIndex}${colIndex}`
                    )}
                  </Grid.Column>
                ))}
              </Grid.Row>
            ))}
          </Grid>
        </Form.Field>
      </>
    );
  };

  const getDefaultSelectValue = (index, filter) => {
    const { filterParams } = state;
    let result = null;
    if (filterParams && filter.title in filterParams) {
      const filterValue = filterParams[filter.title];
      if (
        ("multiple" in filter && filter.multiple) ||
        !("multiple" in filter)
      ) {
        result = filterValue.map(item => item && item.value);
      } else {
        if (Array.isArray(filterValue)) {
          result = filterValue.map(item => item.value).find(e => e === e);
        } else {
          result = filterValue;
        }
      }
    }
    return result;
  };

  const renderSelect = (index, filter) => {
    let options = filter.data.map(item => ({
      key: item.id,
      text: item.name,
      value: item.id,
      ...(item.color && {
        className: "hideText",
        label: {
          content: item.name,
          className: "ctag",
          style: { backgroundColor: item.color },
        },
      }),
      extraData: item.extraData,
    }));

    if (filter.title === "Country" && state.countryOptions?.length) {
      options = state.countryOptions;
    }

    return (
      <Form.Select
        className="or-filter"
        key={index}
        multiple={"multiple" in filter ? filter.multiple : true}
        search
        clearable={filter.clearable || false}
        name={filter.title}
        label={filter.title}
        options={options}
        value={getDefaultSelectValue(index, filter)}
        onChange={handleSelect}
        renderLabel={item => item.label || item.text}
      />
    );
  };

  const renderRuvixxSelect = (index, filter) => {
    return (
      <RuvixxSelect
        key={index}
        inline={false}
        className="or-filter"
        label={filter.title}
        name={filter.title}
        multiple={"multiple" in filter ? filter.multiple : true}
        clearable={filter.clearable || false}
        value={getDefaultSelectValue(index, filter)}
        onChange={handleSelect}
        queryFn={filter.queryFn}
        {...filter.props}
      />
    );
  };

  const renderRange = (index, filter) => {
    const { title } = filter;
    const filterValue = state.filterParams[title] || {};
    return (
      <div key={index} className="range-filter">
        <Form.Field>
          <label>{title}</label>
        </Form.Field>
        <Form.Group>
          <Form.Select
            options={rangeTypes}
            value={filterValue.type}
            onChange={(_, data) => handleRangeTypeChange({ ...data, title })}
          />
          {filterValue.type && (
            <>
              <Form.Input
                type="number"
                className="range-start"
                defaultValue={filterValue.start}
                onChange={event =>
                  handleRangeNumberChange(event, title, "start")
                }
              />
              {filterValue.type === RANGE_FILTER.BETWEEN && (
                <>
                  <label className="and-label">and</label>
                  <Form.Input
                    type="number"
                    defaultValue={filterValue.end}
                    onChange={event =>
                      handleRangeNumberChange(event, title, "end")
                    }
                  />
                </>
              )}
            </>
          )}
        </Form.Group>
      </div>
    );
  };

  const setOrFilters = newOrFilters => {
    setState(prevState => ({
      ...prevState,
      orFilters: newOrFilters,
    }));
  };

  return (
    <>
      <SaveSearchParamsModal
        ref={saveSearchParamsModal}
        tableName={props.tableName}
        searchParams={state.searchParams}
        filterParams={state.filterParams}
        tagsFilters={state.tagsFilters}
        customFilters={state.customFilters}
        loadSearchParams={loadSearchParams}
      />
      <Modal
        closeOnDimmerClick={false}
        open={state.modalOpen}
        onClose={handleClose}
        trigger={
          <Button
            className="advanced search"
            content={props.name || "Advanced Search"}
            onClick={handleOpen}
            size="mini"
            {...props.buttonProps}
          />
        }
        closeIcon
        size="small"
        className="advanced-search"
      >
        <Header content={props.name || "Advanced Search"} />
        <Form>
          <Container>
            <div className="load-search-container">
              {props.tableName && (
                <Dropdown
                  className="button primary icon tiny"
                  text={
                    <>
                      <Icon name="magnify" />
                      <strong>Load Search</strong>
                    </>
                  }
                >
                  <Dropdown.Menu
                    style={{
                      left: state.searchParams.length === 0 ? 0 : "auto",
                    }}
                  >
                    {getSearchParamsOptions()}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            {props.filters &&
              props.filters
                .filter(filterFormFilters)
                .map((filter, index) => renderFilterElement(filter, index))}
            <AdvancedSearchTags
              filters={props.filters}
              tagsFilters={state.tagsFilters}
              onAdd={onAddTagFilters}
              onUpdate={onUpdateTagFilters}
              onDelete={onDeleteTagFilter}
            />
            <AdvancedSearchCustomFields
              filters={props.filters}
              customFilters={state.customFilters}
              onUpdate={onUpdateCustomFilters}
              onAdd={onAddCustomFilters}
              onDelete={onDeleteCustomFilter}
              customFieldConfigs={state.customFieldConfigs}
              orFilters={state.orFilters}
              setOrFilters={setOrFilters}
            />
          </Container>
          <Divider hidden style={{ margin: "2.5em 0" }} />
        </Form>
        <Modal.Actions>
          {props.tableName && (
            <SaveSearchParamsModal.Button modalRef={saveSearchParamsModal} />
          )}
          <Button
            basic
            secondary
            size="tiny"
            onClick={handleClose}
            content="Cancel"
          />
          <Button size="tiny" secondary onClick={applyFilter} content="Apply" />
        </Modal.Actions>
      </Modal>
    </>
  );
};

AdvancedSearch.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateFilterParams: PropTypes.func.isRequired,
  tableName: PropTypes.string,
};

export default AdvancedSearch;

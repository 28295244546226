import React, { useState } from "react";
import { Button, Checkbox, Grid, Icon, Modal, Select } from "semantic-ui-react";

import ConfirmationModal from "components/modals/ConfirmationModal";
import withRoleCheck from "components/hocs/withRoleCheck";
import ACL_RELATIONSHIPS from "acl-relationships";
import UserService from "services/User";
const ResetSettingsModal = withRoleCheck(ConfirmationModal, [
  ACL_RELATIONSHIPS.userSettings.edit,
]);

const TableSettings = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [freezedColumns, setFreezedColumns] = useState([]);
  const [customFieldsColumns, setCustomFieldsColumns] = useState([]);
  const [showDisabledTags, setShowDisabledTags] = useState(false);

  const handleOpen = () => {
    const { visibleColumns, freezedColumns, showDisabledTags } = props;
    setVisibleColumns(visibleColumns);
    setFreezedColumns(freezedColumns);
    setShowDisabledTags(showDisabledTags);
    setModalOpen(true);
  };

  const handleClose = () => {
    const { visibleColumns, freezedColumns, showDisabledTags } = props;
    setVisibleColumns(visibleColumns);
    setFreezedColumns(freezedColumns);
    setShowDisabledTags(showDisabledTags);
    setCustomFieldsColumns([]);
    setModalOpen(false);
  };

  const handleSelect = (_, data) => {
    if (data.value.length === 0) {
      return;
    }
    const customFieldColumn = data.value[0];
    if (
      !customFieldsColumns.includes(customFieldColumn) &&
      !visibleColumns.includes(customFieldColumn)
    ) {
      setVisibleColumns([...visibleColumns, customFieldColumn]);
      setCustomFieldsColumns([...customFieldsColumns, customFieldColumn]);
    }
  };

  const resetTableSettings = async () => {
    await UserService.resetTable(props.tableName);
    window.location.reload();
  };

  const handleChange = (_, data) => {
    const customField = data.data.customField;
    const label = customField || data.label;
    if (data.data.fieldSelection) {
      if (data.checked) {
        let tempArray = Array.from(visibleColumns);
        const actionsFieldIndex = tempArray.findIndex(c => c === "Actions");
        if (customField && actionsFieldIndex !== -1) {
          tempArray.splice(actionsFieldIndex, 0, label);
        } else {
          tempArray.push(label);
        }
        setVisibleColumns(tempArray);
      } else {
        setVisibleColumns(visibleColumns.filter(c => c !== label));
        if (customField) {
          setFreezedColumns(freezedColumns.filter(c => c !== label));
        }
      }
    } else if (data.data.freezeSelection) {
      if (data.checked) {
        const tempArray = Array.from(freezedColumns);
        tempArray.push(label);
        setFreezedColumns(tempArray);
      } else {
        setFreezedColumns(freezedColumns.filter(c => c !== label));
      }
    }
  };

  const updateColumns = async () => {
    setCustomFieldsColumns([]);
    props.updateTableSettings({
      visibleColumns,
      freezedColumns,
      showDisabledTags,
    });
    setModalOpen(false);
  };

  return (
    <Modal
      trigger={
        <Button size="mini" onClick={handleOpen}>
          <Icon style={{ margin: "auto" }} name="setting" />
        </Button>
      }
      open={modalOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>Field Selection</Modal.Header>
      <Modal.Content style={{ padding: "2.5rem" }}>
        <Grid
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {props.allColumns
            .filter(
              c =>
                typeof c.Header === "string" &&
                !(
                  c.accessor &&
                  c.accessor.includes &&
                  !!props.customFields.find(cf =>
                    c.accessor.startsWith(`${cf.accessorPrefix}.`)
                  )
                )
            )
            .map(c => (
              <Checkbox
                key={c.Header}
                style={{ width: 220, margin: "10px 0" }}
                label={c.Header}
                onClick={handleChange}
                data={{ fieldSelection: true }}
                checked={!!visibleColumns.find(v => v === c.Header)}
              />
            ))}
        </Grid>
      </Modal.Content>
      {props.enableCustomFieldsSettings &&
        props.customFields &&
        props.customFields.map(cf => (
          <>
            <Modal.Header>{`${
              cf.headerPrefix || ""
            } Custom Fields`}</Modal.Header>
            <Modal.Content>
              <Grid>
                <Grid.Column>
                  <Select
                    clearable
                    multiple
                    search
                    onChange={handleSelect}
                    name="customField"
                    options={cf.fields
                      .filter(
                        field =>
                          !visibleColumns.includes(
                            `${cf.accessorPrefix}.${field}`
                          )
                      )
                      .map((field, index) => ({
                        key: index,
                        text: field,
                        value: `${cf.accessorPrefix}.${field}`,
                      }))}
                    placeholder="Select Custom Field"
                    style={{ width: "100%" }}
                    value={[]}
                  />
                </Grid.Column>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflowWrap: "break-word",
                }}
              >
                {props.allColumns
                  .filter(
                    c =>
                      typeof c.Header === "string" &&
                      c.accessor &&
                      c.accessor.startsWith &&
                      c.accessor.startsWith(`${cf.accessorPrefix}.`)
                  )
                  .map(c => {
                    const cf = props.customFields.find(cf =>
                      c.accessor.startsWith(`${cf.accessorPrefix}.`)
                    );
                    return (
                      <Checkbox
                        key={c.Header}
                        style={{ width: 220, margin: "10px 0" }}
                        label={c.Header.replace(`${cf.headerPrefix}.`, "")}
                        onClick={handleChange}
                        data={{
                          fieldSelection: true,
                          customField: c.accessor,
                        }}
                        checked={!!visibleColumns.find(v => v === c.accessor)}
                      />
                    );
                  })}
                {customFieldsColumns
                  .filter(c => c.startsWith(`${cf.accessorPrefix}.`))
                  .map(c => (
                    <Checkbox
                      key={c}
                      style={{ width: 220, margin: "10px 0" }}
                      label={c.replace(`${cf.accessorPrefix}.`, "")}
                      onClick={handleChange}
                      data={{ fieldSelection: true, customField: c }}
                      checked={!!visibleColumns.find(v => v === c)}
                    />
                  ))}
              </Grid>
            </Modal.Content>
          </>
        ))}
      <Modal.Header>Freeze Selection</Modal.Header>
      <Modal.Content>
        <Grid
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {props.allColumns
            .filter(
              c =>
                typeof c.Header === "string" &&
                !(
                  c.accessor &&
                  c.accessor.startsWith &&
                  !!props.customFields.find(cf =>
                    c.accessor.startsWith(`${cf.accessorPrefix}.`)
                  )
                )
            )
            .map(c => (
              <Checkbox
                key={c.Header}
                style={{ width: 220, margin: "10px 0" }}
                label={c.Header}
                onClick={handleChange}
                data={{ freezeSelection: true }}
                checked={!!freezedColumns.find(v => v === c.Header)}
              />
            ))}
        </Grid>
      </Modal.Content>
      {props.enableCustomFieldsSettings &&
        props.customFields &&
        props.customFields.map(cf => (
          <>
            <Modal.Header>{`Frozen ${
              cf.headerPrefix || ""
            } Custom Fields`}</Modal.Header>
            <Modal.Content>
              <Grid
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflowWrap: "break-word",
                }}
              >
                {props.allColumns
                  .filter(
                    c =>
                      typeof c.Header === "string" &&
                      c.accessor &&
                      c.accessor.startsWith &&
                      c.accessor.startsWith(`${cf.accessorPrefix}.`)
                  )
                  .map(c => (
                    <Checkbox
                      key={c.Header}
                      style={{ width: 220, margin: "10px 0" }}
                      label={c.Header.replace(`${cf.headerPrefix}.`, "")}
                      onClick={handleChange}
                      data={{
                        freezeSelection: true,
                        customField: c.accessor,
                      }}
                      checked={!!freezedColumns.find(v => v === c.accessor)}
                    />
                  ))}
                {customFieldsColumns
                  .filter(c => c.startsWith(`${cf.accessorPrefix}.`))
                  .map(c => (
                    <Checkbox
                      key={c}
                      style={{ width: 220, margin: "10px 0" }}
                      label={c.replace(`${cf.accessorPrefix}.`, "")}
                      onClick={handleChange}
                      data={{ freezeSelection: true, customField: c }}
                      checked={!!freezedColumns.find(v => v === c)}
                    />
                  ))}
              </Grid>
            </Modal.Content>
          </>
        ))}
      <Modal.Header>Tags</Modal.Header>
      <Modal.Content>
        <Checkbox
          style={{ margin: "0 5px" }}
          label="Show disabled tags"
          checked={showDisabledTags}
          onChange={(_, { checked }) => setShowDisabledTags(checked)}
        />
      </Modal.Content>
      <Modal.Actions>
        <ResetSettingsModal
          style={{ float: "left" }}
          actionDescription="This will reset the current table settings to their original state"
          icon="warning circle"
          buttonColor="orange"
          buttonText="Reset Table Settings"
          onConfirm={resetTableSettings}
          warning
        >
          <p>Are you sure you want to reset the table Settings?</p>
        </ResetSettingsModal>
        <Button basic size="tiny" onClick={handleClose} content="Close" />
        <Button primary size="tiny" onClick={updateColumns} content="Confirm" />
      </Modal.Actions>
    </Modal>
  );
};

export default TableSettings;

import React, { useState, useEffect, useCallback } from "react";
import { Form } from "semantic-ui-react";

import "./VisibilitySelector.scoped.scss";
import taskConstants from "../../../constants/Task";
import UserService from "../../../services/User";
import RoleService from "../../../services/Role";

const VisibilitySelector = ({
  visibility,
  setVisibility,
  visibilityIds,
  setVisibilityIds,
}) => {
  const [options, setOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchOptions = useCallback(
    async query => {
      const { key } = visibility;
      const byIds = Array.isArray(query);
      switch (key) {
        case "private_roles":
          let roles;
          if (byIds) {
            roles = await RoleService.getRoles();
            roles = roles.filter(({ id }) => query.includes(id));
          } else {
            roles = await RoleService.getRolesTable({
              search: query,
            });
            roles = roles.data;
          }
          return roles.map(({ id, name }) => ({
            key: id,
            value: id,
            text: name,
          }));
        case "private_users":
          let users;
          if (byIds) {
            users = await UserService.getUsers();
            users = users.filter(({ id }) => query.includes(id));
          } else {
            users = await UserService.search(query);
          }
          return users.map(({ id, full_name }) => ({
            key: id,
            value: id,
            text: full_name,
          }));
        default:
      }
      return [];
    },
    [visibility]
  );

  const loadVisibility = useCallback(async () => {
    if (!visibility || !visibilityIds) {
      return;
    }
    const options = await fetchOptions(visibilityIds);
    setOptions(options);
  }, [visibility, visibilityIds, fetchOptions]);

  useEffect(() => {
    loadVisibility();
  }, [loadVisibility]);

  const handleVisibilityChange = async (_, { value }) => {
    const visibility = taskConstants.visibilities.find(
      ({ id }) => id === value
    );
    setVisibility(visibility);
    setVisibilityIds([]);
    setOptions([]);
    setSearchQuery("");
  };

  const handleIdsChange = async (_, { value, options }) => {
    const selectedOptions = options.filter(option =>
      value.includes(option.value)
    );
    setOptions(selectedOptions);
    setSearchQuery("");
    setVisibilityIds(value);
  };

  const handleSearchChange = async (_, { searchQuery }) => {
    setSearchQuery(searchQuery);
    if (!searchQuery) {
      setOptions([]);
      return;
    }
    const options = await fetchOptions(searchQuery);
    setOptions(options);
  };

  const handleBlur = () => {
    setSearchQuery("");
  };

  return (
    <div className="visibilitySelector">
      <Form.Group>
        <Form.Field required inline>
          <label>Visibility</label>
        </Form.Field>
        <Form.Select
          fluid
          value={visibility.id}
          className="visibility"
          options={taskConstants.visibilities.map(({ id, name }) => ({
            key: id,
            value: id,
            text: name,
          }))}
          onChange={handleVisibilityChange}
        />
        {visibility.id && visibility.key !== "public" && (
          <Form.Select
            required
            search
            multiple
            value={visibilityIds}
            className="visibilityIds"
            options={options}
            noResultsMessage={searchQuery ? undefined : null}
            placeholder={`Start typing to search for a ${visibility.model}`}
            searchQuery={searchQuery}
            onChange={handleIdsChange}
            onSearchChange={handleSearchChange}
            onBlur={handleBlur}
          />
        )}
      </Form.Group>
    </div>
  );
};

export default VisibilitySelector;

import React from "react";
import { Container, Dropdown, Segment } from "semantic-ui-react";

import "react-table-6/react-table.css";
import "react-table-hoc-draggable-columns/dist/styles.css";

import "../styles/table.scss";
import "../styles/tab_view.scss";

import BaseTable from "./BaseTable";
import AuthService from "../services/Auth";
import ACL_RELATIONSHIPS from "../acl-relationships";
import withRoleCheck from "./hocs/withRoleCheck";
import CustomDropdown from "./CustomDropdown";
import setPageTitle from "../helpers/title";
import RevenueOpportunityService from "../services/RevenueOpportunities";
import _RevenueOpportunityStatusModal from "./modals/RevenueOpportunityStatusModal";
import _DeleteRevenueOpportunityStatusModal from "./modals/DeleteRevenueOpportunityStatusModal";

const RevenueOpportunityStatusModal = withRoleCheck(
  _RevenueOpportunityStatusModal,
  [
    ACL_RELATIONSHIPS.revenueOpportunity.edit,
    ACL_RELATIONSHIPS.revenueOpportunity.create,
  ]
);

const DeleteRevenueOpportunityStatusModal = withRoleCheck(
  _DeleteRevenueOpportunityStatusModal,
  [ACL_RELATIONSHIPS.revenueOpportunity.delete]
);

class RevenueOpportunityStatusesTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod =
      RevenueOpportunityService.getRevenueOpportunityStatusesTable;

    this.state = {
      ...this.state,
      header: "Revenue Opportunity Status",
      headerIcon: "building",
      tableName: "revenue_opportunity_status",
      noDataText: "No Opportunity Statuses found. Try adjusting your filters.",
      statusOptions: [],
      createButton: (
        <RevenueOpportunityStatusModal
          fetchStatus={this.fetchData}
          tableView={true}
        />
      ),
      enableCustomFieldsSettings: true,
    };
  }

  handleOnSuccess = async () => {
    await this.fetchRevenueOpportunityStatus();
    await this.fetchData();
  };

  deleteRevenueOpportunityStatus = async revenueOpportunityId => {
    try {
      await RevenueOpportunityService.deleteRevenueOpportunityStatus(
        revenueOpportunityId
      );
      this.fetchData();
      return null;
    } catch (error) {
      return error;
    }
  };

  async componentDidMount() {
    setPageTitle("Revenue Opportunity Statuses");
    if (AuthService.isLoggedIn()) {
      this.fetchData();
    }
  }

  setColumns = () => {
    const columns = [
      {
        Header: "Opportunity Status ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Actions",
        id: "actions",
        width: 140,
        className: "centered",
        sortable: false,
        headerClassName: "centered non-sortable",
        Cell: props => (
          <CustomDropdown icon="ellipsis horizontal">
            <Dropdown.Menu direction="left">
              <RevenueOpportunityStatusModal
                fetchStatus={this.fetchData}
                revenueOpportunityStatusId={props.original.parent_id}
              />
              <DeleteRevenueOpportunityStatusModal
                onConfirmDelete={() =>
                  this.deleteRevenueOpportunityStatus(props.original.id)
                }
                menuTrigger
              />
            </Dropdown.Menu>
          </CustomDropdown>
        ),
      },
    ];
    this.initTableSettings(columns);
  };

  render() {
    return (
      <Container fluid className="route sub-route">
        <div className="campaign-segment">
          <Segment>{this.renderTable()}</Segment>
        </div>
      </Container>
    );
  }
}

export default RevenueOpportunityStatusesTable;

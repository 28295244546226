import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import ProgressRing from "./ProgressRing";

export default class UploadProgressRing extends Component {
  static propTypes = {
    uploads: PropTypes.array,
    onDelete: PropTypes.func,
    xOnComplete: PropTypes.bool,
  };

  static defaultProps = {
    uploads: [],
    onDelete: null,
    xOnComplete: false,
  };

  render = () => (
    <Grid style={{ marginTop: "1em" }}>
      {this.props.uploads.map(upload => {
        let xOnHover = this.props.xOnComplete || upload.percent < 100;
        return (
          <Grid.Row
            key={upload.id}
            columns="equal"
            style={{ marginBottom: "1em" }}
          >
            <Grid.Column>
              <ProgressRing
                percent={upload.percent}
                label={upload.file.name}
                onClick={
                  xOnHover ? () => this.props.onDelete(upload) : () => {}
                }
                xOnHover={xOnHover}
                alwaysShowX={this.props.xOnComplete && upload.percent === 100}
              />
            </Grid.Column>
          </Grid.Row>
        );
      })}
    </Grid>
  );
}

import React, { useState } from "react";
import { Button, Modal, Segment, Header } from "semantic-ui-react";
import ResetPasswordForm from "./ResetPasswordForm";

function ResetPasswordModal({ handleResetSuccess }) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={
        <Segment basic textAlign="center">
          <Button onClick={handleOpen} className="textLink">
            Forgot Password?
          </Button>
        </Segment>
      }
    >
      <Header content={"Reset Password"} />
      <Modal.Content>
        {
          <ResetPasswordForm
            onCancel={handleClose}
            handleResetSuccess={handleResetSuccess}
          />
        }
      </Modal.Content>
    </Modal>
  );
}

export default ResetPasswordModal;

// copy of https://github.com/TanStack/react-table/blob/v6.11.4/src/pagination.js
// with added methods for total rows display

import React, { Component } from "react";
import classnames from "classnames";

const defaultButton = props => (
  <button type="button" {...props} className="-btn">
    {props.children}
  </button>
);

export default class ReactTablePagination extends Component {
  static defaultProps = {
    PreviousComponent: defaultButton,
    NextComponent: defaultButton,
    renderPageJump: ({
      onChange,
      value,
      onBlur,
      onKeyPress,
      inputType,
      pageJumpText,
    }) => (
      <div className="-pageJump">
        <input
          aria-label={pageJumpText}
          type={inputType}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
      </div>
    ),
    renderCurrentPage: page => <span className="-currentPage">{page + 1}</span>,
    renderTotalPagesCount: pages => (
      <span className="-totalPages">{pages || 1}</span>
    ),
    renderPageSizeOptions: ({
      pageSize,
      pageSizeOptions,
      rowsSelectorText,
      onPageSizeChange,
      rowsText,
    }) => (
      <span className="select-wrap -pageSizeOptions">
        <select
          aria-label={rowsSelectorText}
          onChange={e => onPageSizeChange(Number(e.target.value))}
          value={pageSize}
        >
          {pageSizeOptions.map((option, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={option}>
              {`${option} ${rowsText}`}
            </option>
          ))}
        </select>
      </span>
    ),
  };

  constructor(props) {
    super(props);

    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyPage = this.applyPage.bind(this);

    this.state = {
      page: props.page,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.page !== this.props.page) {
      // this is probably safe because we only update when old/new state.page are different
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        page: this.props.page,
      });
    }
  }

  getSafePage(page) {
    if (Number.isNaN(page)) {
      page = this.props.page;
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1);
  }

  changePage(page) {
    page = this.getSafePage(page);
    this.setState({ page });
    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  }

  applyPage(e) {
    if (e) {
      e.preventDefault();
    }
    const page = this.state.page;
    this.changePage(page === "" ? this.props.page : page);
  }

  getPageJumpProperties() {
    return {
      onKeyPress: e => {
        if (e.which === 13 || e.keyCode === 13) {
          this.applyPage();
        }
      },
      onBlur: this.applyPage,
      value: this.state.page === "" ? "" : this.state.page + 1,
      max: this.props.totalRows,
      onChange: e => {
        const val = e.target.value;
        const page = val - 1;
        if (val === "") {
          return this.setState({ page: val });
        }
        this.setState({ page: this.getSafePage(page) });
      },
      inputType: this.state.page === "" ? "text" : "number",
      pageJumpText: this.props.pageJumpText,
    };
  }

  renderPageJump({
    onChange,
    value,
    onBlur,
    onKeyPress,
    inputType,
    pageJumpText,
    max,
  }) {
    return (
      <div className="-pageJump">
        <input
          ref={this.pageJumpRef}
          aria-label={pageJumpText}
          type={inputType}
          onChange={onChange}
          value={value}
          min={1}
          max={max}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
      </div>
    );
  }

  calcMinRow(page, pageSize, totalRows) {
    if (!totalRows) {
      return 0;
    }
    return page * pageSize + 1;
  }

  calcMaxRow(page, pageSize, totalRows) {
    return Math.min((page + 1) * pageSize, totalRows);
  }

  render() {
    const {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      totalRows,
      tableName,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      PreviousComponent,
      NextComponent,
      renderPageJump,
      renderCurrentPage,
      renderTotalPagesCount,
      renderPageSizeOptions,
    } = this.props;

    return (
      <div
        className={classnames(className, "-pagination")}
        style={this.props.style}
      >
        <div className="-previous">
          <PreviousComponent
            onClick={() => {
              if (!canPrevious) return;
              this.changePage(page - 1);
            }}
            disabled={!canPrevious}
          >
            {this.props.previousText}
          </PreviousComponent>
        </div>
        <div className="-center">
          <span className="-pageInfo">
            {this.props.pageText}{" "}
            {showPageJump
              ? renderPageJump(this.getPageJumpProperties())
              : renderCurrentPage(page)}{" "}
            {this.props.ofText} {renderTotalPagesCount(pages)}
          </span>

          {typeof totalRows !== "undefined" ? (
            <span className="-rowInfo">
              {"Showing "}
              <span className="-rowMin">
                {this.calcMinRow(page, pageSize, totalRows)}
              </span>
              {" - "}
              <span className="-rowMax">
                {this.calcMaxRow(page, pageSize, totalRows)}
              </span>
              {" of "}
              <span className="-rowCount">
                {totalRows.toLocaleString("en-US")}
              </span>
            </span>
          ) : (
            ""
          )}
          {showPageSizeOptions &&
            renderPageSizeOptions({
              pageSize,
              rowsSelectorText: this.props.rowsSelectorText,
              pageSizeOptions,
              onPageSizeChange,
              rowsText: this.props.rowsText,
            })}
        </div>
        <div className="-next">
          <NextComponent
            onClick={() => {
              if (!canNext) return;
              this.changePage(page + 1);
            }}
            disabled={!canNext}
          >
            {this.props.nextText}
          </NextComponent>
        </div>
      </div>
    );
  }
}

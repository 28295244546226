import { applyFilters, doRequest, retryOnError } from "./helpers";

export default class DialSessionService {
  static async getSessions(filters) {
    let path = `/dial_session/`;
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }

  static async getSession(id) {
    let path = `/dial_session/${id}`;
    return doRequest("GET", path);
  }

  static async createSession(data) {
    return doRequest("POST", "/dial_session/", { data });
  }

  static editSession(id, data) {
    return doRequest("PUT", `/dial_session/${id}`, { data });
  }

  static deleteSession(id) {
    return doRequest("DELETE", `/dial_session/${id}`);
  }

  static async createPrivateSession(data) {
    return doRequest("POST", "/dial_session/private/", { data });
  }

  static async getSessionSummary(dialSessionId) {
    let path = `/dial_session/${dialSessionId}/summary`;
    return doRequest("GET", path);
  }

  static async getDialSessionsForFilters(filters = null) {
    let path = "/dial_session/for_filters";
    path = applyFilters(filters, path);
    return doRequest("GET", path);
  }
}

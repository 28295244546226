import React from "react";
import { Button, Label } from "semantic-ui-react";

import "./PriorityLabel.scoped.scss";

const PriorityLabel = ({
  priority,
  onClick,
  className = "",
  selected,
  ...labelProps
}) => {
  if (!priority) {
    return null;
  }

  const { name, color } = priority;

  const renderLabel = () => (
    <Label
      size="tiny"
      className={`priority ${color} ${className} ${selected ? "selected" : ""}`}
      content={name}
      {...labelProps}
    />
  );

  return (
    <Button
      className="textLink"
      onClick={onClick}
      content={renderLabel()}
      style={{ cursor: onClick ? "pointer" : "default" }}
    />
  );
};

export default PriorityLabel;

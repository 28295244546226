import { useCallback, useEffect, useState } from "react";
import useStore from "./useStore";
import { checkIsAuthorized } from "../components/helpers";

const useAuthorized = (permissions, params) => {
  const [value, setValue] = useState(false);
  const userAuth = useStore("userAuth");
  const permissionCategories = useStore("permissionCategories");

  const getValue = useCallback(async () => {
    const newValue = checkIsAuthorized(permissions, {
      ...params,
      userAuth,
      permissionCategories,
    });
    setValue(newValue);
  }, [userAuth, permissionCategories, permissions, params]);

  useEffect(() => {
    getValue();
  }, [getValue]);

  return value;
};

export default useAuthorized;
